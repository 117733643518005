import { reducer } from '@ardoq/rxbeach';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import {
  BeginCreateWorkflowPayload,
  BeginEditWorkflowPayload,
  BeginGridEditorAddFieldWorkflowPayload,
  BeginManageWorkflowPayload,
  SetEnhancedScopeDataPayload,
  UpdateFieldOrderPayload,
  beginCreateWorkflow,
  beginEditWorkflow,
  beginGridEditorAddFieldWorkflow,
  beginManageWorkflow,
  clearStashedStates,
  finishWorkflowReducer,
  popPreviousWorkflow,
  setEnhancedScopeData,
  stashCurrentWorkflow,
  updateFieldOrder,
} from 'appModelStateEdit/actions';
import propertiesEditorReducers from 'appModelStateEdit/propertiesEditor/reducers';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import {
  notifyComponentLocked,
  notifyComponentUnlocked,
} from 'streams/components/ComponentActions';
import {
  notifyReferenceLocked,
  notifyReferenceUnlocked,
} from 'streams/references/ReferenceActions';
import {
  getScopeDataWithPreservedFieldData,
  updateScopeDataProperties,
} from 'appModelStateEdit/reducerUtils';
import { GetContentOptions } from 'appModelStateEdit/legacyTypes';
import { closeGridEditorFieldWorkflow } from './propertiesEditor/addField/gridEditor/actions';
import type { PropertyValue } from 'aqTypes';
import {
  setRefTypesSortedByMostUsed,
  SetRefTypesSortedByMostUsedPayload,
} from './propertiesEditor/actions';

export const defaultState: AppModelStateEditStreamShape = {
  newEntityAttributes: null,
  entityType: null,
  entityIDs: [],
  enhancedScopeData: null,
  originalEnhancedScopeData: null,
  addedFields: new Set(),
  addedFieldsNewToWorkspace: new Set(),
  addedTags: new Set(),
  dirtyAttributes: new Set(),
  options: {} as GetContentOptions,
  stashedStates: [],
  errorMessage: null,
};

const beginEditWorkflowReducer = (
  { stashedStates }: AppModelStateEditStreamShape,
  payload: BeginEditWorkflowPayload
): AppModelStateEditStreamShape => {
  return { ...defaultState, ...payload, stashedStates };
};

const beginCreateWorkflowReducer = (
  { stashedStates }: AppModelStateEditStreamShape,
  payload: BeginCreateWorkflowPayload
): AppModelStateEditStreamShape => {
  return { ...defaultState, ...payload, stashedStates };
};

const beginManageWorkflowReducer = (
  { stashedStates }: AppModelStateEditStreamShape,
  payload: BeginManageWorkflowPayload
): AppModelStateEditStreamShape => {
  return { ...defaultState, ...payload, stashedStates };
};

const beginGridEditorAddFieldWorkflowReducer = (
  _: AppModelStateEditStreamShape,
  payload: BeginGridEditorAddFieldWorkflowPayload
): AppModelStateEditStreamShape => {
  return { ...defaultState, ...payload };
};

const resetWorkflowStateExceptStashedStates = ({
  stashedStates,
}: AppModelStateEditStreamShape) => {
  return { ...defaultState, stashedStates };
};

const setEnhancedScopeDataReducer = (
  state: AppModelStateEditStreamShape,
  { enhancedScopeData }: SetEnhancedScopeDataPayload
): AppModelStateEditStreamShape => {
  return {
    ...state,
    enhancedScopeData,
    originalEnhancedScopeData: enhancedScopeData,
  };
};
const updateScopeDataReducer =
  (
    entityType: APIEntityType,
    propertiesToUpdate: Record<string, PropertyValue>
  ) =>
  (state: AppModelStateEditStreamShape, id: ArdoqId) => {
    if (!state.entityType || !state.enhancedScopeData) {
      return { ...state };
    }
    if (state.entityType !== entityType) {
      return { ...state };
    }
    if (!state.entityIDs.includes(id)) {
      return { ...state };
    }
    return {
      ...state,
      enhancedScopeData: updateScopeDataProperties(
        state.entityType,
        [id],
        state.enhancedScopeData,
        propertiesToUpdate
      ),
    };
  };

const notifyComponentLockedReducer = updateScopeDataReducer(
  APIEntityType.COMPONENT,
  { lock: true }
);
const notifyComponentUnlockedReducer = updateScopeDataReducer(
  APIEntityType.COMPONENT,
  { lock: null }
);

const notifyReferenceLockedReducer = updateScopeDataReducer(
  APIEntityType.REFERENCE,
  { lock: true }
);

const notifyReferenceUnlockedReducer = updateScopeDataReducer(
  APIEntityType.REFERENCE,
  { lock: null }
);

const updateFieldOrderReducer = (
  state: AppModelStateEditStreamShape,
  { fieldId, order }: UpdateFieldOrderPayload
) => {
  if (!state.enhancedScopeData) {
    return { ...state };
  }
  return {
    ...state,
    enhancedScopeData: updateScopeDataProperties(
      APIEntityType.FIELD,
      [fieldId],
      state.enhancedScopeData,
      { _order: order }
    ),
  };
};

const stashCurrentWorkflowReducer = (state: AppModelStateEditStreamShape) => {
  return { ...state, stashedStates: [...state.stashedStates, state] };
};

const popPreviousWorkflowReducer = (
  currentState: AppModelStateEditStreamShape,
  previousState: AppModelStateEditStreamShape
) => {
  if (!previousState.enhancedScopeData || !currentState.enhancedScopeData) {
    return { ...currentState };
  }
  const enhancedScopeData = getScopeDataWithPreservedFieldData(
    previousState.enhancedScopeData,
    currentState.enhancedScopeData
  );
  const newField = enhancedScopeData.fields.find(
    field => !previousState.enhancedScopeData?.fieldsById[field._id]
  );
  if (newField) {
    return {
      ...previousState,
      enhancedScopeData,
      addedFields: new Set([...previousState.addedFields, newField.name]),
    };
  }
  return {
    ...previousState,
    enhancedScopeData,
  };
};

const clearStashedStatesReducer = (
  currentState: AppModelStateEditStreamShape
): AppModelStateEditStreamShape => {
  return { ...currentState, stashedStates: [] };
};

const setRefTypesSortedByMostUsedReducer = (
  currentState: AppModelStateEditStreamShape,
  { newValue }: SetRefTypesSortedByMostUsedPayload
): AppModelStateEditStreamShape => {
  return {
    ...currentState,
    refTypesSortedByMostUsed: newValue,
  };
};

export const reducers = [
  reducer(beginEditWorkflow, beginEditWorkflowReducer),
  reducer(beginCreateWorkflow, beginCreateWorkflowReducer),
  reducer(beginManageWorkflow, beginManageWorkflowReducer),
  reducer(
    beginGridEditorAddFieldWorkflow,
    beginGridEditorAddFieldWorkflowReducer
  ),
  reducer(closeGridEditorFieldWorkflow, resetWorkflowStateExceptStashedStates),
  reducer(finishWorkflowReducer, resetWorkflowStateExceptStashedStates),
  reducer(setEnhancedScopeData, setEnhancedScopeDataReducer),
  reducer(notifyComponentLocked, notifyComponentLockedReducer),
  reducer(notifyComponentUnlocked, notifyComponentUnlockedReducer),
  reducer(notifyReferenceLocked, notifyReferenceLockedReducer),
  reducer(notifyReferenceUnlocked, notifyReferenceUnlockedReducer),
  reducer(updateFieldOrder, updateFieldOrderReducer),
  reducer(stashCurrentWorkflow, stashCurrentWorkflowReducer),
  reducer(popPreviousWorkflow, popPreviousWorkflowReducer),
  reducer(clearStashedStates, clearStashedStatesReducer),
  reducer(setRefTypesSortedByMostUsed, setRefTypesSortedByMostUsedReducer),
  ...propertiesEditorReducers,
];
