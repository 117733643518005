import { APIEntityType, APITagAttributes, ArdoqId } from '@ardoq/api-types';
import { uniq, without } from 'lodash';
import { getTagEntityCollectionName } from './collectionUtils';
import type { EnhancedScopeData } from '@ardoq/data-model';

export const getWorkspaceTags = (
  workspaceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  return enhancedScopeData.tags.filter(
    tag => tag.rootWorkspace === workspaceId
  );
};

export const doesTagContainEntity = (
  tag: APITagAttributes,
  entityType: APIEntityType,
  entityID: ArdoqId
) => {
  const entityKey = getTagEntityCollectionName(entityType);
  return tag[entityKey].includes(entityID);
};

export const getCommonTagNamesForEntities = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData
) => {
  return uniq(
    enhancedScopeData.tags
      .filter(tag =>
        entityIDs.every(entityID =>
          doesTagContainEntity(tag, entityType, entityID)
        )
      )
      .map(tag => tag.name)
  );
};

export const addEntitiesToTag = (
  tag: APITagAttributes,
  entityType: APIEntityType,
  entityIDs: ArdoqId[]
) => {
  const entityKey = getTagEntityCollectionName(entityType);
  return { ...tag, [entityKey]: uniq([...tag[entityKey], ...entityIDs]) };
};

export const removeEntitiesFromTag = (
  tag: APITagAttributes,
  entityType: APIEntityType,
  entityIDs: ArdoqId[]
) => {
  const entityKey = getTagEntityCollectionName(entityType);
  return { ...tag, [entityKey]: without(tag[entityKey], ...entityIDs) };
};
