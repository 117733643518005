import { TimeSinceLastUpdate } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const chooseSurveyContentTimeSinceLastUpdate =
  actionCreator<TimeSinceLastUpdate>(
    '[surveyNotUpdatedSinceCondition] CHOOSE_SURVEY_CONTENT_TIME_SINCE_LAST_UPDATE'
  );

export const toggleSurveyNotUpdatedSinceCondition = actionCreator(
  '[surveyNotUpdatedSinceCondition] TOGGLE_SURVEY_NOT_UPDATED_SINCE_CONDITION'
);
