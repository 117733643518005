import { CreatableSelect } from '@ardoq/select';
import { CreatableListEditorProps } from 'scopeData/editors/types';

const CreatableList = ({
  label,
  value,
  description,
  creationValidator,
  invalidOptionMessage,
  dataClickId,
  options,
  onValueChange,
  onCreateOption,
  isDisabled,
  disabledMessage,
  isClearable,
  errorMessages,
}: CreatableListEditorProps) => {
  return (
    <CreatableSelect
      value={value ?? null}
      label={label}
      options={options}
      onValueChange={onValueChange}
      onCreateOption={onCreateOption}
      isValidNewOption={creationValidator!}
      noOptionsMessage={() => invalidOptionMessage}
      isDisabled={isDisabled}
      helperText={isDisabled ? disabledMessage : description}
      isClearable={isClearable}
      clickId={dataClickId}
      errorMessage={errorMessages?.[0] as string}
    />
  );
};

export default CreatableList;
