import { actionCreator } from '@ardoq/rxbeach';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { NewEntityAttributes } from 'scopeData/scopeEditUtils/types';
import {
  CreateFieldGetContentOptions,
  FieldEditorGetContentOptions,
  GetContentOptions,
} from 'appModelStateEdit/legacyTypes';
import { AppModelStateEditStreamShape } from './types';

export type BeginEditWorkflowPayload = {
  readonly entityType: APIEntityType;
  readonly entityIDs: ArdoqId[];
  readonly options: GetContentOptions;
  readonly model?: ArdoqId;
  readonly isEditingComponentStyle?: boolean;
};

export type BeginManageWorkflowPayload = {
  readonly entityType: APIEntityType;
  readonly model: ArdoqId;
};

export type BeginGridEditorAddFieldWorkflowPayload = BeginManageWorkflowPayload;

export type BeginCreateWorkflowPayload = BeginEditWorkflowPayload & {
  readonly newEntityAttributes: NewEntityAttributes[];
};

export type SetEnhancedScopeDataPayload = {
  readonly enhancedScopeData: EnhancedScopeData;
};

export const beginEditWorkflow = actionCreator<BeginEditWorkflowPayload>(
  '[appModelStateEdit] BEGIN_EDIT_WORKFLOW'
);

export const beginCreateWorkflow = actionCreator<BeginCreateWorkflowPayload>(
  '[appModelStateEdit] BEGIN_CREATE_WORKFLOW'
);

export const beginManageWorkflow = actionCreator<BeginManageWorkflowPayload>(
  '[appModelStateEdit] BEGIN_MANAGE_WORKFLOW'
);

export const beginGridEditorAddFieldWorkflow =
  actionCreator<BeginGridEditorAddFieldWorkflowPayload>(
    '[appModelStateEdit] BEGIN_GRID_EDITOR_ADD_FIELD_WORKFLOW'
  );

export const beginGridEditorCreateFieldWorkflow =
  actionCreator<CreateFieldGetContentOptions>(
    '[appModelStateEdit] BEGIN_GRID_EDITOR_CREATE_FIELD_WORKFLOW'
  );

export const beginGridEditorEditFieldWorkflow =
  actionCreator<FieldEditorGetContentOptions>(
    '[appModelStateEdit] BEGIN_GRID_EDITOR_EDIT_FIELD_WORKFLOW'
  );

export const finishWorkflow = actionCreator(
  '[appModelStateEdit] FINISH_WORKFLOW'
);

export const finishWorkflowReducer = actionCreator(
  '[appModelStateEdit] FINISH_WORKFLOW_REDUCER'
);

export const beginNestedCreateFieldWorkflow = actionCreator<GetContentOptions>(
  '[appModelStateEdit] BEGIN_NESTED_CREATE_FIELD_WORKFLOW'
);

export const finishNestedCreateFieldWorkflow = actionCreator(
  '[appModelStateEdit] FINISH_NESTED_CREATE_FIELD_WORKFLOW'
);

export const finishNonNestedCreateFieldWorkflow = actionCreator(
  '[appModelStateEdit] FINISH_NON_NESTED_CREATE_FIELD_WORKFLOW'
);

export const finishCreateFieldWorkflow = actionCreator(
  '[appModelStateEdit] FINISH_CREATE_FIELD_WORKFLOW'
);

export const clearStashedStates = actionCreator(
  '[appModelStateEdit] CLEAR_STASHED_STATES'
);

export const stashCurrentWorkflow = actionCreator(
  '[appModelStateEdit] STASH_CURRENT_WORKFLOW'
);

export const popPreviousWorkflow = actionCreator<AppModelStateEditStreamShape>(
  '[appModelStateEdit] POP_PREVIOUS_WORKFLOW'
);

export const repeatLatestWorkflow = actionCreator(
  '[appModelStateEdit] REPEAT_LATEST_WORKFLOW'
);

export const setEnhancedScopeData = actionCreator<SetEnhancedScopeDataPayload>(
  '[appModelStateEdit] SET_ENHANCED_SCOPED_DATA'
);

export type UpdateFieldOrderPayload = {
  readonly fieldId: ArdoqId;
  readonly order: number;
};
export const updateFieldOrder = actionCreator<UpdateFieldOrderPayload>(
  '[appModelStateEdit] UPDATE_FIELD_ORDER'
);

export const saveEditedEntities = actionCreator(
  '[appModelStateEdit] SAVE_EDITED_ENTITIES'
);

export const createEntities = actionCreator(
  '[appModelStateEdit] CREATE_ENTITIES'
);

export const saveAddedFieldsAndExit = actionCreator(
  '[appModelStateEdit] SAVE_ADDED_FIELDS_AND_EXIT'
);
