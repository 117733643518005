import { collectRoutines } from '@ardoq/rxbeach';
import { reportApi } from '@ardoq/api';
import { renameRoutine } from 'streams/assets/routines';
import { handleFetchAll, handleDelete } from 'streams/crud/routines';
import { reportNamespace } from './actions';

export const reportsRoutines = collectRoutines(
  handleDelete(reportNamespace, reportApi.delete),
  handleFetchAll(reportNamespace, reportApi.fetchAll),
  renameRoutine(reportNamespace, reportApi.rename)
);
