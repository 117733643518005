import Models from 'collections/models';
import Tags from 'collections/tags';
import References from 'collections/references';
import Workspaces from 'collections/workspaces';
import Components from 'collections/components';
import Fields from 'collections/fields';
import * as wamp from 'sync/wamp';
import CurrentUser from 'models/currentUser';
import * as eventHandlers from './eventHandlers';
import { componentApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError, logWarn } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import { isReference, ResourceType } from '@ardoq/api-types';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { documentArchiveAttachments } from 'collections/documentArchive';
import { documentArchiveFolders } from 'collections/documentArchive';
import {
  ArdoqEvent,
  OrgEvent,
  UnknownEvent,
  UserEvent,
  WorkspaceEvent,
  isWebSocketComponentReferenceCount,
  isResourceCreate,
  isResourceDelete,
  isResourceUpdate,
  isWebSocketBulkDelete,
  isWebSocketCreate,
  isWebSocketRecalculationDone,
  isWebSocketRecalculationError,
  isWebSocketRecalculationStarted,
  isWebSocketResetAttribute,
  isWebSocketUnset,
  isWebSocketUpdateSiblings,
} from './types';
import { dispatchAction } from '@ardoq/rxbeach';
import { userEvent } from './actions';
import { ardoqEventOperations } from './ardoqEventOperations';
import { syncModuleBackboneInterface } from './syncModuleBackboneInterface';
import { isDevelopmentMode } from 'appConfig';
import { subscribeToAction } from 'streams/utils/streamUtils';
import {
  notifyWorkspaceClosed,
  notifyWorkspaceOpened,
} from 'streams/context/ContextActions';
import { dispatchWebsocketEvent } from './websocket$';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { partition } from 'lodash';
import { updateLinkedWorkspaces } from '../models/actions';

const mapOfCollections = {
  scenario: undefined,
  tag: Tags.collection,
  component: Components.collection,
  workspace: Workspaces.collection,
  reference: References.collection,
  field: Fields.collection,
  model: Models.collection,
  attachment: documentArchiveAttachments,
  folder: documentArchiveFolders,
  workspacefolder: undefined,
  presentation: undefined,
  slide: undefined,
  report: undefined,
  user: undefined,
  notification: undefined,
  // A hack to allow the websocket handler to handle permission zone events
  [ResourceType.PERMISSION_ZONE]: undefined,
} as const;

const logIncomingWebsocketEvent = (event: UnknownEvent) => {
  if (isDevelopmentMode()) {
    // eslint-disable-next-line no-console
    console.debug('[Websockets]: Incoming event', event);
  }
};

const getCollectionForResource = (
  resourceType: ResourceType
): Backbone.Collection<Backbone.Model> | null | undefined => {
  // temporary suppress dashboards and surveys as they are unsupported
  if (
    resourceType === ResourceType.DASHBOARD ||
    resourceType === ResourceType.SURVEY ||
    resourceType === ResourceType.CHAT ||
    resourceType === ResourceType.TRAVERSAL || // No longer backbone
    resourceType === ResourceType.PRESENTATION || // No longer backbone
    resourceType === ResourceType.REPORT || // No longer backbone
    resourceType === ResourceType.BOOKMARK // Never was backbone
  ) {
    return undefined;
  }

  if (!Object.keys(mapOfCollections).includes(resourceType)) {
    logError(Error('websockets event type not implemented'), null, {
      eventType: resourceType,
    });
    return undefined;
  }

  return mapOfCollections[resourceType as keyof typeof mapOfCollections];
};

const branchAwareCollections = new Set([
  ResourceType.COMPONENT,
  ResourceType.FIELD,
  ResourceType.MODEL,
  ResourceType.REFERENCE,
  ResourceType.TAG,
  ResourceType.WORKSPACE,
]);

const isUpdateForBranchAwareCollection = (resourceType: ResourceType) =>
  branchAwareCollections.has(resourceType);

const MASTER_ID = '@@master@@';

const getActiveBranchId = () =>
  scenarioInterface.getScopeAndBranchId(getActiveScenarioId()).branchId ||
  MASTER_ID;

const isUpdateForActiveBranch = (branchId = MASTER_ID) =>
  branchId === getActiveBranchId();

/**
 * Sheet Loader needs to peek at the user events to pick up results of async
 * requests.
 */
let sheetLoaderUserEventHandler: null | ((event: UserEvent) => void) = null;
export const registerSheetLoaderUserEventHandler = (
  callback: typeof sheetLoaderUserEventHandler
) => {
  sheetLoaderUserEventHandler = callback;
};

export const setupContinuousFetch = () => {
  wamp.connect();

  CurrentUser.whenLoaded(() => {
    wamp.subscribeOrgEvents(
      CurrentUser.getOrganization()._id,
      handleWebsocketEvent
    );
    wamp.subscribeUserEvents(CurrentUser.id, handleUserEvent);
  });

  subscribeToAction(notifyWorkspaceOpened, workspace =>
    wamp.subscribeWorkspaceEvents(workspace.id!, handleWebsocketEvent)
  );
  subscribeToAction(notifyWorkspaceClosed, ({ workspaceId }) =>
    wamp.unsubscribeWorkspaceEvents(workspaceId)
  );
};

const handleUserEvent = (event: UserEvent) => {
  handleWebsocketEvent(event);
  sheetLoaderUserEventHandler?.(event);
  dispatchAction(userEvent(event));
};

const handleWebsocketEvent = async (
  event: OrgEvent | UserEvent | WorkspaceEvent
) => {
  logIncomingWebsocketEvent(event);

  // Feed any event into the websocket stream after this function has executed.
  setTimeout(dispatchWebsocketEvent, 1, event);

  const { ['resource-type']: resourceType } = event;

  const collection = getCollectionForResource(resourceType);
  if (
    !collection ||
    (hasFeature(Features.SCENARIOS_BETA) &&
      isUpdateForBranchAwareCollection(resourceType) &&
      !isUpdateForActiveBranch(event.branchId))
  ) {
    return;
  }

  try {
    handleWebsocketEventV2(event);
  } catch (error) {
    const meta = {
      eventType: event['event-type'],
      resourceType: event['resource-type'],
      topic: event.topic,
    };

    const message = 'Unexpected error in web socket handler';
    if (error instanceof Error) {
      logError(error, message, meta);
    } else {
      logError(new Error(message), null, meta);
    }
  }
  return;
};

/**
 * Web socket event handler whose responsibilities are:
 * - Piping the event to the right resource handler
 */
async function handleWebsocketEventV2(event: ArdoqEvent<unknown>) {
  const resourceType = event['resource-type'];
  const collection =
    syncModuleBackboneInterface.getCollectionForResource(resourceType);

  if (!collection) {
    return;
  }

  // Handle transaction events
  // This is a concept that is not being continued because web sockets were not
  // reliable enough, however they still exist in our code base.
  if (
    isWebSocketCreate(event) &&
    ardoqEventOperations.isOfResourceType(event, ResourceType.WORKSPACE)
  ) {
    if (!ardoqEventOperations.isTransactionEvent(event)) {
      // Skip non-transactional create workspace event
      return;
    }
    return eventHandlers.handleWorkspaceCreatedTransaction(event.data as any);
  }

  // Handle dangling references
  // When a reference is created or updated to connect one workspace to another
  // for the first time, users who only have one of the workspaces open will
  // not have loaded the other workspace, so the reference will be pointing to
  // a component that may not exist yet.
  // This breaks the expectations of the app which expects all references to
  // point to existing components.
  if (
    isReference(event.data) &&
    ardoqEventOperations.isOfResourceType(event, ResourceType.REFERENCE)
  ) {
    const missingComponentIds = syncModuleBackboneInterface
      .resolveReferenceSourceAndTarget(event.data, componentId =>
        Components.collection.get(componentId)
      )
      .filter(({ component }) => !component)
      .map(({ id }) => id);

    const isDanglingReference = missingComponentIds.length > 0;

    if (isDanglingReference) {
      const results = await Promise.all(
        missingComponentIds.map(componentApi.fetch)
      );

      // In practice only one component can be missing
      const [errors, components] = partition(results, isArdoqError);
      if (errors.length > 0) {
        logError(
          new AggregateError(errors, 'Failed to resolve dangling reference')
        );
        // Drop dangling reference as we cannot resolve its source and target
        return;
      }
      Components.collection.add(components);

      dispatchAction(updateLinkedWorkspaces({ references: [event.data] }));
    }
  }

  if (isResourceUpdate(event)) {
    if (!ardoqEventOperations.eventHasVersionedResource(event)) {
      // In theory this never happens, but this log was added in order to detect
      // if it happens in order to fix the implementation or document it.
      // Afterwards this can be deleted.
      logWarn(new Error('Websocket event with unexpected data'), null, {
        eventType: event['event-type'],
        resourceType: event['resource-type'],
      });
      return;
    }
    return eventHandlers.resourceUpdate(event, collection);
  }

  if (isResourceCreate(event)) {
    if (!ardoqEventOperations.eventHasVersionedResource(event)) {
      // In theory this never happens, but this log was added in order to detect
      // if it happens in order to fix the implementation or document it.
      // Afterwards this can be deleted.
      logWarn(new Error('Websocket event with unexpected data'), null, {
        eventType: event['event-type'],
        resourceType: event['resource-type'],
      });
      return;
    }
    eventHandlers.resourceCreate(event, collection);
  }

  if (isResourceDelete(event)) {
    return eventHandlers.modelDeleted(event, collection);
  }

  if (isWebSocketBulkDelete(event)) {
    return eventHandlers.handleBulkDeleteCleanup(event);
  }

  if (isWebSocketUnset(event)) {
    return eventHandlers.unsetFieldValues(event, collection);
  }

  if (isWebSocketResetAttribute(event)) {
    return eventHandlers.resetAttributes(event, collection);
  }

  if (isWebSocketComponentReferenceCount(event)) {
    return eventHandlers.updateComponentReferenceCounts(event, collection);
  }

  if (isWebSocketUpdateSiblings(event)) {
    return eventHandlers.updateFieldSiblings(event, collection);
  }

  if (isWebSocketRecalculationStarted(event)) {
    return eventHandlers.handleRecalculationStarted(event);
  }

  if (isWebSocketRecalculationDone(event)) {
    return eventHandlers.handleRecalculationDone(event);
  }

  if (isWebSocketRecalculationError(event)) {
    return eventHandlers.handleRecalculationError(event);
  }
}
