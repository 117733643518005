import { delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  ArdoqId,
  PersonalSetting,
} from '@ardoq/api-types';
import { buildScopeDataForWorkspaces } from 'appModelStateEdit/buildScopeData';
import {
  beginCreateWorkflow,
  type BeginCreateWorkflowPayload,
  beginEditWorkflow,
  beginGridEditorAddFieldWorkflow,
  beginGridEditorCreateFieldWorkflow,
  beginGridEditorEditFieldWorkflow,
  beginManageWorkflow,
  type BeginManageWorkflowPayload,
  beginNestedCreateFieldWorkflow,
  clearStashedStates,
  createEntities,
  finishCreateFieldWorkflow,
  finishNestedCreateFieldWorkflow,
  finishNonNestedCreateFieldWorkflow,
  finishWorkflow,
  finishWorkflowReducer,
  popPreviousWorkflow,
  repeatLatestWorkflow,
  saveAddedFieldsAndExit,
  saveEditedEntities,
  setEnhancedScopeData,
  stashCurrentWorkflow,
  updateFieldOrder,
} from 'appModelStateEdit/actions';
import { fetchGlobalFields } from 'globalFields/actions';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import {
  createEntitiesFromAttributes,
  createField,
  createOrUpdateFields,
  createOrUpdateTags,
  saveEntityAttributes,
  saveFieldAttributes,
  saveReferenceTypeAttributes,
  saveWorkspaceAttributes,
} from 'appModelStateEdit/persistenceUtils';
import { enhanceScopeData, getEntityById } from '@ardoq/renderers';
import {
  ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES,
  ADD_FIELD_TO_COMPONENT_TYPE_ATTRIBUTES,
  ADD_FIELD_TO_REFERENCE_TYPE_ATTRIBUTES,
} from 'appModelStateEdit/consts';
import propertiesEditorRoutines from 'appModelStateEdit/propertiesEditor/routines';
import { last, pick, uniq, zip } from 'lodash';
import {
  ExcludeFalsy,
  getArdoqErrorMessage,
  isArdoqError,
} from '@ardoq/common-helpers';
import {
  insertEntities,
  updateEntities,
} from 'scopeData/scopeEditUtils/collectionUtils';
import { getWorkspace } from 'appModelStateEdit/getWorkspaceUtils';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import {
  CreateFieldGetContentOptions,
  FieldEditorGetContentOptions,
  GetContentOptionsType,
} from 'appModelStateEdit/legacyTypes';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import {
  confirmFieldTypeEdit,
  confirmTypeRemovalFromField,
  getNewComponentAttributesById,
  getNewFieldAttributes,
  getNewReferenceTypeAttributes,
  needsFieldTypeEditConfirmation,
  needsTypeRemovalFromFieldConfirmation,
} from './routineUtils';
import {
  clearFormError,
  setFormError,
} from 'appModelStateEdit/propertiesEditor/actions';
import {
  COMPONENT_CREATED_MESSAGE,
  GENERIC_FORM_ERROR_MESSAGE,
} from 'appModelStateEdit/propertiesEditor/consts';
import { ToastType, showToast } from '@ardoq/status-ui';
import { logError } from '@ardoq/logging';
import { context$ } from 'streams/context/context$';
import { workspaceInterface } from '@ardoq/workspace-interface';
import activeFilter$ from 'streams/filters/activeFilter$';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import {
  setIsCreatingWsFromTemplate,
  wsFromTemplateState$,
} from 'workspaceWizard/fromTemplate/isCreatingWsFromTemplate$';
import * as gridEditor2023Actions from 'gridEditor2023/actions';
import { DirtyAttributes } from './types';
import { dateRangeOperations } from '@ardoq/date-range';
import { trackReferenceCreationPaneOpened } from './tracking';
import { trackReferenceCreated } from './propertiesEditor/tracking';
import { currentUserInterface } from '../modelInterface/currentUser/currentUserInterface';
import { api } from '@ardoq/api';
import { fetchOrgUsers } from 'streams/orgUsers/actions';
import { EnhancedScopeData } from '@ardoq/data-model';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { componentInterface } from '@ardoq/component-interface';
import { DEFAULT_DEBOUNCE_TIME } from 'modelInterface/consts';
import propertiesEditor$ from './propertiesEditor/propertiesEditor$';
import { attributesFromProperties } from './propertiesEditor/utils';

const dispatchShowGridEditor = () => {
  dispatchAction(gridEditor2023Actions.showGridEditor());
};

const isPerspectiveIdActive = (
  perspectiveId: string | null,
  selectedPerspectiveId: string | null
) =>
  ((!perspectiveId && !selectedPerspectiveId) ||
    perspectiveId === selectedPerspectiveId) &&
  !perspectiveInterface.isCurrentSetModified();

const loadDefaultPerspectiveIfChanged = (
  defaultPerspective: string | null,
  previousDefaultPerspective: string | null,
  selectedPerspectiveId: string | null
) => {
  if (!defaultPerspective) {
    return;
  }
  const isDefaultPerspectiveChanging =
    previousDefaultPerspective !== defaultPerspective;

  const shouldLoadNewDefaultPerspective =
    isDefaultPerspectiveChanging &&
    defaultPerspective &&
    (!selectedPerspectiveId ||
      isPerspectiveIdActive(previousDefaultPerspective, selectedPerspectiveId));

  if (shouldLoadNewDefaultPerspective) {
    perspectiveInterface.loadSavedPerspective(defaultPerspective);
  }
};

const beginCreateFieldWorkflow = (
  options: CreateFieldGetContentOptions,
  workspaceId: ArdoqId
) => {
  const { label, attributes = {} } = options;
  if (!label) {
    return;
  }
  const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);
  if (!modelId) {
    return;
  }
  const newEntities = [
    getNewFieldAttributes(label, modelId, workspaceId, attributes),
  ];
  dispatchAction(
    beginCreateWorkflow({
      newEntityAttributes: newEntities,
      entityType: APIEntityType.FIELD,
      entityIDs: newEntities.map(entity => entity._id),
      options,
    })
  );
};

const beginEditFieldWorkflow = (options: FieldEditorGetContentOptions) => {
  const field = options.field;
  if (!field) {
    return;
  }
  dispatchAction(
    beginEditWorkflow({
      entityType: APIEntityType.FIELD,
      entityIDs: [field.id],
      options,
    })
  );
};

const handleBeginEditWorkflowAction = routine(
  ofType(beginEditWorkflow),
  extractPayload(),
  tap(({ entityType, entityIDs, model }) => {
    const workspaceIds = uniq(
      entityIDs
        .map(entityID => {
          return getWorkspace(entityType, entityID, model);
        })
        .filter(ExcludeFalsy)
    );
    const scopeData = buildScopeDataForWorkspaces(
      workspaceIds,
      entityType,
      entityIDs
    );
    if (!scopeData) {
      return;
    }
    const enhancedScopeData = enhanceScopeData(scopeData);
    dispatchAction(setEnhancedScopeData({ enhancedScopeData }));
    dispatchAction(fetchOrgUsers());
    dispatchAction(fetchGlobalFields());
  })
);

const isReference = ({ entityType }: BeginCreateWorkflowPayload) =>
  entityType === APIEntityType.REFERENCE;

const trackWhenReferenceCreationPaneIsOpened = routine(
  ofType(beginCreateWorkflow),
  extractPayload(),
  // this routine also runs in the GridEditor, which shouldn't be tracked
  filter(() => window.IS_MAIN_ARDOQ_APP),
  filter(isReference),
  tap(trackReferenceCreationPaneOpened)
);

const handleBeginCreateWorkflowAction = routine(
  ofType(beginCreateWorkflow),
  extractPayload(),
  tap(({ entityType, newEntityAttributes }) => {
    if (!newEntityAttributes) {
      return;
    }
    const workspaceIds = uniq(
      newEntityAttributes
        .map(({ rootWorkspace }) => rootWorkspace)
        .filter(ExcludeFalsy)
    );
    const scopeData = buildScopeDataForWorkspaces(workspaceIds, entityType);
    if (!scopeData) {
      return;
    }
    const enhancedScopeData = enhanceScopeData(scopeData);
    const enhancedScopeDataWithNewEntities = insertEntities(
      entityType,
      newEntityAttributes,
      enhancedScopeData
    );
    dispatchAction(
      setEnhancedScopeData({
        enhancedScopeData: enhancedScopeDataWithNewEntities,
      })
    );
    dispatchAction(fetchOrgUsers());
    dispatchAction(fetchGlobalFields());
  })
);

const updateEnhancedScopeData = ({
  entityType,
  model,
}: BeginManageWorkflowPayload) => {
  if (!model) {
    return;
  }
  const workspace = workspaceInterface.findByModel(model);
  if (!workspace) {
    return;
  }
  const workspaceIds = [workspace._id];
  const scopeData = buildScopeDataForWorkspaces(workspaceIds, entityType);
  if (!scopeData) {
    return;
  }
  const enhancedScopeData = enhanceScopeData(scopeData);
  dispatchAction(setEnhancedScopeData({ enhancedScopeData }));
};

const handleBeginManageWorkflowAction = routine(
  ofType(beginManageWorkflow),
  extractPayload(),
  tap(updateEnhancedScopeData)
);

const handleBeginGridEditorAddFieldWorkflowAction = routine(
  ofType(beginGridEditorAddFieldWorkflow),
  extractPayload(),
  tap(payload => {
    updateEnhancedScopeData(payload);
    dispatchAction(fetchOrgUsers());
    dispatchAction(fetchGlobalFields());
  })
);

const handleBeginGridEditorCreateFieldWorkflowAction = routine(
  ofType(beginGridEditorCreateFieldWorkflow),
  extractPayload(),
  withLatestFrom(context$),
  tap(([options, { workspaceId }]) =>
    beginCreateFieldWorkflow(options, workspaceId)
  )
);

const handleBeginGridEditorEditFieldWorkflow = routine(
  ofType(beginGridEditorEditFieldWorkflow),
  extractPayload(),
  tap(beginEditFieldWorkflow)
);

const handleFinishWorkflowAction = routine(
  ofType(finishWorkflow),
  withLatestFrom(appModelStateEdit$),
  map(([, state]) => state.options.type),
  filter(type => Boolean(type)),
  tap(type => {
    if (type === GetContentOptionsType.CREATE_FIELD) {
      dispatchAction(finishCreateFieldWorkflow());
      return;
    }
    dispatchAction(finishWorkflowReducer());
  })
);

const updateLastUsedReferenceType = (
  enhancedScopeData: EnhancedScopeData,
  reference: APIReferenceAttributes,
  referenceTypeName: string
) => {
  const sourceComponentType =
    enhancedScopeDataOperations.getComponentTypeByComponentId(
      enhancedScopeData,
      reference.source
    );

  // This can't work because the target component data is not stored in the enhancedScopeData
  // const targetComponentType =
  // enhancedScopeDataOperations.getComponentTypeByComponentId(
  //   enhancedScopeData,
  //   reference.target
  // );
  const targetComponentType = componentInterface.getType(reference.target);
  if (!sourceComponentType || !targetComponentType) {
    return;
  }
  currentUserInterface.setLastUsedReferenceTypeTriple(
    sourceComponentType,
    targetComponentType,
    referenceTypeName
  );
};

const handleSaveEditedEntitiesAction = routine(
  ofType(saveEditedEntities),
  delay(DEFAULT_DEBOUNCE_TIME),
  withLatestFrom(
    propertiesEditor$,
    appModelStateEdit$,
    context$,
    activeFilter$
  ),
  tap(
    async ([
      ,
      { propertyGroups },
      {
        entityType,
        entityIDs,
        model,
        enhancedScopeData,
        originalEnhancedScopeData,
        addedFields,
        addedFieldsNewToWorkspace,
        dirtyAttributes,
        options,
      },
      { workspaceId },
      { selectedPerspectiveId },
    ]) => {
      if (!enhancedScopeData || !originalEnhancedScopeData) {
        return;
      }
      dispatchAction(clearFormError());
      try {
        switch (entityType) {
          case APIEntityType.COMPONENT:
          case APIEntityType.REFERENCE: {
            await createOrUpdateFields(
              enhancedScopeData.fields,
              addedFields,
              addedFieldsNewToWorkspace,
              entityType === APIEntityType.COMPONENT
                ? ADD_FIELD_TO_COMPONENT_TYPE_ATTRIBUTES
                : ADD_FIELD_TO_REFERENCE_TYPE_ATTRIBUTES,
              ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES
            );
            const persistedEntities = await saveEntityAttributes<
              Omit<APIComponentAttributes, 'ardoq'> | APIReferenceAttributes
            >(
              entityType,
              entityIDs,
              attributesFromProperties(propertyGroups),
              enhancedScopeData,
              originalEnhancedScopeData,
              dirtyAttributes
            );
            if (isArdoqError(persistedEntities)) {
              api.logErrorIfNeeded(persistedEntities);
              dispatchAction(
                setFormError(getArdoqErrorMessage(persistedEntities))
              );
              return;
            }
            createOrUpdateTags(
              entityType,
              entityIDs,
              enhancedScopeData,
              originalEnhancedScopeData,
              workspaceId
            );
            if (entityType === APIEntityType.REFERENCE) {
              const persistedAttributes =
                persistedEntities?.[0] as APIReferenceAttributes;
              // The follwing is a hack and a half. It seems like when we
              // _bulk_ edit references, the return from `saveEntityAttributes` is null,
              // whereas when we edit them one by-one, the return is the edited reference
              // So, ugly hack is to just not call `updateLastUsedReferenceType` when
              // The update fn doesn't return a value
              if (persistedAttributes) {
                const referenceType = getEntityById(
                  APIEntityType.REFERENCE_TYPE,
                  persistedAttributes.type as unknown as string,
                  enhancedScopeData,
                  persistedAttributes.model!
                );
                if (referenceType)
                  updateLastUsedReferenceType(
                    enhancedScopeData,
                    persistedAttributes,
                    referenceType.name
                  );
              }
            }
            dispatchAction(hideRightPane());
            break;
          }
          case APIEntityType.FIELD: {
            const newAttributes = getEntityById(
              APIEntityType.FIELD,
              entityIDs[0],
              enhancedScopeData
            )!;
            const previousAttributes = getEntityById(
              APIEntityType.FIELD,
              entityIDs[0],
              originalEnhancedScopeData
            )!;
            if (
              needsTypeRemovalFromFieldConfirmation(
                newAttributes,
                previousAttributes
              )
            ) {
              const confirmedRemoval = await confirmTypeRemovalFromField(
                entityIDs[0],
                newAttributes,
                originalEnhancedScopeData
              );
              if (!confirmedRemoval) {
                return;
              }
            }

            const {
              isChangingFromCalculatedFieldType,
              isChangingFromLocallyDerivedFieldType,
              needsConfirmation,
            } = needsFieldTypeEditConfirmation(
              newAttributes,
              previousAttributes
            );

            if (needsConfirmation) {
              const confirmedChange = await confirmFieldTypeEdit(
                isChangingFromCalculatedFieldType,
                isChangingFromLocallyDerivedFieldType
              );
              if (!confirmedChange) {
                return;
              }
            }
            await saveFieldAttributes(entityIDs[0], enhancedScopeData);
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_FIELDS,
              })
            );
            break;
          }
          case APIEntityType.REFERENCE_TYPE: {
            const isReferenceTypeCreationFlow =
              options.type === GetContentOptionsType.CREATE_REFERENCE_TYPE;
            await saveReferenceTypeAttributes(
              entityIDs[0],
              enhancedScopeData,
              model!,
              isReferenceTypeCreationFlow
            );
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.MANAGE_REFERENCE_TYPES,
              })
            );
            break;
          }
          case APIEntityType.WORKSPACE: {
            await saveWorkspaceAttributes(entityIDs[0], enhancedScopeData);
            const previousWorkspaceAttributes =
              originalEnhancedScopeData.workspacesById[entityIDs[0]];
            const workspaceAttributes =
              enhancedScopeData.workspacesById[entityIDs[0]];
            loadDefaultPerspectiveIfChanged(
              workspaceAttributes.defaultPerspective,
              previousWorkspaceAttributes.defaultPerspective,
              selectedPerspectiveId
            );
            dispatchAction(hideRightPane());
            break;
          }
          default:
            // TODO implement other entity types
            break;
        }
      } catch (error) {
        const formError =
          typeof error === 'string' ? error : GENERIC_FORM_ERROR_MESSAGE;
        dispatchAction(setFormError(formError));
        if (error instanceof Error) {
          logError(error);
        }
      }
    }
  )
);

const pickModifiedAttributes = (
  attributes: Partial<APIComponentAttributes | APIReferenceAttributes>,
  dirtyAttributes: DirtyAttributes
) => {
  /**
   * dirtyAttributes contain base name of custom date range fields, whereas
   * attributes have the values kept under '${baseName}_start_date' and '${baseName}_end_date'
   */
  const modifiedDateRangeFields = uniq(
    Object.keys(attributes)
      .filter(fieldName =>
        dateRangeOperations.fieldNameIsPartOfDateRangeField(fieldName)
      )
      .filter(dateRangeField => {
        const baseFieldName =
          dateRangeOperations.extractDateRangeFieldName(dateRangeField);
        return baseFieldName ? dirtyAttributes.has(baseFieldName) : false;
      })
  );

  return {
    ...pick(attributes, Array.from(dirtyAttributes)),
    ...pick(attributes, modifiedDateRangeFields),
  };
};

const handleCreateEntitiesAction = routine(
  ofType(createEntities),
  delay(DEFAULT_DEBOUNCE_TIME),
  withLatestFrom(propertiesEditor$, appModelStateEdit$, context$),
  tap(
    async ([
      ,
      { propertyGroups },
      {
        newEntityAttributes,
        entityType,
        entityIDs,
        enhancedScopeData,
        originalEnhancedScopeData,
        addedFields,
        addedFieldsNewToWorkspace,
        dirtyAttributes,
      },
      { workspaceId },
    ]) => {
      if (
        !newEntityAttributes ||
        !enhancedScopeData ||
        !originalEnhancedScopeData
      ) {
        return;
      }
      dispatchAction(clearFormError());
      try {
        switch (entityType) {
          case APIEntityType.COMPONENT:
          case APIEntityType.REFERENCE: {
            await createOrUpdateFields(
              enhancedScopeData.fields,
              addedFields,
              addedFieldsNewToWorkspace,
              entityType === APIEntityType.COMPONENT
                ? ADD_FIELD_TO_COMPONENT_TYPE_ATTRIBUTES
                : ADD_FIELD_TO_REFERENCE_TYPE_ATTRIBUTES,
              ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES
            );

            const attributesToSave = newEntityAttributes.map(
              singleEntityAttributes => ({
                ...singleEntityAttributes,
                ...pickModifiedAttributes(
                  attributesFromProperties(propertyGroups),
                  dirtyAttributes
                ),
              })
            );

            const persistedEntities = await createEntitiesFromAttributes<
              APIComponentAttributes | APIReferenceAttributes
            >(entityType, attributesToSave);
            if (isArdoqError(persistedEntities)) {
              api.logErrorIfNeeded(persistedEntities);
              dispatchAction(
                setFormError(getArdoqErrorMessage(persistedEntities))
              );
              return;
            }
            const tempIdMappings = Object.fromEntries(
              zip(entityIDs, persistedEntities)
            );
            const persistedIds = persistedEntities.map(({ _id }) => _id);
            const updatedEnhancedScopeData = updateEntities(
              entityType,
              tempIdMappings,
              enhancedScopeData
            );
            const updatedOriginalEnhancedScopeData = updateEntities(
              entityType,
              tempIdMappings,
              originalEnhancedScopeData
            );
            createOrUpdateTags(
              entityType,
              persistedIds,
              updatedEnhancedScopeData,
              updatedOriginalEnhancedScopeData,
              workspaceId
            );

            if (entityType === APIEntityType.COMPONENT) {
              showToast(COMPONENT_CREATED_MESSAGE, ToastType.SUCCESS);
              dispatchAction(repeatLatestWorkflow());
              return;
            }
            if (entityType === APIEntityType.REFERENCE) {
              const persistedAttributes =
                persistedEntities?.[0] as APIReferenceAttributes;

              // it is used to track last used reference
              // type value that is independent of the source and target
              const lastUsedReferenceType =
                currentUserInterface.getPersonalSetting(
                  PersonalSetting.LAST_USED_REFERENCE_TYPE_NAME
                );
              const referenceType = getEntityById(
                APIEntityType.REFERENCE_TYPE,
                persistedAttributes.type as unknown as string,
                enhancedScopeData,
                persistedAttributes.model!
              );
              trackReferenceCreated(
                referenceType?.name !== lastUsedReferenceType
              );
              if (referenceType)
                updateLastUsedReferenceType(
                  enhancedScopeData,
                  persistedAttributes,
                  referenceType.name
                );
            }
            dispatchAction(hideRightPane());
            break;
          }
          case APIEntityType.FIELD: {
            await createField(entityIDs[0], enhancedScopeData);
            dispatchAction(finishWorkflow());
            break;
          }
        }
      } catch (error) {
        const formError =
          typeof error === 'string' ? error : GENERIC_FORM_ERROR_MESSAGE;
        dispatchAction(setFormError(formError));
      }
    }
  )
);

const handleUpdateFieldOrder = routine(
  ofType(updateFieldOrder),
  extractPayload(),
  tap(({ fieldId, order }) => {
    fieldInterface.save(fieldId, { _order: order });
  })
);

const handleShowRightPane = routine(
  ofType(showRightPane),
  extractPayload(),
  withLatestFrom(context$),
  tap(([options, { workspaceId, componentId, referenceId }]) => {
    dispatchAction(gridEditor2023Actions.hideGridEditor());
    switch (options.type) {
      case GetContentOptionsType.CREATE_COMPONENT: {
        const { parentId, workspaceId: currentWorkspaceId } = options;
        const newComponentAttributes = getNewComponentAttributesById(
          currentWorkspaceId!,
          parentId
        );
        if (!newComponentAttributes) {
          return;
        }

        dispatchAction(
          beginCreateWorkflow({
            newEntityAttributes: [newComponentAttributes],
            entityType: APIEntityType.COMPONENT,
            entityIDs: [newComponentAttributes._id],
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.COMPONENT_PROPERTIES: {
        const entityIDs = options.componentIds
          ? options.componentIds
          : componentId
            ? [componentId]
            : null;

        if (!entityIDs) {
          return;
        }

        dispatchAction(
          beginEditWorkflow({
            entityType: APIEntityType.COMPONENT,
            entityIDs,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.COMPONENT_STYLE: {
        const entityIDs = options.componentIds
          ? options.componentIds
          : componentId
            ? [componentId]
            : null;

        if (!entityIDs) {
          return;
        }

        dispatchAction(
          beginEditWorkflow({
            entityType: APIEntityType.COMPONENT,
            entityIDs,
            isEditingComponentStyle: true,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.CREATE_REFERENCE: {
        const { newReferenceAttributes = [], referenceIds = [] } = options;
        dispatchAction(
          beginCreateWorkflow({
            newEntityAttributes: newReferenceAttributes,
            entityType: APIEntityType.REFERENCE,
            entityIDs: referenceIds,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.REFERENCE_PROPERTIES: {
        const entityIDs = options.referenceIds
          ? options.referenceIds
          : referenceId
            ? [referenceId]
            : null;

        if (!entityIDs) {
          return;
        }

        dispatchAction(
          beginEditWorkflow({
            entityType: APIEntityType.REFERENCE,
            entityIDs,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.CREATE_FIELD: {
        beginCreateFieldWorkflow(options, workspaceId);
        break;
      }
      case GetContentOptionsType.FIELD_EDITOR: {
        beginEditFieldWorkflow(options);
        break;
      }
      case GetContentOptionsType.CREATE_REFERENCE_TYPE: {
        const model = structuredClone(
          workspaceInterface.getModelData(workspaceId)
        );
        if (!model) {
          return;
        }
        const newEntities = [getNewReferenceTypeAttributes(model, workspaceId)];
        dispatchAction(
          beginCreateWorkflow({
            // @ts-expect-error doesn't match up with the expected type because _id is a number for reference types
            newEntityAttributes: newEntities,
            entityType: APIEntityType.REFERENCE_TYPE,
            // @ts-expect-error passing a number (reference type id) when entityIDs should be a string[]
            entityIDs: newEntities.map(entity => entity._id),
            model: model._id,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.REFERENCE_TYPE_EDITOR: {
        const referenceType = options.referenceType;
        if (!referenceType) {
          return;
        }
        const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);
        if (!modelId) {
          return;
        }
        dispatchAction(
          beginEditWorkflow({
            entityType: APIEntityType.REFERENCE_TYPE,
            // @ts-expect-error passing a number (reference type id) when entityIDs should be a string[]
            entityIDs: [referenceType.id],
            model: modelId,
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.WORKSPACE_PROPERTIES: {
        const selectedWorkspaceId = options.workspaceId;
        if (!selectedWorkspaceId) {
          return;
        }
        dispatchAction(
          beginEditWorkflow({
            entityType: APIEntityType.WORKSPACE,
            entityIDs: [selectedWorkspaceId],
            options,
          })
        );
        break;
      }
      case GetContentOptionsType.MANAGE_FIELDS: {
        const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);
        if (!modelId) {
          return;
        }
        dispatchAction(
          beginManageWorkflow({
            entityType: APIEntityType.FIELD,
            model: modelId,
          })
        );
        break;
      }
      case GetContentOptionsType.MANAGE_REFERENCE_TYPES: {
        const modelId = workspaceInterface.getWorkspaceModelId(workspaceId);
        if (!modelId) {
          return;
        }
        dispatchAction(
          beginManageWorkflow({
            entityType: APIEntityType.REFERENCE_TYPE,
            model: modelId,
          })
        );
        break;
      }
      case GetContentOptionsType.ADD_FIELD_TO_WORKSPACE: {
        dispatchAction(fetchGlobalFields());
      }
    }
  })
);

const handleHideRightPane = routine(
  ofType(hideRightPane),
  withLatestFrom(wsFromTemplateState$),
  tap(([, { isCreating: isCreatingWorkspaceFromTemplate }]) => {
    dispatchAction(finishWorkflow());
    if (isCreatingWorkspaceFromTemplate) {
      dispatchAction(setIsCreatingWsFromTemplate(false));
      dispatchShowGridEditor();
    }
  })
);

const handleRepeatLatestWorkflow = routine(
  ofType(repeatLatestWorkflow),
  withLatestFrom(appModelStateEdit$),
  tap(([, { options }]) => {
    dispatchAction(hideRightPane());
    dispatchAction(showRightPane(options));
  })
);

const handleBeginNestedCreateFieldWorkflow = routine(
  ofType(beginNestedCreateFieldWorkflow),
  extractPayload(),
  tap(options => {
    dispatchAction(stashCurrentWorkflow());
    dispatchAction(hideRightPane());
    dispatchAction(showRightPane(options));
  })
);

const handleFinishNestedCreateFieldWorkflow = routine(
  ofType(finishNestedCreateFieldWorkflow),
  withLatestFrom(appModelStateEdit$),
  map(([, { stashedStates }]) => {
    return last(stashedStates)!;
  }),
  tap(previousState => {
    dispatchAction(clearStashedStates());
    dispatchAction(finishWorkflowReducer());
    const previousOptions = previousState.options;
    dispatchAction(hideRightPane());
    dispatchAction(showRightPane(previousOptions));
    dispatchAction(popPreviousWorkflow(previousState));
  })
);

const handleFinishNonNestedCreateFieldWorkflow = routine(
  ofType(finishNonNestedCreateFieldWorkflow),
  tap(() => {
    dispatchAction(finishWorkflowReducer());
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.MANAGE_FIELDS,
      })
    );
  })
);

const handleFinishCreateFieldWorkflow = routine(
  ofType(finishCreateFieldWorkflow),
  withLatestFrom(appModelStateEdit$),
  map(([, { stashedStates }]) => Boolean(stashedStates.length)),
  tap(hasStashedStates =>
    dispatchAction(
      hasStashedStates
        ? finishNestedCreateFieldWorkflow()
        : finishNonNestedCreateFieldWorkflow()
    )
  )
);

const handleSaveAddedFieldsAndExit = routine(
  ofType(saveAddedFieldsAndExit),
  withLatestFrom(appModelStateEdit$),
  tap(
    async ([
      ,
      { entityType, enhancedScopeData, addedFields, addedFieldsNewToWorkspace },
    ]) => {
      if (!enhancedScopeData) {
        dispatchAction(hideRightPane());
        return;
      }
      try {
        await createOrUpdateFields(
          enhancedScopeData.fields,
          addedFields,
          addedFieldsNewToWorkspace,
          entityType === APIEntityType.COMPONENT
            ? ADD_FIELD_TO_COMPONENT_TYPE_ATTRIBUTES
            : ADD_FIELD_TO_REFERENCE_TYPE_ATTRIBUTES,
          ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES
        );
      } catch (error) {
        const message =
          'Failed to save fields while closing the Sidebar Editor';
        if (error instanceof Error) {
          logError(error, message);
        } else {
          logError(Error(message));
        }
      }
      dispatchAction(hideRightPane());
    }
  )
);

export default collectRoutines(
  handleBeginEditWorkflowAction,
  handleBeginCreateWorkflowAction,
  handleBeginManageWorkflowAction,
  handleBeginGridEditorAddFieldWorkflowAction,
  handleBeginGridEditorCreateFieldWorkflowAction,
  handleBeginGridEditorEditFieldWorkflow,
  handleFinishWorkflowAction,
  handleSaveEditedEntitiesAction,
  handleCreateEntitiesAction,
  handleUpdateFieldOrder,
  handleShowRightPane,
  handleHideRightPane,
  handleRepeatLatestWorkflow,
  handleBeginNestedCreateFieldWorkflow,
  handleFinishNestedCreateFieldWorkflow,
  handleFinishNonNestedCreateFieldWorkflow,
  handleFinishCreateFieldWorkflow,
  handleSaveAddedFieldsAndExit,
  trackWhenReferenceCreationPaneIsOpened,
  ...propertiesEditorRoutines
);
