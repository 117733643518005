import { ListEditorProps } from 'scopeData/editors/types';
import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { OrgUsersStreamShape } from 'streams/orgUsers/types';

type UserOptionsStreamShape = Pick<ListEditorProps, 'options'>;

const orgMembersToUserOptions = ({
  users,
}: OrgUsersStreamShape): UserOptionsStreamShape => ({
  options: users.map(({ _id, name, email }) => ({
    value: _id,
    label: `${name} (${email})`,
  })),
});

const userOptions$ = derivedStream('userOptions$', orgUsers$).pipe(
  map(([state]) => state),
  map(orgMembersToUserOptions)
);

export default userOptions$;
