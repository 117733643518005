import { EditIcon } from '@ardoq/icons';
import { Field } from '@ardoq/forms';
import { ButtonGroup, TertiaryButton } from '@ardoq/button';

interface CalculatedFieldEditorSectionProps {
  lastUpdated: string;
  shouldShowEditButton: boolean;
  onEditButtonClick: VoidFunction;
  onRefreshButtonClick: VoidFunction;
}

const CalculatedFieldEditorSection = ({
  shouldShowEditButton,
  onEditButtonClick,
  onRefreshButtonClick,
}: CalculatedFieldEditorSectionProps) => (
  <Field>
    <ButtonGroup>
      <TertiaryButton onClick={onRefreshButtonClick}>
        Recalculate
      </TertiaryButton>

      {shouldShowEditButton && (
        <TertiaryButton onClick={onEditButtonClick}>
          <EditIcon />
        </TertiaryButton>
      )}
    </ButtonGroup>
    {/* Hidden until proper field can be displayed */}
    {/* <InputHintMessage>Last calculation: {lastUpdated}</InputHintMessage> */}
  </Field>
);

export default CalculatedFieldEditorSection;
