import { APIRoleAttributes } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export const fetchRoles = actionCreator('[roles] FETCH_ROLES');

export const setRoles = actionCreator<APIRoleAttributes[]>('[roles] SET_ROLES');

export const notifyFailedToFetchRoles = actionCreator(
  '[roles] NOTIFY_FAILED_TO_FETCH_ROLES'
);

export const updateRole = actionCreator<APIRoleAttributes>(
  '[roles] UPDATE_ROLE'
);
