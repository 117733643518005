import styled from 'styled-components';
import { colors, s24 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { text1 } from '@ardoq/typography';
import {
  IntegrationWorkspace,
  SavedTransferConfig,
} from '@ardoq/api-types/integrations';
import { IntegrationDate } from '../integrationDate/IntegrationDate';
import { ExpandedRowMapping } from '../expandedRowMapping/ExpandedRowMapping';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

const ConfigText = styled.div`
  ${text1}
`;

const ExpandedTransferConfigContainer = styled(Space)`
  background-color: ${colors.grey95};
  padding: ${s24};
`;

export function ExpandedTransferConfig({
  lastUpdated,
  lastImported,
  tables,
  connection,
  onWorkspaceClick,
}: Pick<SavedTransferConfig, 'lastUpdated' | 'lastImported' | 'tables'> & {
  connection?: { name: string };
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
}) {
  return (
    <ExpandedTransferConfigContainer $isVertical>
      {connection && <ConfigText>Connection: {connection.name}</ConfigText>}

      <Space $justify="space-between">
        <ConfigText>
          Created:{' '}
          <IntegrationDate date={lastUpdated} placeholder="Re-save to update" />
        </ConfigText>
        <ConfigText>
          Last imported:{' '}
          <IntegrationDate
            date={lastImported}
            placeholder="not available yet"
          />
        </ConfigText>
      </Space>
      <ExpandedRowMapping
        dataSource={tables.map(table => ({
          name: table.name,
          rowRepresentation: table.rowRepresentation,
          workspace: table.rootWorkspace,
          isWorkspaceAvailable: table.rootWorkspace.id
            ? !!workspaceInterface.get(table.rootWorkspace.id)
            : false,
        }))}
        onWorkspaceClick={onWorkspaceClick}
      ></ExpandedRowMapping>
    </ExpandedTransferConfigContainer>
  );
}
