import Backbone from 'backbone';
import { getShapes } from '@ardoq/icons';
import { ArrowType, LineType } from '@ardoq/api-types';
import { logError, logWarn } from '@ardoq/logging';
import { getCurrentLocale, localeAreEqualLowercase } from '@ardoq/locale';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { contextInterface } from 'modelInterface/contextInterface';
import { getImageSelectList_DEPRECATED, noImageOption } from 'icons/images';
import { getApiUrl } from 'backboneExtensions';
import type {
  ComponentBackboneModel,
  Model as ModelBackboneModel,
} from 'aqTypes';
import type { ModelType } from './ModelType';
import type { APIReferenceType } from '@ardoq/api-types';
import { each, isString } from 'lodash';

interface GetReferenceTypeByIdOptions {
  skipDeletedTypes?: boolean;
}
const collectNames = (
  obj: Record<string, ModelType>,
  ignoreId?: string
): string[] =>
  Object.values(obj)
    .filter(({ id }) => id !== ignoreId)
    .flatMap(({ name, children = {} }) => [name, ...collectNames(children)]);

export const IMPLICIT_REFTYPE_ID = 2;

export const DEFAULT_REFERENCE_TYPES: Record<number, APIReferenceType> = {
  0: {
    name: 'Asynchronous',
    id: 0,
    index: 0,
    line: LineType.DOTTED,
    color: '#00f', // blue
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.BOTTOM,
    returnsValue: true,
    svgStyle: null,
  },
  1: {
    name: 'Synchronous',
    id: 1,
    index: 1,
    line: LineType.SOLID,
    color: '#000', // black
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.BOTH,
    returnsValue: true,
    svgStyle: null,
  },
  [IMPLICIT_REFTYPE_ID]: {
    name: 'Implicit',
    id: IMPLICIT_REFTYPE_ID,
    index: IMPLICIT_REFTYPE_ID,
    line: LineType.DASHED,
    color: '#f00', // red
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.BOTH_FILLED,
    returnsValue: false,
    svgStyle: null,
  },
  3: {
    name: 'Fire and forget',
    id: 3,
    index: 3,
    line: LineType.DOTTED,
    color: '#ffa500', // orange
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.BOTH,
    returnsValue: false,
    svgStyle: null,
  },
  4: {
    name: 'Data flow',
    id: 4,
    index: 4,
    line: LineType.SOLID,
    color: '#808080', // gray
    lineBeginning: ArrowType.NONE,
    lineEnding: ArrowType.CIRCLE,
    returnsValue: false,
    svgStyle: null,
  },
};
type ModelClass = {
  extend: typeof Backbone.Model.extend;
  new (attributes?: Record<string, any>): ModelBackboneModel;
};
const deletedModelType: ModelType = {
  name: 'Error - Deleted Type',
  icon: 'none',
  id: 'NONE',
  children: {},
  index: 0,
  shape: '',
  standard: '',
  image: null,
  color: '#000',
  returnsValue: false,
  level: 0,
};
export const Model: ModelClass = Backbone.Model.extend({
  idAttribute: '_id',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/model`,
  _internalModelChanged: 'internal:ModelChanged',
  _svgShapes: getShapes(),
  defaultReferenceTypes: DEFAULT_REFERENCE_TYPES,
  deletedModelType,
  defaults: {
    name: '',
    category: 'Other',
    useAsTemplate: false,
    description: '',
    root: null,
    flexible: false,
    referenceTypes: null,
    blankTemplate: false,
    startView: null,
    defaultViews: [],
  },
  isFlexible: function (this: ModelBackboneModel) {
    return this.get('flexible');
  },
  isTemplate: function (this: ModelBackboneModel) {
    return this.isCommon() || this.get('useAsTemplate');
  },
  isCommon: function (this: ModelBackboneModel) {
    return this.get('common');
  },
  getImageList: function (
    this: ModelBackboneModel,
    currentValue: string | null
  ) {
    const currentWsId = contextInterface.getCurrentWsId();
    if (!currentWsId) return [];
    const documentArchiveImages =
      documentArchiveInterface.getDocumentArchiveImages({
        wsId: currentWsId,
      });
    return [
      noImageOption,
      ...getImageSelectList_DEPRECATED(documentArchiveImages, currentValue),
    ];
  },
  getAllTypes: function (this: ModelBackboneModel) {
    if (!this._flattenedModel) {
      this.fixModel();
    }
    return this._flattenedModel;
  },
  getReferenceTypes: function (this: ModelBackboneModel) {
    const referenceTypes = this.get('referenceTypes');
    if (!referenceTypes) {
      return this.defaultReferenceTypes;
    } else if (!referenceTypes[2]) {
      // Ensure that the implicit reference type is always present
      referenceTypes[2] = this.defaultReferenceTypes[2];
    }
    return referenceTypes;
  },
  getReferenceTypeById: function (
    this: ModelBackboneModel,
    id: string | number,
    options: GetReferenceTypeByIdOptions = {}
  ) {
    const referenceType = this.getReferenceTypes()[id];

    if (referenceType) {
      return referenceType;
    } else if (!options.skipDeletedTypes) {
      return {
        name: 'Error - deleted type',
        id,
        line: LineType.DOTTED,
        color: 'red',
        lineBeginning: ArrowType.NONE,
        lineEnding: ArrowType.NONE,
      };
    }
  },
  removeReferenceType: function (
    this: ModelBackboneModel,
    referenceTypeId: number
  ) {
    this.attributes.referenceTypes = Object.fromEntries(
      Object.entries(this.attributes.referenceTypes).filter(entry => {
        return entry[0] !== String(referenceTypeId);
      })
    );
    return this.save();
  },
  getTypeById: function (this: ModelBackboneModel, id: string | string[]) {
    if (!this._flattenedModel) {
      this.fixModel();
    }
    const modelTypes = this._flattenedModel!;

    if (Array.isArray(id)) {
      const foundTypes = id.map(currentId => {
        if (!modelTypes[currentId]) {
          logWarn(Error('Missing component type in workspace model'), null, {
            id: currentId,
            workspaceModelId: this.get('_id'),
            modelKeys: Object.keys(modelTypes),
          });
        }
        return modelTypes[currentId];
      });
      return foundTypes;
    }

    if (!modelTypes[id]) {
      logWarn(Error('Missing component type in workspace model'), null, {
        id,
        workspaceModelId: this.get('_id'),
        modelKeys: Object.keys(modelTypes),
      });
      return this.deletedModelType;
    }

    return modelTypes[id];
  },
  canModelTypeHaveChildren: function (this: ModelBackboneModel, model: string) {
    const myModelType = isString(model) ? this.getTypeById(model) : model;
    if (
      this.isFlexible() ||
      (myModelType && Object.keys(myModelType.children).length > 0)
    ) {
      return true;
    }
    return false;
  },
  getFirstModelType: function (
    this: ModelBackboneModel,
    modelList: Record<string, ModelType>
  ) {
    const firstKey = Object.keys(modelList)[0];
    return modelList[firstKey];
  },
  getTypeByComponent: function (
    this: ModelBackboneModel,
    comp: ComponentBackboneModel
  ) {
    const typeId: string = comp.get('typeId');
    return typeId && this.getTypeById(typeId);
  },
  getTypeByComponentsParent: function (
    this: ModelBackboneModel,
    comp: ComponentBackboneModel
  ) {
    const typeId = comp.get('typeId');
    const parent = comp.getParent();
    const parentType = parent && this.getTypeById(parent.getTypeId());
    const modelList: Record<string, ModelType> = parentType
      ? parentType.children
      : this.get('root');

    const type = modelList[typeId] || this.getFirstModelType(modelList);
    if (type) {
      return type;
    }
    // If no type is returned from the previous statement, then there is no
    // hierarchical rule (most commonly occurring when adding leaf components
    // beyond the defined model hierarchy levels). If the model is flexible,
    // arbitrarily return the first type from the root node. If not flexible,
    // something has gone wrong.
    return this.isFlexible() ? this.getFirstModelType(this.get('root')) : null;
  },
  getComponentTypes: function (
    this: ModelBackboneModel,
    comp: ComponentBackboneModel
  ) {
    const parent = comp.getParent();
    const modelList: Record<string, ModelType> = {};
    if (!this.isFlexible()) {
      const parentType: Record<string, ModelType> = parent
        ? (parent.getMyType() as ModelType).children
        : this.get('root');
      each(parentType, function (type, id) {
        if (!modelList[id]) {
          modelList[id] = type;
        }
      });
    } else {
      // Makes sure the 'hierarchically correct' types are returned first
      each(this.getAllTypes(), function (type, id) {
        if (!modelList[id]) {
          modelList[id] = type;
        }
      });
    }
    return modelList;
  },
  fixModel: function (this: ModelBackboneModel, children: ModelType) {
    const localChildren: Record<string, ModelType> = children
      ? children.children
      : this.attributes.root;
    if (!children) {
      this._flattenedModel = {};
      this._parentList = {};
    }
    let index = 1;
    each(localChildren, (model, key) => {
      if (this._flattenedModel![key]) {
        const errorString = 'Model already contains type of name';
        logError(new Error(errorString), errorString, { key });
      } else {
        this._parentList![key] = localChildren;
        this._flattenedModel![key] = model;
        if (model.index !== index) {
          model.index = index;
        }
        index++;
        this.fixModel(model);
      }
    });
  },
  getParentType: function (this: ModelBackboneModel, typeId: string) {
    return this._parentList![typeId];
  },
  removeChild: function (this: ModelBackboneModel, typeId: string) {
    const parent = this.getParentType(typeId);
    if (parent) {
      delete parent[typeId];
      this.trigger('change', this);
    }
  },
  initialize: function (this: ModelBackboneModel) {
    if (!this.get('root')) {
      this.set('root', {});
    }

    this.on(`${this._internalModelChanged} changed:root sync`, () => {
      this.fixModel();
    });
  },
  name: function (this: ModelBackboneModel) {
    return this.get('name');
  },
  toString: function (this: ModelBackboneModel) {
    return this.name();
  },
  isNameValid: function (name = '', currentId?: string) {
    if (!name) return false;

    const locale = getCurrentLocale();
    const names = collectNames(this.get('root'), currentId);

    return names.every(value => !localeAreEqualLowercase(value, name, locale));
  },
});
