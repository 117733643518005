import { APIModelAttributes } from '@ardoq/api-types';
import { IMPLICIT_REFTYPE_ID } from 'models/model';

export const getNextReferenceId = (model: APIModelAttributes): number => {
  const maxRemoteId = model.maxReferenceTypeKey ?? IMPLICIT_REFTYPE_ID;
  const currentIds = Object.values(model.referenceTypes).map(
    referenceType => referenceType.id
  );

  return Math.max(...currentIds, maxRemoteId) + 1;
};
