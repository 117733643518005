import { ArdoqId } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';

export type ModifyParentPayload = ArdoqId | null;
export const modifyParent = actionCreator<ModifyParentPayload>(
  '[appModelStateEdit] MODIFY_PARENT'
);

export type ModifyComponentTypePayload = ArdoqId;
export const modifyComponentType = actionCreator<ModifyComponentTypePayload>(
  '[appModelStateEdit] MODIFY_COMPONENT_TYPE'
);

export const assignSubdivisionMembershipFromParent =
  actionCreator<ArdoqId | null>(
    '[appModelStateEdit] ASSIGN_SUBDIVISION_MEMBERSHIP_FROM_PARENT'
  );
