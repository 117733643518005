import { DateRangePropertyValue, PropertyValue } from 'aqTypes';
import { EditorProperty } from 'appModelStateEdit/propertiesEditor/types';
import { isObject } from 'lodash';
import { hasOwnProperty } from 'utils/objectUtils';
import {
  APIFieldAttributes,
  APIFieldType,
  APIGlobalField,
  DateRangeFieldDefaultValue,
} from '@ardoq/api-types';
import { AddedFieldsNewToWorkspace } from 'appModelStateEdit/types';
import {
  getDateRangePartType,
  dateRangeOperations,
  isDateRangeFieldType,
} from '@ardoq/date-range';
import { Maybe } from '@ardoq/common-helpers';

export const getFieldOptions = (
  field: Pick<APIFieldAttributes, 'defaultValue' | 'type'>
) => {
  if (
    field.type === APIFieldType.LIST ||
    field.type === APIFieldType.SELECT_MULTIPLE_LIST
  ) {
    if (!field.defaultValue || typeof field.defaultValue !== 'string') {
      return;
    }
    return field.defaultValue
      .split(',')
      .map(value => ({ value, label: value }));
  }
};

export const splitDateRangeFieldIntoDateTimeFields = <T extends APIGlobalField>(
  field: T
): T | T[] => {
  if (!isDateRangeFieldType(field.type)) {
    return field;
  }

  let start: Maybe<APIFieldAttributes> = null;
  let end: Maybe<APIFieldAttributes> = null;

  if (dateRangeOperations.isDateRangeField(field)) {
    start = field.dateTimeFields.start;
    end = field.dateTimeFields.end;
  }

  return [
    {
      ...field,
      type: getDateRangePartType(field.type),
      name: `${field.name}_start_date`,
      label: `${field.label}.StartDate`,
      defaultValue: (field.defaultValue as DateRangeFieldDefaultValue).start,
      // Necessary due to hack calculated date range field
      // See: https://help.ardoq.com/en/articles/43910-calculated-date-ranges
      calculatedFieldSettings:
        start?.calculatedFieldSettings ?? field.calculatedFieldSettings,
    },
    {
      ...field,
      type: getDateRangePartType(field.type),
      name: `${field.name}_end_date`,
      label: `${field.label}.EndDate`,
      defaultValue: (field.defaultValue as DateRangeFieldDefaultValue).end,
      // Necessary due to hack calculated date range field
      // See: https://help.ardoq.com/en/articles/43910-calculated-date-ranges
      calculatedFieldSettings:
        end?.calculatedFieldSettings ?? field.calculatedFieldSettings,
    },
  ];
};

export type AddedFieldsReducerUtilAccumulator = {
  readonly fieldsToUpdate: APIFieldAttributes[];
  readonly fieldsToCreate: APIFieldAttributes[];
};
export const addedFieldsReducerUtil =
  (
    fields: APIFieldAttributes[],
    addedFieldsNewToWorkspace: AddedFieldsNewToWorkspace
  ) =>
  (result: AddedFieldsReducerUtilAccumulator, addedFieldName: string) => {
    const field = fields.find(
      currentField => currentField.name === addedFieldName
    );
    if (!field) {
      return result;
    }
    const key = addedFieldsNewToWorkspace.has(field.name)
      ? 'fieldsToCreate'
      : 'fieldsToUpdate';
    return {
      ...result,
      [key]: [...result[key], field].flatMap(
        splitDateRangeFieldIntoDateTimeFields
      ),
    };
  };

/*
 *  -> Attributes
 */

const isDateRangePropertyValue = (
  value: PropertyValue
): value is DateRangePropertyValue =>
  isObject(value) &&
  hasOwnProperty(value, 'start') &&
  hasOwnProperty(value, 'end');

const scopeDataDateRangePropertyToApiDateRangePropertyPair = (
  property: EditorProperty,
  value: DateRangePropertyValue
) => [
  {
    ...property,
    name: `${property.name}_start_date`,
    value: value.start,
  },
  {
    ...property,
    name: `${property.name}_end_date`,
    value: value.end,
  },
];

export const splitDateRangeProperties = (properties: EditorProperty[]) => {
  return properties.flatMap(property => {
    if (isDateRangePropertyValue(property.value)) {
      return scopeDataDateRangePropertyToApiDateRangePropertyPair(
        property,
        property.value
      );
    }
    return { ...property };
  });
};
