import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToPath } from 'integrations/common/navigation/actions';
import { ImportRoute } from 'integrations/common/navigation/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { AsyncStatus } from 'integrations/common/types/api';
import { consent$ } from 'integrations/microsoftEntraId/streams/consent/consent';
import { combineLatest, map } from 'rxjs';
import { enableTabularMappingsDefault } from '../../../common/streams/tabularMappingsDefault/actions';

function mergeStatuses(
  connectionsStatus: AsyncStatus,
  consentStatus: AsyncStatus
): AsyncStatus {
  if (connectionsStatus === 'FAILURE' || consentStatus === 'FAILURE') {
    return 'FAILURE';
  }
  if (connectionsStatus === 'LOADING' || consentStatus === 'LOADING') {
    return 'LOADING';
  }
  if (connectionsStatus === 'INIT' || consentStatus === 'INIT') {
    return 'INIT';
  }
  return 'SUCCESS';
}

function onConnectionClick() {
  dispatchAction(
    navigateToPath({
      integrationId: 'microsoft-entra-id',
      path: ImportRoute.SELECT_DATA,
    })
  );
  dispatchAction(
    enableTabularMappingsDefault({ integrationId: 'microsoft-entra-id' })
  );
}

export const viewModel$ = combineLatest([
  getConnectionsStream('microsoft-entra-id'),
  consent$,
]).pipe(
  map(([connectionsState, consent]) => {
    return {
      connections: connectionsState.connections,
      consent,
      status: mergeStatuses(
        connectionsState.statuses.list.status,
        consent.status
      ),
      onConnectionClick,
    };
  })
);
