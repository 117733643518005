import {
  HistoryVersionTableRowData,
  RevertBlockedType,
  UseEntityScopeProps,
} from './types';
import { SelectOption } from '@ardoq/select';
import {
  APIComponentAttributes,
  APIComponentType,
  APIEntityType,
  APIFieldAttributes,
  APIFieldType,
  APIReferenceAttributes,
  APIReferenceType,
  APITagAttributes,
  ComponentHistoryResult,
  FetchHistoryAPIResult,
  ComparableAPIEntityTypes,
} from '@ardoq/api-types';
import { sortBy, uniq } from 'lodash';
import {
  ComparableAPIEntityAttributes,
  getAllFieldNamesDifferentBetweenEntities,
  getCustomFieldNamesFromModelByEntityId,
  getFieldLabel,
  getModelIdFromReference,
  isDefaultField,
} from '@ardoq/renderers';
import { formatDateTime } from '@ardoq/date-time';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { Tag } from '@ardoq/status-ui';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { getCurrentLocale } from '@ardoq/locale';

const getFieldNamesToRenderInDiffTable = (
  currentEntityAttirbutes: ComparableAPIEntityAttributes,
  historyPointEntityAttributes: FetchHistoryAPIResult,
  entityType: ComparableAPIEntityTypes,
  enhancedScopeData: EnhancedScopeData
) => {
  const attrNames = getAllFieldNamesDifferentBetweenEntities(
    [
      currentEntityAttirbutes,
      historyPointEntityAttributes as ComparableAPIEntityAttributes,
    ],
    entityType
  );

  const customFieldNames = getCustomFieldNamesFromModelByEntityId(
    currentEntityAttirbutes._id,
    enhancedScopeData
  );

  return Object.keys(currentEntityAttirbutes).filter(
    fieldName =>
      attrNames.includes(fieldName) &&
      (isDefaultField(fieldName) || customFieldNames.includes(fieldName))
  );
};

export const getSelectVersionOptionsFromHistory = (
  history: FetchHistoryAPIResult[],
  currentEntityAttirbutes: ComparableAPIEntityAttributes | null,
  entityType: ComparableAPIEntityTypes,
  enhancedScopeData: EnhancedScopeData
): SelectOption<number>[] => {
  const locale = getCurrentLocale();

  if (!currentEntityAttirbutes) {
    return [];
  }
  return sortBy(history, '_version')
    .reverse()
    .map(historyPoint => {
      const attrNames = getFieldNamesToRenderInDiffTable(
        currentEntityAttirbutes,
        historyPoint as ComparableAPIEntityAttributes,
        entityType,
        enhancedScopeData
      );
      const hasDifferences = attrNames.length > 0;
      if (!hasDifferences) return null;

      const diffs = calculateDiffBetweenEntityAttributes(
        currentEntityAttirbutes,
        historyPoint,
        entityType,
        enhancedScopeData
      );

      const isIrreversible =
        diffs.length && diffs.find(diff => diff.revertBlockType === null);

      return {
        value: historyPoint._version,
        label: `Version ${historyPoint._version}`,
        description: `by ${historyPoint.lastModifiedByName} on ${formatDateTime(
          historyPoint.lastUpdated,
          locale
        )}`,
        rightContent: !isIrreversible ? <Tag label="Not reversible" /> : null,
      };
    })
    .filter(ExcludeFalsy);
};

export const getRevertBlockedType = (
  entityType: ComparableAPIEntityTypes,
  entityId: string,
  enhancedScopeData: EnhancedScopeData,
  fieldName: string,
  historicFieldValue: string | null
): RevertBlockedType | null => {
  if (getIsReadOnlyField(fieldName, enhancedScopeData.fields)) {
    return RevertBlockedType.READ_ONLY_FILED;
  }

  if (
    entityType === APIEntityType.COMPONENT &&
    fieldName === 'typeId' &&
    historicFieldValue !== null &&
    !findAPIComponentType({
      typeId: historicFieldValue,
      componentId: entityId,
      enhancedScopeData,
    })
  ) {
    return RevertBlockedType.COMPONENT_TYPE_DELETED;
  }

  if (
    entityType === APIEntityType.REFERENCE &&
    historicFieldValue !== null &&
    ['source', 'target'].includes(fieldName) &&
    !enhancedScopeData.componentsById[historicFieldValue]
  ) {
    return RevertBlockedType.COMPONENT_DELETED;
  }

  if (
    entityType === APIEntityType.COMPONENT &&
    historicFieldValue !== null &&
    fieldName === 'parent' &&
    !enhancedScopeData.componentsById[historicFieldValue]
  ) {
    return RevertBlockedType.COMPONENT_DELETED;
  }

  if (
    entityType === APIEntityType.REFERENCE &&
    historicFieldValue !== null &&
    fieldName === 'type' &&
    !findAPIReferenceType({
      typeId: historicFieldValue,
      referenceId: entityId,
      enhancedScopeData,
    })
  ) {
    return RevertBlockedType.REFERENCE_TYPE_DELETED;
  }

  const field = enhancedScopeData.fields.find(({ name }) => name === fieldName);
  if (
    historicFieldValue !== null &&
    (field?.type === APIFieldType.LIST ||
      field?.type === APIFieldType.SELECT_MULTIPLE_LIST)
  ) {
    const possibleFieldOptions = (field.defaultValue as string).split(',');
    const historicFieldValueAsArray = [historicFieldValue].flat();
    const historicOptionIsNotValid = Boolean(
      historicFieldValueAsArray.find(
        historicOption => !possibleFieldOptions.includes(historicOption)
      )
    );
    if (historicOptionIsNotValid) {
      return RevertBlockedType.SELECT_FIELD_OPTION_DELETED;
    }
  }

  return null;
};

export const revertBlockedTypeToMessage: Record<RevertBlockedType, string> = {
  [RevertBlockedType.READ_ONLY_FILED]: 'Cannot revert read only field.',
  [RevertBlockedType.COMPONENT_DELETED]: 'Component has been deleted.',
  [RevertBlockedType.SELECT_FIELD_OPTION_DELETED]:
    'The field option has been deleted.',
  [RevertBlockedType.COMPONENT_TYPE_DELETED]:
    'Component type has been deleted.',
  [RevertBlockedType.REFERENCE_TYPE_DELETED]:
    'Reference type has been deleted.',
};

export const calculateDiffBetweenEntityAttributes = (
  currentEntityAttirbutes: ComparableAPIEntityAttributes,
  historyPointEntityAttributes: FetchHistoryAPIResult,
  entityType: ComparableAPIEntityTypes,
  enhancedScopeData: EnhancedScopeData
): HistoryVersionTableRowData[] => {
  return getFieldNamesToRenderInDiffTable(
    currentEntityAttirbutes,
    historyPointEntityAttributes,
    entityType,
    enhancedScopeData
  ).map(
    (fieldName): HistoryVersionTableRowData => ({
      fieldName,
      label: getFieldLabel({
        fieldName,
        entityType,
        enhancedScopeData,
      }),
      currentValue:
        currentEntityAttirbutes[fieldName as keyof FetchHistoryAPIResult],
      revisionValue:
        historyPointEntityAttributes[fieldName as keyof FetchHistoryAPIResult],
      revertBlockType: getRevertBlockedType(
        entityType,
        currentEntityAttirbutes._id,
        enhancedScopeData,
        fieldName,
        historyPointEntityAttributes[fieldName as keyof FetchHistoryAPIResult]
      ),
    })
  );
};

export const getEntityName = (
  entityAttirbutes: ComparableAPIEntityAttributes,
  entityType: ComparableAPIEntityTypes
): string => {
  if (entityType === APIEntityType.COMPONENT) {
    return entityAttirbutes.name;
  }
  return entityType;
};

const getIsReadOnlyField = (
  fieldName: string,
  fields: APIFieldAttributes[]
) => {
  const field = fields.find(({ name }) => name === fieldName);
  return Boolean(field?.calculatedFieldSettings);
};

export const collectComponentIdsFromHistory = (
  historyResults: ComponentHistoryResult[] | APIReferenceAttributes[],
  entityType: ComparableAPIEntityTypes
) => {
  if (entityType === APIEntityType.COMPONENT)
    return collectComponentIdsFromComponentHistory(
      historyResults as ComponentHistoryResult[]
    );
  if (entityType === APIEntityType.REFERENCE)
    return collectComponentIdsFromReferenceHistory(
      historyResults as APIReferenceAttributes[]
    );
  return [];
};

const collectComponentIdsFromReferenceHistory = (
  historyResults: APIReferenceAttributes[]
): string[] =>
  uniq(historyResults.flatMap(x => [x.source, x.target]).filter(ExcludeFalsy));

const collectComponentIdsFromComponentHistory = (
  historyResults: ComponentHistoryResult[]
): string[] =>
  uniq(historyResults.flatMap(({ parent }) => parent).filter(ExcludeFalsy));

type FindAPIReferenceTypeParams = {
  typeId?: string;
  referenceId: string;
  enhancedScopeData: EnhancedScopeData;
};

export const findAPIReferenceType = ({
  typeId,
  referenceId,
  enhancedScopeData,
}: FindAPIReferenceTypeParams): APIReferenceType | null => {
  const modelId = getModelIdFromReference(
    enhancedScopeData.referencesById[referenceId],
    enhancedScopeData
  );
  return modelId && typeId
    ? enhancedScopeData.typesByModelId[modelId]?.referenceTypesById[typeId]
    : null;
};

type FindAPIComponentTypeParams = {
  typeId?: string;
  componentId: string;
  enhancedScopeData: EnhancedScopeData;
};

export const findAPIComponentType = ({
  typeId,
  componentId,
  enhancedScopeData,
}: FindAPIComponentTypeParams): APIComponentType | null => {
  if (!typeId) return null;
  const modelId = enhancedScopeData.componentsById[componentId].model;
  return (
    enhancedScopeData.typesByModelId[modelId].componentTypesById[typeId] || null
  );
};

export const getEntityAttributesFromEnhancedScopeData = ({
  entityId,
  entityType,
  enhancedScopeData,
}: UseEntityScopeProps & {
  enhancedScopeData: EnhancedScopeData;
}):
  | APIComponentAttributes
  | APIReferenceAttributes
  | APITagAttributes
  | null => {
  const entities = enhancedScopeData[
    `${entityType}s`
  ] as APIComponentAttributes[];
  return entities.find(({ _id }) => _id === entityId) || null;
};
