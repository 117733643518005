import { SingleValueEditorProps } from 'scopeData/editors/types';
import { ComponentShapeSelect } from '@ardoq/select';

const ShapeSelector = ({
  label,
  value,
  onValueChange,
  isDisabled,
  disabledMessage,
  description,
  dataClickId,
  placeholder,
}: SingleValueEditorProps) => {
  return (
    <ComponentShapeSelect
      label={label}
      value={value as string}
      onValueChange={onValueChange}
      isDisabled={isDisabled}
      helperText={isDisabled ? disabledMessage : description}
      clickId={dataClickId}
      placeholder={placeholder}
      isClearable
    />
  );
};

export default ShapeSelector;
