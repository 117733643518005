import styled, { css } from 'styled-components';

const Flex = styled.div<{
  $direction?: string;
  $justify?: string;
  $align?: string;
  $padding?: string;
  $margin?: string;
  $height?: string;
  $flexWrap?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `}
  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `}
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `} 
  ${({ $direction }) =>
    $direction &&
    css`
      flex-direction: ${$direction};
    `}
  ${({ $justify }) =>
    $justify &&
    css`
      justify-content: ${$justify};
    `}
  ${({ $align }) =>
    $align &&
    css`
      align-items: ${$align};
    `}
  ${({ $flexWrap }) =>
    $flexWrap &&
    css`
      flex-wrap: wrap;
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export default Flex;
