import { ArdoqId } from '@ardoq/api-types';
import { Workspace } from 'aqTypes';
import { actionCreator } from '@ardoq/rxbeach';
import { OpenEntityMergeParams } from './EntityHistoryModal/types';
import * as profiling from '@ardoq/profiling';
import { UserEvent } from './types';

export enum ForceReloadReason {
  CANNOT_CONNECT_WS = 'cannot_connect_ws',
  DEAD_CONNECTION_WS = 'dead_connection_ws',
}

/**
 * Indicate that a websocket connection has been estableshed. Triggered both on
 * initial connection and on successful reconnects.
 */
export const websocketConnected = actionCreator('[sync] websocket connected');

/**
 * Indicate that the user session has expired.
 * Typically triggered on 401 responses, or by websocket messages.
 */
export const sessionExpired = actionCreator('[sync] session expired');

/**
 * Indicate that a new user event has occured
 */

export const userEvent = actionCreator<UserEvent>('[sync] user event');

type LoadAggregateWorkspacesPayload = {
  workspaces: Workspace[];
  transaction: profiling.Transaction;
};

export const loadAggregateWorkspaces =
  actionCreator<LoadAggregateWorkspacesPayload>(
    '[sync] load aggregate workspaces'
  );

export const workspacesClosed = actionCreator<{
  openWorkspaceIds: ArdoqId[];
  closedWorkspaceIds: ArdoqId[];
}>('[sync] workspaces closed');

export const openEntityHistoryModal = actionCreator<
  Omit<OpenEntityMergeParams, 'versionsToFetchCount'>
>('[sync] open entity history modal');

type NotifySyncConflictPayload = {
  userId?: ArdoqId;
  meta: Record<string, string | number>;
};
export const notifySyncConflict = actionCreator<NotifySyncConflictPayload>(
  '[SyncManagerActions] SYNC_CONFLICT'
);
