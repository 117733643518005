import { IntegrationInitialState } from 'integrations/common/initialState';
import { buildInitialTermsState } from 'integrations/common/streams/integrationTermsDictionary/initialState';
import { UnifiedIntegrationParams } from 'integrations/unified/types';

export const initialState: IntegrationInitialState & UnifiedIntegrationParams =
  {
    activeIntegration: {
      integrationId: 'signavio-exporter',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'SAP Signavio',
      integrationOverviewTitle: 'SAP Signavio exporter',
      rightSidebarHeader: 'Having trouble with the SAP Signavio exporter?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/213357-signavio-exporter',
          text: 'How to export data.',
        },
      ],
      connectYourAccountTitle:
        'Connect your SAP Signavio account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to SAP Signavio',
      overviewPrimaryButton: 'Create new export',
    }),
    defaultAuthMethod: 'basic',
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          {
            keyPath: ['baseUrl'],
            label: 'SAP Signavio base URL',
            type: 'url',
            urlProtocol: 'https://',
            standardDomain: '.signavio.com',
            required: true,
          },
          {
            keyPath: ['accessKey'],
            label: 'SAP Signavio username',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['secretKey'],
            label: 'SAP Signavio password',
            type: 'password',
            required: true,
          },
          {
            keyPath: ['providerParams', 'tenant-id'],
            label: 'Workspace ID (or Tenant ID)',
            type: 'text',
            required: true,
          },
        ],
      },
    },
  };
