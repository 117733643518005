import fp from 'lodash/fp';
import { IntegrationTermsDictionary } from './types';

type RequiredFields =
  | 'name'
  | 'integrationOverviewTitle'
  | 'rightSidebarHeader'
  | 'rightSideBarFAQ'
  | 'connectYourAccountBtn'
  | 'connectYourAccountTitle';

const initialTermsDictionary: IntegrationTermsDictionary = {
  // these properties need to be overridden by each integration
  name: '',
  integrationOverviewTitle: '',
  rightSidebarHeader: '',
  connectYourAccountBtn: '',
  connectYourAccountTitle: '',
  rightSideBarFAQ: [],

  // these properties are common for every integration
  // override these if/when necessary
  tableHeader: 'Tables details',
  stepTitle: 'Table rows contain:',
  tabsTitle: 'Tables',
  selectWorkspace: 'Select workspace to import this table',
  selectOrCreateWorkspace: 'Select or create workspace to import this table',
  dryRunSummaryType: 'table',
  dryRunSummaryWarning: {
    import:
      'Review the warnings in your table details. You can continue your import but some of your data might be affected.',
    export:
      'Review the warnings in your table details. You can continue your export but some of your data might be affected.',
  },
  dryRunSummaryError: {
    import: 'Some data needs reconfiguration before it can be imported.',
    export: 'Some data needs reconfiguration before it can be exported.',
  },
  selectConnectionHelperText:
    "Select a connection to access your organization's data sets.",
  selectConnectionPlaceHolder: 'Select one instance',
  selectConfigHelperText:
    'Using a saved configuration will automatically populate the data selection and configuration of data.',
  noConfigsMessage: 'Create a new import to save a configuration.',
  noSchedulesMessage: 'Create a new import to set up a schedule.',
};

export const buildInitialTermsState = (
  integrationState: Partial<IntegrationTermsDictionary> &
    Required<Pick<IntegrationTermsDictionary, RequiredFields>>
): IntegrationTermsDictionary =>
  fp.merge(initialTermsDictionary, integrationState);
