// TODO figure out why @ardoq componentInterface doesn't work here?
//
// Tom's comment: @ardoq/component-interface needs to call
// registerImplementation for it to work first?I don't think
// it's used anywhere yet in ardoq-front
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  APIScopeData,
  ArdoqId,
  ComparableAPIEntityTypes,
} from '@ardoq/api-types';
import { mergeMultipleScopeData, readRawValue } from '@ardoq/renderers';
import { isArdoqError, sleep, toArdoqError } from '@ardoq/common-helpers';
import { dateRangeOperations } from '@ardoq/date-range';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { ApiResponse, componentApi, referenceApi, tagApi } from '@ardoq/api';

export const updateEntityData = async (
  entityId: ArdoqId,
  entityType: ComparableAPIEntityTypes,
  entityAttributesPartial:
    | Partial<APIComponentAttributes>
    | Partial<APIReferenceAttributes>,
  enhancedScopeData: EnhancedScopeData
) => {
  /**
   * Whats that?
   *
   * At the moment of writing the code an expected way to interact
   * with the components / references requires using interfaces.
   * In ardoq-front that works on in case of opened workspace(s)
   * because that's the only time when the collections are filled
   * with data. However if interfaces are used out of the workspace
   * we need to manually interact.
   */

  const workspaceId = readRawValue(
    entityType,
    entityId,
    'rootWorkspace',
    enhancedScopeData
  );
  // Before sending the data back to the API we need to split the date range fields back from the front-end
  // internal structure into the structure that the api understands
  const mappedEntityAttributes =
    (entityType === APIEntityType.COMPONENT ||
      entityType === APIEntityType.REFERENCE) &&
    workspaceId
      ? dateRangeOperations.splitFieldsOnEntity(
          entityAttributesPartial,
          workspaceId,
          enhancedScopeData
        )
      : entityAttributesPartial;

  if (entityType === APIEntityType.COMPONENT) {
    // The flag `isLoadedInApp` is checking if the entity is accessible
    // through interfaces or if it requires manual access.
    const isLoadedInApp = componentInterface.isComponent(entityId);
    if (isLoadedInApp) {
      componentInterface.setAttributes({
        ...(mappedEntityAttributes as APIComponentAttributes),
        _id: entityId,
      });
      // TODO: Would be good to have interfaces async where it make sense
      // so we can make a better UX. Now to keep things feeling "natural"
      // I am doing this sleep for 1s. It's not that bad time if we are
      // showing progress / loading spinner and it long enough
      // for API to complete most of the merge cases
      await sleep(1000);
    } else {
      const componentAttributes = await componentApi.fetch(entityId);
      if (isArdoqError(componentAttributes)) {
        return componentAttributes;
      }

      const updatedComponent = await componentApi.updateComponent({
        ...componentAttributes,
        ...(mappedEntityAttributes as APIComponentAttributes),
      });
      if (isArdoqError(updatedComponent)) {
        return updatedComponent;
      }
    }
  }
  if (entityType === APIEntityType.REFERENCE) {
    const isLoadedInApp = referenceInterface.isReference(entityId);
    if (isLoadedInApp) {
      referenceInterface.setAttributes({
        ...(mappedEntityAttributes as APIReferenceAttributes),
        _id: entityId,
      });
      // TODO: Would be good to have interfaces async where it make sense
      // so we can make a better UX. Now to keep things feeling "natural"
      // I am doing this sleep for 1s. It's not that bad time if we are
      // showing progress / loading spinner and it long enough
      // for API to complete most of the merge cases
      await sleep(1000);
    } else {
      const referenceAttributes = await referenceApi.fetch(entityId);
      if (isArdoqError(referenceAttributes)) {
        return referenceAttributes;
      }
      const updatedReference = await referenceApi.updateReference({
        ...referenceAttributes,
        ...(mappedEntityAttributes as APIReferenceAttributes),
      });
      if (isArdoqError(updatedReference)) {
        return updatedReference;
      }
    }
  }
};

type FetchEntityPartialScopeData = {
  entityIds: string[];
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE;
};

const fetchEntityPartialScopeData = async ({
  entityIds,
  entityType,
}: FetchEntityPartialScopeData): ApiResponse<APIScopeData> => {
  if (entityType === APIEntityType.COMPONENT) {
    return componentApi.scopedata(entityIds);
  } // entityType === APIEntityType.REFERENCE
  return referenceApi.scopedata(entityIds);
};

// TODO[ARD-22945] - apps/ardoq-front/src/js/ardoq/sync/EntityHistoryModal/useHistoryData.ts
export const fetchEntityScopeData = async ({
  entityIds,
  entityType,
}: {
  entityIds: ArdoqId[];
  entityType: ComparableAPIEntityTypes;
}): ApiResponse<APIScopeData> => {
  if (
    entityType === APIEntityType.COMPONENT ||
    entityType === APIEntityType.REFERENCE
  ) {
    const partialScopeData = await fetchEntityPartialScopeData({
      entityType,
      entityIds: entityIds,
    });

    if (isArdoqError(partialScopeData)) {
      return partialScopeData;
    }

    return mergeMultipleScopeData(partialScopeData);
  }
  if (entityType === APIEntityType.TAG) {
    const tags = await tagApi.fetchAll();

    if (isArdoqError(tags)) {
      return tags;
    }

    return mergeMultipleScopeData({ tags });
  }
  return toArdoqError({
    error: new Error(`Entity "${entityType}" is not supported`),
  });
};
