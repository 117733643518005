import { FilterAttributes } from '@ardoq/api-types';
import { DynamicFilterState } from '@ardoq/data-model';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  GridEditorNotifyFiltersChangePayload,
  notifyGridEditorFiltersLoaded,
} from '../actions';

export type DynamicAndGlobalFiltersState = {
  dynamicFilterStates: DynamicFilterState[];
  globalFilterAttributes: FilterAttributes[];
};

const getInitialState = (): DynamicAndGlobalFiltersState => {
  return { dynamicFilterStates: [], globalFilterAttributes: [] };
};

const payloadToState = (
  payload: GridEditorNotifyFiltersChangePayload
): DynamicAndGlobalFiltersState => ({
  dynamicFilterStates: payload.dynamicFilterStates,
  globalFilterAttributes: payload.filters,
});

const stateToPayload = (
  state: DynamicAndGlobalFiltersState
): GridEditorNotifyFiltersChangePayload => ({
  dynamicFilterStates: state.dynamicFilterStates,
  filters: state.globalFilterAttributes,
});

const initialState = getInitialState();

const overwriteDynamicAndGlobalFilters = (
  _currentState: DynamicAndGlobalFiltersState,
  payload: GridEditorNotifyFiltersChangePayload
): DynamicAndGlobalFiltersState => {
  return payloadToState(payload);
};

/**
 * The GridEditor cannot edit/update filters, so we only need to listen for changes
 * from ardoq-front and update the state.
 * WARNING: Can only be used by standalone GridEditor window.
 *
 * Persistent because the bridge opens and emits changes before react is mounted,
 * so lazily subscribed components will miss the initial state.
 * TODO sseppola: Open bridge after mounting React
 */
export const dynamicAndGlobalFilters$ = persistentReducedStream(
  'gridEditor2023/dynamicAndGlobalFilters$',
  initialState,
  [reducer(notifyGridEditorFiltersLoaded, overwriteDynamicAndGlobalFilters)]
);

export const dynamicAndGlobalFiltersOperations = {
  getInitialState,
  stateToPayload,
  payloadToState,
};
