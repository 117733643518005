import { createElement } from 'react';
import {
  APIEntityType,
  APIReferenceType,
  ArdoqId,
  ArrowType,
  LineType,
} from '@ardoq/api-types';
import {
  FieldType,
  getArrowTypeLabel,
  getEntityById,
  getFieldLabel,
  getLineTypeLabel,
  readRawValue,
  referenceTypeAttributesMap,
} from '@ardoq/renderers';
import {
  customReferenceTypeAttributeNames,
  defaultReferenceTypeAttributeNames,
} from 'appModelStateEdit/propertiesEditor/referenceTypePropertiesEditor/consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { ReferenceTypePreview } from '@ardoq/renderers';
import { SelectOption } from '@ardoq/select';
import { getValidationErrorMessages } from 'scopeData/editors/validators';
import { DirtyAttributes } from 'appModelStateEdit/types';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { EditorProperty } from '../types';

const isCustomReferenceTypePropertyDisabled = (
  referenceType: APIReferenceType,
  attributeName: string
) =>
  referenceType.hasCardinality &&
  ['lineBeginning', 'lineEnding'].includes(attributeName);

export const getDefaultProperties = (
  referenceTypeId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  model: ArdoqId,
  dirtyAttributes: DirtyAttributes
): EditorProperty[] =>
  defaultReferenceTypeAttributeNames
    .map(attributeName => {
      const type = referenceTypeAttributesMap.get(attributeName);
      if (!type) {
        return null;
      }
      const label = getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.REFERENCE_TYPE,
      });
      const value = readRawValue(
        APIEntityType.REFERENCE_TYPE,
        referenceTypeId,
        attributeName,
        enhancedScopeData,
        model
      );
      const isDirty = dirtyAttributes.has(attributeName);
      const errorMessages = getValidationErrorMessages(type, value);
      return {
        type,
        name: attributeName,
        label,
        value,
        isDirty,
        errorMessages,
      };
    })
    .filter(ExcludeFalsy);

export const getCustomProperties = (
  referenceTypeId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  model: ArdoqId,
  dirtyAttributes: DirtyAttributes
): EditorProperty[] => {
  const referenceType = getEntityById(
    APIEntityType.REFERENCE_TYPE,
    referenceTypeId,
    enhancedScopeData,
    model
  );
  if (!referenceType) {
    return [];
  }
  return customReferenceTypeAttributeNames.map(attributeName => {
    const type = referenceTypeAttributesMap.get(attributeName)!;
    const value = readRawValue(
      APIEntityType.REFERENCE_TYPE,
      referenceTypeId,
      attributeName,
      enhancedScopeData,
      model
    );
    const isDirty = dirtyAttributes.has(attributeName);
    const errorMessages = getValidationErrorMessages(type, value);
    return {
      type,
      name: attributeName,
      label: getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.REFERENCE_TYPE,
      }),
      value,
      isDirty,
      errorMessages,
      options: customPropertyOptionGetterMap.get(type)?.(referenceType),
      isDisabled: isCustomReferenceTypePropertyDisabled(
        referenceType,
        attributeName
      ),
    };
  });
};

const getLineTypeOptions = ({
  color,
}: APIReferenceType): SelectOption<LineType>[] =>
  Object.values(LineType).map(line => {
    return {
      value: line,
      label: getLineTypeLabel(line),
      leftContent: createElement(ReferenceTypePreview, {
        width: 65,
        height: 24,
        line,
        color,
      }),
    };
  });

const getLineBeginningOptions = ({
  color,
  line,
}: APIReferenceType): SelectOption<ArrowType>[] =>
  [
    ArrowType.NONE,
    ArrowType.BOTH_START,
    ArrowType.DIAMOND_START,
    ArrowType.DIAMOND_FILLED_START,
    ArrowType.TOP_START,
    ArrowType.BOTTOM_START,
    ArrowType.BOTH_FILLED_START,
    ArrowType.BOTH_OUTLINED_START,
    ArrowType.CIRCLE_START,
    ArrowType.ONE_START,
    ArrowType.ONE_MANDATORY_START,
    ArrowType.MANY_START,
    ArrowType.ONE_OR_MANY_START,
    ArrowType.ZERO_OR_ONE_START,
    ArrowType.ZERO_OR_MANY_START,
    ArrowType.DEFAULT_START,
  ].map(arrowType => {
    return {
      value: arrowType,
      label: getArrowTypeLabel(arrowType),
      leftContent: createElement(ReferenceTypePreview, {
        width: 65,
        height: 24,
        line,
        color,
        lineBeginning: arrowType,
      }),
    };
  });

const getLineEndingOptions = ({
  color,
  line,
}: APIReferenceType): SelectOption<ArrowType>[] =>
  [
    ArrowType.NONE,
    ArrowType.BOTH,
    ArrowType.DIAMOND,
    ArrowType.DIAMOND_FILLED,
    ArrowType.TOP,
    ArrowType.BOTTOM,
    ArrowType.BOTH_FILLED,
    ArrowType.BOTH_OUTLINED,
    ArrowType.CIRCLE,
    ArrowType.ONE,
    ArrowType.ONE_MANDATORY,
    ArrowType.MANY,
    ArrowType.ONE_OR_MANY,
    ArrowType.ZERO_OR_ONE,
    ArrowType.ZERO_OR_MANY,
  ].map(arrowType => {
    return {
      value: arrowType,
      label: getArrowTypeLabel(arrowType),
      leftContent: createElement(ReferenceTypePreview, {
        width: 65,
        height: 24,
        line,
        color,
        lineEnding: arrowType,
      }),
    };
  });

const customPropertyOptionGetterMap = new Map<
  FieldType,
  (referenceType: APIReferenceType) => SelectOption<LineType | ArrowType>[]
>([
  [FieldType.REFERENCE_TYPE_LINE, getLineTypeOptions],
  [FieldType.REFERENCE_TYPE_LINE_BEGINNING, getLineBeginningOptions],
  [FieldType.REFERENCE_TYPE_LINE_ENDING, getLineEndingOptions],
]);
