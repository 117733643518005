import { derivedStream } from '@ardoq/rxbeach';
import { filter, map } from 'rxjs/operators';
import type { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import globalFields$ from 'globalFields/globalFields$';
import { GlobalFieldsStreamShape } from 'globalFields/types';
import { ScenarioModeState } from 'scope/types';
import { APIEntityType } from '@ardoq/api-types';
import { fieldOptionOps } from 'models/utils/fieldOptionOps';
import { getAllFieldsOfEntity } from '@ardoq/scope-data';
import type { EnhancedScopeData } from '@ardoq/data-model';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

type PopulatedAppModelStateEditStream = Omit<
  AppModelStateEditStreamShape,
  'entityIDs' | 'entityType' | 'enhancedScopeData'
> & {
  readonly entityIDs: string[];
  readonly entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE;
  readonly enhancedScopeData: EnhancedScopeData;
};

const isApplicableToCurrentEntity = (
  data: [
    GlobalFieldsStreamShape,
    AppModelStateEditStreamShape,
    ScenarioModeState,
  ]
): data is [
  GlobalFieldsStreamShape,
  PopulatedAppModelStateEditStream,
  ScenarioModeState,
] => {
  const [, { entityIDs, entityType, enhancedScopeData }] = data;
  return (
    !!enhancedScopeData &&
    !!entityIDs?.length &&
    !!entityType &&
    [APIEntityType.COMPONENT, APIEntityType.REFERENCE].includes(entityType)
  );
};

const toAddFieldToEntityStream = ([
  { globalFields },
  { entityIDs, entityType, enhancedScopeData },
  activeScenarioState,
]: [
  GlobalFieldsStreamShape,
  PopulatedAppModelStateEditStream,
  ScenarioModeState,
]) => {
  const usedFieldNames = new Set(
    entityIDs.flatMap(entityID =>
      getAllFieldsOfEntity(entityID, entityType, enhancedScopeData)
    )
  );
  const fields = globalFields.filter(field => !usedFieldNames.has(field.name));

  return fieldOptionOps.fieldsToSortedCreatableListProps({
    fields,
    isScenarioMode:
      activeScenarioOperations.isInScenarioMode(activeScenarioState),
  });
};

const addFieldToEntity$ = derivedStream(
  'addFieldToEntity$',
  globalFields$,
  appModelStateEdit$,
  activeScenario$
).pipe(filter(isApplicableToCurrentEntity), map(toAddFieldToEntityStream));

export default addFieldToEntity$;
