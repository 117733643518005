import { DataSource, MergeState } from './types';

export const getStatusFromChildren = (
  children: DataSource,
  status: MergeState
) => {
  let hasChildWithStatus, isPartial, hasChildWithMergeStateNone;
  children.forEach(child => {
    if (child.status === status) {
      hasChildWithStatus = true;
    } else if (child.status === MergeState.PARTIAL) {
      isPartial = true;
    } else {
      hasChildWithMergeStateNone = true;
    }
  });
  if (isPartial || (hasChildWithStatus && hasChildWithMergeStateNone)) {
    return MergeState.PARTIAL;
  } else if (hasChildWithStatus) {
    return status;
  }
  return MergeState.NONE;
};
