import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { ArdoqId, GroupingRule, FilterAttributes } from '@ardoq/api-types';
import { DynamicPerspectiveFilter } from '@ardoq/perspectives';
import { Filter, PerspectiveBackboneModel } from 'aqTypes';
import Perspectives from 'collections/perspectives';
import currentUser from 'models/currentUser';
import { omit } from 'lodash';
import { backboneFilterToFilterAttributes } from 'collections/filterUtils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import clearPerspectives from 'collections/helpers/clearPerspectives';
import loadPerspective from 'collections/helpers/loadPerspective';

type SavedPerspectiveFiltersWithBackboneContext = {
  /**
   * for loaded saved perspective we have FilterAttributes[], but once we save it, it becomes aware of Backbone context and switches to Filter[]
   */
  advancedFilters: FilterAttributes[] | Filter[];
  /**
   * for loaded saved perspective we have FilterAttributes, but once we save it, it becomes aware of Backbone context and switches to Filter
   */
  workspaceFilter: null | FilterAttributes | Filter;
};

export type SavedPerspectiveWithBackboneContext = {
  description: string | null;
  _id: ArdoqId;
  lastModifiedBy: ArdoqId;
  workspace: ArdoqId;
  createdBy: ArdoqId;
  lastModifiedByName: string;
  filters: SavedPerspectiveFiltersWithBackboneContext;
  name: string;
  createdByEmail: string;
  created: string;
  dynamicFilters: DynamicPerspectiveFilter[];
  groupBys: GroupingRule[];
  lastUpdated: string;
  lastModifiedByEmail: string;
  ardoqPersistent: {
    useCase: string;
  } | null;
  _version: number;
  ardoq: {
    entityType: 'perspective';
  };
  createdByName: string;
};

type SavedPerspectiveFilters = {
  advancedFilters: FilterAttributes[];
  workspaceFilter: null | FilterAttributes;
};

export type SavedPerspective = Omit<
  SavedPerspectiveWithBackboneContext,
  'filters'
> & { filters: SavedPerspectiveFilters };

type SavedPerspectivePermissions = {
  create: boolean;
  delete: boolean;
  update: boolean;
};

export type SavedPerspectivesPermissions = Map<
  string,
  SavedPerspectivePermissions
>;

const getAll = (): SavedPerspective[] => {
  return Perspectives.map(perspective => removeBackboneContext(perspective));
};

const getById = (perspectiveId: string): SavedPerspective | null => {
  const savedPerspective = Perspectives.get(perspectiveId);
  return savedPerspective ? removeBackboneContext(savedPerspective) : null;
};

const removeBackboneContext = (
  perspective: PerspectiveBackboneModel
): SavedPerspective => {
  const savedPerspectiveAttributes: SavedPerspectiveWithBackboneContext =
    perspective.attributes;
  const basicFields = omit(savedPerspectiveAttributes, 'filters');

  const { filters } = savedPerspectiveAttributes;

  return {
    ...basicFields,
    filters: {
      workspaceFilter: removeBackboneContextFromBackboneFilter(
        filters.workspaceFilter
      ),
      advancedFilters: filters.advancedFilters
        .map(removeBackboneContextFromBackboneFilter)
        .filter(ExcludeFalsy),
    },
  };
};

const isFilterIsBackboneFilter = (
  filter: FilterAttributes | Filter
): filter is Filter => {
  return 'attributes' in filter;
};

const removeBackboneContextFromBackboneFilter = (
  filter: FilterAttributes | Filter | null
): FilterAttributes | null => {
  if (!filter) {
    return null;
  }
  if (isFilterIsBackboneFilter(filter)) {
    return backboneFilterToFilterAttributes(filter);
  }

  if (filter.rules) {
    // it happens that the first level of filter rules is of type FilterAttributes, but the nested level is of type BackboneFilter
    filter.rules = filter.rules
      .map(removeBackboneContextFromBackboneFilter)
      .filter(ExcludeFalsy);
  }
  return filter;
};

const removePerspective = (perspectiveId: string) => {
  const perspectiveToDelete = Perspectives.get(perspectiveId); // // TODO, AL will add a check if id exists or else throw error
  return perspectiveToDelete?.destroy({
    wait: true,
  });
};

const getSavedPerspectivesPermissions = () => {
  const permissions = getAll().map(perspective => {
    const perspectivePermissions =
      getSavedPerspectivesPermissionsForPerspective(perspective._id);
    return [perspective._id, perspectivePermissions] as const;
  });

  return new Map(permissions);
};

const getSavedPerspectivesPermissionsForPerspective = (
  savedPerspectiveId: string
) => {
  const isOrgAdmin = isCurrentUserOrgAdmin();
  const isOrgWriter = isCurrentUserOrgWriter();
  const isOwnerOfThePerspective =
    isCurrentUserAnOwnerOfPerspective(savedPerspectiveId);

  return {
    create: isOrgAdmin || isOrgWriter,
    delete: isOrgAdmin || (isOwnerOfThePerspective && isOrgWriter),
    update: isOrgAdmin || (isOwnerOfThePerspective && isOrgWriter),
  };
};

const isCurrentUserOrgAdmin = () => {
  return currentUser.isOrgAdmin();
};

const isCurrentUserOrgWriter = () => {
  return workspaceInterface.hasWriteAccess(
    workspaceInterface.getCurrentWsId() as string
  );
};

const hasUserPermissionToSavePerspectives = (): boolean => {
  return isCurrentUserOrgAdmin() || isCurrentUserOrgWriter();
};

const isCurrentUserAnOwnerOfPerspective = (perspectiveId: string) => {
  const createdUserId = Perspectives.get(perspectiveId)?.attributes.createdBy;
  const currentUserId = currentUser.id;
  return currentUserId ? createdUserId === currentUserId : false;
};

const loadSavedPerspective = async (savedPerspectiveId: string) => {
  const selectedSavedPerspective = Perspectives.get(savedPerspectiveId); // TODO, AL will add a check if id exists or else throw error
  await loadPerspective(selectedSavedPerspective);
};

const getSelectedSavedPerspectiveId = () => {
  return Perspectives.getCurrentSet()?.id;
};

const isSavedPerspectiveSelected = () => {
  return Boolean(Perspectives.getCurrentSet()?.id);
};

const saveExistingPerspective = (_: ArdoqId) => {
  /** TODO
   * We have proposed an idea to modify the update perspective function which would use the selectedPerspectiveId,
   * but currently we are not focusing on doing that and going with existing function
   * however we still want to pass the perspectiveId here and to resolve the lint error AL has used _
   */
  return Perspectives.updateCurrentPerspective();
};

/*
  It saves formatting, grouping and filtering rules to currently applied perspective,
  AM proposed to rewrite this method in the future, but for now we can at least explain the intention
*/
const saveAsNewPerspective = (perspectiveName: string) => {
  return Perspectives.createFromCurrentModifiers({
    name: perspectiveName,
    workspaceId: workspaceInterface.getCurrentWsId() as string,
    setAsCurrent: true,
  });
};

const createNewPerspective = (perspectiveName: string) => {
  return Perspectives.createFromCurrentModifiers({
    name: perspectiveName,
    workspaceId: workspaceInterface.getCurrentWsId() as string,
    setAsCurrent: true,
  });
};

const renameSavedPerspective = (
  newSavedPerspectiveName: string,
  selectedSavedPerspectiveId: string
) => {
  const savedPerspectiveToBeRenamed = Perspectives.get(
    selectedSavedPerspectiveId
  );
  if (!savedPerspectiveToBeRenamed) {
    throw new Error(
      'Perspective does not exist, perhaps someone else has deleted it before you.'
    );
  }
  return savedPerspectiveToBeRenamed.save(
    { name: newSavedPerspectiveName },
    { wait: true }
  );
};

const isCurrentSetModified = () => Perspectives.isCurrentSetModified();

export const perspectiveInterface = {
  getAll,
  getById,
  clearPerspectives: () =>
    clearPerspectives({ shouldTriggerChangeEvent: true }),
  removePerspective,
  getSavedPerspectivesPermissions,
  hasUserPermissionToSavePerspectives,
  isCurrentUserAnOwnerOfPerspective,
  loadSavedPerspective,
  getSelectedSavedPerspectiveId,
  isSavedPerspectiveSelected,
  saveExistingPerspective,
  saveAsNewPerspective,
  createNewPerspective,
  renameSavedPerspective,
  isCurrentSetModified,
  getSavedPerspectivesPermissionsForPerspective,
};
