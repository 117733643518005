export const defaultComponentAttributeNames = [
  'name',
  'description',
  'parent',
  'typeId',
];
export const defaultMultiComponentAttributeNames = ['parent', 'typeId'];
export const propertiesNotEditableAcrossMultipleWorkspaces = [
  'parent',
  'typeId',
];
