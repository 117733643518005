import {
  APIComponentAttributes,
  APIEntityType,
  APIModelAttributes,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { AttributeKey } from '@ardoq/data-model';
import { ModelSaveOptions } from 'backbone';
import { getCollectionForEntityType } from 'collectionInterface/utils';

export type StringKeys<T> = Extract<keyof T, string>;

export interface AugmentedModel<T> extends Backbone.Model {
  attributes: StringKeys<T>;
}

type AttributesGetter<Attributes> = <Key extends StringKeys<Attributes>>(
  modelId: ArdoqId,
  attributes: Key[]
) => Pick<Attributes, Key>;

type GetAttributesOfModel = <Attributes>(
  collection: Backbone.Collection<AugmentedModel<Attributes>>,
  keyMap?: Map<StringKeys<Attributes>, StringKeys<Attributes>>
) => AttributesGetter<Attributes>;

type GetCustomAttributesOfModel = (
  collection: Backbone.Collection<AugmentedModel<any>>,
  defaultAttributes: Set<string>
) => (modelId: ArdoqId, attributes: AttributeKey[]) => any;

export const getAttributesOfModel: GetAttributesOfModel =
  <Attributes>(
    collection: Backbone.Collection<AugmentedModel<Attributes>>,
    keyMap: Map<StringKeys<Attributes>, StringKeys<Attributes>> = new Map()
  ) =>
  <Key extends StringKeys<Attributes>>(modelId: ArdoqId, attributes: Key[]) => {
    const instance = collection.get(modelId);
    return Object.fromEntries(
      attributes.map((key: Key) => [
        key,
        instance ? instance.get(keyMap.get(key) || key) : undefined,
      ])
    ) as Pick<Attributes, Key>;
  };

export const getAttributeOfModel =
  <Attributes>(
    collection: Backbone.Collection<AugmentedModel<Attributes>>,
    keyMap: Map<StringKeys<Attributes>, StringKeys<Attributes>> = new Map()
  ) =>
  <Key extends StringKeys<Attributes>>(
    modelId: ArdoqId,
    key: Key
  ): Attributes[Key] =>
    collection.get(modelId)?.get(keyMap.get(key) || key);

export const getCustomAttributesOfModel: GetCustomAttributesOfModel =
  (
    collection: Backbone.Collection<AugmentedModel<any>>,
    defaultAttributes: Set<string>
  ) =>
  (modelId: ArdoqId, attributes: AttributeKey[]) => {
    if (attributes.some(key => defaultAttributes.has(key))) {
      // eslint-disable-next-line no-console
      console.warn(
        `Don't use getCustomAttributesOfModel to read default attributes`
      );
    }
    const instance = collection.get(modelId);
    return Object.fromEntries(
      attributes
        .filter((key: AttributeKey) => !defaultAttributes.has(key))
        .map((key: AttributeKey) => [
          key,
          instance ? instance.get(key) : undefined,
        ])
    );
  };

export const save = (
  entityType: APIEntityType,
  id: ArdoqId,
  attributes?: Partial<
    APIComponentAttributes | APIReferenceAttributes | APIModelAttributes
  >,
  options?: ModelSaveOptions
) => getCollectionForEntityType(entityType).get(id)?.save(attributes, options);

export const create = (
  entityType: APIEntityType,
  attributes: Partial<APIComponentAttributes | APIReferenceAttributes>,
  options?: ModelSaveOptions
) => getCollectionForEntityType(entityType).create(attributes, options);
