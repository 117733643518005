import { ArdoqId } from '@ardoq/api-types';
import {
  FieldType,
  componentTypeCanHaveChildren,
  componentTypesHaveParentChildRelationship,
  getPossibleComponentTypes,
  hasChildren,
} from '@ardoq/renderers';
import { componentUtils } from '@ardoq/scope-data';
import { getFieldOptions } from 'appModelStateEdit/propertiesEditor/fieldUtils';
import { RIGID_TYPE_WARNING_MSG } from 'models/consts';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { PermissionContext } from '@ardoq/access-control';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { SubdivisionsContext } from '@ardoq/subdivisions';

const getPossibleParents = (
  componentId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const component = enhancedScopeData.componentsById[componentId];
  const workspaceComponents = enhancedScopeData.components.filter(
    ({ rootWorkspace }) => rootWorkspace === component.rootWorkspace
  );
  const componentDescendantIds = componentUtils.getDescendantIds(
    enhancedScopeData,
    componentId
  );
  const isFlexible = enhancedScopeData.modelsById[component.model].flexible;
  return workspaceComponents.filter(comp => {
    if (comp._id === component._id) {
      return false;
    }
    if (componentDescendantIds.includes(comp._id)) {
      return false;
    }
    if (isFlexible) {
      return true;
    }
    if (
      componentDescendantIds.length === 0 &&
      componentTypeCanHaveChildren(
        comp.typeId,
        component.model,
        enhancedScopeData
      )
    ) {
      return true;
    }
    return componentTypesHaveParentChildRelationship(
      comp.typeId,
      component.typeId,
      component.model,
      enhancedScopeData
    );
  });
};

const getParentOptions = (
  componentId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
) => {
  const scenarioId = getActiveScenarioId();
  const possibleParents = getPossibleParents(componentId, enhancedScopeData);
  const validParentComponents = scenarioId
    ? possibleParents
    : possibleParents.filter(component =>
        componentAccessControlOperation.canEditComponent({
          component,
          permissionContext,
          subdivisionsContext,
        })
      );
  return [
    {
      value: null,
      label: 'None',
    },
    ...validParentComponents.map(({ _id: value, name: label }) => ({
      value,
      label,
    })),
  ];
};

const getTypeOptions = (
  componentId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const component = enhancedScopeData.componentsById[componentId];
  const isFlexible =
    enhancedScopeData.modelsById[component.model].flexible ||
    !hasChildren(component._id, enhancedScopeData);
  if (!isFlexible) {
    return [
      {
        value: component.typeId,
        label: RIGID_TYPE_WARNING_MSG,
      },
    ];
  }
  return getPossibleComponentTypes(componentId, enhancedScopeData).map(
    type => ({
      value: type.id,
      label: type.name,
    })
  );
};

export const defaultPropertyOptionGetterMap = new Map([
  [FieldType.PARENT, getParentOptions],
  [FieldType.TYPE_ID_COMPONENT, getTypeOptions],
]);

export const customPropertyOptionGetterMap = new Map([
  [FieldType.LIST, getFieldOptions],
  [FieldType.SELECT_MULTIPLE_LIST, getFieldOptions],
]);
