import DialogBodyBlock from './DialogBodyBlock';
import { showSupport } from 'utils/support';
import { ModalStep } from './types';
import { PseudoLink } from './atoms';
import {
  ArdoqError,
  getArdoqErrorMessage,
  getArdoqErrorTraceId,
} from '@ardoq/common-helpers';
import { SecondaryButton } from '@ardoq/button';
import { ModalBody, ModalFooter, ModalHeader } from '@ardoq/modal';
import { Paragraph } from '@ardoq/typography';

const getErrorTitleText = (step: ModalStep) => {
  if (step === ModalStep.PRELOADING_FAILED) {
    return 'Loading failed';
  } else if (step === ModalStep.COMPONENTS_NUMBER_EXCEEDED) {
    return 'Components number exceeded';
  } else if (step === ModalStep.UPDATING_SCENARIO_FAILED) {
    return 'Adding items to scenario failed';
  } else if (step === ModalStep.CREATING_SCENARIO_FAILED) {
    return 'Creating scenario failed';
  }
  return 'Scenario operation failed';
};

type ErrorStepProps = {
  step: ModalStep;
  onCancel: () => void;
  error: ArdoqError;
};

const getErrorBodyText = (modalStep: ModalStep, apiError: ArdoqError) => {
  if (modalStep === ModalStep.COMPONENTS_NUMBER_EXCEEDED) {
    return (
      <>
        <p>
          The scenario cannot be created from this search result, since it
          exceeds 1000 components.
        </p>
        <p>
          Scenarios are designed to allow you to work and make changes to a
          small subset of your data.
        </p>
      </>
    );
  }

  const errorMessage = getArdoqErrorMessage(apiError);
  const traceId = getArdoqErrorTraceId(apiError);

  return (
    <>
      <p>{errorMessage}</p>
      <p>
        <PseudoLink onClick={() => showSupport()}>
          {traceId
            ? `Please contact support. Trace ID: ${traceId}`
            : 'Please contact support.'}
        </PseudoLink>
      </p>
    </>
  );
};

const ErrorStep = ({ onCancel, step, error }: ErrorStepProps) => {
  if (
    [
      ModalStep.PRELOADING_FAILED,
      ModalStep.CREATING_SCENARIO_FAILED,
      ModalStep.UPDATING_SCENARIO_FAILED,
      ModalStep.COMPONENTS_NUMBER_EXCEEDED,
    ].includes(step)
  ) {
    return (
      <>
        <ModalHeader
          title={getErrorTitleText(step)}
          onCloseButtonClick={onCancel}
        />
        <ModalBody>
          <DialogBodyBlock>
            <Paragraph variant="text1">
              {getErrorBodyText(step, error)}
            </Paragraph>
          </DialogBodyBlock>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Close</SecondaryButton>
        </ModalFooter>
      </>
    );
  }
  return null;
};

export default ErrorStep;
