import styled from 'styled-components';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import {
  IntegrationWorkspace,
  TableMappingType,
} from '@ardoq/api-types/integrations';
import { WorkspaceName } from '../workspaceName/WorkspaceName';

const Capitalized = styled.div`
  text-transform: capitalize;
`;

type DataSourceItem = {
  name: string;
  id?: string;
  rowRepresentation: TableMappingType;
  workspace: IntegrationWorkspace;
  isWorkspaceAvailable: boolean;
};

export const ExpandedRowMapping = ({
  dataSource,
  onWorkspaceClick,
}: {
  dataSource: DataSourceItem[];
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
}) => {
  const hasId = dataSource.every(item => 'id' in item);

  return (
    <DatasourceTable
      dataSource={dataSource}
      components={newTableTheme}
      columns={[
        {
          title: hasId ? 'Tables (ID)' : 'Tables',
          valueRender: hasId
            ? (_, { name, id }) => (
                <div>
                  {name} ({id})
                </div>
              )
            : (_, { name }) => <div>{name}</div>,
        },
        {
          dataIndex: 'rowRepresentation',
          title: 'Mapped to',
          valueRender: rowRepresentation => (
            <Capitalized>{rowRepresentation}</Capitalized>
          ),
        },
        {
          dataIndex: 'workspace',
          title: 'Workspace',
          valueRender: (
            workspace: IntegrationWorkspace,
            { isWorkspaceAvailable }
          ) => (
            <WorkspaceName
              isWorkspaceAvailable={isWorkspaceAvailable}
              workspace={workspace}
              onWorkspaceClick={onWorkspaceClick}
            />
          ),
        },
      ]}
    />
  );
};
