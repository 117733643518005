import { api, handleError, permissionApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import {
  ActorPermissionDataSource,
  actorPermissionOps,
  BasicResource,
} from '@ardoq/manage-resource-permissions';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { showToast, ToastType } from '@ardoq/status-ui';
import { of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { apiFetchCurrentUserPermissions } from 'streams/currentUserPermissions/actions';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { addResourcesToSubdivision } from 'streams/subdivisions/actions';
import {
  apiPutResourcePermission,
  openResourcePermissionDialog,
  resourcePermissionDialogClosed,
  resourcePermissionDialogOpened,
  resourcePermissionError,
  saveResourcePermissions,
  setResourcePermissions,
} from './actions';
import { manageResourcePermissionContext$ } from './manageResourcePermissionContext$';
import {
  openManageResourcePermissionsDialog,
  openNewManageResourcePermissionsDialog,
} from './openResourcePermissionsDialog';
import resourcePermissions$ from './resourcePermissions$';
import {
  createGetActorsOptions,
  createGetCurrentUserCanEditResource,
  fetchAndWaitForResourcePermissionData,
  getResourcePathName,
} from './routineUtils';
import {
  ResourcePermissionsState,
  ResourcesPermissionEditorEvents,
} from './types';
import {
  changeAwarePermissionUpdatesTracker,
  trackResourcesPermissionEditorEvent,
} from './utils';
import { initializeResourcePermissionViewModel } from './viewModel/actions';

const handleApiPutResourcePermissions = routine(
  ofType(apiPutResourcePermission),
  extractPayload(),
  withLatestFrom(orgUsers$),
  tap(changeAwarePermissionUpdatesTracker),
  switchMap(([{ permissionsUpdates }]) =>
    permissionApi.updateMultipleResourcePermissions(permissionsUpdates)
  ),
  handleError(error => {
    const defaultMessage = 'An error occurred while updating the permissions';
    const errorMessage = api.logErrorIfNeededAndReturnMessage(
      error,
      defaultMessage
    );
    dispatchAction(resourcePermissionError(errorMessage));
    showToast(errorMessage, ToastType.INFO);
  }),
  tap(responses => {
    dispatchAction(setResourcePermissions(responses));
    dispatchAction(apiFetchCurrentUserPermissions());
  })
);

const handleOpenResourcePermissionDialog = routine(
  ofType(openResourcePermissionDialog),
  extractPayload(),
  fetchAndWaitForResourcePermissionData,
  handleError(error => {
    const defaultMessage = 'An error occurred while updating the permissions';
    const errorMessage = api.logErrorIfNeededAndReturnMessage(
      error,
      defaultMessage
    );
    dispatchAction(resourcePermissionError(errorMessage));
    showToast(errorMessage, ToastType.INFO);
  }),
  getResourcePathName,
  map(payload => {
    dispatchAction(resourcePermissionDialogOpened());
    // If the new permissions editor is enabled, we dispatch an action to open the new dialog instead.
    // For now it only works with single resource.
    if (payload.resources.length !== 1) {
      trackResourcesPermissionEditorEvent(
        ResourcesPermissionEditorEvents.OPENED_OLD_RESOURCE_PERMISSIONS_EDITOR,
        {
          resourceType: payload.resources[0].resourceType,
          resourcesCount: payload.resources.length,
        }
      );
      openManageResourcePermissionsDialog(payload);
      return;
    }
    return payload;
  }),
  // Use the new dialog instead of the old one
  filter(Boolean),
  withLatestFrom(manageResourcePermissionContext$),
  tap(async ([{ resources, headerText, originPage }, context]) => {
    // Initialize the view model
    dispatchAction(
      initializeResourcePermissionViewModel({
        ...context,
        resources,
        ...createGetActorsOptions(context),
        ...createGetCurrentUserCanEditResource(context),
      })
    );
    trackResourcesPermissionEditorEvent(
      ResourcesPermissionEditorEvents.OPENED_NEW_RESOURCE_PERMISSIONS_EDITOR,
      {
        resourceType: resources[0].resourceType,
        resourcesCount: resources.length,
        originPage,
      }
    );
    const isSaved = await openNewManageResourcePermissionsDialog({
      resources,
      headerText,
      appState: {
        ...context,
      },
      originPage,
    });
    dispatchAction(resourcePermissionDialogClosed({ isSaved }));
  })
);

const handleSaveResourceSubdivisions = routine(
  ofType(saveResourcePermissions),
  extractPayload(),
  withLatestFrom(resourcePermissions$),
  tap(([{ resources }]) => {
    resources.forEach(resource => {
      resource.subdivisions?.forEach(subdivision => {
        if (!subdivision.isUnSaved) {
          return;
        }
        dispatchAction(
          addResourcesToSubdivision({
            resourceIds: [resource.resourceId],
            subdivisionId: subdivision._id,
          })
        );
      });
    });
  })
);

const getResourcePermission = ([
  { permissionsEditorState, resources },
  previousPermissions,
]: [
  {
    permissionsEditorState: ActorPermissionDataSource[];
    resources: BasicResource[];
  },
  ResourcePermissionsState,
]) => {
  const permissionsUpdates =
    actorPermissionOps.getApiResourcesPermissionFromActorPermissions({
      resourcePermissions: previousPermissions.byId,
      actorPermissions: permissionsEditorState,
      resources,
    });
  if (isArdoqError(permissionsUpdates)) {
    return of(permissionsUpdates);
  }
  return of({ previousPermissions, permissionsUpdates });
};

const handleSaveResourcePermissions = routine(
  ofType(saveResourcePermissions),
  extractPayload(),
  withLatestFrom(resourcePermissions$),
  switchMap(getResourcePermission),
  handleError(error => {
    const defaultMessage = 'An error occurred while updating the permissions';
    const errorMessage = api.logErrorIfNeededAndReturnMessage(
      error,
      defaultMessage
    );
    dispatchAction(resourcePermissionError(errorMessage));
    showToast(errorMessage, ToastType.INFO);
  }),
  tap(({ previousPermissions, permissionsUpdates }) => {
    const updatedPayload = {
      permissionsUpdates,
      prevPermissionsByResource: previousPermissions.byId,
    };
    dispatchAction(apiPutResourcePermission(updatedPayload));
  })
);

export const resourcePermissionRoutines = collectRoutines(
  handleApiPutResourcePermissions,
  handleOpenResourcePermissionDialog,
  handleSaveResourcePermissions,
  handleSaveResourceSubdivisions
);
