import { APIEntityType } from '@ardoq/api-types';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  beginCreateWorkflow,
  beginEditWorkflow,
  BeginEditWorkflowPayload,
} from 'appModelStateEdit/actions';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { filter, tap, withLatestFrom } from 'rxjs';
import { getMostUsedReferenceTypes } from './refTypesUtils';
import {
  modifyProperty,
  ModifyPropertyPayload,
  setRefTypesSortedByMostUsed,
} from '../actions';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { FieldType } from '@ardoq/renderers';
import { getLastUsedTripleByReferenceId } from './utils';
import { trackReferenceTypeChangedByMostUsed } from '../tracking';
import { getEditableEntityPropertiesToCompare } from '../utils';

/**
 * When editing a reference, we only need to update the most used reference types.
 */
const handleUpdateMostUsedReferenceTypesOnEdit = routine(
  ofType(beginEditWorkflow),
  extractPayload<BeginEditWorkflowPayload>(),
  filter(({ entityType }) => entityType === APIEntityType.REFERENCE),
  withLatestFrom(appModelStateEdit$),
  tap(async ([_, { enhancedScopeData, entityIDs }]) => {
    const newValue = await getMostUsedReferenceTypes(
      enhancedScopeData,
      entityIDs
    );
    dispatchAction(
      setRefTypesSortedByMostUsed({
        newValue,
      })
    );
  }),
  tap()
);

/**
 * When creating a new reference, we need to update the most used
 * reference types and set the first one as the type.
 */
const handleInitUpdateMostUsedReferenceTypes = routine(
  ofType(beginCreateWorkflow),
  extractPayload<BeginEditWorkflowPayload>(),
  filter(({ entityType }) => entityType === APIEntityType.REFERENCE),
  withLatestFrom(appModelStateEdit$),
  tap(
    async ([
      { entityType },
      { enhancedScopeData, entityIDs, originalEnhancedScopeData },
    ]) => {
      const newValue = await getMostUsedReferenceTypes(
        enhancedScopeData,
        entityIDs
      );
      dispatchAction(
        setRefTypesSortedByMostUsed({
          newValue,
        })
      );

      if (!newValue) {
        return;
      }

      const lastUsedReferenceTypeName = getLastUsedTripleByReferenceId(
        originalEnhancedScopeData,
        entityIDs[0]
      );

      // We prioritize the last used reference type over the most used ones
      if (lastUsedReferenceTypeName) {
        return;
      }
      const originalProperties = getEditableEntityPropertiesToCompare(
        // We know that originalEnhancedScopeData is not null,
        // otherwise we would not have reached this point
        originalEnhancedScopeData!,
        entityType,
        entityIDs[0]
      );
      trackReferenceTypeChangedByMostUsed();
      dispatchAction(
        modifyProperty({
          propertyName: 'type',
          newValue: newValue[0].id,
          originalProperties,
          propertyType: FieldType.REFERENCE_TYPE,
        })
      );
    }
  )
);

const isModifyingReferenceTargetOrSource = ([
  { propertyType },
  { entityType },
]: [ModifyPropertyPayload, AppModelStateEditStreamShape]) =>
  entityType === APIEntityType.REFERENCE &&
  (propertyType === FieldType.SOURCE || propertyType === FieldType.TARGET);

/**
 * When the source or target of a reference is changed,
 * we need to update the most used reference types sort.
 */
const updateReferenceTypesOnSourceOrTargetChange = routine(
  ofType(modifyProperty),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  filter(isModifyingReferenceTargetOrSource),
  tap(async ([_, { enhancedScopeData, entityIDs }]) => {
    const newValue = await getMostUsedReferenceTypes(
      enhancedScopeData,
      entityIDs
    );

    dispatchAction(
      setRefTypesSortedByMostUsed({
        newValue,
      })
    );
  })
);

const routines = [
  handleInitUpdateMostUsedReferenceTypes,
  updateReferenceTypesOnSourceOrTargetChange,
  handleUpdateMostUsedReferenceTypesOnEdit,
];
export default routines;
