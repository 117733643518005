import styled from 'styled-components';
import {
  CellWithDropdown,
  Column,
  DatasourceTable,
  newTableTheme,
} from '@ardoq/table';
import { AsyncStatus } from 'integrations/common/types/api';
import { Connection } from '@ardoq/api-types/integrations';
import { DataSourceEmptyTable } from '@ardoq/integrations';

type ConnectionsTableParams = {
  listStatus: AsyncStatus;
  connections: Connection[];
  isAdmin: boolean;
  onDelete: (connection: Connection) => void;
  onUpsertClick: (connection?: Connection) => void;
  onConnectionClick: (onConnectionClick: Connection) => void;
  extraColumns?: Column<Connection>[];
  connectYourAccountTitle: string;
  connectYourAccountBtn: string;
};

export function ConnectionsTable({
  connections,
  listStatus,
  isAdmin,
  onDelete,
  onUpsertClick,
  onConnectionClick,
  extraColumns = [],
  connectYourAccountTitle,
  connectYourAccountBtn,
}: ConnectionsTableParams) {
  return (
    <DatasourceTable
      loading={listStatus === 'LOADING'}
      components={newTableTheme}
      dataSource={connections}
      columns={[
        {
          dataIndex: 'name',
          title: 'Connection Name',
          headerStyle: { width: '45%' },
          valueRender: (_, connection) => (
            <ConnectionName onClick={() => onConnectionClick(connection)}>
              {connection.name}
            </ConnectionName>
          ),
        },
        ...extraColumns,
        isAdmin
          ? {
              headerStyle: { float: 'right' },
              valueRender: (_, row) => (
                <CellWithDropdown
                  options={[
                    {
                      label: 'Edit Connection',
                      onClick: () => onUpsertClick(row),
                    },
                    {
                      label: 'Delete',
                      onClick: () => onDelete(row),
                    },
                  ]}
                />
              ),
              cellStyle: { float: 'right', height: '40px', display: 'flex' },
            }
          : {},
      ]}
      renderEmptyTable={() =>
        listStatus !== 'LOADING' ? (
          <DataSourceEmptyTable
            title="You don't have any connections yet"
            message={connectYourAccountTitle}
            button={{
              text: connectYourAccountBtn,
              onClick: () => onUpsertClick(),
            }}
          ></DataSourceEmptyTable>
        ) : null
      }
    />
  );
}

const ConnectionName = styled.div`
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
