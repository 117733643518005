import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { ManageResourcePermissionDataContext } from '@ardoq/manage-resource-permissions';

const manageResourcePermissionInitialState: ManageResourcePermissionDataContext =
  {
    actorsPermissions: [],
    persistedActorsPermissions: [],
    getActorsOptions: async () => [],
    resources: [],
    isEdited: false,
  };

export const manageResourcePermissionViewModel$ =
  persistentReducedStream<ManageResourcePermissionDataContext>(
    'manageResourcePermissionViewModel$',
    manageResourcePermissionInitialState,
    reducers
  );
