import styled from 'styled-components';
import { ModalLayout, ModalTemplate } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import { s32 } from '@ardoq/design-tokens';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { Connection } from '@ardoq/api-types/integrations';
import { Stack } from '@ardoq/layout';

type DeleteConnectionDialogParams = {
  connection: Connection;
  statuses: ConnectionsAsyncOperationsState;
  onCancel: () => void;
  onSubmit: () => void;
  configNames: string[];
  scheduleNames: string[];
};

export function DeleteConnectionDialog({
  statuses,
  connection,
  configNames,
  scheduleNames,
  onSubmit,
  onCancel,
}: DeleteConnectionDialogParams) {
  return (
    <ModalTemplate
      headerText="Delete the connection permanently?"
      secondaryButtonText="Keep connection"
      isInProgress={statuses.delete.status === 'LOADING'}
      onSecondaryButtonClick={onCancel}
      dangerButtonText="Delete connection"
      onDangerButtonClick={onSubmit}
    >
      <ModalLayout>
        {statuses.delete.status === 'FAILURE' && statuses.delete.message && (
          <ErrorNotificationContainer>
            <ErrorNotification>{statuses.delete.message}</ErrorNotification>
          </ErrorNotificationContainer>
        )}
        <div>
          Some schedules and configurations might not work if the connection{' '}
          <b>{connection.name}</b> is deleted.
        </div>
        <Stack gap="medium">
          {Boolean(scheduleNames.length) && (
            <Stack gap="xsmall">
              <b>Affected schedules:</b>
              <List>
                {scheduleNames.map((schedule, i) => (
                  <li key={i}>{schedule}</li>
                ))}
              </List>
            </Stack>
          )}
          {Boolean(configNames.length) && (
            <Stack gap="xsmall">
              <b>Affected configurations:</b>
              <List>
                {configNames.map((config, i) => (
                  <li key={i}>{config}</li>
                ))}
              </List>
            </Stack>
          )}
        </Stack>
        <div>The connection can’t be recovered once deleted.</div>
      </ModalLayout>
    </ModalTemplate>
  );
}

const List = styled.ul`
  padding-left: 30px;
  max-width: 400px;
`;

const ErrorNotificationContainer = styled.div`
  margin: ${s32} ${s32} 0 ${s32};
`;
