import {
  APIEntityType,
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
  LocallyDerivedTransformationOperation,
} from '@ardoq/api-types';
import {
  FieldType,
  defaultAttributesMap,
  fieldAttributesMap,
  getComponentTypesByFieldId,
  getFieldLabel,
  getReferenceTypesByFieldId,
  transposeFieldNameIfNeeded,
} from '@ardoq/renderers';
import {
  CALCULATED_LIST_OPTIONS_MESSAGE,
  customFieldAttributeNames,
  defaultFieldCreateAttributeNames,
  defaultFieldEditAttributeNames,
  legacyCustomFieldAttributeNames,
  readonlyDefaultFieldAttributeNames,
  UNSUPPORTED_SOURCE_TYPE_FOR_DICTIONARY_LOOKUP,
  NOT_NUMBER_FIELD_DISABLED_MESSAGE,
} from 'appModelStateEdit/propertiesEditor/fieldPropertiesEditor/consts';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { numberFormatOptions } from '@ardoq/locale';
import { SelectOption } from '@ardoq/select';
import { fieldOptionOps } from 'models/utils/fieldOptionOps';
import { fieldOps } from 'models/utils/fieldOps';
import {
  creationValidatorMap,
  getInvalidOptionMsg,
  getValidationErrorMessages,
} from 'scopeData/editors/validators';
import { DirtyAttributes } from 'appModelStateEdit/types';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { EditorProperty } from '../types';
import { Features, hasFeature } from '@ardoq/features';
import { fieldOperations } from '@ardoq/core';
import { AutocompleteFieldSuggestion } from 'scopeData/editors/calculatedFields/types';
import { IconName } from '@ardoq/icons';
import { isDateRangeFieldType } from '@ardoq/date-range';

const ardoqAttributeReactiveFieldOptions: SelectOption<AutocompleteFieldSuggestion>[] =
  [
    {
      value: { name: 'name', type: FieldType.NAME },
      label: 'Component Name',
      description: 'Component name',
      iconName: IconName.ROLE,
    },
    {
      value: { name: '_id', type: FieldType.ID },
      label: 'Ardoq OID',
      description: 'Ardoq object ID',
      iconName: IconName.DATA_STORE,
    },
    {
      value: { name: 'component-key', type: FieldType.COMPONENT_KEY },
      label: 'Ardoq ID',
      description: 'Component key',
      iconName: IconName.KEY,
    },
  ];

const isDefaultValue = (attributeName: string) =>
  fieldAttributesMap.get(attributeName) === FieldType.DEFAULT_VALUE;

const isCalculatedFieldSettings = (attributeName: string) =>
  fieldAttributesMap.get(attributeName) === FieldType.TRANSFORMATIONS;

const isNumberTransformFieldType = (type: FieldType) =>
  type === FieldType.TRANSFORM_NUMBER;

const isTextTransformFieldType = (type: FieldType) =>
  type === FieldType.TRANSFORM_TEXT;

const isTransformListType = (type: FieldType) =>
  type === FieldType.TRANSFORM_LIST;

const isTransformFieldType = (type: FieldType) =>
  isNumberTransformFieldType(type) ||
  isTextTransformFieldType(type) ||
  isTransformListType(type);

const isConcatReactiveField = (field: APIFieldAttributes) =>
  fieldOps.getLocallyDerivedOperation(field) ===
  LocallyDerivedTransformationOperation.CONCAT;

/**
 * @param fieldType According to EnhancedScopeData this should be APIFieldType
 */
const getType = (
  attributeName: string,
  fieldType: APIFieldType
): FieldType | undefined => {
  if (isDefaultValue(attributeName)) {
    switch (fieldType) {
      case APIFieldType.TEXT:
        return FieldType.DEFAULT_VALUE_TEXT;
      case APIFieldType.TEXT_AREA:
        return FieldType.DEFAULT_VALUE_TEXT_AREA;
      case APIFieldType.NUMBER:
        return FieldType.DEFAULT_VALUE_NUMBER;
      case APIFieldType.CHECKBOX:
        return FieldType.DEFAULT_VALUE_CHECKBOX;
      case APIFieldType.LIST:
        return FieldType.DEFAULT_VALUE_LIST;
      case APIFieldType.SELECT_MULTIPLE_LIST:
        return FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST;
      case APIFieldType.DATE_ONLY:
        return FieldType.DEFAULT_VALUE_DATE_ONLY;
      case APIFieldType.DATE_ONLY_RANGE:
        return FieldType.DEFAULT_VALUE_DATE_ONLY_RANGE;
      case APIFieldType.DATE_TIME:
        return FieldType.DEFAULT_VALUE_DATE_TIME;
      case APIFieldType.DATE_TIME_RANGE:
        return FieldType.DEFAULT_VALUE_DATE_TIME_RANGE;
      case APIFieldType.URL:
        return FieldType.DEFAULT_VALUE_URL;
      case APIFieldType.EMAIL:
        return FieldType.DEFAULT_VALUE_EMAIL;
      case APIFieldType.USER:
        return FieldType.DEFAULT_VALUE_USER;
      case APIFieldType.FILE:
        return FieldType.DEFAULT_VALUE_FILE;
      default:
        return FieldType.DEFAULT_VALUE_TEXT;
    }
  }

  if (isCalculatedFieldSettings(attributeName)) {
    switch (fieldType) {
      case APIFieldType.NUMBER:
        return FieldType.TRANSFORM_NUMBER;
      case APIFieldType.TEXT:
        return FieldType.TRANSFORM_TEXT;
      case APIFieldType.LIST:
        return FieldType.TRANSFORM_LIST;
    }
  }

  return (
    fieldAttributesMap.get(attributeName) ??
    defaultAttributesMap.get(attributeName)
  );
};

const getValue = (
  field: APIFieldAttributes,
  attributeName: string,
  type: FieldType,
  isReadOnlyContext: boolean,
  isReadOnlyFieldTypeWithPopulateMethodSuffix: boolean
) => {
  if (type === FieldType.FIELD_TYPE) {
    // when isReadOnlyContext is true, the value is for <Text> value, otherwise it is for <List> option key
    return isReadOnlyContext
      ? isReadOnlyFieldTypeWithPopulateMethodSuffix
        ? fieldOps.getFieldTypeLabelWithPopulateMethod(field)
        : fieldOps.getFieldTypeLabel(field.type)
      : field.type;
  }
  if (type === FieldType.POPULATE_METHOD) {
    return fieldOps.getPopulateMethodFromField(field);
  }
  if (isTransformFieldType(type) && fieldOps.isLocallyDerivedField(field)) {
    return fieldOps.getLocallyDerivedTransformations(field);
  }
  if (!isDefaultValue(attributeName)) {
    // @ts-expect-error APIFieldAttributes doesn't have a string indexer
    return field[attributeName];
  }
  const specialTypes = new Set([
    FieldType.DEFAULT_VALUE_LIST,
    FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST,
  ]);
  if (!specialTypes.has(type)) {
    // @ts-expect-error APIFieldAttributes doesn't have a string indexer
    return field[attributeName];
  }
  if (!field.defaultValue || typeof field.defaultValue !== 'string') {
    return [];
  }
  // @ts-expect-error APIFieldAttributes doesn't have a string indexer
  return field[attributeName].split(',');
};

export const getDefaultProperties = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  canCreateGraphFilter: boolean
): EditorProperty[] => {
  const attributeNames = isCreatingNewEntity
    ? defaultFieldCreateAttributeNames
    : defaultFieldEditAttributeNames;
  return attributeNames
    .map(attributeName => {
      const field = enhancedScopeData.fieldsById[fieldId];
      if (!field) {
        return null;
      }
      const type = getType(attributeName, field.type);
      if (!type) {
        return null;
      }
      const transposedFieldName = transposeFieldNameIfNeeded({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.FIELD,
        entityId: fieldId,
      });
      const label = getFieldLabel({
        fieldName: transposedFieldName,
        enhancedScopeData,
        entityType: APIEntityType.FIELD,
      });
      const isCalculated = fieldOps.isCalculatedField(field);
      const isLocallyDerivedField = fieldOps.isLocallyDerivedField(field);
      if (
        isCalculated &&
        isDefaultValue(attributeName) &&
        type !== FieldType.DEFAULT_VALUE_LIST
      ) {
        return null;
      }
      if (
        type === FieldType.NUMBER_FORMAT_OPTIONS &&
        field.type !== APIFieldType.NUMBER
      ) {
        return null;
      }

      if (isTransformFieldType(type) && !isLocallyDerivedField) {
        return null;
      }

      if (type === FieldType.POPULATE_METHOD) {
        const hasNoChangablePopulateMethod =
          getDefaultPopulateMethodOptions(field.type, canCreateGraphFilter)
            .length < 2;

        // if there is no changable populate method, just hide it
        if (hasNoChangablePopulateMethod) {
          return null;
        }
      }

      const isDefaultReadOnlyEditContext =
        !isCreatingNewEntity &&
        readonlyDefaultFieldAttributeNames.has(attributeName);

      const isReadOnlyCalculatedContext =
        type === FieldType.DEFAULT_VALUE && isCalculated;

      const isReadOnlyContext =
        isDefaultReadOnlyEditContext || isReadOnlyCalculatedContext;

      // It is necessary for hack calculated date range field,
      // Calculated date range field is not formally supported.
      // See: https://help.ardoq.com/en/articles/43910-calculated-date-ranges
      const isReadOnlyFieldTypeWithPopulateMethodSuffix =
        !isCreatingNewEntity &&
        type === FieldType.FIELD_TYPE &&
        isDateRangeFieldType(field.type);

      const value = getValue(
        field,
        attributeName,
        type,
        isReadOnlyContext,
        isReadOnlyFieldTypeWithPopulateMethodSuffix
      );

      const isDirty = dirtyAttributes.has(attributeName);
      const errorMessages = getValidationErrorMessages(type, value);
      const invalidOptionMessage = getInvalidOptionMsg(type);
      return {
        type,
        name: attributeName,
        label,
        value,
        isDirty,
        description:
          isCalculated && type === FieldType.DEFAULT_VALUE_LIST
            ? CALCULATED_LIST_OPTIONS_MESSAGE
            : '',
        errorMessages,
        creationValidator: creationValidatorMap.get(type) ?? null,
        invalidOptionMessage,
        options: getAttributeDefaultOptions({
          type,
          field,
          canCreateGraphFilter,
        }),
        reactiveFieldOptions: getReactiveFieldOptions({
          type,
          field,
          enhancedScopeData,
        }),
        isReadOnly: isReadOnlyContext,
      };
    })
    .filter(ExcludeFalsy);
};

const isCustomFieldPropertyDisabled = (
  property: string,
  field: APIFieldAttributes
) => {
  return (
    (property === 'componentType' && field.global) ||
    (property === 'referenceType' && field.globalref)
  );
};

const getCustomFieldAttributeNames = (): string[] =>
  hasFeature(Features.DISABLE_FIELD_APPLIES_TO_ALL)
    ? customFieldAttributeNames
    : legacyCustomFieldAttributeNames;

export const getCustomProperties = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): EditorProperty[] => {
  const field = enhancedScopeData.fieldsById[fieldId];
  if (!field) {
    return [];
  }
  return getCustomFieldAttributeNames().map(attributeName => {
    const type = fieldAttributesMap.get(attributeName)!;
    // @ts-expect-error APIFieldAttributes doesn't have a string indexer
    const value = field[attributeName];
    const isDirty = dirtyAttributes.has(attributeName);
    const errorMessages = getValidationErrorMessages(type, value);
    const isDisabled = isCustomFieldPropertyDisabled(attributeName, field);
    return {
      type,
      name: attributeName,
      label: getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.FIELD,
      }),
      value: isDisabled ? [] : value,
      isDirty,
      errorMessages,
      options: customPropertyOptionGetterMap.get(type)?.(
        fieldId,
        enhancedScopeData
      ),
      isDisabled,
    };
  });
};

const getNumberFormatOptions = () =>
  Array.from(numberFormatOptions.values()).map(
    ({ val, label, option, category }) => {
      return {
        value: val,
        label,
        option,
        category,
      };
    }
  );

const getDefaultCheckboxValueOptions = () => {
  return [
    { label: 'Not set', value: null },
    { label: 'Checked', value: true },
    { label: 'Unchecked', value: false },
  ];
};

const getDefaultPopulateMethodOptions = (
  fieldType: APIFieldType,
  canCreateGraphFilter: boolean
) => {
  const methods = fieldOps.getAvailablePopulateMethods(
    fieldType,
    canCreateGraphFilter
  );

  return methods.map(method => ({
    label: fieldOps.getFieldPopulateMethodLabel(method),
    value: method,
  }));
};

const getDefaultListValueOptions = (field: APIFieldAttributes) => {
  if (!field.defaultValue || typeof field.defaultValue !== 'string') {
    return [];
  }
  return field.defaultValue.split(',').map(value => {
    return {
      value,
      label: value,
    };
  });
};

export const getAutocompleteFieldOptionDisabledMessage = (
  field: APIFieldAttributes,
  suggestion: APIFieldAttributes
): string | undefined => {
  const operation = fieldOps.getLocallyDerivedOperation(field);

  if (operation === LocallyDerivedTransformationOperation.CONCAT) {
    // Concat transformations can use either number or text fields as input
    if (
      fieldOperations.isNumberField(suggestion) ||
      fieldOperations.isTextField(suggestion) ||
      fieldOperations.isListField(suggestion)
    ) {
      return;
    }

    return UNSUPPORTED_SOURCE_TYPE_FOR_DICTIONARY_LOOKUP;
  }

  if (operation === LocallyDerivedTransformationOperation.DICTIONARY_LOOKUP) {
    // Dictionary lookup transformations can use either number or text fields as input
    if (
      fieldOperations.isNumberField(suggestion) ||
      fieldOperations.isTextField(suggestion) ||
      fieldOperations.isListField(suggestion)
    ) {
      return;
    }

    return UNSUPPORTED_SOURCE_TYPE_FOR_DICTIONARY_LOOKUP;
  }

  // Number transformations can only use number fields as input
  if (
    fieldOperations.isNumberField(field) &&
    !fieldOperations.isNumberField(suggestion)
  ) {
    return NOT_NUMBER_FIELD_DISABLED_MESSAGE;
  }
};

const getComponentTypeOptions = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  return Object.values(
    getComponentTypesByFieldId(fieldId, enhancedScopeData)
  ).map(type => ({
    value: type.id,
    label: type.name,
  }));
};

const getReferenceTypeOptions = (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  return Object.values(
    getReferenceTypesByFieldId(fieldId, enhancedScopeData)
  ).map(type => ({
    value: String(type.id),
    label: type.name,
  }));
};

const getAttributeDefaultOptions = ({
  type,
  field,
  canCreateGraphFilter,
}: {
  type: FieldType;
  field: APIFieldAttributes;
  canCreateGraphFilter: boolean;
}) => {
  if (type === FieldType.FIELD_TYPE) {
    return fieldOps
      .getAvailableFieldTypes()
      .map(fieldOptionOps.toFieldOptionFromType);
  }
  if (type === FieldType.NUMBER_FORMAT_OPTIONS) {
    return getNumberFormatOptions();
  }
  if (type === FieldType.DEFAULT_VALUE_CHECKBOX) {
    return getDefaultCheckboxValueOptions();
  }
  if (
    type === FieldType.DEFAULT_VALUE_LIST ||
    type === FieldType.TRANSFORM_LIST ||
    type === FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST
  ) {
    return getDefaultListValueOptions(field);
  }
  if (type === FieldType.POPULATE_METHOD) {
    return getDefaultPopulateMethodOptions(field.type, canCreateGraphFilter);
  }
  return undefined;
};

const getReactiveFieldOptions = ({
  type,
  field,
  enhancedScopeData,
}: {
  type: FieldType;
  field: APIFieldAttributes;
  enhancedScopeData: EnhancedScopeData;
}) => {
  if (!isTransformFieldType(type)) {
    return;
  }
  const optionsFromFields = enhancedScopeData.fields
    .filter(suggestion =>
      plausibleAutocompleteFieldSuggestion(field, suggestion)
    )
    .map(suggestion => toAutocompleteFieldSuggestion(field, suggestion));

  if (isConcatReactiveField(field)) {
    return [...ardoqAttributeReactiveFieldOptions, ...optionsFromFields];
  }

  return optionsFromFields;
};

export const toAutocompleteFieldSuggestionValue = (
  suggestion: APIFieldAttributes
) => {
  if (suggestion.type === APIFieldType.LIST) {
    return {
      type: suggestion.type,
      name: suggestion.name,
      options: fieldOperations.getListFieldOptions(suggestion),
    };
  }

  return {
    type: suggestion.type,
    name: suggestion.name,
  };
};

export const plausibleAutocompleteFieldSuggestion = (
  field: APIFieldAttributes,
  suggestion: APIFieldAttributes
) => {
  // Don't show the current field as a suggestion
  return suggestion._id !== field._id;
};

export const toAutocompleteFieldSuggestion = (
  field: APIFieldAttributes,
  suggestion: APIFieldAttributes
): SelectOption<AutocompleteFieldSuggestion> => {
  const disabledMessage = getAutocompleteFieldOptionDisabledMessage(
    field,
    suggestion
  );

  return {
    value: toAutocompleteFieldSuggestionValue(suggestion),
    label: suggestion.label,
    description: suggestion.description ?? undefined,
    leftIcon: { name: getFieldIcon(suggestion) },
    isDisabled: disabledMessage !== undefined,
    popoverContent: disabledMessage,
  };
};

const getFieldIcon = (field: APIFieldAttributes): IconName => {
  if (fieldOperations.isNumberField(field)) {
    return IconName.NUMBER;
  }

  if (fieldOperations.isListField(field)) {
    return IconName.LIST;
  }

  if (fieldOperations.isTextField(field)) {
    return IconName.TEXT_FORMAT;
  }

  return IconName.CLOSE;
};

const customPropertyOptionGetterMap = new Map<
  FieldType,
  (
    fieldId: ArdoqId,
    enhancedScopeData: EnhancedScopeData
  ) => SelectOption<string>[]
>([
  [FieldType.COMPONENT_TYPE, getComponentTypeOptions],
  [FieldType.REFERENCE_TYPE, getReferenceTypeOptions],
]);
