import { ActionCreator, actionCreator } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

export type WithIntegrationId = {
  integrationId: IntegrationId;
};

export type ActionCreatorParameter<T extends ActionCreator<any>> =
  Parameters<T>[0];

export const actionCreatorWithIntegrationId = <Payload extends object>(
  type: Parameters<typeof actionCreator>[0]
) => actionCreator<Payload & WithIntegrationId>(type);
