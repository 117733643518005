import { SchedulingPolicy } from '@ardoq/api-types';
import styled from 'styled-components';

const Capitalized = styled.div`
  text-transform: capitalize;
`;

interface SchedulePolicyProps {
  schedulingPolicy: SchedulingPolicy;
}

export const SchedulePolicy = ({ schedulingPolicy }: SchedulePolicyProps) => (
  <Capitalized>
    {schedulingPolicy.type === 'weekly'
      ? `Every ${schedulingPolicy.weekDay}`
      : 'Daily'}
  </Capitalized>
);
