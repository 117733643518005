import * as excel from '../excel-v3/initialState';
import * as servicenow from '../service-now/initialState';
import * as aws from '../aws/initialState';
import * as microsoftEntraId from '../microsoftEntraId/initialState';
import * as azure from '../azure/initialState';
import * as unified from '../unified/initialState';
import * as signavioExporter from '../signavio-exporter/initalState';
import * as itpedia from '../ITPedia/initalState';
import { IntegrationMapBy } from './streams/activeIntegrations/types';
import { IntegrationTermsDictionary } from './streams/integrationTermsDictionary/types';
import { IntegrationId } from './streams/tabularMappings/types';

export type IntegrationInitialState = {
  activeIntegration: {
    integrationId: IntegrationId;
    integrationMappingParams: IntegrationMapBy;
  };
  termsDictionary: IntegrationTermsDictionary;
};

export const integrationInitialStates: Record<
  IntegrationId,
  IntegrationInitialState
> = {
  'excel-v3': excel.initialState,
  'servicenow-v3': servicenow.initialState,
  'azure-v3': azure.initialState,
  'aws-v3': aws.initialState,
  'microsoft-entra-id': microsoftEntraId.initialState,
  'signavio-exporter': signavioExporter.initialState,
  'it-pedia': itpedia.initialState,
  ...unified.initialStates,
};
