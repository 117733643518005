import {
  action$,
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { firstValueFrom, map } from 'rxjs';
import { tap, withLatestFrom, filter } from 'rxjs/operators';
import { logError } from '@ardoq/logging';
import { addBreadcrumb, setSentryTag } from '@ardoq/sentry';
import {
  gridEditorTableLoaded,
  hideGridEditor,
  notifyCurrentUserChange,
  notifyGridEditorFiltersChanged,
  notifyGridEditorFirstPaintFinished,
  notifyPopoutGridEditorOpened,
  requestPopoutGridEditor,
  showGridEditor,
  gridFieldBatchUpdate,
  gridEnableAllFields,
  gridEditorLoadWorkspace,
  notifyScopeDataChange,
  notifyOpenPopoutGridEditorFailed,
  notifyScopeDataLoaded,
} from './actions';
import gridEditor$ from './gridEditor$';
import { hideRightPane } from 'appContainer/actions';
import currentUser from 'models/currentUser';
import { GridEditorFrame, ScopeDataDependenciesWithScopeData } from './types';
import { contextInterface } from 'modelInterface/contextInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { gridEditorStateOperations } from './gridEditorStateOperations';
import * as persistedSettings from './gridEditorPersistedSettings';
import { isDefaultFieldName } from './defaultFields';
import { gridEditorInterface } from './gridEditorBackboneInterface';
import { popoutGridEditorUtils } from './popoutGridEditorUtils';
import * as profiling from '@ardoq/profiling';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { dynamicAndGlobalFilters$ } from './observables/dynamicAndGlobalFilters$';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

const handleShowGridEditor = routine(
  ofType(showGridEditor),
  withLatestFrom(gridEditor$),
  tap(([, state]) => {
    // Only ardoq-front is responsible for managing visible Grid Editors
    if (state.frameId !== GridEditorFrame.ARDOQ_FRONT_BRIDGE) {
      return;
    }

    if (state.activeFrameId === GridEditorFrame.DOCKED) {
      dispatchAction(hideRightPane());
      return;
    }

    try {
      popoutGridEditorUtils.focusPopoutGridEditor();
    } catch (error) {
      dispatchAction(notifyOpenPopoutGridEditorFailed());
      if (error instanceof Error) {
        logError(error, 'Failed to focus Popout GridEditor');
      }
    }
  })
);

const handleRequestPopout = routine(
  ofType(requestPopoutGridEditor),
  withLatestFrom(gridEditor$),
  tap(([, state]) => {
    if (state.frameId !== GridEditorFrame.ARDOQ_FRONT_BRIDGE) {
      // Only ardoq-front handles this routine in order to keep the
      // communication between the various frames *simple*.
      return;
    }

    try {
      // if the popout is already open, focus it
      if (state.activeFrameId === GridEditorFrame.POPOUT) {
        popoutGridEditorUtils.focusPopoutGridEditor();
      } else {
        popoutGridEditorUtils.openPopoutGridEditor(() => {
          dispatchAction(notifyPopoutGridEditorOpened());
        });
      }
    } catch (error) {
      dispatchAction(notifyOpenPopoutGridEditorFailed());
      if (error instanceof Error) {
        logError(error, 'Failed to open Popout GridEditor');
      }
    }
  })
);

const getTransactionData = (
  action: ReturnType<typeof showGridEditor | typeof requestPopoutGridEditor>
) => {
  const threshold = 6500; // empirical value
  if (action.type === requestPopoutGridEditor.type) {
    return { name: `Open Popout Grid Editor`, threshold };
  }
  return { name: `Open Grid Editor`, threshold };
};

/**
 * This is not a completely fair measurement of how the Grid Editor opens,
 * however it is very close to what the user perceives.
 *
 * Popout Grid Editor:
 * Measures end-to-end loading, initializing and rendering, this is the true
 * performance measurement of the Grid Editor.
 *
 * Docked Grid Editor:
 * This editor will typically be initialized and loaded by the time the user
 * requests it, so often the measurements will mainly reflect rendering.
 * When the Docked Grid Editor is open on page load this measurement will
 * reflect the full initialization.
 */
const trackGridEditorInit = routine(
  ofType(showGridEditor, requestPopoutGridEditor),
  withLatestFrom(gridEditor$),
  tap(async ([startAction, gridEditorModel]) => {
    if (gridEditorModel.frameId !== GridEditorFrame.ARDOQ_FRONT_BRIDGE) {
      // Only ardoq-front handles this routine in order to keep the
      // communication between the various frames *simple*.
      return;
    }

    const { name, threshold } = getTransactionData(startAction);
    const transaction = profiling.startTransaction(
      name,
      threshold,
      profiling.Team.CORE
    );

    try {
      const endAction = await firstValueFrom(
        action$.pipe(ofType(notifyGridEditorFirstPaintFinished, hideGridEditor))
      );
      // Throw an error to stop performance tracking, transactions/spans that
      // are not ended are not sent to Sentry.
      if (endAction.type === hideGridEditor.type) {
        throw new Error('Open Grid Editor Cancelled');
      }

      profiling.endTransaction(transaction, {
        viewId: 'gridEditor2023',
        metadata: {
          countReferencesLoaded: referenceInterface.getReferencesCount(),
          countComponentsLoaded: componentInterface.getComponentsCount(),
          countFieldsLoaded: fieldInterface.getFieldsCount(),
          countFiltersLoaded: filterInterface.getFilterCount(),
        },
      });
    } catch (error) {
      // no-op
    }
  })
);

const removeLoader = routine(
  ofType(gridEditorTableLoaded),
  tap(() => {
    const loader = window.parent.document.querySelector(
      '.grid-editor-loading-container'
    );
    if (loader) {
      loader.remove();
    }
  })
);

/**
 * Filter routines
 */
const handleFilterRulesChange = routine(
  ofType(notifyGridEditorFiltersChanged),
  extractPayload(),
  withLatestFrom(gridEditor$),
  tap(async ([payload, gridEditorModel]) => {
    // Only perform update if we're in a standalone grid editor frame
    if (gridEditorModel.frameId === GridEditorFrame.ARDOQ_FRONT_BRIDGE) {
      return;
    }
    gridEditorInterface.setGlobalAndDynamicFilters(payload);
  })
);

const isExistingFieldName = (fieldName: string) => {
  return (
    isDefaultFieldName(fieldName) || fieldInterface.existsByName(fieldName)
  );
};

/**
 * Persist disabled fields when they are updated by the user.
 * This intentionally excludes 'gridEditorInitDisabledFields'
 */
const handlePersistDisabledFields = routine(
  ofType(gridEnableAllFields, gridFieldBatchUpdate),
  withLatestFrom(gridEditor$),
  // Discard action because we only care about the state after the reducer ran
  map(([_, gridEditor]) => gridEditor),
  // Disabled fields are only persisted from the main ardoq-front runtime
  filter(gridEditorStateOperations.isArdoqFrontRuntime),
  map(gridEditorStateOperations.getDisabledFieldNames),
  tap(async fieldNames => {
    const fieldNamesToStore = [...fieldNames].filter(isExistingFieldName);
    await persistedSettings.setHiddenColumns(fieldNamesToStore);
  })
);

/**
 * Loading a workspace from the Grid Editor means telling ardoq-front to load it
 * into context, which in turn triggers a context sync.
 */
const handleLoadWorkspace = routine(
  ofType(gridEditorLoadWorkspace),
  extractPayload(),
  withLatestFrom(gridEditor$),
  filter(([_, gridEditor]) => {
    return gridEditorStateOperations.isArdoqFrontRuntime(gridEditor);
  }),
  map(([{ workspaceId }]) => [workspaceId]),
  tap(workspaceIds => {
    contextInterface.loadWorkspaces({ workspaceIds });
  })
);

/**
 * Sync currentUser and currentUserPermissions from ardoq-front
 */
const handleCurrentUserUpdate = routine(
  ofType(notifyCurrentUserChange),
  extractPayload(),
  withLatestFrom(gridEditor$),
  filter(([, gridEditorModel]) => {
    return !gridEditorStateOperations.isArdoqFrontRuntime(gridEditorModel);
  }),
  tap(([payload]) => {
    currentUser.set(payload);
  })
);

const getMetadataFromNotifyScopeDataChange = ({
  context,
  activeScenarioState,
  scopeData,
}: ScopeDataDependenciesWithScopeData): Record<
  string,
  number | string | boolean
> => {
  const ctxMetadata = {
    componentId: context.componentId,
    connectedWorkspaceIds: context.connectedWorkspaceIds.join(', '),
    modelId: context.modelId,
    scenarioId:
      activeScenarioOperations.getActiveScenarioId(activeScenarioState) ?? '',
    sort: JSON.stringify(context.sort),
    workspaceId: context.workspaceId,
    workspacesIds: context.workspacesIds.join(', '),
  };
  if (scopeData) {
    return {
      ...ctxMetadata,
      countComponents: scopeData.components.length,
      countReferences: scopeData.references.length,
      countModels: scopeData.models.length,
      countFields: scopeData.fields.length,
      countWorkspaces: scopeData.workspaces.length,
    };
  }
  return ctxMetadata;
};

/**
 * Handle sync of context and scope data from ardoq-front
 * Includes:
 * - scopeData, if changed
 * - activeScenarioState$
 * - context$
 */
const handleScopeDataChanged = routine(
  ofType(notifyScopeDataChange),
  extractPayload(),
  withLatestFrom(dynamicAndGlobalFilters$, gridEditor$),
  filter(([, , gridEditor]) => {
    return !gridEditorStateOperations.isArdoqFrontRuntime(gridEditor);
  }),
  tap(async ([data, dynamicAndGlobalFilters]) => {
    setSentryTag('openerUrl', window.opener?.location?.href);
    const transactionName = data.scopeData
      ? 'GridEditor ScopeData Changed'
      : 'GridEditor Context Changed';

    const metadata = getMetadataFromNotifyScopeDataChange(data);
    addBreadcrumb(transactionName, { data: metadata });

    const transaction = profiling.startTransaction(
      transactionName,
      1000,
      profiling.Team.CORE
    );
    try {
      await gridEditorInterface.resetContextUsingScopeData(data, {
        dynamicAndGlobalFilters,
      });
    } catch (error) {
      /* empty */
    }
    dispatchAction(notifyScopeDataLoaded(data));
    profiling.endTransaction(transaction, {
      viewId: 'gridEditor2023',
      metadata,
    });
  })
);

export default collectRoutines(
  trackGridEditorInit,
  handleShowGridEditor,
  handleRequestPopout,
  removeLoader,
  handlePersistDisabledFields,
  handleFilterRulesChange,
  handleLoadWorkspace,
  handleScopeDataChanged,
  handleCurrentUserUpdate
);
