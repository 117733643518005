import {
  APIBroadcastAttributes,
  APIDiscoverViewpointAttributes,
  APIOrganizationAttributes,
  ArdoqId,
  BroadcastPreview,
  UnpersistedEntity,
} from '@ardoq/api-types';

export type InstancePerson = {
  readonly name: string | null;
  readonly email: string;
};

export type UnpersistedCurrentBroadcast =
  UnpersistedEntity<APIBroadcastAttributes> & {
    folderId: ArdoqId | null;
  };

export type CurrentBroadcast =
  | Readonly<APIBroadcastAttributes>
  | Readonly<UnpersistedCurrentBroadcast>;

export enum BroadcastFetchStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  REVALIDATING = 'revalidating',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type BroadcastMeta = {
  latestSchedule: string;
  intervalInDays: number;
};

export enum BroadcastSaveStatus {
  IDLE = 'idle',
  SAVING = 'saving',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum BroadcastPreviewFetchStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  NO_AUDIENCE_WITH_EMAIL = 'There is no one in the audience with an email address associated with them. Please check your audience list and try again.',
  ERROR = 'Something went wrong and the broadcast preview could not be generated. Please check your broadcast for any errors, save and then try again.',
}

export type BroadcastStreamShape = {
  readonly broadcastsById: Record<ArdoqId, APIBroadcastAttributes>;
  readonly broadcasts: APIBroadcastAttributes[];
  readonly fetchStatus: BroadcastFetchStatus;
  readonly saveStatus: BroadcastSaveStatus;
  readonly currentBroadcast: CurrentBroadcast | null;
  readonly richTextEditorResetKey: number;
  readonly organization: APIOrganizationAttributes | null;
  readonly viewpoints: APIDiscoverViewpointAttributes[] | null;
  readonly isLoadingViewpoints: boolean;
  readonly broadcastPreview: BroadcastPreview | null;
  readonly broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus;
};

export type ValueGraphSearchResponse = {
  readonly status: string;
  readonly result: string[];
  readonly error: string;
  readonly count: number;
};
