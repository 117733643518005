import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import { Attributes } from './types';

export const get = <T extends APIEntityType>(
  entityType: T,
  id: ArdoqId
): Attributes<T> | null => {
  const entity = getCollectionForEntityType(entityType).get(id);
  return entity ? structuredClone(entity.attributes) : null;
};

export const add = <T extends APIEntityType>(
  entityType: T,
  entities: Attributes<T>[]
) => getCollectionForEntityType(entityType).add(entities);

export const getWorkspaceEntities = <T extends APIEntityType>(
  entityType: T,
  workspaceId: ArdoqId
): Attributes<T>[] => {
  return getCollectionForEntityType(entityType)
    .where({ rootWorkspace: workspaceId })
    .map(entity => structuredClone(entity.attributes));
};
