import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';
import { RolesStreamShape } from './types';

const roles$ = persistentReducedStream<RolesStreamShape>(
  'roles$',
  defaultState,
  reducers
);

export default roles$;
