import { actionCreator } from '@ardoq/rxbeach';
import { APIFieldAttributes } from '@ardoq/api-types';
import { AddFieldNewToWorkspacePayload } from 'appModelStateEdit/propertiesEditor/addField/types';

export const addFieldByName = actionCreator<string>(
  '[appModelStateEdit/addFieldToEntity] ADD_FIELD_BY_NAME'
);

type AddWorkspaceFieldToComponentTypePayload = Pick<
  APIFieldAttributes,
  'name' | 'componentType'
>;
type AddWorkspaceFieldToReferenceTypePayload = Pick<
  APIFieldAttributes,
  'name' | 'referenceType'
>;
export type AddWorkspaceFieldPayload =
  | AddWorkspaceFieldToComponentTypePayload
  | AddWorkspaceFieldToReferenceTypePayload;

export const addWorkspaceField = actionCreator<AddWorkspaceFieldPayload>(
  '[appModelStateEdit/addFieldToEntity] ADD_WORKSPACE_FIELD'
);

export const addFieldNewToWorkspace =
  actionCreator<AddFieldNewToWorkspacePayload>(
    '[appModelStateEdit/addFieldToEntity] ADD_FIELD_NEW_TO_WORKSPACE'
  );

export const createFieldByLabel = actionCreator<string>(
  '[appModelStateEdit/addFieldToEntity] CREATE_FIELD_BY_LABEL'
);

export const removeField = actionCreator<string>(
  '[appModelStateEdit/addFieldToEntity] REMOVE_FIELD'
);
