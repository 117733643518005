import { useState } from 'react';
import { confirm, modal } from '@ardoq/modal';
import styled from 'styled-components';
import { ConnectedNewManagePermissionsDialog } from './viewModel/ConnectedNewManagePermissionsDialog';
import {
  Actor,
  ActorPermission,
  AppStateManageResourcePermissionContext,
  BasicResource,
  groupActorOps,
  ManagePermissionsDialog,
  ManageResourcePermissionsDialogProps,
} from '@ardoq/manage-resource-permissions';
import viewModel$ from './viewModel$';
import {
  AddNewResourcePermissionPayload,
  addResourceToSubdivisionInPermissionsEditor,
  grantReadAccessUnderlyingResource,
  permissionChange,
  revokePermission,
} from './viewModel/actions';
import {
  ResourcePermissionMetaPayload,
  resourcePermissionError,
  saveResourcePermissions,
  setResourcePermissions,
} from './actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { addNewResourcePermission } from './viewModel/actions';
import { ToastType, showToast } from '@ardoq/status-ui';
import { manageResourcePermissionViewModel$ } from './viewModel/manageResourcePermissionViewModel$';
import { trackResourcesPermissionEditorEvent } from './utils';
import { ResourcesPermissionEditorEvents } from './types';

const Backdrop = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
`;

/* --------------------------------------------------------
 * --------------------------------------------------------
 * -------  Old manage resource permissions dialog  -------
 * --------------------------------------------------------
 * ----------------------------------------------------- */

const ConnectedManageResourcePermissionsDialog = connect(
  ManagePermissionsDialog,
  viewModel$
);

export const openManageResourcePermissionsDialog = ({
  headerText,
  resources,
}: ResourcePermissionMetaPayload) =>
  modal<void>(resolve => (
    <Backdrop>
      <ConnectedManageResourcePermissionsDialog
        resources={resources}
        closeHandler={resolve}
        headerText={headerText}
      />
    </Backdrop>
  ));

/* --------------------------------------------------------
 * --------------------------------------------------------
 * -------  New manage resource permissions dialog  -------
 * --------------------------------------------------------
 * ----------------------------------------------------- */

type OpenNewManageResourcePermissionsDialogArgs =
  ResourcePermissionMetaPayload & {
    appState: AppStateManageResourcePermissionContext;
  };
export const openNewManageResourcePermissionsDialog = ({
  headerText,
  appState,
}: OpenNewManageResourcePermissionsDialogArgs) => {
  const onCloseConfirmationHandler = createOnCloseConfirmationHandler();
  return modal<true>(
    resolve => {
      const [isSaving, setIsSaving] = useState(false);

      return (
        <ConnectedNewManagePermissionsDialog
          closeHandler={async () => {
            if (await onCloseConfirmationHandler()) {
              resolve(false);
            }
          }}
          allSubdivisions={appState.subdivisions}
          headerText={headerText}
          isSaving={isSaving}
          actions={getManageResourcePermissionsDialogActions(
            appState,
            setIsSaving,
            resolve
          )}
        />
      );
    },
    {
      shouldCloseOnBackdropClick: onCloseConfirmationHandler,
      shouldCloseOnEscapeKey: onCloseConfirmationHandler,
    }
  );
};

const createOnCloseConfirmationHandler = () => {
  let onEscPressHasPendingPromise: boolean = false;

  return async () => {
    if (onEscPressHasPendingPromise) {
      return false;
    }

    trackResourcesPermissionEditorEvent(
      ResourcesPermissionEditorEvents.CLICKED_ON_CLOSE_NEW_RESOURCE_PERMISSIONS_EDITOR_BUTTON
    );

    // Currently we don't have a way to read this data from the stream in a pure way.
    if (!manageResourcePermissionViewModel$.state.isEdited) {
      return true;
    }
    onEscPressHasPendingPromise = true;
    const answer = Boolean(
      await confirm({
        title: 'Discard changes?',
        text: 'You have unsaved changes. Are you sure you want to discard them?',
        confirmButtonTitle: 'Discard changes',
        cancelButtonTitle: 'Cancel',
      })
    );
    if (answer) {
      trackResourcesPermissionEditorEvent(
        ResourcesPermissionEditorEvents.CONFIRMED_DISCARD_CHANGES
      );
    }
    onEscPressHasPendingPromise = false;
    return answer;
  };
};

/**
 *  Creates the onActorSelect handler for the new permissions editor.
 */
const createOnActorSelect = (
  appState: AddNewResourcePermissionPayload['appState']
) => {
  return {
    onActorSelect: (actor: Actor, resources: BasicResource[]) => {
      trackResourcesPermissionEditorEvent(
        ResourcesPermissionEditorEvents.ADDED_NEW_ACTOR_TO_RESOURCE_PERMISSIONS,
        {
          resourcesCount: resources.length,
          resourceType: resources[0].resourceType,
          isGroup: groupActorOps.isGroupActor(actor),
        }
      );
      dispatchAction(
        addNewResourcePermission({
          actor,
          resources,
          appState,
        })
      );
    },
  };
};

/**
 * Creates the onSaveChanges handler for the new permissions editor
 */
const getOnSaveChanges = (
  setIsSaving: (isSaving: boolean) => void,
  closeHandler: (saved: true) => void
) => ({
  onSaveChanges: async (
    actorsPermissions: ActorPermission[],
    resources: BasicResource[]
  ) => {
    trackResourcesPermissionEditorEvent(
      ResourcesPermissionEditorEvents.SAVED_CHANGES,
      {
        resourcesCount: resources.length,
        resourceType: resources[0].resourceType,
      }
    );
    setIsSaving(true);
    try {
      const response = await dispatchActionAndWaitForResponse(
        saveResourcePermissions({
          permissionsEditorState: actorsPermissions,
          resources,
        }),
        setResourcePermissions,
        resourcePermissionError
      );
      if (response.type === setResourcePermissions.type) {
        showToast('Permissions are saved.', ToastType.SUCCESS);
        closeHandler(true);
      }
    } catch (e) {
      /* empty */
    }
    setIsSaving(false);
  },
});

const getManageResourcePermissionsDialogActions = (
  appState: AddNewResourcePermissionPayload['appState'],
  setIsSaving: (isSaving: boolean) => void,
  closeHandler: (saved: true) => void
): ManageResourcePermissionsDialogProps['actions'] => ({
  ...createOnActorSelect(appState),
  ...getOnSaveChanges(setIsSaving, closeHandler),
  onGrantReadAccessUnderlyingResource: props =>
    dispatchAction(grantReadAccessUnderlyingResource(props)),
  onPermissionChanges: changes => {
    trackResourcesPermissionEditorEvent(
      ResourcesPermissionEditorEvents.CHANGED_RESOURCE_PERMISSIONS_FOR_AN_ACTOR,
      {
        isGroup: groupActorOps.isGroupActor(changes.actor),
      }
    );
    dispatchAction(permissionChange(changes));
  },
  onRevokePermission: permission => {
    trackResourcesPermissionEditorEvent(
      ResourcesPermissionEditorEvents.REVOKED_RESOURCE_PERMISSIONS_FOR_AN_ACTOR
    );
    dispatchAction(revokePermission(permission));
  },
  onAddResourceToSubdivision: (resourceId, subdivision) => {
    dispatchAction(
      addResourceToSubdivisionInPermissionsEditor({ resourceId, subdivision })
    );
  },
});
