import {
  APIWorkspaceFolder,
  ArdoqId,
  AssetFolder,
  AssetType,
  ContentItem,
  ContentByType,
  ResourceType,
} from '@ardoq/api-types';
import { groupBy, mapValues } from 'lodash';
import { findNestedContent } from './utils';
import { persistentReducedStream, streamReducer } from '@ardoq/rxbeach';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { fetchAllReducer, websocketReducer } from 'streams/crud/reducers';

export type AssetFoldersState = AssetFolder[];

const groupByType = (content: ContentItem[]): ContentByType => ({
  [AssetType.FOLDER]: [],
  [AssetType.METAMODEL]: [],
  [AssetType.PRESENTATION]: [],
  [AssetType.SCENARIO]: [],
  [AssetType.SURVEY]: [],
  [AssetType.WORKSPACE]: [],
  [AssetType.REPORT]: [],
  [AssetType.DASHBOARD]: [],
  [AssetType.TRAVERSAL]: [],
  [AssetType.BOOKMARK]: [],
  [AssetType.VIEWPOINT]: [],
  [AssetType.BROADCAST]: [],
  ...mapValues(groupBy(content, 'type'), value => value.map(({ _id }) => _id)),
});

const toAssetFolder = (
  folder: APIWorkspaceFolder,
  nestedContentHash: Record<ArdoqId, ContentItem[]>
): AssetFolder => ({
  ...folder,
  contentByType: groupByType(folder.content),
  nestedContent: nestedContentHash[folder._id] || [],
  nestedContentByType: groupByType(nestedContentHash[folder._id] || []),
  meta: {},
});

const resetFolders = (
  _: AssetFoldersState,
  folders: APIWorkspaceFolder[]
): AssetFoldersState => {
  const nestedContentHash = findNestedContent(folders);
  return folders.map(folder => toAssetFolder(folder, nestedContentHash));
};

const deleteFolder = (
  state: AssetFoldersState,
  id: ArdoqId
): AssetFoldersState => state.filter(m => m._id !== id);

const updateFolder = (
  state: AssetFoldersState,
  folder: APIWorkspaceFolder
): AssetFoldersState => {
  const newModels = state.map(existingFolder =>
    existingFolder._id === folder._id ? folder : existingFolder
  );

  return resetFolders(state, newModels);
};

const createFolder = (
  state: AssetFoldersState,
  folder: APIWorkspaceFolder
): AssetFoldersState => resetFolders(state, [folder, ...state]);

export default persistentReducedStream<AssetFoldersState>(
  'assetFolder$',
  [],
  [
    fetchAllReducer(resetFolders),
    streamReducer(
      toResourceTypeStream<APIWorkspaceFolder>(
        websocket$,
        ResourceType.WORKSPACEFOLDER
      ),
      websocketReducer({
        create: createFolder,
        update: updateFolder,
        delete: deleteFolder,
      })
    ),
  ],
  { namespace: 'workspacefolder' }
);
