import { OpenEntityMergeParams } from './types';
import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  FetchHistoryAPIResult,
} from '@ardoq/api-types';
import { enhanceScopeData, mergeMultipleScopeData } from '@ardoq/renderers';
import { useEffect, useState } from 'react';
import { collectComponentIdsFromHistory } from './utils';
import { fetchEntityScopeData } from './dataUtils';
import { dateRangeOperations } from '@ardoq/date-range';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { versionApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

type UseHistoryDataState = {
  isLoading: boolean;
  hasError: boolean;
  enhancedScopeData: EnhancedScopeData | null;
  historyByVersion: { [version: string]: FetchHistoryAPIResult };
};

const initialState: UseHistoryDataState = {
  isLoading: false,
  hasError: false,
  enhancedScopeData: null,
  historyByVersion: {},
};

export const useHistoryData = ({
  entityId,
  entityType,
  versionsToFetchCount,
}: OpenEntityMergeParams): UseHistoryDataState => {
  const [state, setState] = useState<UseHistoryDataState>(initialState);

  useEffect(() => {
    (async () => {
      setState({ ...initialState, isLoading: true });

      //
      // Those 2 calls can run in parallel
      //
      const [history, entityScope] = await Promise.all([
        versionApi.history({
          id: entityId,
          type: entityType,
          maxFetchedVersions: versionsToFetchCount,
        }),
        fetchEntityScopeData({
          entityType,
          entityIds: [entityId],
        }),
      ]);

      if (isArdoqError(history)) {
        logError(history, 'Error loading history merge data');
        setState({ ...initialState, hasError: true });
        return;
      }
      if (isArdoqError(entityScope)) {
        logError(entityScope, 'Error loading entity scope data');
        setState({ ...initialState, hasError: true });
        return;
      }

      // Browsing historical data to look for componentIds
      const entityHistoryScope = await fetchEntityScopeData({
        entityType: APIEntityType.COMPONENT,
        entityIds: collectComponentIdsFromHistory(history, entityType),
      });

      if (isArdoqError(entityHistoryScope)) {
        logError(entityHistoryScope, 'Error loading component scope data');
        setState({ ...initialState, hasError: true });
        return;
      }

      const enhancedScopeData = enhanceScopeData(
        dateRangeOperations.getMergedDateRangeFieldForScopeData(
          mergeMultipleScopeData(entityScope, entityHistoryScope)
        )
      );
      const historyByVersion = history.reduce<
        Record<string, FetchHistoryAPIResult>
      >(
        (byVersion, historyApiResult) => ({
          ...byVersion,
          [historyApiResult._version]:
            enhancedScopeData.dateRangeFieldMap &&
            historyApiResult.rootWorkspace
              ? dateRangeOperations.mergeDateRangeFieldsForEntity(
                  historyApiResult as
                    | APIComponentAttributes
                    | APIReferenceAttributes,
                  enhancedScopeData.dateRangeFieldMap
                )
              : historyApiResult,
        }),
        {}
      );
      setState({ ...initialState, enhancedScopeData, historyByVersion });
    })();
  }, [entityId, entityType, versionsToFetchCount]);

  return state;
};
