import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { logError } from '@ardoq/logging';
import { api, handleError, statusApi } from '@ardoq/api';
import { redirectToLogin } from '../authentication';

const updateStatus$ = new BehaviorSubject<{ timeout?: number }>({
  timeout: 10000,
});

const status$ = updateStatus$.pipe(
  switchMap(({ timeout = 10000 }) => {
    return statusApi.fetchStatus(timeout);
  }),
  handleError(error => {
    logError(error);
    if (api.isUnauthorized(error)) {
      // If /api/status returns 401, the user is being blocked by the IP allowlist filter
      // Redirect to login to display appropriate error page
      redirectToLogin();
    } else {
      // Continue the chain, so that the error can be handled by the caller
      throw error;
    }
  }),
  shareReplay({
    bufferSize: 1,
    refCount: true,
  })
);

export const getStatus = ({
  shouldRefresh = false,
  timeout,
}: { shouldRefresh?: boolean; timeout?: number } = {}) => {
  if (shouldRefresh) {
    updateStatus$.next({ timeout });
  }
  return firstValueFrom(status$);
};

export const getAnalyticsKeys = () =>
  getStatus().then(status => ({
    intercomKey: status?.analytics,
    supportUrl: status?.['support-url'],
  }));
