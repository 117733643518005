import { IntegrationInitialState } from 'integrations/common/initialState';
import { buildInitialTermsState } from 'integrations/common/streams/integrationTermsDictionary/initialState';
import { microsoftEntraIdDictionary } from 'integrations/common/dictionary';

export const initialState: IntegrationInitialState = {
  activeIntegration: {
    integrationId: 'microsoft-entra-id',
    integrationMappingParams: {
      mapColumnsBy: 'sourceFieldName',
      mapTablesBy: 'id',
    },
  },
  termsDictionary: buildInitialTermsState(microsoftEntraIdDictionary),
};
