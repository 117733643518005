import { subscribeToAction } from 'streams/utils/streamUtils';
import { sessionExpired } from './actions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { isEqual } from 'lodash';
import { userApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

export function installFocusAuthenticatedChecker() {
  let focused = true;
  let doCheck = true;

  subscribeToAction(sessionExpired, () => {
    doCheck = false;
  });

  window.onfocus = function () {
    if (doCheck && !focused) {
      focused = true;
      userApi.fetchCurrentUser().then(resp => {
        if (isArdoqError(resp)) {
          doCheck = false;
          return;
        }
        if (!isEqual(resp, currentUserInterface.getCurrentUserAttributes())) {
          currentUserInterface.setCurrentUserAttributes(resp);
          return;
        }
      });
    }
  };
  window.onblur = function () {
    focused = false;
  };
}
