import { actionCreator } from '@ardoq/rxbeach';
import {
  APICurrentUser,
  APICurrentUserPermission,
  APIResourcePermissionAttributes,
} from '@ardoq/api-types';
import { UserResourcePermissionsRecord } from '@ardoq/access-control';

export const apiFetchCurrentUserPermissions = actionCreator(
  '[currentUserPermissions] FETCH_CURRENT_USER_PERMISSIONS'
);

export const setCurrentUserPermissions =
  actionCreator<UserResourcePermissionsRecord>(
    '[currentUserPermissions] SET_CURRENT_USER_PERMISSIONS'
  );

export const fetchedCurrentUserPermissions = actionCreator<
  APICurrentUserPermission[]
>('[currentUserPermissions] FETCHED_CURRENT_USER_PERMISSIONS');

export const failedFetchCurrentUserPermissions = actionCreator<unknown>(
  '[currentUserPermissions] FAILED_FETCH_CURRENT_USER_PERMISSIONS'
);

export const addPermissionForResource = actionCreator<{
  permission: APIResourcePermissionAttributes;
  currentUser: APICurrentUser;
}>('[currentUserPermissions] ADD_PERMISSION_FOR_RESOURCE');
