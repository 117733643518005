import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { PermissionGroup$State } from '../types';
import {
  setError,
  setPermissionGroups,
  setSearchPhraseAction,
} from './actions';
import { permissionGroupOperations } from '../permissionGroupsOperations';

const defaultState: PermissionGroup$State = {
  groupsById: {},
  error: null,
  searchPhrase: '',
};

const permissionGroup$ = persistentReducedStream<PermissionGroup$State>(
  'permissionGroup',
  defaultState,
  [
    reducer(
      setPermissionGroups,
      permissionGroupOperations.handleSetPermissionGroups
    ),
    reducer(setError, permissionGroupOperations.handleSetError),
    reducer(setSearchPhraseAction, permissionGroupOperations.setSearchPhrase),
  ]
);

export default permissionGroup$;
