import { catchError, defer, EMPTY, map, of, startWith, switchMap } from 'rxjs';
import { orgTriples$ } from '../../streams/orgTriples$';
import { APIOrgTripleAttributes } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';

type SourceComponentTypeName = string;
type TargetComponentTypeName = string;
type ReferenceTypeName = string;
export type IntendedRefTypes = Record<
  SourceComponentTypeName,
  Record<TargetComponentTypeName, ReferenceTypeName[]>
>;

const buildIntendedRefTypesMap = (
  triples: APIOrgTripleAttributes[]
): IntendedRefTypes => {
  const intendedRefTypes: IntendedRefTypes = {};
  for (const triple of triples) {
    if (!intendedRefTypes[triple.sourceType]) {
      intendedRefTypes[triple.sourceType] = {};
    }
    if (!intendedRefTypes[triple.sourceType][triple.targetType]) {
      intendedRefTypes[triple.sourceType][triple.targetType] = [];
    }
    intendedRefTypes[triple.sourceType][triple.targetType].push(
      triple.referenceType
    );
  }
  return intendedRefTypes;
};

const enforceOrgTriples$ = defer(() =>
  of(hasFeature(Features.ENFORCE_ORG_TRIPLES))
);

const mappedIntendedRefTypes$ = orgTriples$.pipe(map(buildIntendedRefTypesMap));

export const intendedRefTypes$ = enforceOrgTriples$.pipe(
  switchMap(enforceOrgTriples => {
    if (enforceOrgTriples) {
      return mappedIntendedRefTypes$;
    }
    return EMPTY;
  }),
  catchError(err => {
    logError(
      err,
      'Error when building intended ref types map from org triples'
    );
    // If there is an error in the stream, we don't retry.
    // The stream will be re-subscribed the next time the user opens the properties editor.
    return EMPTY;
  }),
  // Always emit `undefined` so the properties editor can always render.
  startWith(undefined)
);
