import {
  SurveyNotUpdatedSinceFilterCondition,
  BroadcastFilterConditionType,
  BroadcastFilterCondition,
} from '@ardoq/api-types';
import { DEFAULT_TIME_SINCE_LAST_UPDATE } from 'broadcasts/consts';
import { getFilterConditionByType } from 'broadcasts/utils';

export const newSurveyNotUpdatedSinceCondition = (
  timeSinceLastUpdate = DEFAULT_TIME_SINCE_LAST_UPDATE
): SurveyNotUpdatedSinceFilterCondition => ({
  conditionType: BroadcastFilterConditionType.SURVEY_NOT_UPDATED_IN,
  timeSinceLastUpdate,
});

export const getDefaultState = () => ({
  isEnabled: false,
  surveyNotUpdatedSinceCondition: newSurveyNotUpdatedSinceCondition(),
});

export const findSurveyNotUpdatedSinceCondition = (
  filterConditions: BroadcastFilterCondition[]
) => {
  return getFilterConditionByType(
    filterConditions,
    BroadcastFilterConditionType.SURVEY_NOT_UPDATED_IN
  );
};
