import {
  PermissionContext,
  canDo,
  permissionsOperations,
} from '@ardoq/access-control';
import { ArdoqId } from '@ardoq/api-types';

const canCreateTraversal = (permissionContext: PermissionContext): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }
  return (
    permissionsOperations.isOrgAdmin(permissionContext.user) ||
    permissionsOperations.isOrgWriter(permissionContext.user)
  );
};

const canAdminTraversal = (
  permissionContext: PermissionContext,
  traversalId: ArdoqId
): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    permissionContext,
    action: 'traversal/admin',
    resourceId: traversalId,
  });
};

const canEditTraversal = (
  permissionContext: PermissionContext,
  traversalId: ArdoqId
): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    permissionContext,
    action: 'traversal/edit',
    resourceId: traversalId,
  });
};

const canAccessTraversal = (
  permissionContext: PermissionContext,
  traversalId: ArdoqId
): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    permissionContext,
    action: 'traversal/read',
    resourceId: traversalId,
  });
};

export const traversalAccessControlInterface = {
  canAdminTraversal,
  canEditTraversal,
  canAccessTraversal,
  canCreateTraversal,
};
