import DialogBodyBlock from './DialogBodyBlock';
import { ModalStep } from './types';
import AboutScenarios from './AboutScenarios';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { ModalBody, ModalFooter, ModalHeader } from '@ardoq/modal';

const getProgressTitleText = (step: ModalStep): string => {
  if (step === ModalStep.PRELOADING_COMPONENTS) {
    return 'Loading components...';
  } else if (step === ModalStep.PRELOADING_REFERENCES) {
    return 'Loading references...';
  } else if (step === ModalStep.CREATING_SCENARIO) {
    return 'Creating scenario...';
  } else if (step === ModalStep.UPDATING_SCENARIO) {
    return 'Adding items to scenario...';
  }
  return '';
};

type ProgressStepProps = {
  step: ModalStep;
  onCancel: () => void;
};

const stepsWithLoader = new Set([
  ModalStep.CREATING_SCENARIO,
  ModalStep.UPDATING_SCENARIO,
]);

const ProgressStep = ({ onCancel, step }: ProgressStepProps) => {
  if (
    [
      ModalStep.CREATING_SCENARIO,
      ModalStep.UPDATING_SCENARIO,
      ModalStep.PRELOADING_COMPONENTS,
      ModalStep.PRELOADING_REFERENCES,
    ].includes(step)
  ) {
    return (
      <>
        <ModalHeader
          title={getProgressTitleText(step)}
          onCloseButtonClick={onCancel}
        />
        <ModalBody>
          {stepsWithLoader.has(step) ? (
            <DialogBodyBlock animate>
              <AboutScenarios />
            </DialogBodyBlock>
          ) : (
            <div />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <SecondaryButton isDisabled>Close</SecondaryButton>
            <PrimaryButton isDisabled>Open scenario</PrimaryButton>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  }
  return null;
};

export default ProgressStep;
