import { reducer } from '@ardoq/rxbeach';
import { navigateToNewBroadcastForm } from 'router/navigationActions';
import { getDefaultFilterConditionState } from 'broadcasts/utils';
import { toggleFilterConditionsExpanded } from './actions';
import { FilterConditionsStreamShape } from './types';
import {
  chooseBroadcastContentType,
  initFilterConditions,
} from 'broadcasts/actions';
import type { BroadcastFilterCondition } from '@ardoq/api-types';

const handleInitFilterConditions = (
  _: FilterConditionsStreamShape,
  filterConditions: BroadcastFilterCondition[]
) => ({
  isExpanded: Boolean(filterConditions.length),
});

const handleChooseBroadcastContentType = () => ({
  isExpanded: false,
});

const handleToggleFilterConditionsExpanded = (
  state: FilterConditionsStreamShape
) => ({
  isExpanded: !state.isExpanded,
});

export const reducers = [
  reducer(navigateToNewBroadcastForm, getDefaultFilterConditionState),
  reducer(initFilterConditions, handleInitFilterConditions),
  reducer(chooseBroadcastContentType, handleChooseBroadcastContentType),
  reducer(toggleFilterConditionsExpanded, handleToggleFilterConditionsExpanded),
];
