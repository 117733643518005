import { useState } from 'react';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { SchedulePolicySelector } from 'integrations/common/components/schedulePolicySelector/SchedulePolicySelector';
import {
  IntegrationJobPayload,
  IntegrationSchedule,
  SchedulingPolicy,
} from '@ardoq/api-types';
import { Checkbox } from '@ardoq/forms';
import { Link } from '@ardoq/typography';
import { dispatchAction } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { updateSchedule } from 'integrations/common/streams/schedules/actions';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { getScheduleDirection } from 'integrations/common/streams/schedules/utils';
import { capitalize } from '@ardoq/common-helpers';
import { omit } from 'lodash';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';

type EditScheduleModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  schedule: IntegrationSchedule;
  integrationId: IntegrationId;
};

const EditScheduleModal = ({
  onSubmit,
  onCancel,
  schedule,
  integrationId,
}: EditScheduleModalProps) => {
  const [schedulingPolicy, setSchedulingPolicy] = useState<SchedulingPolicy>(
    schedule.schedulingPolicy
  );
  const [managesWorkspace, setManagesWorkspace] = useState<boolean | undefined>(
    isJobWithTabularMapping(schedule.jobOptions)
      ? schedule.jobOptions.managesWorkspace
      : undefined
  );

  const handleSaveChanges = () => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId,
        name: 'SELECTED_SCHEDULE_INTERVAL',
        metadata: {
          source: 'overview-page-modal',
          interval: schedulingPolicy.type,
          day:
            schedulingPolicy.type === 'weekly'
              ? schedulingPolicy.weekDay
              : null,
          managesWorkspace: managesWorkspace ? 'on' : 'off',
        },
      })
    );
    dispatchAction(
      updateSchedule({
        integrationId,
        schedule: {
          ...omit(schedule, 'meta'),
          schedulingPolicy,
          jobOptions: {
            ...schedule.jobOptions,
            ...(managesWorkspace !== undefined ? { managesWorkspace } : {}),
          },
        } as IntegrationJobPayload,
      })
    );
    onSubmit();
  };

  return (
    <ModalTemplate
      modalSize={ModalSize.S}
      headerText={`Edit interval`}
      primaryButtonText="Save changes"
      secondaryButtonText="Close"
      onPrimaryButtonClick={handleSaveChanges}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <SchedulePolicySelector
          title={`${capitalize(
            getScheduleDirection(schedule.jobOptions.type)
          )} interval`}
          schedulePolicy={schedulingPolicy}
          onSchedulePolicyChange={setSchedulingPolicy}
        ></SchedulePolicySelector>
        {managesWorkspace !== undefined && (
          <Checkbox
            onChange={() => setManagesWorkspace(prevValue => !prevValue)}
            isChecked={managesWorkspace}
          >
            <div>
              Disable manual data changes in the workspace.
              <br />
              <span>
                Update only data from the scheduled import. Learn more about{' '}
                <Link
                  href="https://help.ardoq.com/en/articles/44033-managed-workspaces"
                  target="_blank"
                >
                  data changes
                </Link>
              </span>
            </div>
          </Checkbox>
        )}
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startEditScheduleIntervalModal = (
  integrationId: IntegrationId,
  schedule: IntegrationSchedule
) =>
  modal<boolean>(
    resolve => (
      <EditScheduleModal
        onSubmit={() => resolve(true)}
        onCancel={() => resolve(false)}
        integrationId={integrationId}
        schedule={schedule}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
