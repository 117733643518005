import {
  loadAggregateWorkspaces,
  openEntityHistoryModal,
  sessionExpired,
  notifySyncConflict,
} from './actions';

import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { tap, withLatestFrom } from 'rxjs/operators';
import { logError, logWarn } from '@ardoq/logging';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import References from 'collections/references';
import Components from 'collections/components';
import Tags from 'collections/tags';
import { api, workspaceApi } from '@ardoq/api';
import { renderEventForbiddenError } from './syncManagerViewError';
import { updateLinkedWorkspaces } from 'models/actions';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';
import { APIComponentAttributes } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import * as profiling from '@ardoq/profiling';
import { redirectToLogin } from 'authentication';
import * as wamp from 'sync/wamp';
import { WSClosingReason } from './types';
import {
  setIsLoading,
  setIsLoadingWithConfig,
} from 'components/GlobalPaneLoader/globalPaneLoader$';
import { formatListAsEnglishProse, isArdoqError } from '@ardoq/common-helpers';
import { renderEntityHistoryModal } from './EntityHistoryModal/EntityHistoryModalConnected';
import {
  getSyncConflictModalTextProps,
  getSyncConflictModalTextPropsWithBlame,
  openSyncConflictModal,
} from '@ardoq/modal';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { trackEvent } from '../tracking/tracking';

const handleSyncConflict = routine(
  ofType(notifySyncConflict),
  extractPayload(),
  withLatestFrom(orgUsers$),
  tap(async ([payload, orgUsers]) => {
    trackEvent('Entity sync error detected', payload.meta);

    const { userId } = payload;
    const member = orgUsers.byId[userId ?? ''];
    const modalTextProps = member
      ? getSyncConflictModalTextPropsWithBlame(member)
      : getSyncConflictModalTextProps();

    await openSyncConflictModal({
      ...modalTextProps,
      onPrimaryButtonClick: () => location.reload(),
      onSecondaryButtonClick: resolve => resolve(false),
    });
  })
);

const openEntityHistoryModalRoutine = routine(
  ofType(openEntityHistoryModal),
  extractPayload(),
  tap(payload => {
    renderEntityHistoryModal(payload);
  })
);

const loadAggregateWorkspacesRoutine = routine(
  ofType(loadAggregateWorkspaces),
  extractPayload(),
  tap(async ({ workspaces, transaction }) => {
    const span = profiling.startSpan(transaction, 'load aggregate workspaces');
    const uninitializedWorkspaces = workspaces.filter(
      ws => !ws.aggregateLoaded && !ws.loading
    );
    if (!uninitializedWorkspaces.length) return;
    if (isScenarioMode()) {
      logWarn(
        new Error(
          `Blocked attempt to load ${uninitializedWorkspaces.length} extra workspace(s) when in scenario mode. The source of this trigger should be fixed.`
        )
      );
      return;
    }
    const title = formatListAsEnglishProse(
      workspaces.map(ws => ws.get('name'))
    );

    dispatchAction(
      setIsLoadingWithConfig({
        title: `Loading "${title}"\u2026`,
      })
    );

    uninitializedWorkspaces.forEach(ws => (ws.loading = true));
    const loadDataSpan = profiling.startSpan(transaction, 'load data');

    const result = await workspaceApi.bulkLoadWorkspaceAggregated(
      uninitializedWorkspaces.map(({ id }) => id)
    );

    if (isArdoqError(result)) {
      uninitializedWorkspaces.forEach(ws => (ws.loading = false));
      if (api.isForbidden(result)) {
        await renderEventForbiddenError();
      }
      workspaces.forEach(ws => ws.trigger('errorLoading', result));
      dispatchAction(setIsLoading(false));
      return;
    }
    const { components, references, tags } = result;
    profiling.endSpan(loadDataSpan);
    logMissingWorkspaces(components);
    const bulkAddOptions = { silent: true, sort: false };
    dispatchAction(updateLinkedWorkspaces({ references: references ?? [] }));
    profiling.runWithSpan(transaction, 'init backbone collections', () => {
      Components.collection.add(components, bulkAddOptions);
      References.collection.add(references ?? [], bulkAddOptions);
      Tags.collection.add(tags, bulkAddOptions);
    });

    profiling.runWithSpan(transaction, 'build cache', () =>
      References.collection.buildCacheForSourceAndTargetMaps()
    );

    uninitializedWorkspaces.forEach(workspace => {
      Components.collection.hierarchy.clearWorkspaceHierarchy(workspace.id);
      workspace.aggregateLoaded = true;
      workspace.loading = false;
      dispatchAction(
        notifyWorkspaceAggregatedLoaded({ workspaceId: workspace.getId() })
      );
    });
    Components.collection.trigger('sync');
    References.collection.trigger('sync');
    Tags.collection.trigger('sync');
    profiling.endSpan(span);
    dispatchAction(setIsLoading(false));
  })
);

const logMissingWorkspaces = (components: APIComponentAttributes[]) => {
  const componentsWithMissingWorkspaces = components.filter(
    ({ rootWorkspace }) => !workspaceInterface.isWorkspace(rootWorkspace)
  );
  if (componentsWithMissingWorkspaces.length > 0) {
    logError(Error('Missing workspaces in workspace/subgraph api call'), null, {
      missingWorkspaces: componentsWithMissingWorkspaces.map(
        ({ rootWorkspace }) => rootWorkspace
      ),
      componentsWithMissingWorkspaces: componentsWithMissingWorkspaces.map(
        ({ _id }) => _id
      ),
    });
  }
};

const handleSessionExpiredRoutine = routine(
  ofType(sessionExpired),
  tap(() => {
    wamp.close({ reason: WSClosingReason.SESSION_EXPIRED });
    redirectToLogin();
  })
);

export default collectRoutines(
  handleSyncConflict,
  loadAggregateWorkspacesRoutine,
  openEntityHistoryModalRoutine,
  handleSessionExpiredRoutine
);
