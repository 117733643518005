import { actionCreator } from '@ardoq/rxbeach';
import { AssetsPayload, RenameRequest } from '@ardoq/api-types';

export const deleteAssets = actionCreator<AssetsPayload>(
  '[assets] DELETE_ASSETS'
);

export const deleteAssetsSuccess = actionCreator<AssetsPayload>(
  '[assets] DELETE_ASSETS_SUCCESS'
);

export const renameAsset = actionCreator<RenameRequest>(
  '[assets] RENAME_ASSET'
);
