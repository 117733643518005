import { ContextSort, SortAttribute } from '@ardoq/common-helpers';
import { ArdoqId } from '@ardoq/api-types';
import { dateRangeOperations, isDateRangeFieldType } from '@ardoq/date-range';
import { fieldInterface } from '@ardoq/field-interface';
import * as encodingUtils from '@ardoq/html';
import { SelectOption } from '@ardoq/select';

export const getFieldOptions = (
  workspaceId: ArdoqId
): SelectOption<string>[] => {
  return (
    dateRangeOperations
      // can remove the mergeDateTimeFieldsToDateRangeFields call when Fields.collection has proper date range fields
      .mergeDateTimeFieldsToDateRangeFields(
        fieldInterface.getAllFieldsOfWorkspace(workspaceId)
      )
      .fields.flatMap(field => {
        if (isDateRangeFieldType(field.type)) {
          return dateRangeOperations.splitDateRangeFieldIntoStartAndEndSelectOptions(
            field
          );
        }

        const value = encodingUtils.escapeHTML(field.name);
        const escapedLabel = encodingUtils.escapeHTML(field.label);
        const label =
          escapedLabel && escapedLabel.trim() !== '' ? escapedLabel : value;
        return {
          label,
          value,
        };
      })
  );
};

type GetSortOptionsParams = {
  fieldOptions: SelectOption<string>[];
  currentSort?: ContextSort;
};
enum IconDirection {
  UP = 'up',
  DOWN = 'down',
}
interface SortOption {
  label: string;
  value: string;
  selected: boolean;
  iconDirection: IconDirection;
}
const getSortOptions = ({
  fieldOptions,
  currentSort,
}: GetSortOptionsParams): SortOption[] =>
  [
    { label: 'Order', value: SortAttribute.ORDER },
    { label: 'Name', value: 'name' },
    { label: 'Ardoq ID', value: 'component-key' },
    { label: 'Parent', value: 'parent' },
    { label: 'Type', value: 'typeId' },
    { label: 'Created', value: 'created' },
    { label: 'Last updated', value: 'lastUpdated' },
    ...fieldOptions,
  ].map(option => {
    const isCurrent = option.value === (currentSort || {}).attr;
    const iconDirection =
      isCurrent && currentSort?.order !== 0
        ? IconDirection.UP
        : IconDirection.DOWN;

    return {
      ...option,
      selected: isCurrent,
      iconDirection,
    };
  });

export default getSortOptions;
