import { logError } from '@ardoq/logging';
import { resolveComponentsPath } from './utils';
import { DataSource, MergeState } from './types';
import {
  APIComponentType,
  APIEntityType,
  ArdoqId,
  Verb,
} from '@ardoq/api-types';
import { getStatusFromChildren } from './getStatusFromChildren';
import { Branch } from './Branch';
import { componentUtils } from '@ardoq/scope-data';
import type { EnhancedScopeData } from '@ardoq/data-model';

export const getNewDataSource = (
  dataSource: DataSource,
  index: number,
  mergeStatus: MergeState
): DataSource =>
  Object.assign([], dataSource, {
    [index]: { ...dataSource[index], status: mergeStatus },
  });

const updateChildren = (
  entry: DataSource[0],
  dataSource: DataSource,
  state: MergeState
) => {
  entry.children?.forEach(childIndex => {
    const child = dataSource[childIndex];
    if (!child.isDisabled) {
      Object.assign(dataSource, { [childIndex]: { ...child, status: state } });
      updateChildren(child, dataSource, state);
    }
  });
};

export const updateParent = (entry: DataSource[0], dataSource: DataSource) => {
  if (entry.parent === null) {
    return;
  }
  const parent = dataSource[entry.parent];
  if (!parent) {
    return;
  }

  const siblingRows = parent.children!.map(
    childIndex => dataSource[childIndex]
  );

  const parentState = getStatusFromChildren(siblingRows, siblingRows[0].status);
  Object.assign(dataSource, {
    [parent.index]: { ...parent, status: parentState },
  });

  updateParent(parent, dataSource);
};

const retrieveAncestorsFromChildToParent = (
  componentId: ArdoqId,
  childToParent: Record<ArdoqId, ArdoqId>
): ArdoqId[] => {
  const parentId = childToParent[componentId];
  if (parentId) {
    return [
      parentId,
      ...retrieveAncestorsFromChildToParent(parentId, childToParent),
    ];
  }
  return [];
};

const getComponentTypeAncestorIds = (
  componentTypeId: ArdoqId,
  componentTypesById: Record<ArdoqId, APIComponentType>
) => {
  const childToParent = Object.entries(componentTypesById).reduce(
    (acc, [parentComponentTypesById, componentType]) => {
      Object.keys(componentType.children).forEach(childComponentTypeId => {
        acc[childComponentTypeId] = parentComponentTypesById;
      });
      return acc;
    },
    {} as Record<ArdoqId, ArdoqId>
  );
  return retrieveAncestorsFromChildToParent(componentTypeId, childToParent);
};

const getComponentTypeDescendantIds = (
  componentTypeId: ArdoqId,
  componentTypesById: Record<ArdoqId, APIComponentType>
): ArdoqId[] => {
  const componentTypeChildren =
    componentTypesById[componentTypeId]?.children || [];
  return Object.keys(componentTypeChildren).flatMap(componentTypeId => [
    componentTypeId,
    ...getComponentTypeDescendantIds(componentTypeId, componentTypesById),
  ]);
};

const getAncestorIds = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  parentEntityId?: ArdoqId
) => {
  if (entityType === APIEntityType.COMPONENT) {
    return resolveComponentsPath(entityId, enhancedScopeData).map(
      ({ _id }) => _id
    );
  }
  if (entityType === APIEntityType.COMPONENT_TYPE && parentEntityId) {
    const componentTypesById =
      enhancedScopeData.typesByModelId[parentEntityId].componentTypesById;
    return getComponentTypeAncestorIds(entityId, componentTypesById);
  }
  return [];
};

const getDescendantIds = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  parentEntityId?: ArdoqId
) => {
  if (entityType === APIEntityType.COMPONENT) {
    return componentUtils.getDescendantIds(enhancedScopeData, entityId);
  }
  if (entityType === APIEntityType.COMPONENT_TYPE && parentEntityId) {
    const componentTypesById =
      enhancedScopeData.typesByModelId[parentEntityId].componentTypesById;
    return getComponentTypeDescendantIds(entityId, componentTypesById);
  }
  return [];
};

export const getNewDataSourceAndUpdateParentAndChildren = (
  dataSource: DataSource | null,
  index: number,
  mergeStatus: MergeState
): DataSource | null => {
  const rowSource = dataSource?.[index];
  if (!dataSource || !rowSource) {
    logError(
      Error('no dataSource or index out of range in handleSetMergeState')
    );
    return null;
  }
  if (rowSource.isDisabled) return dataSource;
  const newDataSource = getNewDataSource(dataSource, index, mergeStatus);
  if (newDataSource[index].children?.length)
    updateChildren(newDataSource[index], newDataSource, mergeStatus);
  updateParent(newDataSource[index], newDataSource);
  return newDataSource;
};

export const getRowIndexesToApplyAutoselectOnAncestorsOrDescendants = (
  dataSource: DataSource | null,
  index: number,
  mergeStatus: MergeState
) => {
  if (!dataSource || dataSource.length === 0) return [];
  const isSelecting = mergeStatus === MergeState.SOURCE;
  const selectedRow = dataSource[index];
  const {
    entityId,
    parentEntityId,
    entityType,
    enhancedDiffContextData,
    path: [, verb],
  } = selectedRow;
  if (verb !== Verb.CREATE && verb !== Verb.DELETE) return [];
  const branch =
    enhancedDiffContextData[
      verb === Verb.DELETE ? Branch.TARGET : Branch.SOURCE
    ];
  const componentIdToRowIndexMap = new Map(
    dataSource.map(({ entityId, index }) => [entityId, index])
  );
  const shouldAutoselectAncestors =
    (verb === Verb.DELETE && !isSelecting) ||
    (verb === Verb.CREATE && isSelecting);

  const componentIds = shouldAutoselectAncestors
    ? getAncestorIds(entityId, entityType, branch, parentEntityId)
    : getDescendantIds(entityId, entityType, branch, parentEntityId);

  return componentIds
    .filter(_id => componentIdToRowIndexMap.has(_id))
    .map(_id => componentIdToRowIndexMap.get(_id)) as number[];
};
