import { getAllDefaultAttributeNames } from '@ardoq/renderers';

export const RESERVED_ATTRIBUTE_NAMES = [
  ...getAllDefaultAttributeNames(),
  'ardoq',
  'ardoq-entity-type',
];

export const RIGID_TYPE_WARNING_MSG =
  'Can not modify type of parents in rigid model';

export const TAG_REGEX = /^[a-z\d_\-æøåäöëèé]+$/i;

export const FIELD_NAME_REGEX = /[A-Za-z][A-Za-z0-9_]*/;

export const INVALID_FIELD_NAME_MESSAGE = 'Invalid field name';

export const INVALID_LIST_FIELD_VALUE_MESSAGE =
  'Option values cannot contain commas.';

export const REMOVE_FIELD_MESSAGE = 'Remove field';

export const OLD_DEFAULT_FIELD_ORDER = 10000;

export const TAG_NAME_VALIDATION_MESSAGE = `Can only include regular alphabet characters, numbers, “_” and “-”`;

export const ENTER_TAG_NAME_MESSAGE =
  'Enter a name for your new tag. Alphanumeric characters including "_" and "-" are allowed.';

export const INVALID_TAG_NAME_MESSAGE = `Whitespaces and special characters are not allowed.
              Valid example: sys_API-1`;
