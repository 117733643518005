import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import {
  fetchCalculatedFieldOptions,
  updateCalculatedFieldOptions,
} from './actions';
import { updateCalculatedFieldValue } from 'search/actions';
import { mapFieldUsageToCalcualtedFieldOptions } from './helpers';
import { queryGremlinSearchWarning } from 'search/Gremlin/actions';
import { SearchType } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import { fieldApi, handleError } from '@ardoq/api';
import { getArdoqErrorMessage } from '@ardoq/common-helpers';

const handleFetchCalculatedFieldOptions = routine(
  ofType(fetchCalculatedFieldOptions),
  extractPayload(),
  switchMap(({ fieldId }) => fieldApi.usage(fieldId)),
  handleError(error => {
    dispatchAction(
      queryGremlinSearchWarning({
        searchWarning: getArdoqErrorMessage(error),
      }),
      SearchType.CALCULATED_FIELD_QUERY
    );
    logError(error);
  }),
  map(({ workspaces }) => mapFieldUsageToCalcualtedFieldOptions(workspaces)),
  tap(({ calculatedFieldOptions }) =>
    dispatchAction(
      updateCalculatedFieldOptions({
        calculatedFieldOptions,
      })
    )
  ),
  filter(({ calculatedFieldOptions }) => calculatedFieldOptions.length >= 1),
  tap(({ calculatedFieldOptions: [{ ids, label }] }) =>
    dispatchAction(
      updateCalculatedFieldValue({
        ids,
        calculatedFieldSelectedOption: label,
      }),
      QueryEditorNamespace.CALCULATED_FIELD_QUERY
    )
  )
);

export default collectRoutines(handleFetchCalculatedFieldOptions);
