import { ConcatTransformation } from '@ardoq/api-types';
import { EditorSectionProps } from './TransformationsEditor';
import { SelectOption, SortableMultiselect } from '@ardoq/select';
import { Field, TextInput } from '@ardoq/forms';
import { AutocompleteFieldSuggestion } from './types';
import { ExcludeFalsy } from '@ardoq/common-helpers';

export const ConcatDetailsSection = ({
  value,
  onValueChange,
  reactiveFieldOptions,
}: EditorSectionProps<ConcatTransformation>) => {
  const selectedOptions = value.config.additionalFields
    .map(field =>
      reactiveFieldOptions?.find(option => option.value.name === field)
    )
    .filter(ExcludeFalsy);

  const handleSeparatorChange = (joinStr: string) =>
    onValueChange({ ...value, config: { ...value.config, joinStr } });

  const handleFieldsChange = (
    fields: SelectOption<AutocompleteFieldSuggestion>[] | null
  ) =>
    onValueChange({
      ...value,
      config: {
        ...value.config,
        additionalFields: fields?.map(field => field.value.name) || [],
      },
    });

  return (
    <>
      <Field label="Fields">
        <SortableMultiselect
          placeholder="Select fields to concatenate"
          options={reactiveFieldOptions || []}
          value={selectedOptions}
          onChange={handleFieldsChange}
        />
      </Field>
      <Field label="Separator">
        <TextInput
          value={value.config.joinStr}
          onValueChange={handleSeparatorChange}
        />
      </Field>
    </>
  );
};
