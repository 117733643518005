import { actionCreator } from '@ardoq/rxbeach';
import { Tag } from 'aqTypes';
import { ArdoqId } from '@ardoq/api-types';

interface DeleteTags {
  readonly cids: string[];
}
export const deleteTags = actionCreator<DeleteTags>('[TagActions] DELETE_TAGS');

interface UntagComponent {
  readonly tagCid: string;
  readonly componentCid: string;
}
export const untagComponent = actionCreator<UntagComponent>(
  '[TagActions] UNTAG_COMPONENT'
);

interface UntagReference {
  readonly tagCid: string;
  readonly referenceCid: string;
}
export const untagReference = actionCreator<UntagReference>(
  '[TagActions] UNTAG_REFERENCE'
);

interface TagComponents {
  readonly tagId: string | null;
  readonly componentIds: Set<string>;
}
export const tagComponents = actionCreator<TagComponents>(
  '[TagActions] TAG_COMPONENTS'
);

interface FilterTagPayload {
  id: ArdoqId;
  exclude: boolean;
}
export const editTag = actionCreator<ArdoqId>('[TagActions] EDIT_TAG');

export const deleteTag = actionCreator<ArdoqId>('[TagActions] DELETE_TAG');

export const filterTag = actionCreator<FilterTagPayload>(
  '[TagActions] FILTER_TAG'
);

interface TagPayload {
  readonly tag: Tag;
}

export interface EditTagPayload {
  _id: ArdoqId;
  name: string;
  description: string | null;
}
export interface AddTagPayload {
  name: string;
  description: string | null;
}

export const updateTag = actionCreator<EditTagPayload>(
  '[TagActions] UPDATE_TAG'
);

export const createTag = actionCreator<AddTagPayload>(
  '[TagActions] CREATE_TAG'
);

export const notifyTagAdded = actionCreator<TagPayload>(
  '[TagActions] NOTIFY_TAG_ADDED'
);

export const notifyTagRemoved = actionCreator<TagPayload>(
  '[TagActions] NOTIFY_TAG_REMOVED'
);

export const notifyTagUpdated = actionCreator<TagPayload>(
  '[TagActions] NOTIFY_TAG_UPDATED'
);

export const showAddTagDialog = actionCreator(
  '[TagActions] SHOW_ADD_TAG_DIALOG'
);
