import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { subdivisionCreationContextOperations } from './subdivisionCreationContextOperations';
import {
  resetToInitialState,
  setCurrentCreationContext,
  setDifferentSubdivisionCreationContext,
  setCreationContextSelection,
  syncGridEditorCreationContext,
} from './actions';

const reducers = [
  reducer(
    resetToInitialState,
    subdivisionCreationContextOperations.resetToInitialStateReducer
  ),
  reducer(
    setCurrentCreationContext,
    subdivisionCreationContextOperations.setCurrentCreationContextReducer
  ),
  reducer(
    setDifferentSubdivisionCreationContext,
    subdivisionCreationContextOperations.setDifferentSubdivisionCreationContext
  ),
  reducer(
    setCreationContextSelection,
    subdivisionCreationContextOperations.setCreationContextSelectionReducer
  ),
  reducer(
    syncGridEditorCreationContext,
    subdivisionCreationContextOperations.replaceState
  ),
];

const subdivisionCreationContext$ = persistentReducedStream(
  'subdivisionCreationContext$',
  subdivisionCreationContextOperations.getEmpty(),
  reducers
);

export default subdivisionCreationContext$;
