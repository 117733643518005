import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { SecondaryButton, GhostButton } from '@ardoq/button';
import type { Column } from '@ardoq/table';
import { ErrorNotification } from '@ardoq/status-ui';
import { s16, s8 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import { Connection } from '@ardoq/api-types/integrations';
import { ConnectionsAsyncOperationsState } from 'integrations/common/streams/connections/types';
import { ConnectionsTable } from 'integrations/common/components/connectionsTable/ConnectionsTable';
import { viewModel$ } from './viewModel$';
import { Features, hasFeature } from '@ardoq/features';

type ConnectionsParams = {
  statuses: ConnectionsAsyncOperationsState;
  connections: Connection[];
  isAdmin: boolean;
  onDelete: (connection: Connection) => void;
  onUpsertClick: (connection?: Connection) => void;
  onRefresh: () => void;
  onConnectionClick: (connection: Connection) => void;
  extraColumns?: Column<Connection>[];
  connectYourAccountTitle: string;
  connectYourAccountBtn: string;
};

const ButtonContainer = styled(Space).attrs({ $justify: 'flex-end' })`
  margin-bottom: ${s16};
  width: 100%;
`;

const ConnectionsComponent = ({
  connections,
  statuses,
  isAdmin,
  onDelete,
  onUpsertClick,
  onRefresh,
  onConnectionClick,
  extraColumns = [],
  connectYourAccountTitle,
  connectYourAccountBtn,
}: ConnectionsParams) => {
  const { status: listStatus, message: listMessage } = statuses.list;
  const isListFailure = listStatus === 'FAILURE';

  const hasNewJourneyFeature = hasFeature(Features.NEW_CORE_JOURNEY);

  return (
    <>
      {isListFailure && (
        <ErrorNotification style={{ alignItems: 'center', marginBottom: s8 }}>
          <Space $justify="space-between" $align="center">
            {listMessage}
            {isListFailure && (
              <GhostButton onClick={onRefresh}>Refresh app</GhostButton>
            )}
          </Space>
        </ErrorNotification>
      )}
      {isAdmin && connections.length > 0 && (
        // Temporary for old design compatibility. We should remove this when we remove old design
        <ButtonContainer $paddingRight={hasNewJourneyFeature ? 's16' : 'none'}>
          <SecondaryButton onClick={() => onUpsertClick()}>
            Create new connection
          </SecondaryButton>
        </ButtonContainer>
      )}
      <ConnectionsTable
        connections={connections}
        listStatus={listStatus}
        isAdmin={isAdmin}
        onDelete={onDelete}
        onUpsertClick={onUpsertClick}
        onConnectionClick={onConnectionClick}
        extraColumns={extraColumns}
        connectYourAccountTitle={connectYourAccountTitle}
        connectYourAccountBtn={connectYourAccountBtn}
      />
    </>
  );
};

export const Connections = connect(ConnectionsComponent, viewModel$);
