/**
 *
 * DO NOT CHANGE THE VALUES OR IT WILL MESS-UP WITH THE TRACKING REPORTS
 * ref: apps/ardoq-front/docs/architecture/tracking-events.md
 *
 */

/**
 *
 * `LegacyEvents` and `LegacyClickEvents` existed before https://ardoqcom.atlassian.net/browse/APPSEC-1651
 * therefore their values have been kept as they were.
 *
 */
enum LegacyEvents {
  INVITED_USER_TO_ORG = 'Invited user to organization',
}

enum LegacyClickEvents {
  CLICKED_PREFERENCES = 'preferences',
  CLICKED_USAGE_METRICS_DASHBOARD = 'usage-metrics-dashboard',
  CLICKED_YOUR_ACCOUNT_SETTINGS = 'your-account-settings',
  CLICKED_EMAIL_PREFERENCES = 'email-preferences',
  CLICKED_ORGANIZATION_SETTINGS = 'organization-settings',
  CLICKED_MANAGE_BUNDLES = 'manage-bundles',
  CLICKED_MANAGE_FIELDS = 'manage-fields',
  CLICKED_MANAGE_ORGANIZATION = 'manage-organization',
  CLICKED_MANAGE_PERMISSION_GROUPS = 'manage-permission-groups',
  CLICKED_MANAGE_FEATURE_SETTINGS = 'manage-feature-settings',
  CLICKED_SSO_ATTRIBUTE_MAPPING = 'sso-attribute-mapping',
  CLICKED_MANAGE_USERS = 'manage-users',
  CLICKED_MANAGE_USER_ROLES = 'manage-user-roles',
  CLICKED_MIGRATION_CHECKLIST = 'migration-checklist',
  CLICKED_SHOW_ADMINS = 'show-admins',
  CLICKED_GRAPH_FILTERS = 'graph-filters',
  CLICKED_MANAGE_IP_ALLOWLIST = 'manage-ip-allowlist',
  CLICKED_LOG_OUT = 'log-out',
}

enum AppsecClickEvents {
  CLICKED_ASSET_PERMISSIONS_MENU_ITEM = 'clicked asset permissions menu item',
  CLICKED_EXPORT_PERMISSIONS_TO_EXCEL = 'clicked export permissions to excel',
  CLICKED_MANAGE_ORGANIZATION_TAB = 'clicked manage organization tab',
  CLICKED_TOGGLE_MANAGE_ORGANIZATION_TAB_GROUP = 'clicked toggle manage organization tab group',
  CLICKED_USER_ACCOUNT_SETTINGS_TAB = 'clicked user account settings tab',
  CLICKED_MANAGE_SERVICE_ACCOUNTS = 'clicked manage service accounts',
  CLICKED_MANAGE_PERMISSIONS = 'clicked manage permissions',
  CLICKED_ACCESS_CONTROL = 'clicked access control',
  CLICKED_USER_ROW = 'clicked user row',
}

enum AppsecEvents {
  ADDED_USERS_TO_GROUP = 'added users to group',
  REMOVED_USERS_FROM_GROUP = 'removed users from group',
  CREATED_NEW_GROUP = 'created new group',
  DELETED_GROUP = 'deleted group',
  UPDATED_GROUP_NAME = 'updated group name',
  UPDATED_USER_ASSET_PERMISSIONS = 'updated user asset permissions',
  UPDATED_GROUP_ASSET_PERMISSIONS = 'updated group asset permissions',
  ASSIGNED_PRIVILEGE_TO_USER = 'assigned privilege to user',
  REVOKED_PRIVILEGE_FROM_USER = 'revoked privilege from user',
}

export const APPSEC_EVENTS = {
  ...LegacyEvents,
  ...LegacyClickEvents,
  ...AppsecClickEvents,
  ...AppsecEvents,
} as const;
