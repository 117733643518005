import {
  APIEntityType,
  APIFieldAttributes,
  APIFieldType,
  ArdoqId,
} from '@ardoq/api-types';
import { toModelTypeDictionary, toModelTypes } from '@ardoq/renderers';
import { AddWorkspaceFieldPayload } from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/actions';
import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash';
import { AddFieldNewToWorkspacePayload } from 'appModelStateEdit/propertiesEditor/addField/types';
import { OLD_DEFAULT_FIELD_ORDER } from 'models/consts';
import type { EnhancedScopeData } from '@ardoq/data-model';

const getEmptyAPIFieldAttributes = (): APIFieldAttributes => {
  return {
    _id: '',
    createdBy: '',
    lastModifiedBy: '',
    lastUpdated: '',
    lastModifiedByEmail: '',
    lastModifiedByName: '',
    created: '',
    ardoq: { entityType: APIEntityType.FIELD },
    name: '',
    description: '',
    _order: OLD_DEFAULT_FIELD_ORDER,
    componentType: [],
    referenceType: [],
    createdByName: '',
    createdByEmail: '',
    defaultValue: null,
    label: '',
    model: '',
    type: APIFieldType.TEXT,
    _version: -1,
  };
};

export const applyWorkspaceFieldToType = (
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  fieldData: AddWorkspaceFieldPayload
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      const field = enhancedScopeData.fields.find(
        f => f.name === fieldData.name
      );
      if (!field) {
        return enhancedScopeData;
      }
      const updatedField = { ...field, ...fieldData };
      const fieldsById = {
        ...enhancedScopeData.fieldsById,
        [field._id]: updatedField,
      };
      const fields = Object.values(fieldsById);
      const fieldsByModelIdAndTypeId = toModelTypeDictionary(
        fields,
        toModelTypes(enhancedScopeData.models)
      );
      return {
        ...enhancedScopeData,
        fieldsById,
        fields,
        fieldsByModelIdAndTypeId,
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const applyFieldNewToWorkspaceToType = (
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  fieldData: AddFieldNewToWorkspacePayload
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      const newField = {
        ...getEmptyAPIFieldAttributes(),
        ...fieldData,
        _id: uuidv4(),
      };
      const fieldsById = {
        ...enhancedScopeData.fieldsById,
        [newField._id]: newField,
      };
      const fields = Object.values(fieldsById);
      const fieldsByModelIdAndTypeId = toModelTypeDictionary(
        fields,
        toModelTypes(enhancedScopeData.models)
      );
      return {
        ...enhancedScopeData,
        fieldsById,
        fields,
        fieldsByModelIdAndTypeId,
        fieldLabelByFieldName: {
          ...enhancedScopeData.fieldLabelByFieldName,
          [newField.name]: newField.label,
        },
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const unapplyWorkspaceFieldToType = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  fieldName: string
): EnhancedScopeData => {
  const field = enhancedScopeData.fields.find(f => f.name === fieldName);
  if (!field) {
    return enhancedScopeData;
  }
  switch (entityType) {
    case APIEntityType.COMPONENT: {
      const typeIds = entityIDs.map(componentId => {
        const attributes = enhancedScopeData.componentsById[componentId];
        const { typeId } = attributes;
        return typeId;
      });
      const componentType = new Set(field.componentType);
      typeIds.forEach(typeId => componentType.delete(typeId));
      const updatedField = {
        ...field,
        componentType: Array.from(componentType),
      };
      const fieldsById = {
        ...enhancedScopeData.fieldsById,
        [field._id]: updatedField,
      };
      const fields = Object.values(fieldsById);
      const fieldsByModelIdAndTypeId = toModelTypeDictionary(
        fields,
        toModelTypes(enhancedScopeData.models)
      );
      return {
        ...enhancedScopeData,
        fieldsById,
        fields,
        fieldsByModelIdAndTypeId,
      };
    }
    case APIEntityType.REFERENCE: {
      const typeIds = entityIDs.map(referenceId => {
        const attributes = enhancedScopeData.referencesById[referenceId];
        const { type } = attributes;
        return String(type);
      });
      const referenceType = new Set(field.referenceType);
      typeIds.forEach(typeId => referenceType.delete(typeId));
      const updatedField = {
        ...field,
        referenceType: Array.from(referenceType),
      };
      const fieldsById = {
        ...enhancedScopeData.fieldsById,
        [field._id]: updatedField,
      };
      const fields = Object.values(fieldsById);
      const fieldsByModelIdAndTypeId = toModelTypeDictionary(
        fields,
        toModelTypes(enhancedScopeData.models)
      );
      return {
        ...enhancedScopeData,
        fieldsById,
        fields,
        fieldsByModelIdAndTypeId,
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const unapplyFieldNewToWorkspaceToType = (
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  fieldName: string
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      const field = enhancedScopeData.fields.find(
        currentField => currentField.name === fieldName
      );
      if (!field) {
        return enhancedScopeData;
      }
      const fieldsById: Record<string, APIFieldAttributes> = omit(
        enhancedScopeData.fieldsById,
        field._id
      );
      const fields = Object.values(fieldsById);
      const fieldsByModelIdAndTypeId = toModelTypeDictionary(
        fields,
        toModelTypes(enhancedScopeData.models)
      );
      return {
        ...enhancedScopeData,
        fieldsById,
        fields,
        fieldsByModelIdAndTypeId,
        fieldLabelByFieldName: omit(
          enhancedScopeData.fieldLabelByFieldName,
          fieldName
        ),
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};
