import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultReportRulesState } from './utils';
import { ReportRulesSection$State } from './types';

const reportRulesSection$ = persistentReducedStream<ReportRulesSection$State>(
  'reportRulesSection$',
  getDefaultReportRulesState(),
  reducers
);

export default reportRulesSection$;
