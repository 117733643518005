import { azureDictionary } from 'integrations/common/dictionary';
import { IntegrationInitialState } from 'integrations/common/initialState';
import { buildInitialTermsState } from 'integrations/common/streams/integrationTermsDictionary/initialState';

export const initialState: IntegrationInitialState = {
  activeIntegration: {
    integrationId: 'azure-v3',
    integrationMappingParams: {
      mapColumnsBy: 'sourceFieldName',
      mapTablesBy: 'id',
    },
  },
  termsDictionary: buildInitialTermsState(azureDictionary),
};
