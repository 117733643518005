import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Maybe } from '@ardoq/common-helpers';
import { IntegrationTermsDictionary } from './types';
import { buildInitialStreamState } from '../utils';
import { integrationInitialStates } from 'integrations/common/initialState';
import * as fp from 'lodash/fp';
import {
  unsetIntegrationTermsPayload,
  resetIntegrationTermsDictionary,
  resetIntegrationTermsDictionaryPayload,
  setIntegrationTerms,
  setIntegrationTermsPayload,
  unsetIntegrationTerms,
} from './actions';

type IntegrationTermsDictionaryState = Record<
  IntegrationId,
  IntegrationTermsDictionary
>;

const defaultState: IntegrationTermsDictionaryState =
  buildInitialStreamState<IntegrationTermsDictionary>(
    integrationId => integrationInitialStates[integrationId].termsDictionary
  );

const unsetIntegrationTermsReducer = (
  state: IntegrationTermsDictionaryState,
  { integrationId, keys }: unsetIntegrationTermsPayload
): IntegrationTermsDictionaryState =>
  fp.set(integrationId, fp.omit(keys, state[integrationId]), state);

const handleUnsetIntegrationTerms = reducer<
  IntegrationTermsDictionaryState,
  unsetIntegrationTermsPayload
>(unsetIntegrationTerms, unsetIntegrationTermsReducer);

const setIntegrationTermsReducer = (
  state: IntegrationTermsDictionaryState,
  { integrationId, terms }: setIntegrationTermsPayload
): IntegrationTermsDictionaryState =>
  fp.set(integrationId, fp.merge(state[integrationId], terms), state);

const handleSetIntegrationTerms = reducer<
  IntegrationTermsDictionaryState,
  setIntegrationTermsPayload
>(setIntegrationTerms, setIntegrationTermsReducer);

const resetIntegrationTermsDictionaryReducer = (
  state: IntegrationTermsDictionaryState,
  { integrationId }: resetIntegrationTermsDictionaryPayload
): IntegrationTermsDictionaryState =>
  fp.set(
    [integrationId],
    integrationInitialStates[integrationId].termsDictionary,
    state
  );

const handleResetIntegrationTermsDictionary = reducer<
  IntegrationTermsDictionaryState,
  resetIntegrationTermsDictionaryPayload
>(resetIntegrationTermsDictionary, resetIntegrationTermsDictionaryReducer);

const integrationTermsDictionary$ = persistentReducedStream(
  'integrationTermsDictionary$',
  defaultState,
  [
    handleSetIntegrationTerms,
    handleUnsetIntegrationTerms,
    handleResetIntegrationTermsDictionary,
  ]
);

const integrationToDictionaryStream = new Map<
  IntegrationId,
  Observable<IntegrationTermsDictionary>
>();

export const getIntegrationTermsDictionaryStream = (
  integrationId: Maybe<IntegrationId>
) => {
  if (!integrationId) {
    return EMPTY;
  }

  if (!Object.keys(defaultState).includes(integrationId)) {
    throw new Error(
      `Your integration ${integrationId} is missing in the dictionary.`
    );
  }

  const integrationTermsDictionary =
    integrationToDictionaryStream.get(integrationId);

  if (integrationTermsDictionary) {
    return integrationTermsDictionary;
  }

  const stream$ = integrationTermsDictionary$.pipe(
    map(activeIntegrations => activeIntegrations[integrationId])
  );

  integrationToDictionaryStream.set(integrationId, stream$);

  return stream$;
};
