import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { addWorkspaceFieldReducer } from 'appModelStateEdit/propertiesEditor/addField/gridEditor/actions';
import { reducer } from '@ardoq/rxbeach';
import { APIFieldAttributes } from '@ardoq/api-types';

// this is a reducer for the action which is called addWorkspaceFieldReducer. hence the name-- add workspace field reducer reducer.
const addWorkspaceFieldReducerReducer = (
  state: AppModelStateEditStreamShape,
  updatedField: APIFieldAttributes
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return state;
  }
  const newScopeData = {
    ...state.enhancedScopeData,
    fieldsById: {
      ...state.enhancedScopeData.fieldsById,
      [updatedField._id]: updatedField,
    },
    fields: state.enhancedScopeData.fields.map(field =>
      field._id === updatedField._id ? updatedField : field
    ),
  };
  return {
    ...state,
    enhancedScopeData: newScopeData,
  };
};
const handleAddWorkspaceField = reducer<
  AppModelStateEditStreamShape,
  APIFieldAttributes
>(addWorkspaceFieldReducer, addWorkspaceFieldReducerReducer);

const reducers = [handleAddWorkspaceField];
export default reducers;
