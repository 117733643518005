import { useState } from 'react';
import { modal, ModalLayout, ModalTemplate } from '@ardoq/modal';
import { TextInput } from '@ardoq/forms';
import {
  cloneConfiguration,
  saveConfigurationsFailure,
  saveConfigurationsSuccess,
} from 'integrations/common/streams/transferConfigs/actions';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

type CopyTransferConfigModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  config: SavedTransferConfig;
  integrationId: IntegrationId;
};

const CopyTransferConfigModal = ({
  onSubmit,
  onCancel,
  config,
  integrationId,
}: CopyTransferConfigModalProps) => {
  const [configName, setConfigName] = useState(`${config.name} (copy)`);
  const [isCopying, setIsCopying] = useState(false);

  const handleCopyConfig = async () => {
    setIsCopying(true);
    const action = await dispatchActionAndWaitForResponse(
      cloneConfiguration({
        integrationId,
        name: configName.trim(),
        config,
      }),
      saveConfigurationsSuccess,
      saveConfigurationsFailure
    );
    setIsCopying(false);

    if (action.type === saveConfigurationsSuccess.type) {
      onSubmit();
    }
  };

  return (
    <ModalTemplate
      headerText="Copy configuration?"
      primaryButtonText="Copy configuration"
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={!configName.trim()}
      onPrimaryButtonClick={handleCopyConfig}
      onSecondaryButtonClick={onCancel}
      isInProgress={isCopying}
    >
      <ModalLayout>
        <TextInput
          label="Name of copy"
          onValueChange={setConfigName}
          value={configName}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              handleCopyConfig();
            }
          }}
        ></TextInput>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startCopyTransferConfigModal = (
  integrationId: IntegrationId,
  config: SavedTransferConfig
) =>
  modal<boolean>(
    resolve => (
      <CopyTransferConfigModal
        onSubmit={() => resolve(true)}
        onCancel={() => resolve(false)}
        config={config}
        integrationId={integrationId}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
