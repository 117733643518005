import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { setConsentState } from './actions';
import { ConsentState } from './types';

const defaultState: ConsentState = {
  status: 'INIT',
  adminConsentUrl: '',
  tenantId: null,
};

function updateConsentReducer(
  state: ConsentState,
  payload: Partial<ConsentState>
) {
  return { ...state, ...payload };
}

export const consent$ = persistentReducedStream(
  'microsoftEntraIdConsent',
  defaultState,
  [reducer(setConsentState, updateConsentReducer)]
);
