import integrationViewState$ from 'integrations/integrationViewState$';
import {
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { IntegrationViewState } from 'integrations/types';
import {
  setCurrentTableId,
  setSourceId,
  setTransferConfigId,
  resetIntegration,
  SetConfigPayload,
  SetCurrentTableIdPayload,
  SetSourceIdPayload,
  SetIntegrationNamePayload,
  setIntegrationName,
  setMappingConfigId,
  setMapTablesBy,
  SetIntegrationPathPayload,
  setTrackingFunnelId,
  SetTrackingFunnelIdPayload,
} from 'integrations/common/streams/activeIntegrations/actions';
import {
  ActiveIntegrationState,
  ActiveIntegrations,
} from 'integrations/common/streams/activeIntegrations/types';
import { integrationIdToSourceTypes } from 'integrations/common/streams/activeIntegrations/sourceTypes';
import { Maybe } from '@ardoq/common-helpers';
import { v4 as uuidv4 } from 'uuid';
import { isIntegrationId } from './utils';
import { buildInitialStreamState } from '../utils';
import { integrationInitialStates } from 'integrations/common/initialState';

import fp from 'lodash/fp';

export const getInstanceInitialState = (
  integrationId: IntegrationId
): ActiveIntegrationState => ({
  ...integrationInitialStates[integrationId].activeIntegration,
  integrationPath: null,
  integrationName: '',
  selectedTransferConfigId: null,
  selectedMappingConfigId: null,
  currentTableId: null,
  sourceId: null,
  sourceTypes: [],
  trackingFunnelId: uuidv4(),
});

const defaultState: ActiveIntegrations = buildInitialStreamState(
  getInstanceInitialState
);

const integrationViewStateReducerFn = (
  state: ActiveIntegrations,
  { selectedIntegrationId, integrationPath }: IntegrationViewState
) => {
  if (isIntegrationId(selectedIntegrationId)) {
    const current =
      state?.[selectedIntegrationId] ||
      getInstanceInitialState(selectedIntegrationId);
    return {
      ...state,
      [selectedIntegrationId]: {
        ...current,
        integrationPath: integrationPath || current.integrationPath,
        sourceTypes: integrationIdToSourceTypes(selectedIntegrationId),
      },
    };
  }
  return state;
};
const integrationViewStateReducer = streamReducer<
  ActiveIntegrations,
  IntegrationViewState
>(integrationViewState$, integrationViewStateReducerFn);

const setIntegrationNameReducerFn = (
  state: ActiveIntegrations,
  { name, integrationId }: SetIntegrationNamePayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    integrationName: name,
  },
});

const handleSetIntegrationName = reducer<
  ActiveIntegrations,
  SetIntegrationNamePayload
>(setIntegrationName, setIntegrationNameReducerFn);

const setTransferConfigIdReducerFn = (
  state: ActiveIntegrations,
  { id, integrationId }: SetConfigPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    selectedTransferConfigId: id,
  },
});

const handleSetTransferConfigId = reducer<ActiveIntegrations, SetConfigPayload>(
  setTransferConfigId,
  setTransferConfigIdReducerFn
);

const setMappingConfigIdReducerFn = (
  state: ActiveIntegrations,
  { id, integrationId }: SetConfigPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    selectedMappingConfigId: id,
  },
});
const handleSetMappingConfigId = reducer<ActiveIntegrations, SetConfigPayload>(
  setMappingConfigId,
  setMappingConfigIdReducerFn
);

const setCurrentTableIdReducerFn = (
  state: ActiveIntegrations,
  { id, integrationId }: SetCurrentTableIdPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    currentTableId: id,
  },
});
const handleSetCurrentTable = reducer<
  ActiveIntegrations,
  SetCurrentTableIdPayload
>(setCurrentTableId, setCurrentTableIdReducerFn);

const setSourceIdReducerFn = (
  state: ActiveIntegrations,
  { id, integrationId }: SetSourceIdPayload
) => ({
  ...state,
  [integrationId]: {
    ...state[integrationId],
    sourceId: id,
  },
});
const handleSetSourceId = reducer<ActiveIntegrations, SetSourceIdPayload>(
  setSourceId,
  setSourceIdReducerFn
);

const resetIntegrationReducerFn = (
  state: ActiveIntegrations,
  integrationId: IntegrationId
) => ({
  ...state,
  [integrationId]: getInstanceInitialState(integrationId),
});
const handleResetIntegration = reducer<ActiveIntegrations, IntegrationId>(
  resetIntegration,
  resetIntegrationReducerFn
);

function setMapTablesByReducerFn(
  state: ActiveIntegrations,
  { mapTablesBy, integrationId }: SetIntegrationPathPayload
): ActiveIntegrations {
  return fp.set(
    [integrationId, 'integrationMappingParams', 'mapTablesBy'],
    mapTablesBy,
    state
  );
}

const handleSetMapTablesBy = reducer<
  ActiveIntegrations,
  SetIntegrationPathPayload
>(setMapTablesBy, setMapTablesByReducerFn);

function setTrackingFunnelIdReducer(
  state: ActiveIntegrations,
  { integrationId, trackingFunnelId }: SetTrackingFunnelIdPayload
): ActiveIntegrations {
  return {
    ...state,
    [integrationId]: {
      ...state[integrationId],
      trackingFunnelId,
    },
  };
}

export const activeIntegrations$ = persistentReducedStream(
  'activeIntegrations$',
  defaultState,
  [
    integrationViewStateReducer,
    handleSetIntegrationName,
    handleSetCurrentTable,
    handleSetTransferConfigId,
    handleSetMappingConfigId,
    handleSetSourceId,
    handleResetIntegration,
    handleSetMapTablesBy,
    reducer(setTrackingFunnelId, setTrackingFunnelIdReducer),
  ]
);

const integrationToActiveIntegrationStream = new Map<
  IntegrationId,
  Observable<ActiveIntegrationState>
>();

export const getActiveIntegrationStream = (
  integrationId: Maybe<IntegrationId>
) => {
  if (!integrationId) {
    return EMPTY;
  }

  const activeIntegration =
    integrationToActiveIntegrationStream.get(integrationId);

  if (activeIntegration) {
    return activeIntegration;
  }

  const stream$ = activeIntegrations$.pipe(
    map(activeIntegrations => activeIntegrations[integrationId])
  );

  integrationToActiveIntegrationStream.set(integrationId, stream$);

  return stream$;
};
