import { derivedStream, dispatchAction } from '@ardoq/rxbeach';
import { recalculateCalculatedField } from 'search/CalculatedSearch/actions';
import { map } from 'rxjs/operators';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { noop } from 'lodash';
import { formatDateTime } from '@ardoq/date-time';
import { PermissionContext } from '@ardoq/access-control';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import { getCurrentLocale } from '@ardoq/locale';
import { loadCalculatedFieldStoredQuery } from 'search/actions';
import { findExistingStoredQuery } from 'calculatedField/helpers';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';

const getDefaultState = () => {
  return {
    queryState: null,
    syncStatus: null,
    lastUpdated: '',
    shouldShowEditButton: false,
    onEditButtonClick: () => noop(),
    onRefreshButtonClick: () => noop(),
  };
};

const toCalculatedFieldsState = ([
  permissionContext,
  { entityIDs, enhancedScopeData },
]: [PermissionContext, AppModelStateEditStreamShape]) => {
  if (!enhancedScopeData) {
    return getDefaultState();
  }
  const locale = getCurrentLocale();
  const fieldId = entityIDs[0];
  const field = enhancedScopeData.fieldsById[fieldId];
  if (!field) {
    return getDefaultState();
  }
  const storedQueryId = field.calculatedFieldSettings?.storedQueryId;
  if (!storedQueryId) {
    getDefaultState();
  }

  return {
    lastUpdated: formatDateTime(field.lastUpdated ?? null, locale),
    shouldShowEditButton:
      graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext),
    onEditButtonClick: async () => {
      const storedQuery = await findExistingStoredQuery(storedQueryId);
      if (storedQuery) {
        dispatchAction(
          loadCalculatedFieldStoredQuery({ storedQuery, fieldId }),
          QueryEditorNamespace.CALCULATED_FIELD_QUERY
        );
      }
    },
    onRefreshButtonClick: () =>
      dispatchAction(
        recalculateCalculatedField({
          fieldId,
        })
      ),
  };
};

const calculatedFields$ = derivedStream(
  'addFieldOptions$',
  currentUserPermissionContext$,
  appModelStateEdit$
).pipe(map(toCalculatedFieldsState));

export default calculatedFields$;
