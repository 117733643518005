import { createElement } from 'react';
import DocumentBrowserStackPage from 'components/DocumentBrowser/DocumentBrowserStackPage';
import {
  AttachmentWithContext,
  DocumentArchiveMode,
  DocumentBrowserUIState,
} from 'components/DocumentBrowser/types';
import { RowType } from '@ardoq/table';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { pushStackPage } from '@ardoq/stack-page-manager';
import {
  Editor,
  colorSyntaxPlugin,
  customButtonsPlugin,
} from '@ardoq/rich-text-editor';
import { RichTextEditorExtension } from '@ardoq/rich-text-editor-2024';

type DocumentArchivePickerOptions = {
  addSelectedFilesButtonLabel: string;
  defaultUIState: Partial<DocumentBrowserUIState>;
};

const openDocumentArchivePickerAsync = ({
  defaultUIState,
  addSelectedFilesButtonLabel,
}: DocumentArchivePickerOptions) =>
  new Promise<false | string[]>(resolve =>
    pushStackPage(stackPageProps => {
      const resolveWithArgs = (selectedIds?: string[]) => {
        resolve(selectedIds || false);
        stackPageProps.closeStackPage();
      };
      return createElement(DocumentBrowserStackPage, {
        ...stackPageProps,
        addSelectedFilesButtonLabel,
        closeStackPage: () => resolveWithArgs(),
        onAddSelectedFilesClick: selectedIds => resolveWithArgs(selectedIds),
        defaultUIState,
      });
    })
  );

const openDocumentArchiveImagePickerAsync = () =>
  openDocumentArchivePickerAsync({
    addSelectedFilesButtonLabel: 'Add selected images',
    defaultUIState: {
      mode: DocumentArchiveMode.FILE_PICKER,
      filterByType: {
        [RowType.IMAGE]: true,
        [RowType.DOCUMENT]: false,
        [RowType.UNKOWN_FILE]: false,
      },
    },
  });

const openDocumentArchiveDocumentPickerAsync = () =>
  openDocumentArchivePickerAsync({
    addSelectedFilesButtonLabel: 'Add selected documents',
    defaultUIState: {
      mode: DocumentArchiveMode.FILE_PICKER,
    },
  });

const findDocumentArchiveAttachmentsByIds = (ids: string[]) =>
  documentArchiveInterface
    .getDocumentArchiveAttachments()
    .filter(({ _id }) => ids.includes(_id));

const selectImage = async (): Promise<AttachmentWithContext[]> => {
  const selectedIds = await openDocumentArchiveImagePickerAsync();
  if (!selectedIds || !selectedIds.length) return [];
  return findDocumentArchiveAttachmentsByIds(selectedIds);
};

const handleImageInserting = async (
  insertHtmlString: (input: string) => void
) => {
  const selectedAttachments = await selectImage();
  selectedAttachments.forEach(({ uri }) => {
    insertHtmlString(`<img src="${encodeURI(uri)}" /> `);
  });
};

const selectLink = async (): Promise<AttachmentWithContext[]> => {
  const selectedIds = await openDocumentArchiveDocumentPickerAsync();
  if (!selectedIds || !selectedIds.length) return [];
  return findDocumentArchiveAttachmentsByIds(selectedIds);
};

const handleLinkInserting = async (
  insertHtmlString: (input: string) => void,
  editor: Editor
) => {
  const selectedText = editor.getSelectedText();
  const selectedAttachments = await selectLink();
  selectedAttachments.forEach(({ uri, filename }) => {
    insertHtmlString(
      `<a href="${encodeURI(uri)}" >${selectedText || filename}</a> `
    );
  });
};

export const richTextEditorConfig2024: RichTextEditorExtension[] = [
  'Heading',
  'Bold',
  'Italic',
  'Strike',
  'Blockquote',
  'BulletList',
  'OrderedList',
  'Table',
  'Link',
  'Code',
  'CodeBlock',
  {
    name: 'ImagePicker',
    imagePicker: selectImage,
  },
  {
    name: 'InternalLink',
    linkPicker: async () => {
      const links = await selectLink();
      return links.map(({ uri, filename }) => ({
        url: uri,
        label: filename,
      }));
    },
  },
];

export const richTextEditorConfig = {
  toolbarItems: [
    'heading',
    'bold',
    'italic',
    'strike',
    'divider',
    'quote',
    'divider',
    'ul',
    'ol',
    'divider',
    'table',
    'link',
    'divider',
    'code',
    'codeblock',
    'divider',
  ],
  plugins: [
    colorSyntaxPlugin,
    [
      customButtonsPlugin,
      [
        {
          tooltip: 'Link document from Document Archive',
          className: 'tui-document-archive',
          onClick: handleLinkInserting,
        },
        {
          tooltip: 'Attach image from Document Archive',
          className: 'tui-image-custom-icon',
          onClick: handleImageInserting,
        },
      ],
    ],
  ],
};
