import {
  addNewTags,
  modifyProperty,
  modifySimpleProperty,
  modifyTags,
  removeNewTags,
  setAttributeDirty,
} from 'appModelStateEdit/propertiesEditor/actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { FieldType } from '@ardoq/renderers';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import addFieldToEntityRoutines from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/routines';
import {
  modifyComponentType,
  modifyParent,
  assignSubdivisionMembershipFromParent,
} from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/actions';
import componentPropertiesEditorRoutines from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/routines';
import referencePropertiesEditorRoutines from 'appModelStateEdit/propertiesEditor/referencePropertiesEditor/routines';
import {
  APIFieldAttributes,
  ArdoqId,
  CalculatedFieldSettings,
} from '@ardoq/api-types';
import { Features, hasFeature } from '@ardoq/features';
import { isDateRangeFieldType } from '@ardoq/date-range';
import { formatDateISO } from '@ardoq/date-time';
import { isEqual } from 'lodash';
import { FieldPopulateMethod } from 'aqTypes';
import { SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID } from 'appModelStateEdit/consts';
import { subdivisionCreationContextEditorCommands } from 'subdivisions/subdivisionCreationContext/commands';

const handleModifyPropertyAction = routine(
  ofType(modifyProperty),
  extractPayload(),
  tap(({ propertyType, propertyName, newValue, originalProperties }) => {
    dispatchAction(setAttributeDirty(propertyName));
    switch (propertyType) {
      case FieldType.PARENT: {
        dispatchAction(modifyParent(newValue as string | null));
        if (hasFeature(Features.PERMISSION_ZONES)) {
          dispatchAction(
            assignSubdivisionMembershipFromParent(newValue as ArdoqId | null)
          );
        }
        break;
      }
      case FieldType.TYPE_ID_COMPONENT: {
        dispatchAction(modifyComponentType(newValue as string));
        break;
      }
      case FieldType.FIELD_TYPE: {
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue,
          })
        );
        dispatchAction(
          modifySimpleProperty({
            propertyName: 'calculatedFieldSettings',
            newValue: null,
          })
        );

        if (isDateRangeFieldType(newValue)) {
          dispatchAction(
            modifySimpleProperty({
              propertyName: 'defaultValue',
              newValue: { start: null, end: null },
            })
          );
        } else {
          dispatchAction(
            modifySimpleProperty({
              propertyName: 'defaultValue',
              newValue: null,
            })
          );
        }
        break;
      }
      case FieldType.DEFAULT_VALUE_LIST:
      case FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST: {
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue: Array.isArray(newValue) ? newValue.join(',') : newValue,
          })
        );
        break;
      }
      case FieldType.DEFAULT_VALUE_DATE_ONLY: {
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue:
              newValue instanceof Date ? formatDateISO(newValue) : newValue,
          })
        );
        break;
      }
      case FieldType.DEFAULT_VALUE_DATE_TIME: {
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue:
              newValue instanceof Date ? newValue.toISOString() : newValue,
          })
        );
        break;
      }
      case FieldType.TRANSFORM_NUMBER:
      case FieldType.TRANSFORM_TEXT:
      case FieldType.TRANSFORM_LIST:
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue: {
              locallyDerived: newValue,
            },
          })
        );
        break;
      case FieldType.POPULATE_METHOD: {
        const originalFieldProperties =
          originalProperties as APIFieldAttributes;
        const originalCalculatedFieldSettings =
          originalFieldProperties.calculatedFieldSettings;

        let newCalculatedFieldSettings: CalculatedFieldSettings | null;

        if (newValue === FieldPopulateMethod.CALCULATED) {
          newCalculatedFieldSettings = {
            storedQueryId:
              // if the field was a saved calculated field, keep the saved query id when method is changed back
              originalCalculatedFieldSettings?.storedQueryId ??
              SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID,
          };
          dispatchAction(
            modifySimpleProperty({
              propertyName: 'calculatedFieldSettings',
              newValue: newCalculatedFieldSettings,
            })
          );
        } else if (newValue === FieldPopulateMethod.LOCALLY_DERIVED) {
          newCalculatedFieldSettings = {
            locallyDerived:
              // if the field was a saved derived field, keep the stored locallyDerived when method is changed back
              originalCalculatedFieldSettings?.locallyDerived ?? [],
          };
          dispatchAction(
            modifySimpleProperty({
              propertyName: 'calculatedFieldSettings',
              newValue: newCalculatedFieldSettings,
            })
          );
        } else {
          newCalculatedFieldSettings = null;
          dispatchAction(
            modifySimpleProperty({
              propertyName: 'calculatedFieldSettings',
              newValue: null,
            })
          );
        }

        const isFieldPopulateMethodUnchanged = isEqual(
          originalCalculatedFieldSettings,
          newCalculatedFieldSettings
        );

        dispatchAction(
          modifySimpleProperty({
            propertyName: 'defaultValue',
            newValue: isFieldPopulateMethodUnchanged
              ? originalFieldProperties.defaultValue
              : null,
          })
        );

        break;
      }
      case FieldType.SUBDIVISION_CREATION_CONTEXT:
        subdivisionCreationContextEditorCommands.setCreationContextSelection(
          newValue
        );
        break;
      default:
        dispatchAction(
          modifySimpleProperty({
            propertyName,
            newValue:
              (typeof newValue === 'number' && isNaN(newValue)) ||
              newValue === ''
                ? null
                : newValue,
          })
        );
    }
  })
);

const handleModifyTagsAction = routine(
  ofType(modifyTags),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(([currentTags, { enhancedScopeData, addedTags }]) => {
    if (!enhancedScopeData) {
      return;
    }
    const newTagsToAdd = currentTags.filter(
      tagName => !enhancedScopeData.tags.find(tag => tag.name === tagName)
    );
    if (newTagsToAdd.length) {
      dispatchAction(addNewTags(newTagsToAdd));
    }
    const newTagsToRemove = Array.from(addedTags).filter(
      tagName => !currentTags.includes(tagName)
    );
    if (newTagsToRemove.length) {
      dispatchAction(removeNewTags(newTagsToRemove));
    }
  })
);

const routines = [
  handleModifyPropertyAction,
  handleModifyTagsAction,
  ...addFieldToEntityRoutines,
  ...componentPropertiesEditorRoutines,
  ...referencePropertiesEditorRoutines,
];
export default routines;
