import { ArdoqId } from '@ardoq/api-types';

export enum ViewpointsPane {
  OVERVIEW = 'viewpoints-overview',
  FORM = 'viewpoints-form',
  DEFAULTS = 'viewpoints-defaults',
}

export type ViewpointsRoute = {
  viewpointsPane: ViewpointsPane;
  viewpointId: ArdoqId | null;
};
