import { ReactElement } from 'react';
import { ListEditorPropsWithOptionsGroups } from 'scopeData/editors/types';
import { Select } from '@ardoq/select';

const getLabel = (label: string | ReactElement) => {
  if (typeof label === 'string') {
    return label.toLowerCase();
  }
  if (
    typeof label === 'object' &&
    'props' in label &&
    'children' in label.props &&
    typeof label.props.children[0] === 'string'
  ) {
    // apps/ardoq-front/src/js/ardoq/views/selectViews/selectViews.tsx::getViewOptionLabel
    return label.props.children[0].toLowerCase();
  }
  return String(label);
};

const List = ({
  label,
  value,
  description,
  dataClickId,
  options,
  onValueChange,
  isDisabled,
  disabledMessage,
  isClearable,
  popoverHelpContent,
  onCloseFieldClick,
  closeFieldIconTooltip,
}: ListEditorPropsWithOptionsGroups) => {
  return (
    <Select
      value={value ?? null}
      label={label}
      options={options}
      onValueChange={onValueChange}
      isDisabled={isDisabled}
      helperText={isDisabled ? disabledMessage : description}
      isClearable={isClearable}
      clickId={dataClickId}
      popoverHelpContent={popoverHelpContent}
      filterOption={({ value, label }, query) => {
        const lowercaseQuery = query?.toLowerCase() ?? '';
        // other than a string, value could be null/number/boolean
        const lowercaseValue =
          value !== null ? String(value).toLowerCase() : '';
        const lowercaseLabel = getLabel(label);
        return (
          lowercaseLabel.includes(lowercaseQuery) ||
          lowercaseValue.includes(lowercaseQuery)
        );
      }}
      onCloseFieldClick={onCloseFieldClick}
      closeFieldIconTooltip={closeFieldIconTooltip}
    />
  );
};

export default List;
