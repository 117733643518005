import { DataSource, DiffTableRowType, MergeStep } from './types';
import { APIEntityType } from '@ardoq/api-types';
import { Branch } from './Branch';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';
import { readRawValue } from '@ardoq/renderers';
import { getNewDataSource, updateParent } from './diffMergeTableHelpers';

// Fields have a global (see `globalFieldProperties`) and a workspace specific
// perspective. In the global perspective a set of fields with the same name are
// considered one field. In a workspace specific perspective each field of such
// a set is different.
// For field updates we have to ensure that all global properties of a set of
// fields with the same name have the same value. If not, the backend would throw
// an error because it would not be clear what the global value should be.
// `syncGlobalFieldProperties` takes care of this issue.
export const syncGlobalFieldProperties = (
  enhancedDiffContextData: EnhancedDiffScopeData | null,
  dataSource: DataSource | null,
  entryIndex: number,
  mergeStep: MergeStep
): DataSource | null => {
  const entry = dataSource?.[entryIndex];

  if (
    !(
      enhancedDiffContextData &&
      dataSource &&
      mergeStep === MergeStep.UPDATE_FIELDS &&
      (entry?.rowType === DiffTableRowType.SUB_HEADER_ROW ||
        entry?.rowType === DiffTableRowType.PROPERTY_ROW)
    )
  ) {
    return dataSource;
  }

  if (entry.rowType === DiffTableRowType.SUB_HEADER_ROW) {
    return entry.children!.reduce<DataSource | null>(
      (updatedDataSource, childIndex) =>
        syncGlobalFieldProperties(
          enhancedDiffContextData,
          updatedDataSource,
          childIndex,
          mergeStep
        ),
      dataSource
    );
  }

  if (!globalFieldProperties.has(entry.fieldName)) {
    return dataSource;
  }

  const getGlobalFieldName = getGlobalFieldNameGetter(enhancedDiffContextData);
  const globalFieldName = getGlobalFieldName(entry.entityId);

  return dataSource.reduce(
    (updatedDataSource, { rowType, fieldName, entityId, status }, index) => {
      if (
        !(
          rowType === DiffTableRowType.PROPERTY_ROW &&
          getGlobalFieldName(entityId) === globalFieldName &&
          fieldName === entry.fieldName &&
          status !== entry.status
        )
      ) {
        return updatedDataSource;
      }

      const newDataSource = getNewDataSource(
        updatedDataSource,
        index,
        entry.status
      );
      updateParent(newDataSource[index], newDataSource);
      return newDataSource;
    },
    dataSource
  );
};

const globalFieldProperties = new Set([
  'calculatedFieldSettings',
  'defaultValue',
  'description ',
  'label',
  'name',
  'type',
  'numberFormatOptions',
]);

const getGlobalFieldNameGetter =
  (enhancedDiffContextData: EnhancedDiffScopeData) => (entityId: string) =>
    readRawValue(
      APIEntityType.FIELD,
      entityId,
      'name',
      enhancedDiffContextData[Branch.SOURCE]
    );
