import {
  PayloadOpenIntegration,
  loadImportsHistory,
  loadImportsHistorySuccess,
  setVisibleIntegration,
} from './actions';
import { IntegrationViewState } from './types';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ImportHistory } from '@ardoq/api-types/integrations';

const setVisibleIntegrationReducer = (
  currentState: IntegrationViewState,
  { id, path }: PayloadOpenIntegration
) => ({
  ...currentState,
  integrationPath: path ?? null,
  selectedIntegrationId: id,
});
const handleOpenIntegration = reducer(
  setVisibleIntegration,
  setVisibleIntegrationReducer
);

const loadImportsHistoryReducer = (currentState: IntegrationViewState) => ({
  ...currentState,
  isLoadingImports: true,
});
const handleImportsHistory = reducer(
  loadImportsHistory,
  loadImportsHistoryReducer
);

const loadImportsHistorySuccessReducer = (
  currentState: IntegrationViewState,
  { imports }: { readonly imports: ImportHistory[] }
) => ({
  ...currentState,
  imports,
  isLoadingImports: false,
});
const handleImportsHistorySuccess = reducer(
  loadImportsHistorySuccess,
  loadImportsHistorySuccessReducer
);

const reducers = [
  handleOpenIntegration,
  handleImportsHistory,
  handleImportsHistorySuccess,
];

const defaultState = {
  selectedIntegrationId: null,
  integrationPath: null,
  isLoadingImports: true,
  imports: [],
};

const integrationViewState$ = persistentReducedStream<IntegrationViewState>(
  'integrationsViewState$',
  defaultState,
  reducers
);

export default integrationViewState$;
