import { actionCreator } from '@ardoq/rxbeach';
import {
  AdvancedSearchFilterCondition,
  BooleanOperator,
  QueryBuilderQuery,
} from '@ardoq/api-types';

export const addAdvancedSearchCondition = actionCreator(
  '[advancedSearchCondition] ADD_ADVANCED_SEARCH_CONDITION'
);

export const toggleAdvancedSearchCondition =
  actionCreator<AdvancedSearchFilterCondition>(
    '[advancedSearchCondition] TOGGLE_ADVANCED_SEARCH_CONDITION'
  );

export const expandAdvancedSearchCondition = actionCreator<boolean>(
  '[advancedSearchCondition] EXPAND_ADVANCED_SEARCH_CONDITION'
);

export const removeAdvancedSearchCondition = actionCreator(
  '[advancedSearchCondition] REMOVE_ADVANCED_SEARCH_CONDITION'
);

export const updateAdvancedSearchCondition = actionCreator<BooleanOperator>(
  '[advancedSearchCondition] UPDATE_ADVANCED_SEARCH_CONDITION'
);

export const updateAdvancedSearchQuery =
  actionCreator<QueryBuilderQuery | null>(
    '[advancedSearchCondition] UPDATE_ADVANCED_SEARCH_QUERY'
  );
