import styled, { css } from 'styled-components';
import { colors } from '@ardoq/design-tokens';

export const flatWhiteButtonStyle = css`
  /* && specificity hammer && to override line-height: 1 in .material-icons-round */
  && {
    line-height: 36px;
  }
  background-color: transparent;
  color: color-mix(in srgb, ${colors.white} 80%, transparent);
  &:hover {
    color: color-mix(in srgb, ${colors.white} 30%, transparent);
    color: ${colors.white};
  }
`;

const FlatWhiteButton = styled.button`
  ${flatWhiteButtonStyle}
`;
export default FlatWhiteButton;
