import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import {
  ModifyComponentTypePayload,
  ModifyParentPayload,
  modifyComponentType,
  modifyParent,
  assignSubdivisionMembershipFromParent,
} from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/actions';
import { updateScopeDataProperties } from 'appModelStateEdit/reducerUtils';
import {
  generateHierarchy,
  getComponentTypeByTypeIdAndModelId,
} from '@ardoq/renderers';
import { reducer } from '@ardoq/rxbeach';
import {
  getValidTypeBasedOnNewParent,
  updateTypesBasedOnParent,
} from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/reducerUtils';
import { ArdoqId } from '@ardoq/api-types';
import { subdivisionsComponentsOperations } from '@ardoq/subdivisions';

const modifyParentReducer = (
  state: AppModelStateEditStreamShape,
  newParent: ModifyParentPayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  if (state.entityIDs.length > 1) {
    const scopeDataWithUpdatedParent = updateScopeDataProperties(
      state.entityType,
      state.entityIDs,
      state.enhancedScopeData,
      {
        parent: newParent,
      }
    );
    const scopeDataWithUpdatedTypes = updateTypesBasedOnParent(
      state.entityIDs,
      scopeDataWithUpdatedParent
    );
    return {
      ...state,
      enhancedScopeData: {
        ...scopeDataWithUpdatedTypes,
        hierarchy: generateHierarchy(scopeDataWithUpdatedTypes.components),
      },
    };
  }
  const applicableType = getValidTypeBasedOnNewParent(
    state.entityIDs[0],
    newParent,
    state.enhancedScopeData
  );
  if (!applicableType) {
    return { ...state };
  }
  const newScopeData = updateScopeDataProperties(
    state.entityType,
    state.entityIDs,
    state.enhancedScopeData,
    {
      parent: newParent,
      typeId: applicableType.id,
      type: applicableType.name,
    }
  );
  return {
    ...state,
    enhancedScopeData: {
      ...newScopeData,
      hierarchy: generateHierarchy(newScopeData.components),
    },
  };
};
const handleModifyParent = reducer<
  AppModelStateEditStreamShape,
  ModifyParentPayload
>(modifyParent, modifyParentReducer);

const assignSubdivisionMembershipFromParentReducer = (
  state: AppModelStateEditStreamShape,
  parentComponentId: ArdoqId | null
) => {
  if (
    state.entityType !== 'component' ||
    state.entityIDs.length > 1 ||
    !state.enhancedScopeData
  )
    return state;

  const subdivisionMembership = parentComponentId
    ? subdivisionsComponentsOperations.getSubdivisionsMembership(
        state.enhancedScopeData.componentsById[parentComponentId]
      )
    : [];

  return {
    ...state,
    enhancedScopeData: {
      ...state.enhancedScopeData,
      componentsById: {
        ...state.enhancedScopeData.componentsById,
        [state.entityIDs[0]]: {
          ...state.enhancedScopeData.componentsById[state.entityIDs[0]],
          subdivisionMembership,
        },
      },
    },
  };
};
const handleAssignSubdivisionMembershipFromParent = reducer<
  AppModelStateEditStreamShape,
  ArdoqId | null
>(
  assignSubdivisionMembershipFromParent,
  assignSubdivisionMembershipFromParentReducer
);

const modifyComponentTypeReducer = (
  state: AppModelStateEditStreamShape,
  typeId: ModifyComponentTypePayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  // it's not possible edit type when multi-editing components from different workspaces,
  // so entityIDs[0] is okay here.
  const modelId =
    state.enhancedScopeData?.componentsById[state.entityIDs[0]]?.model;
  const type = getComponentTypeByTypeIdAndModelId(
    typeId,
    modelId!,
    state.enhancedScopeData
  );
  if (!type) {
    return {
      ...state,
    };
  }
  const newScopeData = updateScopeDataProperties(
    state.entityType,
    state.entityIDs,
    state.enhancedScopeData,
    {
      typeId: type.id,
      type: type.name,
    }
  );
  return {
    ...state,
    enhancedScopeData: newScopeData,
  };
};
const handleModifyComponentType = reducer<
  AppModelStateEditStreamShape,
  ModifyComponentTypePayload
>(modifyComponentType, modifyComponentTypeReducer);

const reducers = [
  handleModifyParent,
  handleModifyComponentType,
  handleAssignSubdivisionMembershipFromParent,
];
export default reducers;
