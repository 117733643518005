import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArdoqId } from '@ardoq/api-types';
import documentArchiveAttachments$ from 'streams/documentArchive/documentArchiveAttachments$';
import documentArchiveAttachmentOperations from 'streams/documentArchive/documentArchiveAttachmentOperations';
import { context$ } from 'streams/context/context$';
import {
  AttachmentWithContext,
  DocumentArchiveContext,
} from 'components/DocumentBrowser/types';
import { getCurrentLocale, localeCompare } from '@ardoq/locale';

const getContextWorkspaceAttachments = (
  documentArchiveAttachments: AttachmentWithContext[],
  workspaceId: ArdoqId
) =>
  documentArchiveAttachments.filter(attachment =>
    documentArchiveAttachmentOperations.isOrgOrWorkspaceAttachment(
      attachment,
      workspaceId
    )
  );

const toFileSelectorProps = (attachments: AttachmentWithContext[]) => {
  const [workspaceAttachments, orgAttachments] = attachments.reduce(
    ([workspace, organisation], attachment) =>
      attachment.context === DocumentArchiveContext.WS
        ? [[...workspace, attachment], organisation]
        : [workspace, [...organisation, attachment]],
    [[], []] as [AttachmentWithContext[], AttachmentWithContext[]]
  );

  const locale = getCurrentLocale();

  return {
    options: [
      {
        options: workspaceAttachments
          .map(documentArchiveAttachmentOperations.toSelectOption)
          .sort((a, b) => localeCompare(a.label, b.label, locale)),
        label: 'Workspace attachments',
      },
      {
        options: orgAttachments
          .map(documentArchiveAttachmentOperations.toSelectOption)
          .sort((a, b) => localeCompare(a.label, b.label, locale)),
        label: 'Global attachments',
      },
    ],
  };
};

const fileOptions$ = combineLatest(context$, documentArchiveAttachments$).pipe(
  map(([{ workspaceId }, documentArchive]) =>
    getContextWorkspaceAttachments(documentArchive.models, workspaceId)
  ),
  map(toFileSelectorProps)
);

export default fileOptions$;
