import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  FetchTransferConfigsSuccessPayload,
  fetchTransferConfigs,
  fetchTransferConfigsSuccess,
  renameConfiguration,
  saveConfiguration,
  saveConfigurationsFailure,
  saveConfigurationsSuccess,
  resetTransferConfigMessage,
  setSelectedConfigIds,
} from './actions';
import { filterBySourceTypes } from './utils';
import { Observable, map, switchMap, distinctUntilKeyChanged } from 'rxjs';
import { getActiveIntegrationStream } from '../activeIntegrations/activeIntegrations$';
import { IntegrationId } from '../tabularMappings/types';
import { TransferConfigsState } from './types';
import { AsyncStatus } from 'integrations/common/types/api';

const defaultState: TransferConfigsState = {
  configs: [],
  asyncStatus: 'INIT',
  asyncMessage: '',
  selectedConfigIds: [],
};

const fetchTransferConfigsSuccessReducer = (
  currentState: TransferConfigsState,
  configs: FetchTransferConfigsSuccessPayload
) => ({
  ...currentState,
  configs,
  asyncStatus: 'SUCCESS' as TransferConfigsState['asyncStatus'],
});
const handleSetTransferConfigs = reducer<
  TransferConfigsState,
  FetchTransferConfigsSuccessPayload
>(fetchTransferConfigsSuccess, fetchTransferConfigsSuccessReducer);

const setAsyncStatus =
  (asyncStatus: AsyncStatus) => (state: TransferConfigsState) => ({
    ...state,
    asyncStatus,
  });

const handleFetchConfigurations = reducer<TransferConfigsState>(
  fetchTransferConfigs,
  setAsyncStatus('LOADING')
);

const handleSaveConfigurations = reducer<TransferConfigsState>(
  saveConfiguration,
  setAsyncStatus('LOADING')
);

const handleRenameConfigurations = reducer<TransferConfigsState>(
  renameConfiguration,
  setAsyncStatus('LOADING')
);

const handleSaveConfigurationsSuccess = reducer<TransferConfigsState>(
  saveConfigurationsSuccess,
  setAsyncStatus('SUCCESS')
);

const setConfigurationFailure =
  (asyncStatus: AsyncStatus) =>
  (state: TransferConfigsState, asyncMessage: string) => ({
    ...state,
    asyncStatus,
    asyncMessage,
  });

const handleSaveConfigurationsFailure = reducer<TransferConfigsState, string>(
  saveConfigurationsFailure,
  setConfigurationFailure('FAILURE')
);

const resetTransferConfigMessageReducer = (
  currentState: TransferConfigsState
) => ({
  ...currentState,
  asyncMessage: '',
  asyncStatus: 'INIT' as TransferConfigsState['asyncStatus'],
});

const handleResetTransferConfigMessage = reducer<TransferConfigsState>(
  resetTransferConfigMessage,
  resetTransferConfigMessageReducer
);

const setSelectedConfigIdsReducer = (
  currentState: TransferConfigsState,
  selectedConfigIds: string[]
) => ({
  ...currentState,
  selectedConfigIds,
});

const handleSetSelectedConfigIds = reducer<TransferConfigsState, string[]>(
  setSelectedConfigIds,
  setSelectedConfigIdsReducer
);

const reducers = [
  handleSetTransferConfigs,
  handleFetchConfigurations,
  handleSaveConfigurations,
  handleRenameConfigurations,
  handleSaveConfigurationsSuccess,
  handleSaveConfigurationsFailure,
  handleResetTransferConfigMessage,
  handleSetSelectedConfigIds,
];

export const transferConfigs$ = persistentReducedStream(
  `transferConfigs$`,
  defaultState,
  reducers
);

export const getTransferConfigsStream = (
  integrationId: IntegrationId
): Observable<TransferConfigsState> => {
  return getActiveIntegrationStream(integrationId).pipe(
    distinctUntilKeyChanged('sourceTypes'),
    switchMap(activeIntegration =>
      transferConfigs$.pipe(
        map(transferConfigs => ({
          ...transferConfigs,
          configs: filterBySourceTypes(
            activeIntegration.sourceTypes,
            transferConfigs.configs
          ),
        }))
      )
    )
  );
};
