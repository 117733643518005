import { actionCreator } from '@ardoq/rxbeach';
import {
  AdvancedSearchFilterConditionByReference,
  BooleanOperator,
  QueryBuilderSubquery,
} from '@ardoq/api-types';

export const addAdvancedSearchCondition = actionCreator(
  '[advancedSearchConditionByReference] ADD_ADVANCED_SEARCH_CONDITION'
);

export const toggleAdvancedSearchCondition =
  actionCreator<AdvancedSearchFilterConditionByReference>(
    '[advancedSearchConditionByReference] TOGGLE_ADVANCED_SEARCH_CONDITION'
  );

export const expandAdvancedSearchCondition = actionCreator<boolean>(
  '[advancedSearchConditionByReference] EXPAND_ADVANCED_SEARCH_CONDITION'
);

export const removeAdvancedSearchCondition = actionCreator(
  '[advancedSearchConditionByReference] REMOVE_ADVANCED_SEARCH_CONDITION'
);

export const updateAdvancedSearchCondition = actionCreator<BooleanOperator>(
  '[advancedSearchConditionByReference] UPDATE_ADVANCED_SEARCH_CONDITION'
);

export const updateAdvancedSearchQuery =
  actionCreator<QueryBuilderSubquery | null>(
    '[advancedSearchConditionByReference] UPDATE_ADVANCED_SEARCH_QUERY'
  );

export const toggleIncomingReferences = actionCreator(
  '[advancedSearchConditionByReference] TOGGLE_INCOMING_REFERENCES'
);

export const toggleOutgoingReferences = actionCreator(
  '[advancedSearchConditionByReference] TOGGLE_OUTGOING_REFERENCES'
);
