import styled from 'styled-components';
import xor from 'lodash/xor';
import { ButtonSize, GhostButton } from '@ardoq/button';
import { ICON_SELECTOR, Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

export const ExpandRowButtonComponent = styled(GhostButton)`
  ${({ isActive }) =>
    isActive &&
    `background-color: ${colors.transparent10};
     color: ${colors.grey25};
     ${ICON_SELECTOR} {
        color: ${colors.grey25};
      }
      &:active {
          background-color: ${colors.transparent10};
          color: ${colors.grey25};
        }
      &:hover {
        background-color: ${colors.transparent10};
      }
    `};
`;

type ExpandRowButtonProps = {
  expandedRowIds: string[];
  setExpandedRowIds: (expandedRowIds: string[]) => void;
  rowId: string;
};

export const ExpandRowButton = ({
  expandedRowIds,
  setExpandedRowIds,
  rowId,
}: ExpandRowButtonProps) => (
  <ExpandRowButtonComponent
    buttonSize={ButtonSize.REGULAR}
    overflowMethod="ellipsis"
    isActive={expandedRowIds.includes(rowId)}
    onClick={() => setExpandedRowIds(xor(expandedRowIds, [rowId]))}
  >
    View details{' '}
    <Icon
      iconName={
        expandedRowIds.includes(rowId)
          ? IconName.CHEVRON_UP
          : IconName.CHEVRON_DOWN
      }
    />
  </ExpandRowButtonComponent>
);
