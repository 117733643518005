import { isEqual, sortBy, uniq } from 'lodash';

type Primitive = string | number | boolean | bigint | symbol | null | undefined;
export type SortedList<T extends Primitive> = Array<T>;

const construct = <T extends Primitive>(list: T[]): SortedList<T> => {
  return sortBy(uniq(list));
};

const add = <T extends Primitive>(
  list: SortedList<T>,
  item: T
): SortedList<T> => {
  if (list.includes(item)) return list;
  return sortBy([...list, item]);
};

const remove = <T extends Primitive>(
  list: SortedList<T>,
  item: T
): SortedList<T> => {
  return list.filter(i => i !== item);
};

const clone = <T extends Primitive>(list: SortedList<T>): SortedList<T> => {
  return sortBy([...list]);
};

const areEqual = <T extends Primitive>(
  list1: SortedList<T>,
  list2: SortedList<T>
): boolean => isEqual(list1, list2);

const intersection = <T extends Primitive>(
  list1: SortedList<T>,
  list2: SortedList<T>
): SortedList<T> => list1.filter(i => list2.includes(i));

const difference = <T extends Primitive>(
  list1: SortedList<T>,
  list2: SortedList<T>
): SortedList<T> => list1.filter(i => !list2.includes(i));

const union = <T extends Primitive>(
  list1: SortedList<T>,
  list2: SortedList<T>
): SortedList<T> => construct([...list1, ...list2]);

export const sortedListOperations = {
  construct,
  add,
  remove,
  clone,
  areEqual,
  intersection,
  difference,
  union,
};
