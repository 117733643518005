import {
  collectRoutines,
  routine,
  dispatchAction,
  ofType,
} from '@ardoq/rxbeach';
import {
  apiFetchCurrentUserPermissions,
  failedFetchCurrentUserPermissions,
  fetchedCurrentUserPermissions,
} from './actions';
import { mergeMap, tap } from 'rxjs/operators';
import { handleError, userApi } from '@ardoq/api';

const handleFetchAll = routine(
  ofType(apiFetchCurrentUserPermissions),
  mergeMap(userApi.fetchCurrentUserPermissions),
  handleError(error => {
    dispatchAction(failedFetchCurrentUserPermissions(error));
  }),
  tap(permissions => {
    dispatchAction(fetchedCurrentUserPermissions(permissions));
  })
  // TODO - handle errors?
);
export const currentUserPermissionRoutines = collectRoutines(handleFetchAll);
