import {
  APIComponentAttributes,
  APIEntityType,
  ArdoqId,
} from '@ardoq/api-types';
import {
  FieldType,
  componentAttributesMap,
  getComponentTypeByComponentId,
  getEntityById,
  getFieldLabel,
  readRawValue,
} from '@ardoq/renderers';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  COMPONENT_STYLE_PLACEHOLDER,
  defaultComponentStyleAttributeNames,
} from 'appModelStateEdit/propertiesEditor/componentStylePropertiesEditor/consts';
import { pick } from 'lodash';
import { getValidationErrorMessages } from 'scopeData/editors/validators';
import { DirtyAttributes } from 'appModelStateEdit/types';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { getDefaultCSSColor } from '@ardoq/color-helpers';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { PermissionContext } from '@ardoq/access-control';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { EditorProperty } from '../types';
import { ScenarioModeState } from 'scope/types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

type DefaultStyle = {
  readonly icon: string | null;
  readonly image: string | null;
  readonly shape: string | null;
  readonly color: string | null;
};

const canEditStyle = (
  component: APIComponentAttributes,
  activeScenarioState: ScenarioModeState,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
) => {
  if (activeScenarioOperations.isInScenarioMode(activeScenarioState)) {
    return scenarioAccessControlInterface.canEditActiveScenario(
      permissionContext,
      activeScenarioState
    );
  }
  return componentAccessControlOperation.canEditComponent({
    component,
    permissionContext,
    subdivisionsContext,
  });
};

export const getDefaultStylePropertyValues = (
  componentId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const componentType = getComponentTypeByComponentId(
    componentId,
    enhancedScopeData
  )!;
  const defaultStylePropertyValues = pick(
    componentType,
    defaultComponentStyleAttributeNames
  ) as DefaultStyle;
  if (!defaultStylePropertyValues.color) {
    return {
      ...defaultStylePropertyValues,
      color: getDefaultCSSColor(componentType.level),
    };
  }
  return defaultStylePropertyValues;
};

export const getDefaultProperties = (
  componentId: ArdoqId,
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
): EditorProperty[] => {
  const component = getEntityById(
    APIEntityType.COMPONENT,
    componentId,
    enhancedScopeData
  );
  if (!component) {
    return [];
  }
  const defaultStylePropertyValues = getDefaultStylePropertyValues(
    componentId,
    enhancedScopeData
  );
  return defaultComponentStyleAttributeNames
    .map(attributeName => {
      const type = componentAttributesMap.get(attributeName);
      if (!type) {
        return null;
      }
      const isColor = type === FieldType.COLOR;
      const label = getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.COMPONENT,
      });
      const value = readRawValue(
        APIEntityType.COMPONENT,
        componentId,
        attributeName,
        enhancedScopeData
      );
      const defaultValue =
        defaultStylePropertyValues[attributeName as keyof DefaultStyle];
      const isDirty = dirtyAttributes.has(attributeName);
      const errorMessages = getValidationErrorMessages(type, value);
      const isDisabled = !canEditStyle(
        component,
        activeScenarioState,
        permissionContext,
        subdivisionsContext
      );
      return {
        type,
        name: attributeName,
        label,
        value: isDirty ? value : value || defaultValue,
        defaultValue,
        isDirty,
        isDisabled,
        errorMessages,
        options: defaultPropertyOptionGetterMap.get(type)?.(component),
        ...(isColor ? { hasResetButton: true } : {}),
        ...(!isColor && !value
          ? { placeholder: COMPONENT_STYLE_PLACEHOLDER }
          : {}),
      };
    })
    .filter(ExcludeFalsy);
};

const getImageOptions = (component: APIComponentAttributes) =>
  documentArchiveInterface
    .getDocumentArchiveImages({
      wsId: component.rootWorkspace,
    })
    .map(({ filename, uri }) => ({
      label: filename,
      value: uri,
    }));

const defaultPropertyOptionGetterMap = new Map([
  [FieldType.IMAGE, getImageOptions],
]);
