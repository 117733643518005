import { connect, dispatchAction } from '@ardoq/rxbeach';
import styled from 'styled-components';
import { viewModel$ } from './viewModel';
import { AsyncStatus } from 'integrations/common/types/api';
import { Connection } from '@ardoq/api-types/integrations';
import { MicrosoftGraphConsent } from '@ardoq/api-types/integrations';
import { DataSourceEmptyTable } from '@ardoq/integrations';
import { IconName } from '@ardoq/icons';
import { showSupport } from 'utils/support';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { GrantAccess } from 'integrations/microsoftEntraId/components/GrantAccess/GrantAccess';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { microsoftEntraIdDictionary } from 'integrations/common/dictionary';

const ConnectionName = styled.div`
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

type ConnectionsProps = {
  status: AsyncStatus;
  connections: Connection[];
  consent: MicrosoftGraphConsent;
  onConnectionClick: VoidFunction;
};

const ConnectionsComponent = ({
  status,
  connections,
  consent,
  onConnectionClick,
}: ConnectionsProps) => {
  if (status === 'SUCCESS' && connections.length === 0) {
    return consent.tenantId ? (
      <GrantAccess consentUrl={consent.adminConsentUrl} />
    ) : (
      <DataSourceEmptyTable
        title="You don't have any connections yet"
        message={
          <>
            Contact our customer support team to set up a{' '}
            {microsoftEntraIdDictionary.name} connection. <br /> <br /> During
            setup, you must provide the Tenant ID to establish the connection.
          </>
        }
        button={{
          text: 'Contact support',
          onClick: () => {
            dispatchAction(
              trackIntegrationEvent({
                integrationId: 'microsoft-entra-id',
                name: 'OPENED_CONNECTION_CONTACT_SUPPORT',
              })
            );

            showSupport({
              message: `I need help connecting to ${microsoftEntraIdDictionary.name}.`,
            });
          },
          icon: IconName.COMMENT,
        }}
      />
    );
  }
  return (
    <DatasourceTable
      dataSource={connections}
      loading={status === 'LOADING'}
      components={newTableTheme}
      columns={[
        {
          dataIndex: 'name',
          title: 'Connection name',
          valueRender: (_, connection) => (
            <ConnectionName onClick={onConnectionClick}>
              {connection.name}
            </ConnectionName>
          ),
        },
      ]}
    />
  );
};

export const Connections = connect(ConnectionsComponent, viewModel$);
