import { map, switchMap, tap } from 'rxjs/operators';
import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { fetchGlobalFields, setGlobalFields } from 'globalFields/actions';
import { dateRangeOperations } from '@ardoq/date-range';
import { fieldApi, handleError } from '@ardoq/api';

const handleFetchGlobalFields = routine(
  ofType(fetchGlobalFields),
  switchMap(fieldApi.globalFields),
  handleError(error => logError(error, 'Failed to fetch global fields')),
  map(globalFields => {
    const { fields } =
      dateRangeOperations.mergeGlobalFieldsToDateRangeFields(globalFields);
    return fields;
  }),
  tap(globalFields => {
    dispatchAction(setGlobalFields(globalFields));
  })
);

export default collectRoutines(handleFetchGlobalFields);
