import { reducer } from '@ardoq/rxbeach';
import {
  grantReadAccessUnderlyingResource,
  initializeResourcePermissionViewModel,
  AddNewResourcePermissionPayload,
  addNewResourcePermission,
  permissionChange,
  revokePermission,
  addResourceToSubdivisionInPermissionsEditor,
} from './actions';
import {
  ActorType,
  CreateManageResourcePermissionViewModelArgs,
  ManageResourcePermissionDataContext,
  actorPermissionOps,
  manageResourcePermissionOps,
  userActorOps,
  groupActorOps,
  resourceOps,
} from '@ardoq/manage-resource-permissions';
import {
  GroupResourcePermission,
  PermissionAccessLevel,
  PermissionType,
  UserResourcePermission,
} from '@ardoq/api-types';

const initializeReducer = (
  _: ManageResourcePermissionDataContext,
  config: CreateManageResourcePermissionViewModelArgs
) => ({
  ...manageResourcePermissionOps.createManageResourcePermissionDataContext(
    config
  ),
});

const addNewActorReducer = (
  state: ManageResourcePermissionDataContext,
  { actor, resources, appState }: AddNewResourcePermissionPayload
) => ({
  ...state,
  isEdited: true,
  actorsPermissions: [
    ...state.actorsPermissions,
    ...resources.map(resource => {
      if (actor.type === ActorType.USER) {
        const userPermission: UserResourcePermission = {
          permissions: [PermissionAccessLevel.READ],
          name: actor.name,
          _id: actor.actorId,
          type: PermissionType.USER,
        };

        return userActorOps.userPermissionToActorPermission({
          appState,
          resource,
          userPermission,
        });
      }
      const groupPermission: GroupResourcePermission = {
        name: actor.actorId,
        permissions: [PermissionAccessLevel.READ],
      };
      return groupActorOps.groupPermissionToActorPermission({
        appState,
        resource,
        groupPermission,
      });
    }),
  ],
});

export const reducers = [
  reducer(initializeResourcePermissionViewModel, initializeReducer),
  reducer(revokePermission, actorPermissionOps.revokeActorPermission),
  reducer(permissionChange, actorPermissionOps.changeActorPermission),
  reducer(
    grantReadAccessUnderlyingResource,
    actorPermissionOps.grantReadAccessUnderlyingResource
  ),
  reducer(addNewResourcePermission, addNewActorReducer),
  reducer(
    addResourceToSubdivisionInPermissionsEditor,
    resourceOps.addResourceToSubdivisionReducer
  ),
];
