import { APIEntityType, ArdoqId, ViewIds } from '@ardoq/api-types';
import {
  FieldType,
  defaultAttributesMap,
  getFieldLabel,
  readRawValue,
  workspaceAttributesMap,
} from '@ardoq/renderers';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { SelectOption } from '@ardoq/select';
import {
  customWorkspaceAttributeNames,
  defaultWorkspaceAttributeNames,
} from 'appModelStateEdit/propertiesEditor/workspacePropertiesEditor/consts';
import getSortOptions, { getFieldOptions } from 'utils/getSortOptions';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import { getValidationErrorMessages } from 'scopeData/editors/validators';
import { DirtyAttributes } from 'appModelStateEdit/types';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { EditorProperty } from '../types';

export const getDefaultProperties = (
  workspaceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): EditorProperty[] => {
  return defaultWorkspaceAttributeNames
    .map(attributeName => {
      const type = defaultAttributesMap.get(attributeName);
      if (!type) {
        return null;
      }
      const label = getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.WORKSPACE,
      });
      const value = readRawValue(
        APIEntityType.WORKSPACE,
        workspaceId,
        attributeName,
        enhancedScopeData
      );
      const isDirty = dirtyAttributes.has(attributeName);
      const errorMessages = getValidationErrorMessages(type, value);
      return {
        type,
        name: attributeName,
        label,
        value,
        isDirty,
        errorMessages,
      };
    })
    .filter(ExcludeFalsy);
};

export const getCustomProperties = (
  workspaceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): EditorProperty[] => {
  return customWorkspaceAttributeNames.map(attributeName => {
    const type = workspaceAttributesMap.get(attributeName)!;
    const value = readRawValue(
      APIEntityType.WORKSPACE,
      workspaceId,
      attributeName,
      enhancedScopeData
    );
    const isDirty = dirtyAttributes.has(attributeName);
    const errorMessages = getValidationErrorMessages(type, value);
    return {
      type,
      name: attributeName,
      label: getFieldLabel({
        fieldName: attributeName,
        enhancedScopeData,
        entityType: APIEntityType.WORKSPACE,
      }),
      value,
      isDirty,
      errorMessages,
      options: customPropertyOptionGetterMap.get(type)?.(workspaceId),
    };
  });
};

const getStartViewOptions = (id: ArdoqId): SelectOption<ViewIds | null>[] =>
  workspaceInterface.getStartViewOptions(id) ?? [];

const getDefaultPerspectiveOptions = (): SelectOption<string>[] =>
  perspectiveInterface.getAll().map(perspective => {
    return {
      value: perspective._id,
      label: perspective.name,
    };
  });

const getDefaultSortOptions = (workspaceId: ArdoqId): SelectOption<any>[] =>
  getSortOptions({ fieldOptions: getFieldOptions(workspaceId) });

const customPropertyOptionGetterMap = new Map([
  [FieldType.START_VIEW, getStartViewOptions],
  [FieldType.DEFAULT_PERSPECTIVE, getDefaultPerspectiveOptions],
  [FieldType.DEFAULT_SORT, getDefaultSortOptions],
]);
