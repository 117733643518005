import { combineLatest, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import currentUser$ from 'streams/currentUser/currentUser$';
import { permissionsOperations } from '@ardoq/access-control';
import { emitOverviewActionEvent } from 'integrations/common/tracking/utils';
import {
  connectionClick,
  listConnections,
  upsertConnectionInitiate,
} from 'integrations/common/streams/connections/actions';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { Connection } from '@ardoq/api-types/integrations';
import { updateConnectionStatuses } from 'integrations/common/streams/connections/actions';
import { startDeleteConnectionModal } from 'integrations/common/modals/deleteConnectionModal/DeleteConnectionModal';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { getIntegrationExtraColumns } from './columns';
import { sortBy } from 'lodash/fp';

const onDelete = (integrationId: IntegrationId, connection: Connection) => {
  emitOverviewActionEvent('connection-delete', integrationId);
  startDeleteConnectionModal(integrationId, connection?._id).then(() => {
    dispatchAction(
      updateConnectionStatuses({
        integrationId: integrationId,
        statuses: { delete: { status: 'INIT' } },
      })
    );
  });
};

const onUpsertClick = (
  integrationId: IntegrationId,
  connection?: Connection
) => {
  emitOverviewActionEvent(
    connection ? 'connection-edit' : 'connection-create',
    integrationId
  );
  dispatchAction(upsertConnectionInitiate({ integrationId, connection }));
};

const onConnectionClick = (
  integrationId: IntegrationId,
  connection: Connection
) => {
  dispatchAction(connectionClick({ integrationId: integrationId, connection }));
};

const onRefresh = (integrationId: IntegrationId) => {
  dispatchAction(listConnections({ integrationId }));
};

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId => {
    return combineLatest([
      currentUser$,
      getConnectionsStream(integrationId),
      getIntegrationTermsDictionaryStream(integrationId),
      of(integrationId),
    ]).pipe(
      map(
        ([
          currentUser,
          { connections, statuses },
          { connectYourAccountTitle, connectYourAccountBtn },
          integrationId,
        ]) => ({
          connections: sortBy('name', connections),
          statuses,
          isAdmin: permissionsOperations.isOrgAdmin(currentUser),
          onDelete: (connection: Connection) =>
            onDelete(integrationId, connection),
          onUpsertClick: (connection?: Connection) =>
            onUpsertClick(integrationId, connection),
          onRefresh: () => onRefresh(integrationId),
          onConnectionClick: (connection: Connection) =>
            onConnectionClick(integrationId, connection),
          extraColumns: getIntegrationExtraColumns(integrationId),
          connectYourAccountTitle,
          connectYourAccountBtn,
        })
      )
    );
  })
);
