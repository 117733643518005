import { IntegrationSpecificTerms } from './streams/integrationTermsDictionary/types';
/**
 * Why all dictionaries are in one place instead of being in their respective folders?
 * We use dictionaries outside of the integrations folders, so it makes sense to have them in a common place.
 */

/**
 * Why not unified the dictionaries here?
 * Unified integrations repeat that much of same business name so it's not necessary to unify them.
 */
enum BusinessNames {
  ServiceNow = 'ServiceNow',
  Excel = 'Excel',
  Azure = 'Azure',
  MicrosoftEntraId = 'Microsoft Entra ID',
  Aws = 'AWS',
}

export const microsoftEntraIdDictionary: IntegrationSpecificTerms = {
  name: BusinessNames.MicrosoftEntraId,
  integrationOverviewTitle: `${BusinessNames.MicrosoftEntraId} integration`,
  rightSidebarHeader: `Having trouble with the ${BusinessNames.MicrosoftEntraId} integration?`,
  rightSideBarFAQ: [
    {
      link: 'https://help.ardoq.com/en/articles/169548-microsoft-entra-id',
      text: `How to import ${BusinessNames.MicrosoftEntraId} data.`,
    },
  ],
  connectYourAccountTitle: `Connect your ${BusinessNames.MicrosoftEntraId} account to Ardoq to get started.`,
  connectYourAccountBtn: 'Create new connection',
};

export const excelDictionary: IntegrationSpecificTerms = {
  name: `${BusinessNames.Excel}`,
  integrationOverviewTitle: `${BusinessNames.Excel} integration`,
  tableHeader: 'Worksheets details',
  stepTitle: 'Worksheet rows contain:',
  tabsTitle: 'Worksheets',
  rightSidebarHeader: `Having trouble with the ${BusinessNames.Excel} importer?`,
  connectYourAccountBtn: '',
  connectYourAccountTitle: '',
  rightSideBarFAQ: [
    {
      link: 'https://help.ardoq.com/en/articles/44162-what-are-the-building-blocks-of-ardoq',
      text: 'Ardoq core concepts',
    },
    {
      link: 'https://help.ardoq.com/en/articles/43943-excel-integration',
      text: `${BusinessNames.Excel} import guide`,
    },
  ],
};

export const serviceNowDictionary: IntegrationSpecificTerms = {
  name: `${BusinessNames.ServiceNow}`,
  integrationOverviewTitle: `${BusinessNames.ServiceNow} integration`,
  rightSidebarHeader: `Having trouble with the ${BusinessNames.ServiceNow} integration?`,
  rightSideBarFAQ: [
    {
      link: 'https://help.ardoq.com/en/articles/44072-how-to-import-servicenow-data-into-ardoq',
      text: `How to import ${BusinessNames.ServiceNow} server data.`,
    },
    {
      link: 'https://help.ardoq.com/en/articles/43946-how-to-create-references-with-your-servicenow-data',
      text: `How to create references with ${BusinessNames.ServiceNow} data.`,
    },
    {
      link: 'https://help.ardoq.com/en/articles/43947-how-to-export-ardoq-data-to-servicenow',
      text: `How to export Ardoq server data to ${BusinessNames.ServiceNow}.`,
    },
    {
      link: 'https://help.ardoq.com/en/articles/174771-how-to-map-from-the-servicenow-csdm-to-ardoq-solutions',
      text: `How to map from the ${BusinessNames.ServiceNow} CSDM to Ardoq solutions.`,
    },
  ],
  connectYourAccountTitle: `Connect your ${BusinessNames.ServiceNow} account to Ardoq to get started.`,
  connectYourAccountBtn: `Connect to ${BusinessNames.ServiceNow}`,
  noConfigsMessage: `Create a new import or export to save a configuration.`,
  noSchedulesMessage: `Create a new import or export to set up a schedule.`,
};

export const awsDictionary: IntegrationSpecificTerms = {
  name: `${BusinessNames.Aws}`,
  integrationOverviewTitle: `${BusinessNames.Aws} integration`,
  rightSidebarHeader: `Having trouble with the ${BusinessNames.Aws} integration?`,
  rightSideBarFAQ: [
    {
      link: 'https://help.ardoq.com/en/articles/158198-aws-integration-beta',
      text: `How to import ${BusinessNames.Aws} data.`,
    },
  ],
  connectYourAccountTitle: `Connect your ${BusinessNames.Aws} account to Ardoq to get started.`,
  connectYourAccountBtn: 'Create new connection',
  selectRegionsLabel: 'Select regions',
  selectRegionsHelperText:
    'The geographical location of your infrastructure. Select at least one region to continue.',
  selectConnectionHelperText:
    'Select at least one connection to access your organization’s data sets.',
  selectConnectionPlaceHolder: 'Type and select a connection',
};

export const azureDictionary: IntegrationSpecificTerms = {
  name: `${BusinessNames.Azure}`,
  integrationOverviewTitle: `${BusinessNames.Azure} integration`,
  rightSidebarHeader: `Having trouble with the ${BusinessNames.Azure} integration?`,
  rightSideBarFAQ: [
    {
      link: 'https://help.ardoq.com/en/articles/44034-azure-integration',
      text: `How to import ${BusinessNames.Azure} data.`,
    },
  ],
  connectYourAccountTitle: `Connect your ${BusinessNames.Azure} account to Ardoq to get started.`,
  connectYourAccountBtn: 'Create new connection',
  selectRegionsLabel: 'Select location(s)',
  selectRegionsHelperText:
    'The geographical location of your infrastructure. Select at least one location to continue.',
};
