import { GENERIC_FORM_ERROR_MESSAGE } from 'appModelStateEdit/propertiesEditor/consts';

/**
 * @deprecated use getErrorMessage from @ardoq/api
 */
// TODO[ARD-22935]: Remove this function
export const getFormErrorMessage = (message?: string | null) => {
  if (!message) {
    return GENERIC_FORM_ERROR_MESSAGE;
  }
  if (message.toLowerCase().includes('spec')) {
    return GENERIC_FORM_ERROR_MESSAGE;
  }
  return message;
};
