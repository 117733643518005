import { ArdoqId } from '@ardoq/api-types';
import { ComponentBackboneModel } from '../../aqTypes';
import { ContextSort, ExcludeFalsy } from '@ardoq/common-helpers';
import { ComponentWithRelationships } from '../gridEditorBackboneInterface';
import { compareBackboneModels } from '../../utils/compareUtils';

export type ComponentHierarchy = {
  /**
   * Index of componentId -> componentIds[]
   * Contains ALL workspace components
   * WARN: Do not rely on Object.keys() from this index, may be outdated
   */
  childIndex: Record<string, string[] | undefined> & { root: string[] };

  /**
   * Index of componentId -> parentComponentId | 'root'
   * WARN: Do not rely on Object.keys() from this index, may be outdated
   */
  parentIndex: Record<ArdoqId, ArdoqId | 'root' | undefined>;

  /**
   * Index of componentId -> BackboneComponentModel
   * Contains ALL workspace components, and will by definition have models for
   * all ids in childIndex.
   */
  componentMap: Map<string, ComponentBackboneModel>;
  /**
   * All workspace components. An optimization to avoid iterating over all
   * components when "show all components" is selected
   */
  components: ComponentBackboneModel[];
};

const createEmpty = (): ComponentHierarchy => ({
  childIndex: { root: [] },
  componentMap: new Map(),
  components: [],
  parentIndex: {},
});

/**
 * Copy workspaceComponentHierarchy and its top level properties in order to
 * mutate the top level locally inside another function.
 */
const shallowCopy = (state: ComponentHierarchy): ComponentHierarchy => ({
  componentMap: new Map(state.componentMap),
  childIndex: { ...state.childIndex },
  components: [...state.components],
  parentIndex: { ...state.parentIndex },
});

const containsComponent = (state: ComponentHierarchy, componentId: string) => {
  return state.componentMap.has(componentId);
};

const getAll = ({ components }: ComponentHierarchy) => {
  return components;
};

const getComponent = (
  componentId: string,
  { componentMap }: ComponentHierarchy
) => {
  return componentMap.get(componentId);
};

const getDescendants = (
  componentId: string,
  workspaceComponentHierarchy: ComponentHierarchy
): ComponentBackboneModel[] => {
  const { childIndex, componentMap } = workspaceComponentHierarchy;
  const children = childIndex[componentId] ?? [];
  if (children.length === 0) return [];

  return children.flatMap(childId => {
    // By definition all children IDs exist in componentMap, but this check is
    // performed to appease TypeScript
    const component = componentMap.get(childId);
    if (!component) {
      return [];
    }

    return [component, ...getDescendants(childId, workspaceComponentHierarchy)];
  });
};

/**
 * Use ComponentHierarchy to get children of a component.
 */
const getChildren = (
  componentId: string,
  { childIndex, componentMap }: ComponentHierarchy
) => {
  const directChildren = childIndex[componentId] ?? [];
  return directChildren
    .map(componentId => componentMap.get(componentId))
    .filter(ExcludeFalsy);
};

const getRootComponents = (
  workspaceComponentsHierarchy: ComponentHierarchy
) => {
  return getChildren('root', workspaceComponentsHierarchy);
};

/**
 * Add components to the hierarchy
 * Shallow copies the hierarchy in order to mutate it in order to avoid having
 * to create new structures for every component added.
 */
const addComponents = (
  state: ComponentHierarchy,
  payload: {
    componentsWithRelationships: ComponentWithRelationships[];
    sort: ContextSort;
  }
): ComponentHierarchy => {
  const { componentsWithRelationships, sort } = payload;
  const hierarchyCopy = componentHierarchyOperations.shallowCopy(state);

  return componentsWithRelationships
    .sort(({ component: compA }, { component: compB }) => {
      // This sort ensures that added components are sorted as expected, added
      // here because it is easy to forget
      return compareBackboneModels(compA, compB, sort);
    })
    .reduce<ComponentHierarchy>(
      (newState, { childrenIds, component, componentId, parentId }) => {
        if (!newState.componentMap.has(componentId)) {
          newState.components.push(component);
        }

        newState.componentMap.set(componentId, component);
        newState.childIndex[componentId] = childrenIds;

        // update childIndex of parent
        const parentChildrenIds = newState.childIndex[parentId] ?? [];
        if (!parentChildrenIds.includes(componentId)) {
          newState.childIndex[parentId] = [...parentChildrenIds, componentId];
        }

        newState.parentIndex[componentId] = parentId;

        return newState;
      },
      hierarchyCopy
    );
};

const removeComponents = (
  workspaceHierarchy: ComponentHierarchy,
  removedComponentIds: string[]
): ComponentHierarchy => {
  const hasRelevantChange = removedComponentIds.some(componentId =>
    containsComponent(workspaceHierarchy, componentId)
  );
  if (!hasRelevantChange) {
    return workspaceHierarchy;
  }

  const hierarchyCopy = shallowCopy(workspaceHierarchy);
  const removedComponentIdsSet = new Set(removedComponentIds);

  // Optimization: Remove components in a single pass
  hierarchyCopy.components = hierarchyCopy.components.filter(
    component => !removedComponentIdsSet.has(component.getId())
  );

  // Calculate affected parentIds, ignoring ids that will be deleted
  const affectedParentIds = new Set(
    removedComponentIds
      .map(componentId => hierarchyCopy.parentIndex[componentId])
      .filter(parentId => parentId && !removedComponentIdsSet.has(parentId))
  );

  return removedComponentIds.reduce((newState, removedComponentId) => {
    const isRelevant = newState.componentMap.has(removedComponentId);
    if (!isRelevant) {
      return newState;
    }

    newState.componentMap.delete(removedComponentId);

    const parentId = newState.parentIndex[removedComponentId] || 'root';
    newState.parentIndex[removedComponentId] = undefined;

    // Optimization: Filter childIndex of a parent _once_, this operation may
    // be expensive if many components share the same parent (eg. root)
    if (affectedParentIds.has(parentId)) {
      newState.childIndex[parentId] = newState.childIndex[parentId]?.filter(
        childId => !removedComponentIdsSet.has(childId)
      );
      affectedParentIds.delete(parentId);
    }

    return newState;
  }, hierarchyCopy);
};

/**
 * Get components based on context:
 * - isShowAllComponents => all components
 * - selectedComponentId => selected component and its children
 * - else => root components (components without a parent)
 */
const getComponentsFromSelection = (
  componentHierarchy: ComponentHierarchy,
  context: {
    isShowAllComponents: boolean;
    selectedComponentId: string;
    sort: ContextSort;
  }
) => {
  const { isShowAllComponents, selectedComponentId, sort } = context;

  if (isShowAllComponents) {
    return [...componentHierarchyOperations.getAll(componentHierarchy)].sort(
      (a, b) => compareBackboneModels(a, b, sort)
    );
  }

  // No component selected, return all top level components.
  const selectedComponent = selectedComponentId
    ? componentHierarchyOperations.getComponent(
        selectedComponentId,
        componentHierarchy
      )
    : undefined;

  if (!selectedComponentId || !selectedComponent) {
    return componentHierarchyOperations
      .getRootComponents(componentHierarchy)
      .sort((a, b) => compareBackboneModels(a, b, sort));
  }

  const children = componentHierarchyOperations
    .getChildren(selectedComponentId, componentHierarchy)
    .sort((a, b) => compareBackboneModels(a, b, sort));

  return [selectedComponent, ...children];
};

export const componentHierarchyOperations = {
  createEmpty,
  shallowCopy,
  containsComponent,
  addComponents,
  removeComponents,
  getAll,
  getComponent,
  getComponentsFromSelection,
  getDescendants,
  getChildren,
  getRootComponents,
};
