import { advancedSearchApi } from '@ardoq/api';
import { ArdoqId, QueryBuilderQuery } from '@ardoq/api-types';
import { isArdoqError, toArdoqError } from '@ardoq/common-helpers';

export class ScenarioCreationExceededComponentsLimitError extends Error {
  constructor() {
    super('Components limit exceeded during scenario creation');
  }
}

const COMPONENT_LIMIT = 1000;

export const getComponentIdsFromSearchQuery = async (
  searchQuery: QueryBuilderQuery
) => {
  const result = await advancedSearchApi.search<{ _id: ArdoqId }>(searchQuery, {
    size: COMPONENT_LIMIT,
  });
  if (isArdoqError(result)) {
    return result;
  }
  const { total, results } = result;
  if (total > COMPONENT_LIMIT) {
    return toArdoqError({
      error: new ScenarioCreationExceededComponentsLimitError(),
    });
  }
  return results.map(({ doc }) => doc._id);
};
