import {
  LocallyDerivedTransformation,
  LocallyDerivedTransformationOperation,
} from '@ardoq/api-types';
import { Stack } from '@ardoq/layout';
import { FieldType } from '@ardoq/renderers';
import { SelectOption } from '@ardoq/select';
import { DictionaryLookupDetailsSection } from './DictionaryLookupDetailsSection';
import { MathDetailsSection } from './MathDetailsSection';
import { OperationSection } from './OperationSection';
import { AutocompleteFieldSuggestion } from './types';
import { PropertyValue } from 'aqTypes';
import { ConcatDetailsSection } from './ConcatDetailsSection';

export type BaseTransformationEditorProps<T> = {
  readonly type: FieldType;
  readonly value: T;
  readonly onValueChange: (value: T) => void;
  readonly reactiveFieldOptions?: SelectOption<AutocompleteFieldSuggestion>[];
  readonly options?: SelectOption<PropertyValue>[];
};

export type EditorSectionProps<T = LocallyDerivedTransformation> =
  BaseTransformationEditorProps<T>;

/**
 * We only support editing a single transformation for now
 */
export type TransformationsEditorProps = BaseTransformationEditorProps<
  LocallyDerivedTransformation[]
>;

export const TransformationsEditor = ({
  type,
  value,
  onValueChange,
  reactiveFieldOptions,
  options,
}: TransformationsEditorProps) => {
  return (
    <TransformationEditor
      type={type}
      value={value?.[0]}
      onValueChange={nextValue => onValueChange(nextValue ? [nextValue] : [])}
      reactiveFieldOptions={reactiveFieldOptions}
      options={options}
    />
  );
};

export type TransformationEditorProps = BaseTransformationEditorProps<
  LocallyDerivedTransformation | undefined
>;

export const TransformationEditor = ({
  type,
  value,
  onValueChange,
  reactiveFieldOptions,
  options,
}: TransformationEditorProps) => {
  return (
    <Stack gap="medium">
      <OperationSection
        type={type}
        value={value}
        onValueChange={onValueChange}
      />

      {value && (
        <DetailsSection
          type={type}
          value={value}
          onValueChange={onValueChange}
          reactiveFieldOptions={reactiveFieldOptions}
          options={options}
        />
      )}
    </Stack>
  );
};

export const DetailsSection = ({
  type,
  value,
  onValueChange,
  reactiveFieldOptions,
  options,
}: EditorSectionProps): JSX.Element => {
  switch (value.operation) {
    case LocallyDerivedTransformationOperation.MATH:
      return (
        <MathDetailsSection
          type={type}
          value={value}
          onValueChange={onValueChange}
          reactiveFieldOptions={reactiveFieldOptions}
        />
      );
    case LocallyDerivedTransformationOperation.DICTIONARY_LOOKUP:
      return (
        <DictionaryLookupDetailsSection
          type={type}
          value={value}
          onValueChange={onValueChange}
          reactiveFieldOptions={reactiveFieldOptions}
          options={options}
        />
      );
    case LocallyDerivedTransformationOperation.CONCAT:
      return (
        <ConcatDetailsSection
          type={type}
          value={value}
          onValueChange={onValueChange}
          reactiveFieldOptions={reactiveFieldOptions}
        />
      );
  }
};
