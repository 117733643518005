const defaultBaseFieldAttributeNames = [
  'type',
  'populateMethod',
  'defaultValue',
  'numberFormatOptions',
  'calculatedFieldSettings',
  'description',
];

export const defaultFieldEditAttributeNames = [
  'name',
  ...defaultBaseFieldAttributeNames,
];

export const defaultFieldCreateAttributeNames = [
  'label',
  ...defaultBaseFieldAttributeNames,
];

export const customFieldAttributeNames = ['componentType', 'referenceType'];

export const legacyCustomFieldAttributeNames = [
  'componentType',
  'global',
  'globalref',
  'referenceType',
];

export const readonlyDefaultFieldAttributeNames = new Set(['name', 'type']);

export const CALCULATED_LIST_OPTIONS_MESSAGE = `Please note that the values returned by the Gremlin query have to match the list options below. Other values will be ignored.`;

export const UNSUPPORTED_SOURCE_TYPE_FOR_DICTIONARY_LOOKUP =
  'Only number, text and list fields are supported';

export const NOT_NUMBER_FIELD_DISABLED_MESSAGE = 'Field is not number';
