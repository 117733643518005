import { useState } from 'react';
import { combineLatest } from 'rxjs';
import styled from 'styled-components';
import {
  ModalSize,
  ModalTemplate,
  modal,
  ModalTemplateProps,
  ModalLayout,
} from '@ardoq/modal';
import { ErrorNotification, showToast } from '@ardoq/status-ui';
import { s16, s32 } from '@ardoq/design-tokens';
import {
  ActionCreatorWithoutNamespace,
  connect,
  dispatchAction,
} from '@ardoq/rxbeach';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  saveConfigurationsFailure,
  saveConfigurationsSuccess,
  saveConfiguration,
  createTransferConfig,
  resetTransferConfigMessage,
} from 'integrations/common/streams/transferConfigs/actions';
import { transferConfigs$ } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { ActiveIntegrations } from 'integrations/common/streams/activeIntegrations/types';
import { TransferConfigsState } from 'integrations/common/streams/transferConfigs/types';
import { activeIntegrations$ } from 'integrations/common/streams/activeIntegrations/activeIntegrations$';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { TransferConfig } from '@ardoq/api-types/integrations';
import { showSuccessToast } from 'streams/invitations/utils';

type SaveModalComponentProps = {
  integrationId: IntegrationId;
  activeIntegrations: ActiveIntegrations;
  transferConfigs: TransferConfigsState;
  title: string;
  isNewConfig: boolean;
  config?: TransferConfig;
  saveAction: ActionCreatorWithoutNamespace<{
    integrationId: IntegrationId;
    name: string;
    isNewConfig: boolean;
  }>;
  createConfigAction: ActionCreatorWithoutNamespace<{
    integrationId: IntegrationId;
    config: TransferConfig;
    setAsSelectedTransferConfig?: boolean;
  }>;
  successAction: ActionCreatorWithoutNamespace<{
    integrationId: IntegrationId;
    setAsSelectedTransferConfig?: boolean;
  }>;
  failureAction: ActionCreatorWithoutNamespace<string>;
  onSubmitCompleted: VoidFunction;
  onCancel: VoidFunction;
  modalTemplateOverrides?: Partial<ModalTemplateProps>;
};

const ErrorNotificationContainer = styled.div`
  margin: ${s16} ${s32} ${s32} ${s32};
`;

const SaveModalComponent = ({
  integrationId,
  activeIntegrations,
  transferConfigs: { configs, asyncStatus, asyncMessage },
  title,
  isNewConfig,
  config,
  saveAction,
  createConfigAction,
  successAction,
  failureAction,
  onSubmitCompleted,
  onCancel,
  modalTemplateOverrides,
}: SaveModalComponentProps) => {
  const { integrationName, selectedTransferConfigId } =
    activeIntegrations[integrationId];
  const givenConfigName = config
    ? config.name
    : configs.find(({ _id }) => _id === selectedTransferConfigId)?.name || null;

  const initialConfigName = !givenConfigName
    ? integrationName
    : isNewConfig
      ? `${givenConfigName} (copy)`
      : givenConfigName;

  // useState to handle the input field in the modal
  // and the loading state
  const [newConfigName, setNewConfigName] = useState(initialConfigName);
  const [saving, setSaving] = useState(false);

  const handleOnSubmit = () => {
    setSaving(true);
    dispatchActionAndWaitForResponse(
      config
        ? createConfigAction({
            integrationId,
            config: { ...config, name: newConfigName },
            setAsSelectedTransferConfig: false,
          })
        : saveAction({ integrationId, name: newConfigName, isNewConfig }),
      successAction,
      failureAction
    ).then(resultAction => {
      setSaving(false);

      if (resultAction.type === successAction.type) {
        onSubmitCompleted();
        showSuccessToast('Configuration saved');
      } else {
        showToast('Failed to save configuration');
      }
    });
  };

  return (
    <ModalTemplate
      headerText={title}
      inputLabel="Give your configuration an identifiable name"
      inputValue={newConfigName}
      onInputValueChange={setNewConfigName}
      modalSize={ModalSize.S}
      isInProgress={saving}
      isPrimaryButtonDisabled={!newConfigName.trim().length}
      primaryButtonText="Save"
      onPrimaryButtonClick={handleOnSubmit}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onCancel}
      {...modalTemplateOverrides}
    >
      <ModalLayout>
        {asyncStatus === 'FAILURE' && asyncMessage && (
          <ErrorNotificationContainer>
            <ErrorNotification>{asyncMessage}</ErrorNotification>
          </ErrorNotificationContainer>
        )}
      </ModalLayout>
    </ModalTemplate>
  );
};

const viewModel$ = combineLatest({
  activeIntegrations: activeIntegrations$,
  transferConfigs: transferConfigs$,
});

const SaveModal = connect(SaveModalComponent, viewModel$);

export const startSaveModal = ({
  title,
  isNewConfig,
  modalTemplateOverrides,
  config,
  onDone = () => null,
  integrationId,
}: Pick<
  SaveModalComponentProps,
  | 'integrationId'
  | 'title'
  | 'isNewConfig'
  | 'modalTemplateOverrides'
  | 'config'
> & { onDone?: VoidFunction }) => {
  dispatchAction(resetTransferConfigMessage());
  return modal<boolean>(
    resolve => (
      <SaveModal
        integrationId={integrationId}
        title={title}
        isNewConfig={isNewConfig}
        config={config}
        saveAction={saveConfiguration}
        createConfigAction={createTransferConfig}
        successAction={saveConfigurationsSuccess}
        failureAction={saveConfigurationsFailure}
        onSubmitCompleted={() => {
          onDone();
          resolve(true);
        }}
        onCancel={() => {
          onDone();
          resolve(false);
        }}
        modalTemplateOverrides={modalTemplateOverrides}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
