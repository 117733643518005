import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { Column } from '@ardoq/table';
import { Link } from '@ardoq/typography';
import {
  Connection,
  ServiceNowConnection,
} from '@ardoq/api-types/integrations';

export const getIntegrationExtraColumns = (
  integrationId: IntegrationId
): Column<Connection>[] => {
  if (integrationId === 'servicenow-v3') {
    return [
      {
        dataIndex: 'baseUrl',
        title: 'Base URL',
        valueRender: (_, row) => {
          const connection = row as ServiceNowConnection;
          return (
            <Link
              target="_blank"
              href={connection.baseUrl}
              underlineOnHover={true}
              type="secondary"
            >
              {connection.baseUrl}
            </Link>
          );
        },
        headerStyle: { width: '35%' },
      },
    ];
  }

  return [];
};
