import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { getDefaultStylePropertyValues } from 'appModelStateEdit/propertiesEditor/componentStylePropertiesEditor/utils';

const getDefaultState = () => {
  return {
    icon: '',
    image: '',
    shape: '',
    color: '',
  };
};

const toComponentStylePreviewState = ({
  entityIDs,
  enhancedScopeData,
}: AppModelStateEditStreamShape) => {
  if (!enhancedScopeData) {
    return getDefaultState();
  }
  const componentId = entityIDs[0];
  const component = enhancedScopeData.componentsById[componentId];
  if (!component) {
    return getDefaultState();
  }
  const defaultStylePropertyValues = getDefaultStylePropertyValues(
    componentId,
    enhancedScopeData
  );
  return {
    icon: component.icon ?? defaultStylePropertyValues.icon ?? '',
    image: component.image ?? defaultStylePropertyValues.image ?? '',
    shape: component.shape ?? defaultStylePropertyValues.shape ?? '',
    color: component.color ?? defaultStylePropertyValues.color ?? '',
  };
};

const componentStylePreview$ = derivedStream(
  'componentStylePreview$',
  appModelStateEdit$
).pipe(
  map(([state]) => state),
  map(toComponentStylePreviewState)
);

export default componentStylePreview$;
