import { connect } from '@ardoq/rxbeach';
import {
  ComponentStylePreview as ComponentStylePreviewSVG,
  ComponentStylePreviewArgs,
} from '@ardoq/metamodel-editor';
import componentStylePreview$ from 'appModelStateEdit/propertiesEditor/componentStylePreview/componentStylePreview$';

const ComponentStylePreview = ({
  icon,
  image,
  shape,
  color,
}: ComponentStylePreviewArgs) => {
  return (
    <ComponentStylePreviewSVG
      icon={icon}
      image={image}
      shape={shape}
      color={color}
    />
  );
};

export default connect(ComponentStylePreview, componentStylePreview$);
