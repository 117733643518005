import SyncManager from 'sync/syncManager';
import { delay } from 'lodash';
import { SaveAllChangedModels } from 'aqTypes';
import { isInScopeDiffMode } from 'scope/scopeDiff';

interface SaveManagedBackboneModel extends Backbone.Model {
  mustBeSaved: boolean;
}

interface SaveManagedBackboneCollection
  extends Backbone.Collection<SaveManagedBackboneModel> {
  saveAllChangedModels: SaveAllChangedModels;
}

const collectionMustBeSaved = (col: SaveManagedBackboneCollection) => {
  if (!col) {
    return false;
  }
  return col.some(model => model.mustBeSaved);
};

/**
 * Tracks Backbone collections for unsaved changes and saves the changes every
 * 5 seconds.
 *
 * A collection is considered to have unsaved changes if any `model.mustBeSaved === true`,
 * which means that:
 * - creating a local component does NOT trigger save
 * - adding a local component to a collection will trigger save
 * - changing a component that exists inside the collection will trigger save
 */
class SaveManager {
  collections: SaveManagedBackboneCollection[];
  constructor(collections: SaveManagedBackboneCollection[] = []) {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (this.unsavedChangesFound()) {
        const msg = 'You have unsaved changes! Do you wish to leave?';
        const err = e || window.event;
        // For IE < 8 and Firefox prior to version 4
        if (err) {
          err.returnValue = msg;
        }
        // For Chrome, Safari, IE8+ and Opera 12+
        return msg;
      }
    };
    this.collections = collections;
    this.continuousSave();
  }
  private continuousSave() {
    delay(() => {
      if (!SyncManager.hasRequestsInProgress()) {
        this.saveAllChanges();
      }
      this.continuousSave();
    }, 5 * 1000);
  }
  unsavedChangesFound() {
    return this.collections.some(col => collectionMustBeSaved(col));
  }
  saveAllChanges() {
    if (this.unsavedChangesFound() && !isInScopeDiffMode()) {
      this.collections.forEach(col => col.saveAllChangedModels(true));
    }
  }
}

export default SaveManager;
