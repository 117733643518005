import { ArdoqId } from '@ardoq/api-types';

export type APIErrorWithTraceId = {
  traceId: string;
  message: string;
};

export enum CalculatedFieldEventStatus {
  CALCULATING = 'CALCULATING',
  DONE = 'DONE',
  DONE_NOTIFIED = 'DONE_NOTIFIED',
}

export type CalculatedFieldEvent = {
  userId?: ArdoqId;
  fieldId: ArdoqId;
  status: CalculatedFieldEventStatus | APIErrorWithTraceId;
};

type CalculatedFieldEventWithError = CalculatedFieldEvent & {
  status: APIErrorWithTraceId;
};

export type CalculatedFieldEventMap = Record<ArdoqId, CalculatedFieldEvent>;
export type CalculatedFieldEventWithErrorMap = Record<
  ArdoqId,
  CalculatedFieldEventWithError
>;
