import styled from 'styled-components';
import {
  formatDateOnly,
  formatDateNew,
  formatDateTime,
  parseDate,
} from '@ardoq/date-time';
import { DecoratorPlaceholder, DecoratorWrapper } from '@ardoq/decorators';
import { Icon, IconName } from '@ardoq/icons';
import { getCurrentLocale } from '@ardoq/locale';

type IntegrationDateProps = {
  date?: string | null;
  placeholder?: string;
  showIcon?: boolean;
  format?: 'DATE' | 'DATETIME' | 'DATENEW';
};

const DateContainer = styled(DecoratorWrapper)`
  white-space: nowrap;
`;

export const IntegrationDate = ({
  date,
  showIcon = false,
  placeholder = 'Not yet started',
  format = 'DATETIME',
}: IntegrationDateProps) => {
  const locale = getCurrentLocale();

  return date ? (
    <DateContainer data-tooltip-text={parseDate(date).toString()}>
      {showIcon && <Icon iconName={IconName.CALENDAR} />}
      {format === 'DATE'
        ? formatDateOnly(date, locale)
        : format === 'DATETIME'
          ? formatDateTime(date, locale)
          : formatDateNew(date, locale)}
    </DateContainer>
  ) : (
    <DecoratorPlaceholder>{placeholder}</DecoratorPlaceholder>
  );
};
