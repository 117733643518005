import {
  fieldTypesWithCalculatedTransformation,
  fieldTypesWithLocallyDerivedTransformation,
  FieldPopulateMethod,
} from 'aqTypes';
import {
  APIFieldAttributes,
  APIFieldType,
  APIGlobalField,
  LocallyDerivedTransformation,
  LocallyDerivedTransformationOperation,
} from '@ardoq/api-types';
import { dateRangeOperations } from '@ardoq/date-range';
import { GlobalFieldAttributes } from 'streams/fields/globalFields$';
import { Features, hasFeature } from '@ardoq/features';
import { SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID } from 'appModelStateEdit/consts';

/* ## Module design
The idea behind this "operation" module is to have a set of pure functions
to delegate the responsibility of the field data structure so:
 - other modules can work at a higher abstraction level
 - clarify where to search for and add new operations

To illustrate what belongs inside this file, imagine we change:
 - the data structure of field from an object to a Map, or
 - rename a field like name or componentType
How many files do we have to change? If the design is implemented to its
limit the answer should be 1. This means any access on the field data
structure happens through this module, eg. `fieldOps.getName(field)`
instead of `field.name`

This module *only* knows about the field type, which means avoid using other
domain objects, create a dedicated module instead. */

const getAvailableFieldTypes = (): APIFieldType[] => {
  // Note that the order is significant, it corresponds to the order field types
  // appear in the dropdown.
  let fieldTypes = [
    APIFieldType.TEXT,
    APIFieldType.TEXT_AREA,
    APIFieldType.DATE_ONLY,
    APIFieldType.DATE_TIME,
    APIFieldType.CHECKBOX,
    APIFieldType.NUMBER,
    APIFieldType.USER,
    APIFieldType.EMAIL,
    APIFieldType.URL,
    APIFieldType.FILE,
    APIFieldType.LIST,
    APIFieldType.SELECT_MULTIPLE_LIST,
    APIFieldType.DATE_ONLY_RANGE,
    APIFieldType.DATE_TIME_RANGE,
  ];

  if (hasFeature(Features.DISABLE_DATE_TIME_RANGE)) {
    fieldTypes = fieldTypes.filter(
      fieldType => fieldType !== APIFieldType.DATE_TIME_RANGE
    );
  }

  return fieldTypes;
};

const isCalculatedField = (
  field: APIGlobalField | APIFieldAttributes
): boolean => field.calculatedFieldSettings?.storedQueryId !== undefined;

const isCalculatedFieldWithoutQuery = (
  field: APIGlobalField | APIFieldAttributes
): boolean =>
  field.calculatedFieldSettings?.storedQueryId ===
  SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID;

const isLocallyDerivedField = (
  field: APIGlobalField | APIFieldAttributes
): boolean => field.calculatedFieldSettings?.locallyDerived !== undefined;

const toSetOfCalculatedFieldNames = (
  fields: Array<APIGlobalField | APIFieldAttributes>
) => {
  const calculatedFieldNames = fields
    .filter(isCalculatedField)
    .map(field => field.name);

  return new Set(calculatedFieldNames);
};

/**
 * Field type mapping
 * Internal data structure, do not export!
 */
const fieldTypeLabels: Record<APIFieldType, string> = {
  [APIFieldType.TEXT]: 'Text',
  [APIFieldType.TEXT_AREA]: 'Text paragraph',
  [APIFieldType.DATE_ONLY]: 'Date',
  [APIFieldType.DATE_TIME]: 'Date time',
  [APIFieldType.CHECKBOX]: 'Checkbox',
  [APIFieldType.NUMBER]: 'Number',
  [APIFieldType.USER]: 'User',
  [APIFieldType.EMAIL]: 'Email',
  [APIFieldType.URL]: 'URL',
  [APIFieldType.LIST]: 'List',
  [APIFieldType.SELECT_MULTIPLE_LIST]: 'Select multiple list',
  [APIFieldType.DATE_ONLY_RANGE]: 'Date range',
  [APIFieldType.DATE_TIME_RANGE]: 'Date time range',
  [APIFieldType.FILE]: 'File',
};

const getFieldTypeLabel = (fieldType: APIFieldType) => {
  return fieldTypeLabels[fieldType];
};

const getAvailablePopulateMethods = (
  fieldType: APIFieldType,
  canCreateGraphFilter: boolean
): FieldPopulateMethod[] => {
  const methods = [FieldPopulateMethod.MANUAL];
  if (
    canCreateGraphFilter &&
    fieldTypesWithCalculatedTransformation.has(fieldType)
  ) {
    methods.push(FieldPopulateMethod.CALCULATED);
  }
  if (
    canCreateGraphFilter &&
    fieldTypesWithLocallyDerivedTransformation.has(fieldType)
  ) {
    methods.push(FieldPopulateMethod.LOCALLY_DERIVED);
  }

  return methods;
};
const getPopulateMethodFromField = (
  field: APIGlobalField | APIFieldAttributes
): FieldPopulateMethod => {
  if (isCalculatedField(field)) {
    return FieldPopulateMethod.CALCULATED;
  } else if (isLocallyDerivedField(field)) {
    return FieldPopulateMethod.LOCALLY_DERIVED;
  }
  return FieldPopulateMethod.MANUAL;
};

const fieldPopulateMethodLabels: Record<FieldPopulateMethod, string> = {
  [FieldPopulateMethod.MANUAL]: 'Manual',
  [FieldPopulateMethod.CALCULATED]: 'Calculated',
  [FieldPopulateMethod.LOCALLY_DERIVED]: 'Reactive',
};

const getFieldPopulateMethodLabel = (populateMethod: FieldPopulateMethod) => {
  return fieldPopulateMethodLabels[populateMethod];
};

const getFieldTypeLabelWithPopulateMethod = (
  field: APIGlobalField | APIFieldAttributes
) => {
  const isTransformedField =
    isCalculatedField(field) || isLocallyDerivedField(field);
  return isTransformedField
    ? `${fieldOps.getFieldTypeLabel(field.type)} (${fieldOps.getFieldPopulateMethodLabel(fieldOps.getPopulateMethodFromField(field))})`
    : fieldOps.getFieldTypeLabel(field.type);
};

const getLocallyDerivedTransformations = (
  field: APIGlobalField | APIFieldAttributes
): LocallyDerivedTransformation[] =>
  field.calculatedFieldSettings?.locallyDerived ?? [];

const getLocallyDerivedOperation = (
  field: APIGlobalField | APIFieldAttributes
): LocallyDerivedTransformationOperation | undefined =>
  getLocallyDerivedTransformations(field)[0]?.operation;

/**
 * Use to sort fields by their labels alphabetical order
 */
const compareFieldsByLabelAsc = (
  a: APIGlobalField | APIFieldAttributes | GlobalFieldAttributes,
  b: APIGlobalField | APIFieldAttributes | GlobalFieldAttributes
) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

/**
 * The field's label + a type annotation
 */
const getLabelWithTypeAnnotation = (
  field: APIGlobalField | APIFieldAttributes
) => {
  const fieldType = getFieldTypeLabel(field.type);
  return `${field.label} (${fieldType})`;
};

const getTooltip = (field: APIGlobalField | APIFieldAttributes): string => {
  const transformation: LocallyDerivedTransformation | undefined =
    getLocallyDerivedTransformations(field)[0];

  if (
    transformation?.operation === LocallyDerivedTransformationOperation.MATH
  ) {
    return `Calculated as ${transformation.config.expression.replaceAll('@', '')}`;
  }

  if (
    transformation?.operation ===
    LocallyDerivedTransformationOperation.DICTIONARY_LOOKUP
  ) {
    return `Calculated from ${transformation.config.additionalFields[0]}`;
  }

  if (
    transformation?.operation === LocallyDerivedTransformationOperation.CONCAT
  ) {
    return `Calculated from ${transformation.config.additionalFields.join(', ')}`;
  }

  return '';
};

const compareFieldsByOrderAsc = (
  fieldA: APIFieldAttributes,
  fieldB: APIFieldAttributes
) => {
  if (dateRangeOperations.areMatchingDateTimeFields(fieldA, fieldB)) {
    return dateRangeOperations.compareDateRangeFieldLabels(
      fieldA.label,
      fieldB.label
    );
  }
  return fieldA._order - fieldB._order;
};

// Should be the only export aside from types
export const fieldOps = {
  compareFieldsByLabelAsc,
  compareFieldsByOrderAsc,
  getAvailableFieldTypes,
  getFieldPopulateMethodLabel,
  getFieldTypeLabel,
  getFieldTypeLabelWithPopulateMethod,
  getLabelWithTypeAnnotation,
  getLocallyDerivedOperation,
  getLocallyDerivedTransformations,
  getTooltip,
  toSetOfCalculatedFieldNames,
  isCalculatedField,
  isLocallyDerivedField,
  isCalculatedFieldWithoutQuery,
  getAvailablePopulateMethods,
  getPopulateMethodFromField,
};
