import { PermissionGroup$State } from './types';
import type { ArdoqId, PermissionGroup } from '@ardoq/api-types';

const handleSetPermissionGroups = (
  state: PermissionGroup$State,
  permissionGroups: PermissionGroup[]
): PermissionGroup$State => ({
  ...state,
  groupsById: permissionGroups.reduce(
    (acc, permissionGroup) => ({
      ...acc,
      [permissionGroup._id]: permissionGroup,
    }),
    {}
  ),
});

const handleSetError = (
  state: PermissionGroup$State,
  error: string
): PermissionGroup$State => ({
  ...state,
  error,
});

const getUserGroups = (
  state: Pick<PermissionGroup$State, 'groupsById'>,
  userId: ArdoqId
): PermissionGroup[] => {
  return Object.values(state.groupsById).filter(group =>
    group.users.includes(userId)
  );
};

const filterGroupsByQuery = <Data extends { name: string }>(
  groups: Data[],
  query: string
): Data[] => {
  if (!query) {
    return groups;
  }
  return groups.filter(group =>
    group.name.toLowerCase().includes(query.toLowerCase())
  );
};

const setSearchPhrase = (
  state: PermissionGroup$State,
  { query }: { query: string }
) => ({
  ...state,
  searchPhrase: query,
});

const someGroupsHasSSOAttributeMapping = (
  groups: Pick<PermissionGroup, 'ssoMappingRuleCount'>[]
) => groups.some(group => group.ssoMappingRuleCount > 0);

export const permissionGroupOperations = {
  handleSetPermissionGroups,
  handleSetError,
  getUserGroups,
  filterGroupsByQuery,
  setSearchPhrase,
  someGroupsHasSSOAttributeMapping,
};
