import { PropertyValue } from 'aqTypes';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';
import { toModelTypeDictionary, toModelTypes } from '@ardoq/renderers';
import { updateEntityProperties } from 'scopeData/scopeEditUtils/updateEntityProperties';
import { uniqBy } from 'lodash';
import type { EnhancedScopeData } from '@ardoq/data-model';

export const updateScopeDataProperties = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  propertiesToUpdate: Record<string, PropertyValue>,
  model?: ArdoqId
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.WORKSPACE:
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE:
    case APIEntityType.FIELD:
    case APIEntityType.REFERENCE_TYPE: {
      return updateEntityProperties(
        entityType,
        entityIDs,
        propertiesToUpdate,
        enhancedScopeData,
        model
      );
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const getScopeDataWithPreservedFieldData = (
  scopeDataWithFormData: EnhancedScopeData,
  scopeDataWithFieldData: EnhancedScopeData
) => {
  const { calculatedFields, dateRangeFieldMap, fieldLabelByFieldName, fields } =
    scopeDataWithFieldData;
  const newFields = uniqBy(
    [...scopeDataWithFormData.fields, ...fields],
    'name'
  );
  return {
    ...scopeDataWithFormData,
    calculatedFields: new Set([
      ...scopeDataWithFormData.calculatedFields,
      ...calculatedFields,
    ]),
    dateRangeFieldMap: new Map([
      ...scopeDataWithFormData.dateRangeFieldMap!,
      ...dateRangeFieldMap!,
    ]),
    fieldLabelByFieldName: {
      ...scopeDataWithFormData.fieldLabelByFieldName,
      ...fieldLabelByFieldName,
    },
    fields: newFields,
    fieldsById: toByIdDictionary(newFields),
    fieldsByModelIdAndTypeId: toModelTypeDictionary(
      newFields,
      toModelTypes(scopeDataWithFormData.models)
    ),
  };
};
