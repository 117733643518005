import { Maybe } from '@ardoq/common-helpers';
import { actionCreator } from '@ardoq/rxbeach';
import type { TablePreview } from '../tablePreviews/types';
import { SavedTransferConfig } from '@ardoq/api-types/integrations';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { MapTablesBy } from './types';

const NAMESPACE = '[integrations] ACTIVE_INTEGRATION';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type SetCurrentTableIdPayload = {
  integrationId: IntegrationId;
  id: TablePreview['id'];
};
export const setCurrentTableId = actionCreator<SetCurrentTableIdPayload>(
  getActionName('SET_CURRENT_TABLE_ID')
);

export type SetSourceIdPayload = {
  integrationId: IntegrationId;
  id: string;
};

export const setSourceId = actionCreator<SetSourceIdPayload>(
  getActionName('SET_SOURCE_ID')
);

export type SetConfigPayload = {
  integrationId: IntegrationId;
  id: Maybe<SavedTransferConfig['_id']>;
};
export const setTransferConfigId = actionCreator<SetConfigPayload>(
  getActionName('SET_TRANSFER_CONFIG_ID')
);

export const setMappingConfigId = actionCreator<SetConfigPayload>(
  getActionName('SET_MAPPING_CONFIG_ID')
);

export type SetIntegrationNamePayload = {
  integrationId: IntegrationId;
  name: string;
};
export const setIntegrationName = actionCreator<SetIntegrationNamePayload>(
  getActionName('SET_INTEGRATION_NAME')
);

export const resetIntegration = actionCreator<IntegrationId>(
  getActionName('RESET_INTEGRATION')
);

export type SetIntegrationPathPayload = {
  integrationId: IntegrationId;
  mapTablesBy: MapTablesBy;
};

export const setMapTablesBy = actionCreator<SetIntegrationPathPayload>(
  getActionName('MAP_TABLES_BY')
);

export type SetTrackingFunnelIdPayload = {
  integrationId: IntegrationId;
  trackingFunnelId: string;
};

export const setTrackingFunnelId = actionCreator<SetTrackingFunnelIdPayload>(
  getActionName('SET_TRACKING_FUNNEL_ID')
);
