import { UNIFIED_INTEGRATION_IDS } from 'integrations/unified/constants';

export const AZURE_INTEGRATION_ID = 'azure-v3';
export const AWS_INTEGRATION_ID = 'aws-v3';
export const EXCEL_INTEGRATION_ID = 'excel-v3';
export const SERVICENOW_INTEGRATION_ID = 'servicenow-v3';
export const MICROSOFT_ENTRA_ID_INTEGRATION_ID = 'microsoft-entra-id';
export const SIGNAVIO_EXPORTER_INTEGRATION_ID = 'signavio-exporter';
export const IT_PEDIA_INTEGRATION_ID = 'it-pedia';

export const INTEGRATION_IDS = [
  AWS_INTEGRATION_ID,
  AZURE_INTEGRATION_ID,
  EXCEL_INTEGRATION_ID,
  SERVICENOW_INTEGRATION_ID,
  MICROSOFT_ENTRA_ID_INTEGRATION_ID,
  SIGNAVIO_EXPORTER_INTEGRATION_ID,
  IT_PEDIA_INTEGRATION_ID,
  ...UNIFIED_INTEGRATION_IDS,
] as const;
