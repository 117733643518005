import {
  ConcatTransformation,
  DictionaryLookupTransformation,
  LocallyDerivedTransformation,
  LocallyDerivedTransformationOperation,
  MathTransformation,
} from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';

export type Preset<
  T extends LocallyDerivedTransformation = LocallyDerivedTransformation,
> = {
  name: string;
  description: string;
  icon: IconName;
  transformation: T;
};

export const mathPreset: Preset<MathTransformation> = {
  name: 'Math expression',
  description: 'Mathematical expression using existing fields',
  icon: IconName.LANGUAGE,
  transformation: {
    operation: LocallyDerivedTransformationOperation.MATH,
    config: {
      additionalFields: [],
      expression: '',
    },
  },
};

export const concatPreset: Preset<ConcatTransformation> = {
  name: 'Concatenate fields',
  description: 'Concatenate multiple fields',
  icon: IconName.LIST,
  transformation: {
    operation: LocallyDerivedTransformationOperation.CONCAT,
    config: {
      additionalFields: [],
      joinStr: ', ',
    },
  },
};

export const dictionaryLookupPreset: Preset<DictionaryLookupTransformation> = {
  name: 'Dictionary lookup',
  description: 'Lookup a value in a dictionary',
  icon: IconName.MESSAGE_FORMAT,
  transformation: {
    operation: LocallyDerivedTransformationOperation.DICTIONARY_LOOKUP,
    config: {
      additionalFields: [],
      dictionary: [],
    },
  },
};

export const transformationPresets = [
  dictionaryLookupPreset,
  mathPreset,
  concatPreset,
];
