import { IconName } from '@ardoq/icons';
import { pluralize } from '@ardoq/common-helpers';
import { SummaryIcon, SummaryList, SummaryTitle } from './atoms';

type SummaryBlockProps = {
  referencesCount: number;
  componentsCount: number;
};

const SummaryBlock = ({
  referencesCount,
  componentsCount,
}: SummaryBlockProps) => {
  return (
    <>
      <SummaryTitle>These items will be added to the scenario</SummaryTitle>
      <SummaryList>
        <li>
          <SummaryIcon iconName={IconName.REFERENCE} />
          {referencesCount} {pluralize('reference', referencesCount)}
        </li>
        <li>
          <SummaryIcon iconName={'category' as IconName} />
          {componentsCount} {pluralize('component', componentsCount)}
        </li>
      </SummaryList>
    </>
  );
};

export default SummaryBlock;
