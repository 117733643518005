import { Subdivision } from '@ardoq/api-types';
import { IconName } from '@ardoq/icons';
import { SelectOption } from '@ardoq/select';
import { subdivisionObjectOperations } from '@ardoq/subdivisions';

/**
 * Converts a Subdivision to a SelectOption using the id as the value and the name as the label.
 */
const convertToSelectOption = (
  subdivision: Subdivision
): SelectOption<string> => ({
  label: subdivisionObjectOperations.getName(subdivision),
  value: subdivisionObjectOperations.getId(subdivision),
  leftIcon: {
    name: IconName.CIRCLE,
    color: subdivisionObjectOperations.getColor(subdivision),
  },
});

export const subdivisionOperations = {
  convertToSelectOption,
};
