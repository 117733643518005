import filterConditions$ from './broadcastBuilder/broadcastContentForm/filterConditions$';
import { reducer, RegisteredReducer, streamReducer } from '@ardoq/rxbeach';
import {
  createNewBroadcast,
  createNewInterval,
  createStartDate,
  findBroadcast,
  getNextMessageOnContentTypeChange,
  isPersistedBroadcast,
  isRepeatingSchedule,
  withAddedOrReplacedAudience,
  withAddedOrUpdatedBroadcast,
  withoutAudienceOfType,
  withoutBroadcast,
} from 'broadcasts/utils';
import {
  navigateToEditBroadcastForm,
  navigateToNewBroadcastForm,
} from 'router/navigationActions';
import {
  APIBroadcastAttributes,
  APIDiscoverViewpointAttributes,
  ArdoqId,
  AudienceStartingPage,
  BroadcastAudienceType,
  BroadcastContentType,
  IntervalType,
  PredefinedQueryOption,
  WorkspaceAudienceData,
  BroadcastPreview,
} from '@ardoq/api-types';
import {
  BroadcastFetchStatus,
  BroadcastPreviewFetchStatus,
  BroadcastSaveStatus,
  BroadcastStreamShape,
} from 'broadcasts/types';
import {
  addOrUpdateEmailBroadcastAudience,
  addOrUpdateGremlinBroadcastAudience,
  addOrUpdatePredefinedBroadcastAudience,
  addOrUpdateWorkspaceBroadcastAudience,
  chooseBroadcastContentComponentType,
  chooseBroadcastContentId,
  chooseBroadcastContentType,
  chooseBroadcastContentWorkspace,
  chooseBroadcastReminder,
  chooseIntervalType,
  chooseIntervalValue,
  chooseScheduleEndDate,
  chooseScheduleStartDate,
  fetchBroadcastsError,
  fetchViewpoints,
  notifyDeletingBroadcastSucceeded,
  notifyFetchingBroadcasts,
  notifyIsSavingBroadcast,
  notifySavingBroadcastFailed,
  notifySavingBroadcastSucceeded,
  notifyUpdatingBroadcastSucceeded,
  removeAudienceFromBroadcast,
  setAudienceStartingPage,
  setAudienceStartingPageViewpoint,
  setBroadcastList,
  setBroadcastPreview,
  setBroadcastPreviewStatus,
  setCurrentBroadcast,
  updateBroadcastName,
  updateHideLogo,
  updateMessageBody,
  updateMessageReplyTo,
  updateMessageSender,
  updateMessageSubject,
  updateScheduleRecurrence,
  updateSendOnWeekends,
  updateUseCompanyLogo,
  updateViewpoints,
} from 'broadcasts/actions';
import { notifyCopyingBroadcastSucceeded } from './broadcastOverview/actions';
import { toggleEndDateEnabled } from './broadcastBuilder/broadcastSchedulerAndReminderForm/endDate/actions';
import reportRulesSection$ from './broadcastBuilder/broadcastContentForm/reportRulesSection/reportRulesSection$';
import { ExtractStreamShape } from 'tabview/types';
import { ReportRulesSection$State } from './broadcastBuilder/broadcastContentForm/reportRulesSection/types';
import { setStateProperty, toByIdDictionary } from '@ardoq/common-helpers';
import currentOrganization$ from 'streams/organizations/currentOrganization$';

export const defaultState: BroadcastStreamShape = {
  broadcastsById: {},
  broadcasts: [],
  fetchStatus: BroadcastFetchStatus.IDLE,
  saveStatus: BroadcastSaveStatus.IDLE,
  currentBroadcast: null,
  richTextEditorResetKey: 0,
  organization: null,
  viewpoints: null,
  isLoadingViewpoints: false,
  broadcastPreview: null,
  broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus.IDLE,
};

const handleNavigateToNewBroadcastForm = (
  state: BroadcastStreamShape,
  folderId: ArdoqId | null
): BroadcastStreamShape => ({
  ...state,
  currentBroadcast: createNewBroadcast(folderId),
  richTextEditorResetKey: state.richTextEditorResetKey === 0 ? 1 : 0,
});

const handleNavigateToEditBroadcastForm = (
  state: BroadcastStreamShape,
  broadcastId: ArdoqId
): BroadcastStreamShape => ({
  ...state,
  saveStatus: BroadcastSaveStatus.IDLE,
  currentBroadcast:
    state.broadcasts.find(broadcast => broadcast._id === broadcastId) ?? null,
});

const handleChooseBroadcastContentType = (
  state: BroadcastStreamShape,
  nextContentType: BroadcastContentType
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  const currentContentType = state.currentBroadcast.content.contentType;
  const currentMessage = state.currentBroadcast.message;

  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audienceStartingPage:
        nextContentType === BroadcastContentType.MESSAGE
          ? AudienceStartingPage.PLAIN
          : nextContentType === BroadcastContentType.SURVEY
            ? AudienceStartingPage.SURVEY_OVERVIEW
            : AudienceStartingPage.REPORT_DISCOVER,
      content: {
        ...state.currentBroadcast.content,
        contentType: nextContentType,
        contentId: null,
        workspaceId: null,
        componentType: null,
        columnName: null,
        aggregate: null,
        threshold: null,
        predicate: null,
        filterConditions: [],
      },
      message: getNextMessageOnContentTypeChange(
        currentContentType,
        nextContentType,
        currentMessage
      ),
    },
    richTextEditorResetKey: state.richTextEditorResetKey === 0 ? 1 : 0,
  };
};

const handleChooseBroadcastContentWorkspace = (
  state: BroadcastStreamShape,
  workspaceId: ArdoqId
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (
    state.currentBroadcast.content.contentType !== BroadcastContentType.MESSAGE
  ) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      content: {
        ...state.currentBroadcast.content,
        workspaceId,
        componentType: null,
      },
    },
  };
};

const handleChooseBroadcastContentComponentType = (
  state: BroadcastStreamShape,
  componentType: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (
    state.currentBroadcast.content.contentType !== BroadcastContentType.MESSAGE
  ) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audienceStartingPageViewpoint: undefined,
      content: {
        ...state.currentBroadcast.content,
        componentType,
      },
    },
  };
};

const handleChooseBroadcastContentId = (
  state: BroadcastStreamShape,
  contentId: ArdoqId
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (
    state.currentBroadcast.content.contentType === BroadcastContentType.MESSAGE
  ) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      content: {
        ...state.currentBroadcast.content,
        contentId,
      },
    },
  };
};

const handleFilterConditionsChange = (
  state: BroadcastStreamShape,
  filterConditions: ExtractStreamShape<typeof filterConditions$>
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (
    state.currentBroadcast.content.contentType === BroadcastContentType.REPORT
  ) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      content: {
        ...state.currentBroadcast.content,
        filterConditions,
      },
    },
  };
};

const handleReportRulesChange = (
  state: BroadcastStreamShape,
  reportRules: ReportRulesSection$State
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      content: {
        ...state.currentBroadcast.content,
        ...reportRules,
      },
    },
  };
};

const handleAddOrUpdatePredefinedBroadcastAudience = (
  state: BroadcastStreamShape,
  options: PredefinedQueryOption[]
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audiences: withAddedOrReplacedAudience(state.currentBroadcast.audiences, {
        audienceType: BroadcastAudienceType.PREDEFINED,
        options,
      }),
    },
  };
};

const handleAddOrUpdateGremlinBroadcastAudience = (
  state: BroadcastStreamShape,
  query: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audiences: withAddedOrReplacedAudience(state.currentBroadcast.audiences, {
        audienceType: BroadcastAudienceType.GREMLIN,
        query,
      }),
    },
  };
};

const handleAddOrUpdateEmailBroadcastAudience = (
  state: BroadcastStreamShape,
  emails: string[]
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audiences: withAddedOrReplacedAudience(state.currentBroadcast.audiences, {
        audienceType: BroadcastAudienceType.EMAIL,
        emails,
      }),
    },
  };
};

const handleAddOrUpdateWorkspaceBroadcastAudience = (
  state: BroadcastStreamShape,
  { workspaceId, limitedToPersonIds }: WorkspaceAudienceData
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audiences: withAddedOrReplacedAudience(state.currentBroadcast.audiences, {
        audienceType: BroadcastAudienceType.WORKSPACE,
        workspaceId,
        limitedToPersonIds,
      }),
    },
  };
};

const handleRemoveAudienceFromBroadcast = (
  state: BroadcastStreamShape,
  audienceType: BroadcastAudienceType
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audiences: withoutAudienceOfType(audienceType)(
        state.currentBroadcast.audiences
      ),
    },
  };
};

const handleSetAudienceStartingPage = (
  state: BroadcastStreamShape,
  audienceStartingPage: AudienceStartingPage
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: { ...state.currentBroadcast, audienceStartingPage },
  };
};

const handleSetAudienceStartingPageViewpoint = (
  state: BroadcastStreamShape,
  audienceStartingPageViewpoint: ArdoqId | null
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      audienceStartingPageViewpoint,
    },
  };
};

const handleNotifyFetchingBroadcasts = (
  state: BroadcastStreamShape
): BroadcastStreamShape => ({
  ...state,
  fetchStatus:
    state.fetchStatus === BroadcastFetchStatus.SUCCESS
      ? BroadcastFetchStatus.REVALIDATING
      : BroadcastFetchStatus.LOADING,
});

const handleFetchBroadcastsError = (
  state: BroadcastStreamShape
): BroadcastStreamShape => ({
  ...state,
  fetchStatus: BroadcastFetchStatus.ERROR,
});

const handleSetBroadcastPreview = (
  state: BroadcastStreamShape,
  broadcastPreview: BroadcastPreview | null
): BroadcastStreamShape => ({
  ...state,
  broadcastPreview,
  broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus.SUCCESS,
});

const handleSetBroadcastPreviewStatus = (
  state: BroadcastStreamShape,
  broadcastPreviewFetchStatus: BroadcastPreviewFetchStatus
): BroadcastStreamShape => ({
  ...state,
  broadcastPreviewFetchStatus,
});

const handleSetBroadcastList = (
  state: BroadcastStreamShape,
  broadcasts: APIBroadcastAttributes[]
): BroadcastStreamShape => ({
  ...state,
  fetchStatus: BroadcastFetchStatus.SUCCESS,
  broadcasts,
  broadcastsById: toByIdDictionary(broadcasts),
});

const handleSetCurrentBroadcast = (
  state: BroadcastStreamShape,
  broadcastId: ArdoqId
): BroadcastStreamShape => ({
  ...state,
  currentBroadcast: findBroadcast(state.broadcasts, broadcastId),
});

const handleNotifyIsSavingBroadcast = (state: BroadcastStreamShape) => ({
  ...state,
  saveStatus: BroadcastSaveStatus.SAVING,
});

const handleNotifySavingBroadcastSucceeded = (
  state: BroadcastStreamShape,
  broadcast: APIBroadcastAttributes
): BroadcastStreamShape => ({
  ...state,
  broadcasts: withAddedOrUpdatedBroadcast(state.broadcasts, broadcast),
  currentBroadcast: broadcast,
  saveStatus: BroadcastSaveStatus.SUCCESS,
});

const handleNotifySavingBroadcastFailed = (
  state: BroadcastStreamShape
): BroadcastStreamShape => ({
  ...state,
  saveStatus: BroadcastSaveStatus.ERROR,
});

const handleNotifyCopyingBroadcastSucceeded = (
  state: BroadcastStreamShape,
  broadcast: APIBroadcastAttributes
): BroadcastStreamShape => {
  return {
    ...state,
    broadcasts: withAddedOrUpdatedBroadcast(state.broadcasts, broadcast),
  };
};

const handleNotifyUpdatingBroadcastSucceeded = (
  state: BroadcastStreamShape,
  broadcast: APIBroadcastAttributes
): BroadcastStreamShape => {
  return {
    ...state,
    broadcasts: withAddedOrUpdatedBroadcast(state.broadcasts, broadcast),
    currentBroadcast:
      isPersistedBroadcast(state.currentBroadcast) &&
      state.currentBroadcast._id === broadcast._id
        ? broadcast
        : state.currentBroadcast,
  };
};

const handleNotifyDeletingBroadcastSucceeded = (
  state: BroadcastStreamShape,
  broadcastId: ArdoqId
): BroadcastStreamShape => {
  return {
    ...state,
    broadcasts: withoutBroadcast(state.broadcasts, broadcastId),
    currentBroadcast:
      isPersistedBroadcast(state.currentBroadcast) &&
      state.currentBroadcast._id === broadcastId
        ? null
        : state.currentBroadcast,
  };
};

const handleUpdateBroadcastName = (
  state: BroadcastStreamShape,
  name: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      name,
    },
  };
};

const handleUpdateMessageSubject = (
  state: BroadcastStreamShape,
  subject: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        subject,
      },
    },
  };
};

const handleUpdateMessageSender = (
  state: BroadcastStreamShape,
  sender: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        sender,
      },
    },
  };
};

const handleUpdateMessageReplyTo = (
  state: BroadcastStreamShape,
  replyToArdoq: boolean
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        replyToArdoq,
      },
    },
  };
};

const handleUpdateMessageBody = (
  state: BroadcastStreamShape,
  body: string
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        body,
      },
    },
  };
};

const handleUpdateScheduleRecurrence = (
  state: BroadcastStreamShape,
  repeating: boolean
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: repeating
        ? {
            repeating,
            startDate:
              state.currentBroadcast.scheduling.startDate ?? createStartDate(),
            reminderInDays: state.currentBroadcast.scheduling.reminderInDays,
            interval: createNewInterval(),
            sendOnWeekends: state.currentBroadcast.scheduling.sendOnWeekends,
          }
        : {
            repeating,
            startDate:
              state.currentBroadcast.scheduling.startDate ?? createStartDate(),
            reminderInDays: state.currentBroadcast.scheduling.reminderInDays,
            sendOnWeekends: state.currentBroadcast.scheduling.sendOnWeekends,
          },
    },
  };
};

const handleChooseBroadcastReminder = (
  state: BroadcastStreamShape,
  reminderInDays: number | null
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        reminderInDays,
      },
    },
  };
};

const handleChooseIntervalType = (
  state: BroadcastStreamShape,
  intervalType: IntervalType
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (!isRepeatingSchedule(state.currentBroadcast.scheduling)) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        interval: {
          ...state.currentBroadcast.scheduling.interval,
          intervalType,
        },
      },
    },
  };
};

const handleChooseIntervalValue = (
  state: BroadcastStreamShape,
  intervalValue: number
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (!isRepeatingSchedule(state.currentBroadcast.scheduling)) {
    return { ...state };
  }
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        interval: {
          ...state.currentBroadcast.scheduling.interval,
          intervalValue,
        },
      },
    },
  };
};

const handleChooseScheduleStartDate = (
  state: BroadcastStreamShape,
  startDate: string | null
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        startDate,
      },
    },
  };
};

const handleChooseScheduleEndDate = (
  state: BroadcastStreamShape,
  endDate: string | null
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  if (!state.currentBroadcast.scheduling.repeating) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        endDate,
      },
    },
  };
};

const handleUpdateUseCompanyLogo = (
  state: BroadcastStreamShape,
  useCompanyLogo: boolean
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        companyLogo: useCompanyLogo,
      },
    },
  };
};

const handleUpdateViewpoints = (
  state: BroadcastStreamShape,
  viewpoints: APIDiscoverViewpointAttributes[]
): BroadcastStreamShape => {
  if (!viewpoints) return { ...state };
  return {
    ...state,
    viewpoints,
    isLoadingViewpoints: false,
  };
};

const handleFetchViewpoints = (
  state: BroadcastStreamShape
): BroadcastStreamShape => {
  return {
    ...state,
    isLoadingViewpoints: true,
  };
};

const handleIsEndDateEnabledChange = (
  state: BroadcastStreamShape
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return state;
  if (!state.currentBroadcast.scheduling.repeating) return { ...state };
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        endDate: null,
      },
    },
  };
};

const handleUpdateSendOnWeekends = (
  state: BroadcastStreamShape,
  sendOnWeekends: boolean
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return state;
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      scheduling: {
        ...state.currentBroadcast.scheduling,
        sendOnWeekends,
      },
    },
  };
};

const handleUpdateHideLogo = (
  state: BroadcastStreamShape,
  hideLogo: boolean
): BroadcastStreamShape => {
  if (!state.currentBroadcast) return state;
  return {
    ...state,
    currentBroadcast: {
      ...state.currentBroadcast,
      message: {
        ...state.currentBroadcast.message,
        hideLogo,
      },
    },
  };
};

export const reducers: RegisteredReducer<BroadcastStreamShape>[] = [
  reducer(navigateToNewBroadcastForm, handleNavigateToNewBroadcastForm),
  reducer(navigateToEditBroadcastForm, handleNavigateToEditBroadcastForm),
  reducer(chooseBroadcastContentType, handleChooseBroadcastContentType),
  reducer(
    chooseBroadcastContentWorkspace,
    handleChooseBroadcastContentWorkspace
  ),
  reducer(
    chooseBroadcastContentComponentType,
    handleChooseBroadcastContentComponentType
  ),
  reducer(chooseBroadcastContentId, handleChooseBroadcastContentId),
  streamReducer(filterConditions$, handleFilterConditionsChange),
  streamReducer(reportRulesSection$, handleReportRulesChange),
  reducer(
    addOrUpdatePredefinedBroadcastAudience,
    handleAddOrUpdatePredefinedBroadcastAudience
  ),
  reducer(
    addOrUpdateGremlinBroadcastAudience,
    handleAddOrUpdateGremlinBroadcastAudience
  ),
  reducer(
    addOrUpdateEmailBroadcastAudience,
    handleAddOrUpdateEmailBroadcastAudience
  ),
  reducer(
    addOrUpdateWorkspaceBroadcastAudience,
    handleAddOrUpdateWorkspaceBroadcastAudience
  ),
  reducer(removeAudienceFromBroadcast, handleRemoveAudienceFromBroadcast),
  reducer(setAudienceStartingPage, handleSetAudienceStartingPage),
  reducer(
    setAudienceStartingPageViewpoint,
    handleSetAudienceStartingPageViewpoint
  ),
  reducer(setBroadcastList, handleSetBroadcastList),
  reducer(setCurrentBroadcast, handleSetCurrentBroadcast),
  reducer(notifyIsSavingBroadcast, handleNotifyIsSavingBroadcast),
  reducer(notifySavingBroadcastSucceeded, handleNotifySavingBroadcastSucceeded),
  reducer(notifySavingBroadcastFailed, handleNotifySavingBroadcastFailed),
  reducer(
    notifyCopyingBroadcastSucceeded,
    handleNotifyCopyingBroadcastSucceeded
  ),
  reducer(
    notifyUpdatingBroadcastSucceeded,
    handleNotifyUpdatingBroadcastSucceeded
  ),
  reducer(
    notifyDeletingBroadcastSucceeded,
    handleNotifyDeletingBroadcastSucceeded
  ),
  reducer(updateBroadcastName, handleUpdateBroadcastName),
  reducer(updateMessageSubject, handleUpdateMessageSubject),
  reducer(updateMessageSender, handleUpdateMessageSender),
  reducer(updateMessageBody, handleUpdateMessageBody),
  reducer(updateScheduleRecurrence, handleUpdateScheduleRecurrence),
  reducer(chooseBroadcastReminder, handleChooseBroadcastReminder),
  reducer(chooseIntervalType, handleChooseIntervalType),
  reducer(chooseIntervalValue, handleChooseIntervalValue),
  reducer(chooseScheduleStartDate, handleChooseScheduleStartDate),
  reducer(notifyFetchingBroadcasts, handleNotifyFetchingBroadcasts),
  reducer(fetchBroadcastsError, handleFetchBroadcastsError),
  reducer(chooseScheduleEndDate, handleChooseScheduleEndDate),
  streamReducer(currentOrganization$, setStateProperty('organization')),
  reducer(updateUseCompanyLogo, handleUpdateUseCompanyLogo),
  reducer(updateMessageReplyTo, handleUpdateMessageReplyTo),
  reducer(updateHideLogo, handleUpdateHideLogo),
  reducer(updateViewpoints, handleUpdateViewpoints),
  reducer(fetchViewpoints, handleFetchViewpoints),
  reducer(toggleEndDateEnabled, handleIsEndDateEnabledChange),
  reducer(setBroadcastPreview, handleSetBroadcastPreview),
  reducer(setBroadcastPreviewStatus, handleSetBroadcastPreviewStatus),
  reducer(updateSendOnWeekends, handleUpdateSendOnWeekends),
];
