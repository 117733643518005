import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { PropertyValue } from 'aqTypes';
import {
  getEntityCollectionName,
  getEntityDictionaryName,
} from 'scopeData/scopeEditUtils/collectionUtils';
import type { EnhancedScopeData } from '@ardoq/data-model';

const updateReferenceTypePropertiesReducer = (
  entityID: ArdoqId,
  propertiesToUpdate: Record<string, PropertyValue>,
  enhancedScopeData: EnhancedScopeData,
  model: ArdoqId
) => {
  return {
    ...enhancedScopeData,
    typesByModelId: {
      ...enhancedScopeData.typesByModelId,
      [model]: {
        ...enhancedScopeData.typesByModelId[model],
        referenceTypesById: {
          ...enhancedScopeData.typesByModelId[model].referenceTypesById,
          [entityID]: {
            ...enhancedScopeData.typesByModelId[model].referenceTypesById[
              entityID
            ],
            ...propertiesToUpdate,
          },
        },
      },
    },
  };
};

const updateReferenceTypeProperties = (
  entityIDs: ArdoqId[],
  propertiesToUpdate: Record<string, PropertyValue>,
  enhancedScopeData: EnhancedScopeData,
  model?: ArdoqId
) => {
  if (!model) {
    return enhancedScopeData;
  }
  return entityIDs.reduce(
    (scopeData, entityID) =>
      updateReferenceTypePropertiesReducer(
        entityID,
        propertiesToUpdate,
        scopeData,
        model
      ),
    enhancedScopeData
  );
};

export const updateEntityProperties = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  propertiesToUpdate: Record<string, PropertyValue>,
  enhancedScopeData: EnhancedScopeData,
  model?: ArdoqId
) => {
  switch (entityType) {
    case APIEntityType.WORKSPACE:
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE:
    case APIEntityType.FIELD: {
      const entityDictionary = getEntityDictionaryName(entityType);
      const entityKey = getEntityCollectionName(entityType);
      const modifiedEntries = entityIDs.reduce<Record<string, unknown>>(
        (modified, entityID) => {
          modified[entityID] = {
            ...enhancedScopeData[entityDictionary][entityID],
            ...propertiesToUpdate,
          };
          return modified;
        },
        {}
      );
      const newEntitiesById = {
        ...enhancedScopeData[entityDictionary],
        ...modifiedEntries,
      };
      return {
        ...enhancedScopeData,
        [entityDictionary]: newEntitiesById,
        [entityKey]: Object.values(newEntitiesById),
      };
    }
    case APIEntityType.REFERENCE_TYPE: {
      return updateReferenceTypeProperties(
        entityIDs,
        propertiesToUpdate,
        enhancedScopeData,
        model
      );
    }
  }
  return enhancedScopeData;
};
