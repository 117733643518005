import { useRef, useState } from 'react';
import SummaryBlock from './SummaryBlock';
import {
  ExcludeFalsy,
  isArdoqError,
  ArdoqError,
  getArdoqErrorMessage,
} from '@ardoq/common-helpers';
import { ArdoqId, APIScenarioAttributes } from '@ardoq/api-types';
import { uniq } from 'lodash';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { getErrorStatusCode, scenarioApi } from '@ardoq/api';
import { ModalBody, ModalFooter, ModalHeader, ModalLayout } from '@ardoq/modal';
import { Select } from '@ardoq/select';
import {
  ArdoqLoaderSpinner,
  LoaderColor,
  LoaderSize,
} from '@ardoq/ardoq-loader-component';

type AddToScenarioStepProps = {
  componentIds: ArdoqId[];
  scenarios: APIScenarioAttributes[];
  onCancel: () => void;
  onConfirm: (scenario: APIScenarioAttributes, referenceIds: ArdoqId[]) => void;
  onLoadingReferencesFailed: (e: ArdoqError) => void;
};

const AddToScenarioStep = ({
  onCancel,
  onConfirm,
  componentIds,
  scenarios,
  onLoadingReferencesFailed,
}: AddToScenarioStepProps) => {
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [canAddToScenarioLoading, setCanAddToScenarioLoading] =
    useState<boolean>(false);
  const [addToScenarioError, setAddToScenarioError] = useState<string[]>([]);
  const [scenario, setScenario] = useState<APIScenarioAttributes | null>();
  const [referenceIds, setReferenceIds] = useState<ArdoqId[]>([]);
  const [isLoadingReferences, setIsLoadingReferences] =
    useState<boolean>(false);

  return (
    <>
      <ModalHeader title="Add to scenario" onCloseButtonClick={onCancel} />
      <ModalBody>
        <ModalLayout>
          <Select
            label="Select scenario"
            blurInputOnSelect
            options={scenarios.map(scenarioOption => ({
              label: scenarioOption.name,
              value: scenarioOption,
            }))}
            value={scenario && { label: scenario.name, value: scenario }}
            onChange={async option => {
              if (!option) {
                return;
              }

              const scenario = option.value;
              let canAddToScenario = false;
              setScenario(null);
              setAddToScenarioError([]);
              setCanAddToScenarioLoading(true);
              const result = await scenarioApi.canAddToScenario(
                scenario._id,
                componentIds
              );
              if (isArdoqError(result)) {
                const message = getArdoqErrorMessage(result);
                const code = getErrorStatusCode(result);
                setAddToScenarioError(
                  [
                    'The selected components cannot be added to the scenario.',
                    message && `Reason: ${message}`,
                    code && `Error code: ${code}`,
                  ].filter(ExcludeFalsy)
                );
                return;
              }
              canAddToScenario = Boolean(result);

              setCanAddToScenarioLoading(false);

              if (canAddToScenario) {
                setScenario(scenario);
                setIsLoadingReferences(true);
                const { branchId, scopeId } = scenario;
                const result = await scenarioApi.getScenarioReferences(
                  componentIds,
                  scopeId,
                  branchId
                );
                if (isArdoqError(result)) {
                  setCanAddToScenarioLoading(false);
                  onLoadingReferencesFailed(result);
                  return;
                }
                const { referencesToScope, references } = result;
                const referenceIds = uniq(
                  [...references, ...referencesToScope].map(({ _id }) => _id)
                );
                setReferenceIds(referenceIds);
                setIsLoadingReferences(false);
              }
            }}
            data-click-id="select-scenario-to-add-components"
          />
          {canAddToScenarioLoading && (
            <>
              Checking if scenario is in sync...{' '}
              <ArdoqLoaderSpinner
                color={LoaderColor.GRAY}
                size={LoaderSize.SMALL}
              />
            </>
          )}
          {!scenario &&
            !canAddToScenarioLoading &&
            addToScenarioError.length === 0 && (
              <>
                Select scenario to check if the selected components can be
                added.
              </>
            )}
          {addToScenarioError.length > 0 && (
            <>
              {addToScenarioError.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </>
          )}
          {scenario && isLoadingReferences && (
            <>
              It&apos;s loading related references...{' '}
              <ArdoqLoaderSpinner
                color={LoaderColor.GRAY}
                size={LoaderSize.SMALL}
              />
            </>
          )}
          {scenario && !isLoadingReferences && (
            <SummaryBlock
              referencesCount={referenceIds.length}
              componentsCount={componentIds.length}
            />
          )}
        </ModalLayout>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <SecondaryButton ref={cancelButtonRef} onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            ref={confirmButtonRef}
            isDisabled={!scenario || isLoadingReferences}
            onClick={() => scenario && onConfirm(scenario, referenceIds)}
            data-click-id="add-to-scenario-confirm-button"
          >
            Add to scenario
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default AddToScenarioStep;
