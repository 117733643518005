import { FieldType } from '@ardoq/renderers';
import { PropertyValue } from 'aqTypes';
import { actionCreator } from '@ardoq/rxbeach';
import { APIEntityType, APIReferenceType } from '@ardoq/api-types';
import { getEditableEntityPropertiesToCompare } from 'appModelStateEdit/propertiesEditor/utils';

export type ModifyPropertyPayload = {
  readonly propertyType: FieldType;
  readonly propertyName: string;
  readonly newValue: PropertyValue;
  readonly originalProperties: ReturnType<
    typeof getEditableEntityPropertiesToCompare<APIEntityType>
  >;
};
export const modifyProperty = actionCreator<ModifyPropertyPayload>(
  '[appModelStateEdit] MODIFY_PROPERTY'
);

export type ModifySimplePropertyPayload = Omit<
  ModifyPropertyPayload,
  'originalProperties' | 'propertyType'
>;
export const modifySimpleProperty = actionCreator<ModifySimplePropertyPayload>(
  '[appModelStateEdit] MODIFY_SIMPLE_PROPERTY'
);

export type ModifyTagsPayload = string[];
export const modifyTags = actionCreator<ModifyTagsPayload>(
  '[appModelStateEdit] MODIFY_TAGS'
);

export type TagNamesPayload = string[];
export const addNewTags = actionCreator<TagNamesPayload>(
  '[appModelStateEdit] ADD_NEW_TAGS'
);

export const removeNewTags = actionCreator<TagNamesPayload>(
  '[appModelStateEdit] REMOVE_NEW_TAGS'
);

export const setFormError = actionCreator<string>(
  '[appModelStateEdit] SET_FORM_ERROR'
);

export const clearFormError = actionCreator(
  '[appModelStateEdit] CLEAR_FORM_ERROR'
);

export const setAttributeDirty = actionCreator<string>(
  '[appModelStateEdit] SET_ATTRIBUTE_DIRTY'
);

export type SetRefTypesSortedByMostUsedPayload = {
  newValue: APIReferenceType[] | null;
};

export const setRefTypesSortedByMostUsed =
  actionCreator<SetRefTypesSortedByMostUsedPayload>(
    '[appModelStateEdit] SET_REF_TYPES_SORTED_BY_MOST_USED'
  );
