import {
  SUBDIVISIONS_STRINGS,
  SubdivisionsContext,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import {
  SubdivisionCreationContextEditorProperty,
  SubdivisionMembershipsViewProperty,
  SubdivisionCreationContextState,
} from './types';
import { ArdoqId } from '@ardoq/api-types';
import { subdivisionCreationContextOperations } from './subdivisionCreationContextOperations';
import { FieldType } from '@ardoq/renderers';
import { componentInterface } from '@ardoq/component-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { subdivisionOperations } from 'subdivisions/subdivisionOperations';

const getSubdivisionCreationContextProperty = ({
  subdivisionCreationContext,
  parentComponentId,
  subdivisionsContext,
}: {
  subdivisionCreationContext: SubdivisionCreationContextState;
  subdivisionsContext: SubdivisionsContext;
  parentComponentId: ArdoqId | null;
}):
  | SubdivisionCreationContextEditorProperty
  | SubdivisionMembershipsViewProperty => {
  if (parentComponentId === null) {
    return subdivisionCreationContextOperations.generateEditorProperty(
      subdivisionCreationContext
    );
  }
  const options = (
    componentInterface.getAttribute(
      parentComponentId,
      'subdivisionMembership'
    ) || []
  )
    .map(subdivisionId =>
      subdivisionsOperations.getSubdivisionById(
        subdivisionsContext,
        subdivisionId
      )
    )
    .filter(ExcludeFalsy)
    .map(subdivisionOperations.convertToSelectOption);

  return {
    type: FieldType.INHERITED_SUBDIVISIONS,
    name: SUBDIVISIONS_STRINGS.CREATION_CONTEXT_EDITOR.HEADER,
    label: SUBDIVISIONS_STRINGS.CREATION_CONTEXT_EDITOR.HEADER,
    options,
    isDirty: false,
    value: options.map(option => option.value),
    isReadOnly: true,
    defaultValue: [],
    errorMessages: null,
  };
};

export const subdivisionCreationContextInterface = {
  getSubdivisionCreationContextProperty,
};
