import { actionCreator } from '@ardoq/rxbeach';
import { GridEditorAddFieldContext } from './types';
import { APIFieldAttributes } from '@ardoq/api-types';
import { AddFieldNewToWorkspacePayload } from 'appModelStateEdit/propertiesEditor/addField/types';

type AddFieldByNamePayload = {
  readonly name: string;
  readonly fieldContext: GridEditorAddFieldContext;
};
export const addFieldByName = actionCreator<AddFieldByNamePayload>(
  '[appModelStateEdit/gridEditor] ADD_FIELD_BY_NAME'
);

export const addWorkspaceField = actionCreator<APIFieldAttributes>(
  '[appModelStateEdit/gridEditor] ADD_WORKSPACE_FIELD'
);

export const addWorkspaceFieldReducer = actionCreator<APIFieldAttributes>(
  '[appModelStateEdit/gridEditor] ADD_WORKSPACE_FIELD_REDUCER'
);

export const addFieldNewToWorkspace =
  actionCreator<AddFieldNewToWorkspacePayload>(
    '[appModelStateEdit/gridEditor] ADD_FIELD_NEW_TO_WORKSPACE'
  );

type CreateFieldByLabelPayload = {
  readonly label: string;
  readonly fieldContext: GridEditorAddFieldContext;
};
export const createFieldByLabel = actionCreator<CreateFieldByLabelPayload>(
  '[appModelStateEdit/gridEditor] CREATE_FIELD_BY_LABEL'
);

export const closeGridEditorFieldWorkflow = actionCreator(
  '[appModelStateEdit/gridEditor] CLOSE_GRID_EDITOR_FIELD_WORKFLOW'
);

export const createOrUpdateFieldInGridEditor = actionCreator(
  '[appModelStateEdit/gridEditor] CREATE_OR_UPDATE_FIELD_IN_GRID_EDITOR'
);
