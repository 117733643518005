import { FC } from 'react';
import styled from 'styled-components';
import ExternallyManagedIndicator from 'externallyManaged/ExternallyManagedIndicator';
import { POPOVER_ID_ATTR, popoverRegistry } from '@ardoq/popovers';
import { LinkDecorator } from '@ardoq/decorators';
import { colors } from '@ardoq/design-tokens';
import { ArdoqId, WorkspaceIntegration } from '@ardoq/api-types';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { navigateToIntegrationBySource } from 'integrations/common/navigation/utils';

const { getWorkspaceIntegrations } = workspaceInterface;

type ExternallyManagedNavigatorIndicatorProps = {
  readonly workspaceId: ArdoqId;
};
type IntegrationsProps = {
  readonly integrations: WorkspaceIntegration[];
};

const IntegrationsWrapper = styled.div`
  width: 300px;
  color: ${colors.grey35};
  pointer-events: auto;
`;

const Integrations: FC<IntegrationsProps> = ({ integrations }) => {
  return (
    <IntegrationsWrapper>
      <div>
        <div>This workspace is managed by:</div>
        {integrations.map(({ _id, name, type }) => (
          <div key={_id}>
            <LinkDecorator
              hideIcon
              href=""
              onClick={() => {
                navigateToIntegrationBySource(type);
              }}
            >
              {name}
            </LinkDecorator>
          </div>
        ))}
      </div>
      <br />
      <div>Note: Changes on populating components may be overwritten</div>
    </IntegrationsWrapper>
  );
};

const ExternallyManagedIndicatorWithPopover: FC<
  ExternallyManagedNavigatorIndicatorProps
> = ({ workspaceId }) => {
  const POPOVER_ID = 'externally-managed-indicator-container';
  popoverRegistry.set(POPOVER_ID, target => (
    <Integrations
      integrations={getWorkspaceIntegrations(
        target?.getAttribute('data-workspace-id') ?? ''
      )}
    />
  ));

  return (
    <span
      style={{ cursor: 'pointer' }}
      {...{ [POPOVER_ID_ATTR]: POPOVER_ID }}
      data-workspace-id={workspaceId}
    >
      <ExternallyManagedIndicator />
    </span>
  );
};

export default ExternallyManagedIndicatorWithPopover;
