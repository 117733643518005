import { FieldType } from '@ardoq/renderers';
import { EditorProperty } from 'appModelStateEdit/propertiesEditor/types';
import { head, intersection, isEqual, uniqWith, zip } from 'lodash';
import { PropertyValue } from 'aqTypes';
import * as profiling from '@ardoq/profiling';
import { SelectOptionsOrGroups } from '@ardoq/select';
import { ExcludeFalsy } from '@ardoq/common-helpers';

const LIST_TYPE_PROPERTIES = [
  FieldType.TYPE_ID_COMPONENT,
  FieldType.PARENT,
  FieldType.LIST,
  FieldType.TYPE_ID_REFERENCE,
  FieldType.SOURCE_CARDINALITY,
  FieldType.TARGET_CARDINALITY,
  FieldType.SOURCE,
  FieldType.TARGET,
  FieldType.REFERENCE_TYPE_LINE,
  FieldType.REFERENCE_TYPE_LINE_BEGINNING,
  FieldType.REFERENCE_TYPE_LINE_ENDING,
  FieldType.FIELD_TYPE,
  FieldType.NUMBER_FORMAT_OPTIONS,
  FieldType.ICON,
  FieldType.IMAGE,
  FieldType.SHAPE,
  FieldType.START_VIEW,
  FieldType.DEFAULT_PERSPECTIVE,
  FieldType.DEFAULT_SORT,
  FieldType.SELECT_MULTIPLE_LIST,
  FieldType.COMPONENT_TYPE,
  FieldType.REFERENCE_TYPE,
  FieldType.USER,
  FieldType.DEFAULT_VALUE_USER,
  FieldType.FILE,
  FieldType.DEFAULT_VALUE_FILE,
  FieldType.DEFAULT_VALUE_LIST,
  FieldType.DEFAULT_VALUE_SELECT_MULTIPLE_LIST,
];

const allArrays = (values: PropertyValue[]): values is string[][] =>
  values.every(Array.isArray);

const getNullValueForFieldType = (fieldType: FieldType) => {
  if (
    fieldType === FieldType.DATE_ONLY_RANGE ||
    fieldType === FieldType.DATE_TIME_RANGE
  ) {
    return { start: null, end: null };
  }
  if (fieldType === FieldType.SELECT_MULTIPLE_LIST) {
    return [];
  }
  return null;
};

const getCoercedOptions = (editorProperties: EditorProperty[]) => {
  const [firstProperty] = editorProperties;
  const isListTypeProperty = LIST_TYPE_PROPERTIES.includes(firstProperty.type);
  if (!isListTypeProperty || !firstProperty.options) {
    return;
  }
  const isDisabled = editorProperties.some(
    ({ isDisabled: currentIsDisabled }) => currentIsDisabled
  );
  if (isDisabled) {
    return;
  }
  const optionValues = editorProperties.map(
    ({ options }) =>
      options
        ?.flatMap(optionOrGroup =>
          'options' in optionOrGroup ? optionOrGroup.options : [optionOrGroup]
        )
        .map(({ value }) => value) ?? []
  );
  const uniqueOptionValues = uniqWith(optionValues, isEqual);
  const commonValues = new Set(intersection(...uniqueOptionValues));
  return filterOptionValues(firstProperty.options, value =>
    commonValues.has(value)
  );
};

const filterOptionValues = (
  optionsOrGroups: SelectOptionsOrGroups<PropertyValue>,
  shouldIncludeValue: (value: PropertyValue) => boolean
): SelectOptionsOrGroups<PropertyValue> => {
  return optionsOrGroups
    .map(optionOrGroup => {
      if ('options' in optionOrGroup) {
        return {
          ...optionOrGroup,
          options: optionOrGroup.options.filter(({ value }) =>
            shouldIncludeValue(value)
          ),
        };
      } else if (shouldIncludeValue(optionOrGroup.value)) {
        return optionOrGroup;
      }
      return null;
    })
    .filter(ExcludeFalsy);
};

const isMultiSourceOrMultiTarget = (editorProperties: EditorProperty[]) => {
  const [firstProperty] = editorProperties;
  if (
    firstProperty.type !== FieldType.SOURCE &&
    firstProperty.type !== FieldType.TARGET
  ) {
    return false;
  }
  const values = new Set(editorProperties.map(({ value }) => value));
  return values.size > 1;
};

const getDisabledMultiReferenceAttributes = (
  editorProperties: EditorProperty[]
) => {
  if (isMultiSourceOrMultiTarget(editorProperties)) {
    return { isDisabled: true, disabledMessage: 'Multiple' };
  }
  return {};
};

const getCoercedValueByType = (
  editorProperties: EditorProperty[]
): PropertyValue => {
  const [firstProperty] = editorProperties;
  const valuesList = editorProperties.map(({ value }) => value);
  const values = new Set(valuesList.map(value => JSON.stringify(value)));
  if (values.size <= 1) {
    return firstProperty.value;
  }
  if (firstProperty.type === FieldType.SELECT_MULTIPLE_LIST) {
    if (allArrays(valuesList)) {
      return intersection<string>(...valuesList);
    }
  }
  return getNullValueForFieldType(firstProperty.type);
};

const mergeProperties = (
  editorProperties: EditorProperty[]
): {
  options?: SelectOptionsOrGroups<PropertyValue>;
  value: PropertyValue;
} & EditorProperty => {
  const [firstProperty] = editorProperties;
  const mergedProperty = {
    ...firstProperty,
    ...getDisabledMultiReferenceAttributes(editorProperties),
  };
  const options = mergedProperty.isDisabled
    ? []
    : getCoercedOptions(editorProperties);
  return {
    ...mergedProperty,
    value: getCoercedValueByType(editorProperties),
    ...(options ? { options } : {}),
  };
};

const sortByOrderOfFirstEntity = (propertiesPerEntity: EditorProperty[][]) => {
  const sortReference = new Map<string, number>(
    head(propertiesPerEntity)!.map(({ name }, index) => [name, index])
  );
  return propertiesPerEntity.map(entityProperties =>
    entityProperties.sort((a, b) => {
      return sortReference.get(a.name)! - sortReference.get(b.name)!;
    })
  );
};

export const getMergedMultiEntityProperties = profiling.functionWithTransaction(
  '[Sidebar Editor] getMergedMultiEntityProperties',
  1000,
  (propertiesPerEntity: EditorProperty[][]) => {
    // @ts-expect-error issues with undefined
    return zip(...propertiesPerEntity).map(mergeProperties);
  },
  profiling.Team.CORE
);

export const getMergedCustomMultiEntityProperties =
  profiling.functionWithTransaction(
    '[Sidebar Editor] getMergedCustomMultiEntityProperties',
    1000,
    (
      propertiesPerEntity: EditorProperty[][],
      commonPropertyNames: Set<string>
    ): ({
      options?: SelectOptionsOrGroups<PropertyValue>;
      value: PropertyValue;
    } & EditorProperty)[] => {
      const propertiesPerEntityFilteredByCommonProperties =
        propertiesPerEntity.map(entityProperties =>
          entityProperties.filter(({ name }) => commonPropertyNames.has(name))
        );
      const sortedAndFilteredProperties =
        commonPropertyNames.size > 0
          ? sortByOrderOfFirstEntity(
              propertiesPerEntityFilteredByCommonProperties
            )
          : propertiesPerEntityFilteredByCommonProperties;

      return zip(...sortedAndFilteredProperties).map(
        // @ts-expect-error issues with undefined
        mergeProperties
      );
    },
    profiling.Team.CORE
  );
