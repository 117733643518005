import {
  DataSource,
  DataSourceItem,
  DiffTableRowType,
  FieldConflict,
  MergeState,
  MergeStep,
  MergeTableSectionType,
  MissingComponentConflict,
  Path,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIModelAttributes,
  APIReferenceAttributes,
  APITagAttributes,
  ArdoqId,
  ScopeDataCollection,
  Verb,
} from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { Branch } from 'components/DiffMergeTable/Branch';
import { getInitialMergeState } from 'components/DiffMergeTable/getInitialMergeState';
import { getStructuralConflict, isFieldInUseOnTarget } from '../utils';
import {
  getEntityById,
  hasComponentField,
  hasComponentType,
  hasReferenceField,
  hasReferenceType,
  readRawValue,
} from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { difference, uniq } from 'lodash';
import {
  getNewDataSourceAndUpdateParentAndChildren,
  getRowIndexesToApplyAutoselectOnAncestorsOrDescendants,
} from '../diffMergeTableHelpers';
import { MergeDirection } from 'scope/merge/MergeDirection';

export const getHeader = ({
  entityType,
  basePath,
  enhancedDiffContextData,
  rootIndex,
  rootChildren,
  status,
  isDisabled,
}: {
  entityType: APIEntityType;
  basePath: Path;
  enhancedDiffContextData: EnhancedDiffScopeData;
  rootIndex: number;
  rootChildren: number[];
  status?: MergeState;
  isDisabled?: boolean;
}): DataSourceItem => ({
  rowType: DiffTableRowType.HEADER_ROW,
  entityId: '',
  entityType,
  fieldName: '',
  enhancedDiffContextData,
  path: [...basePath],
  index: rootIndex,
  parent: null,
  children: rootChildren,
  status: status ?? MergeState.NONE,
  isDisabled,
  structuralConflict: StructuralConflict.NONE,
  fieldConflict: FieldConflict.NONE,
});

export const getSubHeader = ({
  entityType,
  entityId,
  basePath,
  enhancedDiffContextData,
  parent,
  index,
  children,
  status,
  verb,
  isDisabled,
  hasTypeConflict,
  missingComponentConflict,
  structuralConflict,
  sectionType,
  hasWritePermission,
}: {
  entityType: APIEntityType;
  entityId: ArdoqId;
  basePath: Path;
  enhancedDiffContextData: EnhancedDiffScopeData;
  parent: number;
  index: number;
  children?: number[];
  status?: MergeState;
  verb: Verb;
  hasWritePermission: boolean;
  isDisabled?: boolean;
  hasTypeConflict?: boolean;
  missingComponentConflict?: MissingComponentConflict | null;
  structuralConflict?: StructuralConflict;
  sectionType?: MergeTableSectionType;
}): DataSourceItem => ({
  rowType: DiffTableRowType.SUB_HEADER_ROW,
  entityId,
  entityType,
  fieldName: 'name',
  enhancedDiffContextData,
  path: [...basePath, entityId] as Path,
  parent,
  status: status ?? MergeState.NONE,
  isExpanded: false,
  index,
  children: children ?? [],
  structuralConflict: getStructuralConflict(
    entityId,
    entityType,
    enhancedDiffContextData
  ),
  isDisabled:
    (entityType === APIEntityType.FIELD &&
      verb === Verb.CREATE &&
      (sectionType === MergeTableSectionType.CREATED_IN_BRANCH ||
        sectionType === MergeTableSectionType.CREATED_IN_MAINLINE)) ||
    isDisabled ||
    hasTypeConflict ||
    structuralConflict ===
      StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED ||
    Boolean(missingComponentConflict) ||
    !hasWritePermission,
  fieldConflict: FieldConflict.NONE,
  isInUseOnTarget:
    entityType === APIEntityType.FIELD &&
    isFieldInUseOnTarget(entityId, enhancedDiffContextData[Branch.TARGET]),
  hasTypeConflict,
  missingComponentConflict,
  hasWritePermission,
});

const getHasMissingParentConflict = ({
  entityId,
  entityType,
  fieldName,
  enhancedDiffContextData,
}: {
  entityId: ArdoqId;
  entityType: APIEntityType;
  fieldName: string;
  enhancedDiffContextData: EnhancedDiffScopeData;
}) =>
  entityType === APIEntityType.COMPONENT &&
  fieldName === 'parent' &&
  hasMissingParentOnTarget(entityId, entityType, enhancedDiffContextData);

export const getPropertyRow = ({
  entityType,
  entityId,
  basePath,
  enhancedDiffContextData,
  index,
  parentIndex,
  fieldName,
  status,
  updates,
  fieldConflict = getFieldConflict(
    entityType,
    entityId,
    fieldName,
    enhancedDiffContextData
  ),
  isDisabled,
  verb,
  hasWritePermission,
}: {
  entityType: APIEntityType;
  entityId: ArdoqId;
  basePath: Path;
  enhancedDiffContextData: EnhancedDiffScopeData;
  index: number;
  parentIndex: number;
  fieldName: string;
  status?: MergeState;
  updates?:
    | APIFieldAttributes
    | APITagAttributes
    | APIComponentAttributes
    | APIModelAttributes
    | APIReferenceAttributes;
  fieldConflict?: FieldConflict;
  isDisabled?: boolean;
  verb: Verb;
  hasWritePermission: boolean;
}) => {
  const hasTypeConflict =
    (fieldName === 'type' || fieldName === 'typeId') &&
    getHasTypeConflict(entityType, entityId, verb, enhancedDiffContextData);

  const hasMissingParentConflict = getHasMissingParentConflict({
    entityId,
    entityType,
    fieldName,
    enhancedDiffContextData,
  });
  const initialStatus =
    entityType === APIEntityType.FIELD
      ? getStatusForFields(
          entityId,
          enhancedDiffContextData,
          fieldName,
          updates as APIFieldAttributes,
          hasWritePermission
        )
      : (status ??
        getInitialMergeState({
          entityId,
          entityType,
          fieldName,
          enhancedDiffContextData,
          fieldConflict,
          hasTypeConflict,
          hasMissingParentConflict,
          hasWritePermission,
        }));

  return {
    rowType: DiffTableRowType.PROPERTY_ROW,
    entityId,
    entityType,
    fieldName,
    enhancedDiffContextData,
    path: [...basePath, entityId, fieldName] as Path,
    index,
    parent: parentIndex,
    children: [] as number[],
    structuralConflict: StructuralConflict.NONE,
    status: initialStatus,
    fieldConflict,
    isDisabled:
      fieldConflict !== FieldConflict.NONE ||
      isDisabled ||
      hasTypeConflict ||
      hasMissingParentConflict ||
      !hasWritePermission,
    hasTypeConflict,
    hasMissingParentConflict,
    hasWritePermission,
  };
};

const hasMissingParentOnTarget = (
  entityId: ArdoqId,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  const { mergeDirection } = enhancedDiffContextData;
  const parentId = readRawValue(
    entityType,
    entityId,
    'parent',
    enhancedDiffContextData[Branch.SOURCE]
  );
  return (
    Boolean(parentId) && // if parent id is null it's a root component
    (mergeDirection === MergeDirection.BRANCH_TO_MAINLINE ||
      // With merge direction mainline to branch we have to ensure that the
      // parent is not a deleted scenario component.
      // If the parent is not part of the scoped components it will be added
      // as a contextual component to the scenario in the reset call.
      enhancedDiffContextData[Branch.TARGET].scopeComponentIds.has(parentId)) &&
    !getEntityById(entityType, parentId, enhancedDiffContextData[Branch.TARGET])
  );
};

const getStatusForFields = (
  entityId: ArdoqId,
  enhancedDiffContextData: EnhancedDiffScopeData,
  fieldName: string,
  updates: APIFieldAttributes,
  hasWritePermission: boolean
) => {
  if (
    fieldName === 'componentType' &&
    Object.keys(updates).includes('global')
  ) {
    return getInitialMergeState({
      entityId,
      entityType: APIEntityType.FIELD,
      fieldName: 'global',
      enhancedDiffContextData,
      hasWritePermission,
    });
  } else if (
    fieldName === 'referenceType' &&
    Object.keys(updates).includes('globalref')
  ) {
    return getInitialMergeState({
      entityId,
      entityType: APIEntityType.FIELD,
      fieldName: 'globalref',
      enhancedDiffContextData,
      hasWritePermission,
    });
  }
  return getInitialMergeState({
    entityId,
    entityType: APIEntityType.FIELD,
    fieldName,
    enhancedDiffContextData,
    hasWritePermission,
  });
};

interface GetPreselectionStatusArgs {
  entityType: APIEntityType;
  entityId: ArdoqId;
  verb: Verb;
  enhancedDiffContextData: EnhancedDiffScopeData;
  missingComponentConflict: MissingComponentConflict | null;
  hasTypeConflict: boolean;
  structuralConflict: StructuralConflict;
  sectionType: MergeTableSectionType;
  hasWritePermission: boolean;
}

export const getPreselectionStatus = ({
  entityType,
  entityId,
  verb,
  enhancedDiffContextData,
  missingComponentConflict,
  hasTypeConflict,
  structuralConflict,
  sectionType,
  hasWritePermission,
}: GetPreselectionStatusArgs) => {
  if (
    hasTypeConflict ||
    structuralConflict ===
      StructuralConflict.RIGID_MODEL_PARENT_TYPE_HAS_CHANGED ||
    missingComponentConflict ||
    (entityType === APIEntityType.FIELD &&
      verb === Verb.DELETE &&
      isFieldInUseOnTarget(entityId, enhancedDiffContextData[Branch.TARGET])) ||
    !hasWritePermission
  ) {
    return MergeState.NONE;
  }

  return (verb === Verb.CREATE &&
    (sectionType === MergeTableSectionType.CREATED_IN_BRANCH ||
      sectionType === MergeTableSectionType.CREATED_IN_MAINLINE)) ||
    (verb === Verb.DELETE &&
      (sectionType === MergeTableSectionType.DELETED_IN_MAINLINE ||
        sectionType === MergeTableSectionType.DELETED_IN_BRANCH))
    ? MergeState.SOURCE
    : MergeState.NONE;
};

export const mergeFieldConfig = (updates: APIFieldAttributes) =>
  // we don't want to show a row for the global attribute, map it to type instead and show apply to all in the table
  Object.fromEntries(
    Object.entries(updates).map(([key, update]) => {
      if (key === 'global') {
        return ['componentType', update];
      } else if (key === 'globalref') {
        return ['referenceType', update];
      }
      return [key, update];
    })
  );

export const getHasTypeConflict = (
  entityType: APIEntityType,
  entityId: ArdoqId,
  verb: Verb,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  if (verb === Verb.CREATE || verb === Verb.UPDATE) {
    if (entityType === APIEntityType.COMPONENT) {
      const component =
        enhancedDiffContextData[Branch.SOURCE].componentsById[entityId];
      return !hasComponentType(
        component?.typeId,
        component?.rootWorkspace,
        enhancedDiffContextData[Branch.TARGET]
      );
    }

    if (entityType === APIEntityType.REFERENCE) {
      const reference =
        enhancedDiffContextData[Branch.SOURCE].referencesById[entityId];
      return !hasReferenceType(
        reference?.type,
        reference?.rootWorkspace,
        enhancedDiffContextData[Branch.TARGET]
      );
    }
  }

  return false;
};

export const getMissingComponentConflict = (
  entityId: ArdoqId,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  const reference = getEntityById<APIEntityType.REFERENCE>(
    APIEntityType.REFERENCE,
    entityId,
    enhancedDiffContextData[Branch.SOURCE]
  );

  const sourceComponent = getEntityById(
    APIEntityType.COMPONENT,
    reference!.source,
    enhancedDiffContextData[Branch.TARGET]
  );

  const targetComponent = getEntityById(
    APIEntityType.COMPONENT,
    reference!.target,
    enhancedDiffContextData[Branch.TARGET]
  );

  if (!(sourceComponent || targetComponent)) {
    return MissingComponentConflict.BOTH;
  } else if (!sourceComponent) {
    return MissingComponentConflict.SOURCE;
  } else if (!targetComponent) {
    return MissingComponentConflict.TARGET;
  }
  return null;
};

const getFieldConflict = (
  entityType: APIEntityType,
  entityId: ArdoqId,
  fieldName: string,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  if (entityType === APIEntityType.COMPONENT) {
    return getComponentFieldConflict(
      entityId,
      fieldName,
      enhancedDiffContextData
    );
  }

  if (entityType === APIEntityType.REFERENCE) {
    return getReferenceFieldConflict(
      entityId,
      fieldName,
      enhancedDiffContextData
    );
  }

  return FieldConflict.NONE;
};

type HasField = (
  entityId: ArdoqId,
  fieldName: string,
  enhancedScopeData: EnhancedScopeData
) => boolean;

const getEntityFieldConflict =
  (hasField: HasField) =>
  (
    entityId: ArdoqId,
    fieldName: string,
    enhancedDiffContextData: EnhancedDiffScopeData
  ) => {
    const hasFieldOnSource = hasField(
      entityId,
      fieldName,
      enhancedDiffContextData[Branch.SOURCE]
    );
    const hasFieldOnTarget = hasField(
      entityId,
      fieldName,
      enhancedDiffContextData[Branch.TARGET]
    );

    return hasFieldOnSource && hasFieldOnTarget
      ? FieldConflict.NONE
      : hasFieldOnTarget
        ? FieldConflict.MISSING_FIELD_ON_SOURCE
        : FieldConflict.MISSING_FIELD_ON_TARGET;
  };

const getComponentFieldConflict = getEntityFieldConflict(hasComponentField);

const getReferenceFieldConflict = getEntityFieldConflict(hasReferenceField);

export const getEntitiesFromEnhancedDiffContext = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  collection: ScopeDataCollection,
  verb: Verb
) => {
  const entitiesForCollection =
    enhancedDiffContextData.diffTargetToSource[
      collection as ScopeDataCollection
    ];
  return entitiesForCollection?.[verb];
};

export const getIndexer = () => {
  let index = 0;
  return () => index++;
};

export const getPath = (
  mergeStep: MergeStep
): [ScopeDataCollection, Verb] | null => {
  switch (mergeStep) {
    case MergeStep.NONE:
      return null;
    case MergeStep.UPDATE_MODELS:
      return [ScopeDataCollection.MODELS, Verb.UPDATE];
    case MergeStep.UPDATE_COMPONENTS:
      return [ScopeDataCollection.COMPONENTS, Verb.UPDATE];
    case MergeStep.CREATE_COMPONENTS:
      return [ScopeDataCollection.COMPONENTS, Verb.CREATE];
    case MergeStep.DELETE_COMPONENTS:
      return [ScopeDataCollection.COMPONENTS, Verb.DELETE];
    case MergeStep.CREATE_REFERENCES:
      return [ScopeDataCollection.REFERENCES, Verb.CREATE];
    case MergeStep.UPDATE_REFERENCES:
      return [ScopeDataCollection.REFERENCES, Verb.UPDATE];
    case MergeStep.DELETE_REFERENCES:
      return [ScopeDataCollection.REFERENCES, Verb.DELETE];
    case MergeStep.CREATE_TAGS:
      return [ScopeDataCollection.TAGS, Verb.CREATE];
    case MergeStep.DELETE_TAGS:
      return [ScopeDataCollection.TAGS, Verb.DELETE];
    case MergeStep.UPDATE_TAGS:
      return [ScopeDataCollection.TAGS, Verb.UPDATE];
    case MergeStep.MERGE_TAGS:
      return [ScopeDataCollection.TAGS, Verb.MERGE];
    case MergeStep.CREATE_FIELDS:
      return [ScopeDataCollection.FIELDS, Verb.CREATE];
    case MergeStep.DELETE_FIELDS:
      return [ScopeDataCollection.FIELDS, Verb.DELETE];
    case MergeStep.UPDATE_FIELDS:
      return [ScopeDataCollection.FIELDS, Verb.UPDATE];
  }
  return null;
};

export const getSkipSectionHeader = (
  verb: Verb,
  sectionType: MergeTableSectionType
) => {
  const isDeleteStep = verb === Verb.DELETE;
  const isCreateStep = verb === Verb.CREATE;
  const isCreatedSection =
    sectionType === MergeTableSectionType.CREATED_IN_BRANCH ||
    sectionType === MergeTableSectionType.CREATED_IN_MAINLINE;
  const isDeletedSection =
    sectionType === MergeTableSectionType.DELETED_IN_BRANCH ||
    sectionType === MergeTableSectionType.DELETED_IN_MAINLINE;
  return (
    (isCreateStep && isCreatedSection) || (isDeleteStep && isDeletedSection)
  );
};

export const propagatePreselectionToAncesotrsOrDescendants = (
  dataSource: DataSource
) => {
  const preselectedDataSourceRows = dataSource.filter(
    ({ rowType, isDisabled, status }) =>
      !isDisabled &&
      rowType === DiffTableRowType.SUB_HEADER_ROW &&
      status !== MergeState.NONE
  );

  const rowIndexesThatShouldBePreselected = uniq(
    difference(
      preselectedDataSourceRows.flatMap(({ index, status }) =>
        getRowIndexesToApplyAutoselectOnAncestorsOrDescendants(
          dataSource,
          index,
          status
        )
      ),
      preselectedDataSourceRows.map(({ index }) => index)
    )
  );
  if (preselectedDataSourceRows.length) {
    const defaultMergeStatus = preselectedDataSourceRows[0].status;
    const applyPreselectionOnDataSourceForIndex = (
      accDataSource: DataSource,
      index: number
    ): DataSource =>
      getNewDataSourceAndUpdateParentAndChildren(
        accDataSource,
        index,
        defaultMergeStatus
      ) || [];
    return rowIndexesThatShouldBePreselected.reduce<DataSource>(
      applyPreselectionOnDataSourceForIndex,
      dataSource
    );
  }
  return dataSource;
};
