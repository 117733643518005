import { ArdoqId } from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';
import {
  componentTypesHaveParentChildRelationship,
  getComponentTypeByComponentId,
} from '@ardoq/renderers';
import { head } from 'lodash';
import type { EnhancedScopeData } from '@ardoq/data-model';

export const getDefaultType = (
  componentId: ArdoqId,
  parentId: ArdoqId | null,
  enhancedScopeData: EnhancedScopeData
) => {
  const component = enhancedScopeData.componentsById[componentId];
  const model = enhancedScopeData.modelsById[component.model];
  const firstType = head(Object.values(model.root));
  if (!parentId) {
    return firstType ?? null;
  }
  const parent = enhancedScopeData.componentsById[parentId];
  const parentType = getComponentTypeByComponentId(
    parent._id,
    enhancedScopeData
  )!;
  const firstChildType = head(Object.values(parentType.children));
  if (!component.typeId) {
    return firstChildType ?? firstType ?? null;
  }
  const type =
    getComponentTypeByComponentId(component._id, enhancedScopeData) ?? null;
  if (
    type &&
    componentTypesHaveParentChildRelationship(
      parentType.id,
      type.id,
      model._id,
      enhancedScopeData
    )
  ) {
    return type;
  }
  return firstChildType ?? firstType ?? null;
};

export const getValidTypeBasedOnNewParent = (
  componentId: ArdoqId,
  newParentId: ArdoqId | null,
  enhancedScopeData: EnhancedScopeData
) => {
  const component = enhancedScopeData.componentsById[componentId];
  const model = enhancedScopeData.modelsById[component.model];
  const type =
    getComponentTypeByComponentId(component._id, enhancedScopeData) ?? null;
  if (model.flexible) {
    return type;
  }
  return getDefaultType(componentId, newParentId, enhancedScopeData);
};

export const updateTypesBasedOnParent = (
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData
) => {
  const components = enhancedScopeData.components.map(component => {
    if (!entityIDs.includes(component.id)) {
      return component;
    }
    const type = getValidTypeBasedOnNewParent(
      component._id,
      component.parent,
      enhancedScopeData
    );
    if (!type) {
      return component;
    }
    return {
      ...component,
      typeId: type.id,
      type: type.name,
    };
  });
  return {
    ...enhancedScopeData,
    components,
    componentsById: toByIdDictionary(components),
  };
};
