import { DatasourceTable, newTableTheme } from '@ardoq/table';
import { SmallSecondaryButton, SmallGhostButton } from '@ardoq/button';
import { CloseIcon, HistoryIcon } from '@ardoq/icons';
import { FlexBox } from '@ardoq/layout';
import { caption } from '@ardoq/typography';
import styled from 'styled-components';
import { colors } from '@ardoq/design-tokens';
import { without } from 'lodash';
import {
  APIEntityType,
  APIOrganizationUser,
  FetchHistoryAPIResult,
  ComparableAPIEntityTypes,
} from '@ardoq/api-types';
import {
  calculateDiffBetweenEntityAttributes,
  findAPIComponentType,
  findAPIReferenceType,
  revertBlockedTypeToMessage,
} from './utils';
import {
  ComparableAPIEntityAttributes,
  EntityType,
  FieldType,
  Nonexistent,
  RenderOption,
  Renderer,
  graphics,
} from '@ardoq/renderers';
import { formatDateTime } from '@ardoq/date-time';
import { RevertBlockedType } from './types';
import { TextOverflow, withPlainTextPopover } from '@ardoq/popovers';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { getCurrentLocale } from '@ardoq/locale';

type HistoryVersionTableProps = {
  entityId: string;
  entityType: ComparableAPIEntityTypes;
  entityAttributes: ComparableAPIEntityAttributes;
  enhancedScopeData: EnhancedScopeData;
  users: Record<string, APIOrganizationUser>;
  historyPoint: FetchHistoryAPIResult;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
};

const Description = styled.div`
  ${caption}
  color: ${colors.grey50};
`;

const LABEL_COLUMN_WIDTH = 220;
const VALUE_COLUMN_WIDTH = 250;

const DEFAULT_RENDERER_OPTIONS = {
  definitions: {
    [RenderOption.TEXT_ELLIPSIS]: {
      maxLength: VALUE_COLUMN_WIDTH - 10,
      showInPopover: true,
    },
  },
  types: {
    [FieldType.TEXT_AREA]: new Set([RenderOption.TEXT_ELLIPSIS]),
  },
};

export const HistoryVersionTable = ({
  entityId,
  enhancedScopeData,
  users,
  entityType,
  entityAttributes,
  historyPoint,
  selectedKeys,
  setSelectedKeys,
}: HistoryVersionTableProps) => {
  const locale = getCurrentLocale();

  return (
    <DatasourceTable
      fixedHeader
      components={newTableTheme}
      dataSource={calculateDiffBetweenEntityAttributes(
        entityAttributes,
        historyPoint,
        entityType,
        enhancedScopeData
      )}
      columns={[
        {
          dataIndex: 'label',
          cellStyle: { maxWidth: LABEL_COLUMN_WIDTH },
          valueRender: label => <TextOverflow>{label}</TextOverflow>,
        },
        {
          dataIndex: 'currentValue',
          headerStyle: { width: VALUE_COLUMN_WIDTH },
          headerRender: () => (
            <FlexBox flexDirection="column" gap="xsmall" marginBottom="small">
              Version{entityAttributes._version}(current)
              <Description>
                by {entityAttributes.lastModifiedByName} on{' '}
                {formatDateTime(entityAttributes.lastUpdated || '', locale)}
              </Description>
            </FlexBox>
          ),
          valueRender: (value, { fieldName }) => (
            <div style={{ maxWidth: VALUE_COLUMN_WIDTH }}>
              <Renderer
                entityId={entityId}
                entityType={entityType}
                fieldName={fieldName}
                // if we aim to use renderers outside the scenario scope
                // we cannot require branch name to be a part of scope
                enhancedScopeData={enhancedScopeData}
                users={users}
                graphics={graphics}
                options={{
                  rawValue: value,
                  ...DEFAULT_RENDERER_OPTIONS,
                }}
              />
            </div>
          ),
        },
        {
          dataIndex: 'revisionValue',
          headerStyle: { width: VALUE_COLUMN_WIDTH },
          headerRender: () => (
            <FlexBox flexDirection="column" gap="xsmall">
              Version{historyPoint._version}
              <Description>
                by {historyPoint.lastModifiedByName} on{' '}
                {formatDateTime(historyPoint.lastUpdated || '', locale)}
              </Description>
            </FlexBox>
          ),
          valueRender: (value, { fieldName, revertBlockType }) => (
            <div style={{ maxWidth: VALUE_COLUMN_WIDTH }}>
              {revertBlockType === RevertBlockedType.COMPONENT_DELETED ? (
                <Nonexistent />
              ) : (fieldName === 'type' &&
                  entityType === APIEntityType.REFERENCE) ||
                (fieldName === 'typeId' &&
                  entityType === APIEntityType.COMPONENT) ? (
                <EntityType
                  type={
                    entityType === APIEntityType.REFERENCE
                      ? findAPIReferenceType({
                          typeId: value,
                          referenceId: entityId,
                          enhancedScopeData,
                        })
                      : findAPIComponentType({
                          typeId: value,
                          componentId: entityId,
                          enhancedScopeData,
                        })
                  }
                />
              ) : (
                <Renderer
                  entityId={entityId}
                  entityType={entityType}
                  fieldName={fieldName}
                  // if we aim to use renderers outside the scenario scope
                  // we cannot require branch name to be a part of scope
                  enhancedScopeData={enhancedScopeData}
                  users={users}
                  graphics={graphics}
                  options={{
                    rawValue: value,
                    ...DEFAULT_RENDERER_OPTIONS,
                  }}
                />
              )}
            </div>
          ),
        },
        {
          headerStyle: { width: 150 },
          dataIndex: 'fieldName',
          valueRender: (fieldName, { revertBlockType }) => {
            return (
              <span
                {...withPlainTextPopover(
                  revertBlockType
                    ? `The change is not reversible. ${revertBlockedTypeToMessage[revertBlockType]}`
                    : null
                )}
              >
                {selectedKeys.includes(fieldName) ? (
                  <SmallGhostButton
                    isFlexible
                    onClick={() =>
                      setSelectedKeys(without(selectedKeys, fieldName))
                    }
                    data-tooltip-text="Click to undo"
                  >
                    <CloseIcon />
                    Undo
                  </SmallGhostButton>
                ) : (
                  <SmallSecondaryButton
                    isDisabled={Boolean(revertBlockType)}
                    isFlexible
                    onClick={() =>
                      setSelectedKeys([...selectedKeys, fieldName])
                    }
                  >
                    <HistoryIcon />
                    Revert
                  </SmallSecondaryButton>
                )}
              </span>
            );
          },
        },
      ]}
    />
  );
};
