import { SortWrapper } from 'components/EntityBrowser/atoms';
import styled, { css } from 'styled-components';
import { BasicTable } from '@ardoq/table';
import { SortOrder } from '@ardoq/api-types';
import { Icon, IconProps } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

type SortHeaderProps = {
  className?: string;
  onSortChanged: (sortByField: string) => void;
  sortOrder: SortOrder;
  dataClickId: string;
  title: string;
  sortByField: string | null;
  dataIndex: string;
  iconProps?: IconProps;
  tooltipText?: string;
};

const SortHeader = ({
  dataIndex,
  className,
  onSortChanged,
  sortOrder,
  dataClickId,
  title,
  sortByField,
  iconProps,
  tooltipText,
}: SortHeaderProps) => (
  <SortWrapper
    className={className}
    DEPRECATED_onClick={() => onSortChanged(dataIndex)}
    data-click-id={dataClickId}
  >
    {iconProps && (
      <Icon
        {...iconProps}
        color={iconProps.color ?? colors.grey50}
        data-tooltip-text={tooltipText}
      />
    )}
    {title}
    {sortByField === dataIndex && <BasicTable.SortIcon order={sortOrder} />}
  </SortWrapper>
);

export default SortHeader;

export const SortHeaderWithOffset = styled(SortHeader)<{
  $offsetMargin: number;
}>`
  ${({ $offsetMargin }) =>
    $offsetMargin &&
    css`
      margin-left: ${$offsetMargin}px;
      flex: 1;
    `}
`;
