import { reducer } from '@ardoq/rxbeach';
import { SurveyNotUpdatedSinceConditionStreamShape } from './types';
import { navigateToNewBroadcastForm } from 'router/navigationActions';
import {
  findSurveyNotUpdatedSinceCondition,
  getDefaultState,
  newSurveyNotUpdatedSinceCondition,
} from './utils';
import {
  chooseBroadcastContentType,
  initFilterConditions,
} from 'broadcasts/actions';
import {
  chooseSurveyContentTimeSinceLastUpdate,
  toggleSurveyNotUpdatedSinceCondition,
} from './actions';
import {
  BroadcastFilterCondition,
  TimeSinceLastUpdate,
} from '@ardoq/api-types';

const handleNavigateToNewBroadcastForm = reducer(
  navigateToNewBroadcastForm,
  getDefaultState
);

const handleInitFilterConditions = (
  state: SurveyNotUpdatedSinceConditionStreamShape,
  filterConditions: BroadcastFilterCondition[]
) => {
  const surveyNotUpdatedSinceCondition =
    findSurveyNotUpdatedSinceCondition(filterConditions);
  if (!surveyNotUpdatedSinceCondition) return getDefaultState();
  return {
    ...state,
    isEnabled: true,
    surveyNotUpdatedSinceCondition,
  };
};

const handleToggleSurveyNotUpdatedSinceCondition = (
  state: SurveyNotUpdatedSinceConditionStreamShape
) => ({
  ...state,
  isEnabled: !state.isEnabled,
});

const handleChooseSurveyContentTimeSinceLastUpdate = (
  state: SurveyNotUpdatedSinceConditionStreamShape,
  timeSinceLastUpdate: TimeSinceLastUpdate
) => ({
  ...state,
  surveyNotUpdatedSinceCondition:
    newSurveyNotUpdatedSinceCondition(timeSinceLastUpdate),
});

const handleChooseBroadcastContentType = reducer(
  chooseBroadcastContentType,
  getDefaultState
);

export const reducers = [
  handleNavigateToNewBroadcastForm,
  reducer(initFilterConditions, handleInitFilterConditions),
  reducer(
    toggleSurveyNotUpdatedSinceCondition,
    handleToggleSurveyNotUpdatedSinceCondition
  ),
  reducer(
    chooseSurveyContentTimeSinceLastUpdate,
    handleChooseSurveyContentTimeSinceLastUpdate
  ),
  handleChooseBroadcastContentType,
];
