import {
  APIDiffContext,
  APIPresentationScenarioAttributes,
  APIReferenceAttributes,
  APIScopeData,
  ArdoqId,
} from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { partition } from 'lodash';

export const fixPresentationScenario = (
  scenario: APIPresentationScenarioAttributes
) => {
  const { _id, scope, diffContext } = scenario;
  const { scopeComponents } = scope;
  return {
    ...scenario,
    scope: fixScopeData(scope, _id, scopeComponents),
    diffContext: fixDiffContext(diffContext, _id),
  };
};

export const fixDiffContext = (
  diffContext: APIDiffContext,
  scenarioId: ArdoqId | null
) => {
  const { master, branch, branchOff } = diffContext;
  const { scopeComponents } = branch;
  return {
    master: fixScopeData(master, scenarioId, scopeComponents),
    branch: fixScopeData(branch, scenarioId, scopeComponents),
    branchOff: fixScopeData(branchOff, scenarioId, scopeComponents),
  };
};

export const fixScopeData = <T extends APIScopeData>(
  scopeData: T,
  scenarioId: ArdoqId | null,
  scopeComponents = scopeData.scopeComponents
): T => {
  const { references } = scopeData;
  return {
    ...scopeData,
    references: removeDanglingReferences(
      references,
      scopeComponents,
      scenarioId
    ),
  };
};

// A dangling reference is a references where source and target are not
// scoped components. They don't belong in the scope data per definition.
// They are caused by a bug somewhere on the BE, but for now we remove them on
// the FE.
// Some more context in https://ardoqcom.atlassian.net/browse/ARD-11407.
const removeDanglingReferences = (
  references: APIReferenceAttributes[],
  scopeComponents: ArdoqId[],
  scenarioId: ArdoqId | null
) => {
  const componentIds = new Set(scopeComponents);
  const [sanitizedReferences, danglingReferences] = partition(
    references,
    ({ source, target }) => componentIds.has(source) && componentIds.has(target)
  );
  if (danglingReferences.length > 0) {
    logError(Error('Scope data with dangling reference'), null, {
      scenarioId,
      danglingReferencesCount: danglingReferences.length,
    });
  }
  return sanitizedReferences;
};
