import {
  APIEntityType,
  APITagAttributes,
  ArdoqId,
  ArrowType,
  Entity,
  LineType,
  MetaData,
  ScopeDataCollection,
} from '@ardoq/api-types';
import { generateHierarchy } from '@ardoq/renderers';
import { toByIdDictionary } from '@ardoq/common-helpers';
import { NewEntityAttributes } from 'scopeData/scopeEditUtils/types';
import type { EnhancedScopeData } from '@ardoq/data-model';

const getEmptyReferenceTypeAttributes = (id: number) => {
  return {
    id,
    index: id,
    line: LineType.DASHED,
    color: '#000',
    lineEnding: ArrowType.BOTH,
    returnsValue: false,
    svgStyle: null,
    hasCardinality: false,
  };
};

export const getEntityDictionaryName = (entityType: APIEntityType) => {
  if (entityType === APIEntityType.WORKSPACE) {
    return 'workspacesById';
  }
  if (entityType === APIEntityType.REFERENCE) {
    return 'referencesById';
  }
  if (entityType === APIEntityType.FIELD) {
    return 'fieldsById';
  }
  return 'componentsById';
};

export const getEntityCollectionName = (entityType: APIEntityType) => {
  if (entityType === APIEntityType.WORKSPACE) {
    return 'workspaces';
  }
  if (entityType === APIEntityType.REFERENCE) {
    return ScopeDataCollection.REFERENCES;
  }
  if (entityType === APIEntityType.FIELD) {
    return ScopeDataCollection.FIELDS;
  }
  return ScopeDataCollection.COMPONENTS;
};

export const getTagEntityCollectionName = (
  entityType: APIEntityType
): keyof Pick<
  APITagAttributes,
  ScopeDataCollection.COMPONENTS | ScopeDataCollection.REFERENCES
> =>
  entityType === APIEntityType.REFERENCE
    ? ScopeDataCollection.REFERENCES
    : ScopeDataCollection.COMPONENTS;

const insertReferenceType = (
  id: number,
  model: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  return {
    ...enhancedScopeData,
    typesByModelId: {
      ...enhancedScopeData.typesByModelId,
      [model]: {
        ...enhancedScopeData.typesByModelId[model],
        referenceTypesById: {
          ...enhancedScopeData.typesByModelId[model].referenceTypesById,
          [id]: getEmptyReferenceTypeAttributes(id),
        },
      },
    },
  };
};

const insertEntity = (
  entityType: APIEntityType,
  entityAttributes: NewEntityAttributes,
  enhancedScopeData: EnhancedScopeData
) => {
  if (entityType === APIEntityType.REFERENCE_TYPE) {
    const { _id, model } = entityAttributes;
    return insertReferenceType(parseInt(_id, 10), model, enhancedScopeData);
  }
  const entityCollectionName = getEntityCollectionName(entityType);
  const entityDictionary = getEntityDictionaryName(entityType);
  const newEntities = [
    ...enhancedScopeData[entityCollectionName],
    entityAttributes,
  ];
  const newEntitiesById = toByIdDictionary(newEntities);
  return {
    ...enhancedScopeData,
    [entityCollectionName]: newEntities,
    [entityDictionary]: newEntitiesById,
  };
};

export const insertEntities = (
  entityType: APIEntityType,
  entityAttributes: NewEntityAttributes[],
  enhancedScopeData: EnhancedScopeData
) =>
  entityAttributes.reduce(
    (scopeData, singleEntityAttributes) =>
      insertEntity(entityType, singleEntityAttributes, scopeData),
    enhancedScopeData
  );

// before: Partial<T> & { _id: tempId }
// after: Persisted<T>
const updateEntity = <T extends Entity & MetaData>(
  entityType: APIEntityType,
  tempId: string,
  entity: T,
  enhancedScopeData: EnhancedScopeData
) => {
  const entityCollectionName = getEntityCollectionName(entityType);
  const entityDictionary = getEntityDictionaryName(entityType);
  const persistedId = entity._id;
  const newEntities = enhancedScopeData[entityCollectionName].map(
    scopeEntity => {
      if (scopeEntity._id === tempId) {
        return entity;
      }
      return scopeEntity;
    }
  );
  const newEntitiesById = toByIdDictionary(newEntities);
  const newTags = enhancedScopeData.tags.map(tag => {
    return {
      ...tag,
      // @ts-expect-error weak inference
      [entityCollectionName]: tag[entityCollectionName].map(taggedId => {
        if (taggedId === tempId) {
          return persistedId;
        }
        return taggedId;
      }),
    };
  });
  const newTagsById = toByIdDictionary(newTags);
  // updates all places where an entity might have been referenced by its tempId
  return {
    ...enhancedScopeData,
    [entityCollectionName]: newEntities,
    [entityDictionary]: newEntitiesById,
    tags: newTags,
    tagsById: newTagsById,
    ...(entityType === APIEntityType.COMPONENT
      ? {
          // @ts-expect-error typing issues with the generic param
          hierarchy: generateHierarchy(newEntities),
        }
      : {}),
  };
};

export const updateEntities = <T extends Entity & MetaData>(
  entityType: APIEntityType,
  tempIdMappings: Record<string, T>,
  enhancedScopeData: EnhancedScopeData
) =>
  Object.entries(tempIdMappings).reduce(
    (scopeData, [tempId, entity]) =>
      updateEntity(entityType, tempId, entity, scopeData),
    enhancedScopeData
  );
