import { combineLatest, map } from 'rxjs';
import { loadedCurrentUser$ } from 'streams/currentUser/currentUser$';
import organizations$ from 'streams/organizations/organizations$';

const currentOrganization$ = combineLatest([
  loadedCurrentUser$,
  organizations$,
]).pipe(
  map(
    ([currentUser, organizations]) =>
      organizations.byId[currentUser.organization._id]
  )
);

export default currentOrganization$;
