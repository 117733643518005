import { KnowledgeBaseLink } from '@ardoq/knowledge-base';
import { PseudoLink } from './atoms';
import { Paragraph } from '@ardoq/typography';

const AboutScenarios = () => (
  <>
    <Paragraph variant="text1">
      Changes you make in a scenario are done in isolation from the mainline.
      Everyone in a scenario sees all that is included, regardless of workspace
      permissions.
    </Paragraph>
    <Paragraph variant="text1">
      <PseudoLink target="_blank" href={KnowledgeBaseLink.SCENARIOS}>
        To learn more about scenarios read our knowledge base article
      </PseudoLink>
    </Paragraph>
  </>
);

export default AboutScenarios;
