import { FieldConflict, MergeState } from './types';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { readRawValue } from '@ardoq/renderers';
import { EnhancedDiffScopeData } from './enhanceDiffContextData';
import { isEqual } from 'lodash';

type GetInitialMergeStateArgs = {
  entityId: ArdoqId;
  entityType: APIEntityType;
  fieldName: string;
  enhancedDiffContextData: EnhancedDiffScopeData;
  parentEntityId?: ArdoqId;
  fieldConflict?: FieldConflict;
  hasTypeConflict?: boolean;
  hasMissingParentConflict?: boolean;
  hasWritePermission?: boolean;
};

export const getInitialMergeState = ({
  entityId,
  entityType,
  fieldName,
  enhancedDiffContextData,
  parentEntityId,
  fieldConflict = FieldConflict.NONE,
  hasTypeConflict,
  hasMissingParentConflict,
  hasWritePermission,
}: GetInitialMergeStateArgs) => {
  if (fieldConflict !== FieldConflict.NONE) {
    return fieldConflict === FieldConflict.MISSING_FIELD_ON_SOURCE
      ? MergeState.TARGET
      : MergeState.NONE;
  }
  if (
    hasTypeConflict ||
    hasMissingParentConflict ||
    hasWritePermission === false
  )
    return MergeState.NONE;

  const { sourceBranch, targetBranch, branchOff } = enhancedDiffContextData;
  const sourceValue = readRawValue(
    entityType,
    entityId,
    fieldName,
    sourceBranch,
    parentEntityId
  );
  const targetValue = readRawValue(
    entityType,
    entityId,
    fieldName,
    targetBranch,
    parentEntityId
  );
  const branchOffValue = readRawValue(
    entityType,
    entityId,
    fieldName,
    branchOff,
    parentEntityId
  );

  if (
    !isEqual(sourceValue, branchOffValue) && // isEqual here instead of === because sometimes these values are arrays
    !isEqual(targetValue, branchOffValue)
  ) {
    return MergeState.NONE;
  }

  if (!isEqual(sourceValue, branchOffValue)) {
    return MergeState.SOURCE;
  }

  return MergeState.TARGET;
};
