import { actionCreator } from '@ardoq/rxbeach';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { IntegrationTermsDictionary } from './types';

const NAMESPACE = '[integrations] INTEGRATION_TERMS_DICTIONARY';

const getActionName = (name: string) => `${NAMESPACE}_${name}` as const;

export type setIntegrationTermsPayload = {
  integrationId: IntegrationId;
  terms: Partial<IntegrationTermsDictionary>;
};

export const setIntegrationTerms = actionCreator<setIntegrationTermsPayload>(
  getActionName('SET_INTEGRATION_TERMS')
);

export type unsetIntegrationTermsPayload = {
  integrationId: IntegrationId;
  keys: Array<keyof IntegrationTermsDictionary>;
};

export const unsetIntegrationTerms =
  actionCreator<unsetIntegrationTermsPayload>(
    getActionName('UNSET_INTEGRATION_TERMS')
  );

export type resetIntegrationTermsDictionaryPayload = {
  integrationId: IntegrationId;
};

export const resetIntegrationTermsDictionary =
  actionCreator<resetIntegrationTermsDictionaryPayload>(
    getActionName('RESET_INTEGRATION_TERMS_DICTIONARY')
  );
