import { Subdivision, Zone } from '@ardoq/api-types';
import subdivisions$ from './subdivisions$';
import { SubdivisionsStreamShape } from './types';
import {
  subdivisionsOperations,
  subdivisionsZonesOperations,
} from '@ardoq/subdivisions';

const getSubdivisionsStreamState = (): SubdivisionsStreamShape => {
  return subdivisions$.state;
};

const getZoneById = (zoneId: string): Zone | null => {
  return subdivisionsZonesOperations.getZoneById(
    getSubdivisionsStreamState(),
    zoneId
  );
};

const getSubdivisionById = (subdivisionId: string): Subdivision | null => {
  return subdivisionsOperations.getSubdivisionById(
    getSubdivisionsStreamState(),
    subdivisionId
  );
};

export const subdivisionsInterface = {
  getSubdivisionsStreamState,
  getZoneById,
  getSubdivisionById,
};
