import {
  DataSource,
  MergeTableSectionType,
  Path,
} from 'components/DiffMergeTable/types';
import {
  APIEntityType,
  ArdoqId,
  ScopeDataCollection,
  Verb,
} from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { getStatusFromChildren } from 'components/DiffMergeTable/getStatusFromChildren';
import {
  collectionToEntityType,
  getStructuralConflict,
  hasMergePermission,
  recursivelySortHierarchy,
} from '../utils';
import {
  getEntitiesFromEnhancedDiffContext,
  getHasTypeConflict,
  getHeader,
  getIndexer,
  getMissingComponentConflict,
  getPreselectionStatus,
  getSkipSectionHeader,
  getSubHeader,
  propagatePreselectionToAncesotrsOrDescendants,
} from './toDataSourceHelpers';
import {
  getSectionHeader,
  groupEntityIdsBySections,
} from './sectionHeaderUtils';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { Branch } from 'components/DiffMergeTable/Branch';

export const toDataSourceCreatedOrDeleted = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  basePath: Path
): DataSource => {
  const [collection, verb] = basePath;
  const entities = getEntitiesFromEnhancedDiffContext(
    enhancedDiffContextData,
    collection,
    verb
  );
  if (!entities) return [];
  const entityType = collectionToEntityType[collection];
  const sectionGroups = groupEntityIdsBySections({
    entityUniqueIds: Object.keys(entities),
    entityType,
    enhancedDiffContextData,
  });

  const getIndex = getIndexer();
  const rootIndex = getIndex();
  const rootChildren: number[] = [];

  const entityRows = Object.entries(sectionGroups).flatMap(
    ([_sectionType, sectionEntityIds]) => {
      if (sectionEntityIds.length === 0) return [];
      // a bit ugly type casting
      const sectionType: MergeTableSectionType =
        _sectionType as unknown as MergeTableSectionType;
      const skipSectionHeader = getSkipSectionHeader(verb, sectionType);
      const sectionChildren: number[] = [];
      const sectionHeaderIndex = skipSectionHeader ? -1 : getIndex();
      if (!skipSectionHeader) rootChildren.push(sectionHeaderIndex);

      const sectionRows = (
        collection === ScopeDataCollection.COMPONENTS
          ? sortComponentsByHierarchy(
              sectionEntityIds,
              enhancedDiffContextData[
                verb === Verb.CREATE ? Branch.SOURCE : Branch.TARGET
              ]
            )
          : sectionEntityIds
      ).map(entityId => {
        const entityRowIndex = getIndex();
        sectionChildren.push(entityRowIndex);
        const hasTypeConflict = getHasTypeConflict(
          entityType,
          entityId,
          verb,
          enhancedDiffContextData
        );

        const missingComponentConflict =
          verb === Verb.CREATE && entityType === APIEntityType.REFERENCE
            ? getMissingComponentConflict(entityId, enhancedDiffContextData)
            : null;

        const structuralConflict = getStructuralConflict(
          entityId,
          entityType,
          enhancedDiffContextData
        );

        const hasWritePermission = hasMergePermission(
          entityId,
          entityType,
          enhancedDiffContextData
        );

        return getSubHeader({
          entityType,
          entityId,
          basePath,
          enhancedDiffContextData,
          parent: skipSectionHeader ? rootIndex : sectionHeaderIndex,
          verb,
          index: entityRowIndex,
          status: getPreselectionStatus({
            entityType,
            entityId,
            verb,
            enhancedDiffContextData,
            missingComponentConflict,
            hasTypeConflict,
            structuralConflict,
            sectionType,
            hasWritePermission,
          }),
          hasTypeConflict,
          missingComponentConflict,
          structuralConflict,
          sectionType,
          hasWritePermission,
        });
      });

      if (skipSectionHeader) rootChildren.push(...sectionChildren);

      return [
        ...(sectionType !== MergeTableSectionType.DEFAULT &&
        !skipSectionHeader &&
        sectionChildren.length
          ? [
              getSectionHeader({
                sectionType,
                entityType,
                index: sectionHeaderIndex,
                children: sectionChildren,
                enhancedDiffContextData,
                basePath,
                parent: rootIndex,
                status: getStatusFromChildren(
                  sectionRows,
                  sectionRows![0].status
                ),
                isDisabled: sectionRows.every(row => row.isDisabled),
              }),
            ]
          : []),
        ...sectionRows,
      ];
    }
  );

  const dataSource = entityRows.length
    ? [
        getHeader({
          entityType,
          basePath,
          enhancedDiffContextData,
          rootIndex,
          rootChildren,
          status: getStatusFromChildren(entityRows, entityRows![0].status),
          isDisabled: entityRows.every(row => row.isDisabled),
        }),
        ...entityRows,
      ]
    : [];

  return propagatePreselectionToAncesotrsOrDescendants(dataSource);
};

const sortComponentsByHierarchy = (
  componentIds: ArdoqId[],
  enhancedScopeData: EnhancedScopeData
) => {
  const componentIdSet = new Set(componentIds);
  const componentHierarchy = componentIds.reduce<Record<ArdoqId, ArdoqId[]>>(
    (hierarchy, componentId) => {
      const parentId = enhancedScopeData.componentsById[componentId].parent;
      const key =
        !parentId || !componentIdSet.has(parentId) ? 'root' : parentId;

      if (!hierarchy[key]) {
        hierarchy[key] = [componentId];
      } else {
        hierarchy[key].push(componentId);
      }
      return hierarchy;
    },
    {}
  );

  return (
    componentHierarchy.root?.flatMap(componentId =>
      recursivelySortHierarchy(
        componentId,
        componentHierarchy,
        APIEntityType.COMPONENT
      )
    ) ?? componentIds
  );
};
