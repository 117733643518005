import { reducer } from '@ardoq/rxbeach';
import { ApiState, ResourcePermissionsState } from './types';
import {
  ResourcePermissionMetaPayload,
  apiPutResourcePermission,
  openResourcePermissionDialog,
  resourcePermissionError,
  setResourcePermissions,
} from './actions';
import { APIResourcePermissionAttributes } from '@ardoq/api-types';

export const defaultState: ResourcePermissionsState = {
  apiState: ApiState.READY,
  byId: {},
  resourceNames: [],
};

const onApiCallResourcePermission = (prev: ResourcePermissionsState) => ({
  ...prev,
  apiState: ApiState.LOADING,
});

const onSetResourcePermissions = (
  prev: ResourcePermissionsState,
  resourcePermissions: APIResourcePermissionAttributes[]
) => ({
  ...prev,
  byId: {
    ...prev.byId,
    ...resourcePermissions.reduce(
      (acc, item) => ({ ...acc, [item._id]: item }),
      prev.byId
    ),
  },
  apiState: ApiState.SUCCESS,
});

const onInitialLoad = (
  prev: ResourcePermissionsState,
  { resources }: ResourcePermissionMetaPayload
) => ({
  ...prev,
  byId: {},
  apiState: ApiState.LOADING_INIT,
  resourceNames: resources.map(({ resourceName }) => resourceName),
});

const onResourcePermissionError = (
  prev: ResourcePermissionsState,
  _error: string
) => ({
  ...prev,
  apiState: ApiState.ERROR,
});

export const resourcePermissionReducers = [
  reducer(apiPutResourcePermission, onApiCallResourcePermission),
  reducer(setResourcePermissions, onSetResourcePermissions),
  reducer(resourcePermissionError, onResourcePermissionError),
  reducer(openResourcePermissionDialog, onInitialLoad),
];
