import {
  addFieldByName,
  addFieldNewToWorkspace,
  addWorkspaceField,
  addWorkspaceFieldReducer,
  createFieldByLabel,
  createOrUpdateFieldInGridEditor,
  closeGridEditorFieldWorkflow,
} from 'appModelStateEdit/propertiesEditor/addField/gridEditor/actions';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import globalFields$ from 'globalFields/globalFields$';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  dispatchAction,
  routine,
  collectRoutines,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { OLD_DEFAULT_FIELD_ORDER } from 'models/consts';
import { logWarn } from '@ardoq/logging';
import {
  beginGridEditorCreateFieldWorkflow,
  beginGridEditorEditFieldWorkflow,
} from 'appModelStateEdit/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { APIEntityType } from '@ardoq/api-types';
import {
  createField,
  createFieldFromAttributes,
  saveFieldAttributes,
} from 'appModelStateEdit/persistenceUtils';
import { trackEvent } from 'tracking/tracking';
import { splitDateRangeFieldIntoDateTimeFields } from 'appModelStateEdit/propertiesEditor/fieldUtils';
import { ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES } from 'appModelStateEdit/consts';
import { getErrorMessages } from 'authentication/errorUtil';
import { setFormError } from 'appModelStateEdit/propertiesEditor/actions';
import { fieldUtils } from '@ardoq/scope-data';

const handleAddFieldByName = routine(
  ofType(addFieldByName),
  extractPayload(),
  withLatestFrom(appModelStateEdit$, globalFields$),
  tap(([{ name, fieldContext }, { enhancedScopeData }, { globalFields }]) => {
    if (!enhancedScopeData) {
      return;
    }
    const workspaceField = enhancedScopeData.fields.find(
      field => field.name === name
    );
    if (workspaceField) {
      if (fieldContext.entityType === APIEntityType.COMPONENT) {
        return dispatchAction(
          addWorkspaceField({
            ...workspaceField,
            componentType: Array.from(
              new Set([
                ...workspaceField.componentType,
                ...fieldContext.componentType,
              ])
            ),
          })
        );
      }
      return dispatchAction(
        addWorkspaceField({
          ...workspaceField,
          referenceType: Array.from(
            new Set([
              ...workspaceField.referenceType,
              ...fieldContext.referenceType,
            ])
          ),
        })
      );
    }
    const field = globalFields.find(currentField => currentField.name === name);
    if (!field) {
      return;
    }
    dispatchAction(
      addFieldNewToWorkspace({
        ...field,
        _order: OLD_DEFAULT_FIELD_ORDER,
        global: false,
        globalref: false,
        componentType:
          fieldContext.entityType === APIEntityType.COMPONENT
            ? fieldContext.componentType
            : [],
        referenceType:
          fieldContext.entityType === APIEntityType.REFERENCE
            ? fieldContext.referenceType
            : [],
        model: fieldContext.modelId,
        name,
      })
    );
  })
);

const handleAddWorkspaceField = routine(
  ofType(addWorkspaceField),
  extractPayload(),
  tap(field => {
    dispatchAction(
      beginGridEditorEditFieldWorkflow({
        type: GetContentOptionsType.FIELD_EDITOR,
        field: { id: field._id },
      })
    );
    dispatchAction(addWorkspaceFieldReducer(field));
  })
);

const handleAddFieldNewToWorkspace = routine(
  ofType(addFieldNewToWorkspace),
  extractPayload(),
  tap(async field => {
    const fieldsToCreate = [field].flatMap(
      splitDateRangeFieldIntoDateTimeFields
    );

    try {
      const createdFields = await Promise.all(
        fieldsToCreate.map(
          createFieldFromAttributes(ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES)
        )
      );

      dispatchAction(
        beginGridEditorEditFieldWorkflow({
          type: GetContentOptionsType.FIELD_EDITOR,
          field: { id: createdFields[0]._id },
        })
      );
    } catch (err) {
      const errorMessage = getErrorMessages[err as string] ?? err;
      dispatchAction(setFormError(errorMessage));
    }
  })
);

const handleCreateFieldByLabel = routine(
  ofType(createFieldByLabel),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(
    ([
      { label, fieldContext },
      { entityIDs, entityType, enhancedScopeData },
    ]) => {
      if (!enhancedScopeData) {
        return;
      }
      const modelId = fieldContext.modelId;
      const typeIDs =
        fieldContext.entityType === APIEntityType.COMPONENT
          ? fieldContext.componentType
          : fieldContext.referenceType;
      if (!modelId || !typeIDs) {
        logWarn(
          new Error(
            `No model or typeId found for entity IDs: ${entityIDs.toString()} of entity type: ${entityType}`
          )
        );
        return;
      }
      const entityTypeKey =
        fieldContext.entityType === APIEntityType.COMPONENT
          ? 'componentType'
          : 'referenceType';
      dispatchAction(
        beginGridEditorCreateFieldWorkflow({
          type: GetContentOptionsType.CREATE_FIELD,
          label,
          attributes: {
            [entityTypeKey]: typeIDs,
          },
        })
      );
    }
  )
);

const handleCreateOrUpdateFieldInGridEditor = routine(
  ofType(createOrUpdateFieldInGridEditor),
  withLatestFrom(appModelStateEdit$),
  tap(async ([, { entityIDs, enhancedScopeData, options }]) => {
    if (!enhancedScopeData) return;
    if (options.type === GetContentOptionsType.CREATE_FIELD) {
      trackEvent('Created Field In Grid Editor', {
        fieldType: fieldUtils.getFieldData(enhancedScopeData, entityIDs[0])
          .type,
      });
      await createField(entityIDs[0], enhancedScopeData);
      dispatchAction(closeGridEditorFieldWorkflow());
      return;
    }
    trackEvent('Updated Field In Grid Editor');
    await saveFieldAttributes(entityIDs[0], enhancedScopeData);
    dispatchAction(closeGridEditorFieldWorkflow());
  })
);

export default collectRoutines(
  handleAddFieldByName,
  handleAddWorkspaceField,
  handleAddFieldNewToWorkspace,
  handleCreateFieldByLabel,
  handleCreateOrUpdateFieldInGridEditor
);
