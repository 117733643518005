import { reducer } from '@ardoq/rxbeach';
import {
  selectReportColumn,
  selectAggregate,
  selectRuleOperator,
  selectRuleValue,
  initReportRules,
} from './actions';
import { ReportRulesSection$State } from './types';
import {
  getDefaultReportRulesState,
  isValidAggregateForColumnType,
} from './utils';
import { navigateToNewBroadcastForm } from 'router/navigationActions';
import { chooseBroadcastContentId } from 'broadcasts/actions';
import type {
  Aggregate,
  BroadcastAggregate,
  ReportColumn,
  ReportRuleOperator,
} from '@ardoq/api-types';
import { returnPayload } from '@ardoq/common-helpers';

const handleSelectColumn = (
  state: ReportRulesSection$State,
  reportColumn: ReportColumn
): ReportRulesSection$State => ({
  ...state,
  columnName: reportColumn.key,
  aggregate:
    state.aggregate &&
    isValidAggregateForColumnType(reportColumn, state.aggregate)
      ? state.aggregate
      : null,
});

const handleSelectAggregate = (
  state: ReportRulesSection$State,
  aggregate: BroadcastAggregate | Aggregate
): ReportRulesSection$State => ({
  ...state,
  aggregate: aggregate as BroadcastAggregate,
});

const handleSelectedRuleOperator = (
  state: ReportRulesSection$State,
  predicate: ReportRuleOperator
): ReportRulesSection$State => ({
  ...state,
  predicate,
});

const handleSelectedValue = (
  state: ReportRulesSection$State,
  threshold: number
): ReportRulesSection$State => ({
  ...state,
  threshold,
});

const handleChooseBroadcastContentId = (
  state: ReportRulesSection$State,
  _contentId: string
): ReportRulesSection$State => ({
  ...state,
  columnName: null,
});

export const reducers = [
  reducer(initReportRules, returnPayload<ReportRulesSection$State>),
  reducer(selectReportColumn, handleSelectColumn),
  reducer(selectAggregate, handleSelectAggregate),
  reducer(selectRuleOperator, handleSelectedRuleOperator),
  reducer(selectRuleValue, handleSelectedValue),
  reducer(navigateToNewBroadcastForm, getDefaultReportRulesState),
  reducer(chooseBroadcastContentId, handleChooseBroadcastContentId),
];
