import { Observable } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  ExcludeFalsy,
  isArdoqError,
  ArdoqError,
  SortAttribute,
} from '@ardoq/common-helpers';
import { LocallyCreatedComponentAttributes } from '@ardoq/component-interface';
import { ContextShape, DynamicFilterState } from '@ardoq/data-model';
import {
  APIWorkspaceAttributes,
  APIComponentAttributes,
  APIModelAttributes,
  APIScopeData,
  FilterAttributes,
} from '@ardoq/api-types';
import { getCurrentLocale, Locale } from '@ardoq/locale';
import { logError } from '@ardoq/logging';
import { workspaceInterface } from '@ardoq/workspace-interface';
import Components, { postBatchCreateComponents } from 'collections/components';
import Filters, { filterCache } from 'collections/filters';
import Workspaces from 'collections/workspaces';
import Context from 'context';
import Fields from 'collections/fields';
import Models from 'collections/models';
import Tags from 'collections/tags';
import References from 'collections/references';
import { documentArchiveAttachments as Attachments } from 'collections/documentArchive';
import Component from 'models/component';
import { contextInterface } from 'modelInterface/contextInterface';
import { replaceDynamicFilters } from 'streams/dynamicFilters/actions';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';

import { ComponentBackboneModel } from 'aqTypes';
import { componentInterface } from '../modelInterface/components/componentInterface';
import * as scenarioActions from '../scope/actions';
import {
  GridEditorNotifyFiltersChangePayload,
  notifyGridEditorFiltersChanged,
  notifyGridEditorFiltersLoaded,
} from './actions';
import { compareBackboneModels } from '../utils/compareUtils';
import { ScopeDataDependenciesWithScopeData } from './types';
import { componentHierarchyOperations } from './observables/componentHierarchyOperations';
import { ComponentHierarchy, ComponentSelectionConfig } from './types';
import type { DynamicAndGlobalFiltersState } from './observables/dynamicAndGlobalFilters$';
import { createComponentsForScenario } from './utils';

/**
 * Because the Grid Editor works directly with BB components it cannot use
 * ardoq-front's model interfaces.
 * This module creates a similar abstraction, but is allowed to return the
 * underlying Backbone model.
 */

const getWorkspaceComponents = (workspaceId: string) => {
  const components =
    Components.collection.getWorkspaceComponentsUnsorted(workspaceId);

  return components;
};

const getWorkspaceComponentsSorted = (args: {
  workspaceId: string;
  sort: ContextShape['sort'];
}) => {
  const { workspaceId, sort } = args;
  return getWorkspaceComponents(workspaceId).sort((compA, compB) => {
    return compareBackboneModels(compA, compB, sort);
  });
};

interface ComponentAttributesForCreation
  extends Partial<APIComponentAttributes> {
  name: string; // required
}

const initComponentAttributesByWorkspace = (
  attributes: ComponentAttributesForCreation,
  workspace: APIWorkspaceAttributes
) => {
  return componentInterface.createLocalComponent({
    ...attributes,
    rootWorkspace: workspace._id,
    model: workspace.componentModel,
  });
};

const findMax = <T>(items: T[], selector: (item: T) => number) => {
  if (items.length === 0) return 0;
  return Math.max(...items.map(selector));
};

const getComponentOrder = (component: ComponentBackboneModel) =>
  component.get(SortAttribute.ORDER) ?? 0;

/**
 * Returns the highest order of components in a workspace.
 * Note that API may set order values that are not integers, so if you are using
 * this in the front-end you may want to ceil the result.
 * NOTE: This will not work for traversal loading as we don't have all components
 */
const getHighestOrderForWorkspace = (workspaceId: string) => {
  if (!contextInterface.isContextWorkspace(workspaceId)) {
    return findMax(
      Components.collection.getWorkspaceComponents(null, workspaceId),
      getComponentOrder
    );
  }

  // Special handling for context workspace
  const selectedComponent = Context.component();

  const components = selectedComponent
    ? selectedComponent.getChildren()
    : Components.collection.getWorkspaceComponents(null, workspaceId);

  return findMax(components, getComponentOrder);
};

const setDefaultOrder = (
  attributes: ComponentAttributesForCreation,
  _order: number
) => {
  if ('_order' in attributes && typeof attributes._id === 'number') {
    return attributes;
  }
  return { ...attributes, _order };
};

const createComponentAttributesForWorkspace = (data: {
  attributes: ComponentAttributesForCreation;
  workspaceId: string;
}) => {
  const { workspaceId } = data;
  const workspace = Workspaces.collection.get(workspaceId);
  if (!workspace) return;

  const _order = getHighestOrderForWorkspace(workspaceId) + 500;
  const attributes = setDefaultOrder(data.attributes, _order);

  if (!contextInterface.isContextWorkspace(workspaceId)) {
    return initComponentAttributesByWorkspace(attributes, workspace.attributes);
  }

  // Special handling for creating component in context workspace
  const selectedComponent = contextInterface.getSelectedComponent();
  const canHaveChildren =
    selectedComponent &&
    componentInterface.canComponentHaveChildren(selectedComponent._id);

  const componentAttributes = canHaveChildren
    ? { ...attributes, parent: selectedComponent._id }
    : attributes;

  return initComponentAttributesByWorkspace(
    componentAttributes,
    workspace.attributes
  );
};

/**
 * Network race condition: If the component already exist in the collection
 * after model.save() it means the web socket update arrived first.
 * In this case we want to use the component from the collection.
 */
const getOrSetComponentModelInCollection = (
  localComponent: ComponentBackboneModel
) => {
  const persistedComponent = Components.collection.get(localComponent.getId());
  if (persistedComponent) return persistedComponent;

  // Has not been added to the collection yet, so we add it here
  Components.collection.add(localComponent);
  return localComponent;
};

const createComponentWithCompletingObservable = (
  attributes: LocallyCreatedComponentAttributes
): Observable<ComponentBackboneModel> => {
  const localComponent = Component.create(attributes);

  // Return an observable that first emits the local component, then the persisted
  // component when it returns from the server.
  return new Observable<ComponentBackboneModel>(observer => {
    observer.next(localComponent);

    localComponent
      .save()
      .then(() => {
        observer.next(getOrSetComponentModelInCollection(localComponent));
        observer.complete();
      })
      .catch((error: any) => {
        const workspaceId = attributes.rootWorkspace;
        logError(error, null, { workspaceId });
      });
  });
};

const createComponents = (
  components: LocallyCreatedComponentAttributes[],
  { scenarioId }: { scenarioId: string | null }
): Promise<Array<APIComponentAttributes | null> | ArdoqError> => {
  return scenarioId
    ? createComponentsForScenario({ components, scenarioId })
    : postBatchCreateComponents(components);
};

/**
 * Create components in batch (in scenario or not), and update the global
 * Backbone Components collection with the created components.
 */
const createComponentsAsBackboneModelsInCollection = async (
  components: LocallyCreatedComponentAttributes[],
  { scenarioId }: { scenarioId: string | null }
): Promise<Array<ComponentBackboneModel> | ArdoqError> => {
  const createdAttributes = await createComponents(components, { scenarioId });
  if (isArdoqError(createdAttributes)) {
    return createdAttributes;
  }

  return createdAttributes
    .filter(ExcludeFalsy)
    .map(attributes => Component.create(attributes, { silent: true }))
    .map(getOrSetComponentModelInCollection);
};

type workspaceReferencesArgs = {
  workspaceId?: string;
};

type WorkspaceReferencesContext =
  | { workspaceId: string; currentLocale: Locale; model: APIModelAttributes }
  | { workspaceId: undefined };

/**
 * Side-effecting getter built specifically for the workspaceReferenceTypes
 * stream because context$ does not provide model attributes nor locale.
 */
const getWorkspaceReferenceTypesContext = ({
  workspaceId,
}: workspaceReferencesArgs): WorkspaceReferencesContext => {
  const model = workspaceInterface.getModelData(workspaceId ?? '');
  if (!model) {
    return { workspaceId: undefined };
  }

  const currentLocale = getCurrentLocale();
  return {
    currentLocale,
    model,
    workspaceId,
  };
};

const getComponentId = (component: ComponentBackboneModel) => {
  return component.getId();
};

export type ComponentWithRelationships = {
  childrenIds: string[];
  component: ComponentBackboneModel;
  componentId: string;
  parentId: string;
};
const getComponentWithRelationships = (
  componentId: string
): ComponentWithRelationships | null => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return null;
  }

  return {
    childrenIds: componentInterface.getChildren(componentId),
    component,
    componentId,
    parentId: componentInterface.getParentId(componentId) || 'root',
  };
};

const isIncludedInContextByFilter = (componentId: string) => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return false;
  }
  return Filters.isIncludedInContextByFilter(component);
};

/**
 * Filter components based on global and dynamic filters.
 * Takes filters as arguments for two reasons:
 * 1. to indicate the dependency on them even if they are not used
 * 2. to trigger a re-computation when they change
 */
const applyGlobalComponentFilters = (
  components: ComponentBackboneModel[],
  _filters: {
    dynamicFilterStates: DynamicFilterState[];
    globalFilterAttributes: FilterAttributes[];
  }
) => {
  return components.filter(component => {
    return isIncludedInContextByFilter(component.getId());
  });
};

const getComponentsFromSelection = (
  componentHierarchy: ComponentHierarchy,
  {
    dynamicFilterStates,
    globalFilterAttributes,
    isShowAllComponents,
    selectedComponentId,
    sort,
  }: ComponentSelectionConfig
): ComponentBackboneModel[] => {
  const components = componentHierarchyOperations.getComponentsFromSelection(
    componentHierarchy,
    { isShowAllComponents, selectedComponentId, sort }
  );

  return gridEditorInterface.applyGlobalComponentFilters(components, {
    dynamicFilterStates,
    globalFilterAttributes,
  });
};

/**
 * Set filters and dynamic filters
 */
const setGlobalAndDynamicFilters = (
  payload: GridEditorNotifyFiltersChangePayload
) => {
  // Tests show that this is necessary to get the right result, but it has not
  // been observed in practice when using the app.
  filterCache.reset();

  // Sets all filters except dynamic filters which are in a different store
  Filters.loadFilters({
    advancedFilters: payload.filters,
    shouldTriggerChangeEvent: true,
  });

  dispatchAction(replaceDynamicFilters(payload.dynamicFilterStates));
  dispatchAction(notifyGridEditorFiltersLoaded(payload));
};

const getWorkspaceComponentHierarchy = (
  workspaceId: string
): ComponentHierarchy => {
  const components = getWorkspaceComponents(workspaceId);

  // Decompose children hierarchy and component models
  const { childIndex, componentMap, parentIndex }: ComponentHierarchy = {
    childIndex: { root: [] },
    parentIndex: {},
    componentMap: new Map(),
    components,
  };

  for (const component of components) {
    const componentId = gridEditorInterface.getComponentId(component);
    const componentRelationships =
      gridEditorInterface.getComponentWithRelationships(componentId);

    if (!componentRelationships) {
      continue;
    }

    const { childrenIds, parentId } = componentRelationships;
    componentMap.set(componentId, component);

    // Index children
    childIndex[componentId] = childrenIds;
    parentIndex[componentId] = parentId;

    // If a component does not have a parent, it means it's a child of the root
    if (parentId === 'root') {
      childIndex.root.push(componentId);
    }
  }

  return {
    childIndex,
    componentMap,
    components,
    parentIndex,
  };
};

type ResetContextUsingScopeDataExtraState = {
  dynamicAndGlobalFilters: DynamicAndGlobalFiltersState;
};
/**
 * Reset context and related backbone collections based on scope data.
 * The mental model of this function is: if scopeData is present we have received
 * a new source of truth, so reset everything and update based on that.
 */
const resetContextUsingScopeData = async (
  {
    context,
    activeScenarioState,
    scopeData,
  }: ScopeDataDependenciesWithScopeData,
  { dynamicAndGlobalFilters }: ResetContextUsingScopeDataExtraState
) => {
  const { scenarioId } = activeScenarioState;

  if (scopeData) {
    // 1. Clear context
    await Context.resetState();

    // 2. Populate state with scope data
    setScopeData(scopeData);
    dispatchAction(scenarioActions.setScenarioId({ scenarioId }));

    // When calling Context.resetState() context is cleared, and we notify the
    // entire app that all workspaces are closed; the unfortunate side-effect
    // is that the filters are also cleared, so we need to re-set them here
    // in order to maintain the flow "reset and reload all data"
    dispatchAction(
      notifyGridEditorFiltersChanged({
        dynamicFilterStates: dynamicAndGlobalFilters.dynamicFilterStates,
        filters: dynamicAndGlobalFilters.globalFilterAttributes,
      })
    );
  }

  // 3. Update the (navigator) context
  await contextInterface.setContext(context);
};

/**
 * The GridEditor reads the whole collections and is not dependent on the
 * events that these triggers could cause, so we can safely reset them silently
 * for performance reasons.
 */
const setScopeData = (scopeData: APIScopeData) => {
  Tags.collection.reset(scopeData.tags, { silent: true });
  Fields.collection.reset(scopeData.fields, { silent: true });
  Fields.collection.invalidateFieldsCache();
  Models.collection.reset(scopeData.models, { silent: true });
  Workspaces.collection.reset(scopeData.workspaces, { silent: true });
  Attachments.reset(scopeData.attachments, { silent: true });

  // Scenarios collection does not have to be loaded because the GridEditor
  // only needs to know the scenarioId if active.

  Components.collection.reset(scopeData.components, { silent: true });
  References.collection.reset(scopeData.references, { silent: true });
  References.collection.buildCacheForSourceAndTargetMaps();

  // manually set aggregateLoaded attribute and context workspaces using
  // loadedWorkspaceIds to prevent re-loading all the data
  scopeData.workspaces
    .map(workspace => Workspaces.collection.get(workspace._id))
    .filter(ExcludeFalsy)
    .forEach(workspace => {
      workspace.aggregateLoaded = true;
      dispatchAction(
        notifyWorkspaceAggregatedLoaded({ workspaceId: workspace.getId() })
      );
    });
};

const getWorkspace = (workspaceId: string) => {
  return Workspaces.collection.get(workspaceId);
};

export const gridEditorInterface = {
  applyGlobalComponentFilters,
  createComponentAttributesForWorkspace,
  getComponentId,
  getComponentWithRelationships,
  getComponentsFromSelection,
  getHighestOrderForWorkspace,
  getWorkspace,
  getWorkspaceComponents,
  getWorkspaceComponentsSorted,
  getWorkspaceReferenceTypesContext,
  isIncludedInContextByFilter,
  getWorkspaceComponentHierarchy,
  setGlobalAndDynamicFilters,
  setScopeData,
  resetContextUsingScopeData,
  createComponentWithCompletingObservable,
  createComponentsAsBackboneModelsInCollection,
};
