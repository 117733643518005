import { ExcludeFalsy } from '@ardoq/common-helpers';
import { CreatableValidator } from './types';

export const getPastedOptions = (
  pastedText: string | null,
  validator?: CreatableValidator<string> | null
) => {
  if (typeof pastedText !== 'string') {
    return [];
  }
  return pastedText
    .split(',')
    .map(str => str.trim())
    .filter(ExcludeFalsy)
    .filter(str => {
      if (!validator) {
        return str;
      }
      return validator(str);
    });
};
