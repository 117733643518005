import { batchApi } from '@ardoq/api';
import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { ArdoqError, isArdoqError, toArdoqError } from '@ardoq/common-helpers';
import { add, get } from 'collectionInterface/genericInterfaces';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from 'modelInterface/references/referenceInterface';

type WithId = { _id: ArdoqId };
type PartialEntityWithId<T> = Partial<T> & WithId;

export const batchSaveComponents = async (
  attributes: PartialEntityWithId<APIComponentAttributes>[]
): Promise<ArdoqError | void> => {
  for (const changedAttributes of attributes) {
    const component = get(APIEntityType.COMPONENT, changedAttributes._id);
    if (!component) {
      return toArdoqError({
        error: new Error(
          `Component "${changedAttributes.name}" no longer exists`
        ),
      });
    }
    componentInterface.setAttributes(changedAttributes);
  }
  componentInterface.saveAllChangedComponents(true);
};

export const batchSaveReferences = async (
  attributes: PartialEntityWithId<APIReferenceAttributes>[]
): Promise<ArdoqError | void> => {
  for (const changedAttributes of attributes) {
    const reference = get(APIEntityType.REFERENCE, changedAttributes._id);
    if (!reference) {
      return toArdoqError({
        error: new Error(
          `Reference "${changedAttributes.name}" no longer exists`
        ),
      });
    }
    referenceInterface.setAttributes(changedAttributes);
  }
  referenceInterface.saveAllChangedReferences(true);
};

export const batchCreateReferences = async (
  attributes: Partial<APIReferenceAttributes>[]
) => {
  const data = attributes.map(attrs => {
    const { _id: batchId, ...noIdAttrs } = attrs;
    return {
      ...noIdAttrs,
      batchId,
    };
  });
  const response = await batchApi.execute({
    options: { includeEntities: true },
    references: {
      create: data,
    },
  });

  if (isArdoqError(response)) {
    return response;
  }

  const resultMap = response.references.created ?? {};
  const persistedEntities = data.map(
    ({ batchId }) => resultMap[response.references.ids[batchId as string]]
  );
  add(APIEntityType.REFERENCE, persistedEntities);
  return persistedEntities;
};
