import { useRef } from 'react';
import DialogBodyBlock from './DialogBodyBlock';
import AboutScenarios from './AboutScenarios';
import { useEffectOnce } from '@ardoq/hooks';
import { SecondaryButton, PrimaryButton, ButtonGroup } from '@ardoq/button';
import { ModalFooter, ModalHeader } from '@ardoq/modal';

type SuccessStepProps = {
  onCancel: VoidFunction;
  onComplete: VoidFunction;
  addToExistingScenario?: boolean;
};

const SuccessStep = ({
  onCancel,
  onComplete,
  addToExistingScenario,
}: SuccessStepProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffectOnce(() => {
    if (!btnRef.current) return;
    btnRef.current.focus();
  });
  return (
    <>
      <ModalHeader
        title={
          addToExistingScenario
            ? 'The items have been added to the scenario successfully'
            : 'The scenario has been created successfully'
        }
        onCloseButtonClick={onCancel}
      />
      <DialogBodyBlock>
        <AboutScenarios />
      </DialogBodyBlock>
      <ModalFooter>
        <ButtonGroup>
          <SecondaryButton onClick={onCancel} data-click-id="close-scenario">
            Close
          </SecondaryButton>
          <PrimaryButton
            ref={btnRef}
            onClick={onComplete}
            data-click-id="open-scenario"
          >
            Open scenario
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default SuccessStep;
