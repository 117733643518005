import {
  ManageResourcePermissionsDialogProps,
  NewManagePermissionsDialog,
} from '@ardoq/manage-resource-permissions';
import { connect } from '@ardoq/rxbeach';
import { manageResourcePermissionViewModel$ } from './manageResourcePermissionViewModel$';

const NewManagePermissionsDialogWithContext = (
  props: ManageResourcePermissionsDialogProps
) => {
  return <NewManagePermissionsDialog {...props}></NewManagePermissionsDialog>;
};

export const ConnectedNewManagePermissionsDialog = connect(
  NewManagePermissionsDialogWithContext,
  manageResourcePermissionViewModel$
);
