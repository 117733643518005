import { PermissionContext } from '@ardoq/access-control';
import {
  APIReferenceAttributes,
  APIReferenceType,
  ArdoqId,
} from '@ardoq/api-types';
import { getFieldOptions } from 'appModelStateEdit/propertiesEditor/fieldUtils';
import {
  FieldType,
  getArrowTypeLabel,
  getReferenceTypeByReferenceId,
} from '@ardoq/renderers';
import { getCardinalityArrowTypes } from 'modelInterface/util';
import { CARDINALITY_ATTRIBUTES } from 'appModelStateEdit/propertiesEditor/referencePropertiesEditor/consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import {
  CustomPropertyOptionGetterMap,
  TypeOptionsExtraContext,
  type DefaultPropertyOptionGetterMap,
} from '../utils';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { SelectOptionsOrGroups } from '@ardoq/select';
import { partition } from 'lodash';

type ListOption = { value: string | number; label: string };

const referenceTypeToOption = (
  referenceType: APIReferenceType
): ListOption => ({
  value: referenceType.id,
  label: referenceType.name,
});

const getTypeOptions = (
  referenceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  _permissionContext: PermissionContext,
  _: SubdivisionsContext,
  extraContext?: TypeOptionsExtraContext
): SelectOptionsOrGroups<string | number> => {
  const reference = enhancedScopeDataOperations.getReference(
    enhancedScopeData,
    referenceId
  );
  const modelId = reference?.model;
  if (!reference || !modelId) {
    return [];
  }

  let availableRefTypes = extraContext?.refTypesSortedByMostUsed ?? [];
  if (!availableRefTypes.length) {
    availableRefTypes = enhancedScopeDataOperations.getModelReferenceTypes(
      enhancedScopeData,
      modelId
    );
  }

  if (!extraContext?.intendedRefTypes) {
    // Triple enforcement is not enabled
    return availableRefTypes.map(referenceTypeToOption);
  }

  const sourceType =
    enhancedScopeDataOperations.getComponent(
      enhancedScopeData,
      reference.source
    )?.type ?? '';
  const targetType =
    enhancedScopeDataOperations.getComponent(
      enhancedScopeData,
      reference.target
    )?.type ?? '';

  if (!sourceType || !targetType) {
    // Not enough information to segment the ref types
    return availableRefTypes.map(referenceTypeToOption);
  }

  const intendedRefTypeNames =
    extraContext.intendedRefTypes[sourceType]?.[targetType] || [];

  const [goodRefTypes, badRefTypes] = partition(availableRefTypes, refType =>
    intendedRefTypeNames.includes(refType.name)
  );

  return [
    {
      label: 'Supported',
      options: goodRefTypes.map(referenceTypeToOption),
    },
    {
      label: 'Other reference types',
      options: badRefTypes.map(referenceTypeToOption),
    },
  ];
};

const getSourceOptions = (
  referenceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  permissionContext: PermissionContext
) => {
  const reference = enhancedScopeData.referencesById[referenceId];
  const rootWorkspace = reference.rootWorkspace;

  return enhancedScopeData.components
    .filter(component => {
      if (component.rootWorkspace !== rootWorkspace) {
        return false;
      }
      return componentAccessControlOperation.canEditComponent({
        component,
        permissionContext,
        subdivisionsContext: subdivisionsInterface.getSubdivisionsStreamState(),
      });
    })
    .map(component => ({
      value: component._id,
      label: `${componentInterface.getFullPathName(component._id)} [${
        component.type
      }]`,
    }));
};

const getTargetOptions = (
  _entityId: ArdoqId,
  _enhancedScopeData: EnhancedScopeData,
  _permissionContext: PermissionContext
): ListOption[] => {
  return componentInterface.getAllPartial(['_id', 'type']).map(component => ({
    value: component._id,
    label: `${componentInterface.getFullPathName(component._id)} [${
      component.type
    }]`,
  }));
};

const getCardinalityOptions =
  (suffix = '') =>
  (
    _entityId: ArdoqId,
    _enhancedScopeData: EnhancedScopeData,
    _permissionContext: PermissionContext
  ): ListOption[] => {
    return getCardinalityArrowTypes().map(arrowType => ({
      value: `${arrowType}${suffix}`,
      label: getArrowTypeLabel(arrowType),
    }));
  };

export const defaultPropertyOptionGetterMap: DefaultPropertyOptionGetterMap =
  new Map([
    [FieldType.TYPE_ID_REFERENCE, getTypeOptions],
    [FieldType.SOURCE, getSourceOptions],
    [FieldType.TARGET, getTargetOptions],
    [FieldType.SOURCE_CARDINALITY, getCardinalityOptions('Start')],
    [FieldType.TARGET_CARDINALITY, getCardinalityOptions()],
  ]);

export const customPropertyOptionGetterMap: CustomPropertyOptionGetterMap =
  new Map([
    [FieldType.LIST, getFieldOptions],
    [FieldType.SELECT_MULTIPLE_LIST, getFieldOptions],
  ]);

export const isCardinalityAttributeAllowed =
  (referenceId: ArdoqId, enhancedScopeData: EnhancedScopeData) =>
  (attributeName: string) => {
    if (!CARDINALITY_ATTRIBUTES.includes(attributeName)) {
      return true;
    }
    const type = getReferenceTypeByReferenceId(referenceId, enhancedScopeData);
    return Boolean(type?.hasCardinality);
  };

export const toSourceTargetIdTuple = (
  reference: APIReferenceAttributes
): [ArdoqId, ArdoqId] => [reference.source, reference.target];

export const sourceTargetIdTupleToNameTuple = ([sourceId, targetId]: [
  ArdoqId,
  ArdoqId,
]): [string, string] => {
  return [
    componentInterface.getDisplayName(sourceId)!,
    componentInterface.getDisplayName(targetId)!,
  ];
};

export const getLastUsedTripleByReferenceId = (
  enhancedScopeData: EnhancedScopeData | null,
  referenceId: ArdoqId
) => {
  if (!enhancedScopeData) {
    return null;
  }
  const reference = enhancedScopeDataOperations.getReference(
    enhancedScopeData,
    referenceId
  );

  if (!reference) {
    return null;
  }
  const sourceType = enhancedScopeDataOperations.getComponentTypeByComponentId(
    enhancedScopeData,
    reference.source
  );
  // This can't work because the target component data is not stored in the enhancedScopeData
  // const targetType = enhancedScopeDataOperations.getComponentTypeByComponentId(
  //   enhancedScopeData,
  //   reference.target
  // );

  const targetType = componentInterface.getType(reference.target);

  if (!sourceType || !targetType) {
    return null;
  }

  return currentUserInterface.getLastUsedReferenceTypeTriple(
    sourceType,
    targetType
  );
};
