import { dispatchAction } from '@ardoq/rxbeach';
import { trackIntegrationEvent } from './actions';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';

export const emitOverviewActionEvent = (
  action: string,
  integrationId: IntegrationId
) => {
  dispatchAction(
    trackIntegrationEvent({
      integrationId,
      name: 'CLICKED_OVERVIEW_ACTION',
      metadata: { action },
    })
  );
};
