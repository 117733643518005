import { currentTimestamp, differenceInMinutes } from '@ardoq/date-time';
import { trackEvent } from '../../tracking/tracking';
import { EditorProperty } from './types';
import { debounce } from 'lodash';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';

export const trackReferenceTypeChangedByMostUsed = () =>
  trackEvent('Sidebar editor0 reference type changed to most used');

let referenceLastCreatedAt: number | null = null;
export const trackReferenceCreated = (
  isDifferentTypeThanPreviouslyCreatedReference: boolean
) => {
  const now = currentTimestamp();
  const isMoreThanFiveMinutesSinceLastReferenceWasCreated =
    !referenceLastCreatedAt ||
    differenceInMinutes(now, referenceLastCreatedAt) > 5;

  trackEvent('Sidebar editor0 reference created', {
    isDifferentTypeThanPreviouslyCreatedReference,
    isMoreThanFiveMinutesSinceLastReferenceWasCreated,
    referenceLastCreatedAt,
  });
  referenceLastCreatedAt = now;
};

const debouncedTrackEvent = debounce(trackEvent, 1000, { trailing: true });

export const trackSelectedPropertyChange = (
  customField: boolean,
  property: EditorProperty,
  trackingContext?: GetContentOptionsType
) => {
  if (!trackingContext) return;
  debouncedTrackEvent(`Sidebar editor0 ${trackingContext} property changed`, {
    name: customField ? 'custom_field' : property.name,
  });
};

export const trackFieldAddedToEntity = (
  fieldName: string,
  trackingContext?: GetContentOptionsType
) => {
  if (!trackingContext) return;
  trackEvent(`Sidebar editor0 ${trackingContext} field added`, { fieldName });
};

export const trackFieldCreatedAddedToEntity = (
  fieldLabel: string,
  trackingContext?: GetContentOptionsType
) => {
  if (!trackingContext) return;
  trackEvent(`Sidebar editor0 ${trackingContext} field created and added`, {
    fieldLabel,
  });
};

export const trackEntityTagsModified = (
  trackingContext?: GetContentOptionsType
) => {
  if (!trackingContext) return;
  trackEvent(`Sidebar editor0 ${trackingContext} tags modified`);
};
