import styled from 'styled-components';
import { Icon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';

export const SummaryTitle = styled.span`
  display: inline-block;
  margin-bottom: 8px;
`;

export const SummaryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 32px;
`;

export const SummaryIcon = styled(Icon)`
  margin-right: 8px;
  transform: translateY(4px);
  color: ${colors.grey60};
`;

export const PseudoLink = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
