import permissionGroup$ from 'admin/accessControl/PermissionGroups/streams/permissionGroups$';
import resourcePermissions$ from 'resourcePermissions/resourcePermissions$';
import { Observable, combineLatest, map } from 'rxjs';

import { currentUserPermissions$ } from 'streams/currentUserPermissions/currentUserPermissions$';
import { AppStateManageResourcePermissionContext } from '@ardoq/manage-resource-permissions';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { subdivisionsOperations } from '@ardoq/subdivisions';
import { subdivisionAccessControlInterface } from './accessControlHelpers/subdivisions';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { usersOperations } from '@ardoq/core';

export const manageResourcePermissionContext$: Observable<AppStateManageResourcePermissionContext> =
  combineLatest({
    resourcePermissions: resourcePermissions$,
    currentUserPermissionContext: currentUserPermissionContext$,
    permissionGroups: permissionGroup$,
    orgUsers: orgUsers$,
    currentUserPermissions: currentUserPermissions$,
    subdivisions: subdivisions$,
  }).pipe(
    map(
      ({
        resourcePermissions,
        currentUserPermissionContext,
        permissionGroups,
        orgUsers: { users },
        currentUserPermissions,
        subdivisions,
      }) =>
        ({
          resourcePermissions: Object.values(resourcePermissions.byId),
          currentUser: currentUserPermissionContext.user!,
          permissionGroups: Object.values(permissionGroups.groupsById),
          orgMembers: usersOperations.getOrgMembers(users),
          currentUserPermissions,
          subdivisions: subdivisionsOperations
            .getSubdivisionsList(subdivisions)
            .filter(({ _id: subdivisionId }) =>
              subdivisionAccessControlInterface.canEditSubdivision(
                currentUserPermissionContext,
                subdivisions,
                subdivisionId
              )
            ),
        }) satisfies AppStateManageResourcePermissionContext
    )
  );
