import styled from 'styled-components';

type TypeRemovalFromFieldWarningDialogContentProps = {
  readonly impactedComponentTypes: ImpactedType[];
  readonly impactedReferenceTypes: ImpactedType[];
};

export type ImpactedType = {
  readonly name: string;
  readonly count: number;
};

type TypeWarningMessageProps = {
  readonly label: string;
  readonly impactedTypes: ImpactedType[];
};

const Header = styled.p`
  font-weight: bold;
  margin-top: 20px;
`;

const TypeWarningMessage = ({
  label,
  impactedTypes,
}: TypeWarningMessageProps) => {
  if (!impactedTypes.length) {
    return null;
  }
  return (
    <>
      <Header>{label} Types</Header>
      <ul>
        {impactedTypes.map(({ name, count }) => (
          <li key={`${name}${count}`}>
            {name} ({count})
          </li>
        ))}
      </ul>
    </>
  );
};
export const TypeRemovalFromFieldWarningDialogContent = ({
  impactedComponentTypes,
  impactedReferenceTypes,
}: TypeRemovalFromFieldWarningDialogContentProps) => {
  return (
    <>
      <p>
        When you remove a field from a component or reference type, the field
        values will be deleted.
      </p>
      <p>
        {impactedComponentTypes.length || impactedReferenceTypes.length
          ? 'You will remove this field from the following:'
          : 'Would you like to proceed?'}
      </p>
      <TypeWarningMessage
        label="Reference"
        impactedTypes={impactedReferenceTypes}
      />
      <TypeWarningMessage
        label="Component"
        impactedTypes={impactedComponentTypes}
      />
    </>
  );
};
