import { derivedStream } from '@ardoq/rxbeach';
import { map } from 'rxjs/operators';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import { readRawValue } from '@ardoq/renderers';
import { APIEntityType } from '@ardoq/api-types';

const getDefaultState = () => {
  return {
    line: '',
    color: '',
    lineBeginning: '',
    lineEnding: '',
  };
};

const toReferenceTypePreviewState = ({
  entityIDs,
  enhancedScopeData,
  model,
}: AppModelStateEditStreamShape) => {
  if (!enhancedScopeData) {
    return getDefaultState();
  }
  const referenceTypeId = entityIDs[0];
  const getRawValue = (fieldName: string) =>
    readRawValue(
      APIEntityType.REFERENCE_TYPE,
      referenceTypeId,
      fieldName,
      enhancedScopeData,
      model
    );
  return {
    line: getRawValue('line'),
    color: getRawValue('color'),
    lineBeginning: getRawValue('lineBeginning'),
    lineEnding: getRawValue('lineEnding'),
  };
};

const referenceTypePreview$ = derivedStream(
  'referenceTypePreview$',
  appModelStateEdit$
).pipe(
  map(([state]) => state),
  map(toReferenceTypePreviewState)
);

export default referenceTypePreview$;
