import { useEffect, useState } from 'react';
import styled from 'styled-components';
import scenarioLoader from './scenario-loader.svg';

const DOQ_WIDTH = 202;

const Doq = styled.div`
  align-self: center;
  /* To ensure inline SVGs work in Vite, we need to wrap the URL
     in double quotes. Although this SVG is currently too large
     to be inlined, we still quote it in case the threshold
     changes in the future. */
  /* prettier-ignore */
  background-image: url("${scenarioLoader}");
  background-position: -10000px 0;
  background-repeat: no-repeat;
  background-size: auto 240px;
  height: 240px;
  width: ${DOQ_WIDTH}px;
`;

const ScenarioLoader = ({ animate }: { animate?: boolean }) => {
  const [index, setIndex] = useState((Math.random() * 15) | 0);
  useEffect(() => {
    const timeout = animate
      ? window.setTimeout(() => setIndex((index + 1) % 15), 330)
      : 0;

    return () => clearTimeout(timeout);
  });

  const style = { backgroundPosition: `-${index * DOQ_WIDTH}px 0` };
  return <Doq style={style} />;
};

export default ScenarioLoader;
