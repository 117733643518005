import { actionCreator } from '@ardoq/rxbeach';
import { APIGlobalField } from '@ardoq/api-types';

export const fetchGlobalFields = actionCreator(
  '[globalFields] FETCH_GLOBAL_FIELDS'
);

export const setGlobalFields = actionCreator<APIGlobalField[]>(
  '[globalFields] SET_GLOBAL_FIELDS'
);
