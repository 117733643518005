import { actionCreator } from '@ardoq/rxbeach';
import { ReportRulesSection$State } from './types';
import {
  Aggregate,
  BroadcastAggregate,
  ReportRuleOperator,
  ReportColumn,
} from '@ardoq/api-types';

const ACTION_PREFIX = '[reportRulesSection]';
const addPrefix = (actionName: string): `[${string}] ${string}` =>
  `${ACTION_PREFIX} ${actionName}`;

export const initReportRules = actionCreator<ReportRulesSection$State>(
  addPrefix('INIT_REPORT_RULES')
);

export const selectReportColumn = actionCreator<ReportColumn>(
  addPrefix('SELECT_REPORT_COLUMN')
);

export const selectAggregate = actionCreator<BroadcastAggregate | Aggregate>(
  addPrefix('SELECT_AGGREGATE')
);

export const selectRuleOperator = actionCreator<ReportRuleOperator>(
  addPrefix('SELECT_RULE_OPERATOR')
);

export const selectRuleValue = actionCreator<number>(
  addPrefix('SELECT_RULE_VALUE')
);
