import Models from 'collections/models';
import Tags from 'collections/tags';
import References from 'collections/references';
import Workspaces from 'collections/workspaces';
import Components from 'collections/components';
import Fields from 'collections/fields';
import { logError } from '@ardoq/logging';
import {
  APIComponentAttributes,
  APIReferenceAttributes,
  ArdoqId,
  Entity,
  getEntityType,
  isComponent,
  MetaData,
  ResourceType,
  Versioned,
  VersionedEntity,
} from '@ardoq/api-types';
import { documentArchiveAttachments } from 'collections/documentArchive';
import { documentArchiveFolders } from 'collections/documentArchive';
import Backbone from 'backbone';
import { SyncableBackboneModel } from '../aqTypes';
import { ArdoqEvent } from './types';
import * as wamp from './wamp';
import { contextInterface } from 'modelInterface/contextInterface';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { isMergeFlowActive } from 'components/DiffMergeTable/isMergeFlowActive$';

const mapOfCollections = {
  tag: Tags.collection,
  component: Components.collection,
  workspace: Workspaces.collection,
  reference: References.collection,
  field: Fields.collection,
  model: Models.collection,
  attachment: documentArchiveAttachments,
  folder: documentArchiveFolders,
  slide: undefined,
  report: undefined,
  user: undefined,
};

const getCollectionForResource = (
  resourceType: ResourceType
): Backbone.Collection<SyncableBackboneModel> | undefined => {
  if (!Object.keys(mapOfCollections).includes(resourceType)) {
    logError(Error('websockets event type not implemented'), null, {
      eventType: resourceType,
    });
    return undefined;
  }

  return mapOfCollections[resourceType as keyof typeof mapOfCollections];
};

export type Resource = {
  resourceType: ResourceType;
  resource: Entity & Versioned;
};

/**
 * Handle creation of a resource from a web socket event.
 * This is different from other creation mechanisms because we need to
 * manipulate the `model.mustBeSaved` flag.
 *
 * Create a resource and add it to the respective collection by resource type
 * @param attributes resource attributes, typed minimally because we expect it to be complete
 */
const createResource = (
  attributes: Entity & Versioned,
  collection: Backbone.Collection<SyncableBackboneModel>
): void => {
  // This will set a `model.mustBeSaved` flag due to initialization of the model
  collection.add(attributes);

  const model = collection.get(attributes._id);
  if (!model) {
    return; // just pleasing TS
  }

  // Web socket event will update parent separately if changed

  // Prevent SaveManager from trying to re-create this model
  model.trigger('sync', model);
};

/**
 * Check if a resource exists in a collection and have unsaved changes
 * associated with it.
 * This does not work for the create resource flow because the flow is:
 * 1. Create local model
 * 2. Persist it to the back-end
 * 3. Receive resource response from back-end
 * 4. Add it to local collection
 * meaning we cannot detect it in this interface until step 4.
 */
const hasUnsavedResourceInCollection = (
  model: SyncableBackboneModel | undefined
) => {
  if (!model) return false;
  const hasInFlightChange = model.isSyncInProgress ?? false;
  const hasLocalChanges = model.mustBeSaved ?? false;

  return hasInFlightChange || hasLocalChanges;
};

const isEventByCurrentUser = (
  event: ArdoqEvent<VersionedEntity & MetaData>
) => {
  try {
    const editorUserId = event.data.lastModifiedBy;
    const currentUserId = currentUserInterface.getCurrentUserAttributes()?._id;
    return editorUserId === currentUserId;
  } catch (error) {
    if (error instanceof Error) {
      logError(error, 'Unexpected error in isEventByCurrentUser', {
        _id: event.data._id,
        _version: event.data._version,
        resourceType: event['resource-type'],
      });
    }

    // Fallback option: Check if the event was triggered by the user's session.
    // This only checks if the event was triggered by this session (eg. session cookie)
    return wamp.isFromCurrentSession(event);
  }
};

/**
 * Toggle selected component if its parent changes in order to update views that
 * do not pick up on the change in parent (eg. Navigator)
 *
 * TODO: This is a HACK and is scheduled to be fixed properly in the Navigator
 * https://ardoqcom.atlassian.net/browse/ARD-18411
 */
const toggleComponentSelectionIfParentChanged = (
  attributes: APIComponentAttributes
) => {
  const model = Components.collection.get(attributes._id);
  const isSelectedComponent = contextInterface.isSelectedComponent(
    attributes._id
  );
  if (!model || !isSelectedComponent) {
    return;
  }

  const nextParent = attributes.parent;
  const prevParent = model.getParent();
  const hasChangedParent = nextParent !== prevParent;
  if (!hasChangedParent) {
    return;
  }

  model.once('change:parent', async () => {
    await contextInterface.unsetComponent();
    await contextInterface.setComponentById(attributes._id);
  });
};

/**
 * Update resource in collection in response to an external event – eg. not
 * performed by the user – and manage triggered events to avoid bad side effects.
 */
const updateResource = (
  attributes: Entity & Versioned,
  collection: Backbone.Collection<SyncableBackboneModel>
): void => {
  const model = collection.get(attributes._id);

  if (!model) {
    // This is a workaround, not a fix, main issues are field updates triggered
    // by internal field sync during merge, but it's safe to disregard model
    // updates in general here while we are in the merge flow. The backbone
    // models are not used during the merge flow. On closing the merge flow the
    // scenario gets reloaded and all relevant backbone collections get reset.
    if (!isMergeFlowActive()) {
      logError(Error('Model not found.'), null, {
        modelId: attributes._id,
        entityType: getEntityType(attributes),
      });
    }
    return;
  }

  // If the selected component changes parent we need to toggle its selection
  // in order to force re-renders of certain views (Navigator in particular)

  if (isComponent(attributes)) {
    toggleComponentSelectionIfParentChanged(attributes);
  }

  // The following will set a `model.mustBeSaved` flag due to the change event
  // which MUST be followed by a "sync" event to prevent a self-sustaining loop
  model.set(attributes);

  // `sync` must be the last event, to prevent SaveManager from saving the
  // model again, and trigger a change of metadata, leading to other clients
  // receiving the change and saving the model again over and over.
  // Model.set() and its event listeners run synchronously, so the SaveManager
  // does not get a chance to detect this flash `model.mustBeSaved = true`.
  model.trigger('sync', model);
};

const resolveReferenceSourceAndTarget = <T>(
  { source, target }: APIReferenceAttributes,
  getComponent: (id: ArdoqId) => T
) => {
  return [
    { id: source, component: getComponent(source), kind: 'source' },
    { id: target, component: getComponent(target), kind: 'target' },
  ];
};

export const syncModuleBackboneInterface = {
  createResource,
  getCollectionForResource,
  hasUnsavedResourceInCollection,
  isEventByCurrentUser,
  updateResource,
  resolveReferenceSourceAndTarget,
};
