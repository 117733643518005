import { ComponentType, useEffect } from 'react';
import { SingleValueEditorProps } from './types';
import { debounce } from 'lodash';

/**
 * Instead of passing value, we pass the default value (in a role of the default value) and then let the Component handle the state internally.
 * When we passed down value prop, users faced the problem of jittery text - some letters would be swallowed and users had to type slowly to avoid that (reproducible with throttled CPU).
 */
const DebouncedInput = (
  Component: ComponentType<Omit<SingleValueEditorProps, 'value'>>
) =>
  function DebouncedComponent({
    onValueChange,
    value,
    ...props
  }: SingleValueEditorProps) {
    const debouncedOnValueChange = debounce(onValueChange, 300);
    useEffect(() => {
      return () => debouncedOnValueChange.cancel();
    }, [onValueChange, debouncedOnValueChange]);
    return (
      <Component
        {...props}
        defaultValue={value}
        onValueChange={debouncedOnValueChange}
        onBlur={() => debouncedOnValueChange.flush()}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            debouncedOnValueChange.flush();
          }
        }}
      />
    );
  };

export default DebouncedInput;
