import {
  type ExtractPayload,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { fetchQueriesSuccess } from './actions';
import {
  type PayloadStoredQuery,
  createSearchQuerySuccess,
  deleteQuery,
  loadStoredQuery,
  saveSearchQuerySuccess,
} from 'search/actions';
import { ArdoqId, StoredQueryModel } from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';

interface StoredQueriesStateShape {
  storedQueries: StoredQueryModel[];
  storedQueriesById: Record<ArdoqId, StoredQueryModel>;
  hasFetchedQueries: boolean;
}

type StoredQueriesReducer<T> = (
  state: StoredQueriesStateShape,
  input: T
) => StoredQueriesStateShape;

const isQueryInState = (state: StoredQueriesStateShape, queryId: ArdoqId) =>
  Boolean(state.storedQueriesById[queryId]) &&
  state.storedQueries.some(existingQuery => existingQuery._id === queryId);

const addQueryReducer: StoredQueriesReducer<StoredQueryModel> = (
  state,
  query
) => ({
  ...state,
  storedQueriesById: { ...state.storedQueriesById, [query._id]: query },
  storedQueries: [query, ...state.storedQueries],
});

const updateQueryReducer: StoredQueriesReducer<StoredQueryModel> = (
  state,
  query
) => ({
  ...state,
  storedQueriesById: { ...state.storedQueriesById, [query._id]: query },
  storedQueries: state.storedQueries.map(existingQuery =>
    existingQuery._id === query._id ? query : existingQuery
  ),
});

const removeQueryReducer: StoredQueriesReducer<ArdoqId> = (state, id) => {
  const clonedStoredQueriesById = Object.assign({}, state.storedQueriesById);
  delete clonedStoredQueriesById[id];
  return {
    ...state,
    storedQueriesById: clonedStoredQueriesById,
    storedQueries: state.storedQueries.filter(
      existingQuery => existingQuery._id !== id
    ),
  };
};

const handleFetchedQueries = (
  state: StoredQueriesStateShape,
  { storedQueries }: ExtractPayload<typeof fetchQueriesSuccess>
) => ({
  ...state,
  storedQueries,
  storedQueriesById: toByIdDictionary(storedQueries),
  hasFetchedQueries: true,
});

const handleDeletedQuery = (
  state: StoredQueriesStateShape,
  { queryId: deletedQueryId }: ExtractPayload<typeof deleteQuery>
) => removeQueryReducer(state, deletedQueryId);

const handleSavedQuery = (
  state: StoredQueriesStateShape,
  { storedQuery }: PayloadStoredQuery
) => updateQueryReducer(state, storedQuery);

const handleLoadStoredQuery = (
  state: StoredQueriesStateShape,
  { storedQuery }: PayloadStoredQuery
) =>
  // We might be loading the stored query from somewhere else so we should
  // add it to the collection
  isQueryInState(state, storedQuery._id)
    ? state
    : addQueryReducer(state, storedQuery);

const handleCreatedQuery = (
  state: StoredQueriesStateShape,
  { storedQuery }: PayloadStoredQuery
) => addQueryReducer(state, storedQuery);

const reducers = [
  reducer(fetchQueriesSuccess, handleFetchedQueries),
  reducer(deleteQuery, handleDeletedQuery),
  reducer(saveSearchQuerySuccess, handleSavedQuery),
  reducer(loadStoredQuery, handleLoadStoredQuery),
  reducer(createSearchQuerySuccess, handleCreatedQuery),
];

const defaultState = {
  storedQueries: [],
  storedQueriesById: {},
  hasFetchedQueries: false,
};

const storedQueries$ = persistentReducedStream(
  'storedQueries$',
  defaultState,
  reducers
);

export default storedQueries$;
