import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { getEntityById, readRawValue } from '@ardoq/renderers';
import {
  getCommonTagNamesForEntities,
  getWorkspaceTags,
} from 'scopeData/scopeEditUtils/tags';
import { uniqBy } from 'lodash';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { PermissionContext } from '@ardoq/access-control';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import { ScenarioModeState } from 'scope/types';

export const getEntityTags = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  activeScenarioState: ScenarioModeState,
  isCreatingNewEntity: boolean
) => {
  const workspaces = entityIDs.map(entityID =>
    readRawValue(entityType, entityID, 'rootWorkspace', enhancedScopeData)
  );
  const appliedTags = getCommonTagNamesForEntities(
    entityType,
    entityIDs,
    enhancedScopeData
  );
  return {
    value: appliedTags,
    isDisabled: isDisabled(
      entityType,
      enhancedScopeData,
      entityIDs,
      workspaces,
      permissionContext,
      subdivisionsContext,
      activeScenarioState,
      isCreatingNewEntity
    ),
    options: uniqBy(
      workspaces.flatMap(workspace =>
        getWorkspaceTags(workspace, enhancedScopeData)
      ),
      'name'
    )
      .filter(({ name }) => !appliedTags.includes(name))
      .map(({ name }) => ({
        value: name,
        label: name,
      })),
  };
};

const isDisabled = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  enhancedScopeData: EnhancedScopeData,
  entityIDs: ArdoqId[],
  workspacesIds: ArdoqId[],
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  activeScenarioState: ScenarioModeState,
  isCreatingNewEntity: boolean
) => {
  if (activeScenarioOperations.isInScenarioMode(activeScenarioState)) {
    return !scenarioAccessControlInterface.canEditActiveScenario(
      permissionContext,
      activeScenarioState
    );
  }
  const canEditAllWorkspaces = workspacesIds.every(workspaceId =>
    workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      workspaceId,
      activeScenarioState
    )
  );
  if (canEditAllWorkspaces) {
    return false;
  }

  const attributes = getEntityById(entityType, entityIDs[0], enhancedScopeData);
  if (!attributes) {
    return false;
  }

  if (entityType === APIEntityType.COMPONENT) {
    // -----------------------------------------------
    // -----------------  Component  -----------------
    // -----------------------------------------------
    let componentData: APIComponentAttributes | undefined =
      attributes as APIComponentAttributes;
    const isNotRootComponent = Boolean(attributes.parent);
    if (isCreatingNewEntity && isNotRootComponent) {
      componentData = getEntityById(
        entityType,
        attributes.parent,
        enhancedScopeData
      );
    }
    if (componentData) {
      return !componentAccessControlOperation.canEditComponent({
        component: componentData,
        permissionContext,
        subdivisionsContext,
      });
    }
  } else if (entityType === APIEntityType.REFERENCE) {
    // -----------------------------------------------
    // -----------------  Reference  -----------------
    // -----------------------------------------------
    if (!isCreatingNewEntity) {
      return !referenceAccessControlOperation.canEditReference({
        reference: attributes as APIReferenceAttributes,
        permissionContext,
        subdivisionsContext,
      });
    }
    return !referenceAccessControlOperation.canEditComponentReferencesBySourceComponentId(
      attributes.source
    );
  }
  return false;
};
