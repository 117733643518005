import { flow, omit, set } from 'lodash/fp';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  pauseSchedule,
  runSchedule,
  updateSchedule,
} from 'integrations/common/streams/schedules/actions';
import { emitOverviewActionEvent } from 'integrations/common/tracking/utils';
import { startEditScheduleIntervalModal } from 'integrations/common/modals/editScheduleModal/EditScheduleModal';
import { startDeleteScheduleModal } from 'integrations/common/modals/deleteSchedule/DeleteScheduleModal';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { combineLatest, map, of, switchMap } from 'rxjs';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import { loadSchedule } from 'integrations/common/streams/schedule/actions';
import {
  getScheduleConnectionName,
  getScheduleSourceConfig,
} from 'integrations/common/streams/schedules/utils';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { IntegrationJobPayload, IntegrationSchedule } from '@ardoq/api-types';
import {
  IntegrationWorkspace,
  TransferConfig,
} from '@ardoq/api-types/integrations';
import { startSaveModal } from 'integrations/common/modals/saveModal/SaveModal';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';
import { isIntegrationWithTransferConfigs } from 'integrations/common/streams/transferConfigs/utils';
import { setVisibleAsset } from 'assets/navigation/actions';

const onEditSchedule = (integrationId: IntegrationId, scheduleId: string) => {
  emitOverviewActionEvent('schedule-edit', integrationId);
  dispatchAction(loadSchedule({ integrationId, scheduleId }));
};

const onRenameSchedule = (
  integrationId: IntegrationId,
  name: string,
  schedule: IntegrationSchedule
) => {
  dispatchAction(
    updateSchedule({
      integrationId: integrationId,
      schedule: omit('meta')({
        ...schedule,
        jobOptions: { ...schedule.jobOptions, name },
      }) as IntegrationJobPayload,
    })
  );
};

const onStartScheduleRenaming = (integrationId: IntegrationId) => () => {
  emitOverviewActionEvent('schedule-renaming', integrationId);
};

const onPauseSchedule = (integrationId: IntegrationId, scheduleId: string) => {
  emitOverviewActionEvent('schedule-pause', integrationId);
  dispatchAction(pauseSchedule(scheduleId));
};

const onRunSchedule =
  (integrationId: IntegrationId) => (scheduleId: string) => {
    emitOverviewActionEvent('schedule-import-now', integrationId);
    dispatchAction(runSchedule(scheduleId));
  };

const onStartEditInterval = (
  integrationId: IntegrationId,
  schedule: IntegrationSchedule
) => {
  emitOverviewActionEvent('schedule-edit-interval', integrationId);
  startEditScheduleIntervalModal(integrationId, schedule);
};

const onStartDeleteSchedule = (
  integrationId: IntegrationId,
  schedule: IntegrationSchedule
) => {
  emitOverviewActionEvent('schedule-delete', integrationId);
  startDeleteScheduleModal(schedule);
};

const onStartCreateConfig = (
  integrationId: IntegrationId,
  schedule: IntegrationSchedule
) => {
  if (!isJobWithTabularMapping(schedule.jobOptions)) {
    return;
  }
  const sourceConfig = getScheduleSourceConfig(schedule);

  const config = flow(
    omit(['_id', '_version', 'id', 'version', 'lastUpdated']),
    set('name', schedule.jobOptions.name),
    set('sourceConfig', sourceConfig)
  )(schedule.jobOptions.tabularMapping) as TransferConfig;

  startSaveModal({
    integrationId,
    title: 'Save as a configuration',
    isNewConfig: false,
    config,
  });
};

const onWorkspaceClick = (workspace: IntegrationWorkspace) => {
  if (workspace.id) {
    dispatchAction(
      setVisibleAsset({ assetId: workspace.id, assetType: 'workspace' })
    );
  }
};

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      of(integrationId),
      getSchedulesStream(integrationId),
      getConnectionsStream(integrationId),
      getIntegrationTermsDictionaryStream(integrationId),
    ])
  ),
  map(
    ([
      integrationId,
      { schedules, asyncStatus },
      { connections },
      integrationTermsDictionary,
    ]) => {
      return {
        schedules,
        isLoading: asyncStatus === 'LOADING',
        noSchedulesMessage: integrationTermsDictionary.noSchedulesMessage,
        onRenameSchedule: (name: string, schedule: IntegrationSchedule) =>
          onRenameSchedule(integrationId, name, schedule),
        onStartScheduleRenaming: onStartScheduleRenaming(integrationId),
        onRunSchedule: onRunSchedule(integrationId),
        onPauseSchedule: (scheduleId: string) =>
          onPauseSchedule(integrationId, scheduleId),
        onStartCreateConfig: (schedule: IntegrationSchedule) =>
          onStartCreateConfig(integrationId, schedule),
        onStartEditInterval: (schedule: IntegrationSchedule) =>
          onStartEditInterval(integrationId, schedule),
        onStartDeleteSchedule: (schedule: IntegrationSchedule) =>
          onStartDeleteSchedule(integrationId, schedule),
        onEditSchedule: (scheduleId: string) =>
          onEditSchedule(integrationId, scheduleId),
        onWorkspaceClick: onWorkspaceClick,
        getConnectionName: (schedule: IntegrationSchedule) =>
          getScheduleConnectionName(schedule, connections),
        allowedConfigurations: isIntegrationWithTransferConfigs(integrationId),
      };
    }
  )
);
