import { useState } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import { smartSort } from '@ardoq/pagination';
import {
  CellWithDropdown,
  DatasourceTable,
  newTableTheme,
  useSelectableTable,
  useSortableTable,
} from '@ardoq/table';
import {
  IntegrationWorkspace,
  SavedTransferConfig,
} from '@ardoq/api-types/integrations';
import { SortOrder } from '@ardoq/api-types';
import { getCurrentLocale } from '@ardoq/locale';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { EditableCell } from '../editableCell/EditableCell';
import { useEditableConfig } from '../editableCell/useEditableConfig';
import {
  ExpandRowButton,
  ExpandRowButtonComponent,
} from '../expandRowButton/ExpandRowButton';
import { ExpandedTransferConfig } from '../expandedTransferConfig/ExpanedTransferConfig';
import { DataSourceEmptyTable } from '@ardoq/integrations';
import { isConnectionUsedInSourceConfig } from 'integrations/common/streams/transferConfigs/utils';
import { setSelectedConfigIds } from 'integrations/common/streams/transferConfigs/actions';
import { formatDateTime, parseDate } from '@ardoq/date-time';

const ConfigName = styled.div`
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const ConfigsTable = styled(DatasourceTable<SavedTransferConfig>)`
  tr:hover ${ExpandRowButtonComponent} {
    opacity: 1;
  }
`;

type ActionItem = {
  label: string;
  onClick: (config: SavedTransferConfig) => void;
};

type TransferConfigsTableProps = {
  dataSource: SavedTransferConfig[];
  connections?: { _id: string; name: string }[];
  extraSingleItemActions?: ActionItem[];
  isLoading: boolean;
  selectedIds: string[];
  isExpandable?: boolean;
  isClickable?: boolean;
  noConfigsMessage: string;
  onConfigClick?: (config: SavedTransferConfig) => void;
  onDeleteConfigs: (configs: SavedTransferConfig[]) => void;
  onRenameConfiguration: (name: string, config: SavedTransferConfig) => void;
  onStartConfigurationRenaming?: () => void;
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
};

export const TransferConfigsTable = ({
  dataSource,
  connections = [],
  isLoading,
  selectedIds,
  extraSingleItemActions = [],
  onDeleteConfigs,
  onRenameConfiguration,
  onStartConfigurationRenaming = noop,
  isExpandable = false,
  isClickable = false,
  onConfigClick = () => {},
  onWorkspaceClick,
  noConfigsMessage,
}: TransferConfigsTableProps) => {
  const setSelectedIds = (ids: string[]) => {
    dispatchAction(setSelectedConfigIds(ids));
  };

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const { editingId, editingName, setEditingId, setEditingName, clear } =
    useEditableConfig();

  const { sortOrder, sortBy, getSortableColumnProps } = useSortableTable({
    initialSortBy: 'name',
    initialSortOrder: SortOrder.ASC,
  });

  const sortedDataSource = smartSort(dataSource, sortBy, sortOrder);

  const { rowStyle, checkboxColumnConfig } = useSelectableTable({
    selectedIds,
    setSelectedIds,
    selectableIds: sortedDataSource.map(({ _id }) => _id),
    dataIndex: '_id',
  });

  const isMultiSelect = selectedIds.length > 1;

  return (
    <>
      <ConfigsTable
        components={newTableTheme}
        dataSource={sortedDataSource}
        rowStyle={rowStyle}
        loading={isLoading}
        idIndex="_id"
        expandedRowIds={expandedRowIds}
        getExpandedRow={config => (
          <ExpandedTransferConfig
            connection={connections.find(c =>
              isConnectionUsedInSourceConfig(config, c._id)
            )}
            lastUpdated={config.lastUpdated}
            lastImported={config.lastImported}
            tables={config.tables}
            onWorkspaceClick={onWorkspaceClick}
          />
        )}
        columns={[
          checkboxColumnConfig,
          {
            title: 'Name',
            dataIndex: 'name',
            valueRender: (_, config) => {
              const { _id, name } = config;
              if (editingId === _id) {
                return (
                  <EditableCell
                    initValue={name}
                    onClear={clear}
                    onSubmit={() => {
                      if (!editingName) return;
                      onRenameConfiguration(editingName, config);
                    }}
                    onValueChange={setEditingName}
                  />
                );
              }

              if (isClickable) {
                return (
                  <ConfigName onClick={() => onConfigClick(config)}>
                    {name}
                  </ConfigName>
                );
              }

              return <div onClick={() => onConfigClick(config)}>{name}</div>;
            },
            ...getSortableColumnProps('name'),
          },
          {
            title: 'Tables',
            dataIndex: 'tables',
            ...getSortableColumnProps('tables'),
            valueRender: (_, { tables }: SavedTransferConfig) => tables.length,
          },
          {
            title: 'Last modified',
            dataIndex: 'lastUpdated',
            ...getSortableColumnProps('lastUpdated'),
            valueRender: (_, { lastUpdated }: SavedTransferConfig) => {
              const locale = getCurrentLocale();

              if (!lastUpdated) return null;

              return (
                <span data-tooltip-text={parseDate(lastUpdated).toString()}>
                  {`${formatDateTime(lastUpdated, locale)}`}
                </span>
              );
            },
          },
          ...(isExpandable
            ? [
                {
                  valueRender: (_: any, { _id }: SavedTransferConfig) => (
                    <ExpandRowButton
                      expandedRowIds={expandedRowIds}
                      setExpandedRowIds={setExpandedRowIds}
                      rowId={_id}
                    ></ExpandRowButton>
                  ),
                },
              ]
            : []),
          {
            headerStyle: {
              width: 45,
            },
            valueRender: (_, config) => (
              <CellWithDropdown
                options={[
                  ...(isMultiSelect
                    ? [
                        {
                          label: `Delete selected (${selectedIds.length})`,
                          onClick: () => {
                            const selectedConfigs = selectedIds
                              .map(id =>
                                dataSource.find(config => config._id === id)
                              )
                              .filter(ExcludeFalsy);
                            onDeleteConfigs(selectedConfigs);
                          },
                        },
                      ]
                    : [
                        ...extraSingleItemActions.map(({ label, onClick }) => ({
                          label,
                          onClick: () => {
                            onClick(config);
                          },
                        })),
                        {
                          label: 'Rename',
                          onClick: () => {
                            onStartConfigurationRenaming();
                            setEditingId(config._id);
                          },
                        },
                        {
                          label: 'Delete',
                          onClick: () => {
                            onDeleteConfigs([config]);
                          },
                        },
                      ]),
                ]}
              />
            ),
          },
        ]}
        renderEmptyTable={() =>
          !isLoading ? (
            <DataSourceEmptyTable
              title="You don't have any configurations yet"
              message={noConfigsMessage}
            />
          ) : null
        }
        scrollableSectionHeight="100%"
        fixedHeader
      />
    </>
  );
};
