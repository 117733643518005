import { defaultQueryBuilderRules } from 'search/AdvancedSearch/consts';
import { getFilterConditionByType } from 'broadcasts/utils';
import {
  AdvancedSearchFilterCondition,
  BroadcastFilterCondition,
  BroadcastFilterConditionType,
  QueryBuilderSubquery,
} from '@ardoq/api-types';

export const newAdvancedSearchCondition = (
  advancedQuery: QueryBuilderSubquery = defaultQueryBuilderRules
): AdvancedSearchFilterCondition => ({
  conditionType: BroadcastFilterConditionType.ADVANCED_SEARCH,
  advancedQuery,
});

export const getDefaultState = () => ({
  isEnabled: false,
  isChecked: false,
  isExpanded: false,
  advancedSearchCondition: newAdvancedSearchCondition(),
});

export const findAdvancedSearchCondition = (
  filterConditions: BroadcastFilterCondition[]
) => {
  return getFilterConditionByType(
    filterConditions,
    BroadcastFilterConditionType.ADVANCED_SEARCH
  );
};
