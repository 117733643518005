import { reducer } from '@ardoq/rxbeach';
import { PrivilegesStreamShape, SetPrivilegesPayload } from './types';
import { _userHasPrivilege as userHasPrivilege } from './reducerUtils';
import {
  fetchPrivileges,
  notifyFailedToFetchPrivileges,
  setPrivileges,
} from './actions';
import { PrivilegeLabel } from '@ardoq/api-types';
import { getUsersIdsByPrivilege } from './utils';

export const defaultState: PrivilegesStreamShape = {
  status: 'idle',
  configurablePrivileges: [],
  privilegesByUser: {},
  userIdsByPrivilege: {},
  currentUserCanConfigurePrivileges: false,
};

const handleSetPrivileges = (
  _state: PrivilegesStreamShape,
  {
    configurablePrivileges,
    privilegesByUser,
    currentUserId,
  }: SetPrivilegesPayload
): PrivilegesStreamShape => ({
  configurablePrivileges,
  privilegesByUser,
  status: 'success',
  userIdsByPrivilege: getUsersIdsByPrivilege(privilegesByUser),
  currentUserCanConfigurePrivileges: userHasPrivilege(
    privilegesByUser,
    currentUserId,
    PrivilegeLabel.CONFIGURE_PRIVILEGES
  ),
});

const handleFetchPrivileges = (
  state: PrivilegesStreamShape
): PrivilegesStreamShape => {
  if (state.status === 'success' || state.status === 'revalidating') {
    return { ...state, status: 'revalidating' };
  }
  return { ...state, status: 'loading' };
};

const handleNotifyFailedToFetchPrivileges = (): PrivilegesStreamShape => ({
  status: 'error',
  configurablePrivileges: [],
  privilegesByUser: {},
  userIdsByPrivilege: {},
  currentUserCanConfigurePrivileges: false,
});

export const reducers = [
  reducer(setPrivileges, handleSetPrivileges),
  reducer(fetchPrivileges, handleFetchPrivileges),
  reducer(notifyFailedToFetchPrivileges, handleNotifyFailedToFetchPrivileges),
];
