import {
  ArdoqId,
  SetCreationContextRequest,
  Subdivision,
} from '@ardoq/api-types';
import {
  SubdivisionCreationContextState,
  ResourcesShape,
  SubdivisionCreationContextEditorProperty,
} from './types';
import {
  subdivisionObjectOperations,
  SUBDIVISIONS_STRINGS,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import { SortedList, sortedListOperations } from './sortedListOperations';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';
import { ContextShape } from '@ardoq/data-model';
import { subdivisionOperations } from 'subdivisions/subdivisionOperations';
import { FieldType } from '@ardoq/renderers';

const getEmpty = (): SubdivisionCreationContextState => ({
  subdivisions: [],
  creationContext: sortedListOperations.construct([]),
  currentCreationContext: sortedListOperations.construct([]),
  status: 'ready',
  selectionErrorMessage: null,
  workspaceId: '',
});

const hasChanges = (state: SubdivisionCreationContextState): boolean => {
  return !sortedListOperations.areEqual(
    state.creationContext,
    state.currentCreationContext
  );
};

const isSubdivisionInCreationContext = (
  state: SubdivisionCreationContextState,
  subdivisionId: ArdoqId
): boolean => state.creationContext.includes(subdivisionId);

const getSubdivisions = (
  state: SubdivisionCreationContextState
): Subdivision[] => [...state.subdivisions];

const generateSetCreationContextRequestPayload = (
  state: SubdivisionCreationContextState
): SetCreationContextRequest => ({ subdivisionIds: state.creationContext });

/**
 * This function checks if the current state is invalid based on the provided subdivisions and creation context.
 *
 * An invalid state can occur in two scenarios:
 * 1. When a user deselects the last subdivision from the current creation context, leaving it empty.
 * 2. When a user switches to a workspace with fewer subdivisions, and none of the subdivisions in the previously defined creation context exist in the new workspace.
 *
 * The function constructs a sorted list of subdivision IDs and checks for an intersection with the current or new creation context.
 * If there is no intersection, it returns true, indicating an invalid state.
 */
const isInvalidState = (
  { subdivisions, currentCreationContext }: SubdivisionCreationContextState,
  newCurrentCreationContext?: SortedList<string>
): boolean => {
  const subdivisionIds = sortedListOperations.construct(
    subdivisions.map(subdivisionObjectOperations.getId)
  );
  return (
    sortedListOperations.intersection(
      subdivisionIds,
      newCurrentCreationContext || currentCreationContext
    ).length === 0
  );
};

const recoverInvalidState = (
  state: SubdivisionCreationContextState
): SubdivisionCreationContextState => {
  const creationContext = sortedListOperations.construct([
    ...state.subdivisions.map(subdivisionObjectOperations.getId),
    ...state.currentCreationContext,
  ]);
  return { ...state, creationContext, status: 'recovered' };
};

const workspaceHasChanged = (
  state: SubdivisionCreationContextState,
  context: ContextShape
): boolean => {
  return state.workspaceId !== context.workspaceId;
};

const isRecovered = (state: SubdivisionCreationContextState): boolean =>
  state.status === 'recovered';

const isReady = (state: SubdivisionCreationContextState): boolean =>
  state.status === 'ready';

const setDifferentSubdivisionCreationContext = (
  _state: SubdivisionCreationContextState,
  newState: SubdivisionCreationContextState
): SubdivisionCreationContextState => newState;

const getErrorMessage = (state: SubdivisionCreationContextState): string =>
  state.selectionErrorMessage || '';

const mustUpdateServer = (state: SubdivisionCreationContextState): boolean => {
  return (
    !!state.workspaceId &&
    (isRecovered(state) || (hasChanges(state) && isReady(state)))
  );
};

const createState = ({
  subdivisions,
  currentUser,
  permissions,
  workspace,
}: ResourcesShape): SubdivisionCreationContextState => {
  const boundedEditableSubdivisions = subdivisionsOperations
    .getSubdivisionsBoundedToWorkspace(subdivisions, workspace)
    .filter(subdivision =>
      subdivisionAccessControlInterface.canEditSubdivision(
        permissions,
        subdivisions,
        subdivisionObjectOperations.getId(subdivision)
      )
    );

  const currentCreationContext = sortedListOperations.construct(
    currentUser.subdivisionCreationContext
  );

  const newState = {
    ...getEmpty(),
    subdivisions: boundedEditableSubdivisions,
    currentCreationContext,
    creationContext: currentCreationContext,
    setCreationContextRequestPayload: null,
    selectionErrorMessage: null,
    workspaceId: workspace._id,
  };

  if (isInvalidState(newState)) return recoverInvalidState(newState);
  return newState;
};

const resetToInitialStateReducer = (
  state: SubdivisionCreationContextState
): SubdivisionCreationContextState => ({
  ...state,
  creationContext: sortedListOperations.clone(state.currentCreationContext),
  status: 'ready',
  selectionErrorMessage: null,
});

const setCurrentCreationContextReducer = (
  state: SubdivisionCreationContextState,
  currentCreationContext: ArdoqId[]
): SubdivisionCreationContextState => {
  const sortedCurrentCreationContext = sortedListOperations.construct(
    currentCreationContext
  );
  return {
    ...state,
    currentCreationContext: sortedCurrentCreationContext,
    creationContext: sortedCurrentCreationContext,
    selectionErrorMessage: null,
    status: 'ready',
  };
};

const setCreationContextSelectionReducer = (
  state: SubdivisionCreationContextState,
  {
    creationContextSelection,
    errorMessage = SUBDIVISIONS_STRINGS.CREATION_CONTEXT_EDITOR.SELECTION_ERROR,
  }: { creationContextSelection: ArdoqId[]; errorMessage?: string }
): SubdivisionCreationContextState => {
  const errorPartial =
    creationContextSelection.length === 0
      ? {
          selectionErrorMessage: errorMessage,
          status: 'error' as SubdivisionCreationContextState['status'],
        }
      : {
          selectionErrorMessage: null,
          status: 'ready' as SubdivisionCreationContextState['status'],
        };
  const creationContext = sortedListOperations.union(
    sortedListOperations.difference(
      state.creationContext,
      state.subdivisions.map(subdivisionObjectOperations.getId)
    ),
    creationContextSelection
  );
  return {
    ...state,
    ...errorPartial,
    creationContext,
  };
};

const setSelectionErrorMessageReducer = (
  state: SubdivisionCreationContextState,
  selectionErrorMessage: string | null
): SubdivisionCreationContextState => ({
  ...state,
  selectionErrorMessage,
  status: selectionErrorMessage ? 'error' : 'ready',
});

const generateEditorProperty = (
  state: SubdivisionCreationContextState,
  label = SUBDIVISIONS_STRINGS.CREATION_CONTEXT_EDITOR.HEADER
): SubdivisionCreationContextEditorProperty => {
  const subdivisions =
    subdivisionCreationContextOperations.getSubdivisions(state);
  const options = subdivisions.map(subdivisionOperations.convertToSelectOption);
  const defaultValue = subdivisions
    .map(subdivisionObjectOperations.getId)
    .filter(subdivisionId =>
      subdivisionCreationContextOperations.isSubdivisionInCreationContext(
        state,
        subdivisionId
      )
    );
  const res: SubdivisionCreationContextEditorProperty = {
    type: FieldType.SUBDIVISION_CREATION_CONTEXT,
    name: label,
    label,
    options,
    value: defaultValue,
    defaultValue,
    isDirty: false,
    isReadOnly: false,
    onValueChange: _value => {},
    errorMessages: [getErrorMessage(state)].filter(Boolean),
  };
  return res;
};

const replaceState = (
  _state: SubdivisionCreationContextState,
  newState: SubdivisionCreationContextState
): SubdivisionCreationContextState => newState;

export const subdivisionCreationContextOperations = {
  getEmpty,
  hasChanges,
  isSubdivisionInCreationContext,
  getSubdivisions,
  isInvalidState,
  recoverInvalidState,
  workspaceHasChanged,
  isRecovered,
  isReady,
  setDifferentSubdivisionCreationContext,
  createState,
  getErrorMessage,
  mustUpdateServer,
  generateSetCreationContextRequestPayload,
  resetToInitialStateReducer,
  setCurrentCreationContextReducer,
  setCreationContextSelectionReducer,
  setSelectionErrorMessageReducer,
  generateEditorProperty,
  replaceState,
};
