import { connect } from '@ardoq/rxbeach';
import referenceTypePreview$ from 'appModelStateEdit/propertiesEditor/referenceTypePreview/referenceTypePreview$';
import { ReferenceTypePreviewProps } from 'appModelStateEdit/propertiesEditor/referenceTypePreview/types';
import { ReferenceTypePreview as ReferenceTypePreviewSVG } from '@ardoq/renderers';

const ReferenceTypePreview = ({
  line,
  color,
  lineBeginning,
  lineEnding,
}: ReferenceTypePreviewProps) => {
  return (
    <ReferenceTypePreviewSVG
      width={65}
      height={24}
      line={line}
      color={color}
      lineBeginning={lineBeginning}
      lineEnding={lineEnding}
    />
  );
};

export default connect(ReferenceTypePreview, referenceTypePreview$);
