import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import {
  ModifySimplePropertyPayload,
  ModifyTagsPayload,
  TagNamesPayload,
  addNewTags,
  clearFormError,
  modifySimpleProperty,
  modifyTags,
  removeNewTags,
  setAttributeDirty,
  setFormError,
} from 'appModelStateEdit/propertiesEditor/actions';
import { updateScopeDataProperties } from 'appModelStateEdit/reducerUtils';
import { reducer } from '@ardoq/rxbeach';
import componentPropertiesEditorReducers from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/reducers';
import addFieldToEntityReducers from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/reducers';
import addFieldToGridEditorReducers from 'appModelStateEdit/propertiesEditor/addField/gridEditor/reducers';
import {
  addNewTagsToScopeData,
  applyTagsToEntities,
  removeNewTagsFromScopeData,
} from 'appModelStateEdit/propertiesEditor/reducerUtils';

const modifySimplePropertyReducer = (
  state: AppModelStateEditStreamShape,
  { propertyName, newValue }: ModifySimplePropertyPayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  return {
    ...state,
    enhancedScopeData: updateScopeDataProperties(
      state.entityType,
      state.entityIDs,
      state.enhancedScopeData,
      { [propertyName]: newValue },
      state.model
    ),
  };
};

const modifyTagsReducer = (
  state: AppModelStateEditStreamShape,
  currentTags: ModifyTagsPayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  const newScopeData = applyTagsToEntities(
    state.entityType,
    state.entityIDs,
    state.enhancedScopeData,
    currentTags
  );
  return {
    ...state,
    enhancedScopeData: newScopeData,
  };
};

const addNewTagsReducer = (
  state: AppModelStateEditStreamShape,
  tagNamesToAdd: TagNamesPayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  const newScopeData = addNewTagsToScopeData(
    state.entityType,
    state.entityIDs,
    state.enhancedScopeData,
    tagNamesToAdd
  );
  const addedTags = new Set([...state.addedTags, ...tagNamesToAdd]);
  return {
    ...state,
    addedTags,
    enhancedScopeData: newScopeData,
  };
};

const removeNewTagsReducer = (
  state: AppModelStateEditStreamShape,
  tagNamesToRemove: TagNamesPayload
) => {
  if (!state.enhancedScopeData) {
    return { ...state };
  }
  const newScopeData = removeNewTagsFromScopeData(
    state.enhancedScopeData,
    tagNamesToRemove
  );
  const addedTags = new Set(state.addedTags);
  tagNamesToRemove.forEach(tagName => addedTags.delete(tagName));
  return {
    ...state,
    addedTags,
    enhancedScopeData: newScopeData,
  };
};

const setFormErrorReducer = (
  state: AppModelStateEditStreamShape,
  errorMessage: string
): AppModelStateEditStreamShape => {
  return {
    ...state,
    errorMessage,
  };
};

const clearFormErrorReducer = (
  state: AppModelStateEditStreamShape
): AppModelStateEditStreamShape => {
  return {
    ...state,
    errorMessage: null,
  };
};

const setAttributeDirtyReducer = (
  state: AppModelStateEditStreamShape,
  attributeName: string
) => {
  if (state.dirtyAttributes.has(attributeName)) {
    return state;
  }
  return {
    ...state,
    dirtyAttributes: new Set([attributeName, ...state.dirtyAttributes]),
  };
};

const reducers = [
  reducer(modifySimpleProperty, modifySimplePropertyReducer),
  reducer(modifyTags, modifyTagsReducer),
  reducer(addNewTags, addNewTagsReducer),
  reducer(removeNewTags, removeNewTagsReducer),
  reducer(setFormError, setFormErrorReducer),
  reducer(clearFormError, clearFormErrorReducer),
  reducer(setAttributeDirty, setAttributeDirtyReducer),
  ...componentPropertiesEditorReducers,
  ...addFieldToEntityReducers,
  ...addFieldToGridEditorReducers,
];
export default reducers;
