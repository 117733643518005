import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Backbone from 'backbone';
import AQ from 'ardoq';
import NodeModel from 'models/node';
import Fields from 'collections/fields';
import Filters, { currentFilterColor } from 'collections/filters';
import * as encodingUtils from '@ardoq/html';
import Models from 'collections/models';
import { getIcon } from '@ardoq/icons';
import { ComponentRepresentation } from '@ardoq/renderers';
import { getIn } from 'utils/collectionUtil';
import { getSanitizedRgbaClassName } from '@ardoq/color-helpers';
import { classes, SortAttribute } from '@ardoq/common-helpers';
import logMissingModel from './logMissingModel';
import { getApiUrl } from 'backboneExtensions';
import { getModelUrl } from 'models/utils/scenarioUtils';
import { logWarn } from '@ardoq/logging';
import { CollectionView } from 'collections/consts';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { RIGID_TYPE_WARNING_MSG } from 'models/consts';
import {
  LI_BACKGROUND_CLASS_NAME,
  NO_FILTER_CLASS_NAME,
} from '@ardoq/global-consts';
import { getImageSelectList_DEPRECATED } from 'icons/images';
import { ComponentBackboneModel, ImageListItem } from 'aqTypes';
import type { Components } from 'collections/components';
import { ModelType } from './ModelType';
import { GetCssClassNamesOption } from '@ardoq/data-model';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyComponentLocked,
  notifyComponentUnlocked,
} from 'streams/components/ComponentActions';
import BasicModel from 'models/basicmodel';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { each, forEach } from 'lodash';
import { currentDate, currentTimestamp } from '@ardoq/date-time';

interface PossibleParentsListItem {
  val: null | string;
  label: string;
}

const _setDefaultOrder = (comp: ComponentBackboneModel) => {
  // Order is set by the API on sync, but we want to render it before
  // So we should give it the highest order among its siblings upon initialization
  if (!comp.get(SortAttribute.ORDER)) {
    let lastOrder = 0;
    const parentComponent = comp.getParent();
    const siblings = parentComponent
      ? parentComponent.getChildren()
      : AQ.globalComponents.getWorkspaceComponents(
          null,
          comp.get('rootWorkspace')
        );

    (siblings || []).forEach(siblingComp => {
      if (siblingComp.get(SortAttribute.ORDER) > lastOrder) {
        lastOrder = siblingComp.get(SortAttribute.ORDER);
      }
    });
    comp.set(SortAttribute.ORDER, lastOrder + 1);
  }
};

const getComponentIndentString = (comp: ComponentBackboneModel) =>
  '-'.repeat(comp.getLevel());

const Component = NodeModel.model.extend({
  EVENT_FILTERED_CHANGED: 'filteredChanged',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/component`,
  url: function (this: ComponentBackboneModel) {
    return getModelUrl(this.urlRoot, this.isNew(), this.id);
  },
  mustBeSaved: false,
  validationErrors: null,
  getIncomingReferenceCount: function (this: ComponentBackboneModel) {
    return (this.get('ardoq') || {}).incomingReferenceCount || 0;
  },
  getOutgoingReferenceCount: function (this: ComponentBackboneModel) {
    return (this.get('ardoq') || {}).outgoingReferenceCount || 0;
  },
  getReferenceCount: function (this: ComponentBackboneModel) {
    return this.getIncomingReferenceCount() + this.getOutgoingReferenceCount();
  },
  getPossibleTypes: function (
    this: ComponentBackboneModel,
    callback?: (
      values: { val: string; label: string; dataContent?: string }[]
    ) => void
  ) {
    let values;
    const children = this.getChildren();
    const model = this.getMyModel();
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'component',
      });
      return [];
    } else if (model.isFlexible() || children.length === 0) {
      const types = Object.values(model.getComponentTypes(this));
      values = types.map(type => ({
        val: type.id,
        label: type.name,
        dataContent: encodingUtils.escapeHTML(type.name),
      }));
    } else {
      values = [
        {
          val: this.getTypeId(),
          label: RIGID_TYPE_WARNING_MSG,
        },
      ];
    }
    if (callback) callback(values);
    return values;
  },

  getPossibleParents: function (
    this: ComponentBackboneModel,
    callback?: (values: PossibleParentsListItem[]) => void
  ) {
    const values: PossibleParentsListItem[] = [
      {
        val: null,
        label: 'None',
      },
    ];

    const permissionContext = currentUserInterface.getPermissionContext();
    const subdivisionsContext =
      subdivisionsInterface.getSubdivisionsStreamState();

    const children = this.getChildren();
    const childIds = this.findChildrenIds();
    const model = this.getMyModel();
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'component',
      });
      if (callback) {
        callback(values);
      }
      return values;
    }
    if (model.isFlexible()) {
      AQ.globalComponents.each(comp => {
        const canEditComponent =
          componentAccessControlOperation.canEditComponent({
            component: comp.attributes,
            permissionContext,
            subdivisionsContext,
          });
        if (
          comp.canHaveChildren() &&
          !childIds?.includes(comp.getId()) &&
          comp.getId() !== this.id &&
          comp.attributes.rootWorkspace === this.attributes.rootWorkspace &&
          canEditComponent
        ) {
          values.push({
            val: comp.getId(),
            label: getComponentIndentString(comp) + comp.get('name'),
          });
        }
      });
    } else if (children.length === 0) {
      each(
        (
          (this.collection as Components) || AQ.globalComponents
        ).getComponentHierarchy(this),
        comp => {
          const canEditComponent =
            componentAccessControlOperation.canEditComponent({
              component: comp.attributes,
              permissionContext,
              subdivisionsContext,
            });
          if (
            comp.canHaveChildren() &&
            comp.getId() !== this.id &&
            comp.attributes.rootWorkspace === this.attributes.rootWorkspace &&
            canEditComponent
          ) {
            values.push({
              val: comp.getId(),
              label: getComponentIndentString(comp) + comp.get('name'),
            });
          }
        }
      );
    } else {
      each(
        (
          (this.collection as Components) || AQ.globalComponents
        ).getComponentHierarchy(this),
        comp => {
          const canEditComponent =
            componentAccessControlOperation.canEditComponent({
              component: comp.attributes,
              permissionContext,
              subdivisionsContext,
            });
          if (
            comp.canHaveChildren() &&
            comp.getId() !== this.id &&
            !childIds?.includes(comp.getId()) &&
            comp.attributes.rootWorkspace === this.attributes.rootWorkspace &&
            canEditComponent
          ) {
            const childTypes = (comp.getMyType() as ModelType).children;
            for (const childName in childTypes) {
              if ((childName && childTypes[childName]) === this.getMyType()) {
                values.push({
                  val: comp.getId(),
                  label: getComponentIndentString(comp) + comp.get('name'),
                });
              }
            }
          }
        }
      );
    }
    if (callback) {
      callback(values);
    }
    return values;
  },
  findChildrenIds: function (this: ComponentBackboneModel) {
    if (!this.getChildren || this.getChildren().length === 0) {
      return;
    }
    const childIds: string[] = [];
    forEach(this.getChildren(), child => {
      childIds.push(child.getId());
      const newChildIds = child.findChildrenIds();
      if (newChildIds) {
        childIds.push(...newChildIds);
      }
    });
    return childIds;
  },
  getPossibleChildrenTypes: function (this: ComponentBackboneModel) {
    const model = this.getMyModel()!;
    if (model.isFlexible()) {
      return model.getAllTypes();
    }
    return (this.getMyType() as ModelType).children;
  },

  getImageList: function (
    this: ComponentBackboneModel,
    callback: (values: ImageListItem[]) => void
  ) {
    const documentArchiveImages =
      documentArchiveInterface.getDocumentArchiveImages({
        wsId: this.get('rootWorkspace'),
      });
    const options = getImageSelectList_DEPRECATED(documentArchiveImages, null);
    callback?.(options);
    return options;
  },

  defaults: {
    name: '',
    description: '',
    parent: null,
    'last-updated': currentDate(),
    typeId: null,
    rootWorkspace: null,
    type: null,
    model: null,
  },

  initialize: function (
    this: ComponentBackboneModel,
    attributes: { type?: string; typeId?: string } = {}
  ) {
    const typeObj =
      attributes.type &&
      !attributes.typeId &&
      this.getPossibleTypes().find(({ label }) => label === attributes.type);

    if (typeObj) {
      this.set('typeId', typeObj.val);
      this.set('type', typeObj.label);
    }

    NodeModel.model.prototype.initialize.apply(this);

    if (this.attributes._id && !this.attributes.typeId) {
      // THE COMPONENT SHOULD BE LOADED BY FETCH, PROBABLY LOADED FROM REFERENCE
      // INIT MUST BE CALLED AFTER THE SYNC.
      this.once('sync', () => this.initialize());
      return this;
    }

    this.on('change:parent', this.handleChangeParent);

    this.mustBeSaved = this.isNew();
    this.on('change', (model: ComponentBackboneModel) => {
      model.lastChangeTime = currentTimestamp();
      model.validationErrors = null;

      const isVersionUnchanged = !model.changed._version;
      const hasChangedKeys = Object.keys(model.changed).length > 1;

      // If a change occurs without a version update it means it was changed
      // locally, so it must be synced with the back-end
      model.mustBeSaved = isVersionUnchanged || hasChangedKeys;
    });

    this.on('change:typeId', function (this: ComponentBackboneModel) {
      const typeName = this.getComponentTypeName();
      if (this.get('type') !== typeName) {
        this.set('type', typeName);
      }
    });

    this.on('change:_version', function (this: ComponentBackboneModel) {
      if (this.attributes._version === null) {
        delete this.attributes._version;
      }
    });

    if (this.isNew() && AQ.context) {
      if (!this.get('typeId')) {
        this.setComponentType();
      } else if (!this.get('type')) {
        this.setComponentTypeName();
      }
      if (!this.get('rootWorkspace')) {
        const parent = this.getParent();
        const contextWorkspace = AQ.context.workspace();
        if (parent) {
          this.set('rootWorkspace', parent.get('rootWorkspace'));
        } else if (contextWorkspace) {
          this.set('rootWorkspace', contextWorkspace.id);
        } else {
          logWarn(Error('No valid workspace found!'));
        }
      }

      this.setDefaultValues(this.attributes);
      _setDefaultOrder(this);
    }

    this.on('merged', function (this: ComponentBackboneModel) {
      this.changedDescription = false;
    });
    this.on(
      'sync',
      function (this: ComponentBackboneModel) {
        this.changedDescription = false;
        this.mustBeSaved = false;
      },
      this
    );
  },
  setDefaultValues: function (
    this: ComponentBackboneModel,
    props: Record<string, any>
  ) {
    const model = Models.collection.get(props.model);
    if (model) {
      each(
        Fields.collection.getByComponentType(props.typeId, model.id),
        field => {
          const fieldValue = props[field.name()];
          if (field.getType() === 'SelectMultipleList') {
            // Default values for list fields is not implemented, see #888
            props[field.name()] = Array.isArray(fieldValue) ? fieldValue : [];
          } else if (field.getType() === 'Checkbox') {
            props[field.name()] =
              fieldValue ||
              field.get('defaultValue') === 'true' ||
              field.get('defaultValue') === true;
          } else if (
            field.getType() !== 'List' &&
            field.get('defaultValue') &&
            props[field.name()] === undefined
          ) {
            props[field.name()] = field.get('defaultValue');
          }
        }
      );
    }
  },
  getSubtreeDepth: function (this: ComponentBackboneModel) {
    const children = this.getChildren();
    return children.length
      ? Math.max(...children.map(child => child.getSubtreeDepth())) + 1
      : 0;
  },

  getChildren: function (
    this: ComponentBackboneModel,
    ignoreSort = false,
    nodeCollectionView = CollectionView.DEFAULT_VIEW
  ) {
    if (!this.collection) {
      return [];
    }
    const result = this.getChildrenAsObjects(
      this.collection,
      nodeCollectionView
    );

    if (!ignoreSort && AQ && AQ.context.getSort && AQ.context.getSort()) {
      return result.sort((this.collection as Components).comparator);
    }
    return result;
  },
  getChildrenDeep: function (this: ComponentBackboneModel, ignoreSort = false) {
    const children: ComponentBackboneModel[] = [];

    this.getChildren(ignoreSort).forEach(child => {
      children.push(child);
      children.push(...child.getChildrenDeep(ignoreSort));
    });

    return children;
  },
  add: function (
    this: ComponentBackboneModel,
    component: ComponentBackboneModel
  ) {
    this.addChild(component);
    this.collection.add(component);
    return component;
  },
  getParent: function (this: ComponentBackboneModel) {
    return this.getParentObject(this.collection || AQ.globalComponents);
  },
  getParents: function (this: ComponentBackboneModel) {
    const parents = [];
    let current = this.getParent();
    while (current) {
      parents.push(current);
      current = current.getParent();
    }
    return parents;
  },
  getFields: function (this: ComponentBackboneModel) {
    return Fields.collection.getByComponent(this);
  },
  /**
   * @param escapeFieldHTML I suspect this should always be false, but to avoid a risky change I'm initially defaulting it to true.
   */
  getRawLabel: function (this: ComponentBackboneModel, escapeFieldHTML = true) {
    const activeCompLabelFilter = Filters.getCompLabelFilter();
    const currentSetting: string = getIn(
      activeCompLabelFilter[0],
      ['value'],
      'name'
    );
    if (currentSetting === 'none') {
      return '';
    }
    if (currentSetting === 'type') {
      return this.getComponentTypeName();
    }
    const componentName: string = this.get('name') || '';
    if (currentSetting === 'name') {
      return componentName;
    }
    const fieldValue = this.attributes[currentSetting];
    const field = this.getFields().find(
      field => field.name() === currentSetting
    );

    const excludeTime = !activeCompLabelFilter[0]?.attributes?.includeTime;

    const formattedValue = field
      ? field.format(fieldValue, undefined, escapeFieldHTML, excludeTime)
      : fieldValue;

    const hasFormattedValue = formattedValue || formattedValue === 0;
    const labelSuffix = hasFormattedValue ? `\n[${formattedValue}]` : '';
    return `${componentName}${labelSuffix}`;
  },
  getLabel: function (this: ComponentBackboneModel) {
    return encodingUtils.escapeHTML(this.getRawLabel());
  },
  handleChangeParent: function (this: ComponentBackboneModel) {
    const hierarchy = (this.collection as Components)?.hierarchy;
    if (hierarchy) {
      // Unlike the "add" and "remove" events, this needs to be done here,
      // because if we listen to "change:parent" on the collection, it will be
      // triggered after this method. That's a problem because this.setComponentType()
      // depends on getting an accurate value from this.getChildrenAsObjects().
      hierarchy.clearWorkspaceHierarchy(this.get('rootWorkspace'));
    }
    if (this.attributes.parent === '' || this.attributes.parent === undefined) {
      this.attributes.parent = null;
    }

    const model = this.getMyModel();
    const isFlexible = model && model.isFlexible();
    if (!isFlexible || !this.get('typeId')) {
      this.setComponentType();
    }
  },
  canHaveChildren: function (this: ComponentBackboneModel) {
    const model = this.getMyModel();
    if (!model) {
      logMissingModel({
        id: this.id,
        rootWorkspace: this.get('rootWorkspace'),
        modelTypeName: 'component',
      });
      return false;
    }
    return model.canModelTypeHaveChildren(this.getTypeId());
  },
  getObjectCollectionName: function () {
    return 'children';
  },
  setComponentTypeName: function (this: ComponentBackboneModel) {
    const model = this.getMyModel(),
      type = model && model.getTypeById(this.get('typeId'));

    if (type) {
      this.set('type', type.name);
    } else {
      this.setComponentType();
    }
  },
  setComponentType: function (this: ComponentBackboneModel) {
    const compType = this.getValidComponentTypeByParent();
    if (compType) {
      if (this.get('typeId') === compType.id) return;
      this.set('typeId', compType.id);
      this.set('type', this.getComponentTypeName());
      this.getChildrenAsObjects(this.collection).forEach(childComp =>
        childComp.setComponentType()
      );
    } else {
      logWarn(Error('No component type found!'));
    }
  },
  getMyModel: function (this: ComponentBackboneModel) {
    const modelId: string = this.get('model');
    let model = modelId ? Models.collection.get(modelId) : null;
    const ws = AQ.context ? AQ.context.workspace() : false;
    if (!model && ws) {
      const rootWorkspace = this.get('rootWorkspace');
      const missingModelIsExpected = this.isNew() && !rootWorkspace; // this is often the place where the model is set during initialization. if typeId is not passed as an attribute to the constructor, the initialize method will call setComponentType, which calls getMyModel, which ends up setting the model. side effects in a method named 'get' are an antipattern, but this seems to be working as designed.
      if (!missingModelIsExpected) {
        logMissingModel({
          id: this.id,
          rootWorkspace,
          modelTypeName: 'component',
        });
      }
      const parentComponent = this.getParent();
      model = parentComponent ? parentComponent.getMyModel() : ws.getModel();
      if (model) {
        this.set('model', model.id);
      }
    }
    return model;
  },
  getModelId: function (this: ComponentBackboneModel) {
    return this.get('model');
  },
  getValidComponentType: function (this: ComponentBackboneModel) {
    const model = this.getMyModel();
    return model ? model.getTypeByComponent(this) : null;
  },
  getValidComponentTypeByParent: function (this: ComponentBackboneModel) {
    const model = this.getMyModel();
    return model ? model.getTypeByComponentsParent(this) : null;
  },
  getRepresentationData: function (this: ComponentBackboneModel) {
    let isImage = false;
    let value = null;
    if (this.get('image')) {
      isImage = true;
      value = this.get('image');
    } else if (this.get('icon')) {
      value = this.get('icon');
    } else {
      const ct = this.getValidComponentType();
      if (ct) {
        value = ct.image || ct.icon;
        isImage = Boolean(ct.image);
      }
    }
    return { isImage, value, icon: getIcon(value) };
  },
  getRepresentation: function (this: ComponentBackboneModel) {
    return renderToStaticMarkup(
      createElement(ComponentRepresentation, this.getRepresentationData())
    );
  },
  getImage: function (this: ComponentBackboneModel) {
    const ct = this.getValidComponentType();
    const image =
      this.get('image') && this.get('image') !== ''
        ? this.get('image')
        : ct
          ? ct.image
          : null;
    return image;
  },
  getColor: function (this: ComponentBackboneModel) {
    return this.get('color') || this.getValidComponentType()?.color || null;
  },
  getIcon: function (this: ComponentBackboneModel) {
    const ct = this.getValidComponentType();
    const icon =
      this.get('icon') && this.get('icon') !== ''
        ? this.get('icon')
        : ct
          ? ct.icon
          : null;
    return icon;
  },
  getShape: function (this: ComponentBackboneModel) {
    const ct = this.getValidComponentType();
    const icon =
      this.get('shape') && this.get('shape') !== ''
        ? this.get('shape')
        : ct
          ? ct.shape
          : 'process';
    return icon;
  },
  getComponentTypeName: function (this: ComponentBackboneModel) {
    const compType = this.getValidComponentType();
    return compType ? compType.name : null;
  },
  getTypeId: function (this: ComponentBackboneModel) {
    let type = this.get('typeId');
    if (!type) {
      this.setComponentType();
      type = this.get('typeId');
    }
    return type;
  },
  getWorkspace: function (this: ComponentBackboneModel) {
    if (!this.attributes.rootWorkspace) {
      return AQ.context.workspace();
    }
    return AQ.workspaces.get(this.attributes.rootWorkspace);
  },
  getLevel: function (this: ComponentBackboneModel) {
    let level = 1;
    let p = this.getParent();
    while (p) {
      p = p.getParent();
      level++;
    }
    return level;
  },
  getMyType: function (this: ComponentBackboneModel) {
    const model = this.getMyModel();
    return model?.getTypeById(this.getTypeId()) ?? null;
  },
  hasReturnValue: function (this: ComponentBackboneModel) {
    const type = this.getMyType() as ModelType;
    return type.returnsValue;
  },
  getCSSFilterColor: function (this: ComponentBackboneModel) {
    const filterColor = currentFilterColor(this);
    if (!filterColor) return '';
    if (filterColor.startsWith('#')) return filterColor.replace('#', 'filter');
    // Provide valid CSS class name
    if (filterColor.toLowerCase().startsWith('rgba'))
      return getSanitizedRgbaClassName(filterColor);
    return '';
  },
  getCSS: function (
    this: ComponentBackboneModel,
    { useAsBackgroundStyle = false }: Partial<GetCssClassNamesOption> = {}
  ) {
    const type = this.getMyType();
    const filterColor = this.getCSSFilterColor() || NO_FILTER_CLASS_NAME;

    return classes(
      'component',
      this.cid,
      type?.id,
      filterColor,
      useAsBackgroundStyle && LI_BACKGROUND_CLASS_NAME
    );
  },
  isIncludedInContextByFilter: function (this: ComponentBackboneModel) {
    return Filters.isIncludedInContextByFilter(this);
  },
  getFullPathName: function (this: ComponentBackboneModel) {
    const separator = ' / ';
    let name: string = this.get('name');
    const parent = this.getParent();

    if (parent) {
      name = parent.getFullPathName() + separator + name;
    }
    return name;
  },
  getFullPathNameArr: function (this: ComponentBackboneModel) {
    const parentPath: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let current: ComponentBackboneModel | null = this;
    do {
      parentPath.unshift(current.get('name'));
      current = current.getParent();
    } while (current);

    return parentPath;
  },
  changedAndMustBeSaved: function (this: ComponentBackboneModel) {
    return (
      this.validationErrors === null &&
      NodeModel.model.prototype.changedAndMustBeSaved.apply(this)
    );
  },
  lock: function () {
    BasicModel.prototype.lock.call(this);
    dispatchAction(notifyComponentLocked(this.id));
  },
  unlock: function () {
    BasicModel.prototype.unlock.call(this);
    dispatchAction(notifyComponentUnlocked(this.id));
  },
});

function createComponent(
  props?: Record<string, any>,
  options?: Record<string, any>
) {
  return new Component(props, options) as ComponentBackboneModel;
}

type ComponentClass = ComponentBackboneModel & {
  extend: typeof NodeModel.model.extend;
  new (): ComponentBackboneModel;
};
export default {
  model: Component as ComponentClass,
  create: createComponent,
};
