import { connect } from '@ardoq/rxbeach';
import CalculatedFieldEditorSection from 'scopeData/editors/calculatedFields/CalculatedFieldEditorSection';
import calculatedFields$ from 'appModelStateEdit/propertiesEditor/calculatedFields/calculatedFields$';

const CalculatedFields = connect(
  CalculatedFieldEditorSection,
  calculatedFields$
);

export default CalculatedFields;
