import { exhaustMap, filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  collectRoutines,
  routine,
  dispatchAction,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { scenarioNamespace } from './actions';
import { openAddToScenarioDialog, openScenario } from 'scope/actions';
import scenarios$ from './scenarios$';
import { addToScenarioModal } from 'components/ScenarioModal/ScenarioModal';
import {
  APIScenarioAttributes,
  CreateScenarioResponse,
  ResourceType,
} from '@ardoq/api-types';
import { scenarioApi } from '@ardoq/api';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { setIsLoadingWithConfig } from 'components/GlobalPaneLoader/globalPaneLoader$';
import { renameRoutine } from 'streams/assets/routines';
import { handleDelete, handleFetchAll } from 'streams/crud/routines';
import { toResourceTypeStream, websocket$ } from 'sync/websocket$';
import { ArdoqEvent } from 'sync/types';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';

const handleOpenAddToScenarioDialog = routine(
  ofType(openAddToScenarioDialog),
  extractPayload(),
  withLatestFrom(scenarios$),
  exhaustMap(async ([payload, scenarios]) => {
    const scenario = await addToScenarioModal(payload, scenarios.list);
    if (scenario) {
      dispatchAction(
        openScenario({
          scenarioId: scenario._id,
          workspaceId: payload.context?.workspaceId,
          componentId: payload.context?.componentId,
          trackingClickSource: 'addToScenarioDialog',
        })
      );
    }
  })
);

const handleOpenScenario = routine(
  ofType(openScenario),
  extractPayload(),
  tap(({ scenarioId, skipLoadingBar }) => {
    if (skipLoadingBar) return;
    const name = scenarioInterface.getScenarioNameById(scenarioId);
    dispatchAction(setIsLoadingWithConfig({ title: `Loading "${name}"...` }));
  })
);

const isScenarioCreateEvent = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<CreateScenarioResponse> =>
  Boolean(ardoqEventOperations.isTransactionEvent(event)) &&
  ardoqEventOperations.isOfResourceType(event, ResourceType.SCENARIO);

const handleCreateEvent = (event: ArdoqEvent<CreateScenarioResponse>) => {
  const { permission } = event.data;
  const currentUser = currentUserInterface.getCurrentUserAttributesClone();
  dispatchAction(addPermissionForResource({ permission, currentUser }));
};

toResourceTypeStream<APIScenarioAttributes>(websocket$, ResourceType.SCENARIO)
  .pipe(filter(isScenarioCreateEvent), tap(handleCreateEvent))
  .subscribe();

export default collectRoutines(
  handleOpenAddToScenarioDialog,
  handleFetchAll(scenarioNamespace, scenarioApi.fetchAll),
  handleDelete(scenarioNamespace, scenarioApi.delete),
  renameRoutine(scenarioNamespace, scenarioApi.rename),
  handleOpenScenario
);
