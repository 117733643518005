import { reducer } from '@ardoq/rxbeach';
import { navigateToNewBroadcastForm } from 'router/navigationActions';
import { getDefaultState } from './utils';
import { getFilterConditionByType } from 'broadcasts/utils';
import {
  type BroadcastFilterCondition,
  BroadcastFilterConditionType,
} from '@ardoq/api-types';
import {
  toggleSurveyCompleteCondition,
  toggleSurveyIncompleteCondition,
} from './actions';
import {
  chooseBroadcastContentType,
  initFilterConditions,
} from 'broadcasts/actions';

type SurveyCompletenessConditionsStreamShape = {
  readonly hasSurveyCompleteCondition: boolean;
  readonly hasSurveyIncompleteCondition: boolean;
};

const handleNavigateToNewBroadcastForm = reducer(
  navigateToNewBroadcastForm,
  getDefaultState
);

const handleInitFilterConditions = (
  state: SurveyCompletenessConditionsStreamShape,
  filterConditions: BroadcastFilterCondition[]
) => {
  const hasSurveyCompleteCondition = Boolean(
    getFilterConditionByType(
      filterConditions,
      BroadcastFilterConditionType.SURVEY_COMPLETE
    )
  );
  const hasSurveyIncompleteCondition = Boolean(
    getFilterConditionByType(
      filterConditions,
      BroadcastFilterConditionType.SURVEY_INCOMPLETE
    )
  );
  return {
    ...state,
    hasSurveyCompleteCondition,
    hasSurveyIncompleteCondition,
  };
};

const handleToggleSurveyCompleteCondition = (
  state: SurveyCompletenessConditionsStreamShape
) => ({
  ...state,
  hasSurveyCompleteCondition: !state.hasSurveyCompleteCondition,
});

const handleToggleSurveyIncompleteCondition = (
  state: SurveyCompletenessConditionsStreamShape
) => ({
  ...state,
  hasSurveyIncompleteCondition: !state.hasSurveyIncompleteCondition,
});

const handleChooseBroadcastContentType = reducer(
  chooseBroadcastContentType,
  getDefaultState
);

export const reducers = [
  handleNavigateToNewBroadcastForm,
  reducer(initFilterConditions, handleInitFilterConditions),
  reducer(toggleSurveyCompleteCondition, handleToggleSurveyCompleteCondition),
  reducer(
    toggleSurveyIncompleteCondition,
    handleToggleSurveyIncompleteCondition
  ),
  handleChooseBroadcastContentType,
];
