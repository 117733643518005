import { DataSource, MergeStep, Path } from 'components/DiffMergeTable/types';
import { Verb } from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';

import {
  isComponentOrReferenceTypeMergeStep,
  toDataSourceComponentAndReferenceTypes,
} from 'components/DiffMergeTable/typeCollectionsUtil';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { toDataSourceUpdate } from './toDataSourceUpdate';
import { toDataSourceCreatedOrDeleted } from './toDataSourceCreatedOrDeleted';
import { toDataSourceTagsMerge } from './toDataSourceTagsMerge';
import { getPath } from './toDataSourceHelpers';

export const toDataSource = (
  enhancedDiffContextData: EnhancedDiffScopeData | null,
  mergeStep: MergeStep,
  mergeDirection: MergeDirection
): DataSource | null => {
  if (!enhancedDiffContextData) {
    return null;
  }
  if (isComponentOrReferenceTypeMergeStep(mergeStep)) {
    return toDataSourceComponentAndReferenceTypes(
      enhancedDiffContextData,
      mergeStep,
      mergeDirection
    );
  }
  const path = getPath(mergeStep);
  if (!path) {
    return [];
  }
  const [, verb] = path;
  return toDataSources[verb](enhancedDiffContextData, path as Path);
};

const toDataSources = {
  [Verb.UPDATE]: toDataSourceUpdate,
  [Verb.CREATE]: toDataSourceCreatedOrDeleted,
  [Verb.DELETE]: toDataSourceCreatedOrDeleted,
  [Verb.MERGE]: toDataSourceTagsMerge,
};
