import { DateRangePropertyValue, PropertyValue } from 'aqTypes';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldType,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { difference, isArray, pick, pickBy } from 'lodash';
import { Diff, getDiff, getEntityById } from '@ardoq/renderers';
import { ExcludeFalsy, toByIdDictionary } from '@ardoq/common-helpers';
import { DirtyAttributes } from './types';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { isDateRangeFieldType } from '@ardoq/date-range';

type AttributeNameListValueTuple = [
  attributeName: string,
  attributeValue: string[],
];

const getFieldTypeByName = (
  fieldName: string,
  enhancedScopeData: EnhancedScopeData
) =>
  enhancedScopeData.fields.find(field => field.name === fieldName)?.type ??
  null;

const getCorrectDateRangeAttributeValue = (
  currentValue: DateRangePropertyValue,
  originalValue: DateRangePropertyValue,
  isDirty: boolean
) => {
  if (isDirty) return currentValue;
  return currentValue.start === null && currentValue.end === null
    ? originalValue
    : currentValue;
};

const getCorrectSelectMultipleAttributeValue = (
  currentValues: string[] | null,
  initialUniqueAttributeValues: string[]
) => {
  return Array.from(
    new Set([...initialUniqueAttributeValues, ...(currentValues ?? [])])
  );
};

const getCorrectPrimitiveAttributeValue = (
  currentValue: PropertyValue,
  originalValue: PropertyValue,
  isDirty: boolean
) => {
  if (isDirty) return currentValue;
  return currentValue === null ? originalValue : currentValue;
};

const getInitialUniqueAttributeValuesPerEntity = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  originalEnhancedScopeData: EnhancedScopeData
) => {
  const entities = entityIDs
    .map(entityID =>
      getEntityById(entityType, entityID, originalEnhancedScopeData)
    )
    .filter(ExcludeFalsy);
  const entitiesById = toByIdDictionary(entities);
  const propertiesWithListValues = new Set(
    entities.flatMap(entity => Object.keys(pickBy(entity, isArray)))
  );
  return Object.fromEntries(
    Object.entries(entitiesById).map(([entityID, entity]) => {
      const entriesWithListValues: AttributeNameListValueTuple[] =
        Object.entries(entity).filter(([attributeName]) =>
          propertiesWithListValues.has(attributeName)
        );
      const entityWithRelevantProperties = Object.fromEntries(
        entriesWithListValues.map(([attributeName, attributeValue]) => {
          const uniqueValues = difference(
            attributeValue ?? [],
            ...entities
              .filter(e => e !== entity)
              .map(e => e[attributeName] ?? [])
          );
          return [attributeName, uniqueValues];
        })
      );
      return [entityID, entityWithRelevantProperties];
    })
  );
};

const getMergedAttributeValues = (
  diff: Diff,
  currentAttributes: Partial<APIComponentAttributes | APIReferenceAttributes>,
  originalAttributes: Partial<APIComponentAttributes | APIReferenceAttributes>,
  enhancedScopeData: EnhancedScopeData,
  initialUniqueAttributeValues: Record<string, string[]>,
  dirtyAttributes: DirtyAttributes
) => {
  return Object.fromEntries(
    Object.keys(diff).flatMap(attributeName => {
      const fieldType = getFieldTypeByName(attributeName, enhancedScopeData);
      const currentValue = currentAttributes[attributeName];
      const originalValue = originalAttributes[attributeName];
      if (isDateRangeFieldType(fieldType)) {
        const attributeValue = getCorrectDateRangeAttributeValue(
          currentValue,
          originalValue,
          dirtyAttributes.has(attributeName)
        );
        return [
          [`${attributeName}_start_date`, attributeValue.start],
          [`${attributeName}_end_date`, attributeValue.end],
        ];
      }
      if (fieldType === APIFieldType.SELECT_MULTIPLE_LIST) {
        const initialValue = initialUniqueAttributeValues[attributeName] ?? [];
        const attributeValue = getCorrectSelectMultipleAttributeValue(
          currentValue,
          initialValue
        );
        return [[attributeName, attributeValue]];
      }
      const attributeValue = getCorrectPrimitiveAttributeValue(
        currentValue,
        originalValue,
        dirtyAttributes.has(attributeName)
      );
      return [[attributeName, attributeValue]];
    })
  );
};

export const getModifiedSingleEntityAttributes = <T>(
  attributes: Partial<T>,
  dirtyAttributes: DirtyAttributes,
  enhancedScopeData: EnhancedScopeData
) => {
  return pick(
    attributes,
    Array.from(dirtyAttributes).flatMap(fieldName => {
      const fieldType = getFieldTypeByName(fieldName, enhancedScopeData);
      if (isDateRangeFieldType(fieldType)) {
        return [`${fieldName}_start_date`, `${fieldName}_end_date`];
      }
      return fieldName;
    })
  );
};

// this method should be used when editing multiple entities at once
export const getModifiedAttributesPerEntity = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  originalEnhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): Partial<APIComponentAttributes | APIReferenceAttributes> => {
  const initialUniqueAttributeValuesPerEntity =
    getInitialUniqueAttributeValuesPerEntity(
      entityType,
      entityIDs,
      originalEnhancedScopeData
    );
  const modifiedEntityAttributes = entityIDs.map(entityID => {
    const currentAttributes = getEntityById(
      entityType,
      entityID,
      enhancedScopeData
    );
    const originalAttributes = getEntityById(
      entityType,
      entityID,
      originalEnhancedScopeData
    )!;
    const diff = getDiff(currentAttributes, originalAttributes);
    if (!diff || !currentAttributes) {
      return { _id: originalAttributes._id };
    }
    const modifiedAttributes = getMergedAttributeValues(
      diff,
      currentAttributes,
      originalAttributes,
      enhancedScopeData,
      initialUniqueAttributeValuesPerEntity[entityID],
      dirtyAttributes
    );
    return {
      _id: originalAttributes._id,
      ...modifiedAttributes,
    };
  });
  return toByIdDictionary(modifiedEntityAttributes);
};
