import integrationViewState$ from 'integrations/integrationViewState$';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, filter } from 'rxjs/operators';
import { isIntegrationId } from '../activeIntegrations/utils';
import { IntegrationId } from '../tabularMappings/types';

export const integrationId$: Observable<IntegrationId> =
  integrationViewState$.pipe(
    distinctUntilKeyChanged('selectedIntegrationId'),
    filter(state => isIntegrationId(state.selectedIntegrationId)),
    map(({ selectedIntegrationId }) => selectedIntegrationId as IntegrationId)
  );
