import { Branch } from './Branch';
import { APIScopeData } from '@ardoq/api-types';
import { MergeDirection } from 'scope/merge/MergeDirection';
import {
  diffSourceToTarget,
  enhanceScopeData,
  enhanceScopeDataWithBranchName,
  getDynamicFilter,
  getWorkspacesFromMainlineAndBranch,
  ignoredProperties,
} from '@ardoq/renderers';

export type EnhancedDiffScopeData = ReturnType<typeof enhanceDiffContextData>;

export const enhanceDiffContextData = (
  master: APIScopeData,
  branch: APIScopeData,
  branchOff: APIScopeData,
  mergeDirection: MergeDirection
) => {
  const enhancedScopeDataMainline = enhanceScopeData(master);
  const enhancedScopeDataBranch = enhanceScopeData(branch);
  const enhancedScopeDataBranchOff = enhanceScopeData({
    ...branchOff,
    workspaces: getWorkspacesFromMainlineAndBranch(master, branch),
  });
  const isDiffMainlineToBranch =
    mergeDirection === MergeDirection.MAINLINE_TO_BRANCH;
  const sourceData = isDiffMainlineToBranch ? master : branch;
  const targetData = isDiffMainlineToBranch ? branch : master;
  const enhancedScopeDataSourceBranch = isDiffMainlineToBranch
    ? enhancedScopeDataMainline
    : enhancedScopeDataBranch;
  const enhancedScopeDataTargetBranch = isDiffMainlineToBranch
    ? enhancedScopeDataBranch
    : enhancedScopeDataMainline;
  const dynamicFilter = getDynamicFilter(
    enhancedScopeDataSourceBranch,
    enhancedScopeDataTargetBranch
  );
  const scopedComponentIds = new Set(branch.scopeComponents);
  const allReferenceIds = new Set(
    [...branch.references, ...master.references].map(({ _id }) => _id)
  );
  const branchOffComponentAndRefernceIds = new Set(
    [...branchOff.references, ...branchOff.components].map(({ _id }) => _id)
  );

  // This filter is used for components and references.
  const exposeAsDeletedFilter = (id: string) =>
    isDiffMainlineToBranch
      ? branchOffComponentAndRefernceIds.has(id)
      : // See https://ardoqcom.atlassian.net/browse/ARD-8564 for more context.
        allReferenceIds.has(id) || scopedComponentIds.has(id);

  // This filter is only used for components.
  const exposeAsCreatedFilter = (id: string) =>
    // See https://ardoqcom.atlassian.net/browse/ARD-8564 for more context.
    // Recreation of deleted scenario components should still be
    // possible in the create step from mainline to branch.
    !isDiffMainlineToBranch || scopedComponentIds.has(id);

  const sourceBranch = isDiffMainlineToBranch
    ? enhancedScopeDataMainline
    : enhancedScopeDataBranch;
  const targetBranch = isDiffMainlineToBranch
    ? enhancedScopeDataBranch
    : enhancedScopeDataMainline;
  const {
    diffData: diffTargetToSource,
    sourceMetaData,
    targetMetaData,
  } = diffSourceToTarget({
    sourceData,
    targetData,
    dynamicFilter,
    exposeAsDeletedFilter,
    exposeAsCreatedFilter,
    ignoredProperties,
  });

  return {
    mergeDirection,
    [Branch.MAIN]: enhanceScopeDataWithBranchName(
      enhancedScopeDataMainline,
      Branch.MAIN
    ),
    [Branch.BRANCH]: enhanceScopeDataWithBranchName(
      enhancedScopeDataBranch,
      Branch.BRANCH
    ),
    [Branch.BRANCH_OFF]: enhanceScopeDataWithBranchName(
      enhancedScopeDataBranchOff,
      Branch.BRANCH_OFF
    ),
    [Branch.SOURCE]: enhanceScopeDataWithBranchName(
      sourceBranch,
      Branch.SOURCE
    ),
    [Branch.TARGET]: enhanceScopeDataWithBranchName(
      targetBranch,
      Branch.TARGET
    ),
    diffTargetToSource,
    sourceMetaData,
    targetMetaData,
  };
};
