import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, dashboardReducers } from './reducers';
import { DashboardsStreamShape } from './types';

const dashboards$ = persistentReducedStream<DashboardsStreamShape>(
  'dashboards$',
  defaultState,
  dashboardReducers
);

export default dashboards$;
