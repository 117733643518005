import { uniq } from 'lodash';
import { AutocompleteFieldSuggestion } from './types';
import { SelectOption } from '@ardoq/select';

const FIELD_PATTERN = /@([A-Za-z][A-Za-z0-9_]*)/gi;

export const getReferencedFieldsFromExpression = (
  expression: string
): string[] =>
  uniq(Array.from(expression.matchAll(FIELD_PATTERN), match => match[1]));

/**
 * Return selected dictionary lookup option
 */
export const getSelectedDictionaryLookupFieldSelectedOption = (
  selectedName: string | undefined,
  options: SelectOption<AutocompleteFieldSuggestion>[] | undefined
): SelectOption<AutocompleteFieldSuggestion> | null => {
  if (!options) {
    return null;
  }

  const selectedOption =
    selectedName && options.find(option => option.value.name === selectedName);

  if (selectedOption) {
    return selectedOption;
  }

  return null;
};
