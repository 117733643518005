import {
  ArdoqId,
  FetchHistoryAPIResult,
  ComparableAPIEntityTypes,
} from '@ardoq/api-types';

export type OpenEntityMergeParams = {
  entityId: ArdoqId;
  entityType: ComparableAPIEntityTypes;
  versionsToFetchCount?: number;
};

export type FetchHistoryState = {
  historyByVersion: { [version: string]: FetchHistoryAPIResult };
  isLoading: boolean;
  hasError: boolean;
};

type Value = string | boolean | number;

export type HistoryVersionTableRowData = {
  fieldName: string;
  label: string;
  currentValue?: Value;
  revisionValue?: Value;
  revertBlockType: RevertBlockedType | null;
};

export enum RevertBlockedType {
  COMPONENT_DELETED = 'COMPONENT_DELETED',
  COMPONENT_TYPE_DELETED = 'COMPONENT_TYPE_DELETED',
  REFERENCE_TYPE_DELETED = 'REFERENCE_TYPE_DELETED',
  SELECT_FIELD_OPTION_DELETED = 'SELECT_FIELD_OPTION_DELETED',
  READ_ONLY_FILED = 'READ_ONLY_FILED',
}

export type UseEntityScopeProps = {
  entityId: ArdoqId;
  entityType: ComparableAPIEntityTypes;
};
