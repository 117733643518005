import { combineLatest, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { dispatchAction } from '@ardoq/rxbeach';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import {
  IntegrationWorkspace,
  SavedTransferConfig,
} from '@ardoq/api-types/integrations';
import { emitOverviewActionEvent } from 'integrations/common/tracking/utils';
import { startCopyTransferConfigModal } from 'integrations/common/modals/copyTransferConfigModal/CopyTransferConfigModal';
import {
  bulkDeleteConfigurations,
  renameConfiguration,
} from 'integrations/common/streams/transferConfigs/actions';
import { loadTransferConfig } from 'integrations/common/streams/transferConfigs/actions';
import { getIntegrationTermsDictionaryStream } from 'integrations/common/streams/integrationTermsDictionary/getIntegrationTermsDictionaryStream';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { setVisibleAsset } from 'assets/navigation/actions';

const onDeleteConfigs =
  (integrationId: IntegrationId) => (configs: SavedTransferConfig[]) => {
    emitOverviewActionEvent(
      configs.length === 1 ? 'config-delete' : 'config-bulk-delete',
      integrationId
    );
    dispatchAction(bulkDeleteConfigurations({ configs }));
  };

const onRenameConfiguration =
  (integrationId: IntegrationId) =>
  (name: string, config: SavedTransferConfig) => {
    dispatchAction(renameConfiguration({ integrationId, name, config }));
  };

const onStartConfigurationRenaming = (integrationId: IntegrationId) => () => {
  emitOverviewActionEvent('config-rename', integrationId);
};

const onWorkspaceClick = (workspace: IntegrationWorkspace) => {
  if (workspace.id) {
    dispatchAction(
      setVisibleAsset({ assetId: workspace.id, assetType: 'workspace' })
    );
  }
};

export const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest([
      of(integrationId),
      getTransferConfigsStream(integrationId),
      getIntegrationTermsDictionaryStream(integrationId),
    ])
  ),
  map(
    ([
      integrationId,
      { configs, asyncStatus, selectedConfigIds },
      integrationTermsDictionary,
    ]) => {
      const loadConfig = (transferConfig: SavedTransferConfig) => {
        emitOverviewActionEvent('config-edit', integrationId);
        dispatchAction(
          loadTransferConfig({
            transferConfig,
            integrationId,
          })
        );
      };

      return {
        dataSource: configs,
        isLoading: asyncStatus === 'LOADING',
        selectedIds: selectedConfigIds,
        extraSingleItemActions: [
          {
            label: 'Copy configuration',
            onClick: (config: SavedTransferConfig) => {
              emitOverviewActionEvent('config-copy', integrationId);
              startCopyTransferConfigModal(integrationId, config);
            },
          },
          {
            label: 'Edit configuration',
            onClick: loadConfig,
          },
        ],
        onDeleteConfigs: onDeleteConfigs(integrationId),
        onRenameConfiguration: onRenameConfiguration(integrationId),
        onStartConfigurationRenaming:
          onStartConfigurationRenaming(integrationId),
        loadConfig,
        onConfigClick: loadConfig,
        onWorkspaceClick,
        isExpandable: true,
        isClickable: true,
        noConfigsMessage: integrationTermsDictionary.noConfigsMessage,
      };
    }
  )
);
