/**
 * This module is one layer above SimpleWAMP. The goals of this module are:
 *  - Integrate the status of the websocket connection into the app
 *  - Allow subscribing and unsubscribing from events
 */
import { isAutomatedTestMode } from 'appConfig';
import { loggedOut as loggedOutAction } from 'authentication/actions';
import { logWebsocketStatusMessage } from 'logging/logWebsocketEvent';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  ForceReloadReason,
  sessionExpired,
  websocketConnected,
} from './actions';
import { ConnectionStatus, SimpleWAMP } from './SimpleWAMP';
import {
  OrgEvent,
  TokenEvent,
  UserEvent,
  WSClosingReason,
  WorkspaceEvent,
} from './types';
import { renderForceReload } from './syncManagerViewError';

const ssl =
  window.location.protocol === 'https:' ||
  /^(\w|-)+\.ardoq\.com/.test(window.location.hostname);

const WS_URI = `${(ssl ? 'wss://' : 'ws://') + window.location.host}/ws`;

const BASE_TOPIC_URI = 'https://app.ardoq.com/';
const swamp = new SimpleWAMP(WS_URI);

let hasBeenConnected = false;
let expired = false;
let loggedOut = false;
let token: string | null = null;

swamp.onConnected(() => {
  logWebsocketStatusMessage(
    `Connected to ${WS_URI}, session id: ${swamp.sessionId}`
  );

  dispatchAction(websocketConnected());

  hasBeenConnected = true;
  expired = false;
  loggedOut = false;
});

swamp.onReconnecting((_, event) => {
  logWebsocketStatusMessage(
    `Reconnecting.
    type: ${event.type}
    reason: ${event.reason}
    code: ${event.code}
    wasClean: ${event.wasClean}`
  );
});

swamp.onDisconnected(() => {
  if (expired) {
    logWebsocketStatusMessage(`Connection closed due to Expired session`);
  } else if (!hasBeenConnected) {
    logWebsocketStatusMessage(`Connection closed due to never connected`);
    // Need a timeout, so the modal loader is ready before we trigger the modal
    setTimeout(
      () => renderForceReload(ForceReloadReason.CANNOT_CONNECT_WS),
      6000
    );
  } else if (loggedOut) {
    logWebsocketStatusMessage(`Connection closed due to Logout`);
  } else {
    logWebsocketStatusMessage(`Connection dead (not expired session)`);
    renderForceReload(ForceReloadReason.DEAD_CONNECTION_WS);
  }
});

swamp.onError((_, event) => {
  if (hasBeenConnected) {
    logWebsocketStatusMessage(`Error.
      type: ${event.type}
      readyState: ${swamp.websocket?.readyState}`);
  } else {
    logWebsocketStatusMessage(`First websocket connection failed.
      type: ${event.type}
      readyState: ${swamp.websocket?.readyState}`);
    swamp.close();
  }
});

swamp.setPrefix('event', `${BASE_TOPIC_URI}event#`);
swamp.subscribe('event', 'ping', (event: { token?: string }) => {
  if (event.token) {
    token = event.token;
  }
});
swamp.subscribe('event', 'session-expired', () => {
  logWebsocketStatusMessage(`Connection expired by remote`);

  // Handling of the sessionExpired action closes the connection for us
  // and sets expired = true
  dispatchAction(sessionExpired());
});

swamp.subscribe('event', 'logout', () => {
  logWebsocketStatusMessage(`The user logged out`);
  // Handling of the logout action closes the connection for us
  // and sets expired = true
  dispatchAction(loggedOutAction());
});

export const connect = () => {
  // detecting only unit tests here, because in case of Cypress we still want to
  // continue here. Basically we want to return from the function only in case of Jest
  if (isAutomatedTestMode({ detectUnitTestsOnly: true })) {
    // Our mock API, used for browser tests in Jest, does not provide
    // websockets, so we don't try to connect.
    return;
  }

  logWebsocketStatusMessage(`Attempting to connect to ${WS_URI}`);

  swamp.connect();
};

export const close = (config?: { reason?: WSClosingReason }) => {
  const reason = config?.reason;
  expired = reason === WSClosingReason.SESSION_EXPIRED;
  loggedOut = reason === WSClosingReason.LOGOUT;
  swamp.close();
};

export const isSessionConnected = () =>
  swamp.status === ConnectionStatus.CONNECTED;

/**
 * Check whether the event was triggered by this session
 */
export const isFromCurrentSession = (event: TokenEvent) =>
  event.token === token;

export const subscribeUserEvents = (
  userId: string,
  callback: (event: UserEvent) => void
) => swamp.subscribe('event', `user-${userId}`, callback);

export const subscribeOrgEvents = (
  orgId: string,
  callback: (event: OrgEvent) => void
) => swamp.subscribe('event', `org-${orgId}`, callback);

export const subscribeWorkspaceEvents = (
  workspaceId: string,
  callback: (event: WorkspaceEvent) => void
) => swamp.subscribe('event', `workspace-${workspaceId}`, callback);

export const unsubscribeWorkspaceEvents = (workspaceId: string) =>
  swamp.unsubscribe('event', `workspace-${workspaceId}`);
