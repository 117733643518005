import {
  APIFieldAttributes,
  APIFieldType,
  APIGlobalField,
} from '@ardoq/api-types';
import { fieldOps } from './fieldOps';
import { SelectOption } from '@ardoq/select';

type FieldOption = { value: string; label: string };

/**
 * Fields as dropdown options
 */
const toFieldOption = (field: APIGlobalField | APIFieldAttributes) => {
  return {
    value: field.name,
    label: fieldOps.getLabelWithTypeAnnotation(field),
  };
};

const toFieldOptionFromType = (
  fieldType: APIFieldType
): SelectOption<APIFieldType> => {
  return {
    value: fieldType,
    label: fieldOps.getFieldTypeLabel(fieldType),
  };
};

/**
 * Convert FieldOptions into CreatableList-compatible props
 */
const fieldOptionsToCreatableListProps = (args: {
  fieldOptions: FieldOption[];
  isScenarioMode: boolean;
}) => {
  const { fieldOptions, isScenarioMode } = args;
  if (!isScenarioMode) {
    return { options: fieldOptions, isDisabled: false as const };
  }

  return {
    options: fieldOptions,
    isDisabled: true as const,
    disabledMessage: 'Adding fields is disabled in scenarios.',
  };
};

/**
 * Convert APIGlobalFields into CreatableList-compatible props
 */
const fieldsToSortedCreatableListProps = (args: {
  fields: APIGlobalField[];
  isScenarioMode: boolean;
}) => {
  const { fields, isScenarioMode } = args;
  const fieldOptions = fields
    .sort(fieldOps.compareFieldsByLabelAsc)
    .map(toFieldOption);
  return fieldOptionsToCreatableListProps({ fieldOptions, isScenarioMode });
};

// Should be the only export aside from types
export const fieldOptionOps = {
  toFieldOption,
  toFieldOptionFromType,
  fieldOptionsToCreatableListProps,
  fieldsToSortedCreatableListProps,
};
