import { colors, s24 } from '@ardoq/design-tokens';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';
import { Link, text1 } from '@ardoq/typography';
import {
  getComplaints,
  getUnknownError,
} from 'integrations/common/streams/transferState/utils';
import {
  ErrorPanel,
  WarningPanel,
} from 'integrations/common/components/Complaints/Complaints';
import { IntegrationDate } from 'integrations/common/components/integrationDate/IntegrationDate';
import { ExpandedRowMapping } from 'integrations/common/components/expandedRowMapping/ExpandedRowMapping';
import {
  getScheduleDirection,
  isITPediaSchedule,
  isJobOptionsWithWorkspace,
} from 'integrations/common/streams/schedules/utils';

import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { isJobWithTabularMapping } from 'integrations/common/utils/scheduleApi';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { FlexBox } from '@ardoq/layout';
import { IconName } from '@ardoq/icons';
import { IntegrationSchedule } from '@ardoq/api-types';
import { ITPediaDetails } from './ITPediaDetails';

const ScheduleText = styled.div`
  ${text1}
`;

const ExpandedScheduleContainer = styled(Space)`
  background-color: ${colors.grey95};
  padding: ${s24};
`;

export function ExpandedSchedule({
  schedule,
  connectionName,
  onWorkspaceClick,
}: {
  schedule: IntegrationSchedule;
  connectionName: string | null;
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
}) {
  const { jobOptions, meta } = schedule;
  const complaints = getComplaints(meta.lastResult?.summary?.validation);

  const { status, message } = meta.lastResult || {};

  const errors = complaints.errors.length
    ? complaints.errors
    : status === 'error'
      ? getUnknownError(message)
      : [];

  return (
    <ExpandedScheduleContainer $isVertical>
      <Space $justify="space-between">
        <ScheduleText>
          Connection: {connectionName || <MissingConnection />}
        </ScheduleText>
      </Space>
      {isJobOptionsWithWorkspace(jobOptions) && (
        <FlexBox justify="space-between">
          <ScheduleText>
            Workspace:{' '}
            {jobOptions.workspace.id &&
            !!workspaceInterface.get(jobOptions.workspace.id) &&
            onWorkspaceClick ? (
              <Link
                iconName={IconName.WORKSPACE}
                type="primary"
                typography="text1"
                onClick={() => onWorkspaceClick(jobOptions.workspace)}
              >
                {jobOptions.workspace.name}
              </Link>
            ) : (
              jobOptions.workspace.name
            )}
          </ScheduleText>
        </FlexBox>
      )}
      <Space $justify="space-between">
        <ScheduleText>
          First scheduled {getScheduleDirection(jobOptions.type)}:{' '}
          <IntegrationDate date={meta.firstCompleted} />
        </ScheduleText>
        <ScheduleText>
          Next scheduled {getScheduleDirection(jobOptions.type)}:{' '}
          <IntegrationDate date={meta.nextStart} />
        </ScheduleText>
      </Space>

      <WarningPanel warnings={complaints.warnings} isCollapsible={false} />
      <ErrorPanel errors={errors} isCollapsible={false} />
      {isJobWithTabularMapping(jobOptions) && (
        <ExpandedRowMapping
          dataSource={jobOptions.tabularMapping.tables.map(table => ({
            name: table.name,
            id: table.id,
            rowRepresentation: table.rowRepresentation,
            workspace: table.rootWorkspace,
            isWorkspaceAvailable: table.rootWorkspace.id
              ? !!workspaceInterface.get(table.rootWorkspace.id)
              : false,
          }))}
          onWorkspaceClick={onWorkspaceClick}
        />
      )}
      {isITPediaSchedule(schedule) && (
        <ITPediaDetails
          schedule={schedule}
          onWorkspaceClick={onWorkspaceClick}
        />
      )}
    </ExpandedScheduleContainer>
  );
}

const GreyText = styled.span`
  color: ${colors.grey50};
`;

const MissingConnection = () => <GreyText>Missing</GreyText>;
