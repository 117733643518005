import { IntegrationInitialState } from 'integrations/common/initialState';
import { buildInitialTermsState } from 'integrations/common/streams/integrationTermsDictionary/initialState';

export const initialState: IntegrationInitialState = {
  activeIntegration: {
    integrationId: 'it-pedia',
    integrationMappingParams: {
      mapColumnsBy: 'sourceFieldName',
      mapTablesBy: 'id',
    },
  },
  termsDictionary: buildInitialTermsState({
    name: 'IT Pedia',
    integrationOverviewTitle: 'IT-Pedia integration',
    rightSidebarHeader: 'Having trouble with the IT-Pedia integration?',
    rightSideBarFAQ: [
      {
        link: 'https://help.ardoq.com/en/articles/99168-it-pedia-integration',
        text: 'Onboarding guide.',
      },
    ],
    connectYourAccountTitle:
      'Connect your IT Pedia account to Ardoq to get started.',
    connectYourAccountBtn: 'Connect to IT-Pedia account',
    overviewPrimaryButton: 'Create new sync',
  }),
};
