import { api, handleError, scenarioApi } from '@ardoq/api';
import { APIScenarioScopeData, APIScopeData, ArdoqId } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import * as profiling from '@ardoq/profiling';
import {
  diffSourceToTarget,
  enhanceScopeData,
  enhanceScopeDataWithBranchName,
  getDynamicFilter,
  getWorkspacesFromMainlineAndBranch,
  ignoredProperties,
} from '@ardoq/renderers';
import {
  apply,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { isDevelopmentMode } from 'appConfig';
import { hideRightPane, requestShowAppModule } from 'appContainer/actions';
import { hideScopeRelatedNavigator } from 'appContainer/MainAppModule/MainAppModuleSidebar/actions';
import { closeVisualDiff } from 'appContainer/MainAppModule/MainAppModuleSidebar/NavigatorTopBar/utils';
import { AppModules } from 'appContainer/types';
import Components from 'collections/components';
import Fields from 'collections/fields';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import resetPerspectives from 'collections/helpers/resetPerspectives';
import Models from 'collections/models';
import References from 'collections/references';
import Tags from 'collections/tags';
import Workspaces from 'collections/workspaces';
import { Branch } from 'components/DiffMergeTable/Branch';
import Context from 'context';
import { clearLoadedState } from 'loadedState/actions';
import { scenarioInterface } from 'modelInterface/scenarios/scenarioInterface';
import { pipe } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import {
  clearComponentHierarchies,
  clearComponentHierarchiesAndCloseWorkspaces,
  getEntities,
  getScopeSize,
  openScenarioIsOutOfSyncModal,
  openWelcomeScenarioModal,
  scenariosUserSettings,
  setDefaultCollectionView,
  setModelsToCollections,
  updateSyncStatusIfChanged,
} from 'scope/utils';
import {
  loadAndFixDiffContext,
  loadAndFixScenario,
} from 'services/scenarioApi';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import {
  notifyComponentsAdded,
  notifyComponentsRemoved,
} from 'streams/components/ComponentActions';
import { notifyScenarioChanged } from 'streams/context/ContextActions';
import { triggerFiltersChangedEvent } from 'streams/filters/FilterActions';
import {
  fetchAllModels,
  fetchAllModelsFailed,
  fetchAllModelsSucceeded,
} from 'streams/models/actions';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
} from 'streams/references/ReferenceActions';
import { clearLoadedGraphState } from 'streams/traversals/actions';
import { notifyWorkspaceAggregatedLoaded } from 'streams/workspaces/actions';
import intercom from 'tracking/intercom';
import {
  addCSSForComponents,
  addCSSForModel,
  clearAllCidStyle,
} from 'utils/modelCssManager/ardoqModelCSSManager';
import { isCurrentScenarioInSync } from '../scenarioIsInSync$';
import { setTagsFromScenario } from '../tabview/tagscape/actions';
import {
  closeLoadingScenarioProgressBar,
  closeScenario,
  closeScenarioSuccess,
  loadDiff,
  loadDiffError,
  loadDiffSuccess,
  openScenario,
  openScenarioError,
  openScenarioSuccess,
  reloadScenario,
  reloadScenarioError,
  reloadScenarioSuccess,
  setLoadedScenarioData,
  setScenarioId,
  setScenarioIsInSync,
  updateEnhancedScopeDiffData,
  updateLoadedScenarioData,
  updateScopeDiff,
} from './actions';
import testScenario from './test';
import { trackOpenScenario } from './tracking';

const SCENARIO_TOUR_ID = 157102;

// TODO temporary to test the api.
if (isDevelopmentMode()) {
  window.testScenario = testScenario;
}

// TODO temporary solution
/** @deprecated */
const genericScenarioErrorHandler = (
  message: string,
  cb?: (error: any) => void
) =>
  catchError((error, stream) => {
    dispatchAction(closeLoadingScenarioProgressBar());
    api.logErrorIfNeeded(error);
    alert({
      title: 'An error occured',
      text: message,
    });
    cb?.(error);
    return stream;
  });

const openScenarioRoutine = routine(
  ofType(openScenario),
  extractPayload(),
  apply(
    ({
      workspaceId,
      componentId,
      trackingClickSource,
      scenarioId,
      isPresentationSlide,
    }) =>
      pipe(
        tap(() => {
          closeVisualDiff();
          dispatchAction(
            clearLoadedGraphState({ keepCurrentViewPointMode: false })
          );
          dispatchAction(clearLoadedState());
        }),
        switchMap(() => scenarioApi.isInSync(scenarioId)),
        handleError(),
        tap(isInSync => dispatchAction(setScenarioIsInSync({ isInSync }))),
        switchMap(() => loadAndFixScenario(scenarioId)),
        handleError(),
        filter(response => {
          if (response.workspaces.length === 0) {
            dispatchAction(closeLoadingScenarioProgressBar());
            alert({
              title: 'Empty scenario',
              text: 'Sorry, for now, you are unable to open an empty scenario. Instead, go to the hamburger menu and click update components to add content.',
            });
            return false;
          }
          return true;
        }),
        tap(scenarioData => {
          checkDataConsistencyAndLogError(scenarioData, scenarioId);
          trackOpenScenario(
            trackingClickSource,
            getScopeSize(scenarioData),
            scenarioId
          );
          const transaction = profiling.startTransaction(
            'open scenario',
            2000,
            profiling.Team.IMPACT
          );
          handleLoadedScopeData(scenarioId, scenarioData, workspaceId);

          dispatchAction(
            requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
          );
          dispatchAction(closeLoadingScenarioProgressBar());

          if (componentId && Components.collection.get(componentId)) {
            setTimeout(async () => {
              await Context.setComponent(
                Components.collection.get(componentId)
              );
              dispatchAction(openScenarioSuccess());
            });
          } else if (workspaceId && Workspaces.collection.get(workspaceId)) {
            setTimeout(async () => {
              await Context.loadWorkspaces({
                contextWorkspace: Workspaces.collection.get(workspaceId),
              });
              dispatchAction(openScenarioSuccess());
            });
          } else if (scenarioInterface.exists(scenarioId)) {
            setTimeout(() => {
              Context.setScenarioId(scenarioId);
              dispatchAction(openScenarioSuccess());
            });
          } else {
            dispatchAction(openScenarioSuccess());
          }
          dispatchAction(hideRightPane()); // closing sidebar menu
          // If the scenario is loaded from a presentation slide
          // grouping rules and filters from that slide will be set.
          if (!isPresentationSlide) {
            preserveGroupsAndFormattingFilters();
          }
          profiling.endTransaction(transaction);
        })
      )
  ),
  genericScenarioErrorHandler(
    'Error occured on trying to open a scenario',
    () => dispatchAction(openScenarioError())
  )
);

const preserveGroupsAndFormattingFilters = async () => {
  const formattingFilters = Filters.getFormattingFilters();
  const groupingRules = GroupByCollection.toArray();
  await resetPerspectives();
  Filters.set(formattingFilters);
  GroupByCollection.set(groupingRules);
  dispatchAction(triggerFiltersChangedEvent());
};

const checkDataConsistencyAndLogError = (
  { components, references }: APIScopeData,
  scenarioId: ArdoqId
) => {
  const componentIds = new Set(components.map(({ _id }) => _id));
  const errorMessage = references.reduce(
    (currentMessage, { _id, source, target }) => {
      const missingSourceMessage = componentIds.has(source)
        ? ''
        : `Component ${_id} is missing source: ${source}.`;
      const missingTargetMessage = componentIds.has(target)
        ? ''
        : `Component ${_id} is missing target: ${target}.`;
      const messageToAppend = `${missingSourceMessage}${missingTargetMessage}`;
      return currentMessage + messageToAppend;
    },
    ''
  );
  const hasMissingTargetOrSource = !!errorMessage;
  if (hasMissingTargetOrSource) {
    logError(Error('Missing source or target in scope data'), errorMessage, {
      scenarioId,
    });
  }
};

const reloadScenarioRoutine = routine(
  ofType(reloadScenario),
  extractPayload(),
  switchMap(({ scenarioId }) => loadAndFixScenario(scenarioId)),
  handleError(),
  tap(scenarioData => {
    const transaction = profiling.startTransaction(
      'reload scenario',
      2000,
      profiling.Team.IMPACT
    );

    clearComponentHierarchies();

    setCollections(scenarioData);
    scenarioData.models.forEach(({ _id }) => addCSSForModel(_id));
    clearAllCidStyle();
    const components = scenarioData.components
      .map(({ _id }) => Components.collection.get(_id))
      .filter(ExcludeFalsy);
    addCSSForComponents(components);

    if (!isCurrentScenarioInSync()) {
      updateSyncStatusIfChanged();
    }

    dispatchAction(setLoadedScenarioData({ scenarioData }));
    dispatchAction(reloadScenarioSuccess());

    profiling.endTransaction(transaction);
  }),
  genericScenarioErrorHandler(
    'Something went wrong adding to the scenario',
    () => dispatchAction(reloadScenarioError())
  )
);

const handleLoadedScopeData = (
  scenarioId: ArdoqId,
  scenarioData: APIScenarioScopeData,
  workspaceId?: ArdoqId
) => {
  dispatchAction(setScenarioId({ scenarioId }));
  [Workspaces, Models, Fields, Components, References, Tags].forEach(
    ({ collection }) => collection.reset([], { silent: true })
  );
  clearComponentHierarchiesAndCloseWorkspaces();
  setCollections(scenarioData, workspaceId);
  dispatchAction(setLoadedScenarioData({ scenarioData }));
};

const setCollections = (
  scenarioData: APIScenarioScopeData,
  workspaceId?: ArdoqId
) => {
  const {
    workspaces,
    components,
    references,
    connected,
    tags,
    models,
    fields,
  } = scenarioData;

  const preUpdateCache = {
    components: new Set(Components.collection.map(m => m.id)),
    references: new Set(References.collection.map(m => m.id)),
  };

  Fields.collection.invalidateFieldsCache();

  setModelsToCollections({
    workspaces: [...connected.workspaces, ...workspaces],
    models: [...connected.models, ...models],
    fields: [...connected.fields, ...fields],
    components: [...connected.components, ...components],
    references: [...connected.references, ...references],
    tags: tags,
  });

  setDefaultCollectionView(
    workspaces,
    connected.workspaces,
    Workspaces.collection
  );

  setDefaultCollectionView(
    components,
    connected.components,
    Components.collection
  );

  setDefaultCollectionView(
    references,
    connected.references,
    References.collection
  );

  setDefaultCollectionView(fields, connected.fields, Fields.collection);

  const afterUpdateCache = {
    components: new Set(Components.collection.map(m => m.id)),
    references: new Set(References.collection.map(m => m.id)),
  };

  const added = {
    components: [...afterUpdateCache.components].filter(
      id => !preUpdateCache.components.has(id)
    ),
    references: [...afterUpdateCache.references].filter(
      id => !preUpdateCache.references.has(id)
    ),
  };

  const removed = {
    components: [...preUpdateCache.components].filter(
      id => !afterUpdateCache.components.has(id)
    ),
    references: [...preUpdateCache.references].filter(
      id => !afterUpdateCache.references.has(id)
    ),
  };

  dispatchAction(setTagsFromScenario(tags));
  if (added.components.length) {
    dispatchAction(notifyComponentsAdded({ componentIds: added.components }));
  }
  if (removed.components.length) {
    dispatchAction(
      notifyComponentsRemoved({ componentIds: removed.components })
    );
  }
  if (added.references.length) {
    dispatchAction(notifyReferencesAdded({ referenceIds: added.references }));
  }
  if (removed.references.length) {
    dispatchAction(
      notifyReferencesRemoved({ referenceIds: removed.references })
    );
  }

  const inContext = Context.workspaces().map(w => w.getId());
  const areAnyWorkspacesInContext = inContext.length > 0;

  let workspaceModels = workspaces
    .filter(w => !inContext.includes(w._id))
    .map(w => Workspaces.collection.get(w._id))
    .filter(ExcludeFalsy);

  const activeWorkspace =
    workspaceId && workspaceModels.find(({ id }) => id === workspaceId);

  if (activeWorkspace) {
    workspaceModels = [
      activeWorkspace,
      ...workspaceModels.filter(model => model !== activeWorkspace),
    ];
  }

  workspaceModels.forEach(workspace => {
    Components.collection.hierarchy.clearWorkspaceHierarchy(workspace.id);
    workspace.aggregateLoaded = true;
    dispatchAction(
      notifyWorkspaceAggregatedLoaded({ workspaceId: workspace.getId() })
    );
  });

  const [contextWorkspace, ...backgroundWorkspaces] = workspaceModels;
  // Set first workspace to context if none is present
  if (contextWorkspace && !areAnyWorkspacesInContext) {
    Context.loadWorkspaces({
      contextWorkspace,
      workspaces: backgroundWorkspaces,
    });
  } else {
    Context.loadWorkspaces({ workspaces: workspaceModels });
  }
};

const hideWelcomeModal = async () =>
  (await scenariosUserSettings.get('hideWelcomeModal')) || false;

const onOpenScenarioModalRoutine = routine(
  ofType(openScenarioSuccess),
  tap(async () => {
    if (!isCurrentScenarioInSync()) {
      const isSyncNow = await openScenarioIsOutOfSyncModal();
      if (isSyncNow) {
        return;
      }
    }
    if (!(await hideWelcomeModal())) {
      await openWelcomeScenarioModal();
      intercom.startIntercomTour(SCENARIO_TOUR_ID);
    }
  })
);

const closeScenarioRoutine = routine(
  ofType(closeScenario),
  tap(async () => {
    dispatchAction(setScenarioId({ scenarioId: null }));
    dispatchAction(setScenarioIsInSync({ isInSync: false }));
    dispatchAction(updateScopeDiff({ scopeDiff: null }));
    dispatchAction(
      setLoadedScenarioData({
        scenarioData: null,
      })
    );
    dispatchAction(
      updateEnhancedScopeDiffData({
        main: null,
        branch: null,
        branchOff: null,
        diffData: null,
      })
    );

    Context.workspaces().forEach(workspace => {
      Components.collection.hierarchy.clearWorkspaceHierarchy(workspace.id);
      Context.justCloseWorkspace(workspace);
    });
    Context.closeAllWorkspacesSilently();

    References.collection.reset();
    Components.collection.reset();
    Tags.collection.reset();
    Fields.collection.reset();
    Models.collection.reset();
    Workspaces.collection.reset();
    resetPerspectives();
    const options = {
      silent: true,
      sort: false,
    };

    const fetchModelsPromise = dispatchActionAndWaitForResponse(
      fetchAllModels(),
      fetchAllModelsSucceeded,
      fetchAllModelsFailed
    );

    await Promise.all([
      Workspaces.collection.fetch(options),
      fetchModelsPromise,
      Fields.collection.fetch(options),
    ]);

    dispatchAction(notifyScenarioChanged(null));
    dispatchAction(closeScenarioSuccess());
  }),
  genericScenarioErrorHandler('Error occured on trying to close a scenario')
);

const loadDiffRoutine = routine(
  ofType(loadDiff),
  tap(() => {
    dispatchAction(
      updateLoadedScenarioData({
        components: Components.collection.toJSON(),
        references: References.collection.toJSON(),
      })
    );
    dispatchAction(hideScopeRelatedNavigator());
  }),
  extractPayload(),
  apply(({ scenarioId }) =>
    pipe(
      switchMap(() => loadAndFixDiffContext(scenarioId!)),
      handleError(),
      tap(({ master, branch, branchOff }) => {
        if (scenarioId && scenarioId !== getActiveScenarioId()) {
          handleLoadedScopeData(scenarioId, branch);
        }

        handleDiffContext({
          master,
          branch,
          branchOff,
        });

        dispatchAction(loadDiffSuccess());
      })
    )
  ),
  genericScenarioErrorHandler(
    'Error occured on trying to load the diff to master',
    () => dispatchAction(loadDiffError())
  )
);

type HandleDiffContextArguments = {
  master: APIScopeData;
  branch: APIScopeData;
  branchOff: APIScopeData;
};

export const handleDiffContext = ({
  master,
  branch,
  branchOff,
}: HandleDiffContextArguments) => {
  const branchData = enhanceScopeData(branch, {
    shouldFreeze: false,
  });
  const masterData = enhanceScopeData(master, {
    shouldFreeze: false,
  });
  const dynamicFilter = getDynamicFilter(branchData, masterData);

  const { diffData } = diffSourceToTarget({
    sourceData: branch,
    targetData: master,
    dynamicFilter,
    ignoredProperties,
  });

  dispatchAction(
    updateEnhancedScopeDiffData({
      main: enhanceScopeDataWithBranchName(masterData, Branch.MAIN),
      branch: enhanceScopeDataWithBranchName(branchData, Branch.BRANCH),
      branchOff: enhanceScopeDataWithBranchName(
        enhanceScopeData(
          {
            ...branchOff,
            workspaces: getWorkspacesFromMainlineAndBranch(master, branch),
          },
          { shouldFreeze: false }
        ),
        Branch.BRANCH_OFF
      ),
      diffData,
    })
  );

  const {
    branch: branchComponents,
    master: masterComponents,
    diff: diffComponents,
  } = getEntities(branch.components, master.components, diffData.components);

  const {
    branch: branchReferences,
    master: masterReferences,
    diff: diffReferences,
  } = getEntities(branch.references, master.references, diffData.references);

  dispatchAction(
    updateScopeDiff({
      scopeDiff: {
        branchComponents,
        masterComponents,
        diffComponents,
        branchReferences,
        masterReferences,
        diffReferences,
      },
    })
  );
};

export const scopeRoutines = collectRoutines(
  openScenarioRoutine,
  closeScenarioRoutine,
  loadDiffRoutine,
  reloadScenarioRoutine,
  onOpenScenarioModalRoutine
);
