import { reducer } from '@ardoq/rxbeach';
import { RolesStreamShape } from './types';
import { APIRoleAttributes } from '@ardoq/api-types';
import { fetchRoles, notifyFailedToFetchRoles, setRoles } from './actions';
import { roleComparator } from './utils';

export const defaultState: RolesStreamShape = {
  status: 'idle',
  roles: [],
};

const handleSetRoles = (
  state: RolesStreamShape,
  roles: APIRoleAttributes[]
): RolesStreamShape => {
  return {
    ...state,
    roles: [...roles].sort((a, b) => roleComparator(a.label, b.label)),
    status: 'success',
  };
};

const handleFetchRoles = (state: RolesStreamShape): RolesStreamShape => {
  if (state.status === 'success' || state.status === 'revalidating') {
    return { ...state, status: 'revalidating' };
  }
  return { ...state, status: 'loading' };
};

const handleNotifyFailedToFetchRoles = (): RolesStreamShape => ({
  status: 'error',
  roles: [],
});

export const reducers = [
  reducer(setRoles, handleSetRoles),
  reducer(fetchRoles, handleFetchRoles),
  reducer(notifyFailedToFetchRoles, handleNotifyFailedToFetchRoles),
];
