import {
  APIFieldType,
  DictionaryItem,
  DictionaryLookupTransformation,
} from '@ardoq/api-types';
import { SecondaryButton, GhostButton } from '@ardoq/button';
import { s8 } from '@ardoq/design-tokens';
import {
  Field,
  Label,
  NumberInput,
  TextInput,
  useOptimistic,
} from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { FieldType } from '@ardoq/renderers';
import { Select, SelectOption } from '@ardoq/select';
import React from 'react';
import styled from 'styled-components';
import { EditorSectionProps } from './TransformationsEditor';
import { AutocompleteFieldSuggestion, InputConfig } from './types';
import { getSelectedDictionaryLookupFieldSelectedOption } from './utils';

const defaultDictionaryItem: DictionaryItem = {
  key: '',
  value: '',
};

export const DictionaryLookupDetailsSection = ({
  type,
  value: transformation,
  onValueChange: onTransformationChange,
  reactiveFieldOptions,
  options,
}: EditorSectionProps<DictionaryLookupTransformation>) => {
  const selectedOption = getSelectedDictionaryLookupFieldSelectedOption(
    transformation.config.additionalFields[0],
    reactiveFieldOptions
  );

  const dictionary = getDictionaryItems(transformation, defaultDictionaryItem);

  const setDictionary = (dictionary: DictionaryItem[]) =>
    onTransformationChange({
      ...transformation,
      config: { ...transformation.config, dictionary },
    });

  const handleFieldChange = (field: AutocompleteFieldSuggestion | null) =>
    onTransformationChange({
      ...transformation,
      config: {
        ...transformation.config,
        additionalFields: field ? [field.name] : [],
        dictionary: [],
      },
    });

  const handleChangeItem =
    (itemIndex: number) => (entry: Partial<DictionaryItem>) =>
      setDictionary(
        dictionary.map((item, index) =>
          index === itemIndex ? { ...item, ...entry } : item
        )
      );

  const handleRemoveItem = (itemIndex: number) => () =>
    setDictionary(dictionary.filter((_item, index) => index !== itemIndex));

  const handleAddNewItem = () => {
    setDictionary([...dictionary, defaultDictionaryItem]);
  };

  return (
    <>
      <Field label="Field">
        <Select
          placeholder="Select field"
          options={reactiveFieldOptions}
          value={selectedOption}
          onValueChange={handleFieldChange}
        />
      </Field>
      {selectedOption && (
        <LookupDictionary>
          <Label>Map from</Label>
          <Label>Map to</Label>

          {dictionary.map((entry, index) => {
            return (
              <React.Fragment key={index}>
                <From>
                  <DictionaryInput
                    inputConfig={selectedOption.value}
                    value={entry.key}
                    onValueChange={key =>
                      handleChangeItem(index)({ key: key ?? undefined })
                    }
                  />
                </From>
                <To>
                  {type === FieldType.TRANSFORM_LIST ? (
                    <DictionaryInput
                      inputConfig={{ type, options: options ?? [] }}
                      value={entry.value}
                      onValueChange={value =>
                        handleChangeItem(index)({ value: value ?? undefined })
                      }
                    />
                  ) : (
                    <DictionaryInput
                      inputConfig={{ type }}
                      value={entry.value}
                      onValueChange={value =>
                        handleChangeItem(index)({ value: value ?? undefined })
                      }
                    />
                  )}
                </To>
                <GhostButton
                  aria-label="Remove row"
                  isDisabled={dictionary.length === 1}
                  onClick={handleRemoveItem(index)}
                >
                  <Icon iconName={IconName.REMOVE} />
                </GhostButton>
              </React.Fragment>
            );
          })}
          <AddNewButton onClick={handleAddNewItem}>
            <Icon iconName={IconName.ADD} /> Add row
          </AddNewButton>
        </LookupDictionary>
      )}
    </>
  );
};

const DictionaryInput = ({
  inputConfig,
  value: externalValue,
  onValueChange: onExternalValueChange,
}: {
  inputConfig: InputConfig;
  value: string | number;
  onValueChange: (value: string | number | null) => void;
}) => {
  const [value, onValueChange] = useOptimistic(
    externalValue,
    onExternalValueChange
  );

  if (
    inputConfig.type === APIFieldType.NUMBER ||
    inputConfig.type === FieldType.TRANSFORM_NUMBER
  ) {
    return (
      <NumberInput
        value={toNumberInputValue(value)}
        onValueChange={onValueChange}
      />
    );
  }

  if (
    inputConfig.type === APIFieldType.LIST ||
    inputConfig.type === FieldType.TRANSFORM_LIST
  ) {
    return (
      <Select
        placeholder="Select option"
        options={inputConfig.options.map(option =>
          typeof option === 'string'
            ? { label: option, value: option }
            : (option as SelectOption<string>)
        )}
        value={value}
        onValueChange={onValueChange}
      />
    );
  }

  return <TextInput value={value ?? undefined} onValueChange={onValueChange} />;
};

const toNumberInputValue = (
  value: string | number | null
): number | undefined => {
  if (!value || value === '') {
    return undefined;
  }

  const number = Number(value);

  return Number.isNaN(number) ? undefined : number;
};

const getDictionaryItems = (
  transformation: DictionaryLookupTransformation,
  defaultItem: DictionaryItem
): DictionaryItem[] => {
  if (
    !transformation.config.dictionary ||
    transformation.config.dictionary.length === 0
  ) {
    return [defaultItem];
  }

  return transformation.config.dictionary;
};

const LookupDictionary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  gap: ${s8};
`;

const From = styled.div`
  grid-column: 1;
`;

const To = styled.div`
  grid-column: 2;
`;

const AddNewButton = styled(SecondaryButton)`
  grid-column: 2;
`;
