import { DataSource, Path } from 'components/DiffMergeTable/types';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIModelAttributes,
  APIReferenceAttributes,
  APITagAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { getStatusFromChildren } from 'components/DiffMergeTable/getStatusFromChildren';
import { collectionToEntityType, hasMergePermission } from '../utils';
import {
  getEntitiesFromEnhancedDiffContext,
  getHeader,
  getIndexer,
  getPropertyRow,
  getSubHeader,
  mergeFieldConfig,
} from './toDataSourceHelpers';

const getFieldNameFilter =
  (fieldNameWhiteList: Set<string> | null) => (fieldName: string) =>
    !fieldNameWhiteList || fieldNameWhiteList.has(fieldName);

export const toDataSourceUpdate = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  basePath: Path
): DataSource => {
  const [collection, verb] = basePath;
  const entities = getEntitiesFromEnhancedDiffContext(
    enhancedDiffContextData,
    collection,
    verb
  );
  if (!entities) {
    return [];
  }
  const entityType = collectionToEntityType[collection];
  const fieldNameWhiteList =
    entityType === APIEntityType.TAG ? new Set(['description', 'name']) : null;
  const getIndex = getIndexer();
  const rootIndex = getIndex();
  const rootChildren: number[] = [];

  const updateRows = Object.entries(entities).flatMap(
    ([entityId, updates]: [
      ArdoqId,
      (
        | APIComponentAttributes
        | APIFieldAttributes
        | APIReferenceAttributes
        | APIModelAttributes
        | APITagAttributes
      ),
    ]) => {
      const filteredProperties = Object.keys(
        entityType === APIEntityType.FIELD
          ? mergeFieldConfig(updates as APIFieldAttributes)
          : updates
      ).filter(getFieldNameFilter(fieldNameWhiteList));
      if (!filteredProperties.length) return [];

      const hasWritePermission = hasMergePermission(
        entityId,
        entityType,
        enhancedDiffContextData
      );
      const parentIndex = getIndex();
      rootChildren.push(parentIndex);
      const propertyRowIndexes: number[] = [];
      const propertyRows = filteredProperties.map(fieldName => {
        const index = getIndex();
        propertyRowIndexes.push(index);
        return getPropertyRow({
          entityType,
          entityId,
          basePath,
          enhancedDiffContextData,
          index,
          parentIndex,
          fieldName,
          updates,
          verb,
          hasWritePermission,
        });
      });

      return [
        getSubHeader({
          entityType,
          entityId,
          basePath,
          enhancedDiffContextData,
          parent: rootIndex,
          verb,
          index: parentIndex,
          children: propertyRowIndexes,
          status: getStatusFromChildren(propertyRows, propertyRows[0]!.status),
          isDisabled: propertyRows.every(({ isDisabled }) => isDisabled),
          hasWritePermission,
        }),
        ...propertyRows,
      ];
    }
  );

  if (updateRows.length) {
    return [
      getHeader({
        entityType,
        basePath,
        enhancedDiffContextData,
        rootIndex,
        rootChildren,
        status: getStatusFromChildren(updateRows, updateRows![0].status),
        isDisabled: updateRows.every(({ isDisabled }) => isDisabled),
      }),
      ...updateRows,
    ];
  }
  return [];
};
