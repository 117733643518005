import {
  resetMergeState,
  setMergeData,
  setMergeState,
  setMergeStep,
  toggleIsExpandedTableRow,
} from './actions';
import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import {
  type DataSource,
  DiffTableRowType,
  MergeState,
  MergeStep,
  type PayloadSetMergeData,
  type PayloadSetMergeState,
  type PayloadSetMergeStep,
  type PayloadToggleIsExpandedTableRow,
  type ViewModel,
} from './types';
import { logError } from '@ardoq/logging';
import { initMerge } from 'scope/merge/actions';
import { MergeDirection } from 'scope/merge/MergeDirection';
import { enhanceDiffContextData } from './enhanceDiffContextData';
import { getInitialMergeState } from 'components/DiffMergeTable/getInitialMergeState';
import { toDataSource } from './utils/toDataSource';
import { uniq } from 'lodash';
import {
  getNewDataSource,
  getNewDataSourceAndUpdateParentAndChildren,
  getRowIndexesToApplyAutoselectOnAncestorsOrDescendants,
  updateParent,
} from './diffMergeTableHelpers';

import { syncGlobalFieldProperties } from './syncGlobalFieldProperties';

const setMergeDataReducer = (
  state: ViewModel,
  payload: PayloadSetMergeData
): ViewModel => ({
  ...state,
  ...buildState({
    ...payload,
    mergeDirection: state.mergeDirection,
    mergeStep: state.mergeStep,
  }),
});

const initMergeReducer = (state: ViewModel, mergeDirection: MergeDirection) => {
  if (state.responseData) {
    return {
      ...state,
      ...buildState({
        ...state.responseData,
        users: state.users,
        graphics: state.graphics,
        options: state.options,
        mergeDirection,
        mergeStep: state.mergeStep,
      }),
    };
  }

  const newState = {
    ...state,
    mergeDirection,
  };
  return newState;
};

const buildState = ({
  master,
  branch,
  branchOff,
  users,
  graphics,
  options,
  mergeDirection,
  mergeStep,
}: PayloadSetMergeData & {
  mergeDirection: MergeDirection;
  mergeStep: MergeStep;
}) => {
  if (
    !(
      master &&
      branch &&
      branchOff &&
      users &&
      graphics &&
      options &&
      mergeDirection !== MergeDirection.NONE
    )
  ) {
    return {
      responseData: {
        master,
        branch,
        branchOff,
      },
      mergeDirection,
      enhancedDiffContextData: null,
      dataSource: null,
      users: null,
      graphics: null,
      options: null,
    };
  }

  const enhancedDiffContextData = enhanceDiffContextData(
    master,
    branch,
    branchOff,
    mergeDirection
  );

  const dataSource =
    mergeStep === MergeStep.NONE
      ? null
      : toDataSource(enhancedDiffContextData, mergeStep, mergeDirection);

  return {
    responseData: {
      master,
      branch,
      branchOff,
    },
    mergeDirection,
    enhancedDiffContextData,
    dataSource,
    users,
    graphics,
    options,
  };
};

const setMergeStepReducer = (
  state: ViewModel,
  { mergeStep }: PayloadSetMergeStep
) => {
  const dataSource =
    mergeStep === MergeStep.NONE
      ? null
      : toDataSource(
          state.enhancedDiffContextData,
          mergeStep,
          state.mergeDirection
        );
  return {
    ...state,
    dataSource,
    mergeStep,
  };
};

const getRowIndexesToApplyAutoselectOnAncestorsOrDescendantsForParentAndChildren =
  (
    dataSource: DataSource | null,
    parentIndex: number,
    mergeStatus: MergeState
  ) => {
    if (!dataSource) return [];
    const row = dataSource[parentIndex];
    // children should be considered only if the selected row is a section header
    const children =
      (row.rowType === DiffTableRowType.SECTION_HEADER_ROW && row.children) ||
      [];
    const parentAndChildren = [parentIndex, ...children];
    return uniq(
      parentAndChildren.flatMap(index =>
        getRowIndexesToApplyAutoselectOnAncestorsOrDescendants(
          dataSource,
          index,
          mergeStatus
        )
      )
    );
  };

const setMergeStateReducer = (
  state: ViewModel,
  { index, mergeStatus }: PayloadSetMergeState
) => {
  let dataSource = state.dataSource;
  dataSource =
    getRowIndexesToApplyAutoselectOnAncestorsOrDescendantsForParentAndChildren(
      dataSource,
      index,
      mergeStatus
    ).reduce(
      (accDataSource, ancestorIndex) =>
        accDataSource
          ? getNewDataSourceAndUpdateParentAndChildren(
              accDataSource,
              ancestorIndex,
              mergeStatus
            )
          : null,
      dataSource
    );

  dataSource =
    getNewDataSourceAndUpdateParentAndChildren(
      dataSource,
      index,
      mergeStatus
    ) || dataSource;

  dataSource = syncGlobalFieldProperties(
    state.enhancedDiffContextData,
    dataSource,
    index,
    state.mergeStep
  );

  return { ...state, dataSource };
};

const resetStatusToInitialMergeState = (
  dataSource: DataSource,
  index: number
): DataSource => {
  if (!dataSource?.[index]) {
    logError(
      Error('no dataSource or index out of range in handleResetMergeState')
    );
    return dataSource;
  }

  if (dataSource[index].children?.length) {
    const newDataSource = dataSource[index].children!.reduce(
      (data, childIndex) => resetStatusToInitialMergeState(data, childIndex),
      dataSource
    );
    if (
      newDataSource[index].children!.every(
        childIndex =>
          newDataSource[childIndex].status ===
          newDataSource[newDataSource[index].children![0]].status
      ) // all children have same status
    ) {
      return getNewDataSource(
        newDataSource,
        index,
        newDataSource[newDataSource[index].children![0]].status
      );
    }
    return getNewDataSource(newDataSource, index, MergeState.NONE);
  }

  return getNewDataSource(
    dataSource,
    index,
    getInitialMergeState({
      entityId: dataSource[index].entityId,
      entityType: dataSource[index].entityType,
      fieldName: dataSource[index].fieldName,
      enhancedDiffContextData: dataSource[index].enhancedDiffContextData,
      parentEntityId: dataSource[index].parentEntityId,
      hasWritePermission: dataSource[index].hasWritePermission,
    })
  );
};

const resetMergeStateReducer = (state: ViewModel, index: number) => {
  if (!state.dataSource) return state;
  const newDataSource = resetStatusToInitialMergeState(state.dataSource, index);
  updateParent(newDataSource[index], newDataSource);
  return {
    ...state,
    dataSource: newDataSource,
  };
};

const toggleIsExpandedTableRowReducer = (
  state: ViewModel,
  { index }: PayloadToggleIsExpandedTableRow
) => {
  const { dataSource } = state;
  if (!dataSource?.[index]) {
    logError(
      Error('no dataSource or index out of range in handleToggleIsExpanded')
    );
    return state;
  }

  const entry = {
    ...dataSource[index],
    ...{ isExpanded: !dataSource[index].isExpanded },
  };
  const newDataSource = Object.assign([], dataSource, { [index]: entry });

  return {
    ...state,
    dataSource: newDataSource,
  };
};

const reducers = [
  reducer(initMerge, initMergeReducer),
  reducer(setMergeData, setMergeDataReducer),
  reducer(setMergeState, setMergeStateReducer),
  reducer(resetMergeState, resetMergeStateReducer),
  reducer(setMergeStep, setMergeStepReducer),
  reducer(toggleIsExpandedTableRow, toggleIsExpandedTableRowReducer),
];

const defaultState: ViewModel = {
  responseData: {
    master: null,
    branch: null,
    branchOff: null,
  },
  mergeDirection: MergeDirection.NONE,
  mergeStep: MergeStep.NONE,
  enhancedDiffContextData: null,
  dataSource: null,
  graphics: null,
  users: null,
  options: null,
};

const diffMergeTable$ = persistentReducedStream<ViewModel>(
  'diffMergeTable$',
  defaultState,
  reducers
);

export default diffMergeTable$;
