import {
  AddWorkspaceFieldPayload,
  addFieldByName,
  addFieldNewToWorkspace,
  addWorkspaceField,
  createFieldByLabel,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/actions';
import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import globalFields$ from 'globalFields/globalFields$';
import { tap, withLatestFrom } from 'rxjs/operators';
import { APIEntityType } from '@ardoq/api-types';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { getEntityModelAndTypeIDs } from '@ardoq/renderers';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { beginNestedCreateFieldWorkflow } from 'appModelStateEdit/actions';
import { logWarn } from '@ardoq/logging';
import { OLD_DEFAULT_FIELD_ORDER } from 'models/consts';

const handleAddFieldByNameAction = routine(
  ofType(addFieldByName),
  extractPayload(),
  withLatestFrom(appModelStateEdit$, globalFields$),
  tap(
    ([
      fieldName,
      { entityIDs, entityType, enhancedScopeData },
      { globalFields },
    ]) => {
      switch (entityType) {
        case APIEntityType.COMPONENT:
        case APIEntityType.REFERENCE: {
          if (!enhancedScopeData) {
            return;
          }
          const entityTypeKey: 'componentType' | 'referenceType' =
            `${entityType}Type`;
          const { model, typeIDs } = getEntityModelAndTypeIDs(
            entityType,
            entityIDs,
            enhancedScopeData
          );
          if (!model || !typeIDs) {
            return;
          }
          const workspaceField = enhancedScopeData.fields.find(
            field => field.name === fieldName
          );
          if (workspaceField) {
            const entityTypes = workspaceField[entityTypeKey];
            const types = new Set([...entityTypes, ...typeIDs]);
            const addWorkspaceFieldPayload = {
              name: workspaceField.name,
              [entityTypeKey]: Array.from(types),
            } as AddWorkspaceFieldPayload;
            dispatchAction(addWorkspaceField(addWorkspaceFieldPayload));
            return;
          }
          const field = globalFields.find(
            currentField => currentField.name === fieldName
          );
          if (!field) {
            return;
          }
          dispatchAction(
            addFieldNewToWorkspace({
              ...field,
              _order: OLD_DEFAULT_FIELD_ORDER,
              model,
              global: false,
              globalref: false,
              componentType: [],
              referenceType: [],
              [entityTypeKey]: typeIDs,
            })
          );
        }
      }
    }
  )
);

const handleCreateFieldByLabel = routine(
  ofType(createFieldByLabel),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(([label, { entityIDs, entityType, enhancedScopeData }]) => {
    switch (entityType) {
      case APIEntityType.COMPONENT:
      case APIEntityType.REFERENCE: {
        if (!enhancedScopeData) {
          return;
        }
        const entityTypeKey: 'componentType' | 'referenceType' =
          `${entityType}Type`;
        const { model, typeIDs } = getEntityModelAndTypeIDs(
          entityType,
          entityIDs,
          enhancedScopeData
        );
        if (!model || !typeIDs) {
          logWarn(
            new Error(
              `No model or typeId found for entity IDs: ${entityIDs.toString()} of entity type: ${entityType}`
            )
          );
          return;
        }
        dispatchAction(
          beginNestedCreateFieldWorkflow({
            type: GetContentOptionsType.CREATE_FIELD,
            label,
            attributes: {
              [entityTypeKey]: typeIDs,
            },
          })
        );
      }
    }
  })
);

const routines = [handleAddFieldByNameAction, handleCreateFieldByLabel];
export default routines;
