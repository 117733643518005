import { Observable, Subject } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { filter, share } from 'rxjs/operators';
import { ArdoqEvent } from './types';
import { Entity, ResourceType } from '@ardoq/api-types';
import { ardoqEventOperations } from './ardoqEventOperations';

const websocketSubject$ = new Subject<ArdoqEvent<unknown>>();

export const websocket$: Observable<ArdoqEvent<unknown>> =
  websocketSubject$.pipe(tag('websocket$'), share());

export const toResourceTypeStream = <T extends Entity>(
  stream: Observable<ArdoqEvent<unknown>>,
  resourceType: ResourceType
): Observable<ArdoqEvent<T>> =>
  stream.pipe(
    filter(event => ardoqEventOperations.isOfResourceType(event, resourceType))
  ) as Observable<ArdoqEvent<T>>;

/**
 * Dispatch an event to the websocket stream
 * @param event The event to dispatch to websocket$
 */
export const dispatchWebsocketEvent = (event: ArdoqEvent<unknown>) => {
  websocketSubject$.next(event);
};
