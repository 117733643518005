import { AppModelStateEditStreamShape } from 'appModelStateEdit/types';
import {
  AddWorkspaceFieldPayload,
  addFieldNewToWorkspace,
  addWorkspaceField,
  removeField,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/actions';
import { reducer } from '@ardoq/rxbeach';
import {
  applyFieldNewToWorkspaceToType,
  applyWorkspaceFieldToType,
  unapplyFieldNewToWorkspaceToType,
  unapplyWorkspaceFieldToType,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/reducerUtils';
import { AddFieldNewToWorkspacePayload } from 'appModelStateEdit/propertiesEditor/addField/types';

const addWorkspaceFieldReducer = (
  state: AppModelStateEditStreamShape,
  field: AddWorkspaceFieldPayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  const newScopeData = applyWorkspaceFieldToType(
    state.entityType,
    state.enhancedScopeData,
    field
  );
  const addedFields = new Set(state.addedFields);
  addedFields.add(field.name);
  return {
    ...state,
    addedFields,
    enhancedScopeData: newScopeData,
  };
};
const handleAddWorkspaceField = reducer<
  AppModelStateEditStreamShape,
  AddWorkspaceFieldPayload
>(addWorkspaceField, addWorkspaceFieldReducer);

const addFieldNewToWorkspaceReducer = (
  state: AppModelStateEditStreamShape,
  field: AddFieldNewToWorkspacePayload
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  const newScopeData = applyFieldNewToWorkspaceToType(
    state.entityType,
    state.enhancedScopeData,
    field
  );
  const addedFields = new Set(state.addedFields);
  addedFields.add(field.name);
  const addedFieldsNewToWorkspace = new Set(state.addedFieldsNewToWorkspace);
  addedFieldsNewToWorkspace.add(field.name);
  return {
    ...state,
    addedFields,
    addedFieldsNewToWorkspace,
    enhancedScopeData: newScopeData,
  };
};
const handleAddFieldNewToWorkspace = reducer<
  AppModelStateEditStreamShape,
  AddFieldNewToWorkspacePayload
>(addFieldNewToWorkspace, addFieldNewToWorkspaceReducer);

const removeFieldReducer = (
  state: AppModelStateEditStreamShape,
  fieldName: string
) => {
  if (!state.entityType || !state.enhancedScopeData) {
    return { ...state };
  }
  if (state.addedFieldsNewToWorkspace.has(fieldName)) {
    const newScopeData = unapplyFieldNewToWorkspaceToType(
      state.entityType,
      state.enhancedScopeData,
      fieldName
    );
    const addedFields = new Set(state.addedFields);
    addedFields.delete(fieldName);
    const addedFieldsNewToWorkspace = new Set(state.addedFieldsNewToWorkspace);
    addedFieldsNewToWorkspace.delete(fieldName);
    return {
      ...state,
      addedFields,
      addedFieldsNewToWorkspace,
      enhancedScopeData: newScopeData,
    };
  }
  const newScopeData = unapplyWorkspaceFieldToType(
    state.entityType,
    state.entityIDs,
    state.enhancedScopeData,
    fieldName
  );
  const addedFields = new Set(state.addedFields);
  addedFields.delete(fieldName);
  return {
    ...state,
    addedFields,
    enhancedScopeData: newScopeData,
  };
};
const handleRemoveField = reducer<AppModelStateEditStreamShape, string>(
  removeField,
  removeFieldReducer
);

const reducers = [
  handleAddWorkspaceField,
  handleAddFieldNewToWorkspace,
  handleRemoveField,
];
export default reducers;
