import { persistentReducedStream } from '@ardoq/rxbeach';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { defaultState, reducers } from 'appModelStateEdit/reducers';

const appModelStateEdit$ = persistentReducedStream(
  'appModelStateEdit$',
  defaultState,
  reducers
).pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));

export default appModelStateEdit$;
