import {
  UserResourcePermissionsRecord,
  permissionsOperations,
} from '@ardoq/access-control';
import { OrgAccessLevel, PermissionGroup } from '@ardoq/api-types';
import {
  ActorType,
  GetCurrentUserCanEditResourceArgs,
  GroupActor,
  UserActor,
} from '@ardoq/manage-resource-permissions';
import { getUsers } from './utils';
import { caseInsensitiveStringIncludes } from '@ardoq/common-helpers';

export const getCurrentUserCanEdit = ({
  resource,
  currentUser,
  currentUserPermissions,
}: GetCurrentUserCanEditResourceArgs & {
  currentUserPermissions: UserResourcePermissionsRecord;
}): boolean => {
  if (currentUser.organization.role === OrgAccessLevel.ADMIN) {
    return true;
  }
  return permissionsOperations.hasAdminAccess(
    currentUser,
    currentUserPermissions,
    resource.resourceId
  );
};

export const getActorsOptions = async ({
  search,
  permissionGroups,
  currentOrgLabel,
}: {
  search: string;
  permissionGroups: PermissionGroup[];
  currentOrgLabel: string;
}) => {
  const users: UserActor[] = (await getUsers(search, true)).map(
    user =>
      ({
        ...user,
        type: ActorType.USER,
        role: user.orgRole,
        actorId: user._id,
      }) satisfies UserActor
  );
  const groups: GroupActor[] = permissionGroups
    .filter(({ name }) => caseInsensitiveStringIncludes(name, search))
    .map(
      group =>
        ({
          ...group,
          type: ActorType.GROUP,
          actorId: `${currentOrgLabel}/${group.label}`,
          groupLabel: group.label,
        }) satisfies GroupActor
    );

  return [...groups, ...users];
};
