export const CROSS_WORKSPACE_EDIT_MESSAGE = 'Cannot edit across workspaces';
export const NO_MESSAGE = '';
export const CALCULATED_FIELD_EDIT_MESSAGE =
  'Calculated field data is read-only.';
export const LOCALLY_DERIVED_FIELD_EDIT_MESSAGE =
  'Reactive fields are read-only.';
export const MANAGED_FIELD_EDIT_MESSAGE =
  'This data is managed externally and editing is restricted';
export const LOCKED_MESSAGE =
  'Some of the components or references being edited are locked.';
export const GENERIC_FORM_ERROR_MESSAGE = 'An error occurred.';
export const COMPONENT_CREATED_MESSAGE = 'Component created.';
