import { colors } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { StatusType, Tag } from '@ardoq/status-ui';
import { FlexBox } from '@ardoq/layout';
import { ScheduleMeta } from '@ardoq/api-types';
import { Complaint } from '@ardoq/api-types/integrations';
import { complaintsToComplaintLevel } from 'integrations/common/streams/transferState/utils';

export const ScheduleStatus = ({ meta }: ScheduleMeta) => {
  const defaultComplaints: Complaint[] =
    meta.lastResult?.status === 'error'
      ? [
          {
            level: 'error',
            messageKey: '',
            message: meta.lastResult.message || 'Unknown error',
            target: 'sheet',
            tableId: '',
          } satisfies Complaint,
        ]
      : [];

  const allComplaints = [
    ...(meta.lastResult?.summary?.validation ?? []),
    ...defaultComplaints,
  ];

  const complaintLevel = complaintsToComplaintLevel(allComplaints);

  return (
    <FlexBox gap="small">
      {meta.paused ? (
        <Tag statusType={StatusType.INFO}>Paused</Tag>
      ) : (
        <Tag statusType={StatusType.SUCCESS}>Running</Tag>
      )}
      {complaintLevel === 'Error' && (
        <Icon
          iconName={IconName.ERROR}
          size={IconSize.MEDIUM}
          style={{ color: colors.red60 }}
        />
      )}
      {complaintLevel === 'Warning' && (
        <Icon
          iconName={IconName.WARNING}
          size={IconSize.MEDIUM}
          style={{ color: colors.yellow60 }}
        />
      )}
    </FlexBox>
  );
};
