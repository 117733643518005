import styled from 'styled-components';
import {
  CellWithDropdown,
  DatasourceTable,
  newTableTheme,
  useSortableTable,
} from '@ardoq/table';
import { ExpandRowButton } from 'integrations/common/components/expandRowButton/ExpandRowButton';
import { useState } from 'react';
import { useEditableConfig } from 'integrations/common/components/editableCell/useEditableConfig';
import { smartSort } from '@ardoq/pagination';
import { ExpandedSchedule } from 'integrations/common/components/expandedSchedule/ExpandedSchedule';
import { EditableCell } from 'integrations/common/components/editableCell/EditableCell';
import { SchedulePolicy } from 'integrations/common/components/schedulePolicy/SchedulePolicy';
import { IntegrationDate } from 'integrations/common/components/integrationDate/IntegrationDate';
import { ScheduleStatus } from 'integrations/common/components/scheduleStatus/ScheduleStatus';
import { getScheduleDirection } from 'integrations/common/streams/schedules/utils';
import { DataSourceEmptyTable } from '@ardoq/integrations';
import { viewModel$ } from './viewModel$';
import { connect } from '@ardoq/rxbeach';
import { SortOrder, IntegrationSchedule } from '@ardoq/api-types';
import { IntegrationWorkspace } from '@ardoq/api-types/integrations';

type SchedulesComponentProps = {
  isLoading: boolean;
  schedules: IntegrationSchedule[];
  getConnectionName: (schedule: IntegrationSchedule) => string | null;
  onRenameSchedule: (name: string, schedule: IntegrationSchedule) => void;
  onStartScheduleRenaming: () => void;
  onPauseSchedule: (scheduleId: string) => void;
  onRunSchedule: (scheduleId: string) => void;
  onEditSchedule: (id: string) => void;
  onStartCreateConfig: (schedule: IntegrationSchedule) => void;
  onStartEditInterval: (schedule: IntegrationSchedule) => void;
  onStartDeleteSchedule: (schedule: IntegrationSchedule) => void;
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
  noSchedulesMessage: string;
  allowedConfigurations?: boolean;
};

function SchedulesComponent({
  isLoading,
  schedules,
  getConnectionName,
  onRenameSchedule,
  onStartScheduleRenaming,
  onPauseSchedule,
  onRunSchedule,
  onEditSchedule,
  onStartCreateConfig,
  onStartEditInterval,
  onStartDeleteSchedule,
  onWorkspaceClick,
  noSchedulesMessage,
  allowedConfigurations = true,
}: SchedulesComponentProps) {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const { editingId, editingName, setEditingId, setEditingName, clear } =
    useEditableConfig();

  const { sortOrder, sortBy, getSortableColumnProps } = useSortableTable({
    initialSortBy: 'jobOptions.name',
    initialSortOrder: SortOrder.ASC,
  });

  const sortedSchedules = smartSort(schedules, sortBy, sortOrder);

  return (
    <DatasourceTable
      idIndex="_id"
      loading={isLoading}
      dataSource={sortedSchedules}
      components={newTableTheme}
      expandedRowIds={expandedRowIds}
      getExpandedRow={schedule => (
        <ExpandedSchedule
          schedule={schedule}
          connectionName={getConnectionName(schedule)}
          onWorkspaceClick={onWorkspaceClick}
        />
      )}
      columns={[
        {
          dataIndex: 'jobOptions.name',
          title: 'Name',
          valueRender: (_, schedule) =>
            schedule._id === editingId ? (
              <EditableCell
                initValue={schedule.jobOptions.name}
                onValueChange={setEditingName}
                onSubmit={() => {
                  if (!editingName) return;
                  onRenameSchedule(editingName, schedule);
                }}
                onClear={clear}
              />
            ) : (
              <div>{schedule.jobOptions.name}</div>
            ),
          ...getSortableColumnProps('jobOptions.name'),
        },
        {
          title: 'Type',
          valueRender: (_, { jobOptions }) => (
            <Capitalized>{getScheduleDirection(jobOptions.type)}</Capitalized>
          ),
          ...getSortableColumnProps('jobOptions.type'),
        },
        {
          title: 'Interval',
          valueRender: (_, { schedulingPolicy }) => (
            <SchedulePolicy
              schedulingPolicy={schedulingPolicy}
            ></SchedulePolicy>
          ),
          ...getSortableColumnProps('schedulingPolicy.type'),
        },
        {
          dataIndex: 'meta.lastCompleted',
          title: 'Last sync',
          valueRender: lastCompleted => (
            <IntegrationDate date={lastCompleted} format="DATENEW" showIcon />
          ),
          ...getSortableColumnProps('meta.lastCompleted'),
        },
        {
          title: 'Status',
          valueRender: (_, { meta }) => (
            <ScheduleStatus meta={meta}></ScheduleStatus>
          ),
          ...getSortableColumnProps('meta.lastResult.status'),
        },
        {
          dataIndex: '_id',
          valueRender: id => (
            <ExpandRowButton
              expandedRowIds={expandedRowIds}
              setExpandedRowIds={setExpandedRowIds}
              rowId={id}
            />
          ),
        },
        {
          valueRender: (_, schedule) => (
            <CellWithDropdown
              options={[
                {
                  label: schedule.meta.paused ? 'Resume' : `Edit schedule`,
                  onClick: () => onEditSchedule(schedule._id),
                },
                ...(schedule.meta.paused
                  ? []
                  : [
                      {
                        label: `Edit interval`,
                        onClick: () => onStartEditInterval(schedule),
                      },
                      {
                        label: `${
                          getScheduleDirection(schedule.jobOptions.type) ===
                          'import'
                            ? 'Import'
                            : 'Export'
                        } now`,
                        onClick: () => onRunSchedule(schedule._id),
                      },
                    ]),
                ...(allowedConfigurations
                  ? [
                      {
                        label: 'Create configuration',
                        onClick: () => {
                          onStartCreateConfig(schedule);
                        },
                      },
                    ]
                  : []),
                ...(schedule.meta.paused
                  ? []
                  : [
                      {
                        label: 'Pause',
                        onClick: () => onPauseSchedule(schedule._id),
                      },
                      {
                        label: 'Rename',
                        onClick: () => {
                          onStartScheduleRenaming();
                          setEditingId(schedule._id);
                        },
                      },
                    ]),
                {
                  label: 'Delete',
                  onClick: () => onStartDeleteSchedule(schedule),
                },
              ]}
            />
          ),
        },
      ]}
      renderEmptyTable={() =>
        !isLoading ? (
          <DataSourceEmptyTable
            title="You don't have any schedules yet"
            message={noSchedulesMessage}
          />
        ) : null
      }
      scrollableSectionHeight="100%"
      fixedHeader
    />
  );
}

const Capitalized = styled.div`
  text-transform: capitalize;
`;

export const Schedules = connect(SchedulesComponent, viewModel$);
