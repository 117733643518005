import { APIFieldAttributes } from '@ardoq/api-types';

export const ADD_FIELD_TO_COMPONENT_TYPE_ATTRIBUTES: Array<
  keyof APIFieldAttributes
> = ['componentType'];

export const ADD_FIELD_TO_REFERENCE_TYPE_ATTRIBUTES: Array<
  keyof APIFieldAttributes
> = ['referenceType'];

export const ADD_FIELD_NEW_TO_WORKSPACE_ATTRIBUTES: Array<
  keyof APIFieldAttributes
> = [
  'type',
  'name',
  'label',
  'model',
  'global',
  'globalref',
  'componentType',
  'referenceType',
  'calculatedFieldSettings',
  '_order',
];

export const SAVE_FIELD_ATTRIBUTES: Array<keyof APIFieldAttributes> = [
  'defaultValue',
  'description',
  'numberFormatOptions',
  'calculatedFieldSettings',
  'global',
  'globalref',
  'componentType',
  'referenceType',
];

export const CREATE_NEW_FIELD_ATTRIBUTES: Array<keyof APIFieldAttributes> = [
  ...SAVE_FIELD_ATTRIBUTES,
  'type',
  'label',
  'model',
  '_order',
];

// Synthetic temp queryId used when working on a partial calculated field
export const SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID =
  'client_side_synthetic-partial_calculated_field_query_id';
