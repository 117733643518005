import { ViewpointsPane, ViewpointsRoute } from './types';
import { reducer } from '@ardoq/rxbeach';
import {
  navigateToDefaultViewpoints,
  navigateToNewViewpoints,
  navigateToViewpointForm,
  navigateToViewpoints,
} from 'router/navigationActions';
import { ArdoqId } from '@ardoq/api-types';

export const defaultState: ViewpointsRoute = {
  viewpointsPane: ViewpointsPane.OVERVIEW,
  viewpointId: null,
};

const navigateToViewpointsReducer = (currentState: ViewpointsRoute) => ({
  ...currentState,
  viewpointsPane: ViewpointsPane.OVERVIEW,
});

const navigateToViewpointFormReducer = (
  currentState: ViewpointsRoute,
  viewpointId: ArdoqId | null
) => ({
  ...currentState,
  viewpointsPane: ViewpointsPane.FORM,
  viewpointId,
});

const navigateToDefaultViewpointsReducer = (currentState: ViewpointsRoute) => ({
  ...currentState,
  viewpointsPane: ViewpointsPane.DEFAULTS,
});

export const reducers = [
  reducer(navigateToViewpoints, navigateToViewpointsReducer),
  reducer(navigateToNewViewpoints, navigateToViewpointsReducer),
  reducer(navigateToViewpointForm, navigateToViewpointFormReducer),
  reducer(navigateToDefaultViewpoints, navigateToDefaultViewpointsReducer),
];
