import currentUser from 'models/currentUser';
import { Workspace } from 'aqTypes';
import ResourcePermissions from 'models/ResourcePermissions';
import {
  APIResourcePermissionAttributes,
  ArdoqId,
  PermissionAccessLevel,
  PermissionRole,
  ResourceType,
} from '@ardoq/api-types';
import {
  extractGroupLabelFromRole,
  getMemberGroupRole,
  getPermissionAccessLevelLabel,
} from '@ardoq/manage-resource-permissions';
import { permissionsOperations } from '@ardoq/access-control';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

const MEMBER = 'member';

const currentUserHasAccess = (
  resourcePermission: APIResourcePermissionAttributes,
  type: PermissionAccessLevel
) => {
  const resourceId = resourcePermission.resource;
  const user = currentUserInterface.getCurrentUserAttributes();
  const permissions = {
    [resourceId]: permissionsOperations.fromApiResourcePermissionAttributes(
      user,
      resourcePermission
    ),
  };
  // TODO - Rafaa: ardoq-front confuses permissions and application state,
  // fix after migrating all resource-types permissions checks to @ardoq/access-control.
  if (permissionsOperations.isOrgAdmin(user)) {
    return true;
  }

  if (type === PermissionAccessLevel.ADMIN) {
    return permissionsOperations.hasAdminAccess(user, permissions, resourceId);
  }
  if (type === PermissionAccessLevel.EDIT) {
    return permissionsOperations.hasEditAccess(user, permissions, resourceId);
  }
  return permissionsOperations.hasReadAccess(user, permissions, resourceId);
};

const GROUP_ACCESS_LEVELS = [
  PermissionAccessLevel.EDIT,
  PermissionAccessLevel.READ,
];

const NO_PERMISSIONS_OPTION = {
  value: null,
  text: 'Only named users have access',
};

/**
 * Get the default permission access levels of all users in the current org
 * for a given workspace
 *
 * Will return null if there is no default access level
 */
const getDefaultWorkspacePermissions = async (workspace: Workspace) => {
  const permissionModel = new ResourcePermissions({
    'resource-type': ResourceType.WORKSPACE,
    resource: workspace.id,
  });
  await permissionModel.fetch();
  const currentOrgMemberRole = getMemberGroupRole(
    currentUser.getOrganization().label
  );
  const group = permissionModel
    .getGroups()
    .find(group => group.name === currentOrgMemberRole);

  return group ? group.permissions : null;
};

const getDefaultWorkspacePermissionOptions = () => [
  NO_PERMISSIONS_OPTION,
  ...GROUP_ACCESS_LEVELS.map(value => ({
    value,
    text: getPermissionAccessLevelLabel(value),
  })),
];

const isMemberGroup = (permissionRole: PermissionRole): boolean =>
  extractGroupLabelFromRole(permissionRole) === MEMBER;

const fetchPermissionsForASurvey = (
  surveyId: ArdoqId,
  organizationLabel: string | null
): Promise<ResourcePermissions> => {
  return new Promise((resolve, reject) => {
    if (organizationLabel === null) {
      return reject(
        new Error(
          'Could not set permissions because organization label was null'
        )
      );
    }
    const permission = new ResourcePermissions({
      'resource-type': ResourceType.SURVEY,
      resource: surveyId,
    });

    permission
      .fetch()
      .then((resp: ResourcePermissions) => {
        resolve(resp);
      })
      .catch(reject);
  });
};

export const resourcePermissionsModelInterface = {
  currentUserHasAccess,
  getDefaultWorkspacePermissions,
  getDefaultWorkspacePermissionOptions,
  isMemberGroup,
  fetchPermissionsForASurvey,
};
