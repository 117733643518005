import { IntegrationWorkspace } from '@ardoq/api-types/integrations';
import { WithPopover } from '@ardoq/popovers';
import { FlexBox } from '@ardoq/layout';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { Link } from '@ardoq/typography';
import styled from 'styled-components';

interface WorkspaceNameProps {
  isWorkspaceAvailable: boolean;
  workspace: IntegrationWorkspace;
  onWorkspaceClick?: ((workspace: IntegrationWorkspace) => void) | null;
}

const WorkspaceNotAvailable = styled(FlexBox)`
  width: fit-content;
`;

export const WorkspaceName = ({
  isWorkspaceAvailable,
  workspace,
  onWorkspaceClick,
}: WorkspaceNameProps) => {
  if (!isWorkspaceAvailable) {
    return (
      <WithPopover content={`Workspace is not available`}>
        <WorkspaceNotAvailable align="center" gap="xxsmall">
          {workspace.name}
          <Icon iconName={IconName.INFO} color={colors.grey50} />
        </WorkspaceNotAvailable>
      </WithPopover>
    );
  }

  return onWorkspaceClick ? (
    <Link
      iconName={IconName.WORKSPACE}
      type="primary"
      typography="text1"
      onClick={() => onWorkspaceClick(workspace)}
    >
      {workspace.name}
    </Link>
  ) : (
    workspace.name
  );
};
