import Backbone from 'backbone';
import _ from 'lodash';
import Workspaces from 'collections/workspaces';
import Components from 'collections/components';
import References from 'collections/references';
import Models from 'collections/models';
import Tags from 'collections/tags';
import { dispatchAction } from '@ardoq/rxbeach';
import { removeWorkspaces } from 'streams/workspaces/actions';
import { SyncableBackboneModel } from 'aqTypes';
import {
  APIFieldAttributes,
  ArdoqId,
  CreateWorkspaceResponse,
  ResourceType,
  ComponentBatchDeleteResponse,
  WebSocketRecalculationDonePayload,
  WebSocketRecalculationErrorPayload,
  WebSocketRecalculationStartedPayload,
  WebSocketResetAttributePayload,
  WebSocketUnsetFieldValuesPayload,
  WebSocketUpdateReferenceCountsPayload,
  VersionedEntity,
  WebSocketDeletePayload,
  MetaData,
} from '@ardoq/api-types';
import { ArdoqEvent } from './types';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import Fields from 'collections/fields';
import { currentUserInterface } from '../modelInterface/currentUser/currentUserInterface';
import { ardoqEventOperations } from './ardoqEventOperations';
import {
  Resource,
  syncModuleBackboneInterface,
} from './syncModuleBackboneInterface';
import {
  Action,
  getCreateAction,
  getUpdateAction,
} from './resourceEventHandlers';
import { notifySyncConflict } from './actions';
import { calculatedFieldEventReceived } from 'menus/optionsMenu/workspace/actions';
import {
  CalculatedFieldEvent,
  CalculatedFieldEventStatus,
} from 'menus/optionsMenu/workspace/types';
import { resetGraph } from 'modelInterface/graphModelActions';
import { referenceInterface } from '@ardoq/reference-interface';
import { logError } from '@ardoq/logging';

const emitMergeConflict = (event: ArdoqEvent<VersionedEntity>) => {
  const entityType = ardoqEventOperations.getEntityType(event);
  const entityId = event.data._id;
  dispatchAction(
    notifySyncConflict({
      userId: event.user?.id,
      meta: { entityType, entityId },
    })
  );
};

const logFuturistic = (
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const resource = eventToResource(event);

  const localResource = collection.get(resource.resource._id);

  const localVersion = localResource?.get('_version');
  const isOwnChange = syncModuleBackboneInterface.isEventByCurrentUser(event);
  logError(
    new Error(
      `Websocket update on resource could not be processed ` +
        `because the local version is ahead of the incoming version`
    ),
    `Sync error on resource type: ${resource.resourceType}`,
    {
      id: resource.resource._id,
      resourceType: resource.resourceType,
      previousVersion: localVersion,
      incomingVersion: resource.resource._version,
      isOwnChange,
    }
  );
};

const eventToResource = (
  event: ArdoqEvent<VersionedEntity & MetaData>
): Resource => ({
  resource: event.data,
  resourceType: event['resource-type'],
});

const executeAction = (
  action: Action,
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const attributes = eventToResource(event).resource;
  switch (action) {
    case Action.CREATE:
      syncModuleBackboneInterface.createResource(attributes, collection);
      break;
    case Action.UPDATE:
      syncModuleBackboneInterface.updateResource(attributes, collection);
      break;
    case Action.CONFLICT:
      emitMergeConflict(event);
      break;
    case Action.FUTURISTIC:
      logFuturistic(event, collection);
      break;
    case Action.RETRY:
      window.setTimeout(resourceCreate, 500, event, collection);
      break;
    case Action.IGNORE:
      break;
  }
};

const getUpdateChangeState = (
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const resource = eventToResource(event);
  const localResource = collection.get(resource.resource._id);

  const localVersion = localResource?.get('_version');
  const isOwnChange = syncModuleBackboneInterface.isEventByCurrentUser(event);
  const hasUnsavedLocalResource =
    syncModuleBackboneInterface.hasUnsavedResourceInCollection(localResource);

  return {
    localVersion,
    isOwnChange,
    hasUnsavedLocalResource,
  };
};

export const resourceUpdate = (
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const changeState = getUpdateChangeState(event, collection);
  const resource = eventToResource(event);
  const action = getUpdateAction(resource.resource, changeState);
  executeAction(action, event, collection);
};

const getCreateChangeState = (
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const resource = eventToResource(event);
  const localResource = collection.get(resource.resource._id);

  const localVersion = localResource?.get('_version');
  const isPersisting = collection.some(model => model.mustBeSaved);
  return {
    localVersion,
    isPersisting,
  };
};

export const resourceCreate = (
  event: ArdoqEvent<VersionedEntity & MetaData>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const changeState = getCreateChangeState(event, collection);
  const action = getCreateAction(changeState);
  executeAction(action, event, collection);
};

/**
 * Transactional workspace created
 */
export const handleWorkspaceCreatedTransaction = (
  data: CreateWorkspaceResponse
) => {
  const { model, workspace, permission, fields } = data;

  const { _id: createdWorkspaceId } = workspace;
  if (Workspaces.collection.get(createdWorkspaceId)) {
    return {
      createdWorkspaceId,
    };
  }

  const currentUser = currentUserInterface.getCurrentUserAttributesClone();
  dispatchAction(addPermissionForResource({ permission, currentUser }));

  Models.collection.add(model);
  Workspaces.collection.add(workspace);
  Fields.collection.add(fields);
  const workspaceModel = Workspaces.collection.get(workspace._id)!;
  workspaceModel.trigger('sync', workspaceModel);

  return {
    createdWorkspaceId,
  };
};

/**
 * Handles deletion of a single resource.
 */
export const modelDeleted = (
  event: ArdoqEvent<WebSocketDeletePayload>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  if (ardoqEventOperations.isOfResourceType(event, ResourceType.WORKSPACE)) {
    dispatchAction(removeWorkspaces([event.data._id]));
  } else {
    if (ardoqEventOperations.isOfResourceType(event, ResourceType.REFERENCE)) {
      if (referenceInterface.isReference(event.data._id)) {
        // clear the graphModel$ stream (this is sync)
        // this is called here and in deleteReferencesByIds() in confirmDeletion/helpers.tsx
        // because we don't know what finishes first
        dispatchAction(resetGraph());
      }
    }
    collection.remove(event.data._id);
  }
  return null;
};

/**
 * Unset field values. This websocket-event is triggered when a field is removed
 * from components or references, and the component/references were updated
 * on the backend without a entity-specific update event.
 * Following the unset event is a delete event which removes the field.
 */
export const unsetFieldValues = (
  event: ArdoqEvent<WebSocketUnsetFieldValuesPayload>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  event.data['affected-entities']
    .map((id: ArdoqId) => collection.get(id))
    .filter(ExcludeFalsy)
    .forEach((model: any) => {
      model.set({
        _version: model.get('_version') + 1,
      });
      model.unset(event.data.attribute);
      model.mustBeSaved = false;
    });
  return null;
};

/**
 * Fields are global by their name. Each workspace using that field has a copy
 * (with different workspace specific configurations). Siblings of a given
 * field are fields with the same name across workspaces.
 * Occurs when:
 * - field is added to new workspace
 * - shared values of a field are changed
 */
export const updateFieldSiblings = (
  event: ArdoqEvent<APIFieldAttributes[]>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  event.data.forEach(updatedField => {
    const oldField = collection.get(updatedField._id);
    oldField?.set({ ...updatedField });
  });
  return null;
};

/**
 * Reset specific attributes across multiple entities in a collection.
 * Trigger by:
 * - removing an option from a multiselect field
 */
export const resetAttributes = (
  event: ArdoqEvent<WebSocketResetAttributePayload>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  const affectedEntities = event.data['affected-entities'];
  Object.keys(affectedEntities)
    .map(id => collection.get(id))
    .filter(ExcludeFalsy)
    .forEach(model => {
      model.set({
        _version: model.get('_version') + 1,
        [event.data.attribute]: affectedEntities[model.id],
      });
      model.mustBeSaved = false;
    });
  return null;
};

/**
 * Update incoming/outgoing reference counts on components.
 * Occurs when:
 * - References are reversed
 */
export const updateComponentReferenceCounts = (
  event: ArdoqEvent<WebSocketUpdateReferenceCountsPayload>,
  collection: Backbone.Collection<SyncableBackboneModel>
) => {
  Object.keys(event.data)
    .map(id => collection.get(id))
    .filter(ExcludeFalsy)
    .forEach(obj => {
      const ardoqMetaData = obj.get('ardoq');
      if (!ardoqMetaData) return;

      const { incomingReferenceCount, outgoingReferenceCount } =
        event.data[obj.id];
      const newMetaData = {
        ...ardoqMetaData,
        incomingReferenceCount,
        outgoingReferenceCount,
      };
      obj.set('ardoq', newMetaData);
      obj.mustBeSaved = false;
    });
  return null;
};

export const handleRecalculationStarted = (
  event: ArdoqEvent<WebSocketRecalculationStartedPayload>
) => {
  const calculatedFieldEvent = {
    userId: event.user?.id,
    fieldId: event.data.id,
    status: CalculatedFieldEventStatus.CALCULATING,
  } satisfies CalculatedFieldEvent;
  dispatchAction(calculatedFieldEventReceived(calculatedFieldEvent));

  return null;
};

export const handleRecalculationDone = (
  event: ArdoqEvent<WebSocketRecalculationDonePayload>
) => {
  const calculatedFieldEvent = {
    userId: event.user?.id,
    fieldId: event.data.id,
    status: CalculatedFieldEventStatus.DONE,
  } satisfies CalculatedFieldEvent;
  dispatchAction(calculatedFieldEventReceived(calculatedFieldEvent));

  return null;
};

export const handleRecalculationError = (
  event: ArdoqEvent<WebSocketRecalculationErrorPayload>
) => {
  const calculatedFieldEvent = {
    userId: event.user?.id,
    fieldId: event.data.id,
    status: { traceId: event.data.traceId, message: event.data.message },
  } satisfies CalculatedFieldEvent;
  dispatchAction(calculatedFieldEventReceived(calculatedFieldEvent));

  return null;
};

const bulkDeleteComponents = (componentIds: ArdoqId[]) => {
  const componentsToRemove =
    Components.collection.getComponentsByIds(componentIds);
  if (componentsToRemove.length > 0) {
    Components.collection.batchRemoveComponents(componentsToRemove);
  }
};

export const componentBulkDeleteCleanup = ({
  componentIds,
  referenceIds,
  updatedTags,
}: ComponentBatchDeleteResponse) => {
  const referencesToRemove = referenceIds
    .map(id => References.collection.get(id))
    .filter(ExcludeFalsy);
  if (referencesToRemove.length > 0) {
    References.collection.batchRemoveReferences(referencesToRemove);
  }
  bulkDeleteComponents(componentIds);

  for (const tag of updatedTags) {
    const model = Tags.collection.get(tag._id);
    if (
      !model ||
      (isEqual(model.getIdsOfComponentsWithTag(), tag.components) &&
        isEqual(model.getIdsOfReferencesWithTag(), tag.references))
    ) {
      continue;
    }
    model.attributes = _.clone(new Tags.collection.model(tag).attributes);
    model.trigger('sync', model);
  }
};

export const handleBulkDeleteCleanup = (
  event: ArdoqEvent<ComponentBatchDeleteResponse>
) => {
  componentBulkDeleteCleanup(event.data);
  return null;
};

const isEqual = (a: Array<number | string>, b: Array<number | string>) => {
  const aSet = new Set(a);
  const bSet = new Set(b);
  const abSet = new Set([...a, ...b]);
  return aSet.size === bSet.size && aSet.size === abSet.size;
};
