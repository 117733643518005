import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ArdoqId,
  QueryModel,
  SearchBackend,
  SearchQuery,
  SearchType,
} from '@ardoq/api-types';
import storedQueries$ from 'streams/queries/storedQueries$';
import { storedQueryApi } from '@ardoq/api';

const createCalculatedFieldStoredQueryModel = (
  query: SearchQuery,
  name: string
) => {
  const queryModel: QueryModel = {
    query,
    type: SearchType.CALCULATED_FIELD_QUERY,
    backend: SearchBackend.GREMLIN,
    name: `Calculation for ${name}`,
    supportedParams: ['ids'],
    parameterQueryId: null,
  };

  return queryModel;
};

export const findExistingStoredQuery = async (storedQueryId?: ArdoqId) => {
  const storedQuery$ = firstValueFrom(
    storedQueries$.pipe(
      map(
        ({ storedQueriesById }) =>
          storedQueryId && storedQueriesById[storedQueryId]
      )
    )
  );

  try {
    return await storedQuery$;
  } catch (error) {
    return undefined;
  }
};

const defaultQuery = `g.V(ids).
  project('id', 'name', 'value').
    by(id).
    by('name').
    by(both().count())`;

export const createCalculatedFieldStoredQuery = (name: string) => {
  const queryModel = createCalculatedFieldStoredQueryModel(defaultQuery, name);
  const storedQuery = storedQueryApi.create(queryModel);
  return storedQuery;
};
