import CurrentUser from 'models/currentUser';
import { logError } from '@ardoq/logging';

const userLoaded = new Promise<void>(resolve =>
  CurrentUser.whenLoaded(() => resolve())
);

const validateEntries = <T>(entries: [string, T][]) =>
  entries.filter(entry => {
    if (!Array.isArray(entry) || entry.length !== 2) {
      logError(Error('Unrecognized user settings entry.'), null, {
        type: typeof entry,
        length: entry && entry.length,
      });
      return false;
    }
    const [key] = entry;

    if (typeof key !== 'string') {
      logError(Error('Unrecognized user settings key.'), null, {
        type: typeof key,
        objectKeys: key && Object.keys(key),
      });
      return false;
    }
    return true;
  });

export default class UserSettings {
  constructor(private id = 'generic') {}

  async get<T>(key: string): Promise<T | undefined> {
    await userLoaded;
    const value: T | undefined = CurrentUser.getPersonalSetting(
      this.getNamespacedKey(key)
    );
    return value;
  }

  set<T>(key: string, value: T): Promise<void> {
    return this.setMultiple([[key, value]]);
  }

  async setMultiple<T>(entries: [string, T][]): Promise<void> {
    await userLoaded;
    const userEntries = validateEntries(entries).map(([key, value]) => [
      this.getNamespacedKey(key),
      value,
    ]);
    return CurrentUser.setPersonalSettings(userEntries);
  }

  private getNamespacedKey(key: string): string {
    return [this.id, key].join('_');
  }
}
