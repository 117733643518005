import { ArdoqId } from '@ardoq/api-types';
import { useState } from 'react';

export const useEditableConfig = () => {
  const [editingId, setEditingId] = useState<ArdoqId | null>(null);
  const [editingName, setEditingName] = useState('');

  const clear = () => {
    setEditingId(null);
    setEditingName('');
  };

  const handleSetEditingName = (name: string) => {
    const sanitizedName = name.trim();

    setEditingName(sanitizedName);
  };

  return {
    editingId,
    editingName,
    setEditingId,
    setEditingName: handleSetEditingName,
    clear,
  };
};
