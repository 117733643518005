import Backbone from 'backbone';
import Context from 'context';
import Tags from 'collections/tags';
import Filter from 'models/filter';
import Workspaces from 'collections/workspaces';
import dynamicFilters from 'filters/dynamicFilters';
import {
  backboneFilterToQueryBuilderRow,
  booleanSubqueryToFilterAttributes,
  excludeMissingWorkspaceIds,
  getWorkspaceIdsInWorkspaceFilter,
} from './filterUtils';
import {
  CommonDefaultFilterKeys,
  FiltersAsQueryBuilderQueries,
} from '@ardoq/filter-interface';
import { ActionCreator, dispatchAction } from '@ardoq/rxbeach';
import {
  notifyFilterColorChanged,
  notifyFiltersChanged,
  triggerFiltersChangedEvent,
} from 'streams/filters/FilterActions';
import {
  BooleanOperator,
  Operator,
  FilterTypes,
  QueryBuilderSubquery,
  isComponentFilter,
  isReferenceFilter,
  isFormatting,
  isConditionalFormatting,
  FilterAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { isComponent, isReference } from 'models/utils/filterUtils';
import {
  BackboneFilterAttributes,
  ComponentBackboneModel,
  FieldBackboneModel,
  Filter as FilterBackboneModel,
  HasShouldTriggerChangeEvent,
  LoadFiltersOptions,
  Reference,
  Tag,
} from 'aqTypes';
import {
  notifyComponentChanged,
  notifyReferenceContextChanged,
  notifyScenarioChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { debounce } from 'lodash';

class FilterCache {
  static Instance = new FilterCache();
  private _includedObjects: Record<string, boolean> = {};
  private _filterColors: Record<string, string> = {};
  private constructor() {}
  isIncluded(cid: string) {
    return this._includedObjects[cid];
  }
  getColor(cid: string) {
    return this._filterColors[cid];
  }
  set(cid: string, isIncluded = false, color = '') {
    this._includedObjects[cid] = isIncluded;
    this._filterColors[cid] = color;
  }
  reset() {
    this._includedObjects = {};
    this._filterColors = {};
  }
}

export const filterCache = FilterCache.Instance;

export const currentFilterColor = ({ cid }: Backbone.Model) =>
  filterCache.getColor(cid);

type CreateFiltersFromRuleTreeArgs = {
  rules?: QueryBuilderSubquery | null;
  affectComponent: boolean;
  preexistingFilters: FilterBackboneModel[];
};

const isComponentFilterModel = ({ attributes }: FilterBackboneModel) =>
  isComponentFilter(attributes);

const isReferenceFilterModel = ({ attributes }: FilterBackboneModel) =>
  isReferenceFilter(attributes);

function getWorkspaceFilter(workspaceIds: string[]) {
  return booleanSubqueryToFilterAttributes({
    condition: BooleanOperator.OR,
    rules: workspaceIds.map(workspaceId => ({
      id: CommonDefaultFilterKeys.ROOT_WORKSPACE,
      field: CommonDefaultFilterKeys.ROOT_WORKSPACE,
      input: 'text',
      operator: Operator.EQUAL,
      type: 'string',
      value: workspaceId,
    })),
  });
}

interface FiltersCheckModelArgs {
  checkComponents?: boolean;
  checkReferences?: boolean;
}

interface NotifyOnAffectingChangeArgs extends FiltersCheckModelArgs {
  fieldNames?: string[];
}

interface HasFilterAffectedByFieldArgs extends FiltersCheckModelArgs {
  fieldName: string;
}

interface CreateFilterArgs extends HasShouldTriggerChangeEvent {
  filterName?: string;
}

interface SetFiltersFromQueryBuilderQueriesArgs
  extends HasShouldTriggerChangeEvent {
  componentRules?: QueryBuilderSubquery | null;
  referenceRules?: QueryBuilderSubquery | null;
}

interface SetFormattingFiltersArgs {
  formattingRules: FilterAttributes[];
}

class Filters extends Backbone.Collection<FilterBackboneModel> {
  /**
   * Relates to ensure consistent views in slides, so
   * creator & viewer will get the same workspaces
   */
  workspaceFilter: FilterBackboneModel | null = null;

  notifyOnAffectingChange = debounce(
    ({
      fieldNames = [],
      checkComponents = false,
      checkReferences = false,
    }: NotifyOnAffectingChangeArgs) => {
      const isAffected = fieldNames.some(fieldName =>
        this.hasFilterAffectedByField({
          fieldName,
          checkComponents,
          checkReferences,
        })
      );

      if (isAffected) {
        dispatchAction(triggerFiltersChangedEvent());
      }
    },
    50
  );

  constructor() {
    super(undefined, { model: Filter });
    subscribeToAction(notifyFiltersChanged, () => {
      // Resetting cache so that we get correct caching of filters.
      filterCache.reset();
      dispatchAction(notifyFilterColorChanged());
    });
    const handleContextChanged = () => {
      if (this.models.some(filter => filter.isDynamic())) {
        dispatchAction(triggerFiltersChangedEvent());
      }
    };
    const actionCreators: ActionCreator<any>[] = [
      notifyWorkspaceChanged,
      notifyReferenceContextChanged,
      notifyComponentChanged,
      notifyScenarioChanged,
    ];

    actionCreators.forEach(actionCreator =>
      subscribeToAction(actionCreator, handleContextChanged)
    );

    this.listenTo(
      Tags.collection,
      'change:components change:references remove',
      (tag: Tag) => {
        const tagFilters = this.getTagFilters() || [];
        const affectsFiltering = tagFilters.some(
          filter => filter.get('name') === tag.get('name')
        );

        if (affectsFiltering) {
          dispatchAction(triggerFiltersChangedEvent());
        }
      }
    );
  }

  getPersistedFilters() {
    return {
      advancedFilters: this.filter(
        filter => filter !== this.workspaceFilter
      ).map<BackboneFilterAttributes>(({ attributes }) => {
        // Delete the backbone tag model from the filter
        // so it isn't persisted
        const persistedAttributes = { ...attributes };
        delete persistedAttributes.tag;
        return persistedAttributes;
      }),
      workspaceFilter: this.workspaceFilter,
    };
  }

  loadFilters({
    workspaceFilter,
    advancedFilters = [],
    shouldTriggerChangeEvent,
  }: LoadFiltersOptions) {
    this.removeAllFilters({ shouldTriggerChangeEvent: false });
    if (workspaceFilter) {
      this.workspaceFilter = this.createFilter(
        excludeMissingWorkspaceIds(workspaceFilter),
        { shouldTriggerChangeEvent: false }
      );
    }
    advancedFilters.forEach(filter => {
      this.createFilter(filter, { shouldTriggerChangeEvent: false });
    });
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeFormattingFiltersByField(field: FieldBackboneModel) {
    let filtersRemoved = false;
    this.getFormattingFilters().forEach(filter => {
      if (filter.get('value') === field.get('name')) {
        this.removeFilter(filter, { shouldTriggerChangeEvent: false });
        filtersRemoved = true;
      }
    });
    if (filtersRemoved) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeConditionalFormattingFilters({
    shouldTriggerChangeEvent,
  }: HasShouldTriggerChangeEvent) {
    this.getConditionalFormattingFilters().forEach(filter => {
      this.removeFilter(filter, { shouldTriggerChangeEvent: false });
    });
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeReferenceLabelFormattingFilters({
    shouldTriggerChangeEvent,
  }: HasShouldTriggerChangeEvent) {
    const referenceLabelFormattingFilters = this.models.filter(
      ({ attributes }) => attributes.type === FilterTypes.REFERENCE_LABEL
    );
    referenceLabelFormattingFilters.forEach(model => this.remove(model));
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeComponentLabelFormattingFilters({
    shouldTriggerChangeEvent,
  }: HasShouldTriggerChangeEvent) {
    const referenceLabelFormattingFilters = this.models.filter(
      ({ attributes }) => attributes.type === FilterTypes.COMPONENT_LABEL
    );
    referenceLabelFormattingFilters.forEach(model => this.remove(model));
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeIrrelevantWorkspaceFilters() {
    // Irrelevant workspace filters are workspaces that aren't
    // connected to the ones that we have open
    const connectedWorkspaceIds = Array.from(Context.getConnectedWorkspaceIds())
      // filter by loaded workspaces to ensure we have access to them
      .filter(wsId => Workspaces.collection.get(wsId));
    const workspaceIds = this.getWorkspaceIdsInWorkspaceFilter();
    if (workspaceIds.some(wsId => !connectedWorkspaceIds.includes(wsId))) {
      this.updateWorkspaceFilter(
        workspaceIds.filter(wsId => connectedWorkspaceIds.includes(wsId))
      );
    }
  }

  clearWorkspaceFilter({
    shouldTriggerChangeEvent,
  }: HasShouldTriggerChangeEvent) {
    const workspaceFilter = this.workspaceFilter;
    if (workspaceFilter) {
      this.workspaceFilter = null;
      this.removeFilter(workspaceFilter, {
        shouldTriggerChangeEvent,
      });
    }
  }

  ensureWorkspaceInWorkspaceFilter(workspaceId: ArdoqId) {
    const workspaceIds = this.getWorkspaceIdsInWorkspaceFilter();
    if (!workspaceIds.length || workspaceIds.includes(workspaceId)) {
      return;
    }
    this.updateWorkspaceFilter([...workspaceIds, workspaceId]);
  }

  getWorkspaceIdsInWorkspaceFilter() {
    return this.workspaceFilter
      ? getWorkspaceIdsInWorkspaceFilter(this.workspaceFilter)
      : [];
  }

  updateWorkspaceFilter(workspaceIds: string[]) {
    this.clearWorkspaceFilter({ shouldTriggerChangeEvent: false });
    const hasWorkspaceIds = workspaceIds && workspaceIds.length > 0;
    if (hasWorkspaceIds)
      this.workspaceFilter = this.createFilter(
        getWorkspaceFilter(Array.from(new Set(workspaceIds))),
        { shouldTriggerChangeEvent: false }
      );
    dispatchAction(triggerFiltersChangedEvent());
  }

  createWorkspaceFilterFromURL(attributes: string) {
    this.workspaceFilter = this.createFilter(attributes, {
      shouldTriggerChangeEvent: true,
    });
    return this.workspaceFilter;
  }

  createFilter(
    attributes: FilterBackboneModel | FilterAttributes | string | null,
    options: CreateFilterArgs = {}
  ) {
    const { shouldTriggerChangeEvent, filterName, ...restOptions } = options;
    const filter = this.createFilterFromRule(attributes, restOptions);
    if (filterName) filter.setFilterName(filterName);
    this.add(filter);
    filterCache.reset();

    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
    return filter;
  }

  createFilterFromRule(
    attributes: FilterBackboneModel | FilterAttributes | string | null,
    options: unknown = {}
  ) {
    const filter =
      attributes instanceof Filter
        ? attributes
        : new Filter(attributes, options);
    const rules: FilterBackboneModel[] = filter.get('rules');
    if (rules) {
      const filterFromRules = rules.map(rule =>
        this.createFilterFromRule(rule)
      );
      if (filter.get('type') === FilterTypes.DATE_RANGE) {
        filter.setRules(filterFromRules);
      } else {
        filter.set('rules', filterFromRules);
      }
    }
    return filter;
  }

  removeFilter(
    filter: FilterBackboneModel,
    options: HasShouldTriggerChangeEvent = {}
  ) {
    const { shouldTriggerChangeEvent, ...restOptions } = options;
    this.remove(filter, restOptions);
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  removeAllFilters({ shouldTriggerChangeEvent }: HasShouldTriggerChangeEvent) {
    this.reset();
    this.workspaceFilter = null;

    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  getAllFilters() {
    return this.models;
  }

  getFiltersByType(type: FilterTypes, options?: never) {
    const parsedOptions: { type?: FilterTypes } = options || {};
    parsedOptions.type = type;
    return this.where(parsedOptions);
  }

  getAttributeFilters(options?: never) {
    return this.getFiltersByType(FilterTypes.ATTRIBUTE, options);
  }

  getDateRangeFilters(options?: never) {
    return this.getFiltersByType(FilterTypes.DATE_RANGE, options);
  }

  getTagFilters(options?: never) {
    return this.getFiltersByType(FilterTypes.TAG, options);
  }

  getCompLabelFilter(options?: never) {
    return this.getFiltersByType(FilterTypes.COMPONENT_LABEL, options).filter(
      filter => !filter.get('affectsTypeName' satisfies keyof FilterAttributes)
    );
  }

  getRefLabelFilter(options?: never) {
    return this.getFiltersByType(FilterTypes.REFERENCE_LABEL, options).filter(
      filter => !filter.get('affectsTypeName' satisfies keyof FilterAttributes)
    );
  }

  getCompMultiLabelFilter(options?: never) {
    return this.getFiltersByType(FilterTypes.COMPONENT_LABEL, options).filter(
      filter => filter.get('affectsTypeName' satisfies keyof FilterAttributes)
    );
  }

  getRefMultiLabelFilter(options?: never) {
    return this.getFiltersByType(FilterTypes.REFERENCE_LABEL, options).filter(
      filter => filter.get('affectsTypeName' satisfies keyof FilterAttributes)
    );
  }

  getFiltersAsQueryBuilderQueries(): FiltersAsQueryBuilderQueries {
    const filtersWithoutWorkspaceFilter = this.getAllFilters().filter(
      filter => filter !== this.workspaceFilter
    );
    return {
      componentRules: {
        condition: BooleanOperator.AND,
        rules: filtersWithoutWorkspaceFilter
          .filter(isComponentFilterModel)
          .map(backboneFilterToQueryBuilderRow),
      },
      referenceRules: {
        condition: BooleanOperator.AND,
        rules: filtersWithoutWorkspaceFilter
          .filter(isReferenceFilterModel)
          .map(backboneFilterToQueryBuilderRow),
      },
    };
  }

  /**
   * The goal is to enable OR condition the top level in perspectives query builder.
   * https://ardoqcom.atlassian.net/browse/ARD-13495
   * But the top level condition internally must be AND because of multiple negative
   * filters and backward compatibility, as it is used across the app today.
   *
   * The idea is to "submerse" the user one (rule) level down in the perspectives
   * (ignore the ruleRoot level if its rules contain only one rule with OR condition) and
   * let the query builder do its work. If there are multiple rules, we must use AND logic.
   * Therefore, if there is a top level OR condition, I create one filter from the rule
   * root in setFiltersFromQueryBuilderQueries(), instead of one filter for every rule.
   * This way, if we add negative filters (by excluding stuff from the view), we still
   * have a logically correct rule tree, where the OR root becomes a subquery.
   */

  createFiltersFromRuleTree({
    rules,
    affectComponent,
    preexistingFilters,
  }: CreateFiltersFromRuleTreeArgs) {
    if (rules) {
      if (rules.condition === BooleanOperator.OR) {
        this.createFilter(
          booleanSubqueryToFilterAttributes(rules, affectComponent)!
        );
      } else {
        booleanSubqueryToFilterAttributes(
          rules,
          affectComponent
        )!.rules!.forEach(filter =>
          this.createFilter(filter, { shouldTriggerChangeEvent: false })
        );
      }
    } else if (rules !== null) {
      preexistingFilters.forEach(filter => this.add(filter));
    }
  }

  setConditionalFormattingFilters({
    formattingRules,
  }: SetFormattingFiltersArgs) {
    this.removeConditionalFormattingFilters({
      shouldTriggerChangeEvent: false,
    });

    formattingRules.forEach(filter => this.add(filter));
    // this results in dispatching notifyFilterChange action.
    // perspectiveEditorFormattingOptions$ listens for this action and whenever triggered,
    // refreshes applied conditional formatting in Perspective Editor
    // (It's needed for change detection in Perspective Editor)
    dispatchAction(triggerFiltersChangedEvent());
  }

  setFiltersFromQueryBuilderQueries({
    componentRules,
    referenceRules,
    shouldTriggerChangeEvent,
  }: SetFiltersFromQueryBuilderQueriesArgs) {
    const formattingFilters = this.getFormattingFilters();
    const filters = this.getAllFilters();
    const componentFilters = filters.filter(isComponentFilterModel);
    const referenceFilters = filters.filter(isReferenceFilterModel);
    this.reset();
    filterCache.reset();
    if (this.workspaceFilter) {
      this.add(this.workspaceFilter);
    }
    formattingFilters.forEach(filter => this.add(filter));

    this.createFiltersFromRuleTree({
      rules: componentRules,
      affectComponent: true,
      preexistingFilters: componentFilters,
    });
    this.createFiltersFromRuleTree({
      rules: referenceRules,
      affectComponent: false,
      preexistingFilters: referenceFilters,
    });
    if (shouldTriggerChangeEvent) {
      dispatchAction(triggerFiltersChangedEvent());
    }
  }

  _getNestedFiltersLeafNodeCount(filters: FilterBackboneModel[]): number {
    return filters
      .map(({ attributes }) =>
        attributes.rules && attributes.type !== FilterTypes.DATE_RANGE
          ? this._getNestedFiltersLeafNodeCount(attributes.rules)
          : 1
      )
      .reduce((sum, i) => sum + i, 0);
  }

  getTotalFilterCount() {
    return this.getFilterCount() + this.getFormattingCount();
  }

  /** Returns the count of active filters. Note: this does not include formatting filters. */
  getFilterCount() {
    return this._getNestedFiltersLeafNodeCount(this.getIncludeFilters());
  }

  getFormattingCount() {
    return this.getFormattingFilters().length;
  }

  getIncludeFilters() {
    return this.models.filter(({ attributes }) => !isFormatting(attributes));
  }

  getFormattingFilters() {
    return this.models.filter(({ attributes }) => isFormatting(attributes));
  }

  getConditionalFormattingFilters() {
    return this.models.filter(({ attributes }) =>
      isConditionalFormatting(attributes)
    );
  }

  hasFilterAffectedByField({
    fieldName,
    checkComponents = false,
    checkReferences = false,
  }: HasFilterAffectedByFieldArgs) {
    return this.getAttributeFilters().some(filter => {
      const passesComponentCheck =
        !checkComponents || filter.get('affectComponent');
      const passesReferenceCheck =
        !checkReferences || filter.get('affectReference');
      return (
        filter.get('name') === fieldName &&
        passesComponentCheck &&
        passesReferenceCheck
      );
    });
  }

  updateFiltered(obj: ComponentBackboneModel | Reference) {
    if (obj) {
      const isAffected = this.getAllFilters().some(filter =>
        filter.isAffected(obj)
      );
      const currentIsIncluded = this.isIncludedInContextByFilter(obj);
      const currentColor = currentFilterColor(obj);

      if (isAffected) {
        const updatedIsIncluded = this.isIncludedInContextByFilter(obj, true);
        const updatedColor = currentFilterColor(obj);

        if (
          currentIsIncluded !== updatedIsIncluded ||
          currentColor !== updatedColor
        ) {
          filterCache.set(obj.cid, updatedIsIncluded, updatedColor);
          return true;
        }
      }
    }
    return false;
  }

  getFilterColors() {
    return this.filter(f => f.isComponentFormatting()).map<string>(f =>
      f.get('color')
    );
  }

  getFilterReferenceColors() {
    return this.filter(f => f.isReferenceFormatting()).map<string>(f =>
      f.get('color')
    );
  }

  isIncludedInContextByFilter(
    obj: Reference | ComponentBackboneModel,
    forceReloadCache = false
  ): boolean {
    if (!obj) {
      return false;
    }
    const cachedResult = forceReloadCache
      ? undefined
      : filterCache.isIncluded(obj.cid);
    if (cachedResult !== undefined) {
      return cachedResult;
    }

    const isComponentObj = isComponent(obj);
    const isReferenceObj = isReference(obj);
    const filters = this.getAllFilters();

    let included = filters
      .filter(({ attributes }) => !isFormatting(attributes))
      .every(filter => filter.isIncluded(obj));

    if (isReferenceObj) {
      included =
        included &&
        dynamicFilters.includesReference(obj) &&
        this.isIncludedInContextByFilter(obj.getSource(), forceReloadCache) &&
        this.isIncludedInContextByFilter(obj.getTarget(), forceReloadCache);
    } else if (isComponentObj) {
      included = included && dynamicFilters.includesComponent(obj);
    }

    const firstColorFilter = filters
      .filter(filter => !!filter.get('color'))
      .filter(filter => filter.isIncluded(obj))
      .find(
        filter =>
          (filter.get('affectComponent') && isComponentObj) ||
          (filter.get('affectReference') && isReferenceObj)
      );
    const colorMatch = firstColorFilter
      ? firstColorFilter.get('color')
      : undefined;

    filterCache.set(obj.cid, included, colorMatch);
    return included;
  }
}

const globalFilters = new Filters();
export default globalFilters;
