import { Observable, map, mergeMap, pipe, withLatestFrom } from 'rxjs';
import {
  ResourcePermissionMetaPayload,
  setResourcePermissions,
} from './actions';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  fetchPermissionGroups,
  setPermissionGroups,
  setError as setPermissionGroupsError,
} from 'admin/accessControl/PermissionGroups/streams/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import assets$, { AssetsState } from 'streams/assets/assets$';
import {
  APIResourcePermissionAttributesOperations,
  AppStateManageResourcePermissionContext,
  CreateManageResourcePermissionViewModelArgs,
} from '@ardoq/manage-resource-permissions';
import { ManageResourcePermissionsActorSelectorDataProps } from '@ardoq/manage-resource-permissions';
import { getActorsOptions, getCurrentUserCanEdit } from './contextUtils';
import { ApiResponse, permissionApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { getAssetPathName } from 'utils/hierarchy';

export const fetchAndWaitForResourcePermissionData = mergeMap<
  ResourcePermissionMetaPayload,
  ApiResponse<ResourcePermissionMetaPayload>
>(async (resourcePayload: ResourcePermissionMetaPayload) => {
  const { resources } = resourcePayload;
  const [, ...responses] = await Promise.all([
    dispatchActionAndWaitForResponse(
      fetchPermissionGroups(),
      setPermissionGroups,
      setPermissionGroupsError
    ),
    ...resources.map(({ resourceId, resourceType }) =>
      permissionApi.getResourcePermission({ resourceId, resourceType })
    ),
  ]);
  const validResponses = [];
  for (const response of responses) {
    if (isArdoqError(response)) {
      return response;
    }
    validResponses.push(
      APIResourcePermissionAttributesOperations.sanitize(response)
    );
  }
  dispatchAction(setResourcePermissions(validResponses));

  return resourcePayload;
});

export const getResourcePathName = pipe<
  Observable<ResourcePermissionMetaPayload>,
  Observable<[ResourcePermissionMetaPayload, AssetsState]>,
  Observable<ResourcePermissionMetaPayload>
>(
  withLatestFrom(assets$),
  map(([payload, assets]) => {
    const resources = [...payload.resources];
    if (resources.length === 1) {
      const resource = resources[0];
      const resourceNamePath = [
        ...getAssetPathName(
          Object.values(assets.assetFoldersById),
          resource.resourceId
        ),
        resource.resourceName,
      ];
      resources[0] = { ...resource, resourceNamePath };
    }
    return { ...payload, resources };
  })
);

export const createGetActorsOptions = ({
  currentUser,
  permissionGroups = [],
}: AppStateManageResourcePermissionContext): {
  getActorsOptions: ManageResourcePermissionsActorSelectorDataProps['getActorsOptions'];
} => ({
  getActorsOptions: search =>
    getActorsOptions({
      search,
      permissionGroups,
      currentOrgLabel: currentUser.organization.label,
    }),
});

export const createGetCurrentUserCanEditResource = (
  context: AppStateManageResourcePermissionContext
): {
  getCurrentUserCanEditResource: CreateManageResourcePermissionViewModelArgs['getCurrentUserCanEditResource'];
} => ({
  getCurrentUserCanEditResource: config =>
    getCurrentUserCanEdit({
      ...config,
      currentUserPermissions: context.currentUserPermissions,
    }),
});
