import { ReactNode } from 'react';
import ScenarioLoader from './ScenarioLoader';
import { ModalLayout } from '@ardoq/modal';
import { FlexBox, Stack } from '@ardoq/layout';

const DialogBodyBlock = ({
  children,
  animate,
}: {
  children?: ReactNode;
  animate?: boolean;
}) => {
  return (
    <ModalLayout>
      <FlexBox>
        <FlexBox flex={1} justify="center">
          <ScenarioLoader animate={animate} />
        </FlexBox>
        <FlexBox flex={1} justify="center">
          <Stack gap="medium" justify="center">
            {children}
          </Stack>
        </FlexBox>
      </FlexBox>
    </ModalLayout>
  );
};

export default DialogBodyBlock;
