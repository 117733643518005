import { GRID_EDITOR_POPOUT_WINDOW_NAME } from './consts';
import { getGridEditorIframeSrc } from './gridEditorUtils';

const popoutWindowRef: { current: Window | null } = { current: null };

/**
 * Focuses the popout window if it is open, throws an error otherwise
 */
const focusPopoutGridEditor = () => {
  const gridEditorPopoutWindow = popoutWindowRef.current;
  if (!gridEditorPopoutWindow) {
    throw new Error('Cannot focus Popout GridEditor, it is not open');
  }
  gridEditorPopoutWindow.focus();
};

/**
 * Closes the popout window if it is open
 */
const closePopoutGridEditor = () => {
  popoutWindowRef.current?.close();
  popoutWindowRef.current = null;
};

/**
 * Opens the Popout GridEditor window
 * @param onLoadDone Callback to be called when the popout window has finished loading
 */
const openPopoutGridEditor = (onLoadDone: VoidFunction) => {
  popoutWindowRef.current = window.open(
    getGridEditorIframeSrc(),
    GRID_EDITOR_POPOUT_WINDOW_NAME,
    `width=${window.innerWidth * 0.7}, height=500, top=100, left=100, popup`
  );

  const gridEditorPopoutWindow = popoutWindowRef.current;
  if (!gridEditorPopoutWindow) {
    throw new Error('Failed to open Popout GridEditor');
  }

  const onUnload = (_e: BeforeUnloadEvent) => {
    gridEditorPopoutWindow?.removeEventListener('beforeunload', onUnload);
    // NOTE: Do not try to close the window from here, Chrome will ignore it,
    // instead let the bridge handle closing the window when the bridge disconnects.
    // See `closePopoutOnDisconnect` for this use case.
    popoutWindowRef.current = null;
  };

  const onLoad = () => {
    gridEditorPopoutWindow?.removeEventListener('load', onLoad);
    if (!gridEditorPopoutWindow) {
      return;
    }
    // Safari instantly dispatches an beforeunload event, so wait until 'load' fires first
    gridEditorPopoutWindow.addEventListener('beforeunload', onUnload);
    gridEditorPopoutWindow.document.title = 'Ardoq - Grid Editor';
    onLoadDone();
  };

  // When popout window is closed, then cleanup event listeners
  gridEditorPopoutWindow.addEventListener('load', onLoad);
};

export const popoutGridEditorUtils = {
  openPopoutGridEditor,
  closePopoutGridEditor,
  focusPopoutGridEditor,
};
