import { v4 as uuidv4 } from 'uuid';
import {
  APIFieldAttributes,
  APIFieldType,
  type APIModelAttributes,
  type ArdoqId,
} from '@ardoq/api-types';
import { OLD_DEFAULT_FIELD_ORDER } from 'models/consts';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { getNextReferenceId } from 'modelInterface/models/utils';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { NewEntityAttributes } from 'scopeData/scopeEditUtils/types';
import {
  Diff,
  getComponentTypesByFieldId,
  getDiff,
  getReferenceTypesByFieldId,
} from '@ardoq/renderers';
import { confirm } from '@ardoq/modal';
import { createElement } from 'react';
import {
  ImpactedType,
  TypeRemovalFromFieldWarningDialogContent,
} from './TypeRemovalFromFieldWarningDialogContent';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { fieldApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';

export const getNewComponentAttributesById = (
  workspaceId: ArdoqId,
  parentId?: ArdoqId | null
): NewEntityAttributes | void => {
  const newComponentId = uuidv4();
  const canHaveChildren =
    parentId && componentInterface.canComponentHaveChildren(parentId);

  if (canHaveChildren) {
    const model = componentInterface.getModelId(parentId);
    const rootWorkspace = componentInterface.getWorkspaceId(parentId);

    if (!model || !rootWorkspace) {
      return;
    }

    return {
      _id: newComponentId,
      parent: parentId,
      model,
      rootWorkspace,
    };
  }

  const model = workspaceInterface.getWorkspaceModelId(workspaceId);

  if (!model) {
    return;
  }

  return {
    _id: newComponentId,
    rootWorkspace: workspaceId,
    model,
  };
};

export const getNewFieldAttributes = (
  label: string,
  model: ArdoqId,
  rootWorkspace: ArdoqId,
  attributes: Partial<APIFieldAttributes>
) => {
  return {
    _id: uuidv4(),
    _order: OLD_DEFAULT_FIELD_ORDER,
    type: APIFieldType.TEXT,
    global: false,
    globalref: false,
    componentType: [],
    referenceType: [],
    label,
    model,
    rootWorkspace,
    ...attributes,
  };
};

export const getNewReferenceTypeAttributes = (
  model: APIModelAttributes,
  rootWorkspace: ArdoqId
) => ({
  _id: getNextReferenceId(model),
  model: model._id,
  rootWorkspace,
});

const isDiffRelevant = (diff: Diff) => {
  const relevantAttributes = [
    'global',
    'globalref',
    'componentType',
    'referenceType',
  ];
  return Object.keys(diff).some(attribute =>
    relevantAttributes.includes(attribute)
  );
};

export const needsTypeRemovalFromFieldConfirmation = (
  newAttributes: APIFieldAttributes,
  previousAttributes: APIFieldAttributes
) => {
  const diff = getDiff(newAttributes, previousAttributes);
  if (!diff || !isDiffRelevant(diff)) {
    return false;
  }
  if (newAttributes.global && newAttributes.globalref) {
    return false;
  }
  const hasRemovedComponentGlobal =
    previousAttributes.global && !newAttributes.global;
  const hasRemovedReferenceGlobal =
    previousAttributes.globalref && !newAttributes.globalref;
  if (hasRemovedComponentGlobal || hasRemovedReferenceGlobal) {
    return true;
  }
  const haveSomeComponentTypesBeenRemoved =
    !newAttributes.global &&
    previousAttributes.componentType.some(
      compType => !newAttributes.componentType.includes(compType)
    );
  const haveSomeReferenceTypesBeenRemoved =
    !newAttributes.globalref &&
    previousAttributes.referenceType.some(
      refType => !newAttributes.referenceType.includes(refType)
    );
  return haveSomeComponentTypesBeenRemoved || haveSomeReferenceTypesBeenRemoved;
};

const renderTypeRemovalFromFieldConfirmationDialog = ({
  impactedComponentTypes,
  impactedReferenceTypes,
}: {
  impactedComponentTypes: ImpactedType[];
  impactedReferenceTypes: ImpactedType[];
}) => {
  return confirm({
    title: 'Remove field from types',
    text: createElement(TypeRemovalFromFieldWarningDialogContent, {
      impactedComponentTypes,
      impactedReferenceTypes,
    }),
  });
};

export const confirmTypeRemovalFromField = async (
  fieldId: ArdoqId,
  newAttributes: APIFieldAttributes,
  enhancedScopeData: EnhancedScopeData
) => {
  const usage = await fieldApi.localUsage(fieldId);

  if (isArdoqError(usage)) {
    return renderTypeRemovalFromFieldConfirmationDialog({
      impactedComponentTypes: [],
      impactedReferenceTypes: [],
    });
  }

  const componentsAreImpacted = usage.componentCount && !newAttributes.global;
  const referencesAreImpacted =
    usage.referenceCount && !newAttributes.globalref;
  if (!componentsAreImpacted && !referencesAreImpacted) {
    return true;
  }
  const componentTypes = getComponentTypesByFieldId(fieldId, enhancedScopeData);
  const referenceTypes = getReferenceTypesByFieldId(fieldId, enhancedScopeData);
  const impactedComponentTypes = newAttributes.global
    ? []
    : (Object.keys(usage.componentTypes)
        .filter(compType => !newAttributes.componentType.includes(compType))
        .map(typeId => {
          return {
            name: componentTypes[typeId].name,
            count: usage.componentTypes[typeId],
          };
        }) ?? []);
  const impactedReferenceTypes = newAttributes.globalref
    ? []
    : (Object.keys(usage.referenceTypes)
        .filter(refType => !newAttributes.referenceType.includes(refType))
        .map(typeId => {
          return {
            name: referenceTypes[typeId].name,
            count: usage.referenceTypes[typeId],
          };
        }) ?? []);
  if (!impactedComponentTypes.length && !impactedReferenceTypes.length) {
    return true;
  }
  return renderTypeRemovalFromFieldConfirmationDialog({
    impactedComponentTypes,
    impactedReferenceTypes,
  });
};

export const needsFieldTypeEditConfirmation = (
  newAttributes: APIFieldAttributes,
  previousAttributes: APIFieldAttributes
) => {
  const isChangingFromBaseFieldType = Boolean(
    !previousAttributes.calculatedFieldSettings &&
      newAttributes.calculatedFieldSettings
  );

  const isChangingFromCalculatedFieldType = Boolean(
    previousAttributes.calculatedFieldSettings?.storedQueryId &&
      newAttributes.calculatedFieldSettings?.storedQueryId === undefined
  );

  const isChangingFromLocallyDerivedFieldType = Boolean(
    previousAttributes.calculatedFieldSettings?.locallyDerived?.length &&
      newAttributes.calculatedFieldSettings?.locallyDerived === undefined
  );

  const needsConfirmation =
    isChangingFromBaseFieldType ||
    isChangingFromCalculatedFieldType ||
    isChangingFromLocallyDerivedFieldType;

  return {
    isChangingFromCalculatedFieldType,
    isChangingFromLocallyDerivedFieldType,
    needsConfirmation,
  };
};

export const confirmFieldTypeEdit = async (
  isChangingFromCalculatedFieldType: boolean,
  isChangingFromLocallyDerivedFieldType: boolean
) => {
  const isChangingFromBaseFieldType =
    !isChangingFromCalculatedFieldType &&
    !isChangingFromLocallyDerivedFieldType;

  return confirm({
    title: 'Field type change',
    text: (
      <>
        Changing the field type will reset the field settings.
        <br />
        {isChangingFromCalculatedFieldType ? (
          <>The associated Gremlin script will be deleted.</>
        ) : isChangingFromBaseFieldType ? (
          <>
            The calculation will overwrite all values on components and
            references, that have been manually entered.
          </>
        ) : (
          ''
        )}
        <br />
        Would you like to proceed?
      </>
    ),
  });
};
