import { actionCreator } from '@ardoq/rxbeach';

interface PayloadRecalculateCalculatedField {
  readonly calculatedFieldQueryId?: string;
  readonly fieldId?: string;
  readonly branchId?: string | null;
}

export const recalculateCalculatedField =
  actionCreator<PayloadRecalculateCalculatedField>(
    '[CalculatedSearch] RECALCULATE_FIELD_CALCULATION'
  );
