import viewpointsNavigation$ from './navigation/viewpointsNavigation$';
import { reducer, streamReducer } from '@ardoq/rxbeach';
import workspaces$, { WorkspacesState } from 'streams/workspaces/workspaces$';
import { ViewpointsFetchSuccessPayload, ViewpointsStreamShape } from './types';
import {
  fetchAvailableTriplesError,
  fetchAvailableTriplesSuccess,
  fetchTypeRelationshipsSuccess,
  notifyCopyingViewpointSucceeded,
  viewpointDeleteSuccess,
  viewpointSave,
  viewpointSaveError,
  viewpointSaveSuccess,
  viewpointsFetchSuccess,
  populateRestrictedWorkspaces,
  toggleViewpointLegacyMode,
  viewpointsFetch,
  viewpointsFetchError,
  viewpointFetchSuccess,
  fetchAvailableTriples,
  setViewpointLegacyMode,
} from './actions';
import { ViewpointsRoute } from './navigation/types';
import assetFolders$, {
  AssetFoldersState,
} from 'streams/assetFolders/assetFolders$';
import surveys$, { SurveysStreamShape } from 'streams/surveys/surveys$';
import {
  APIDiscoverViewpointAttributes,
  APIWorkspaceAttributes,
  ArdoqId,
  SortOrder,
  Triple,
  TypeRelationshipsResult,
} from '@ardoq/api-types';
import {
  updateCurrentViewpointIfNeeded,
  updateViewpointInViewpointListById,
} from './utils';
import { restrictedWorkspacesDefaultValue } from './restrictedWorkspaces/const';
import {
  populateRestrictedWorkspacesById,
  populateRestrictedWorspacesByViewpoint,
} from './restrictedWorkspaces/operations';
import { navigateToNewViewpoints } from 'router/navigationActions';
import { ARDOQ_DEFAULT_LOCALE, Locale } from '@ardoq/locale';
import { locale$ } from 'streams/locale$';

export const defaultState: ViewpointsStreamShape = {
  viewpoints: null,
  currentViewpoint: null,
  workspaces: null,
  restrictedWorkspacesById: {},
  surveys: null,
  folders: null,
  typeRelationships: null,
  availableTriples: {
    data: [],
    status: 'idle',
  },
  isSaving: false,
  restrictedWorkspacesForEveryViewpoint: restrictedWorkspacesDefaultValue,
  sortId: 'name',
  sortOrder: SortOrder.ASC,
  loadingViewpoints: false,
  isInLegacyViewpointMode: false,
  locale: ARDOQ_DEFAULT_LOCALE,
};

const handleViewpointsFetchSuccess = (
  {
    restrictedWorkspacesForEveryViewpoint,
    workspaces,
    ...state
  }: ViewpointsStreamShape,
  { viewpoints, currentViewpointId }: ViewpointsFetchSuccessPayload
): ViewpointsStreamShape => {
  return {
    ...state,
    isSaving: false,
    loadingViewpoints: false,
    viewpoints,
    currentViewpoint:
      (currentViewpointId &&
        viewpoints.find(viewpoint => viewpoint._id === currentViewpointId)) ||
      null,
    restrictedWorkspacesForEveryViewpoint:
      populateRestrictedWorspacesByViewpoint(
        restrictedWorkspacesForEveryViewpoint,
        viewpoints,
        workspaces || []
      ),
    workspaces,
  };
};

const handleViewpointsFetchError = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    isSaving: false,
    loadingViewpoints: false,
  };
};

const handleViewpointsIsFetching = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    loadingViewpoints: true,
  };
};

const handleViewpointsNavigationStateChange = (
  state: ViewpointsStreamShape,
  { viewpointId }: ViewpointsRoute
): ViewpointsStreamShape => {
  return {
    ...state,
    currentViewpoint:
      state.viewpoints?.find(viewpoint => viewpoint._id === viewpointId) ??
      null,
    availableTriples: {
      // We don't want to reset availableTriples to an empty list in this case
      // because it would cause the steps in the form to temporarily disappear
      // when the user saves a new viewpoint and we automatically navigate to it.
      ...state.availableTriples,
      status: 'idle',
    },
  };
};

const handleWorkspacesChange = (
  state: ViewpointsStreamShape,
  { byId }: WorkspacesState
): ViewpointsStreamShape => ({
  ...state,
  workspaces: Object.values(byId),
});

const handleSurveysChange = (
  state: ViewpointsStreamShape,
  { list: surveys }: SurveysStreamShape
): ViewpointsStreamShape => ({
  ...state,
  surveys,
});

const handleFoldersChange = (
  state: ViewpointsStreamShape,
  folders: AssetFoldersState
): ViewpointsStreamShape => ({
  ...state,
  folders,
});

const handleFetchAvailableTriples = (
  state: ViewpointsStreamShape
): ViewpointsStreamShape => ({
  ...state,
  availableTriples: {
    ...state.availableTriples,
    status: 'loading',
  },
});

const handleFetchAvailableTriplesSuccess = (
  state: ViewpointsStreamShape,
  availableTriples: Triple[]
): ViewpointsStreamShape => ({
  ...state,
  availableTriples: {
    data: availableTriples,
    status: 'success',
  },
});

const handleFetchAvailableTriplesError = (
  state: ViewpointsStreamShape
): ViewpointsStreamShape => ({
  ...state,
  availableTriples: {
    data: [],
    status: 'error',
  },
});

const handleNotifyCopyingViewpointSucceeded = (
  state: ViewpointsStreamShape,
  viewpoint: APIDiscoverViewpointAttributes
): ViewpointsStreamShape => {
  return {
    ...state,
    viewpoints: [...(state.viewpoints ?? []), viewpoint],
  };
};

const handleViewpointIsSaving = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    isSaving: true,
  };
};

const handleViewpointSavingSuccess = (
  state: ViewpointsStreamShape,
  newViewpoint: APIDiscoverViewpointAttributes
): ViewpointsStreamShape => ({
  ...state,
  isSaving: false,
  currentViewpoint: updateCurrentViewpointIfNeeded(
    state.currentViewpoint,
    newViewpoint
  ),
  viewpoints: updateViewpointInViewpointListById(
    state.viewpoints || [],
    newViewpoint
  ),
});

const handleViewpointDeleteSuccess = (
  state: ViewpointsStreamShape,
  viewpointId: ArdoqId
): ViewpointsStreamShape => ({
  ...state,
  isSaving: false,
  viewpoints: (state.viewpoints || []).filter(
    viewpoint => viewpoint._id !== viewpointId
  ),
});

const handleViewpointSaveError = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    isSaving: false,
  };
};

const handleFetchTypeRelationshipsSuccess = (
  state: ViewpointsStreamShape,
  typeRelationships: TypeRelationshipsResult
): ViewpointsStreamShape => ({
  ...state,
  typeRelationships,
});

const handlePopulateRestrictedWorkspaces = (
  { restrictedWorkspacesForEveryViewpoint, ...state }: ViewpointsStreamShape,
  fetchAllWorkspacesPayload: APIWorkspaceAttributes[]
) => {
  return {
    ...state,
    restrictedWorkspacesForEveryViewpoint: populateRestrictedWorkspacesById(
      restrictedWorkspacesForEveryViewpoint,
      fetchAllWorkspacesPayload,
      state?.workspaces || []
    ),
  };
};

const handleViewpointFetchSuccess = (
  state: ViewpointsStreamShape,
  viewpoint: APIDiscoverViewpointAttributes
): ViewpointsStreamShape => {
  return {
    ...state,
    viewpoints: updateViewpointInViewpointListById(
      state.viewpoints ?? [],
      viewpoint
    ),
  };
};

const handleToggleViewpointLegacyMode = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    isInLegacyViewpointMode: !state.isInLegacyViewpointMode,
  };
};

const handleSetViewpointLegacyMode = (
  state: ViewpointsStreamShape,
  isInLegacyViewpointMode: boolean
) => {
  return {
    ...state,
    isInLegacyViewpointMode,
  };
};

const handleNavigateToNewViewpoints = (state: ViewpointsStreamShape) => {
  return {
    ...state,
    isInLegacyViewpointMode: false,
  };
};

const setLocaleReducer = (state: ViewpointsStreamShape, locale: Locale) => ({
  ...state,
  locale,
});

export const reducers = [
  reducer(viewpointsFetchSuccess, handleViewpointsFetchSuccess),
  reducer(viewpointsFetchError, handleViewpointsFetchError),
  reducer(viewpointsFetch, handleViewpointsIsFetching),
  streamReducer(viewpointsNavigation$, handleViewpointsNavigationStateChange),
  streamReducer(workspaces$, handleWorkspacesChange),
  streamReducer(surveys$, handleSurveysChange),
  streamReducer(assetFolders$, handleFoldersChange),
  reducer(fetchAvailableTriples, handleFetchAvailableTriples),
  reducer(fetchAvailableTriplesSuccess, handleFetchAvailableTriplesSuccess),
  reducer(fetchAvailableTriplesError, handleFetchAvailableTriplesError),
  reducer(
    notifyCopyingViewpointSucceeded,
    handleNotifyCopyingViewpointSucceeded
  ),
  reducer(viewpointSave, handleViewpointIsSaving),
  reducer(viewpointSaveSuccess, handleViewpointSavingSuccess),
  reducer(viewpointDeleteSuccess, handleViewpointDeleteSuccess),
  reducer(viewpointSaveError, handleViewpointSaveError),
  reducer(fetchTypeRelationshipsSuccess, handleFetchTypeRelationshipsSuccess),
  reducer(populateRestrictedWorkspaces, handlePopulateRestrictedWorkspaces),
  reducer(viewpointFetchSuccess, handleViewpointFetchSuccess),
  reducer(toggleViewpointLegacyMode, handleToggleViewpointLegacyMode),
  reducer(setViewpointLegacyMode, handleSetViewpointLegacyMode),
  reducer(navigateToNewViewpoints, handleNavigateToNewViewpoints),
  streamReducer(locale$, setLocaleReducer),
];
