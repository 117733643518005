import { api, handleError, userApi } from '@ardoq/api';
import {
  BulkRemoveUsersPayload,
  BulkUpdatedUsersPrivilegePayload,
  BulkUpdatedUsersRolePayload,
  SetPrivilege,
} from '@ardoq/api-types';
import { getArdoqErrorMessage, isArdoqError } from '@ardoq/common-helpers';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { ToastType, showToast } from '@ardoq/status-ui';
import { isEmpty } from 'lodash';
import { fetchPrivileges } from 'privileges/actions';
import privileges$ from 'privileges/privileges$';
import { PrivilegeAction } from 'privileges/types';
import { privilegeIsRestrictedFromRole } from 'privileges/utils';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { updateCurrentUser } from 'streams/currentUser/actions';
import { fetchOrgUsers } from 'streams/orgUsers/actions';
import { APPSEC_EVENTS } from 'tracking/AppsecEvents';
import { trackEvent } from 'tracking/tracking';
import {
  bulkRemoveUsers,
  bulkUpdateUserPrivileges,
  bulkUpdateUserPrivilegesFailed,
  bulkUpdateUserPrivilegesSucceeded,
  bulkUpdateUserRoles,
} from './actions';

const handleBulkUpdateUserRoles = routine(
  ofType(bulkUpdateUserRoles),
  extractPayload(),
  switchMap(({ selectedUserIds, selectedRoleLabel }) => {
    const payload = selectedUserIds.reduce<BulkUpdatedUsersRolePayload>(
      (acc, id) => ({ ...acc, [id]: { setRole: selectedRoleLabel } }),
      {}
    );
    return userApi.bulkUpdate(payload);
  }),
  handleError(error => {
    api.logErrorIfNeeded(error);
    showToast('Role access failed.', ToastType.INFO);
  }),
  tap(() => {
    dispatchAction(fetchOrgUsers());
    dispatchAction(updateCurrentUser());
    dispatchAction(fetchPrivileges());
    showToast('Role access saved.', ToastType.SUCCESS);
  })
);

const handleBulkUpdateUserPrivileges = routine(
  ofType(bulkUpdateUserPrivileges),
  extractPayload(),
  tap(({ selectedUsers, selectedPrivileges, privilegeAction }) => {
    const eventName =
      privilegeAction === PrivilegeAction.ASSIGN
        ? APPSEC_EVENTS.ASSIGNED_PRIVILEGE_TO_USER
        : APPSEC_EVENTS.REVOKED_PRIVILEGE_FROM_USER;
    selectedPrivileges.forEach(privilegeLabel => {
      selectedUsers.forEach(user => {
        trackEvent(eventName, {
          privilegeLabel,
          role: user.role,
          id: user._id,
        });
      });
    });
  }),
  withLatestFrom(privileges$),
  switchMap(
    async ([
      { selectedUsers, selectedPrivileges, privilegeAction },
      { configurablePrivileges },
    ]) => {
      const payload = selectedUsers.reduce<BulkUpdatedUsersPrivilegePayload>(
        (acc, user) => {
          const newPrivileges = selectedPrivileges.reduce<SetPrivilege>(
            (state, privilege) => {
              const privilegeCanBeAssigned =
                !user.inheritedPrivileges.includes(privilege) &&
                !privilegeIsRestrictedFromRole(
                  privilege,
                  user.role,
                  configurablePrivileges
                );
              if (!privilegeCanBeAssigned) return state;
              return {
                ...state,
                [privilege]: {
                  enabled: Boolean(privilegeAction === PrivilegeAction.ASSIGN),
                },
              };
            },
            {}
          );
          if (isEmpty(newPrivileges)) return acc;
          return {
            ...acc,
            [user._id]: {
              setPrivileges: newPrivileges,
            },
          };
        },
        {}
      );
      const response = await userApi.bulkUpdate(payload);
      if (isArdoqError(response)) {
        api.logErrorIfNeeded(response);

        showToast('Privilege assignment failed.', ToastType.INFO);
        dispatchAction(
          bulkUpdateUserPrivilegesFailed({
            message: getArdoqErrorMessage(response),
          })
        );
        return;
      }
      dispatchAction(bulkUpdateUserPrivilegesSucceeded());
      dispatchAction(fetchPrivileges());
      dispatchAction(updateCurrentUser());
      showToast(
        privilegeAction === PrivilegeAction.ASSIGN
          ? 'Privilege access saved.'
          : 'Privileges removed',
        ToastType.SUCCESS
      );
      return response;
    }
  )
);

const handleBulkRemoveUsers = routine(
  ofType(bulkRemoveUsers),
  extractPayload(),
  switchMap(selectedUserIds => {
    const payload = selectedUserIds.reduce<BulkRemoveUsersPayload>(
      (acc, id) => ({ ...acc, [id]: { revokeMembership: true } }),
      {}
    );
    return userApi.bulkUpdate(payload);
  }),
  handleError(error => {
    api.logErrorIfNeeded(error);
    showToast('User removal failed.', ToastType.INFO);
    dispatchAction(
      bulkUpdateUserPrivilegesFailed({ message: getArdoqErrorMessage(error) })
    );
  }),
  tap(() => {
    dispatchAction(fetchOrgUsers());
    dispatchAction(bulkUpdateUserPrivilegesSucceeded());

    showToast('Users removed.', ToastType.SUCCESS);
  })
);

export default collectRoutines(
  handleBulkUpdateUserRoles,
  handleBulkUpdateUserPrivileges,
  handleBulkRemoveUsers
);
