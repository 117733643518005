import { routine, ofType } from '@ardoq/rxbeach';
import { tap } from 'rxjs';
import { WSClosingReason } from 'sync/types';
import * as wamp from 'sync/wamp';
import { loggedOut } from './actions';
import { redirectToLogin, temporaryDisableAutoLogin } from './auth';

export const handleLoggedOutRoutine = routine(
  ofType(loggedOut),
  tap(() => {
    wamp.close({ reason: WSClosingReason.LOGOUT });
    temporaryDisableAutoLogin();
    redirectToLogin(false);
  })
);
