import { ListEditorProps } from 'scopeData/editors/types';
import { ComponentImageSelect } from '@ardoq/select';

const ImageSelector = ({
  label,
  value,
  options,
  onValueChange,
  isDisabled,
  disabledMessage,
  description,
  dataClickId,
  placeholder,
}: ListEditorProps) => {
  return (
    <ComponentImageSelect
      label={label}
      value={value as string}
      images={options.map(({ value, label }) => ({ url: value, label }))}
      onValueChange={onValueChange}
      isDisabled={isDisabled}
      helperText={isDisabled ? disabledMessage : description}
      clickId={dataClickId}
      placeholder={placeholder}
      isClearable
    />
  );
};

export default ImageSelector;
