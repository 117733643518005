import { ArdoqId, PrivilegeLabel } from '@ardoq/api-types';
import { hasPrivilege } from '@ardoq/privileges';
import {
  canDo,
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';

/**
 * Check if the current user has the correct access to
 * create a new Dashboard (checks for privileges, role, and feature).
 * @returns {boolean}
 */
const canCreateDashboard = (permissionContext: PermissionContext): boolean =>
  permissionsOperations.isLoadedPermissionContext(permissionContext) &&
  canDo({
    action: 'organization/create_dashboard',
    permissionContext,
    resourceId: permissionContext.user.organization._id,
  });

/**
 * Check if the current user has the correct access to
 * copy the Dashboard as is (checks for privileges, role, and feature).
 * @returns {boolean}
 */
const canCopyDashboard = (): boolean =>
  hasPrivilege(PrivilegeLabel.GLOBAL_DATA_ACCESS);

/**
 * Check if the current user has the correct access to
 * Admin a Dashboard (checks for permissions, role, and feature).
 * @returns {boolean}
 */
const canAdminDashboard = (
  permissionContext: PermissionContext,
  dashboardId: ArdoqId
): boolean =>
  canDo({
    action: 'dashboard/admin',
    permissionContext,
    resourceId: dashboardId,
  });
/**
 * Check if the current user has the correct access to
 * edit a Dashboard (checks for permissions, role, and feature).
 * @returns {boolean}
 */
const canEditDashboard = (
  permissionContext: PermissionContext,
  dashboardId: ArdoqId
): boolean =>
  canDo({
    action: 'dashboard/edit',
    permissionContext,
    resourceId: dashboardId,
  });

/**
 * Check if the current user has the correct access to
 * read a new Dashboard (checks for permissions, role, and feature).
 * @returns {boolean}
 */
const canReadDashboard = (
  permissionContext: PermissionContext,
  dashboardId: ArdoqId
): boolean =>
  canDo({
    action: 'dashboard/read',
    permissionContext,
    resourceId: dashboardId,
  });

export const dashboardAccessControlInterface = {
  canCreateDashboard,
  canCopyDashboard,
  canAdminDashboard,
  canEditDashboard,
  canReadDashboard,
};
