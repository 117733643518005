import { createStreamFromBackboneModel } from 'streams/utils/streamUtils';
import currentUserModel from 'models/currentUser';
import { get } from 'lodash';
import { filter } from 'rxjs';
import {
  APICurrentUser,
  APICurrentUserFavorites,
  AssetType,
  Organization,
  PersonalSetting,
} from '@ardoq/api-types';
import { getIsOrgAdmin } from '@ardoq/common-helpers';

export type CurrentUserOrganization = Omit<
  Organization,
  'is-trial' | 'days-remaining-trial' | 'expired'
>;

export type CurrentUserState = APICurrentUser & {
  public: boolean;
};

export const getIsOrgWriter = (currentUser: CurrentUserState): boolean => {
  // It's quite inconsistent to check if user is editor or admin of an org
  // I explained the issue https://ardoqcom.atlassian.net/browse/ARD-3522
  // once this is done we can update that selector
  const writeAccess =
    get(currentUser, 'writeAccess') && !get(currentUser, 'public');
  return getIsOrgAdmin(currentUser) || writeAccess;
};

export const getFavorites = ({
  settings,
}: CurrentUserState): APICurrentUserFavorites => ({
  dashboards: [],
  [AssetType.FOLDER]: [],
  [AssetType.WORKSPACE]: [],
  [AssetType.METAMODEL]: [],
  [AssetType.PRESENTATION]: [],
  [AssetType.SCENARIO]: [],
  [AssetType.SURVEY]: [],
  [AssetType.REPORT]: [],
  [AssetType.DASHBOARD]: [],
  [AssetType.VIEWPOINT]: [],
  [AssetType.BROADCAST]: [],
  [AssetType.TRAVERSAL]: [],
  [AssetType.BOOKMARK]: [],
  ...settings[PersonalSetting.FAVORITE],
});

const currentUser$ = createStreamFromBackboneModel<CurrentUserState>(
  currentUserModel,
  [
    '_id',
    'name',
    'email',
    'writeAccess',
    'settings',
    'roles',
    'groups',
    'organization',
    'organizations',
    'public',
    'features',
    'privileges',
    'subdivisionCreationContext',
  ]
);

/**
 * The current user model singleton is empty until loaded. This stream only emits when the user is done loading.
 */
export const loadedCurrentUser$ = currentUser$.pipe(
  filter(currentUser => currentUser._id !== undefined)
);

export default currentUser$;
