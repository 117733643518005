import {
  DataSourceItem,
  DiffTableRowType,
  FieldConflict,
  MergeState,
  MergeTableSectionType,
  Path,
  StructuralConflict,
} from 'components/DiffMergeTable/types';
import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { getEntityById } from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';

const getItExistsOnBranch = ({
  entityId,
  entityType,
  enhancedScopeData,
  parentEntityId,
}: {
  entityId: string;
  entityType: APIEntityType;
  enhancedScopeData: EnhancedScopeData;
  parentEntityId?: string;
}) =>
  Boolean(
    getEntityById(entityType, entityId, enhancedScopeData, parentEntityId)
  );

const getEntitySectionType = ({
  entityUniqueId,
  entityType,
  enhancedDiffContextData,
}: {
  entityUniqueId: string;
  entityType: APIEntityType;
  enhancedDiffContextData: EnhancedDiffScopeData;
}) => {
  const { branchOff, branch } = enhancedDiffContextData;
  const { entityId, parentEntityId } = splitEntityUniqueId(entityUniqueId);

  const itExistsOnBranchOff = getItExistsOnBranch({
    entityId,
    entityType,
    parentEntityId,
    enhancedScopeData: branchOff,
  });

  const itExistsOnBranch = getItExistsOnBranch({
    entityId,
    entityType,
    parentEntityId,
    enhancedScopeData: branch,
  });

  if (itExistsOnBranchOff && itExistsOnBranch) {
    return MergeTableSectionType.DELETED_IN_MAINLINE;
  } else if (itExistsOnBranchOff && !itExistsOnBranch) {
    return MergeTableSectionType.DELETED_IN_BRANCH;
  } else if (!itExistsOnBranchOff && itExistsOnBranch) {
    return MergeTableSectionType.CREATED_IN_BRANCH;
  } else if (!itExistsOnBranchOff && !itExistsOnBranch) {
    return MergeTableSectionType.CREATED_IN_MAINLINE;
  }
  return MergeTableSectionType.DEFAULT;
};

export const groupEntityIdsBySections = ({
  entityUniqueIds,
  entityType,
  enhancedDiffContextData,
}: {
  entityUniqueIds: string[];
  entityType: APIEntityType;
  enhancedDiffContextData: EnhancedDiffScopeData;
}): Record<MergeTableSectionType, ArdoqId[]> =>
  entityUniqueIds.reduce(
    (sections, entityUniqueId) => {
      sections[
        getEntitySectionType({
          entityUniqueId,
          entityType,
          enhancedDiffContextData,
        })
      ].push(entityUniqueId);
      return sections;
    },
    {
      [MergeTableSectionType.DEFAULT]: [],
      [MergeTableSectionType.CREATED_IN_BRANCH]: [],
      [MergeTableSectionType.DELETED_IN_BRANCH]: [],
      [MergeTableSectionType.CREATED_IN_MAINLINE]: [],
      [MergeTableSectionType.DELETED_IN_MAINLINE]: [],
    } as Record<MergeTableSectionType, ArdoqId[]>
  );

export const getSectionHeader = ({
  sectionType,
  entityType,
  index,
  children,
  enhancedDiffContextData,
  basePath,
  status,
  isDisabled,
  parent,
}: {
  sectionType: MergeTableSectionType;
  entityType: APIEntityType;
  index: number;
  children: number[];
  parent: number;
  enhancedDiffContextData: EnhancedDiffScopeData;
  basePath: Path;
  status: MergeState;
  isDisabled: boolean;
}): DataSourceItem => ({
  rowType: DiffTableRowType.SECTION_HEADER_ROW,
  sectionType,
  entityType,
  index,
  children,
  enhancedDiffContextData,
  isDisabled,
  status,
  parent,
  path: basePath,
  structuralConflict: StructuralConflict.NONE,
  fieldConflict: FieldConflict.NONE,
  entityId: '',
  fieldName: '',
});

const splitEntityUniqueId = (entityUniqueId: string) => {
  const [firstId, secondId] = entityUniqueId.split('-');
  if (secondId) return { entityId: secondId, parentEntityId: firstId };
  return { entityId: firstId, parentEntityId: undefined };
};
