import { actionCreator } from '@ardoq/rxbeach';
import { SupportedAssetTypes } from 'assets/types';

export type SetVisibleAsset = {
  assetType: SupportedAssetTypes;
  assetId: string;
};
export const setVisibleAsset = actionCreator<SetVisibleAsset>(
  '[assets navigation] SET_VISIBLE_ASSET'
);
