import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { get } from 'collectionInterface/genericInterfaces';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';

export const getWorkspace = (
  entityType: APIEntityType,
  entityID: ArdoqId,
  model?: ArdoqId
) => {
  if (entityType === APIEntityType.WORKSPACE) {
    return entityID;
  }
  if (
    entityType === APIEntityType.COMPONENT ||
    entityType === APIEntityType.REFERENCE
  ) {
    const entity = get(entityType, entityID);
    return entity?.rootWorkspace ?? null;
  }
  if (entityType === APIEntityType.FIELD) {
    const field = get(entityType, entityID);
    if (!field) {
      return null;
    }
    const workspace = workspaceInterface.findByModel(field.model);
    return workspace?._id ?? null;
  }
  if (entityType === APIEntityType.REFERENCE_TYPE) {
    const workspace = workspaceInterface.findByModel(model!);
    return workspace?._id ?? null;
  }
  return null;
};
