import { actionCreator } from '@ardoq/rxbeach';
import { CalculatedFieldOptions } from './types';

export const updateCalculatedFieldOptions = actionCreator<
  Readonly<CalculatedFieldOptions>
>('[calculatedFieldOptions] UPDATE_CALCULATED_FIELD_OPTIONS');

export const fetchCalculatedFieldOptions = actionCreator<{ fieldId: string }>(
  '[calculatedFieldOptions] FETCH_CALCULATED_FIELD_OPTIONS'
);
