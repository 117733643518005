import { defaultState, reducers } from './reducers';
import { persistentReducedStream } from '@ardoq/rxbeach';

const viewpointsNavigation$ = persistentReducedStream(
  'viewpointsNavigation$',
  defaultState,
  reducers
);

export default viewpointsNavigation$;
