import { defaultQueryBuilderRules } from 'search/AdvancedSearch/consts';
import { getFilterConditionByType } from 'broadcasts/utils';
import {
  AdvancedSearchFilterConditionByReference,
  BroadcastFilterCondition,
  BroadcastFilterConditionType,
  QueryBuilderSubquery,
} from '@ardoq/api-types';

export const newAdvancedSearchCondition = (
  advancedQuery: QueryBuilderSubquery = defaultQueryBuilderRules,
  outgoingReferencesSelected?: boolean,
  incomingReferencesSelected?: boolean
): AdvancedSearchFilterConditionByReference => ({
  conditionType: BroadcastFilterConditionType.ADVANCED_SEARCH_BY_REFERENCE,
  advancedQuery,
  outgoingReferencesSelected: outgoingReferencesSelected ?? true,
  incomingReferencesSelected: incomingReferencesSelected ?? true,
});

export const getDefaultState = () => ({
  isEnabled: false,
  isChecked: false,
  isExpanded: false,
  advancedSearchCondition: newAdvancedSearchCondition(),
});

export const findAdvancedSearchCondition = (
  filterConditions: BroadcastFilterCondition[]
) => {
  return getFilterConditionByType(
    filterConditions,
    BroadcastFilterConditionType.ADVANCED_SEARCH_BY_REFERENCE
  );
};
