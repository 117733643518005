import { APIViewpointAttributes, ArdoqId } from '@ardoq/api-types';
import { TraversalState } from './types';

const getById = (
  traversalState: TraversalState,
  id: ArdoqId
): APIViewpointAttributes | null => {
  return traversalState.byId[id] ?? null;
};

const isEmpty = (traversalState: TraversalState): boolean => {
  return traversalState.list.length === 0;
};

const getAsList = (
  traversalState: TraversalState
): APIViewpointAttributes[] => {
  return traversalState.list;
};

const getAllById = (
  traversalState: TraversalState
): Record<ArdoqId, APIViewpointAttributes> => {
  return traversalState.byId;
};

export const traversalStateOperations = {
  getById,
  isEmpty,
  getAsList,
  getAllById,
};
