import { handleError } from '@ardoq/api';
import {
  dispatchAction,
  extractPayload,
  ofType,
  routine,
  withNamespace,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs';
import {
  fetchAllFailed,
  fetchAllRequested,
  fetchAllSucceeded,
  deleteRequested,
} from './actions';
import { Result } from '@ardoq/common-helpers';
import { ArdoqId, Entity } from '@ardoq/api-types';
import { presentationsNamespace } from 'streams/presentations/presentations$';
import { notifyPresentationDeleted } from 'streams/presentations/actions';

export const handleFetchAll = <E extends Entity>(
  namespace: string,
  fetchAllFn: () => Promise<Result<E[]>>
) =>
  routine(
    withNamespace(namespace),
    ofType(fetchAllRequested),
    switchMap(fetchAllFn),
    handleError(error => {
      dispatchAction(fetchAllFailed(namespace, error));
    }),
    tap(entities => {
      dispatchAction(fetchAllSucceeded(namespace, entities));
    })
  );

export const handleDelete = (
  namespace: string,
  removeFn: (id: ArdoqId) => Promise<Result<ArdoqId>>
) =>
  routine(
    withNamespace(namespace),
    ofType(deleteRequested),
    extractPayload(),
    switchMap(removeFn),
    handleError(),
    tap(entities => {
      // needed for assets which are set in context
      switch (namespace) {
        case presentationsNamespace: {
          dispatchAction(notifyPresentationDeleted(entities));
          break;
        }
        default:
          break;
      }
    })
  );
