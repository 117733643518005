import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultState } from './utils';

const advancedSearchCondition$ = persistentReducedStream(
  'advancedSearchCondition$',
  getDefaultState(),
  reducers
);

export default advancedSearchCondition$;
