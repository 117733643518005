import CreatableList from 'scopeData/editors/CreatableList';
import { connect } from '@ardoq/rxbeach';
import { CreatableListEditorProps } from 'scopeData/editors/types';
import addFieldToEntity$ from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/addFieldToEntity$';
import { fieldLabelCreationValidator } from 'scopeData/editors/validators';
import { INVALID_FIELD_NAME_MESSAGE } from 'models/consts';

type EventsCallBacks = {
  onChange?: (fieldLabel: string) => void;
  onCreate?: (fieldLabel: string) => void;
};

const AddFieldToEntity = connect(
  ({
    options,
    isDisabled,
    disabledMessage,
    onChange = () => {},
    onCreate = () => {},
  }: Pick<
    CreatableListEditorProps,
    'options' | 'isDisabled' | 'disabledMessage'
  > &
    EventsCallBacks) => (
    <CreatableList
      label="Add field to this type"
      value={null}
      options={options}
      creationValidator={fieldLabelCreationValidator}
      onValueChange={fieldName => onChange(fieldName as string)}
      onCreateOption={onCreate}
      invalidOptionMessage={INVALID_FIELD_NAME_MESSAGE}
      isDisabled={isDisabled}
      disabledMessage={disabledMessage}
      dataClickId="editor-input-add-field-to-entity"
    />
  ),
  addFieldToEntity$
);

export default AddFieldToEntity;
