import { useState } from 'react';
import {
  ArdoqId,
  QueryBuilderQuery,
  APIScenarioAttributes,
} from '@ardoq/api-types';
import CreateScenarioStep from './CreateScenarioStep';
import AddToScenarioStep from './AddToScenarioStep';
import ProgressStep from './ProgressStep';
import SuccessStep from './SuccessStep';
import ErrorStep from './ErrorStep';
import { ModalStep } from './types';
import { useEffectOnce } from '@ardoq/hooks';
import { logError } from '@ardoq/logging';
import {
  ScenarioCreationExceededComponentsLimitError,
  getComponentIdsFromSearchQuery,
} from './utils';
import { ArdoqError, isArdoqError } from '@ardoq/common-helpers';
import { ModalSize, ModalTemplate, modal } from '@ardoq/modal';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { scenarioApi } from '@ardoq/api';
import { PayloadOpenAddToScenarioDialog } from 'scope/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';

type ScenarioModalProps = {
  addToExistingScenario: boolean;
  scenarios: APIScenarioAttributes[];
  onClose: (scenario?: APIScenarioAttributes) => void;
  componentIds?: ArdoqId[];
  searchQuery?: QueryBuilderQuery;
  loadedGraph?: LoadedGraphWithViewpointMode;
};

type UseComponentIdsProps = {
  setStep: (step: ModalStep) => void;
  initialComponentIds?: ArdoqId[];
  searchQuery?: QueryBuilderQuery;
  loadedGraph?: LoadedGraphWithViewpointMode;
};

const useLoadComponentIds = ({
  setStep,
  initialComponentIds,
  searchQuery,
  loadedGraph,
}: UseComponentIdsProps) => {
  const [componentIds, setComponentIds] = useState<ArdoqId[]>(
    initialComponentIds || []
  );
  const [componentIdsLoaded, setComponentIdsLoaded] = useState<boolean>(false);
  useEffectOnce(() => {
    (async () => {
      if (loadedGraph && loadedGraph.isViewpointMode) {
        setComponentIds(loadedGraph.scopeComponentIds);
        setComponentIdsLoaded(true);
        return;
      }

      if (searchQuery) {
        setStep(ModalStep.PRELOADING_COMPONENTS);
        const components = await getComponentIdsFromSearchQuery(searchQuery);
        if (isArdoqError(components)) {
          // TODO[ARD-22935]: Leaving this as is for now, but when we only use ArdoqError, the instanceof can be used on error.error
          const error = isArdoqError(components)
            ? components.error
            : components;
          logError(error);
          if (error instanceof ScenarioCreationExceededComponentsLimitError) {
            setStep(ModalStep.COMPONENTS_NUMBER_EXCEEDED);
          } else {
            setStep(ModalStep.PRELOADING_FAILED);
          }
          return;
        }
        setComponentIds(components);
        setComponentIdsLoaded(true);
      } else {
        setComponentIdsLoaded(true);
      }
    })();
  });
  return { componentIds, componentIdsLoaded };
};

const ScenarioModal = ({
  onClose,
  addToExistingScenario,
  scenarios,
  searchQuery,
  loadedGraph,
  ...props
}: ScenarioModalProps) => {
  const [scenario, setScenario] = useState<APIScenarioAttributes | null>(null);
  const initialStep = addToExistingScenario
    ? ModalStep.ADD_TO_SCENARIO
    : ModalStep.CREATE_SCENARIO;
  const [step, setStep] = useState<ModalStep>(initialStep);
  const [apiError, setApiError] = useState<ArdoqError>();
  const { componentIds, componentIdsLoaded } = useLoadComponentIds({
    initialComponentIds: props.componentIds,
    setStep,
    searchQuery,
    loadedGraph,
  });

  const onCancel = () => onClose();
  const onComplete = () => scenario && onClose(scenario);

  const handleCreateScenario = async (
    name: string,
    referenceIds: ArdoqId[]
  ) => {
    setStep(ModalStep.CREATING_SCENARIO);
    const newScenario = await scenarioApi.create({
      name,
      componentIds,
      referenceIds,
    });
    if (isArdoqError(newScenario)) {
      setApiError(newScenario);
      setStep(ModalStep.CREATING_SCENARIO_FAILED);
      return;
    }
    setScenario(newScenario.scenario);
    setStep(ModalStep.SUCCESS);
    dispatchAction(
      addPermissionForResource({
        permission: newScenario.permission,
        currentUser: currentUserInterface.getCurrentUserAttributes(),
      })
    );
    return newScenario;
  };

  const handleUpdateScenario = async (
    scenarioToUpdate: APIScenarioAttributes,
    referenceIds: ArdoqId[]
  ) => {
    setStep(ModalStep.UPDATING_SCENARIO);
    const response = await scenarioApi.addToScenario({
      scenarioId: scenarioToUpdate._id,
      componentIds,
      referenceIds,
    });
    if (isArdoqError(response)) {
      setApiError(response);
      setStep(ModalStep.UPDATING_SCENARIO_FAILED);
      return;
    }
    setScenario(scenarioToUpdate);
    setStep(ModalStep.SUCCESS);

    return scenarioToUpdate;
  };
  return (
    <ModalTemplate modalSize={ModalSize.S} fixedHeight="400px">
      {!addToExistingScenario && componentIdsLoaded && (
        <CreateScenarioStep
          step={step}
          setStep={setStep}
          componentIds={componentIds}
          loadedGraph={loadedGraph}
          onCancel={onCancel}
          onConfirm={handleCreateScenario}
        />
      )}
      {addToExistingScenario && step === ModalStep.ADD_TO_SCENARIO && (
        <AddToScenarioStep
          scenarios={scenarios}
          componentIds={componentIds}
          onCancel={onCancel}
          onConfirm={handleUpdateScenario}
          onLoadingReferencesFailed={error => {
            logError(error);
            setStep(ModalStep.PRELOADING_FAILED);
          }}
        />
      )}
      {step === ModalStep.SUCCESS && (
        <SuccessStep
          addToExistingScenario={addToExistingScenario}
          onCancel={onCancel}
          onComplete={onComplete}
        />
      )}
      <ProgressStep step={step} onCancel={onCancel} />
      {apiError && (
        <ErrorStep step={step} onCancel={onCancel} error={apiError} />
      )}
    </ModalTemplate>
  );
};

export const addToScenarioModal = (
  {
    componentIds,
    searchQuery,
    addToExistingScenario,
    loadedGraph,
  }: PayloadOpenAddToScenarioDialog,
  scenarios: APIScenarioAttributes[]
) =>
  modal<APIScenarioAttributes | undefined>(resolve => (
    <ScenarioModal
      addToExistingScenario={addToExistingScenario || false}
      componentIds={componentIds}
      loadedGraph={loadedGraph}
      searchQuery={searchQuery}
      onClose={resolve}
      scenarios={scenarios}
    />
  ));
