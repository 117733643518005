import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { IntegrationJobPayload, IntegrationSchedule } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { getScheduleDirection } from 'integrations/common/streams/schedules/utils';
import { text1, text2, text2Bold } from '@ardoq/typography';
import { IntegrationDate } from 'integrations/common/components/integrationDate/IntegrationDate';
import Flex from 'atomicComponents/Flex';
import { deleteSchedule } from 'integrations/common/streams/schedules/actions';
import { SchedulePolicy } from 'integrations/common/components/schedulePolicy/SchedulePolicy';
import { ScheduleStatus } from 'integrations/common/components/scheduleStatus/ScheduleStatus';
import { omit } from 'lodash';

const ScheduleText = styled.div`
  ${text1}
`;

const ScheduleField = styled.div`
  ${text2}
  width: 14ch;
`;

const ScheduleValue = styled.div`
  ${text2Bold}
`;

const Capitalized = styled.div`
  text-transform: capitalize;
`;

type DeleteScheduleModalProps = {
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  schedule: IntegrationSchedule;
};

const DeleteScheduleModal = ({
  onSubmit,
  onCancel,
  schedule,
}: DeleteScheduleModalProps) => {
  const handleSaveChanges = () => {
    dispatchAction(
      deleteSchedule(omit(schedule, 'meta') as IntegrationJobPayload)
    );
    onSubmit();
  };

  return (
    <ModalTemplate
      headerText="Delete the schedule permanently?"
      dangerButtonText="Delete schedule"
      secondaryButtonText="Keep schedule"
      modalSize={ModalSize.S}
      onDangerButtonClick={handleSaveChanges}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <ScheduleText>
          If the schedule <b>{schedule.jobOptions.name}</b> is deleted it will
          stop immediately.
        </ScheduleText>
        <Stack gap="small">
          <Flex>
            <ScheduleField>Name</ScheduleField>
            <ScheduleValue>{schedule.jobOptions.name}</ScheduleValue>
          </Flex>
          <Flex>
            <ScheduleField>Type</ScheduleField>
            <ScheduleValue>
              <Capitalized>
                {getScheduleDirection(schedule.jobOptions.type)}
              </Capitalized>
            </ScheduleValue>
          </Flex>
          <Flex>
            <ScheduleField>Interval</ScheduleField>
            <ScheduleValue>
              <SchedulePolicy
                schedulingPolicy={schedule.schedulingPolicy}
              ></SchedulePolicy>
            </ScheduleValue>
          </Flex>
          <Flex>
            <ScheduleField>Status</ScheduleField>
            <ScheduleStatus meta={schedule.meta}></ScheduleStatus>
          </Flex>
          <Flex>
            <ScheduleField>Last imported</ScheduleField>
            <ScheduleValue>
              <IntegrationDate
                date={schedule.meta.lastCompleted}
                format="DATE"
              />
            </ScheduleValue>
          </Flex>
        </Stack>
        <ScheduleText>
          The schedule can’t be recovered once deleted.
        </ScheduleText>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const startDeleteScheduleModal = (schedule: IntegrationSchedule) =>
  modal<boolean>(
    resolve => (
      <DeleteScheduleModal
        onSubmit={() => resolve(true)}
        onCancel={() => resolve(false)}
        schedule={schedule}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
