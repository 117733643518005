import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultFilterConditionState } from 'broadcasts/utils';

const filterConditionsSection$ = persistentReducedStream(
  'filterConditionsSection$',
  getDefaultFilterConditionState(),
  reducers
);

export default filterConditionsSection$;
