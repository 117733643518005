export const CARDINALITY_ATTRIBUTES = [
  'sourceCardinality',
  'targetCardinality',
];
export const defaultReferenceAttributeNames = [
  'type',
  'displayText',
  ...CARDINALITY_ATTRIBUTES,
  'source',
  'target',
  'description',
  // 'order', // reference order is deprecated.
];
export const defaultMultiReferenceAttributeNames = [
  'type',
  'displayText',
  ...CARDINALITY_ATTRIBUTES,
  'source',
  'target',
  'description',
];
export const propertiesNotEditableAcrossMultipleWorkspaces = [
  'type',
  ...CARDINALITY_ATTRIBUTES,
  'source',
];
