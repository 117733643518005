import { PrimaryButton } from '@ardoq/button';
import { TextInput } from '@ardoq/forms';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ToastType, showToast } from '@ardoq/status-ui';
import { Header1, Header4 } from '@ardoq/typography';
import styled from 'styled-components';
import { dispatchAction } from '@ardoq/rxbeach';
import { trackIntegrationEvent } from 'integrations/common/tracking/actions';
import { s16, s32 } from '@ardoq/design-tokens';
import { FlexBox, Stack } from '@ardoq/layout';
import { microsoftEntraIdDictionary } from 'integrations/common/dictionary';

type GrantAccessProps = {
  consentUrl: string;
};

export function GrantAccess({ consentUrl }: GrantAccessProps) {
  const openLink = () => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'microsoft-entra-id',
        name: 'CLICKED_CONNECTION_GRANT_CONSENT',
      })
    );

    window.open(consentUrl, '_blank');
  };
  const onCopy = () => {
    dispatchAction(
      trackIntegrationEvent({
        integrationId: 'microsoft-entra-id',
        name: 'COPIED_CONNECTION_CONSENT_URL',
      })
    );

    navigator.clipboard.writeText(consentUrl);
    showToast(`Link copied.`, ToastType.SUCCESS);
  };
  return (
    <FlexBox
      gap="xlarge"
      align="center"
      justify="center"
      marginTop="xlarge"
      marginX="large"
    >
      <AccessContent>
        <Stack gap="medium">
          <Header1>
            Ardoq needs access to {microsoftEntraIdDictionary.name}
          </Header1>
          <div>
            To create a connection, a Microsoft Entra user that is authorized to
            consent on behalf of the organization must grant Ardoq access to
            import data and access user profiles and photos. Ardoq won’t be able
            to modify any {microsoftEntraIdDictionary.name} data.
          </div>
          <div>
            Unsure about your user permissions in{' '}
            {microsoftEntraIdDictionary.name}? Learn more about{' '}
            <a
              href="https://learn.microsoft.com/en-us/entra/identity/enterprise-apps/grant-admin-consent?pivots=portal#prerequisites"
              target="_blank"
              rel="noreferrer"
            >
              granting admin consent to an application.{' '}
              <Icon iconName={IconName.OPEN_IN_NEW} iconSize={IconSize.SMALL} />
            </a>
          </div>
        </Stack>
        <Stack gap="small">
          <Header4>Do you have permission to grant admin consent?</Header4>
          <div>
            <ListText>
              1. Click &quot;Grant access&quot; to open a new window.
            </ListText>
            <ListText>2. Accept Ardoq&apos;s access request.</ListText>
            <ListText>3. Return to Ardoq and refresh the page.</ListText>
            <ListText>
              4. A connection is now established; proceed to create a new
              import.
            </ListText>
          </div>
          <ButtonContainer>
            <PrimaryButton onClick={openLink}>
              Grant Access
              <Icon iconName={IconName.OPEN_IN_NEW} />
            </PrimaryButton>
          </ButtonContainer>
        </Stack>
        <Stack gap="small">
          <Header4>Don’t have permission to grant admin consent?</Header4>
          <div>
            <ListText>1. Copy the link below.</ListText>
            <ListText>
              2. Send the link to a Microsoft Entra administrator.
            </ListText>
            <ListText>
              3. After the administrator grants Ardoq access to{' '}
              {microsoftEntraIdDictionary.name}, return to this page and
              refresh.
            </ListText>
            <ListText>
              4. A connection is now established; proceed to create a new
              import.
            </ListText>
          </div>
          <CopyContainer>
            <TextInput
              value={consentUrl}
              rightIconName={IconName.COPY}
              rightIconOnClick={onCopy}
              isReadOnly
            />
          </CopyContainer>
        </Stack>
      </AccessContent>
    </FlexBox>
  );
}

const AccessContent = styled(Stack)`
  min-height: 50vh;
  max-width: 1200px;
  gap: ${s32};
`;

const ListText = styled.p`
  margin: 0;
`;

const ButtonContainer = styled.div`
  margin-top: ${s16};
`;

const CopyContainer = styled.div`
  width: 280px;
  margin-top: ${s16};
`;
