import {
  collectRoutines,
  dispatchAction,
  routine,
  ofType,
} from '@ardoq/rxbeach';
import {
  notifyComponentChanged,
  notifyReferenceContextChanged,
  notifyScenarioChanged,
  notifyWorkspaceChanged,
} from 'streams/context/ContextActions';
import Context from 'context';
import Filters from 'collections/filters';
import { notifyFilterContextChanged } from 'streams/filters/FilterActions';
import { tap } from 'rxjs/operators';
import { ensureComponentFiltersValid } from './legends/componentFilterRuleManager';

const updateFiltersOnContextChangeIfNeeded = routine(
  ofType(
    notifyWorkspaceChanged,
    notifyReferenceContextChanged,
    notifyComponentChanged,
    notifyScenarioChanged
  ),
  tap(() => {
    const activeWorkspaceId = Context.activeWorkspaceId();
    if (activeWorkspaceId) {
      // Both of the following calls can trigger a filterChanged event.
      Filters.ensureWorkspaceInWorkspaceFilter(activeWorkspaceId);
      Filters.removeIrrelevantWorkspaceFilters();
    }

    const componentRules =
      Filters.getFiltersAsQueryBuilderQueries().componentRules;
    let validatedComponentRules = componentRules;
    validatedComponentRules = ensureComponentFiltersValid(
      validatedComponentRules,
      Context.component()
    );
    if (componentRules !== validatedComponentRules) {
      // This will trigger a filter changed event.
      Filters.setFiltersFromQueryBuilderQueries({
        componentRules: validatedComponentRules,
        shouldTriggerChangeEvent: true,
      });
    }
    dispatchAction(notifyFilterContextChanged());
  })
);

export const quickPerspectivesFilterRoutines = collectRoutines(
  updateFiltersOnContextChangeIfNeeded
);
