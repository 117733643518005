import { mapValues } from 'lodash';
import { Meta, Permissions } from '@ardoq/api-types';

const enhanceAssetWithMeta = <T, E>(
  asset: T,
  assetType: E,
  mapMeta: (asset: T) => { permissions: Permissions },
  mapAsset: (asset: T) => any
): T & Meta<E> => ({
  ...asset,
  ...mapAsset(asset),
  meta: { assetType, ...mapMeta(asset) },
});

export const enhanceWithMeta = <T, E>(
  assetsHash: { [id: string]: T },
  assetType: E,
  mapMeta: (asset: T) => { permissions: Permissions } = () => ({
    permissions: {},
  }),
  mapAsset: (asset: T) => any = () => ({})
): { [id: string]: T & Meta<E> } =>
  mapValues({ ...assetsHash }, asset =>
    enhanceAssetWithMeta<T, E>(asset, assetType, mapMeta, mapAsset)
  );
