import { APIEntityType, APITagAttributes, ArdoqId } from '@ardoq/api-types';
import { readRawValue } from '@ardoq/renderers';
import { v4 as uuidv4 } from 'uuid';
import {
  addEntitiesToTag,
  removeEntitiesFromTag,
} from 'scopeData/scopeEditUtils/tags';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { omit } from 'lodash';
import type { EnhancedScopeData } from '@ardoq/data-model';

const getEmptyAPITagAttributes = (): APITagAttributes => {
  return {
    _id: '',
    createdBy: '',
    lastModifiedBy: '',
    createdByEmail: '',
    createdByName: '',
    lastUpdated: '',
    lastModifiedByEmail: '',
    lastModifiedByName: '',
    created: '',
    ardoq: { entityType: APIEntityType.TAG },
    name: '',
    description: '',
    rootWorkspace: '',
    components: [],
    references: [],
    _version: -1,
  };
};

export const applyTagsToEntities = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  currentTags: string[]
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      const newTagsById = Object.fromEntries(
        Object.entries(enhancedScopeData.tagsById).map(entry => {
          const [tagId, tag] = entry;
          if (currentTags.includes(tag.name)) {
            return [tagId, addEntitiesToTag(tag, entityType, entityIDs)];
          }
          return [tagId, removeEntitiesFromTag(tag, entityType, entityIDs)];
        })
      );
      return {
        ...enhancedScopeData,
        tagsById: newTagsById,
        tags: Object.values(newTagsById),
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const addNewTagsToScopeData = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  tagNamesToCreate: string[]
): EnhancedScopeData => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      const workspaces = entityIDs.map(entityID =>
        readRawValue(entityType, entityID, 'rootWorkspace', enhancedScopeData)
      );
      const newTagsById = {
        ...enhancedScopeData.tagsById,
        ...Object.fromEntries(
          workspaces.flatMap(rootWorkspace =>
            tagNamesToCreate.map(name => {
              const id = uuidv4();
              const emptyTag = {
                ...getEmptyAPITagAttributes(),
                name,
                rootWorkspace,
                _id: id,
              };
              return [id, addEntitiesToTag(emptyTag, entityType, entityIDs)];
            })
          )
        ),
      };
      return {
        ...enhancedScopeData,
        tagsById: newTagsById,
        tags: Object.values(newTagsById),
      };
    }
    default:
      // TODO implement other entity types
      return enhancedScopeData;
  }
};

export const removeNewTagsFromScopeData = (
  enhancedScopeData: EnhancedScopeData,
  tagNamesToRemove: string[]
): EnhancedScopeData => {
  const tagIDs = tagNamesToRemove
    .map(
      tagName => enhancedScopeData.tags.find(tag => tag.name === tagName)?._id
    )
    .filter(ExcludeFalsy);
  const newTagsById = omit(enhancedScopeData.tagsById, tagIDs);
  return {
    ...enhancedScopeData,
    tagsById: newTagsById,
    tags: Object.values(newTagsById),
  };
};
