import { reducer } from '@ardoq/rxbeach';
import { GlobalFieldsStreamShape } from 'globalFields/types';
import { setGlobalFields } from 'globalFields/actions';
import { APIGlobalField } from '@ardoq/api-types';

export const defaultState: GlobalFieldsStreamShape = {
  globalFields: [],
};

const handleSetGlobalFields = (
  _state: GlobalFieldsStreamShape,
  globalFields: APIGlobalField[]
): GlobalFieldsStreamShape => {
  return { globalFields };
};

export const reducers = [reducer(setGlobalFields, handleSetGlobalFields)];
