import { ArdoqId, Subdivision } from '@ardoq/api-types';
import {
  CreateManageResourcePermissionViewModelArgs,
  OnChangePermissionProps,
  OnGrantReadAccessUnderlyingResourceProps,
  OnRevokePermissionProps,
  Actor,
  BasicResource,
  AppStateManageResourcePermissionContext,
} from '@ardoq/manage-resource-permissions';
import { actionCreator } from '@ardoq/rxbeach';

export const initializeResourcePermissionViewModel =
  actionCreator<CreateManageResourcePermissionViewModelArgs>(
    '[ResourcePermissionViewModel] INITIALIZE_RESOURCE_PERMISSION_VIEW_MODEL'
  );

export const revokePermission = actionCreator<OnRevokePermissionProps>(
  `[ResourcePermissionViewModel] REVOKE_PERMISSION`
);

export const permissionChange = actionCreator<OnChangePermissionProps>(
  `[ResourcePermissionViewModel] PERMISSION_CHANGE`
);

export const grantReadAccessUnderlyingResource =
  actionCreator<OnGrantReadAccessUnderlyingResourceProps>(
    `[ResourcePermissionViewModel] GRANT_READ_ACCESS_UNDERLYING_RESOURCE`
  );

export type AddNewResourcePermissionPayload = {
  actor: Actor;
  resources: BasicResource[];
  appState: AppStateManageResourcePermissionContext;
};
export const addNewResourcePermission =
  actionCreator<AddNewResourcePermissionPayload>(
    '[ResourcePermissionViewModel] ADD_NEW_RESOURCE_PERMISSION'
  );

type AddResourceToSubdivisionInPermissionsEditorPayload = {
  resourceId: ArdoqId;
  subdivision: Subdivision;
};

export const addResourceToSubdivisionInPermissionsEditor =
  actionCreator<AddResourceToSubdivisionInPermissionsEditorPayload>(
    '[ResourcePermissionViewModel] ADD_RESOURCE_TO_SUBDIVISION_IN_PERMISSIONS_EDITOR'
  );
