import { referenceApi } from '@ardoq/api';
import { isArdoqError } from '@ardoq/common-helpers';
import { EnhancedScopeData } from '@ardoq/data-model';
import {
  APIReferenceAttributes,
  APIReferenceType,
  ArdoqId,
} from '@ardoq/api-types';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';

/**
 * Sorts the list of reference types based on their names, using a predefined order of names.
 */
const matchSorting = (
  referenceTypes: APIReferenceType[],
  sortedReferenceTypeNamesByMostUsed: string[]
): APIReferenceType[] => {
  const refTypesMap = new Map(referenceTypes.map(type => [type.name, type]));

  const sortedRefTypes: APIReferenceType[] = [];
  sortedReferenceTypeNamesByMostUsed.forEach(name => {
    const refType = refTypesMap.get(name);
    if (!refType) {
      return;
    }
    sortedRefTypes.push(refType);
    refTypesMap.delete(name);
  });

  // Add the remaining reference types that were not in the sorted list
  return [...sortedRefTypes, ...refTypesMap.values()];
};

const prepareGetMostUsedReferenceTypesPayload = (
  enhancedScopeData: EnhancedScopeData | null,
  entityIDs: string[]
): PrepareGetMostUsedReferenceTypesPayload | null => {
  const [entityId] = entityIDs;
  if (!enhancedScopeData || !entityId) {
    return null;
  }

  const reference = enhancedScopeDataOperations.getReference(
    enhancedScopeData,
    entityId
  );
  const referenceTypesList =
    reference &&
    enhancedScopeDataOperations.getModelReferenceTypes(
      enhancedScopeData,
      reference.model
    );

  if (!referenceTypesList) {
    return null;
  }
  return { reference, referenceTypesList };
};

type PrepareGetMostUsedReferenceTypesPayload = {
  reference: APIReferenceAttributes;
  referenceTypesList: APIReferenceType[];
};
/**
 * Fetches the most used reference types for the given
 * entity IDs and return the sorted list.
 * @returns The sorted list of reference types or null if
 * the operation failed for any reason.
 */
export const getMostUsedReferenceTypes = async (
  enhancedScopeData: EnhancedScopeData | null,
  entityIDs: ArdoqId[]
) => {
  const payload = prepareGetMostUsedReferenceTypesPayload(
    enhancedScopeData,
    entityIDs
  );
  if (!payload) {
    return null;
  }

  const { reference, referenceTypesList } = payload;
  const response = await referenceApi.getReferenceTypesSortedByMostUsed({
    source: reference.source,
    target: reference.target,
    rootWorkspace: reference.rootWorkspace,
    targetWorkspace: reference.targetWorkspace,
  });

  if (isArdoqError(response) || !response.sortedByMostUsedRefTypes) {
    return null;
  }

  // Sort existing reference types by the most used order
  const sortedByMostUsed = matchSorting(
    referenceTypesList,
    response.sortedByMostUsedRefTypes
  );
  return sortedByMostUsed;
};
