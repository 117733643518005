import { reducer } from '@ardoq/rxbeach';
import { navigateToNewBroadcastForm } from 'router/navigationActions';
import { removeAdvancedSearchCondition } from './actions';
import {
  addAdvancedSearchCondition,
  expandAdvancedSearchCondition,
  toggleAdvancedSearchCondition,
  toggleIncomingReferences,
  toggleOutgoingReferences,
  updateAdvancedSearchCondition,
  updateAdvancedSearchQuery,
} from './actions';
import { AdvancedSearchConditionStreamShape } from './types';
import {
  chooseBroadcastContentType,
  initFilterConditions,
} from 'broadcasts/actions';
import {
  findAdvancedSearchCondition,
  getDefaultState,
  newAdvancedSearchCondition,
} from './utils';
import { createDateRangeQueries } from 'search/AdvancedSearch/utils';
import type {
  BooleanOperator,
  BroadcastFilterCondition,
  QueryBuilderSubquery,
} from '@ardoq/api-types';

const handleInitFilterConditions = (
  state: AdvancedSearchConditionStreamShape,
  filterConditions: BroadcastFilterCondition[]
) => {
  const advancedSearchCondition = findAdvancedSearchCondition(filterConditions);
  if (!advancedSearchCondition) return getDefaultState();
  return {
    ...state,
    isEnabled: true,
    isChecked: true,
    isExpanded: false,
    advancedSearchCondition,
  };
};

const handleExpandAdvancedSearchCondition = (
  state: AdvancedSearchConditionStreamShape,
  isExpanded: boolean
) => ({
  ...state,
  isExpanded,
});

const handleToggleAdvancedSearchCondition = (
  state: AdvancedSearchConditionStreamShape
) => {
  const isChecked = !state.isChecked;
  return {
    ...state,
    isChecked,
    isExpanded: isChecked,
  };
};
const handleToggleIncomingReferences = (
  state: AdvancedSearchConditionStreamShape
) => {
  return {
    ...state,
    advancedSearchCondition: {
      ...state.advancedSearchCondition,
      incomingReferencesSelected:
        !state.advancedSearchCondition.incomingReferencesSelected,
    },
  };
};
const handleToggleOutgoingReferences = (
  state: AdvancedSearchConditionStreamShape
) => {
  return {
    ...state,
    advancedSearchCondition: {
      ...state.advancedSearchCondition,
      outgoingReferencesSelected:
        !state.advancedSearchCondition.outgoingReferencesSelected,
    },
  };
};
const handleAddAdvancedSearchCondition = (
  state: AdvancedSearchConditionStreamShape
) => ({
  ...state,
  isEnabled: true,
  isChecked: true,
  isExpanded: true,
});
const handleUpdateAdvancedSearchCondition = (
  state: AdvancedSearchConditionStreamShape,
  condition: BooleanOperator
) => ({
  ...state,
  advancedSearchCondition: newAdvancedSearchCondition(
    {
      ...state.advancedSearchCondition.advancedQuery,
      condition,
    },
    state.advancedSearchCondition.outgoingReferencesSelected,
    state.advancedSearchCondition.incomingReferencesSelected
  ),
});

const handleUpdateAdvancedSearchQuery = (
  state: AdvancedSearchConditionStreamShape,
  advancedQuery: QueryBuilderSubquery | null
) => ({
  ...state,
  advancedSearchCondition: advancedQuery
    ? newAdvancedSearchCondition(
        createDateRangeQueries(advancedQuery),
        state.advancedSearchCondition.outgoingReferencesSelected,
        state.advancedSearchCondition.incomingReferencesSelected
      )
    : newAdvancedSearchCondition(),
});

export const reducers = [
  reducer(navigateToNewBroadcastForm, getDefaultState),
  reducer(initFilterConditions, handleInitFilterConditions),
  reducer(chooseBroadcastContentType, getDefaultState),
  reducer(expandAdvancedSearchCondition, handleExpandAdvancedSearchCondition),
  reducer(toggleAdvancedSearchCondition, handleToggleAdvancedSearchCondition),
  reducer(addAdvancedSearchCondition, handleAddAdvancedSearchCondition),
  reducer(updateAdvancedSearchCondition, handleUpdateAdvancedSearchCondition),
  reducer(removeAdvancedSearchCondition, getDefaultState),
  reducer(updateAdvancedSearchQuery, handleUpdateAdvancedSearchQuery),
  reducer(toggleIncomingReferences, handleToggleIncomingReferences),
  reducer(toggleOutgoingReferences, handleToggleOutgoingReferences),
];
