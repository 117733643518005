import fp from 'lodash/fp';
import { INTEGRATION_IDS } from './activeIntegrations/constants';
import { IntegrationId } from './tabularMappings/types';

export function buildInitialStreamState<T>(
  build: (integrationId: IntegrationId) => T
): Record<IntegrationId, T> {
  return fp.reduce(
    (acc, id) => ({ ...acc, [id]: build(id) }),
    {},
    INTEGRATION_IDS
  ) as Record<IntegrationId, T>;
}
