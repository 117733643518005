import { ApiDashboardsFailurePayload, DashboardsStreamShape } from './types';
import {
  APIDashboardAttributes,
  ArdoqId,
  ResourceType,
} from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';
import { reducer, streamReducer } from '@ardoq/rxbeach';
import { filter } from 'rxjs';
import {
  bulkDeleteDashboard,
  dashboardDeletedSuccessfully,
  fetchAllDashboardsFailed,
  fetchAllDashboardsRequested,
  fetchAllDashboardsSucceeded,
} from './actions';
import { omit } from 'lodash';
import { ArdoqEvent, EventType } from '../../sync/types';
import { ardoqEventOperations } from '../../sync/ardoqEventOperations';
import { logWarn } from '@ardoq/logging';
import { websocket$ } from '../../sync/websocket$';

export const defaultState: DashboardsStreamShape = {
  dashboards: [],
  dashboardsById: {},
  isFetching: false,
  fetchingError: null,
};

const startDashboardsFetching = (
  state: DashboardsStreamShape
): DashboardsStreamShape => ({
  ...state,
  isFetching: true,
  fetchingError: null,
});

const setDashboards = (
  state: DashboardsStreamShape,
  payload: APIDashboardAttributes[]
): DashboardsStreamShape => ({
  ...state,
  dashboards: payload,
  dashboardsById: toByIdDictionary(payload),
  isFetching: false,
  fetchingError: null,
});

const setFetchingError = (
  state: DashboardsStreamShape,
  payload: ApiDashboardsFailurePayload
): DashboardsStreamShape => ({
  ...state,
  isFetching: false,
  fetchingError: payload,
});

const deleteDashboard = (
  state: DashboardsStreamShape,
  payload: ArdoqId
): DashboardsStreamShape => ({
  ...state,
  dashboards: state.dashboards.filter(dashboard => dashboard._id !== payload),
  dashboardsById: omit(state.dashboardsById, payload),
});

const deleteMultipleDashboards = (
  state: DashboardsStreamShape,
  payload: ArdoqId[]
): DashboardsStreamShape => {
  const filteredDashboards = state.dashboards.filter(
    ({ _id }) => !payload.includes(_id)
  );
  return {
    ...state,
    dashboards: filteredDashboards,
    dashboardsById: omit(state.dashboardsById, payload),
  };
};

const saveDashboard = (
  state: DashboardsStreamShape,
  payload: APIDashboardAttributes
): DashboardsStreamShape => ({
  ...state,
  dashboards: [
    ...state.dashboards.filter(dashboard => dashboard._id !== payload._id),
    payload,
  ],
  dashboardsById: { ...state.dashboardsById, [payload._id]: payload },
});

const handleWebsocketEvent = (
  state: DashboardsStreamShape,
  event: ArdoqEvent<APIDashboardAttributes>
): DashboardsStreamShape => {
  const eventType = event['event-type'];
  switch (eventType) {
    case EventType.DELETE:
      return deleteDashboard(state, event.data._id);
    case EventType.CREATE:
      return saveDashboard(state, event.data);
    case EventType.UPDATE:
      return saveDashboard(state, event.data);
    default:
      logWarn(new Error('unknown event-type'), eventType);
  }

  return state;
};

const isDashboardEvent = (
  event: ArdoqEvent<unknown>
): event is ArdoqEvent<APIDashboardAttributes> => {
  return ardoqEventOperations.isOfResourceType(event, ResourceType.DASHBOARD);
};

export const dashboardReducers = [
  reducer<DashboardsStreamShape>(
    fetchAllDashboardsRequested,
    startDashboardsFetching
  ),
  reducer<DashboardsStreamShape, APIDashboardAttributes[]>(
    fetchAllDashboardsSucceeded,
    setDashboards
  ),
  reducer<DashboardsStreamShape, ApiDashboardsFailurePayload>(
    fetchAllDashboardsFailed,
    setFetchingError
  ),
  reducer<DashboardsStreamShape, ArdoqId>(
    dashboardDeletedSuccessfully,
    deleteDashboard
  ),
  reducer<DashboardsStreamShape, ArdoqId[]>(
    bulkDeleteDashboard,
    deleteMultipleDashboards
  ),
  streamReducer(
    websocket$.pipe(filter(isDashboardEvent)),
    handleWebsocketEvent
  ),
];
