import { ArdoqId, Privilege, PrivilegeLabel } from '@ardoq/api-types';
import { capitalize, pluralize } from '@ardoq/common-helpers';
import { DoqType } from '@ardoq/doq';
import { TableListItem, TableList } from '@ardoq/list';
import { ModalSize, confirm } from '@ardoq/modal';
import { PrivilegeAction } from 'privileges/types';
import { getPrivilegeDescription } from 'privileges/utils';

type PrivilegeChangedUserIds = {
  users?: ArdoqId[];
  action: PrivilegeAction;
};
export type ChangedUserIdsByPrivileges = {
  [k in PrivilegeLabel]?: PrivilegeChangedUserIds;
};

type ConfirmRoleChangesConfig = {
  userIdsByPrivilege: ChangedUserIdsByPrivileges;
  privileges: Privilege[];
  roleLabel: string;
};

export const openConfirmRoleChangesDialog = ({
  userIdsByPrivilege,
  privileges,
  roleLabel,
}: ConfirmRoleChangesConfig) => {
  const hasChanges = Object.entries(userIdsByPrivilege).length;
  let grantedPrivileges: [PrivilegeLabel, PrivilegeChangedUserIds][] = [];
  let revokedPrivileges: [PrivilegeLabel, PrivilegeChangedUserIds][] = [];
  Object.entries(userIdsByPrivilege)
    .filter(([_, changes]) => changes)
    .forEach(change => {
      if (change[1].action === PrivilegeAction.ASSIGN) {
        grantedPrivileges = [
          ...grantedPrivileges,
          change as [PrivilegeLabel, PrivilegeChangedUserIds],
        ];
      } else {
        revokedPrivileges = [
          ...revokedPrivileges,
          change as [PrivilegeLabel, PrivilegeChangedUserIds],
        ];
      }
    });
  return confirm({
    modalSize: ModalSize.S,
    doqType: !hasChanges ? DoqType.TABLE_ERROR : undefined,
    title: 'Save Role changes?',
    confirmButtonTitle: 'Save Changes',
    text: (
      <>
        <TableList isVertical>
          {revokedPrivileges.length ? (
            <TableListItem label="Privileges removed">
              <ul>
                {revokedPrivileges.map(([privilege]) => (
                  <li key={privilege}>
                    {getPrivilegeDescription(
                      privileges,
                      privilege as PrivilegeLabel
                    )}
                    : {capitalize(roleLabel)} role
                  </li>
                ))}
              </ul>
            </TableListItem>
          ) : null}
          {grantedPrivileges.length ? (
            <TableListItem label="Privileges granted">
              <ul>
                {grantedPrivileges.map(([privilege, { users }]) => (
                  <li key={privilege}>
                    {getPrivilegeDescription(
                      privileges,
                      privilege as PrivilegeLabel
                    )}
                    : {capitalize(roleLabel)} role ({users?.length || 0}{' '}
                    additional {pluralize('user', users?.length || 0)})
                  </li>
                ))}
              </ul>
            </TableListItem>
          ) : null}
        </TableList>
      </>
    ),
  });
};
