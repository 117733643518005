import { dispatchAction } from '@ardoq/rxbeach';
import { setCreationContextSelection as setCreationContextSelectionAction } from './actions';
import { EditorProperty } from 'appModelStateEdit/propertiesEditor/types';
import { isArrayOfArdoqId } from './typeValidators';

const setCreationContextSelection = (
  subdivisions: EditorProperty['value']
): void => {
  if (isArrayOfArdoqId(subdivisions)) {
    dispatchAction(
      setCreationContextSelectionAction({
        creationContextSelection: subdivisions,
      })
    );
  }
};

export const subdivisionCreationContextEditorCommands = {
  setCreationContextSelection,
};
