import { Multiselect } from '@ardoq/select';
import { ListEditorProps } from 'scopeData/editors/types';

export const SubdivisionCreationContextEditor = ({
  options,
  defaultValue,
  errorMessages,
  onValueChange,
  label,
}: ListEditorProps) => {
  const errorMessage = errorMessages ? (errorMessages[0] as string) : undefined;
  const hasError = errorMessages ? errorMessages.length > 0 : false;
  return (
    <Multiselect<string>
      label={label}
      options={options}
      defaultValue={defaultValue as string[]}
      errorMessage={errorMessage}
      hasError={hasError}
      onValueChange={onValueChange}
      isClearable={false}
    />
  );
};
