import { APIResourcePermissionAttributes, ArdoqId } from '@ardoq/api-types';

export enum ApiState {
  READY = 'ready',
  SUCCESS = 'success',
  LOADING_INIT = 'loading init',
  LOADING = 'loading',
  ERROR = 'error',
}

export type ResourcePermissionsState = {
  byId: Record<ArdoqId, APIResourcePermissionAttributes>;
  apiState: ApiState;
  resourceNames: string[];
};

export type ApiPutResourcePermissionPayload = {
  permissionsUpdates: APIResourcePermissionAttributes[];
  prevPermissionsByResource: Record<ArdoqId, APIResourcePermissionAttributes>;
};

export enum ResourcesPermissionEditorEvents {
  OPENED_OLD_RESOURCE_PERMISSIONS_EDITOR = 'opened old resource permissions editor',
  OPENED_NEW_RESOURCE_PERMISSIONS_EDITOR = 'opened new resource permissions editor',
  ADDED_NEW_ACTOR_TO_RESOURCE_PERMISSIONS = 'added new actor to resource permissions',
  SAVED_CHANGES = 'saved changes',
  CHANGED_RESOURCE_PERMISSIONS_FOR_AN_ACTOR = 'changed resource permissions for an actor',
  REVOKED_RESOURCE_PERMISSIONS_FOR_AN_ACTOR = 'revoked resource permissions for an actor',
  CLICKED_ON_CLOSE_NEW_RESOURCE_PERMISSIONS_EDITOR_BUTTON = 'clicked on close new resource permissions editor button',
  CONFIRMED_DISCARD_CHANGES = 'confirmed discard changes',
}
