import {
  PermissionContext,
  canDo,
  permissionsOperations,
} from '@ardoq/access-control';

const canCreateGraphFilter = (
  permissionContext: PermissionContext
): boolean => {
  if (!permissionsOperations.isLoadedPermissionContext(permissionContext)) {
    return false;
  }

  return canDo({
    permissionContext,
    action: 'organization/create_gremlin',
    resourceId: permissionsOperations.getOrgIdFromContext(permissionContext),
  });
};

export const graphSearchAccessControlHelpers = {
  canCreateGraphFilter,
};
