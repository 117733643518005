import { useState } from 'react';
import { modal } from '@ardoq/modal';
import { AlertDialog, ModalTemplate, alert } from '@ardoq/modal';
import { OpenEntityMergeParams } from './types';
import { EntityHistoryModal } from './EntityHistoryModal';
import { showToast } from '@ardoq/status-ui';
import { capitalize } from 'lodash';
import { updateEntityData } from './dataUtils';
import { logError } from '@ardoq/logging';
import { useHistoryData } from './useHistoryData';
import { useStream } from '@ardoq/rxbeach';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { map } from 'rxjs';
import { isArdoqError } from '@ardoq/common-helpers';

const DEFAULT_VERIONS_TO_FETCH_COUNT = 50;

type EntityHistoryModalProps = {
  onClose: () => void;
} & OpenEntityMergeParams;

function EntityHistoryModalConnected({
  entityId,
  entityType,
  onClose,
}: EntityHistoryModalProps) {
  const [versionsToFetchCount, setVersionsToFetchCount] = useState<number>(
    DEFAULT_VERIONS_TO_FETCH_COUNT
  );

  const users = useStream(orgUsers$.pipe(map(({ byId }) => byId)), {});

  const { isLoading, hasError, historyByVersion, enhancedScopeData } =
    useHistoryData({
      entityId,
      entityType,
      versionsToFetchCount,
    });

  if (isLoading) {
    return <ModalTemplate isLoading={isLoading} />;
  }

  if (hasError || !enhancedScopeData) {
    return (
      <AlertDialog
        title="Error"
        onClose={onClose}
        text={<>Error while loading entity history.</>}
      />
    );
  }

  return (
    <EntityHistoryModal
      entityId={entityId}
      entityType={entityType}
      onClose={onClose}
      onMergeAndSave={async entityAttributesPartial => {
        const updateResult = await updateEntityData(
          entityId,
          entityType,
          entityAttributesPartial,
          enhancedScopeData
        );
        if (isArdoqError(updateResult)) {
          logError(updateResult, 'Entity merge failed.');
          alert({ title: 'Error', errorMessage: 'Updating an entity failed.' });
          return;
        }
        showToast(`${capitalize(entityType)} updated`);
        onClose();
      }}
      enhancedScopeData={enhancedScopeData}
      users={users}
      historyByVersion={historyByVersion}
      versionsToFetchCount={versionsToFetchCount}
      setVersionsToFetchCount={setVersionsToFetchCount}
    />
  );
}

export const renderEntityHistoryModal = (payload: OpenEntityMergeParams) =>
  modal(resolve => (
    <EntityHistoryModalConnected onClose={() => resolve(false)} {...payload} />
  ));
