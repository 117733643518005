import { Select, SelectOption } from '@ardoq/select';
import { Stack } from '@ardoq/layout';
import { SchedulingPolicy, WeeklySchedulingPolicy } from '@ardoq/api-types';

const typeScheduleInterval: SelectOption<string>[] = [
  {
    label: 'Daily',
    value: 'nightly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
];

const weekDayScheduleInterval: SelectOption<string>[] = [
  {
    label: 'Sunday',
    value: 'sunday',
  },
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
];

type SchedulePolicySelectorProps = {
  title: string;
  schedulePolicy: SchedulingPolicy;
  onSchedulePolicyChange: (schedulingPolicy: SchedulingPolicy) => void;
};

export const SchedulePolicySelector = ({
  title,
  schedulePolicy,
  onSchedulePolicyChange,
}: SchedulePolicySelectorProps) => {
  const handleIntervalChange = (value: string | null) => {
    if (!value) {
      return;
    }

    const weekDay =
      schedulePolicy.type === 'weekly'
        ? schedulePolicy.weekDay
        : weekDayScheduleInterval[0].value;

    onSchedulePolicyChange({
      type: value,
      ...(value === 'weekly' ? { weekDay } : {}),
    } as SchedulingPolicy);
  };

  const handleWeekDayChange = (
    value: WeeklySchedulingPolicy['weekDay'] | null
  ) => {
    if (!value || schedulePolicy.type === 'nightly') {
      return;
    }

    onSchedulePolicyChange({ ...schedulePolicy, weekDay: value });
  };

  return (
    <Stack gap="small">
      <Select
        label={title}
        value={schedulePolicy.type}
        options={typeScheduleInterval}
        onValueChange={value => handleIntervalChange(value)}
      />
      {schedulePolicy.type === 'weekly' && (
        <Select
          label="Run every"
          defaultValue="sunday"
          value={schedulePolicy.weekDay}
          options={weekDayScheduleInterval}
          onValueChange={value =>
            handleWeekDayChange(value as WeeklySchedulingPolicy['weekDay'])
          }
        />
      )}
    </Stack>
  );
};
