import appModelStateEdit$ from 'appModelStateEdit/appModelStateEdit$';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { modifyComponentType } from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/actions';
import { beginCreateWorkflow } from 'appModelStateEdit/actions';
import { APIEntityType } from '@ardoq/api-types';
import { getDefaultType } from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/reducerUtils';
import { readRawValue } from '@ardoq/renderers';

const handleInitComponentType = routine(
  ofType(beginCreateWorkflow),
  extractPayload(),
  withLatestFrom(appModelStateEdit$),
  tap(([{ entityIDs, entityType }, { enhancedScopeData }]) => {
    if (entityType !== APIEntityType.COMPONENT) {
      return;
    }
    if (!enhancedScopeData || !entityIDs || !entityIDs.length) {
      return;
    }
    entityIDs.map(entityID => {
      const type = getDefaultType(
        entityID,
        readRawValue(
          APIEntityType.COMPONENT,
          entityID,
          'parent',
          enhancedScopeData
        ),
        enhancedScopeData
      );
      if (type) {
        dispatchAction(modifyComponentType(type.id));
      }
    });
  })
);

const routines = [handleInitComponentType];
export default routines;
