import { IntegrationInitialState } from 'integrations/common/initialState';
import {
  ConnectionFieldDefinition,
  UnifiedIntegrationId,
  UnifiedIntegrationParams,
} from './types';
import { buildInitialTermsState } from 'integrations/common/streams/integrationTermsDictionary/initialState';

// Commonly used fields for integrations that require access key and secret key
export const accessKeySecretKeyFields = (
  integrationName: string,
  accessKeyLabel: string,
  secretKeyLabel: string,
  standardDomain: string,
  urlProtocol: 'http://' | 'https://' = 'https://'
): ConnectionFieldDefinition[] => [
  {
    keyPath: ['baseUrl'],
    label: `${integrationName} base URL`,
    type: 'url',
    urlProtocol,
    standardDomain,
    required: true,
  },
  {
    keyPath: ['accessKey'],
    label: accessKeyLabel,
    type: 'text',
    required: true,
  },
  {
    keyPath: ['secretKey'],
    label: secretKeyLabel,
    type: 'password',
    required: true,
  },
];

export const initialStates: Record<
  UnifiedIntegrationId,
  IntegrationInitialState & UnifiedIntegrationParams
> = {
  'jira-software': {
    activeIntegration: {
      integrationId: 'jira-software',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Jira Software Cloud',
      integrationOverviewTitle: 'Jira Software Cloud integration',
      rightSidebarHeader: 'Having trouble with the Jira software integration?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/151332-jira-software-cloud-integration',
          text: 'How to import Jira Software Cloud data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Jira account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Jira',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: accessKeySecretKeyFields(
          'Jira Software',
          'Jira Software Email',
          'Jira Software API Token',
          '.atlassian.net'
        ),
      },
    },
    defaultAuthMethod: 'basic',
  },
  signavio: {
    activeIntegration: {
      integrationId: 'signavio',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'SAP Signavio Importer',
      integrationOverviewTitle: 'SAP Signavio integration',
      rightSidebarHeader: 'Having trouble with the SAP Signavio integration?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/174350-sap-signavio-integration',
          text: 'How to import SAP Signavio data',
        },
      ],
      connectYourAccountTitle:
        'Connect your SAP Signavio account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to SAP Signavio',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          ...accessKeySecretKeyFields(
            'SAP Signavio',
            'SAP Signavio username',
            'SAP Signavio password',
            '.signavio.com'
          ),
          {
            keyPath: ['providerParams', 'tenant-id'],
            label: 'Workspace ID (or Tenant ID)',
            type: 'text',
            required: true,
          },
        ],
      },
    },
    defaultAuthMethod: 'basic',
  },
  ardoq: {
    activeIntegration: {
      integrationId: 'ardoq',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Ardoq Importer',
      integrationOverviewTitle: 'Ardoq integration',
      rightSidebarHeader: 'Having trouble with the Ardoq integration?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/ardoq-integration', // TODO: Update this link
          text: 'How to import Ardoq data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Ardoq account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Ardoq',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          ...accessKeySecretKeyFields(
            'Ardoq',
            'Ardoq username',
            'Arodq password',
            '.ardoq.com'
          ),
        ],
      },
    },
    defaultAuthMethod: 'basic',
  },
  'ardoq-reference-data': {
    activeIntegration: {
      integrationId: 'ardoq-reference-data',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Ardoq Reference Data Importer',
      integrationOverviewTitle: 'Ardoq reference data',
      rightSidebarHeader: 'Having trouble with the Ardoq reference data?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/ardoq-integration', // TODO: Update this link
          text: 'How to import Ardoq reference data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Ardoq account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Ardoq',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          ...accessKeySecretKeyFields(
            'Ardoq',
            'Ardoq username',
            'Arodq password',
            '.ardoq.com'
          ),
        ],
      },
    },
    defaultAuthMethod: 'basic',
    areConfigurationActionsDisabled: false,
  },
  'ardoq-sample-data': {
    activeIntegration: {
      integrationId: 'ardoq-sample-data',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Ardoq Sample Data Importer',
      integrationOverviewTitle: 'Ardoq sample data',
      rightSidebarHeader: 'Having trouble with the Ardoq sample data?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/ardoq-integration', // TODO: Update this link
          text: 'How to import Ardoq sample data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Ardoq account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Ardoq',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          ...accessKeySecretKeyFields(
            'Ardoq',
            'Ardoq username',
            'Arodq password',
            '.ardoq.com'
          ),
        ],
      },
    },
    defaultAuthMethod: 'basic',
    areConfigurationActionsDisabled: false,
  },
  'sharepoint-files': {
    activeIntegration: {
      integrationId: 'sharepoint-files',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'SharePoint/OneDrive',
      integrationOverviewTitle: 'SharePoint/OneDrive Files',
      rightSidebarHeader:
        'Having trouble with the SharePoint/OneDrive Files importer?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/ardoq-integration', // TODO: Update this link
          text: 'How to import SharePoint/OneDrive files',
        },
      ],
      connectYourAccountTitle:
        'Connect your SharePoint/OneDrive account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to SharePoint/OneDrive',
    }),
    authMethodFields: {
      basic: {
        label: 'Basic',
        fieldDefinitions: [
          {
            keyPath: ['cloud'],
            label: 'Azure cloud.',
            type: 'hidden',
            value: 'azure-cloud',
            required: true,
          },
          {
            keyPath: ['providerParams', 'tenantId'],
            label: 'Tenant ID',
            type: 'text',
            required: true,
          },
          ...accessKeySecretKeyFields(
            'SharePoint/OneDrive Files',
            'Application ID',
            'Client secret',
            ''
          ),
        ],
      },
    },
    defaultAuthMethod: 'basic',
  },
  celonis: {
    activeIntegration: {
      integrationId: 'celonis',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Celonis Process Intelligence',
      integrationOverviewTitle: 'Celonis Process Intelligence integration',
      rightSidebarHeader: 'Having trouble with the Celonis integration?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/232045-celonis-process-intelligence-integration',
          text: 'How to import Celonis Process Intelligence data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Celonis Process Intelligence account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Celonis Process Intelligence',
    }),
    authMethodFields: {
      token: {
        label: 'Token',
        fieldDefinitions: [
          {
            keyPath: ['baseUrl'],
            label: 'Celonis base URL',
            type: 'url',
            urlProtocol: 'https://',
            standardDomain: '.celonis.cloud',
            required: true,
          },
          {
            keyPath: ['token'],
            label: 'Celonis AppKey',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['providerParams', 'kmIds'],
            label: 'Knowledge Models',
            type: 'text',
            required: true,
            helperText: 'Comma separated list of Celonis Knowledge Model IDs',
          },
        ],
      },
      'oauth-client-credentials': {
        label: 'OAuth 2.0',
        fieldDefinitions: [
          {
            keyPath: ['baseUrl'],
            label: 'Celonis base URL',
            type: 'url',
            urlProtocol: 'https://',
            standardDomain: '.celonis.cloud',
            required: true,
          },
          {
            keyPath: ['provider'],
            type: 'hidden',
            label: 'Provider',
            value: 'celonis',
            required: true,
          },
          {
            keyPath: ['providerParams', 'clientId'],
            label: 'ID',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['providerParams', 'clientSecret'],
            label: 'Secret',
            type: 'password',
            required: true,
          },
          {
            keyPath: ['providerParams', 'tokenType'],
            label: 'Token type',
            type: 'hidden',
            required: true,
            value: 'access-token',
          },
          {
            keyPath: ['providerParams', 'scope'],
            label: 'Scope',
            type: 'hidden',
            required: true,
            value: ['studio'],
          },
          {
            keyPath: ['providerParams', 'clientAuthType'],
            label: 'Client authentication type',
            type: 'hidden',
            required: true,
            value: 'basic',
          },
        ],
      },
    },
    defaultAuthMethod: 'token',
  },
  symbio: {
    activeIntegration: {
      integrationId: 'symbio',
      integrationMappingParams: {
        mapColumnsBy: 'sourceFieldName',
        mapTablesBy: 'id',
      },
    },
    termsDictionary: buildInitialTermsState({
      name: 'Celonis Process Management',
      integrationOverviewTitle: 'Celonis Process Management integration',
      rightSidebarHeader:
        'Having trouble with the Celonis Process Management integration?',
      rightSideBarFAQ: [
        {
          link: 'https://help.ardoq.com/en/articles/232042-celonis-process-management-integration',
          text: 'How to import Celonis Process Management data',
        },
      ],
      connectYourAccountTitle:
        'Connect your Celonis Process Management account to Ardoq to get started.',
      connectYourAccountBtn: 'Connect to Celonis Process Management',
    }),
    authMethodFields: {
      token: {
        label: 'Token',
        fieldDefinitions: [
          {
            keyPath: ['baseUrl'],
            label: 'Symbio base URL',
            type: 'url',
            urlProtocol: 'https://',
            standardDomain: '.symbioweb.com',
            required: true,
          },
          {
            keyPath: ['token'],
            label: 'Symbio API token',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['providerParams', 'collection'],
            label: 'Collection',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['providerParams', 'storage'],
            label: 'Storage',
            type: 'text',
            required: true,
          },
          {
            keyPath: ['providerParams', 'preferredDataCulture'],
            label: 'Preferred language',
            type: 'text',
            required: true,
          },
        ],
      },
    },
    defaultAuthMethod: 'token',
  },
};
