import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { logError } from '@ardoq/logging';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { fetchPrivileges } from 'privileges/actions';
import { updateCurrentUser } from 'streams/currentUser/actions';
import {
  fetchRoles,
  notifyFailedToFetchRoles,
  setRoles,
  updateRole,
} from './actions';
import privileges$ from 'privileges/privileges$';
import roles$ from './roles$';
import { confirmSavingRoleChanges } from './utils';
import { handleError, roleApi } from '@ardoq/api';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';

const handleFetchRoles = routine(
  ofType(fetchRoles),
  switchMap(roleApi.fetchAll),
  handleError(error => {
    dispatchAction(notifyFailedToFetchRoles());
    logError(error, 'Failed to fetch roles');
  }),
  tap(roles => dispatchAction(setRoles(roles)))
);

const handleUpdateRole = routine(
  ofType(updateRole),
  extractPayload(),
  withLatestFrom(privileges$, orgUsers$, roles$),
  switchMap(confirmSavingRoleChanges),
  filter(data => Boolean(data)),
  map(data => data!),
  switchMap(roleApi.update),
  handleError(error => logError(error, 'Failed to update role')),
  tap(() => {
    dispatchAction(fetchRoles());
    dispatchAction(fetchPrivileges());
    dispatchAction(updateCurrentUser());
  })
);

export default collectRoutines(handleFetchRoles, handleUpdateRole);
