import { useRef } from 'react';
import {
  PropertiesEditorStreamShape,
  PropertyGroup,
  Tags,
} from 'appModelStateEdit/propertiesEditor/types';
import { SidebarMenu, SidebarMenuFormSection } from '@ardoq/sidebar-menu';
import { Editor, TagEditor } from 'scopeData/editors/Editors';
import { PropertyValue } from 'aqTypes';
import {
  modifyProperty,
  modifyTags,
} from 'appModelStateEdit/propertiesEditor/actions';
import AddFieldToEntity from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/AddFieldToEntity';
import {
  addFieldByName,
  createFieldByLabel,
  removeField,
} from 'appModelStateEdit/propertiesEditor/addField/addFieldToEntity/actions';
import CalculatedFields from './calculatedFields/CalculatedFields';
import ComponentStylePreview from './componentStylePreview/ComponentStylePreview';
import ReferenceTypePreview from './referenceTypePreview/ReferenceTypePreview';
import { omit, kebabCase } from 'lodash';
import { useOnMount } from '@ardoq/hooks';
import { dispatchAction } from '@ardoq/rxbeach';
import { Stack } from '@ardoq/layout';
import { ErrorNotification } from '@ardoq/status-ui';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import {
  trackEntityTagsModified,
  trackFieldAddedToEntity,
  trackFieldCreatedAddedToEntity,
  trackSelectedPropertyChange,
} from './tracking';

type PropertiesEditorProps = {
  readonly onClose: VoidFunction;
  readonly onSave: (propertyGroups: PropertyGroup[], tags: Tags) => void;
  readonly onDelete?: VoidFunction;
  readonly trackingContext?: GetContentOptionsType;
};

export const PropertiesEditor = ({
  onClose,
  onSave,
  onDelete,
  addFieldAdminMessage,
  errorMessage,
  buttonLabel,
  headerTitle,
  isSaveButtonDisabled,
  lockedMessage,
  multiEditingMessage,
  originalProperties,
  propertyGroups,
  sidebarInstanceId,
  shouldShowAddField,
  shouldShowCalculatedFieldSettings,
  shouldShowComponentStylePreview,
  shouldShowDeleteButton,
  shouldShowReferenceTypePreview,
  shouldShowSaveButton,
  shouldShowTags,
  tags,
  trackingContext,
}: PropertiesEditorProps & PropertiesEditorStreamShape) => {
  const sidebarRef = useRef<HTMLFormElement>(null);
  useOnMount(() => {
    sidebarRef.current?.querySelector('input')?.focus();
  });

  const [firstPropertyGroup, ...otherPropertyGroups] = propertyGroups;
  if (!firstPropertyGroup || firstPropertyGroup.properties.length === 0) {
    return (
      <SidebarMenu
        ref={sidebarRef}
        headerTitle={headerTitle}
        onCloseClick={onClose}
      >
        <SidebarMenuFormSection
          titleDataTestId={`${kebabCase(headerTitle)}-sub-header`}
          title={multiEditingMessage}
          isCollapsible={false}
        >
          <>
            {errorMessage && (
              <ErrorNotification>{errorMessage}</ErrorNotification>
            )}
          </>
        </SidebarMenuFormSection>
      </SidebarMenu>
    );
  }
  return (
    <SidebarMenu
      ref={sidebarRef}
      headerTitle={headerTitle}
      onCloseClick={onClose}
      footerErrorMessage={errorMessage}
      {...(shouldShowSaveButton
        ? {
            primaryButtonLabel: buttonLabel,
            onPrimaryButtonClick: () => onSave(propertyGroups, tags),
            primaryButtonClickId: 'sidebar-action-save-create',
            isPrimaryButtonDisabled: isSaveButtonDisabled,
          }
        : {})}
      {...(shouldShowDeleteButton
        ? {
            dangerButtonLabel: 'Delete',
            onDangerButtonClick: onDelete,
            dangerButtonClickId: 'sidebar-action-delete',
          }
        : {})}
    >
      {multiEditingMessage && (
        <SidebarMenuFormSection
          titleDataTestId={`${kebabCase(headerTitle)}-sub-header`}
          title={multiEditingMessage}
          isCollapsible={false}
        />
      )}
      {lockedMessage && (
        <SidebarMenuFormSection title={lockedMessage} isCollapsible={false} />
      )}
      <SidebarMenuFormSection
        titleDataTestId={`${kebabCase(
          firstPropertyGroup.title
        )}-section-header`}
        title={firstPropertyGroup.title}
        isCollapsible={false}
      >
        <Stack gap="medium">
          {firstPropertyGroup.properties.map((property, index) => (
            <Editor
              key={`${sidebarInstanceId}${property.name}${index}`}
              {...omit(property, 'name')}
              onValueChange={(newValue: PropertyValue) => {
                dispatchAction(
                  modifyProperty({
                    newValue,
                    originalProperties,
                    propertyType: property.type,
                    propertyName: property.name,
                  })
                );

                // This is mainly, to avoid tracking the first emit of the description property.
                // Since the RichEditor emits a change event when the editor is mounted
                if (property.value === newValue) {
                  return;
                }
                trackSelectedPropertyChange(false, property, trackingContext);
              }}
              dataClickId={`editor-input-${property.name}`}
            />
          ))}
        </Stack>
      </SidebarMenuFormSection>
      {shouldShowTags && (
        <SidebarMenuFormSection isCollapsible={false}>
          <TagEditor
            isDisabled={tags.isDisabled}
            label="Tags"
            value={tags.value}
            options={tags.options}
            onValueChange={value => {
              dispatchAction(modifyTags(value as string[]));
              trackEntityTagsModified(trackingContext);
            }}
          />
        </SidebarMenuFormSection>
      )}
      {otherPropertyGroups
        .filter(({ properties }) => properties.length)
        .map((propertyGroup, groupIndex) => (
          <SidebarMenuFormSection
            key={`${sidebarInstanceId}group${groupIndex}`}
            title={propertyGroup.title}
            isCollapsible={false}
          >
            {propertyGroup.properties.map((property, index) => (
              <Editor
                key={`${sidebarInstanceId}${property.name}${index}`}
                {...omit(property, 'name')}
                onValueChange={(newValue: PropertyValue) => {
                  dispatchAction(
                    modifyProperty({
                      newValue,
                      originalProperties,
                      propertyType: property.type,
                      propertyName: property.name,
                    })
                  );
                  trackSelectedPropertyChange(true, property, trackingContext);
                }}
                dataClickId={`editor-input-${property.name}`}
                onRemove={() => dispatchAction(removeField(property.name))}
              />
            ))}
          </SidebarMenuFormSection>
        ))}
      {shouldShowAddField && (
        <SidebarMenuFormSection isCollapsible={false}>
          <AddFieldToEntity
            onChange={fieldName => {
              dispatchAction(addFieldByName(fieldName));
              trackFieldAddedToEntity(fieldName, trackingContext);
            }}
            onCreate={fieldLabel => {
              dispatchAction(createFieldByLabel(fieldLabel));
              trackFieldCreatedAddedToEntity(fieldLabel, trackingContext);
            }}
          />
        </SidebarMenuFormSection>
      )}
      {addFieldAdminMessage && (
        <SidebarMenuFormSection isCollapsible={false}>
          <div>{addFieldAdminMessage}</div>
        </SidebarMenuFormSection>
      )}
      {shouldShowCalculatedFieldSettings && (
        <SidebarMenuFormSection title="Calculated fields" isCollapsible={false}>
          <CalculatedFields />
        </SidebarMenuFormSection>
      )}
      {shouldShowComponentStylePreview && (
        <SidebarMenuFormSection
          isCollapsible={false}
          titleDataTestId="preview-section-header"
        >
          <ComponentStylePreview />
        </SidebarMenuFormSection>
      )}
      {shouldShowReferenceTypePreview && (
        <SidebarMenuFormSection title="Preview" isCollapsible={false}>
          <ReferenceTypePreview />
        </SidebarMenuFormSection>
      )}
    </SidebarMenu>
  );
};
