import { s4 } from '@ardoq/design-tokens';
import { POPOVER_Z_INDEX } from '@ardoq/popovers';
import { Select, SelectOption } from '@ardoq/select';
import styled from 'styled-components';
import { AutocompleteFieldSuggestion } from './types';

export const AutocompleteFieldPopover = ({
  options,
  onClose,
  onValueChange,
}: {
  options?: SelectOption<AutocompleteFieldSuggestion>[];
  onClose: () => void;
  onValueChange: (suggestion: AutocompleteFieldSuggestion | null) => void;
}) => (
  <Container>
    <Select
      autoFocus
      backspaceRemovesValue={false}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      menuIsOpen
      onMenuClose={onClose}
      onValueChange={onValueChange}
      options={options}
      placeholder="Select field..."
      tabSelectsValue={false}
    />
  </Container>
);

const Container = styled.div`
  position: absolute;
  z-index: ${POPOVER_Z_INDEX};
  padding-top: ${s4};
  width: 100%;
`;
