import { SUBDIVISIONS_STRINGS } from '@ardoq/subdivisions';
import styled from 'styled-components';
import { Box, Stack } from '@ardoq/layout';
import { text1Bold, text2 } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';
import { Tag, TagGroup } from '@ardoq/status-ui';
import { SelectOption } from '@ardoq/select';

const Header = styled(Box)`
  ${text1Bold};
`;

const SecondaryText = styled(Box)`
  ${text2}
  color: ${colors.textSubtle};
`;

export type SubdivisionMembershipsViewProps = {
  label: string;
  options: SelectOption<string>[];
};

export const SubdivisionMembershipsView = ({
  label,
  options,
}: SubdivisionMembershipsViewProps) => {
  return (
    <Stack gap="small">
      <Header>{label}</Header>
      <SecondaryText>
        {SUBDIVISIONS_STRINGS.CREATION_CONTEXT_EDITOR.INEHERITANCE_WARNING}
      </SecondaryText>
      <TagGroup>
        {options.map(({ label, leftIcon }, index) => (
          <Tag key={(label || '') + index} label={label} leftIcon={leftIcon} />
        ))}
      </TagGroup>
    </Stack>
  );
};
