import {
  UnifiedSchedule,
  ServiceNowSchedule,
  CloudSchedule,
  MicrosoftEntraIdSchedule,
} from '@ardoq/api-types/integrations';
import { IntegrationSchedule, ScheduleType } from '@ardoq/api-types';

type JobWithTabularMapping =
  | UnifiedSchedule['jobOptions']
  | ServiceNowSchedule['jobOptions']
  | CloudSchedule['jobOptions']
  | MicrosoftEntraIdSchedule['jobOptions'];

const JOBS_WITH_TABULAR_MAPPINGS = [
  ScheduleType.ARDOQ_IMPORT,
  ScheduleType.ARDOQ_REFERENCE_DATA_IMPORT,
  ScheduleType.ARDOQ_SAMPLE_DATA_IMPORT,
  ScheduleType.SHAREPOINT_FILES_IMPORT,
  ScheduleType.CELONIS_IMPORT,
  ScheduleType.SYMBIO_IMPORT,
  ScheduleType.CLOUD_IMPORT,
  ScheduleType.JIRA_IMPORT,
  ScheduleType.SIGNAVIO_IMPORT,
  ScheduleType.SERVICENOW_IMPORT,
  ScheduleType.SERVICENOW_EXPORT,
  ScheduleType.MICROSOFT_ENTRA_ID_IMPORT,
];

export function isJobWithTabularMapping(
  jobOptions: IntegrationSchedule['jobOptions']
): jobOptions is JobWithTabularMapping {
  return JOBS_WITH_TABULAR_MAPPINGS.includes(jobOptions.type);
}
