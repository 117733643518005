import { MathTransformation } from '@ardoq/api-types';
import { TextArea, useOptimistic, useTextAreaSelection } from '@ardoq/forms';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { AutocompleteFieldPopover } from './AutocompleteFieldPopover';
import { EditorSectionProps } from './TransformationsEditor';
import { AutocompleteFieldSuggestion } from './types';
import { getReferencedFieldsFromExpression } from './utils';

export const MathDetailsSection = ({
  value: externalTransformation,
  onValueChange: onExternalTransformationChange,
  reactiveFieldOptions: options,
}: EditorSectionProps<MathTransformation>) => {
  const expressionRef = useRef<HTMLTextAreaElement | null>(null);
  const setCaretPosition = useTextAreaSelection(expressionRef);
  const lastKeyDownRef = useRef<string>();
  const [isSuggesting, setIsSuggesting] = useState(false);

  // ARD-24805: React doesn't recognize that changes to the expression are
  // due to the user typing, because of the `debounceTime` in
  // `propertiesEditor$`. We fix this with optimistic updates.
  const [transformation, onTransformationChange] = useOptimistic(
    externalTransformation,
    onExternalTransformationChange
  );

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    lastKeyDownRef.current = event.key;
  };

  const handleExpressionChange = (expression: string) => {
    onTransformationChange({
      ...transformation,
      config: {
        ...transformation.config,
        additionalFields: getReferencedFieldsFromExpression(expression),
        expression: expression,
      },
    });

    if (lastKeyDownRef.current === '@') {
      setIsSuggesting(true);
    }
  };

  const handleAcceptSuggestion = (
    suggestion: AutocompleteFieldSuggestion | null
  ) => {
    if (suggestion) {
      const insertionIndex = expressionRef.current?.selectionStart ?? -1;
      const expression = transformation.config.expression;
      const updatedExpression =
        expression.slice(0, insertionIndex) +
        suggestion.name +
        expression.slice(insertionIndex);

      handleExpressionChange(updatedExpression);
      setCaretPosition(insertionIndex + suggestion.name.length);
    }

    expressionRef.current?.focus();
  };

  return (
    <ExpressionInputWrapper>
      <TextArea
        ref={expressionRef}
        label="Expression"
        rows={1}
        placeholder="@cost * @amount"
        helperText="Use @ to reference fields"
        value={transformation.config.expression}
        onKeyDown={handleInputKeyDown}
        onValueChange={handleExpressionChange}
      />
      {isSuggesting && (
        <AutocompleteFieldPopover
          options={options}
          onClose={() => setIsSuggesting(false)}
          onValueChange={handleAcceptSuggestion}
        />
      )}
    </ExpressionInputWrapper>
  );
};

const ExpressionInputWrapper = styled.div`
  position: relative;
`;
