import { persistentReducedStream } from '@ardoq/rxbeach';
import { reducers } from './reducers';
import { getDefaultState } from './utils';

const surveyNotUpdatedSinceCondition$ = persistentReducedStream(
  'surveyNotUpdatedSinceCondition$',
  getDefaultState(),
  reducers
);

export default surveyNotUpdatedSinceCondition$;
