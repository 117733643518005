import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from 'globalFields/reducers';

const globalFields$ = persistentReducedStream(
  'globalFields',
  defaultState,
  reducers
);

export default globalFields$;
