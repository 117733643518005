export enum ModalStep {
  CREATE_SCENARIO,
  ADD_TO_SCENARIO,
  SUCCESS,

  // progress steps
  PRELOADING_COMPONENTS,
  PRELOADING_REFERENCES,
  CREATING_SCENARIO,
  UPDATING_SCENARIO,

  // error steps
  PRELOADING_FAILED,
  CREATING_SCENARIO_FAILED,
  UPDATING_SCENARIO_FAILED,
  COMPONENTS_NUMBER_EXCEEDED,
}
