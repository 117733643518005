import { APIOrganizationAttributes } from '@ardoq/api-types';
import { reducedStream, reducer, toPersistentStream } from '@ardoq/rxbeach';
import { map, shareReplay } from 'rxjs';
import {
  emptyCollectionStream,
  toCollectionStream,
} from 'streams/utils/streamUtils';
import {
  fetchCurrentOrganizationSucceeded,
  updateOrganizationSucceeded,
} from './actions';
import { OrganizationListState } from './types';

const updateOrganization = (
  state: OrganizationListState,
  updatedOrganization: APIOrganizationAttributes
) => {
  if (
    state.some(organization => organization._id === updatedOrganization._id)
  ) {
    return state.map(organization =>
      organization._id === updatedOrganization._id
        ? updatedOrganization
        : organization
    );
  }

  return [...state, updatedOrganization];
};

const organizationList$ = reducedStream<OrganizationListState>(
  'organizationList$',
  [],
  [
    reducer(fetchCurrentOrganizationSucceeded, updateOrganization),
    reducer(updateOrganizationSucceeded, updateOrganization),
  ]
);

export const organizationsState$ = organizationList$.pipe(
  map(toCollectionStream),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default toPersistentStream(
  'organizationsState$',
  organizationsState$,
  emptyCollectionStream()
);
