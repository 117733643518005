import {
  FieldConflict,
  MergeState,
  Path,
} from 'components/DiffMergeTable/types';
import { Branch } from 'components/DiffMergeTable/Branch';
import { APIEntityType, ArdoqId, ScopeDataCollection } from '@ardoq/api-types';
import { EnhancedDiffScopeData } from 'components/DiffMergeTable/enhanceDiffContextData';
import { filterUnique } from 'utils/collectionUtil';
import { getStatusFromChildren } from 'components/DiffMergeTable/getStatusFromChildren';
import { collectionToEntityType, hasMergePermission } from '../utils';
import {
  getEntitiesFromEnhancedDiffContext,
  getHeader,
  getIndexer,
  getPropertyRow,
  getSubHeader,
} from './toDataSourceHelpers';
import { getEntityById } from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';
export const toDataSourceTagsMerge = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  basePath: Path
) => {
  const [collection, verb] = basePath;
  const tags = getEntitiesFromEnhancedDiffContext(
    enhancedDiffContextData,
    collection,
    verb
  ) as ArdoqId[];
  if (!tags) return [];

  const entityType = collectionToEntityType[collection];
  const getIndex = getIndexer();

  const rootIndex = getIndex();
  const rootChildren: number[] = [];
  const tagRows = tags.flatMap(tagId => {
    const taggedComponentIds = getTaggedEntityIds(
      tagId,
      APIEntityType.COMPONENT,
      enhancedDiffContextData
    );
    const taggedReferenceIds = getTaggedEntityIds(
      tagId,
      APIEntityType.REFERENCE,
      enhancedDiffContextData
    );
    if (!(taggedComponentIds.length || taggedReferenceIds.length)) {
      return [];
    }

    const tagNameIndex = getIndex();
    rootChildren.push(tagNameIndex);
    const entityRowIndexes: number[] = [];

    const hasWritePermission = hasMergePermission(
      tagId,
      entityType,
      enhancedDiffContextData
    );

    const componentRows = taggedComponentIds.map((componentId: ArdoqId) => {
      const index = getIndex();
      entityRowIndexes.push(index);

      return getPropertyRow({
        entityId: componentId,
        entityType: APIEntityType.COMPONENT,
        fieldName: 'name',
        enhancedDiffContextData,
        index,
        basePath,
        parentIndex: tagNameIndex,
        status: getTagMergePreselectionStatus(
          componentId,
          tagId,
          enhancedDiffContextData,
          ScopeDataCollection.COMPONENTS
        ),
        fieldConflict: FieldConflict.NONE,
        isDisabled:
          !enhancedDiffContextData[Branch.TARGET].componentsById[componentId],
        verb,
        hasWritePermission,
      });
    });

    const referenceRows = taggedReferenceIds.map((referenceId: ArdoqId) => {
      const index = getIndex();
      entityRowIndexes.push(index);

      return getPropertyRow({
        entityId: referenceId,
        entityType: APIEntityType.REFERENCE,
        fieldName: 'name',
        enhancedDiffContextData,
        index,
        basePath,
        parentIndex: tagNameIndex,
        status: getTagMergePreselectionStatus(
          referenceId,
          tagId,
          enhancedDiffContextData,
          ScopeDataCollection.REFERENCES
        ),
        fieldConflict: FieldConflict.NONE,
        isDisabled:
          !enhancedDiffContextData[Branch.TARGET].referencesById[referenceId],
        verb,
        hasWritePermission,
      });
    });

    const entityRows = [...componentRows, ...referenceRows];
    return [
      getSubHeader({
        entityType,
        entityId: tagId,
        basePath,
        enhancedDiffContextData: enhancedDiffContextData,
        parent: rootIndex,
        index: tagNameIndex,
        children: entityRowIndexes,
        status: getStatusFromChildren(entityRows, MergeState.SOURCE),
        verb,
        isDisabled: entityRows.every(({ isDisabled }) => isDisabled),
        hasWritePermission,
      }),
      ...entityRows,
    ];
  });

  return tagRows.length
    ? [
        getHeader({
          entityType,
          basePath,
          enhancedDiffContextData: enhancedDiffContextData,
          rootIndex,
          rootChildren,
          status: getStatusFromChildren(tagRows, MergeState.SOURCE),
          isDisabled: tagRows.every(({ isDisabled }) => isDisabled),
        }),
        ...tagRows,
      ]
    : [];
};

const getTaggedEntityIds = (
  tagId: ArdoqId,
  entityType: APIEntityType,
  enhancedDiffContextData: EnhancedDiffScopeData
) => {
  const sourceEntityIds = getTagEntitiesFromBranch(
    enhancedDiffContextData,
    tagId,
    Branch.SOURCE,
    entityType === APIEntityType.COMPONENT
      ? ScopeDataCollection.COMPONENTS
      : ScopeDataCollection.REFERENCES
  );
  const targetEntityIds = getTagEntitiesFromBranch(
    enhancedDiffContextData,
    tagId,
    Branch.TARGET,
    entityType === APIEntityType.COMPONENT
      ? ScopeDataCollection.COMPONENTS
      : ScopeDataCollection.REFERENCES
  );
  return [...sourceEntityIds, ...targetEntityIds].filter(
    getTaggedEntityFilters(tagId, entityType, enhancedDiffContextData)
  );
};

const getTagEntitiesFromBranch = (
  enhancedDiffContextData: EnhancedDiffScopeData,
  tagId: ArdoqId,
  branch: Branch,
  scopeDataCollection:
    | ScopeDataCollection.COMPONENTS
    | ScopeDataCollection.REFERENCES
) =>
  enhancedDiffContextData[branch].tagsById[tagId]?.[scopeDataCollection] ?? [];

const isEntityTaggedInBranch = (
  entityId: ArdoqId,
  tagId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  scopeDataCollection:
    | ScopeDataCollection.COMPONENTS
    | ScopeDataCollection.REFERENCES
) =>
  Boolean(
    enhancedScopeData.tagsById[tagId]?.[scopeDataCollection].includes(entityId)
  );

const getTaggedEntityFilters =
  (
    tagId: ArdoqId,
    entityType: APIEntityType,
    enhancedDiffContextData: EnhancedDiffScopeData
  ) =>
  (id: ArdoqId, index: number, all: ArdoqId[]) =>
    // Filters duplicates and entity ids that don't exist on both source and target and all entities that are tagged on both source and target
    filterUnique(id, index, all) &&
    getEntityById(entityType, id, enhancedDiffContextData[Branch.SOURCE]) &&
    getEntityById(entityType, id, enhancedDiffContextData[Branch.TARGET]) &&
    !(
      isEntityTaggedInBranch(
        id,
        tagId,
        enhancedDiffContextData[Branch.SOURCE],
        entityType === APIEntityType.COMPONENT
          ? ScopeDataCollection.COMPONENTS
          : ScopeDataCollection.REFERENCES
      ) &&
      isEntityTaggedInBranch(
        id,
        tagId,
        enhancedDiffContextData[Branch.TARGET],
        entityType === APIEntityType.COMPONENT
          ? ScopeDataCollection.COMPONENTS
          : ScopeDataCollection.REFERENCES
      )
    );

const getTagMergePreselectionStatus = (
  entityId: ArdoqId,
  tagId: ArdoqId,
  enhancedDiffContextData: EnhancedDiffScopeData,
  scopeDataCollection:
    | ScopeDataCollection.COMPONENTS
    | ScopeDataCollection.REFERENCES
) => {
  const isEntityTaggedInSource = isEntityTaggedInBranch(
    entityId,
    tagId,
    enhancedDiffContextData[Branch.SOURCE],
    scopeDataCollection
  );

  const isEntityTaggedInTarget = isEntityTaggedInBranch(
    entityId,
    tagId,
    enhancedDiffContextData[Branch.TARGET],
    scopeDataCollection
  );

  const isEntityTaggedInBranchOff = isEntityTaggedInBranch(
    entityId,
    tagId,
    enhancedDiffContextData[Branch.BRANCH_OFF],
    scopeDataCollection
  );

  if (
    (isEntityTaggedInSource && isEntityTaggedInTarget) ||
    (!isEntityTaggedInBranchOff &&
      (isEntityTaggedInTarget || isEntityTaggedInSource))
  ) {
    return MergeState.SOURCE;
  }
  return MergeState.NONE;
};
