import {
  LocallyDerivedTransformation,
  LocallyDerivedTransformationOperation,
} from '@ardoq/api-types';
import { Field } from '@ardoq/forms';
import { FieldType } from '@ardoq/renderers';
import { Select, SelectOption } from '@ardoq/select';
import { EditorSectionProps } from './TransformationsEditor';
import {
  concatPreset,
  dictionaryLookupPreset,
  mathPreset,
  Preset,
  transformationPresets,
} from './transformationsPresets';

export const OperationSection = ({
  type,
  value: transformation,
  onValueChange: onTransformationChange,
}: EditorSectionProps<LocallyDerivedTransformation | undefined>) => {
  const handleOperationChange = (
    operation: LocallyDerivedTransformationOperation | null
  ) => {
    const preset = transformationPresets.find(
      preset => preset.transformation.operation === operation
    );

    onTransformationChange(preset?.transformation);
  };

  return (
    <Field label="Operation">
      <Select
        placeholder="Select operation"
        options={getPresetsForType(type).map(toOperationOption)}
        value={transformation?.operation}
        onValueChange={handleOperationChange}
      />
    </Field>
  );
};

const getPresetsForType = (type: FieldType): Preset[] => {
  switch (type) {
    case FieldType.TRANSFORM_NUMBER:
      return [dictionaryLookupPreset, mathPreset];
    case FieldType.TRANSFORM_TEXT:
      return [dictionaryLookupPreset, concatPreset];
    case FieldType.TRANSFORM_LIST:
      return [dictionaryLookupPreset];
    default:
      return [];
  }
};

const toOperationOption = (
  preset: Preset
): SelectOption<LocallyDerivedTransformationOperation> => ({
  label: preset.name,
  description: preset.description,
  leftIcon: { name: preset.icon },
  value: preset.transformation.operation,
});
