import filterConditionsSection$ from './filterConditionsSection/filterConditionsSection$';
import surveyCompletenessConditions$ from './surveyCompletenessConditions/surveyCompletenessConditions$';
import surveyNotUpdatedSinceCondition$ from './surveyNotUpdatedSinceCondition/surveyNotUpdatedSinceCondition$';
import advancedSearchCondition$ from './advancedSearchCondition/advancedSearchCondition$';
import advancedSearchConditionByReference$ from './advancedSearchConditionByReference/advancedSearchConditionByReference$';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  BroadcastFilterConditionType,
  SurveyCompleteFilterCondition,
  SurveyIncompleteFilterCondition,
} from '@ardoq/api-types';

const persistableSurveyCompleteCondition$ = surveyCompletenessConditions$.pipe(
  map(
    ({ hasSurveyCompleteCondition }): SurveyCompleteFilterCondition | null => {
      if (!hasSurveyCompleteCondition) return null;
      return { conditionType: BroadcastFilterConditionType.SURVEY_COMPLETE };
    }
  )
);

const persistableSurveyIncompleteCondition$ =
  surveyCompletenessConditions$.pipe(
    map(
      ({
        hasSurveyIncompleteCondition,
      }): SurveyIncompleteFilterCondition | null => {
        if (!hasSurveyIncompleteCondition) return null;
        return {
          conditionType: BroadcastFilterConditionType.SURVEY_INCOMPLETE,
        };
      }
    )
  );

const persistableSurveyNotUpdatedSinceCondition$ =
  surveyNotUpdatedSinceCondition$.pipe(
    map(({ isEnabled, surveyNotUpdatedSinceCondition }) => {
      if (!isEnabled) return null;
      return surveyNotUpdatedSinceCondition;
    })
  );

const persistableAdvancedSearchCondition$ = advancedSearchCondition$.pipe(
  map(({ isEnabled, isChecked, advancedSearchCondition }) => {
    if (!isEnabled || !isChecked) return null;
    return advancedSearchCondition;
  })
);

const persistableAdvancedSearchConditionByReference$ =
  advancedSearchConditionByReference$.pipe(
    map(({ isEnabled, isChecked, advancedSearchCondition }) => {
      if (!isEnabled || !isChecked) return null;
      return advancedSearchCondition;
    })
  );

const filterConditions$ = combineLatest([
  filterConditionsSection$,
  persistableSurveyCompleteCondition$,
  persistableSurveyIncompleteCondition$,
  persistableSurveyNotUpdatedSinceCondition$,
  persistableAdvancedSearchCondition$,
  persistableAdvancedSearchConditionByReference$,
]).pipe(
  map(([{ isExpanded }, ...filterConditions]) => {
    if (!isExpanded) return [];
    return filterConditions.filter(ExcludeFalsy);
  })
);

export default filterConditions$;
