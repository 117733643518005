import { action$, reduceState } from '@ardoq/rxbeach';
import { ResourcePermissionsState } from './types';
import { defaultState, resourcePermissionReducers } from './reducer';

const resourcePermissions$ = action$.pipe(
  reduceState<ResourcePermissionsState>(
    'resourcePermissions',
    defaultState,
    resourcePermissionReducers
  )
);

export default resourcePermissions$;
