import {
  routine,
  collectRoutines,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { notifyWorkspaceOpened } from './ContextActions';
import { tap } from 'rxjs/operators';
import { trackOpenedWorkspace } from 'tracking/events/core';

const trackWorkspaceOpened = routine(
  ofType(notifyWorkspaceOpened),
  extractPayload(),
  tap(({ id, useCase, trackingLocation }) => {
    if (!trackingLocation) {
      return;
    }

    trackOpenedWorkspace({
      resourceId: id,
      useCase,
      from: trackingLocation,
    });
  })
);

export default collectRoutines(trackWorkspaceOpened);
