import { VersionedEntity } from '@ardoq/api-types';

export enum Action {
  CREATE = 'create',
  RETRY = 'retry',
  UPDATE = 'update',
  CONFLICT = 'conflict',
  IGNORE = 'ignore',
  FUTURISTIC = 'futuristic',
}
/**
 * Determine course of action when a create event is received
 */
export function getCreateAction({
  localVersion,
  isPersisting,
}: {
  localVersion: number | undefined;
  isPersisting: boolean;
}): Action.CREATE | Action.RETRY | Action.IGNORE {
  // Check if the "new" resource already exist locally and can be ignored

  if (localVersion) {
    return Action.IGNORE;
  }

  // Ensure that we are not in the middle of persisting a new resource to the
  // back-end, which can happen if the web socket event arrives before the api
  // response.
  // If we have an unsaved resource of that type, we still do not know if this
  // is _that_ resource, or a resource created by someone else. When creating
  // locally we do not have the final id, so we cannot identify it with
  // certainty, therefore we will just delay evaluation until our pending
  // persist-calls are resolved.
  if (isPersisting) {
    return Action.RETRY;
  }

  // At this point we know this client is not the author of the create event, so
  // this means we need to add it to our global data stores.
  // NOTE: We generally do not add models to a collection until we receive the
  // api response, so in this `createResource` call there is an assumption that
  // if a resource is created twice it will be deduplicated.
  return Action.CREATE;
}

/**
 * Determine course of action when receiving a update event
 */
export function getUpdateAction(
  resource: VersionedEntity,
  {
    localVersion,
    isOwnChange,
    hasUnsavedLocalResource,
  }: {
    localVersion: number | undefined;
    isOwnChange: boolean;
    hasUnsavedLocalResource: boolean;
  }
):
  | Action.CREATE
  | Action.UPDATE
  | Action.IGNORE
  | Action.CONFLICT
  | Action.FUTURISTIC {
  // Check that the resource exists, if it does not exist we create it.
  // It is relatively common that these resources do not exist locally due to
  // users closing their computer, leaving them with a long running session with
  // outdated resources.
  if (!localVersion) {
    return Action.CREATE;
  }

  // Check if the resource exists locally with a "future version", if yes
  // we either have a bug in our code or we are dealing with a time traveler,
  // either way this is considered an error.
  // As long as the version is not in the future, we want to update the resource.
  // - Local version is the same as update:
  //   calculated fields updates do not increase version number
  // - Local verison is behind the update:
  //   can be multiple versions behind because sessions may fall out of sync
  if (localVersion > resource._version) {
    return Action.FUTURISTIC;
  }

  // Ensure we don't have an unsaved resource matching the id because the
  // websocket system is not responsible for updating collections in this case.
  // In other words: We only update the local resource if we know we have not
  // made an update API request for the same resource or are currently making
  // edits to this resource.
  if (!hasUnsavedLocalResource) {
    return Action.UPDATE;
  }

  // At this point we know the user is editing the resource we received over
  // the websocket in THIS instance of Ardoq, and there are three options:
  // - They received someone else's conflicting update
  // - They received their own update (network race condition)
  // - They are editing the same resource at the same time to test our conflict
  //   resolution.
  // merge conflict, local version is dirty and someone else has made changes.
  if (isOwnChange) {
    // Most likely we received our own changes, and we expect it to be handled
    // at the call site. There are two cases that can still happen:
    // - The user is editing the same resource on two different computers to
    //   test our conflict resolution. If that's what brought you here, I'm
    //   sorry to disappoint
    // - The user has a script running somewhere making changes on their behalf
    //   that conflict with what they are doing themselves.
    // In both of these cases we're currently going to let the resource version
    // go out of sync and force the API to notify the user.
    return Action.IGNORE;
  }

  // Someone else has made changes to the same resource the user is editing
  return Action.CONFLICT;
}
