import { TextInput } from '@ardoq/forms';
import { useEffect, useState } from 'react';

type EditableCellProps = {
  initValue: string;
  onClear: VoidFunction;
  onSubmit: VoidFunction;
  onValueChange: (value: string) => void;
};

export const EditableCell = ({
  initValue,
  onClear,
  onSubmit,
  onValueChange,
}: EditableCellProps) => {
  const [editingName, setEditingName] = useState(initValue);

  useEffect(() => {
    onValueChange(editingName);
  }, [editingName, onValueChange]);

  const handleOnSubmit = () => {
    onSubmit();
    onClear();
  };
  return (
    <TextInput
      autoFocus
      value={editingName}
      onKeyDown={event => {
        switch (event.key) {
          case 'Esc':
          case 'Escape':
            onClear();
            break;
          case 'Enter':
            handleOnSubmit();
        }
      }}
      onValueChange={setEditingName}
      onBlur={handleOnSubmit}
    />
  );
};
