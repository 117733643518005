import { useEffect, useRef, useState } from 'react';
import SummaryBlock from './SummaryBlock';
import { ModalStep } from './types';
import { ArdoqId } from '@ardoq/api-types';
import { logError } from '@ardoq/logging';
import { scenarioApi } from '@ardoq/api';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '@ardoq/button';
import { ModalBody, ModalFooter, ModalHeader, ModalLayout } from '@ardoq/modal';
import { TextInput } from '@ardoq/forms';
import { isArdoqError } from '@ardoq/common-helpers';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';

type UseReferenceIdsProps = {
  setStep: (step: ModalStep) => void;
  componentIds: ArdoqId[];
  loadedGraph?: LoadedGraphWithViewpointMode;
};

const useLoadReferenceIds = ({
  componentIds,
  setStep,
  loadedGraph,
}: UseReferenceIdsProps) => {
  const [referenceIds, setReferenceIds] = useState<ArdoqId[]>([]);
  useEffect(() => {
    (async () => {
      if (loadedGraph && loadedGraph.isViewpointMode) {
        setReferenceIds(loadedGraph.scopeReferenceIds);
        setStep(ModalStep.CREATE_SCENARIO);
        return;
      }

      setStep(ModalStep.PRELOADING_REFERENCES);
      const result = await scenarioApi.getScenarioReferences(componentIds);
      if (isArdoqError(result)) {
        logError(result);
        setStep(ModalStep.PRELOADING_FAILED);
        return;
      }
      const { references } = result;
      setReferenceIds(references.map(({ _id }) => _id));
      setStep(ModalStep.CREATE_SCENARIO);
    })();
  }, [componentIds, setStep, loadedGraph]);
  return { referenceIds };
};

type CreateScenarioStepProps = {
  step: ModalStep;
  setStep: (step: ModalStep) => void;
  componentIds: ArdoqId[];
  onCancel: () => void;
  onConfirm: (name: string, referenceIds: ArdoqId[]) => void;
  loadedGraph?: LoadedGraphWithViewpointMode;
};

const CreateScenarioStep = ({
  componentIds,
  loadedGraph,
  onCancel,
  onConfirm,
  setStep,
  step,
}: CreateScenarioStepProps) => {
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [name, setName] = useState('');
  const { referenceIds } = useLoadReferenceIds({
    componentIds,
    setStep,
    loadedGraph,
  });

  if (step !== ModalStep.CREATE_SCENARIO) return null;

  return (
    <>
      <ModalHeader title="Create scenario" onCloseButtonClick={onCancel} />
      <ModalBody>
        <ModalLayout>
          <TextInput
            autoFocus
            value={name}
            onValueChange={setName}
            label="Scenario name"
            placeholder="Add scenario name here"
            data-click-id="add-scenario-name-here"
            onKeyDown={({ key }) => {
              if (name && key === 'Enter') {
                onConfirm(name, referenceIds);
              }
            }}
          />
          <SummaryBlock
            referencesCount={referenceIds.length}
            componentsCount={componentIds.length}
          />
        </ModalLayout>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <SecondaryButton ref={cancelButtonRef} onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            isDisabled={name === ''}
            ref={confirmButtonRef}
            onClick={() => onConfirm(name, referenceIds)}
            data-click-id="create-scenario-confirm-button"
          >
            Create scenario
          </PrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default CreateScenarioStep;
