import { ArdoqId, AssetType, ContentItem } from '@ardoq/api-types';
import { mapValues } from 'lodash';
import { logError } from '@ardoq/logging';

export type FolderPartial = { _id: ArdoqId; content: ContentItem[] };
type PseudoFolderWithSubfolderIds = FolderPartial & { subfolderIds: string[] };

const findContentRecursively = (
  folder: PseudoFolderWithSubfolderIds,
  allFoldersById: { [_id: string]: PseudoFolderWithSubfolderIds }
): ContentItem[] => {
  const childrenFolderContent = folder.subfolderIds.map(_id => {
    const subfolder = allFoldersById[_id];
    if (!subfolder) {
      logError(Error('Subfolder not found'), null, {
        subfolderId: _id,
        allFolderIds: Object.keys(allFoldersById),
      });
      return [];
    }
    return findContentRecursively(subfolder, allFoldersById);
  });
  return [...folder.content, ...childrenFolderContent].flat();
};

export const findNestedContent = (folders: FolderPartial[]) => {
  const folderSet = new Set(folders.map(({ _id }) => _id));
  const foldersById = Object.fromEntries(
    folders.map(folder => [
      folder._id,
      {
        ...folder,
        subfolderIds: folder.content
          .filter(
            // The folder content is maintained on the BE. When deleting a
            // subfolder not all ancestor folders are instantly updated
            // accordingly, so it's not sure if a subfolders still exist
            // at this moment.
            // At the latest on the next reload it will be ok though.
            ({ type, _id }) => type === AssetType.FOLDER && folderSet.has(_id)
          )
          .map(({ _id }) => _id),
      },
    ])
  );

  return mapValues(foldersById, folder =>
    findContentRecursively(folder, foldersById)
  );
};
