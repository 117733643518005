import { colors, r8, s8 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { Header4, Link } from '@ardoq/typography';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import {
  ITPediaSchedule,
  IntegrationWorkspace,
} from '@ardoq/api-types/integrations';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { FlexBox, Stack } from '@ardoq/layout';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { useMemo } from 'react';

type ExpandedRowParams = {
  schedule: ITPediaSchedule;
  onWorkspaceClick: ((workspace: IntegrationWorkspace) => void) | null;
};

export function ITPediaDetails({
  schedule,
  onWorkspaceClick,
}: ExpandedRowParams) {
  const { completed, enrolled, progress } = useMemo(() => {
    if (!schedule.meta.lastResult?.summary) {
      return { completed: 0, enrolled: 0, progress: 0 };
    }

    const completed = schedule.meta.lastResult.summary.completed.products;
    const enrolled = schedule.meta.lastResult.summary.enrolled.products;
    const progress = (completed / (enrolled > 0 ? enrolled : 1)) * 100;

    return { completed, enrolled, progress };
  }, [schedule]);

  const tableDataSource = useMemo(() => {
    return [
      {
        componentTypes: Object.keys(schedule.jobOptions.componentTypes).join(
          ','
        ),
        workspace: schedule.jobOptions.workspaces.products,
      },
      {
        componentTypes: 'Vulnerability',
        workspace: schedule.jobOptions.workspaces.vulnerabilities,
      },
    ];
  }, [schedule]);

  return (
    <Stack gap="small">
      <FlexBox align="center" gap="small">
        <Header4>Enriching progress</Header4>
        <ProgressNumbers>
          {completed} of {enrolled}
        </ProgressNumbers>
        <Progress percentage={progress} />
      </FlexBox>
      <DatasourceTable
        dataSource={tableDataSource}
        components={newTableTheme}
        columns={[
          {
            title: 'Component types',
            dataIndex: 'componentTypes',
          },
          {
            title: 'Workspace',
            dataIndex: 'workspace',
            valueRender: workspace => {
              if (!workspace.id || !workspaceInterface.get(workspace.id)) {
                return (
                  <WithPopover content="Workspace is not available">
                    <FlexBox align="center" gap="xxsmall">
                      {workspace.name}
                      <Icon iconName={IconName.INFO} color={colors.grey50} />
                    </FlexBox>
                  </WithPopover>
                );
              }
              return onWorkspaceClick ? (
                <Link
                  iconName={IconName.WORKSPACE}
                  type="primary"
                  typography="text1"
                  onClick={() => onWorkspaceClick(workspace)}
                >
                  {workspace.name}
                </Link>
              ) : (
                workspace.name
              );
            },
          },
        ]}
      />
    </Stack>
  );
}

const ProgressNumbers = styled.div`
  text-wrap: nowrap;
`;

// We can't use the progress component from design systems
// this is because that will always put a percentage instead
// actual numbers
const Progress = styled.div<{ percentage: number }>`
  width: 128px;
  background-color: ${colors.surfaceDefault};
  height: ${s8};
  border-radius: ${r8};
  position: relative;
  &::before {
    position: absolute;
    width: ${({ percentage }) => percentage}%;
    border-radius: ${r8};
    background-color: ${colors.surfaceActionPrimary};
    content: ' ';
    height: 100%;
  }
`;
