import { connect } from '@ardoq/rxbeach';
import { combineLatest, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { find } from 'lodash/fp';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { modal } from '@ardoq/modal';
import { IntegrationSchedule } from '@ardoq/api-types';
import { IntegrationConnectionsState } from 'integrations/common/streams/connections/types';
import { IntegrationId } from 'integrations/common/streams/tabularMappings/types';
import { getConnectionsStream } from 'integrations/common/streams/connections/connections$';
import {
  deleteConnection,
  deleteConnectionFailure,
  listConnections,
} from 'integrations/common/streams/connections/actions';
import { integrationId$ } from 'integrations/common/streams/integrationId/integrationId$';
import { getSchedulesStream } from 'integrations/common/streams/schedules/getSchedulesStream';
import { getTransferConfigsStream } from 'integrations/common/streams/transferConfigs/transferConfigs$';
import { SavedTransferConfig, Connection } from '@ardoq/api-types/integrations';
import { DeleteConnectionDialog } from 'integrations/common/components/deleteConnectionDialog';
import { getAffectedSchedules } from 'integrations/common/streams/schedules/utils';
import { isConnectionUsedInSourceConfig } from 'integrations/common/streams/transferConfigs/utils';

type DeleteConnectionModalParams = {
  integrationId: IntegrationId;
  connectionId: string;
  connectionsState: IntegrationConnectionsState;
  onCancel: () => void;
  onSubmit: (
    integrationId: IntegrationId,
    connection: Connection
  ) => Promise<boolean>;
  transferConfigs: SavedTransferConfig[];
  schedules: IntegrationSchedule[];
};

function DeleteConnectionModalComponent({
  integrationId,
  connectionId,
  connectionsState,
  transferConfigs,
  schedules,
  onSubmit,
  onCancel,
}: DeleteConnectionModalParams) {
  const { connections, statuses } = connectionsState;

  const filteredScheduleNames = getAffectedSchedules(
    schedules,
    connectionId
  ).map(({ jobOptions: { name } }) => name);
  const filteredConfigNames = transferConfigs
    .filter(config => isConnectionUsedInSourceConfig(config, connectionId))
    .map(({ name }) => name);
  const connection = find(({ _id }) => connectionId === _id, connections);

  const onDangerButtonClick = async () => {
    if (connection) {
      const isSuccess = await onSubmit(integrationId, connection);

      if (isSuccess) {
        onCancel();
      }
    }
  };

  if (!connection) {
    return null;
  }

  return (
    <DeleteConnectionDialog
      connection={connection}
      configNames={filteredConfigNames}
      scheduleNames={filteredScheduleNames}
      onSubmit={onDangerButtonClick}
      onCancel={onCancel}
      statuses={statuses}
    />
  );
}

const viewModel$ = integrationId$.pipe(
  switchMap(integrationId =>
    combineLatest({
      connectionsState: getConnectionsStream(integrationId),
      schedules: getSchedulesStream(integrationId).pipe(
        map(({ schedules }) => schedules)
      ),
      transferConfigs: getTransferConfigsStream(integrationId).pipe(
        map(({ configs }) => configs)
      ),
    })
  ),
  map(({ connectionsState, schedules, transferConfigs }) => ({
    schedules,
    transferConfigs,
    connectionsState,
  }))
);

const DeleteConnectionModal = connect(
  DeleteConnectionModalComponent,
  viewModel$
);

const onSubmit = async (
  integrationId: IntegrationId,
  connection: Connection
) => {
  const action = await dispatchActionAndWaitForResponse(
    deleteConnection({ integrationId: integrationId, connection }),
    listConnections,
    deleteConnectionFailure
  );

  return action.type === listConnections.type;
};

export const startDeleteConnectionModal = (
  integrationId: IntegrationId,
  connectionId: string
) =>
  modal(
    close => (
      <DeleteConnectionModal
        integrationId={integrationId}
        connectionId={connectionId}
        onCancel={() => close(null)}
        onSubmit={onSubmit}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
