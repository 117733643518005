import { AlertDialog, ModalSize } from '@ardoq/modal';
import { ForceReloadReason } from './actions';
import { KnowledgeBaseLink } from '@ardoq/knowledge-base';

const forceReloadReasonToTitle: Record<ForceReloadReason, string> = {
  [ForceReloadReason.CANNOT_CONNECT_WS]:
    'Cannot establish websocket connection',
  [ForceReloadReason.DEAD_CONNECTION_WS]: 'Lost contact with Ardoq servers',
};

const forceReloadReasonToMessage: Record<ForceReloadReason, string> = {
  [ForceReloadReason.CANNOT_CONNECT_WS]:
    'We have lost the connection to the Ardoq servers.',
  [ForceReloadReason.DEAD_CONNECTION_WS]:
    'We have lost the connection to the Ardoq servers.',
};

const ForceReloadDialog = ({ reason }: { reason: ForceReloadReason }) => {
  return (
    <AlertDialog
      modalSize={ModalSize.S}
      title={forceReloadReasonToTitle[reason]}
      subtitle={forceReloadReasonToMessage[reason]}
      text={
        <>
          <p>
            Without a working websocket connection, Ardoq will not work
            properly. Please read our{' '}
            <a
              href={KnowledgeBaseLink.WEBSOCKETS}
              target="_blank"
              rel="noreferrer"
            >
              knowledge base article
            </a>{' '}
            about websockets.
          </p>
          <p>
            Ardoq must be reloaded to stay in sync. If you have unsaved changes
            that you wish to keep, you can close this dialog and copy your
            changes. After copying, you should reload Ardoq.
          </p>
        </>
      }
      confirmButtonTitle="Reload Ardoq"
      onClose={() => document.location.reload()}
    />
  );
};

export default ForceReloadDialog;
