import { useCallback, useEffect, useState } from 'react';
import { AlertDialog, ModalSize, ModalTemplate } from '@ardoq/modal';
import { Select } from '@ardoq/select';
import { FormSize } from '@ardoq/forms';
import { Space } from '@ardoq/style-helpers';
import styled from 'styled-components';
import { s32 } from '@ardoq/design-tokens';
import { FetchHistoryState, OpenEntityMergeParams } from './types';
import { getEntityName, getSelectVersionOptionsFromHistory } from './utils';
import { pick, truncate } from 'lodash';
import { HistoryVersionTable } from './HistoryVersionTable';
import { SecondaryButton } from '@ardoq/button';
import { withPlainTextPopover } from '@ardoq/popovers';
import { getEntityAttributesFromEnhancedScopeData } from './utils';
import { logError } from '@ardoq/logging';
import {
  APIComponentAttributes,
  APIOrganizationUser,
  APIReferenceAttributes,
} from '@ardoq/api-types';
import type { EnhancedScopeData } from '@ardoq/data-model';

const Wrapper = styled(Space).attrs({ $isVertical: true, $gap: 's32' })`
  overflow: hidden;
  padding: ${s32};
`;

type HistoryVersionMergeModalProps = {
  onClose: () => void;
  onMergeAndSave: (
    selectedHistoryPartial:
      | Partial<APIComponentAttributes>
      | Partial<APIReferenceAttributes>
  ) => Promise<void>;
  enhancedScopeData: EnhancedScopeData;
  users: Record<string, APIOrganizationUser>;
  versionsToFetchCount: number;
  defaultSelectedHistoryVersion?: number;
  setVersionsToFetchCount: (newValue: number) => void;
} & Pick<FetchHistoryState, 'historyByVersion'> &
  OpenEntityMergeParams;

export const EntityHistoryModal = ({
  entityId,
  entityType,
  enhancedScopeData,
  users,
  historyByVersion,
  onClose,
  onMergeAndSave,
  setVersionsToFetchCount,
  versionsToFetchCount,
  defaultSelectedHistoryVersion,
}: HistoryVersionMergeModalProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<number | null>(null);

  const entityAttributes = getEntityAttributesFromEnhancedScopeData({
    entityId,
    entityType,
    enhancedScopeData,
  });

  const selectVersionOptionsFromHistory = getSelectVersionOptionsFromHistory(
    Object.values(historyByVersion),
    entityAttributes,
    entityType,
    enhancedScopeData
  );

  useEffect(() => {
    setSelectedVersion(
      defaultSelectedHistoryVersion ||
        selectVersionOptionsFromHistory[0]?.value ||
        null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyByVersion]);

  const handleMergeAndSave = useCallback(async () => {
    if (!selectedVersion) return;
    const selectedHistoryVersion = historyByVersion[selectedVersion] as
      | Partial<APIComponentAttributes>
      | Partial<APIReferenceAttributes>;
    await onMergeAndSave(pick(selectedHistoryVersion, selectedKeys));
  }, [historyByVersion, selectedKeys, onMergeAndSave, selectedVersion]);

  if (!enhancedScopeData || !entityAttributes) {
    logError(
      new Error(
        'Entity history merge: Entity attributes not found in scope data'
      )
    );
    return <AlertDialog title="Entity not found" onClose={onClose} />;
  }

  const entityName = getEntityName(entityAttributes, entityType);
  const entityVersion = entityAttributes._version;

  if (selectVersionOptionsFromHistory.length === 0) {
    return (
      <AlertDialog
        title={`History for ${truncate(
          entityName
        )}, current revision: ${entityVersion}`}
        onClose={onClose}
        text={
          <>
            There is no history available for
            <strong>{` ${entityName} `}</strong>{' '}
            {entityVersion === 1 ? "since it's in its first version" : ''}.
          </>
        }
      />
    );
  }

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText={`History for ${truncate(
        entityName
      )}, current revision: ${entityVersion}`}
      onCloseButtonClick={() => onClose()}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={() => onClose()}
      primaryButtonText="Merge and save"
      onPrimaryButtonClick={handleMergeAndSave}
      isPrimaryButtonDisabled={!selectedKeys.length || !selectedVersion}
      fixedHeight
    >
      <Wrapper>
        <Space $align="end">
          <Select
            formSize={FormSize.DEFAULT}
            label="Select version"
            options={selectVersionOptionsFromHistory}
            value={selectedVersion}
            onValueChange={newSelectedVersion =>
              setSelectedVersion(newSelectedVersion as number)
            }
          />
          {versionsToFetchCount < (entityAttributes._version || 0) && (
            <SecondaryButton
              onClick={() =>
                setVersionsToFetchCount(entityAttributes._version || 100)
              }
              {...withPlainTextPopover(
                `Initially we load 50 last entity versions. Current entity has been modified already ${entityAttributes._version} times. In order to browse all versions click the button.`
              )}
            >
              Load all versions
            </SecondaryButton>
          )}
        </Space>
        {selectedVersion && (
          <HistoryVersionTable
            entityId={entityId}
            entityType={entityType}
            entityAttributes={entityAttributes}
            enhancedScopeData={enhancedScopeData}
            users={users}
            historyPoint={historyByVersion[selectedVersion]}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
          />
        )}
      </Wrapper>
    </ModalTemplate>
  );
};
