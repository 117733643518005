import { ArdoqId, PrivilegeLabel, PrivilegesByUser } from '@ardoq/api-types';

export const _userHasPrivilege = (
  privilegesByUser: PrivilegesByUser,
  userId: ArdoqId,
  privilegeLabel: PrivilegeLabel
) => {
  const userPrivileges = privilegesByUser[userId] ?? [];
  return userPrivileges.some(({ label }) => label === privilegeLabel);
};
