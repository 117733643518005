import {
  deleteModel,
  fetchAllModels,
  fetchAllModelsSucceeded,
} from './actions';
import {
  collectRoutines,
  routine,
  extractPayload,
  ofType,
  dispatchAction,
} from '@ardoq/rxbeach';
import { switchMap, tap } from 'rxjs/operators';
import Models from 'collections/models';

const handleDeleteModel = routine(
  ofType(deleteModel),
  extractPayload(),
  tap(({ id }) => {
    Models.collection.get(id)?.destroy();
  })
);

const handleFetchAllModels = routine(
  ofType(fetchAllModels),
  switchMap(() => Models.collection.fetch()),
  tap(() => dispatchAction(fetchAllModelsSucceeded()))
);

export default collectRoutines(handleDeleteModel, handleFetchAllModels);
