export enum OverviewRoute {
  CONNECTIONS = 'connections',
  CONFIGURATIONS = 'configurations',
  SCHEDULES = 'schedules',
}

export enum ImportRoute {
  SELECT_DATA = 'import/select-data',
  CONFIGURE = 'import/configure',
  REVIEW = 'import/test',
  IMPORT_AND_SCHEDULE = 'import/import-and-schedule',
}

export enum ExportRoute {
  SELECT_DATA = 'export/select-data',
  CONFIGURE = 'export/configure',
  REVIEW = 'export/test',
  EXPORT = 'export/export',
}

/**
 * Route path for the Signavio Exporter
 * We will use some of the routes specific to the Signavio Exporter for now.
 * The goal is to have a common navigation structure for all integrations.
 * But since this is first indepenent exporter, we will use the routes specific to it.
 * In future, we will refactor this to have a common navigation structure for all exporters.
 */

export enum NewExportRoutes {
  DATA_SELECTION = 'export/data-selection',
  FIELD_MAPPING = 'export/field-mapping',
  REVIEW_EXPORT = 'export/review-export',
  EXPORT_AND_SCHEDULE = 'export/export-and-schedule',
  SUCCESS = 'export/success',
  FAILED = 'export/failed',
}

export type RoutePath =
  | OverviewRoute
  | ImportRoute
  | ExportRoute
  | NewExportRoutes;
