import { persistentReducedStream } from '@ardoq/rxbeach';
import { defaultState, reducers } from './reducers';
import { PrivilegesStreamShape } from './types';

const privileges$ = persistentReducedStream<PrivilegesStreamShape>(
  'privileges$',
  defaultState,
  reducers
);

export default privileges$;
