import {
  APIDiscoverViewpointAttributes,
  ArdoqId,
  BroadcastStatus,
} from '@ardoq/api-types';
import broadcast$ from 'broadcasts/broadcast$';
import viewpoints$ from 'viewpoints/viewpoints$';

const getViewPointById = (
  viewpointId?: ArdoqId
): APIDiscoverViewpointAttributes | null => {
  const { viewpoints } = viewpoints$.state;
  if (!viewpointId || !viewpoints) {
    return null;
  }
  return viewpoints?.find(v => v._id === viewpointId) || null;
};

const viewpointExists = (viewpointId?: ArdoqId) =>
  Boolean(viewpointId && getViewPointById(viewpointId));

const runningBroadcastsUsingViewpoint = (viewpointId?: ArdoqId) => {
  const { broadcasts } = broadcast$.state;
  return broadcasts.filter(
    broadcast =>
      broadcast.audienceStartingPageViewpoint === viewpointId &&
      broadcast.status === BroadcastStatus.RUNNING
  ).length;
};

export const viewpointInterface = {
  getViewPointById,
  viewpointExists,
  runningBroadcastsUsingViewpoint,
};
